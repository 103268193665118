import useScrollPosition from "@react-hook/window-scroll";
import { Checkbox, Col, Div, Label, Row, Tag, Text } from "atomize";
import { isEmpty, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { notificationsCount, notificationsCountSkip } from "../../App";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  GetNotificationsDocument,
  NotificationListingItem,
  useGetMeQuery,
  useGetNotificationsLazyQuery,
  useNewNotificationSubscription,
  useReadAllNotificationsMutation,
} from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import {
  initialGetNotifications,
  initialInboxFilters,
} from "../../helpers/initialData";
import Notification from "./Notification";
import { NotificationTypeFilterOption } from "./utils/helpers";

const Inbox: React.FC = () => {
  // Custom hooks
  const { height, width } = useWindowDimensions();
  const scrollY = useScrollPosition(60 /*fps*/);

  // State variables
  const [messages, setMessages] = useState<
    Array<NotificationListingItem | undefined> | []
  >([]);
  const [page, setPage] = useState(0);
  const [newPage, setNewPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsPag] = useState({
    ...initialGetNotifications,
    limit: Math.floor(height / 70),
  });
  const [typeFiltersState, setTypeFiltersState] =
    useState<NotificationTypeFilterOption[]>(initialInboxFilters);
  const [filters, setFilters] = useState<{
    [key: string]: any;
  }>({});

  // Functions
  const handleSelectFilter = (label: string) => {
    const newFilters = typeFiltersState.map((filter) => {
      if (filter.label === label) {
        return {
          ...filter,
          isSelected: !filter.isSelected,
        };
      }
      return filter;
    });
    setTypeFiltersState(newFilters);
  };

  const handleFiltersChange = (key: string, value: any) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleApplyFilters = (e: any) => {
    e.preventDefault();
    const typeFilters = typeFiltersState
      ?.filter((filter) => filter.isSelected)
      ?.map((filter) => filter.value)
      ?.flat();

    handleFiltersChange("type", uniq(typeFilters));
    setPage(1);
  };

  const handleClearAllFilters = (e: any) => {
    e.preventDefault();
    setTypeFiltersState(
      typeFiltersState.map((filter) => ({
        ...filter,
        isSelected: false,
      }))
    );
    setFilters({});
    setPage(1);
  };

  const handleSelectAllFilters = (e: any) => {
    e.preventDefault();
    setTypeFiltersState((prevState) =>
      prevState.map((filter) => ({
        ...filter,
        isSelected: true,
      }))
    );
    const newFiltersState = initialInboxFilters
      ?.map((filter) => filter.value)
      ?.flat();
    setFilters((prevState) => ({
      ...prevState,
      type: newFiltersState,
    }));
    setPage(1);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (me?.getUser.company?.id) {
      await ReadAll({
        refetchQueries: [GetNotificationsDocument],
        awaitRefetchQueries: true,
      });
      setNewPage(false);
    }
  };

  // Queries
  const { data: me } = useGetMeQuery();
  const [
    getNotifications,
    {
      data: notificationData,
      loading: notificationLoading,
      error: notificationError,
    },
  ] = useGetNotificationsLazyQuery({ fetchPolicy: "network-only" });

  // Mutations
  const [
    ReadAll,
    { data: readAllData, error: readAllError, loading: readAllLoading },
  ] = useReadAllNotificationsMutation();

  // Subscriptions
  useNewNotificationSubscription({
    variables: { user_id: Number(me?.getUser.id) },
    onSubscriptionData: (data) => {
      setMessages((prevState) => [
        data.subscriptionData.data?.newNotification,
        ...prevState,
      ]);
      setPage(1);
    },
  });

  // Variables
  const shouldShowMarkAllAsRead = !(
    total === 0 &&
    messages.length === 0 &&
    !notificationLoading &&
    notificationData?.getNotifications.total === 0
  );

  const isNoTypeFiltersSelected = typeFiltersState?.every(
    (item) => item?.isSelected === false
  );

  // Effects
  useEffect(() => {
    notificationsCountSkip(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page !== 0) {
      const nonEmptyFilters: { [key: string]: any } = Object.keys(
        filters || {}
      ).reduce((acc: { [key: string]: any }, key) => {
        if (!isEmpty(filters[key])) {
          acc[key] = filters[key];
        }

        return acc;
      }, {});

      getNotifications({
        variables: {
          query: {
            ...notificationsPag,
            page: page,
            ...nonEmptyFilters,
            orderBy: "date",
            company_id: me?.getUser?.company_id,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters, me?.getUser?.company_id]);

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    if (
      notificationData?.getNotifications.items &&
      notificationData?.getNotifications?.total
    ) {
      setTotal(notificationData?.getNotifications?.total);
      if (page === 1) {
        setMessages([...notificationData.getNotifications.items]);
      } else {
        setMessages((prevState) => [
          ...prevState,
          ...(notificationData?.getNotifications?.items ?? []),
        ]);
      }
      setNewPage(false);
      setIsLoading(false);
    } else {
      if (page === 1) {
        setMessages([]);
        setTotal(0);
        setNewPage(false);
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  useEffect(() => {
    const windowBottom = document.body;

    if (
      window.innerHeight + scrollY + 160 >= windowBottom.clientHeight &&
      !newPage
    ) {
      total > messages.length && setNewPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  useEffect(() => {
    if (newPage) {
      setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage]);

  // BE Handlers
  ErrorsBeHandler({
    error: notificationError,
  });

  ErrorsBeHandler({
    error: readAllError,
  });

  SuccessBeHandler({
    data: readAllData,
    code: "READ_ALL_SUCCESS",
    fn: () => {
      notificationsCount(0);
    },
  });

  return (
    <Row d="flex" h="100%" align="flex-start" p={{ y: "1rem" }}>
      <Col h="100%" size="3">
        <Div
          d="flex"
          justify="flex-start"
          align="flex-start"
          flexDir="column"
          m={{ t: "2rem", l: "4rem" }}
        >
          <Text
            textSize={12}
            minW="60px"
            m={{ r: "0.5rem" }}
            textColor="light"
            textWeight="600"
            textTransform="uppercase"
            style={{ letterSpacing: "1.4px" }}
          >
            Inbox actions
          </Text>
          {shouldShowMarkAllAsRead && (
            <SecondaryBtn
              w="160px"
              h="32px"
              handleSubmit={(e: any) => handleSubmit(e)}
              isLoading={readAllLoading}
              text={"Mark all as read"}
              styleBtn={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            />
          )}
          <Text
            textSize={12}
            minW="60px"
            m={{ r: "0.5rem", y: "1rem" }}
            textColor="light"
            textWeight="600"
            textTransform="uppercase"
            style={{ letterSpacing: "1.4px" }}
          >
            Filters
          </Text>
          <Row flexDir="column" align="flex-start">
            <Col
              d="flex"
              align="flex-start"
              flexDir="column"
              style={{
                gap: "1rem",
              }}
            >
              {typeFiltersState?.map((option) => (
                <Label key={option.label}>
                  <Checkbox
                    checked={option?.isSelected}
                    onChange={() => handleSelectFilter(option.label)}
                    value={option.label}
                    label={option.label}
                    m={{ r: "0.5rem" }}
                  />
                  <Text textSize={14} textWeight="500">
                    {option.label}
                  </Text>
                </Label>
              ))}
            </Col>
            <Col>
              <SecondaryBtn
                h="32px"
                handleSubmit={(e: any) => handleApplyFilters(e)}
                disabled={notificationLoading}
                text="Apply filters"
                styleBtn={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              />
              <SecondaryBtn
                h="32px"
                handleSubmit={(e: any) =>
                  isNoTypeFiltersSelected
                    ? handleSelectAllFilters(e)
                    : handleClearAllFilters(e)
                }
                disabled={notificationLoading}
                text={`${
                  isNoTypeFiltersSelected ? "Select" : "Clear"
                } all filters`}
                styleBtn={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              />
            </Col>
          </Row>
        </Div>
      </Col>
      <Col size="6">
        {isLoading ? (
          <>
            {[...Array(notificationsPag.limit)].map((_, index: number) => (
              <Col w="100%" key={index}>
                <Skeleton width="100%" height="64px" margin="0" />
              </Col>
            ))}
          </>
        ) : total > 0 && messages.length > 0 ? (
          <>
            {messages.map((notification: any, index: any) => {
              let n: any;
              let p: any;
              n = notification?.date && notification?.date.split("T")[0];
              p = messages[index - 1];
              if (p) {
                p = p?.date?.split("T")[0];
              }
              return (
                <Notification
                  key={index}
                  noti={notification}
                  date={n !== p}
                  myCompanyId={me?.getUser.company?.id}
                  me={me}
                />
              );
            })}
            {total > messages.length ? (
              <>
                <Col w="100%">
                  <Skeleton width="100%" height="64px" margin="0" />
                </Col>
                <Col w="100%">
                  <Skeleton
                    width="100%"
                    height="64px"
                    margin={width > 700 ? "0" : "0 0 48px 0"}
                  />
                </Col>
              </>
            ) : (
              <Div h="48px"></Div>
            )}
          </>
        ) : (
          !shouldShowMarkAllAsRead && (
            <Tag
              p={{ l: "1rem" }}
              m={{ x: "0.5rem" }}
              h="48px"
              w="calc(100% - 1rem)"
              d="flex"
              justify="start"
              textColor="light"
            >
              Inbox is empty.
            </Tag>
          )
        )}
      </Col>
      <Col size="3" />
    </Row>
  );
};

export default Inbox;
