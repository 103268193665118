/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Text,
  Div,
  Collapse,
  Icon,
  Label,
  Switch,
  Textarea,
  Radiobox,
  Input,
  Anchor,
  Dropdown,
} from "atomize";
import Border from "../../../components/Border/Border";
import InputDropdown from "../../../components/InputDropdown/InputDropdown";
import { loadingInitialState } from "./../loading.reducer";
import {
  CompanyStatus,
  GetOrdersDocument,
  LocationStatus,
  TransportCargoType,
  useCreateOrderMutation,
  useGetCompaniesLazyQuery,
  useGetCompanyLazyQuery,
  useGetCountryLazyQuery,
  useGetLocationLazyQuery,
  useGetLocationsLazyQuery,
  useGetMeQuery,
  useGetOrderQuery,
  useUpdateOrderMutation,
  useUpdateTransportMutation,
} from "../../../generated/graphql";
import Skeleton from "../../../components/Skeleton/Skeleton";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import { OrderContext } from "../ReceivePricingBids";
import { useHistory, useParams, useLocation } from "react-router";
import moment from "moment";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
import { tz } from "moment-timezone";
import NumberFormat from "react-number-format";
import { schema } from "../schema.validation.";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import { modalData, responseMsgs } from "../../../App";
import { useReactiveVar } from "@apollo/client";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";

const CreateOrder: React.FC = () => {
  const { code: codes } = useParams<{ code: string }>();
  const location = useLocation<any>();

  const code = codes || location.state?.code;

  const { loadingState, dispatch }: any = useContext(OrderContext);
  const history = useHistory();

  const { returned_value } = useReactiveVar(modalData);

  const [isTimezoneLoadingDropdownOpen, setIsTimezoneLoadingDropdownOpen] =
    useState<boolean>(false);
  const [isTimezoneUnloadingDropdownOpen, setIsTimezoneUnloadingDropdownOpen] =
    useState<boolean>(false);
  const [isTimezoneExpiryDropdownOpen, setIsTimezoneExpirtyDropdownOpen] =
    useState<boolean>(false);
  const [typeChanged, setTypeChanged] = useState<boolean>(false);
  const [detailsChanged, setDetailsChanged] = useState<boolean>(false);

  const { validator } = ValidationsFeHandler();

  const [collapse, setCollapse] = useState({
    loading: false,
    unloading: false,
    transport: false,
    pricing: false,
    description: false,
  });

  const [getShipperCountry, { data: shipperCountry }] =
    useGetCountryLazyQuery();
  const [getReceiverCountry, { data: receiverCountry }] =
    useGetCountryLazyQuery();

  const [
    createOrder,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateOrderMutation({ errorPolicy: "all" });
  const [
    updateOrder,
    { loading: updateOrderLoading, error: updateOrderError },
  ] = useUpdateOrderMutation({ errorPolicy: "all" });
  const [
    updateTransport,
    {
      data: updateTransportData,
      loading: updateTransportLoading,
      error: updateTransportError,
    },
  ] = useUpdateTransportMutation({ errorPolicy: "all" });

  const [
    getReceiverCompany,
    { data: companyReceiver, loading: companyReceiverLoading },
  ] = useGetCompanyLazyQuery();
  const [
    getShipperCompany,
    { data: companyShipper, loading: companyShipperLoading },
  ] = useGetCompanyLazyQuery();
  const [
    getLoadingLocations,
    { data: locationLoading, loading: locationLoadingLoading },
  ] = useGetLocationLazyQuery();
  const [
    getUnloadingLocations,
    { data: locationUnloading, loading: locationUnloadingloading },
  ] = useGetLocationLazyQuery();

  const { data: meData, loading: meLoading } = useGetMeQuery({
    onCompleted: (data) => {
      dispatch({
        type: "USER_TIMEZONE",
        field: "timezone",
        payload: data.getUser.company?.location?.timezone,
      });
    },
  });

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
  } = useGetOrderQuery({
    variables: { code },
    onCompleted: () => {
      if (order?.getOrder.transports) {
        getLoadingLocations({
          variables: {
            id: Number(order?.getOrder.transports[0].loadingLocation_id),
          },
        });
        getUnloadingLocations({
          variables: {
            id: Number(order?.getOrder.transports[0].unloadingLocation_id),
          },
        });
        getShipperCompany({
          variables: {
            id: Number(order?.getOrder.transports[0].shipperCompany_id),
          },
        });
        getReceiverCompany({
          variables: {
            id: Number(order?.getOrder.transports[0].receiverCompany_id),
          },
        });
      }
    },
    fetchPolicy: "no-cache",
    skip: !code,
  });

  ErrorsBeHandler({
    error: orderError,
    fn: () => {
      history.push("/myorders");
    },
  });

  useEffect(() => {
    if (returned_value) {
      if (
        returned_value.action === "HANDLE_SHIPPER_PLACEHOLDER" ||
        returned_value.action === "HANDLE_RECEIVER_PLACEHOLDER" ||
        returned_value.action === "HANDLE_TRANSPORTER_PLACEHOLDER"
      ) {
        dispatch({
          type: returned_value.action,
          field: "",
          payload: {
            company: returned_value.company,
            location: returned_value.location,
          },
        });
      }
      if (
        returned_value.action === "HANDLE_SHIPPER_PLACEHOLDER_LOCATION" ||
        returned_value.action === "HANDLE_RECEIVER_PLACEHOLDER_LOCATION"
      ) {
        dispatch({
          type: returned_value.action,
          field: "",
          payload: {
            location: returned_value.location,
          },
        });
      }
    }
  }, [returned_value]);

  useEffect(() => {
    dispatch({
      type: "HANDLE_RESET",
      field: "",
      payload: {
        ...loadingInitialState,
      },
    });

    if (meData) {
      dispatch({
        type: "USER_TIMEZONE",
        field: "timezone",
        payload: meData.getUser.company?.location?.timezone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      companyShipper &&
      companyReceiver &&
      locationLoading &&
      locationUnloading &&
      code &&
      meData &&
      order
    ) {
      dispatch({
        type: "HANDLE_INITIAL",
        field: "",
        payload: {
          order: order,
          update: codes ? true : false,
          companyReceiver: companyReceiver,
          companyShipper: companyShipper,
          companyTransporter:
            location?.state?.type! === "EXISTING" || codes
              ? order.getOrder?.transporterCompany
              : null,
          locationLoading: locationLoading,
          locationUnloading: locationUnloading,
          user_timezone: meData?.getUser.company?.location?.timezone,
        },
      });

      setCollapse({
        loading: true,
        unloading: true,
        transport: true,
        pricing: true,
        description: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    meData,
    companyShipper,
    companyReceiver,
    companyShipper,
    locationLoading,
    companyShipper,
    locationUnloading,
  ]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const loadingDate = tz(
      `${loadingState?.loading_date} ${loadingState?.loading_time}`,
      loadingState?.loading_timezone
    ).format();
    const unloadingDate = tz(
      `${loadingState?.unloading_date} ${loadingState?.unloading_time}`,
      loadingState?.unloading_timezone
    ).format();

    const transport = {
      shipperCompany_id: loadingState.shipper.id,
      receiverCompany_id: loadingState.receiver.id,
      loadingLocation_id: loadingState.loadingLocation.id,
      unloadingLocation_id: loadingState.unloadingLocation.id,
      requestedLoadingTime: loadingDate,
      requestedUnloadingTime: unloadingDate,
      cargoType: loadingState.cargoType,
      parcels: loadingState.parcels,
      dangerous: loadingState.dangerous_goods,
      stackable: loadingState.stackable_goods,
    };

    const locationInputs = [
      {
        location_id: loadingState?.loadingLocation?.id,
        order: 0,
        loadingDate: loadingDate,
      },
      {
        location_id: loadingState?.unloadingLocation?.id,
        order: 1,
        loadingDate: unloadingDate,
      },
    ];

    const order = loadingState.onMarket
      ? {
          description: loadingState.description,
          company_id: Number(meData?.getUser.company?.id),
          marketExpirationDate: tz(
            `${loadingState.expiry_date} ${loadingState.expiry_time}`,
            loadingState.expiry_timezone
          ).format(),
        }
      : {
          description: loadingState.description,
          company_id: Number(meData?.getUser.company?.id),
          transporterCompany_id: loadingState.transporter.id,
          price: Number(loadingState.price),
        };

    validator({
      schema: schema,
      data: { ...transport, ...order, currentDate: moment().format() },
      context: {
        cargoType: loadingState.cargoType,
        onMarket: loadingState.onMarket,
      },
      success: async () => {
        if (codes) {
          await updateOrder({
            variables: {
              order: {
                description: loadingState.description,
                id: loadingState.id,
                marketExpirationDate: loadingState.onMarket
                  ? tz(
                      `${loadingState.expiry_date} ${loadingState.expiry_time}`,
                      loadingState.expiry_timezone
                    ).format()
                  : null,
                transporterCompany_id: !loadingState.onMarket
                  ? loadingState.transporter.id
                  : null,
                price: !loadingState.onMarket
                  ? Number(loadingState.price)
                  : null,
              },
            },
          });
          await updateTransport({
            variables: {
              transport: {
                ...transport,
                order_id: Number(loadingState.id),
                id: Number(loadingState.transport_id),
              },
            },
            refetchQueries: [GetOrdersDocument],
          });
        } else {
          await createOrder({
            variables: {
              transport: transport,
              order: order,
              locationPoints: locationInputs,
            },
            refetchQueries: [GetOrdersDocument],
          });
        }
      },
    });
  };

  const queryShipper = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isShipperDropdownOpen, setIsShipperDropdownOpen] =
    useState<boolean>(false);
  const [queryShipperStatus, setQueryShipperStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.Active,
    CompanyStatus.PlaceholderWaitingConfirm,
  ]);
  const {
    data: companiesShipperData,
    loading: loadingShipperCompanies,
    search: searchShipper,
    setSearch: setSearchShipper,
    page: searchShipperPage,
    setPage: setSearchShipperPage,
  } = useSearch(
    queryShipper,
    { limit: 5, status: queryShipperStatus },
    300,
    queryShipperStatus
  );

  useEffect(() => {
    const arrayStatus =
      loadingState.receiver.status === CompanyStatus.Placeholder ||
      loadingState.receiver.status === CompanyStatus.PlaceholderWaitingConfirm
        ? // (loadingState.transporter.status === CompanyStatus.Placeholder || loadingState.transporter.status === CompanyStatus.WaitingConfirm || loadingState.transporter.status === CompanyStatus.Incomplete)
          [CompanyStatus.Active]
        : [
            CompanyStatus.Placeholder,
            CompanyStatus.Active,
            CompanyStatus.PlaceholderWaitingConfirm,
          ];
    setQueryShipperStatus(arrayStatus);
    setQueryTransporterStatus(arrayStatus);
  }, [loadingState.receiver.status]);

  const [isReceiverDropdownOpen, setIsReceiverDropdownOpen] =
    useState<boolean>(false);
  const queryReceiver = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [queryReceiverStatus, setQueryReceiverStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.Active,
    CompanyStatus.PlaceholderWaitingConfirm,
  ]);
  const {
    data: companiesReceiverData,
    loading: loadingReceiverCompanies,
    search: searchReceiver,
    setSearch: setSearchReceiver,
    page: searchReceiverPage,
    setPage: setSearchReceiverPage,
  } = useSearch(
    queryReceiver,
    { limit: 5, status: queryReceiverStatus },
    300,
    queryReceiverStatus
  );

  useEffect(() => {
    const arrayStatus =
      loadingState.shipper.status === CompanyStatus.Placeholder ||
      loadingState.receiver.status === CompanyStatus.PlaceholderWaitingConfirm
        ? // (loadingState.transporter.status === CompanyStatus.Placeholder || loadingState.transporter.status === CompanyStatus.WaitingConfirm || loadingState.transporter.status === CompanyStatus.Incomplete) ?
          [CompanyStatus.Active]
        : [
            CompanyStatus.Placeholder,
            CompanyStatus.Active,
            CompanyStatus.PlaceholderWaitingConfirm,
          ];
    setQueryReceiverStatus(arrayStatus);
    setQueryTransporterStatus(arrayStatus);
  }, [loadingState.shipper.status]);

  const [isTransporterDropdownOpen, setIsTransporterDropdownOpen] =
    useState<boolean>(false);
  const queryTransporter = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [queryTransporterStatus, setQueryTransporterStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.Active,
    CompanyStatus.PlaceholderWaitingConfirm,
  ]);
  const {
    data: companiesTransporterData,
    loading: loadingTransporterCompanies,
    search: searchTransporter,
    setSearch: setSearchTransporter,
    page: searchTransporterPage,
    setPage: setSearchTransporterPage,
  } = useSearch(
    queryTransporter,
    { limit: 5, status: queryTransporterStatus },
    300,
    queryTransporterStatus
  );

  useEffect(() => {
    const arrayStatus =
      // (loadingState.shipper.status === CompanyStatus.Placeholder || loadingState.shipper.status === CompanyStatus.WaitingConfirm || loadingState.shipper.status === CompanyStatus.Incomplete)) &&
      loadingState.shipper.status === CompanyStatus.Placeholder ||
      loadingState.receiver.status === CompanyStatus.PlaceholderWaitingConfirm
        ? [CompanyStatus.Active]
        : [
            CompanyStatus.Placeholder,
            CompanyStatus.Active,
            CompanyStatus.PlaceholderWaitingConfirm,
          ];
    setQueryShipperStatus(arrayStatus);
    setQueryReceiverStatus(arrayStatus);
  }, [loadingState.transporter.status]);

  const queryLoadingLocation = useGetLocationsLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isShipperLocationDropdownOpen, setIsShipperLocationDropdownOpen] =
    useState<boolean>(false);
  const {
    data: locationsShipper,
    loading: locationsShipperLoading,
    search: searchLocationShipper,
    setSearch: setSearchLocationShipper,
    page: searchLocationShipperPage,
    setPage: setSearchLocationShipperPage,
  } = useSearch(
    queryLoadingLocation,
    {
      company_id: loadingState.shipper.id,
      limit: 5,
      desc: false,
      status: [
        LocationStatus.Active,
        LocationStatus.Draft,
        LocationStatus.Placeholder,
      ],
    },
    300,
    isShipperLocationDropdownOpen
  );

  const queryUnloadingLocation = useGetLocationsLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isReceiverLocationDropdownOpen, setIsReceiverLocationDropdownOpen] =
    useState<boolean>(false);
  const {
    data: locationsReceiver,
    loading: locationsReceiverLoading,
    search: searchLocationReceiver,
    setSearch: setSearchLocationReceiver,
    page: searchLocationReceiverPage,
    setPage: setSearchLocationReceiverPage,
  } = useSearch(
    queryUnloadingLocation,
    {
      company_id: loadingState.receiver.id,
      limit: 5,
      desc: false,
      status: [
        LocationStatus.Active,
        LocationStatus.Draft,
        LocationStatus.Placeholder,
      ],
    },
    300,
    isReceiverLocationDropdownOpen
  );

  ErrorsBeHandler({
    error: updateOrderError,
  });

  ErrorsBeHandler({
    error: updateTransportError,
  });

  ErrorsBeHandler({
    error: createError,
  });

  SuccessBeHandler({
    data: updateTransportData,
    code: "ORDER_UPDATED",
    fn: () => {
      dispatch({
        type: "HANDLE_CHECK_ORDER",
        field: "",
        payload: {
          id: order?.getOrder.id,
          code: order?.getOrder.code,
          transport_id: updateTransportData?.updateTransport.id,
        },
      });
      history.push("/createneworder/checkorder");
    },
  });

  SuccessBeHandler({
    data: createData,
    code: "ORDER_CREATED",
    fn: () => {
      dispatch({
        type: "HANDLE_CHECK_ORDER",
        field: "",
        payload: {
          id: createData?.createOrder.id,
          code: createData?.createOrder.code,
          transport_id: createData?.createOrder?.transports![0].id,
        },
      });
      history.push("/createneworder/checkorder");
    },
  });

  const { width, height } = useWindowDimensions();

  const isLoading =
    meLoading ||
    ((companyReceiverLoading ||
      companyShipperLoading ||
      locationLoadingLoading ||
      locationUnloadingloading ||
      orderLoading ||
      updateOrderLoading ||
      updateTransportLoading) &&
      !order);

  return (
    <>
      <Row>
        <Col>
          <Text
            m={{ t: "2rem" }}
            p={{ x: "1rem" }}
            textColor="dark"
            textAlign="center"
            textSize={20}
            textWeight="500"
          >
            {codes ? "Update order" : "Enter all order information to proceed"}
          </Text>
        </Col>
      </Row>
      <Row m={{ x: "0", y: { xs: "0rem" } }} w="100%" d="flex" flexDir="column">
        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({ ...collapse, loading: !collapse.loading });
                setIsShipperDropdownOpen(false);
              }}
            >
              <Text textColor="dark" textWeight={500} m={{ l: "0.5rem" }}>
                Loading information{" "}
              </Text>
              {!collapse.loading ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              transition={"height 20s "}
              isOpen={collapse.loading}
              m={{ t: "1rem" }}
              overflow={
                isShipperDropdownOpen ||
                isTimezoneLoadingDropdownOpen ||
                isShipperLocationDropdownOpen
                  ? "visible"
                  : "hidden"
              }
            >
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Shipper company
              </Text>
              <InputDropdown
                page={searchShipperPage}
                setPage={setSearchShipperPage}
                totalPages={Math.ceil(
                  companiesShipperData?.getCompanies?.total / 5
                )}
                addNew={
                  !(
                    (loadingState.receiver.id !== null ||
                      loadingState.transporter.id !== null) &&
                    (loadingState.receiver.status ===
                      CompanyStatus.Placeholder ||
                      loadingState.receiver.status ===
                        CompanyStatus.PlaceholderWaitingConfirm ||
                      loadingState.transporter.status ===
                        CompanyStatus.Placeholder ||
                      loadingState.transporter.status ===
                        CompanyStatus.PlaceholderWaitingConfirm)
                  )
                }
                modal={{
                  msg: "Create Company",
                  name: "createPlaceholderShipperCompany",
                  returned_value: null,
                  props: null,
                }}
                isLoading={loadingShipperCompanies}
                displayResult={
                  loadingState.shipper.id !== null ? (
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                        width: "100%",
                      }}
                    >
                      <PreloadingImage
                        imageSrc={loadingState.shipper.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={loadingState.shipper.name}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          minWidth: "24px",
                          minHeight: "24px",
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Div
                        style={{
                          width: "calc(100% - 70px)",
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          textWeight="500"
                          m={{ l: "0.5rem" }}
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "hidden",
                          }}
                        >
                          {loadingState.shipper.name}
                        </Text>
                      </Div>
                      {loadingState.shipper.status === CompanyStatus.Active && (
                        <Icon
                          m={{ l: "1rem" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Div>
                  ) : (
                    <div></div>
                  )
                }
                isOpen={isShipperDropdownOpen}
                setIsOpen={setIsShipperDropdownOpen}
                value={searchShipper}
                handleChange={setSearchShipper}
                menuList={companiesShipperData?.getCompanies.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={async () => {
                        dispatch({
                          type: "HANDLE_CHANGE_SHIPPER",
                          field: "shipper",
                          payload: {
                            ...item,
                            timezone:
                              meData?.getUser.company?.location?.timezone,
                          },
                        });
                        setSearchShipper("");
                        setIsShipperDropdownOpen(false);
                      }}
                    >
                      <PreloadingImage
                        imageSrc={item.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={item.name}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        m={{ l: "10px" }}
                      >
                        {" "}
                        {item.name}{" "}
                      </Text>
                      {item.status === CompanyStatus.Active && (
                        <Icon
                          m={{ l: "auto" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Anchor>
                  )
                )}
              />

              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Select loading location
              </Text>

              <InputDropdown
                page={searchLocationShipperPage}
                setPage={setSearchLocationShipperPage}
                totalPages={Math.ceil(
                  locationsShipper?.getLocations?.total / 5
                )}
                showSearchField={true}
                isLocked={loadingState.shipper.id === null}
                addNew={
                  loadingState.shipper.status === CompanyStatus.Placeholder
                }
                modal={{
                  msg: "Create Placeholder Location",
                  name: "locationPlaceholderModal",
                  returned_value: null,
                  props: {
                    company_id: loadingState.shipper.id,
                    action: "HANDLE_SHIPPER_PLACEHOLDER_LOCATION",
                  },
                }}
                displayResult={
                  loadingState.loadingLocation.address !== "" ? (
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                      }}
                    >
                      <Text
                        style={{
                          textOverflow: "ellipsis",
                          overflowX: "hidden",
                        }}
                        textColor="semiDark"
                        textWeight="500"
                        m={{ r: "auto", l: "0rem" }}
                        textSize={14}
                      >
                        {`${
                          loadingState.loadingLocation.title
                            ? loadingState.loadingLocation.title + ","
                            : ""
                        } ${loadingState.loadingLocation.address}, ${
                          loadingState.loadingLocation.postCode
                        }, ${loadingState.loadingLocation.postName}`}
                      </Text>
                    </Div>
                  ) : (
                    <Text
                      textColor="semiLight"
                      textWeight="500"
                      m={{ r: "auto", l: "0.5rem" }}
                      textSize={14}
                    >
                      Select Location
                    </Text>
                  )
                }
                isLoading={locationsShipperLoading}
                isOpen={isShipperLocationDropdownOpen}
                setIsOpen={setIsShipperLocationDropdownOpen}
                value={searchLocationShipper}
                handleChange={setSearchLocationShipper}
                menuList={locationsShipper?.getLocations.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        dispatch({
                          type: "HANDLE_CHANGE_LOCATION",
                          field: "loadingLocation",
                          payload: item,
                        });
                        getShipperCountry({
                          variables: { id: Number(item.country.id) },
                        });
                        setIsShipperLocationDropdownOpen(false);
                      }}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {" "}
                        {`${item.title ? item.title + "," : ""} ${
                          item.address
                        }, ${item.postCode}, ${item.postName}`}
                      </Text>
                    </Anchor>
                  )
                )}
              />

              <Row>
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "5" : "6"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Pickup date{" "}
                  </Text>
                  <Input
                    w="100%"
                    placeholder="DD/MM/YYYY"
                    h="2.5rem"
                    name="loading_date"
                    type="date"
                    value={loadingState.loading_date}
                    onChange={(e: any) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: e.target.name,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "3" : "6"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Time
                  </Text>
                  <Input
                    placeholder="16.00"
                    w="100%"
                    h="2.5rem"
                    name="loading_time"
                    type="time"
                    value={loadingState.loading_time}
                    onChange={(e: any) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: e.target.name,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "4" : "12"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Timezone
                  </Text>
                  <Dropdown
                    w="100%"
                    style={{
                      PointerEvent:
                        (locationLoading?.getLocation.country?.code ||
                          shipperCountry?.getCountry.code) &&
                        loadingState.loadingLocation.timezone
                          ? "auto"
                          : "none",
                    }}
                    bg={
                      (locationLoading?.getLocation.country?.code ||
                        shipperCountry?.getCountry.code) &&
                      loadingState.loadingLocation.timezone
                        ? "transparent"
                        : "grey"
                    }
                    h="2.5rem"
                    isOpen={isTimezoneLoadingDropdownOpen}
                    onClick={
                      (locationLoading?.getLocation.country?.code ||
                        shipperCountry?.getCountry.code) &&
                      loadingState.loadingLocation.timezone
                        ? () =>
                            setIsTimezoneLoadingDropdownOpen(
                              !isTimezoneLoadingDropdownOpen
                            )
                        : ""
                    }
                    menu={
                      <Div maxH="300px" overflow="visible scroll">
                        <Anchor
                          d="flex"
                          p={{ y: "0.5rem", x: "0.75rem" }}
                          align="center"
                          onClick={() => {
                            if (
                              meData?.getUser?.company?.location?.timezone &&
                              tz(
                                `${loadingState.loading_date} ${loadingState.loading_time}`,
                                meData?.getUser?.company?.location?.timezone
                              ).format() > moment().format()
                            ) {
                              dispatch({
                                type: "HANDLE_CHANGE",
                                field: "loading_timezone",
                                payload:
                                  meData?.getUser.company?.location?.timezone,
                              });
                              setIsTimezoneLoadingDropdownOpen(false);
                            } else {
                              responseMsgs([
                                {
                                  type: "error",
                                  string:
                                    "Inserted date is not valid, make sure input value is higher than the current date",
                                },
                              ]);
                            }
                          }}
                        >
                          <Text textColor="semiDark" hoverTextColor="dark">
                            {meData?.getUser.company?.location?.timezone +
                              " - Local"}
                          </Text>
                        </Anchor>
                        {loadingState.loadingLocation.timezone && (
                          <Anchor
                            d="flex"
                            p={{ y: "0.5rem", x: "0.75rem" }}
                            align="center"
                            onClick={() => {
                              if (
                                tz(
                                  `${loadingState.loading_date} ${loadingState.loading_time}`,
                                  loadingState.loadingLocation.timezone
                                ).format() > moment().format()
                              ) {
                                dispatch({
                                  type: "HANDLE_CHANGE",
                                  field: "loading_timezone",
                                  payload:
                                    loadingState.loadingLocation.timezone,
                                });
                                setIsTimezoneLoadingDropdownOpen(false);
                              } else {
                                responseMsgs([
                                  {
                                    type: "error",
                                    string:
                                      "Inserted date is not valid, make sure input value is higher than the current date",
                                  },
                                ]);
                              }
                            }}
                          >
                            <Text textColor="semiDark" hoverTextColor="dark">
                              {loadingState.loadingLocation.timezone +
                                " - Loading"}
                            </Text>
                          </Anchor>
                        )}
                      </Div>
                    }
                  >
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {loadingState.loading_timezone}
                    </Text>
                  </Dropdown>
                </Col>
              </Row>
            </Collapse>
          </Col>
        )}

        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="0 0 2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({ ...collapse, unloading: !collapse.unloading });
                setIsReceiverDropdownOpen(false);
              }}
            >
              <Text textColor="dark" textWeight={500} m={{ l: "0.5rem" }}>
                Unloading information{" "}
              </Text>
              {!collapse.unloading ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              isOpen={collapse.unloading}
              m={{ t: "1rem" }}
              overflow={
                isReceiverDropdownOpen ||
                isTimezoneUnloadingDropdownOpen ||
                isReceiverLocationDropdownOpen
                  ? "visible"
                  : "hidden"
              }
            >
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Receiver company{" "}
              </Text>

              <InputDropdown
                page={searchReceiverPage}
                setPage={setSearchReceiverPage}
                totalPages={Math.ceil(
                  companiesReceiverData?.getCompanies?.total / 5
                )}
                addNew={
                  !(
                    (loadingState.shipper.id !== null ||
                      loadingState.transporter.id !== null) &&
                    (loadingState.shipper.status ===
                      CompanyStatus.Placeholder ||
                      loadingState.shipper.status ===
                        CompanyStatus.PlaceholderWaitingConfirm ||
                      loadingState.transporter.status ===
                        CompanyStatus.Placeholder ||
                      loadingState.transporter.status ===
                        CompanyStatus.PlaceholderWaitingConfirm)
                  )
                }
                modal={{
                  msg: "Create Company",
                  name: "createPlaceholderReceiverCompany",
                  returned_value: null,
                  props: null,
                }}
                isLoading={loadingReceiverCompanies}
                displayResult={
                  loadingState.receiver.id !== null ? (
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                        width: "100%",
                      }}
                    >
                      <PreloadingImage
                        imageSrc={loadingState.receiver.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={`${loadingState.receiver.name}`}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          minWidth: "24px",
                          minHeight: "24px",
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Div
                        style={{
                          width: "calc(100% - 70px)",
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          textWeight="500"
                          m={{ l: "0.5rem" }}
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "hidden",
                          }}
                        >
                          {loadingState.receiver.name}
                        </Text>
                      </Div>
                      {loadingState.receiver.status ===
                        CompanyStatus.Active && (
                        <Icon
                          m={{ l: "0.5rem" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Div>
                  ) : (
                    <div></div>
                  )
                }
                isOpen={isReceiverDropdownOpen}
                setIsOpen={setIsReceiverDropdownOpen}
                value={searchReceiver}
                handleChange={setSearchReceiver}
                menuList={companiesReceiverData?.getCompanies.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        dispatch({
                          type: "HANDLE_CHANGE_RECEIVER",
                          field: "receiver",
                          payload: {
                            ...item,
                            timezone:
                              meData?.getUser.company?.location?.timezone,
                          },
                        });
                        setSearchReceiver("");
                        setIsReceiverDropdownOpen(false);
                      }}
                    >
                      <PreloadingImage
                        imageSrc={item.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={`${item.name}`}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        m={{ l: "10px" }}
                      >
                        {" "}
                        {item.name}{" "}
                      </Text>
                      {item.status === CompanyStatus.Active && (
                        <Icon
                          m={{ l: "auto" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Anchor>
                  )
                )}
              />

              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Select unloading location
              </Text>
              <InputDropdown
                page={searchLocationReceiverPage}
                setPage={setSearchLocationReceiverPage}
                totalPages={Math.ceil(
                  locationsReceiver?.getLocations?.total / 5
                )}
                showSearchField={true}
                isLocked={loadingState.receiver.id === null}
                addNew={
                  loadingState.receiver.status === CompanyStatus.Placeholder
                }
                modal={{
                  msg: "Create Placeholder Location",
                  name: "locationPlaceholderModal",
                  returned_value: null,
                  props: {
                    company_id: loadingState.receiver.id,
                    action: "HANDLE_RECEIVER_PLACEHOLDER_LOCATION",
                  },
                }}
                displayResult={
                  loadingState.unloadingLocation.address !== "" ? (
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                      }}
                    >
                      <Text
                        style={{
                          textOverflow: "ellipsis",
                          overflowX: "hidden",
                        }}
                        textColor="semiDark"
                        textWeight="500"
                        m={{ r: "auto", l: "0rem" }}
                        textSize={14}
                      >
                        {`${
                          loadingState.unloadingLocation.title
                            ? loadingState.unloadingLocation.title + ","
                            : ""
                        } ${loadingState.unloadingLocation.address}, ${
                          loadingState.unloadingLocation.postCode
                        }, ${loadingState.unloadingLocation.postName}`}
                      </Text>
                    </Div>
                  ) : (
                    <Text
                      textColor="semiLight"
                      textWeight="500"
                      m={{ r: "auto", l: "0.5rem" }}
                      textSize={14}
                    >
                      Select Location
                    </Text>
                  )
                }
                isLoading={locationsReceiverLoading}
                isOpen={isReceiverLocationDropdownOpen}
                setIsOpen={setIsReceiverLocationDropdownOpen}
                value={searchLocationReceiver}
                handleChange={setSearchLocationReceiver}
                menuList={locationsReceiver?.getLocations.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        dispatch({
                          type: "HANDLE_CHANGE_LOCATION",
                          field: "unloadingLocation",
                          payload: item,
                        });
                        getReceiverCountry({
                          variables: { id: Number(item.country.id) },
                        });
                        setIsReceiverLocationDropdownOpen(false);
                      }}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {" "}
                        {`${item.title ? item.title + "," : ""} ${
                          item.address
                        }, ${item.postName}, ${item.postCode}`}
                      </Text>
                    </Anchor>
                  )
                )}
              />

              <Row size="12">
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "5" : "6"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Delivery date{" "}
                  </Text>
                  <Input
                    w="100%"
                    placeholder="DD/MM/YYYY"
                    h="2.5rem"
                    name="unloading_date"
                    type="date"
                    value={loadingState.unloading_date}
                    onChange={(e: any) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: e.target.name,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "3" : "6"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Time
                  </Text>
                  <Input
                    placeholder="16.00"
                    w="100%"
                    h="2.5rem"
                    name="unloading_time"
                    type="time"
                    value={loadingState.unloading_time}
                    onChange={(e: any) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: e.target.name,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  size={
                    (width > 880 || width <= 700) && width > 600 ? "4" : "12"
                  }
                >
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Timezone
                  </Text>
                  <Dropdown
                    style={{
                      PointerEvent:
                        (locationUnloading?.getLocation.country?.code ||
                          receiverCountry?.getCountry.code) &&
                        loadingState.unloadingLocation.timezone
                          ? "auto"
                          : "none",
                    }}
                    bg={
                      (locationUnloading?.getLocation.country?.code ||
                        receiverCountry?.getCountry.code) &&
                      loadingState.unloadingLocation.timezone
                        ? "transparent"
                        : "grey"
                    }
                    h="2.5rem"
                    w="100%"
                    isOpen={isTimezoneUnloadingDropdownOpen}
                    onClick={
                      (locationUnloading?.getLocation.country?.code ||
                        receiverCountry?.getCountry.code) &&
                      loadingState.unloadingLocation.timezone
                        ? () =>
                            setIsTimezoneUnloadingDropdownOpen(
                              !isTimezoneUnloadingDropdownOpen
                            )
                        : ""
                    }
                    menu={
                      <Div maxH="300px" overflow="visible scroll">
                        <Anchor
                          d="flex"
                          p={{ y: "0.5rem", x: "0.75rem" }}
                          align="center"
                          onClick={() => {
                            dispatch({
                              type: "HANDLE_CHANGE",
                              field: "unloading_timezone",
                              payload:
                                meData?.getUser.company?.location?.timezone,
                            });
                            setIsTimezoneUnloadingDropdownOpen(false);
                          }}
                        >
                          <Text textColor="semiDark" hoverTextColor="dark">
                            {meData?.getUser.company?.location?.timezone +
                              " - Local"}
                          </Text>
                        </Anchor>
                        {loadingState.unloadingLocation.timezone && (
                          <Anchor
                            d="flex"
                            p={{ y: "0.5rem", x: "0.75rem" }}
                            align="center"
                            onClick={() => {
                              dispatch({
                                type: "HANDLE_CHANGE",
                                field: "unloading_timezone",
                                payload:
                                  loadingState.unloadingLocation.timezone,
                              });
                              setIsTimezoneUnloadingDropdownOpen(false);
                            }}
                          >
                            <Text textColor="semiDark" hoverTextColor="dark">
                              {loadingState.unloadingLocation.timezone +
                                " - Unloading"}
                            </Text>
                          </Anchor>
                        )}
                      </Div>
                    }
                  >
                    <Text
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        WebkitLineClamp: "1",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {loadingState.unloading_timezone}
                    </Text>
                  </Dropdown>
                </Col>
              </Row>
            </Collapse>
          </Col>
        )}

        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="0 0 2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({ ...collapse, transport: !collapse.transport });
              }}
            >
              <Text textColor="dark" m={{ l: "0.5rem" }} textWeight={500}>
                Transport details
              </Text>
              {!collapse.transport ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              isOpen={collapse.transport}
              m={{ t: "1rem" }}
              h={{
                xs:
                  detailsChanged && collapse.transport
                    ? "auto !important"
                    : "0",
              }}
            >
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Space requirements
              </Text>
              <Div position="relative" bg="grey" h="1px" w="100%">
                <Div
                  position="absolute"
                  bg="brand600"
                  h="2px"
                  w="50%"
                  transform={`translate(${
                    loadingState.cargoType === TransportCargoType.Ftl
                      ? "0%"
                      : "100%"
                  })`}
                  transition
                ></Div>
              </Div>
              <Div
                d="flex"
                justify="space-around"
                textWeight={600}
                m={{ t: "0.25rem", b: "1.5rem" }}
              >
                <Text
                  textSize={width > 420 ? 14 : 12}
                  cursor="pointer"
                  textColor={
                    loadingState.cargoType === TransportCargoType.Ftl
                      ? "dark"
                      : "light"
                  }
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE_CARGO",
                      payload: TransportCargoType.Ftl,
                    });
                    setDetailsChanged(true);
                  }}
                >
                  Full truck load (FTL)
                </Text>
                <Text
                  textSize={width > 420 ? 14 : 12}
                  cursor="pointer"
                  textColor={
                    !(loadingState.cargoType === TransportCargoType.Ftl)
                      ? "dark"
                      : "light"
                  }
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE_CARGO",
                      payload: TransportCargoType.VolumeWeight,
                    });
                    setDetailsChanged(true);
                  }}
                >
                  Less than truck load (LTL)
                </Text>
              </Div>
              {loadingState.cargoType === TransportCargoType.Ftl ? (
                <Div
                  bg={"gray100"}
                  style={{ borderRadius: "0.5rem" }}
                  p={{ x: "1rem" }}
                  h="100px"
                  d="flex"
                  align="center"
                  justify="center"
                >
                  <Text textColor="semiDark">
                    Your transport can have up to 25,000 kg and 13.6 LTD
                  </Text>
                </Div>
              ) : (
                <>
                  <Div
                    bg={"gray100"}
                    style={{ borderRadius: "0.5rem" }}
                    p={{ xs: "1rem" }}
                  >
                    <Label
                      align="flex-start"
                      textWeight="600"
                      m={{ b: "0.5rem", r: "2rem" }}
                      d="flex"
                      flexWrap="nowrap"
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.VolumeWeight,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.VolumeWeight
                        }
                        name="count"
                      />
                      <Text>
                        Define space requirements by volume and weight
                      </Text>
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.VolumeWeight && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Volume
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={"LDM"}
                            placeholder={"LDM"}
                            value={loadingState.parcels[0].volume}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "volume",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Weight
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={" kg"}
                            placeholder={"kg"}
                            value={loadingState.parcels[0].weight}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "weight",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      align="center"
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.IndustryPallet,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.IndustryPallet
                        }
                        name="count"
                      />
                      I have industry pallets
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.IndustryPallet && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                          >
                            Amount of industry pallets
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={loadingState.parcels[0].quantity}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "quantity",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Weight
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={" kg"}
                            placeholder={"kg"}
                            value={loadingState.parcels[0].weight}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "weight",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      align="center"
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.EurPallet,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.EurPallet
                        }
                        name="count"
                      />
                      I have Euro pallets (EPAL)
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.EurPallet && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                          >
                            Amount of EPAL
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={loadingState.parcels[0].quantity}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "quantity",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Weight
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={" kg"}
                            placeholder={"kg"}
                            value={loadingState.parcels[0].weight}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "weight",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                      align="flex-start"
                      d="flex"
                      flexWrap="nowrap"
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.SizeWeight,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.SizeWeight
                        }
                        name="count"
                      />
                      <Text>Define space requirements by sizes and weight</Text>
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.SizeWeight && (
                      <Div>
                        {loadingState.parcels.map(
                          (parcel: any, index: number) => (
                            <Row
                              key={index}
                              size="15"
                              flexWrap="wrap"
                              pos="relative"
                            >
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Quantity
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  value={parcel.quantity || 1}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "quantity"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "3"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Length
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.length || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "length"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Width
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.width || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "width"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "6"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Height
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.height || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "height"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "3"
                                    : "6"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Weight
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" kg"}
                                  placeholder={"kg"}
                                  value={parcel.weight || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "weight"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Div pos="absolute" bottom="8px" right="-6px">
                                {loadingState.parcels &&
                                loadingState.parcels.length &&
                                loadingState.parcels.length > 1 ? (
                                  <Icon
                                    name="Cross"
                                    m={{ t: "0.8rem" }}
                                    size="14px"
                                    color="semiDark"
                                    cursor="pointer"
                                    onClick={(e: any) => {
                                      dispatch({
                                        type: "HANDLE_CHANGE",
                                        field: "parcels",
                                        payload:
                                          loadingState.parcels.splice(
                                            index,
                                            1
                                          ) && loadingState.parcels,
                                      });
                                    }}
                                  />
                                ) : (
                                  <Div w="14px" />
                                )}
                              </Div>
                            </Row>
                          )
                        )}
                        <Text
                          textColor="primary"
                          textSize={10}
                          textWeight={600}
                          m={{ t: "1rem" }}
                          cursor="pointer"
                          w="fit-content"
                          onClick={(e: any) => {
                            dispatch({
                              type: "HANDLE_CHANGE",
                              field: "parcels",
                              payload: loadingState.parcels.concat([
                                {
                                  weight: null,
                                  volume: null,
                                  quantity: 1,
                                  length: null,
                                  width: null,
                                  height: null,
                                },
                              ]),
                            });
                            setDetailsChanged(true);
                          }}
                        >
                          + ADD PARCEL
                        </Text>
                      </Div>
                    )}
                  </Div>
                </>
              )}
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ y: "1rem" }}
              >
                Other transport requirements
              </Text>
              <Div d="flex">
                <Label
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "dangerous_goods",
                      payload: !loadingState.dangerous_goods,
                    });
                    setDetailsChanged(true);
                  }}
                  align="center"
                  textWeight="600"
                  m={{ b: "1rem" }}
                >
                  <Switch
                    checked={loadingState.dangerous_goods}
                    style={{ boxShadow: "none", overflow: "hidden" }}
                  />
                  <Text textSize={14} textColor="dark" textWeight={500}>
                    Dangerous goods
                  </Text>
                </Label>
              </Div>
              <Div d="flex">
                <Label
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "stackable_goods",
                      payload: !loadingState.stackable_goods,
                    });
                    setDetailsChanged(true);
                  }}
                  align="center"
                  textWeight="600"
                  m={{ b: "1rem" }}
                >
                  <Switch
                    checked={loadingState.stackable_goods}
                    style={{ boxShadow: "none", overflow: "hidden" }}
                  />
                  <Text textSize={14} textWeight={500} textColor="dark">
                    Stackable goods
                  </Text>
                </Label>
              </Div>
            </Collapse>
          </Col>
        )}

        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="0 0 2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({ ...collapse, pricing: !collapse.pricing });
              }}
            >
              <Text textColor="dark" m={{ l: "0.5rem" }} textWeight={500}>
                Order type
              </Text>
              {!collapse.pricing ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              isOpen={collapse.pricing}
              m={{ t: "1rem" }}
              overflow={isTransporterDropdownOpen ? "visible" : "hidden"}
              h={{
                xs: typeChanged && collapse.pricing ? "auto !important" : "0",
              }}
            >
              <Div position="relative" bg="grey" h="1px" w="100%">
                <Div
                  position="absolute"
                  bg="brand600"
                  h="2px"
                  w="50%"
                  transform={`translate(${
                    loadingState.onMarket ? "0%" : "0%"
                  })`}
                  transition
                ></Div>
              </Div>
              <Div
                d="flex"
                justify="start"
                textWeight={600}
                m={{ t: "0.25rem", b: "1.5rem" }}
              >
                {/*<Text
                  textSize={14}
                  cursor="pointer"
                  textColor={loadingState.onMarket ? "dark" : "light"}
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "onMarket",
                      payload: true,
                    });
                    setTypeChanged(true);
                  }}
                >
                  Receive pricing bids
                </Text>*/}
                <Text
                  textSize={14}
                  cursor="pointer"
                  textColor={!loadingState.onMarket ? "dark" : "light"}
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "onMarket",
                      payload: false,
                    });
                    setTypeChanged(true);
                  }}
                >
                  Existing pricing
                </Text>
              </Div>
              {loadingState.onMarket ? (
                <>
                  <Div
                    bg={"gray100"}
                    p={"20px"}
                    style={{ borderRadius: "0.5rem" }}
                    h="100px"
                    d="flex"
                    align="center"
                    justify="center"
                  >
                    <Text textColor="semiDark">
                      Create new order and receive bids from transporters. Most
                      efficient way to reduce your cost and receive great
                      offers.
                    </Text>
                  </Div>

                  <Row size="12">
                    <Col
                      size={
                        (width > 880 || width <= 700) && width > 600 ? "5" : "6"
                      }
                    >
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Order expiry date{" "}
                      </Text>
                      <Input
                        w="100%"
                        placeholder="DD/MM/YYYY"
                        h="2.5rem"
                        name="expiry_date"
                        type="date"
                        value={loadingState.expiry_date}
                        onChange={(e: any) => {
                          dispatch({
                            type: "HANDLE_CHANGE",
                            field: e.target.name,
                            payload: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      size={
                        (width > 880 || width <= 700) && width > 600 ? "3" : "6"
                      }
                    >
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Time
                      </Text>
                      <Input
                        placeholder="16.00"
                        w="100%"
                        h="2.5rem"
                        name="expiry_time"
                        type="time"
                        value={loadingState.expiry_time}
                        onChange={(e: any) => {
                          dispatch({
                            type: "HANDLE_CHANGE",
                            field: e.target.name,
                            payload: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      size={
                        (width > 880 || width <= 700) && width > 600
                          ? "4"
                          : "12"
                      }
                    >
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Timezone
                      </Text>
                      <Dropdown
                        style={{
                          PointerEvent:
                            loadingState.unloadingLocation.timezone ||
                            loadingState.loadingLocation.timezone
                              ? "auto"
                              : "none",
                        }}
                        bg={
                          loadingState.unloadingLocation.timezone ||
                          loadingState.loadingLocation.timezone
                            ? "transparent"
                            : "grey"
                        }
                        h="2.5rem"
                        direction="topright"
                        isOpen={isTimezoneExpiryDropdownOpen}
                        onClick={
                          loadingState.unloadingLocation.timezone ||
                          loadingState.loadingLocation.timezone
                            ? () =>
                                setIsTimezoneExpirtyDropdownOpen(
                                  !isTimezoneExpiryDropdownOpen
                                )
                            : ""
                        }
                        menu={
                          <Div maxH="300px" overflow="visible scroll">
                            <Anchor
                              d="flex"
                              p={{ y: "0.5rem", x: "0.75rem" }}
                              align="center"
                              onClick={() => {
                                dispatch({
                                  type: "HANDLE_CHANGE",
                                  field: "expiry_timezone",
                                  payload:
                                    meData?.getUser.company?.location?.timezone,
                                });
                                setIsTimezoneExpirtyDropdownOpen(false);
                              }}
                            >
                              <Text textColor="semiDark" hoverTextColor="dark">
                                {meData?.getUser.company?.location?.timezone +
                                  " - Local"}
                              </Text>
                            </Anchor>
                            {loadingState.loadingLocation.timezone && (
                              <Anchor
                                d="flex"
                                p={{ y: "0.5rem", x: "0.75rem" }}
                                align="center"
                                onClick={() => {
                                  dispatch({
                                    type: "HANDLE_CHANGE",
                                    field: "expiry_timezone",
                                    payload:
                                      loadingState.loadingLocation.timezone,
                                  });
                                  setIsTimezoneExpirtyDropdownOpen(false);
                                }}
                              >
                                <Text
                                  textColor="semiDark"
                                  hoverTextColor="dark"
                                >
                                  {loadingState.loadingLocation.timezone +
                                    " - Loading"}
                                </Text>
                              </Anchor>
                            )}
                            {loadingState.unloadingLocation.timezone && (
                              <Anchor
                                d="flex"
                                p={{ y: "0.5rem", x: "0.75rem" }}
                                align="center"
                                onClick={() => {
                                  dispatch({
                                    type: "HANDLE_CHANGE",
                                    field: "expiry_timezone",
                                    payload:
                                      loadingState.unloadingLocation.timezone,
                                  });
                                  setIsTimezoneExpirtyDropdownOpen(false);
                                }}
                              >
                                <Text
                                  textColor="semiDark"
                                  hoverTextColor="dark"
                                >
                                  {loadingState.unloadingLocation.timezone +
                                    " - Unloading"}
                                </Text>
                              </Anchor>
                            )}
                          </Div>
                        }
                      >
                        {loadingState.expiry_timezone}
                      </Dropdown>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Div
                    bg={"gray100"}
                    p={"20px"}
                    style={{ borderRadius: "0.5rem" }}
                    minH="100px"
                    d="flex"
                    align="center"
                    justify="center"
                  >
                    <Text textColor="semiDark">
                      Create new order for which you already have a pricing rate
                      with existing transporter. If transporter is not on
                      Cargobite, they will be invited to join.
                    </Text>
                  </Div>
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Transporter company
                  </Text>

                  <InputDropdown
                    page={searchTransporterPage}
                    setPage={setSearchTransporterPage}
                    totalPages={Math.ceil(
                      companiesTransporterData?.getCompanies?.total / 5
                    )}
                    addNew={
                      !(
                        (loadingState.shipper.id !== null ||
                          loadingState.receiver.id !== null) &&
                        (loadingState.shipper.status ===
                          CompanyStatus.Placeholder ||
                          loadingState.shipper.status ===
                            CompanyStatus.PlaceholderWaitingConfirm ||
                          loadingState.receiver.status ===
                            CompanyStatus.Placeholder ||
                          loadingState.receiver.status ===
                            CompanyStatus.PlaceholderWaitingConfirm)
                      )
                    }
                    modal={{
                      msg: "Create Company",
                      name: "createPlaceholderTransporterCompany",
                      returned_value: null,
                      props: null,
                    }}
                    isLoading={loadingTransporterCompanies}
                    displayResult={
                      loadingState.transporter.id !== null ? (
                        <Div
                          d="flex"
                          h="24px"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            WebkitLineClamp: "1",
                            width: "100%",
                          }}
                        >
                          <PreloadingImage
                            imageSrc={loadingState.transporter.logoPath}
                            skeletonComponent={
                              <Skeleton
                                height={"24px"}
                                width={"24px"}
                                borderRadius={"24px"}
                              />
                            }
                            imgComponent={
                              <ShortNameLogo
                                size="24px"
                                name={loadingState.transporter.name}
                                textSize={12}
                              />
                            }
                            imageStyle={{
                              minWidth: "24px",
                              minHeight: "24px",
                              width: "24px",
                              height: "24px",
                              borderRadius: "24px",
                              verticalAlign: "middle",
                            }}
                          />
                          <Div
                            style={{
                              width: "calc(100% - 70px)",
                            }}
                          >
                            <Text
                              textColor="semiDark"
                              hoverTextColor="dark"
                              textWeight="500"
                              m={{ l: "0.5rem" }}
                              style={{
                                textOverflow: "ellipsis",
                                overflowX: "hidden",
                              }}
                            >
                              {loadingState.transporter.name}
                            </Text>
                          </Div>
                          {loadingState.transporter.status ===
                            CompanyStatus.Active && (
                            <Icon
                              m={{ l: "0.5rem" }}
                              name="Checked"
                              size="20px"
                              color="success"
                            />
                          )}
                        </Div>
                      ) : (
                        <div></div>
                      )
                    }
                    isOpen={isTransporterDropdownOpen}
                    setIsOpen={setIsTransporterDropdownOpen}
                    value={searchTransporter}
                    handleChange={setSearchTransporter}
                    menuList={companiesTransporterData?.getCompanies.items?.map(
                      (item: any, i: number) => (
                        <Anchor
                          d="flex"
                          p={{ y: "0.5rem", x: "0.75rem" }}
                          key={i}
                          align="center"
                          onClick={async () => {
                            dispatch({
                              type: "HANDLE_CHANGE_TRANSPORTER",
                              field: "transporter",
                              payload: { ...item },
                            });
                            setSearchTransporter("");
                            setIsTransporterDropdownOpen(false);
                          }}
                        >
                          <PreloadingImage
                            imageSrc={item.logoPath}
                            skeletonComponent={
                              <Skeleton
                                height={"24px"}
                                width={"24px"}
                                borderRadius={"24px"}
                              />
                            }
                            imgComponent={
                              item.name !== undefined ? (
                                <ShortNameLogo
                                  size="24px"
                                  name={item?.name}
                                  textSize={12}
                                />
                              ) : (
                                <></>
                              )
                            }
                            imageStyle={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "24px",
                              verticalAlign: "middle",
                            }}
                          />
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            m={{ l: "10px" }}
                          >
                            {" "}
                            {item.name}{" "}
                          </Text>
                          {item.status === CompanyStatus.Active && (
                            <Icon
                              m={{ l: "auto" }}
                              name="Checked"
                              size="20px"
                              color="success"
                            />
                          )}
                        </Anchor>
                      )
                    )}
                  />

                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "1rem" }}
                  >
                    Price
                  </Text>
                  <NumberFormat
                    thousandSeparator={true}
                    suffix={" €"}
                    placeholder={"€"}
                    value={loadingState.price}
                    onValueChange={(values) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: "price",
                        payload: values.value,
                      });
                    }}
                    customInput={Input}
                  />
                </>
              )}
            </Collapse>
          </Col>
        )}
        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="0 0 2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({
                  ...collapse,
                  description: !collapse.description,
                });
              }}
            >
              <Text textColor="dark" m={{ l: "0.5rem" }} textWeight={500}>
                Description
              </Text>
              {!collapse.description ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              isOpen={collapse.description}
              m={{ t: "1rem" }}
              h={{ xs: collapse.description && "auto!important" }}
            >
              <Row>
                <Col>
                  <Textarea
                    placeholder="Enter any notes you’d like to include in your order..."
                    value={loadingState.description}
                    name="description"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      dispatch({
                        type: "HANDLE_CHANGE",
                        field: e.target.name,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Collapse>
          </Col>
        )}
        <Col>
          <PrimaryBtn
            w="100%"
            handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
              handleSubmit(e)
            }
            styleBtn={{
              marginTop: "2rem",
              marginBottom: height > 1290 ? "2rem" : "10rem",
            }}
            isLoading={
              createLoading || updateOrderLoading || updateTransportLoading
            }
            disabled={
              createLoading ||
              !loadingState.shipper.id ||
              !loadingState.loadingLocation.id ||
              !loadingState.receiver.id ||
              !loadingState.unloadingLocation.id
            }
            text={"Submit"}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateOrder;
