export const mapDriverOptions = (data: any[]) => {
  return data?.map((item) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: item?.id ?? "",
    key: item?.id ?? "",
  }));
};

export const mapCompanyOptions = (data: any[]) => {
  return data?.map((item) => ({
    label: item?.name ?? "",
    value: item?.id ?? "",
    key: item?.id ?? "",
  }));
};

export const mapVehiclesOptions = (data: any[]) => {
  return data?.map((item) => ({
    label: item?.registration ?? "",
    value: item?.registration ?? "",
    key: item?.id ?? "",
  }));
};
