export const config = {
  api: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://api-staging.cargobite.com/gql",
  app_site_url: process.env.REACT_APP_URL
    ? process.env.REACT_APP_URL
    : "http://localhost:3000",
  static_site_url: process.env.REACT_APP_STATIC_URL
    ? process.env.REACT_APP_STATIC_URL
    : "http://localhost:3001",
  ws: process.env.REACT_APP_API_URL_WS
    ? process.env.REACT_APP_API_URL_WS
    : "wss://api-staging.cargobite.com/subscription",
};
