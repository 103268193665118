import React, { useEffect, useState } from "react";
import { Text, Icon, Div, Row, Col, Input, Tag } from "atomize";
import Table from "../../components/TableComponents/Table";
import Th from "../../components/TableComponents/Th";
import Tr from "../../components/TableComponents/Tr";
import Thead from "../../components/TableComponents/Thead";
import Tbody from "../../components/TableComponents/Tbody";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  CompanyStatus,
  CompanyType,
  useGetMeQuery,
  useGetVehiclesQuery,
} from "../../generated/graphql";
import { initialMyVehiclesPagination } from "../../helpers/initialData";
import Pagination from "../../components/Pagination/Pagination";
import Td from "../../components/TableComponents/Td";
import { useHistory } from "react-router";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import { modalData } from "../../App";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import moment from "moment";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import renderIcon from "../../assets/Icons";
import VehicleRowActions from "../../components/Tables/VehicleRowActions";

const SettingsDrivers: React.FC = () => {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const { height, width } = useWindowDimensions();

  const [searchInput, setSearchInput] = useState("");
  const [vehiclesPag, setVehiclesPag] = useState<any>({
    ...initialMyVehiclesPagination,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });

  const { data: me, loading: isLoadingMe } = useGetMeQuery({
    onCompleted: (me) => {
      if (
        me?.getUser.company?.status === CompanyStatus.WaitingConfirm ||
        me?.getUser.company?.type !== CompanyType.Transporter ||
        (me?.getUser.roles![0].id !== 2 &&
          me?.getUser.roles![0].id !== 1 &&
          me?.getUser.roles![0].id !== 6)
      ) {
        history.push("/settings/individual");
      }
    },
  });

  const {
    data: vehicles,
    loading: vehiclesLoading,
    error: vehiclesError,
  } = useGetVehiclesQuery({
    variables: {
      query: { ...vehiclesPag, company_id: me?.getUser.company?.id },
    },
    fetchPolicy: "network-only",
    skip: !me,
    onCompleted: (data) => {
      if (data?.getVehicles?.total)
        setTotalPages(Math.ceil(data?.getVehicles?.total / vehiclesPag.limit));
    },
  });

  useDebouncedEffect(
    () =>
      setVehiclesPag({
        ...vehiclesPag,
        search: searchInput,
      }),
    [searchInput],
    searchInput === "" ? 0 : 300
  );

  ErrorsBeHandler({
    error: vehiclesError,
  });

  useEffect(() => {
    if (me) {
      setVehiclesPag({
        ...vehiclesPag,
        company_id: Number(me.getUser.company?.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <Row m={{ x: "0rem" }}>
      <Col>
        <Div d="flex" h="72px" overflow="hidden" align="center">
          <Div w="100%">
            <Input
              h="32px"
              w="100%"
              p={{ x: "2rem" }}
              borderColor="greyBorder"
              textSize="12"
              placeholder="Search..."
              value={searchInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchInput(e.target.value)
              }
              prefix={
                <Icon
                  name="Search"
                  size="16px"
                  cursor="pointer"
                  pos="absolute"
                  color="semiDark"
                  top="50%"
                  left="0.5rem"
                  transform="translateY(-50%)"
                />
              }
            />
          </Div>
          <Div d="flex" justify="flex-end">
            {vehiclesLoading ? (
              <Skeleton height="2rem" width="120px" margin="0 0  0 0.5rem" />
            ) : (
              <SecondaryBtn
                w="120px"
                h="2rem"
                handleSubmit={() =>
                  modalData({
                    msg: "Create Vehicle",
                    name: "createVehicle",
                    returned_value: null,
                    props: { company_id: me?.getUser.company!.id },
                  })
                }
                prefixIcon={
                  <Icon
                    m={{ r: "6px" }}
                    name="Add"
                    size="18px"
                    color="semiDark"
                  />
                }
                isLoading={false}
                styleBtn={{
                  display: "flex",
                  justifyContent: "left",
                  margin: "0 0 0 0.5rem",
                }}
                text={"Add vehicle"}
                textSize={12}
              />
            )}
          </Div>
        </Div>
        {isLoadingMe || vehiclesLoading ? (
          <>
            <Skeleton
              classN="table_skeleton"
              height={`${49 * vehiclesPag.limit + 60}px`}
            />
          </>
        ) : vehicles?.getVehicles.total &&
          Number(vehicles?.getVehicles!.total) > 0 ? (
          <Table
            width={`calc(100% -  ${
              isLoadingMe || vehiclesLoading ? "0" : "0"
            })`}
          >
            <tbody>
              <Tr hidBorderLast={true} className="border_none">
                <Td width={"100%"} className="divider">
                  <Table>
                    <Thead>
                      <Tr className="head">
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            License plate
                          </Text>
                        </Th>
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Driver
                          </Text>
                        </Th>
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Weight limit
                          </Text>
                        </Th>
                        <Th width={"200px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Volume limit
                          </Text>
                        </Th>
                        <Th width={"200px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Comment
                          </Text>
                        </Th>
                        <Th width={"150px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Date joined
                          </Text>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {vehicles?.getVehicles?.items &&
                        me?.getUser &&
                        vehicles?.getVehicles?.items!.map(
                          (vehicle: any, i: number) => (
                            <Tr>
                              <Td width={"100px"}>
                                <Text textColor={"dark"}>
                                  {vehicle?.registration}
                                </Text>
                              </Td>
                              <Td width={"100px"}>
                                <Text
                                  textColor={
                                    vehicle.driverUser_id ? "dark" : "light"
                                  }
                                >
                                  {vehicle?.driverUser_id
                                    ? `${vehicle?.driver.firstName} ${vehicle?.driver.lastName}`
                                    : "none"}
                                </Text>
                              </Td>
                              <Td width={"100px"}>
                                <Text textColor={"light"}>
                                  {vehicle?.maxCargoVolume} kg
                                </Text>
                              </Td>
                              <Td width={"200px"}>
                                <Text textColor={"light"}>
                                  {vehicle?.maxCargoWeight} LTD
                                </Text>
                              </Td>
                              <Td width={"200px"}>
                                <Text
                                  textColor={
                                    vehicle.description ? "dark" : "light"
                                  }
                                >
                                  {vehicle.description
                                    ? vehicle?.description
                                    : "None"}
                                </Text>
                              </Td>
                              <Td width={"150px"}>
                                <Text textColor={"light"} textSize={142}>
                                  {moment(vehicle?.createTime).format(
                                    "DD/MMM/YYYY HH:mm"
                                  )}
                                </Text>
                              </Td>
                            </Tr>
                          )
                        )}
                    </Tbody>
                  </Table>
                </Td>
                <Td width="40px" className="divider_right no_p">
                  <Table width="auto">
                    <Thead>
                      <Tr>
                        <Td className="head"></Td>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {vehicles?.getVehicles.items?.map(
                        (vehicle: any, i: number) => (
                          <VehicleRowActions vehicle={vehicle} key={i} />
                        )
                      )}
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            </tbody>
          </Table>
        ) : null}
        {vehicles?.getVehicles.total === 0 && !vehiclesLoading && (
          <>
            {searchInput ? (
              <Row m={{ x: "0rem", t: "0rem" }}>
                <Col>
                  <Tag
                    p={{ l: "1rem" }}
                    h="48px"
                    w="100%"
                    d="flex"
                    justify="start"
                    textColor="light"
                  >
                    We couldn’t find any driver related to {vehiclesPag.search}.
                  </Tag>
                </Col>
              </Row>
            ) : (
              <Row
                maxW="440px"
                h={`${height - 162}px`}
                align="center"
                p="32px 0px"
                d="flex"
                m={{ x: "auto" }}
              >
                <Col d="flex" flexDir="column" align="center">
                  <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                    {renderIcon("AddOrder")}
                  </Div>
                  <Text
                    textColor="dark"
                    textWeight="500"
                    textSize={16}
                    textAlign="center"
                  >
                    {" "}
                    You have not made any Drivers.
                  </Text>

                  {me?.getUser.company?.status === CompanyStatus.Active && (
                    <PrimaryBtn
                      w="100%"
                      handleSubmit={(
                        e: React.ChangeEvent<HTMLButtonElement>
                      ) => {
                        e.preventDefault();
                        // modalData({
                        //   msg: "Invite vehicle",
                        //   name: "inviteUser",
                        //   returned_value: null,
                        //   props: {
                        //     company_id: me?.getUser.company?.id,
                        //     vehicle: me?.getUser,
                        //   },
                        // });
                      }}
                      styleBtn={{ marginTop: "1.5rem" }}
                      isLoading={false}
                      text={"Invite driver"}
                    />
                  )}
                </Col>
              </Row>
            )}
          </>
        )}
        {totalPages ? (
          <Row m={{ x: "0" }}>
            <Col>
              <Pagination
                setQuery={setVehiclesPag}
                query={vehiclesPag}
                total={totalPages}
              />
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default SettingsDrivers;
