import React, { useEffect, useState } from "react";
import { Text, Icon, Div, Row, Col, Anchor } from "atomize";
import Table from "../../components/TableComponents/Table";
import Th from "../../components/TableComponents/Th";
import Tr from "../../components/TableComponents/Tr";
import Thead from "../../components/TableComponents/Thead";
import Tbody from "../../components/TableComponents/Tbody";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  Company,
  CompanyStatus,
  useGetCompaniesLazyQuery,
  useGetMeQuery,
  useGetUsersQuery,
} from "../../generated/graphql";
import { initialGetUsers } from "../../helpers/initialData";
import Pagination from "../../components/Pagination/Pagination";
import UsersRow from "../../components/Tables/UsersRow";
import Td from "../../components/TableComponents/Td";
import UsersRowActions from "../../components/Tables/UsersRowActions";
import { useSearch } from "../../helpers/CustomHooks/useSearch";
import InputDropdown from "../../components/InputDropdown/InputDropdown";
import PreloadingImage from "../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../components/ShortNameLogo/ShortNameLogo";
import { useHistory } from "react-router";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import { modalData } from "../../App";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";

interface selectedProps {
  id: null | number;
  logoPath: string;
  name: string;
  status: CompanyStatus;
}

const SettingsTeam: React.FC = () => {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const { height, width } = useWindowDimensions();

  const [selectedCompany, setSelectedCompany] = useState<selectedProps | null>(
    null
  );
  const [usersPag, setUsersPag] = useState<any>({
    ...initialGetUsers,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });

  const { data: me, loading: isLoadingMe } = useGetMeQuery({
    onCompleted: (me) => {
      if (
        me?.getUser.company?.status === CompanyStatus.WaitingConfirm ||
        (me?.getUser.roles![0].id !== 2 &&
          me?.getUser.roles![0].id !== 1 &&
          me?.getUser.roles![0].id !== 6)
      ) {
        history.push("/settings/individual");
      }
    },
  });

  const {
    data: users,
    loading: usersLoading,
    error: usersError,
    refetch,
  } = useGetUsersQuery({
    variables: { query: { ...usersPag, company_id: me?.getUser.company?.id } },
    fetchPolicy: "network-only",
    skip: !me,
    onCompleted: (data) => {
      if (data?.getUsers?.total)
        setTotalPages(Math.ceil(data?.getUsers?.total / usersPag.limit));
    },
  });

  ErrorsBeHandler({
    error: usersError,
  });

  const queryCompany = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] =
    useState<boolean>(false);
  const [queryCompanyStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.Active,
    CompanyStatus.Incomplete,
    CompanyStatus.WaitingConfirm,
    CompanyStatus.PlaceholderWaitingConfirm,
  ]);
  const {
    data: companiesData,
    loading: loadingCompanies,
    search: searchCompany,
    setSearch: setSearchCompany,
    page: searchCompanyPage,
    setPage: setSearchCompanyPage,
  } = useSearch(
    queryCompany,
    { limit: 5, status: queryCompanyStatus },
    300,
    me?.getUser.roles![0].id !== 2 && me?.getUser.roles![0].id !== 1,
    queryCompanyStatus
  );

  useEffect(() => {
    if (me) {
      setUsersPag({
        ...usersPag,
        company_id: Number(me.getUser.company?.id),
      });
      setSelectedCompany({
        id: Number(me.getUser.company?.id),
        logoPath: me.getUser.company?.logoPath
          ? me.getUser.company?.logoPath
          : "",
        name: me?.getUser?.company!.name,
        status: me.getUser.company!.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <Row m={{ x: "0rem" }}>
      <Col>
        <Div d="flex" justify="space-between" m={{ y: "1rem" }}>
          <Div w="300px">
            {me?.getUser.roles![0].id === 1 &&
              (isLoadingMe || usersLoading ? (
                <Skeleton height="40px" width="100%" />
              ) : (
                <InputDropdown
                  page={searchCompanyPage}
                  setPage={setSearchCompanyPage}
                  totalPages={Math.ceil(companiesData?.getCompanies?.total / 5)}
                  addNew={false}
                  modal={{
                    msg: "Create Company",
                    name: "createPlaceholderShipperCompany",
                    returned_value: null,
                    props: null,
                  }}
                  isLoading={loadingCompanies}
                  displayResult={
                    selectedCompany !== null ? (
                      <Div d="flex" h="24px" align="center">
                        <PreloadingImage
                          imageSrc={selectedCompany?.logoPath}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={selectedCompany?.name}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          textWeight="500"
                          m={{ l: "0.5rem" }}
                        >
                          {selectedCompany.name}
                        </Text>
                        {selectedCompany.status === CompanyStatus.Active && (
                          <Icon
                            m={{ l: "0.5rem" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Div>
                    ) : (
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        textWeight="500"
                        m={{ l: "0.5rem" }}
                      >
                        Select company
                      </Text>
                    )
                  }
                  isOpen={isCompanyDropdownOpen}
                  setIsOpen={setIsCompanyDropdownOpen}
                  value={searchCompany}
                  handleChange={setSearchCompany}
                  menuList={companiesData?.getCompanies.items?.map(
                    (item: Company, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={async () => {
                          setSelectedCompany({
                            id: item.id,
                            logoPath: item.logoPath!,
                            name: item.name,
                            status: item.status,
                          });
                          setUsersPag({
                            ...usersPag,
                            company_id: Number(item.id),
                          });
                          setSearchCompany("");
                          setIsCompanyDropdownOpen(false);
                        }}
                      >
                        <PreloadingImage
                          imageSrc={item.logoPath!}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={item.name}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          m={{ l: "10px" }}
                        >
                          {" "}
                          {item.name}{" "}
                        </Text>
                        {item.status === CompanyStatus.Active && (
                          <Icon
                            m={{ l: "auto" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Anchor>
                    )
                  )}
                />
              ))}
          </Div>
          <Div d="flex">
            {isLoadingMe || usersLoading ? (
              <Skeleton height="2rem" width="40px" margin="0 0  0 0.5rem" />
            ) : (
              <SecondaryBtn
                w="40px"
                h="2rem"
                handleSubmit={() => refetch()}
                styleBtn={{ marginLeft: "0.5rem" }}
                isLoading={false}
                text={<Icon name="Refresh" size="16px" color="semiDark" />}
              />
            )}
            {isLoadingMe || usersLoading ? (
              <Skeleton height="2rem" width="120px" margin="0 0  0 0.5rem" />
            ) : (
              me?.getUser.roles![0].id !== 1 && (
                <SecondaryBtn
                  prefixIcon={
                    <Icon
                      name="Edit"
                      size="16px"
                      color="semiDark"
                      m={{ r: "0.4rem" }}
                    />
                  }
                  w="120px"
                  textSize={12}
                  h="2rem"
                  handleSubmit={() =>
                    modalData({
                      msg: "Invite user",
                      name: "inviteUser",
                      returned_value: null,
                      props: {
                        company_id: selectedCompany?.id,
                        user: me?.getUser,
                      },
                    })
                  }
                  styleBtn={{ marginLeft: "0.5rem" }}
                  isLoading={false}
                  text={"Invite user"}
                />
              )
            )}
          </Div>
        </Div>
        {!(isLoadingMe || usersLoading) ? (
          <Table
            width={`calc(100% -  ${isLoadingMe || usersLoading ? "0" : "0"})`}
          >
            <tbody>
              <Tr hidBorderLast={true} className="border_none">
                <Td width={"100%"} className="divider">
                  <Table>
                    <Thead>
                      <Tr className="head">
                        <Th width={"150px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                            m={{ l: "1rem" }}
                          >
                            Roles
                          </Text>
                        </Th>
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            First name
                          </Text>
                        </Th>
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Last name
                          </Text>
                        </Th>
                        <Th width={"200px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Email
                          </Text>
                        </Th>
                        <Th width={"120px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Phone
                          </Text>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {users?.getUsers?.items &&
                        me?.getUser &&
                        users?.getUsers?.items!.map((user: any, i: number) => (
                          <UsersRow user={user} key={i} me={me.getUser} />
                        ))}
                    </Tbody>
                  </Table>
                </Td>
                <Td width="40px" className="divider_right no_p">
                  <Table width="auto">
                    <Thead>
                      <Tr>
                        <Td className="head"></Td>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {users?.getUsers?.items?.map((user: any, i: number) => (
                        <UsersRowActions user={user} key={i} me={me} />
                      ))}
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            </tbody>
          </Table>
        ) : (
          <Skeleton
            classN="table_skeleton"
            height={`${49 * usersPag.limit + 60}px`}
          />
        )}
        {totalPages && (
          <Pagination
            setQuery={setUsersPag}
            query={usersPag}
            total={totalPages}
          />
        )}
      </Col>
    </Row>
  );
};

export default SettingsTeam;
