import React from "react";
import * as Styled from "./Section.styles";

interface SectionProps {
  margin?: string;
  overflowDir?: "v" | "h";
  minWidth?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  margin,
  overflowDir,
  minWidth,
}) => {
  return (
    <Styled.Section
      margin={margin}
      overflowDir={overflowDir}
      innerWidth={minWidth}
    >
      <div className="section_inner">{children}</div>
    </Styled.Section>
  );
};

export default Section;
