import * as yup from "yup";

export const existingItemSchema = yup.object().shape({
  locationPoints: yup.object().shape({
    order_id: yup
      .number()
      .required({ name: "Order ID", code: "REQUIRED_FIELD" }),
    transport_id: yup
      .number()
      .required({ name: "Transport ID", code: "REQUIRED_FIELD" }),
    locationPoints: yup.array().of(
      yup.object().shape({
        loadingDate: yup
          .string()
          .required({ name: "Loading Date & Time", code: "DATA_NOT_SELECTED" }),
        location_id: yup
          .number()
          .required({ name: "Location", code: "DATA_NOT_SELECTED" }),
        note: yup.string().nullable(),
        order: yup.number().required({ name: "Order", code: "REQUIRED_FIELD" }),
      })
    ),
  }),
});

export const newItemSchema = yup.object().shape({
  location: yup.object().shape({
    company_id: yup
      .number()
      .nullable()
      .required({ name: "Company", code: "DATA_NOT_SELECTED" }),
    address: yup
      .string()
      .trim()
      .required({ name: "Address", code: "REQUIRED_FIELD" }),
    postCode: yup
      .string()
      .trim()
      .required({ name: "Post code", code: "REQUIRED_FIELD" }),
    postName: yup
      .string()
      .trim()
      .required({ name: "Post name", code: "REQUIRED_FIELD" }),
    country_id: yup
      .number()
      .required({ name: "Country", code: "DATA_NOT_SELECTED" }),
    loadingDate: yup
      .string()
      .required({ name: "Loading Date & Time", code: "DATA_NOT_SELECTED" }),
  }),
});
