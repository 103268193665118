import { Anchor, Col, Div, Text } from "atomize";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { modalData } from "../../App";
import renderIcon from "../../assets/Icons";
import Border from "../../components/Border/Border";
import { GlobalTheme } from "../../components/GlobalTheme";
import {
  GetMeQuery,
  NotificationListingItem,
  NotificationStatus,
  NotificationType,
} from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { parseJSON, userHasModule } from "../../helpers/functions";
import NotiContent from "./NotiContent";
import { notificationsIcon } from "./utils/helpers";
import { ModuleName } from "../../enums";

const Notification: React.FC<{
  noti: NotificationListingItem;
  date: boolean;
  myCompanyId: number | undefined;
  me: GetMeQuery | undefined;
}> = ({ noti, date, myCompanyId, me }) => {
  const [hover, setHover] = useState(false);
  const { width } = useWindowDimensions();

  const history = useHistory();
  const orderCode =
    noti && parseJSON(noti.data, {}).order_code
      ? parseJSON(noti.data).order_code
      : parseJSON(noti.data).orderCode;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const userHasDocumentInvoicingModule = userHasModule(
    me,
    ModuleName.DOCUMENTS_AND_INVOICING
  );

  if (
    noti.type === NotificationType.Invoice &&
    !userHasDocumentInvoicingModule
  ) {
    return null;
  }

  return (
    <Col
      w="100%"
      size="12"
      flexWrap="wrap"
      style={{ opacity: !isLoading ? 1 : 0, transition: "opacity 0.2s " }}
    >
      <>
        {date && (
          <Div>
            <Border margin="0" color={GlobalTheme.colors.greyBorder}></Border>
            <Div
              d="flex"
              justify="flex-start"
              h="24px"
              textColor="light"
              m={{ b: "1rem" }}
            >
              {moment(noti.date).fromNow()}
            </Div>
          </Div>
        )}
        <Div
          d={"flex"}
          align="center"
          onClick={() => {
            noti.type === NotificationType.Custom
              ? modalData({
                  msg: "Reminder",
                  name: "readMoreTd",
                  returned_value: null,
                  props: { children: noti.text },
                })
              : history.push(`/myorder/${orderCode}`);
          }}
          w="calc(100% + 1rem)"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          bg={
            noti.status !== NotificationStatus.Read && GlobalTheme.colors.grey
          }
          rounded="4"
          transition
          cursor="pointer"
          m={{ x: "-0.5rem", b: "0.5rem" }}
        >
          <Div m={{ t: "8px", r: "16px", l: "0.5rem" }}>
            {renderIcon(
              notificationsIcon(noti?.type as NotificationType, noti.data)
            )}
          </Div>
          <Div d="flex" justify={"flex-start"} align="top" w="100%">
            <Div textWeight="400" d="flex" w="100%">
              <NotiContent
                noti={noti}
                orderCode={orderCode}
                myCompanyId={myCompanyId!}
                dateEl={
                  width <= 1100 ? (
                    <Div m={{ l: "0rem" }} d={"flex"} align="center" transition>
                      <Anchor
                        m={{ r: "0.5rem" }}
                        textColor="light"
                        textWeight="400"
                        d="flex"
                        align="center"
                      >
                        <Text
                          d={{ md: "none", lg: "inline-block" }}
                          m={{ r: "0.5rem" }}
                        >
                          View
                        </Text>
                        {renderIcon("ViewPointer")}
                      </Anchor>
                      <Text
                        m={{ l: "20px", r: "0.5rem" }}
                        textColor="light"
                        textWeight="400"
                      >
                        {moment(noti.date).format("HH:mm")}{" "}
                      </Text>
                    </Div>
                  ) : (
                    <></>
                  )
                }
              />
              {width > 1100 && (
                <Div
                  m={{ l: "auto" }}
                  d={"flex"}
                  align="center"
                  transition
                  style={{ opacity: hover ? 1 : 0 }}
                >
                  <Anchor
                    m={{ r: "0.5rem" }}
                    textColor="light"
                    textWeight="400"
                    d="flex"
                    align="center"
                  >
                    <Text
                      d={{ md: "none", lg: "inline-block" }}
                      m={{ r: "0.5rem" }}
                    >
                      View
                    </Text>
                    {renderIcon("ViewPointer")}
                  </Anchor>
                  <Text
                    m={{ l: "20px", r: "0.5rem" }}
                    textColor="light"
                    textWeight="400"
                  >
                    {moment(noti.date).format("HH:mm")}{" "}
                  </Text>
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      </>
    </Col>
    // </Suspense>
  );
};

export default Notification;
