import styled from "styled-components";
import { GlobalTheme } from "../../components/GlobalTheme";

export const Sidebar = styled.div`
  z-index: 1000;
  display: block;
  flex: 0 0 240px;
  @media only screen and (max-width: 700px) {
    flex: 0 0 0;
  }
`;

interface Props {
  isOpen: boolean;
}

export const SidebarFixed = styled.div<Props>`
  background: ${(props) => props.theme.colors.grey};
  height: 100%;
  width: 240px;
  position: fixed;
  top: 0;
  bottom: 0;
  transform: translateY(0%);
  @media only screen and (max-width: 700px) {
    transition: all 0.3s ease-in-out;
    transform: translateY(
      ${(props) => (props.isOpen ? "calc(3rem)" : "calc(100% + 3rem )")}
    );
    width: 100%;
    height: calc(100% - 3rem);
  }

  scrollbar-width: thin;
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralGrey};
    width: 5px;
    border-radius: ${(props) => props.theme.rounded[2]};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 100%;
    height: 10px;
    background: ${(props) => props.theme.colors.grey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.semiLight};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }
`;

interface PropsCnt {
  isOpen: boolean;
  isOpenSettings: boolean;
}

export const SidebarContainer = styled.div<PropsCnt>`
  height: 100%;
  width: 100%;
  position: relative;
  .report_bug {
    opacity: 0.75;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 1;
    }
  }

  .link_icons {
    svg {
      vertical-align: middle;
      path {
        fill: ${GlobalTheme.colors.semiDark};
      }
    }
    &.link_active_icons {
      svg {
        vertical-align: middle;
        path {
          fill: ${GlobalTheme.colors.primary};
        }
      }
    }

    &.disabled {
      svg {
        path {
          fill: ${GlobalTheme.colors.semiLight};
        }
      }
    }
  }

  .setting_sidebar {
    width: 100%;
    transition: all ease-in-out 0.2s;
    transform: translateX(${(props) => (props.isOpen ? "0%" : "100%")});
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    z-index: 1002;
  }

  .top_side {
    transition: all ease-in-out 0.2s;
    opacity: ${(props) => (props.isOpenSettings ? "0" : "1")};
    transform: translateX(
      ${(props) => (props.isOpenSettings ? "-100%" : "0%")}
    );
  }

  .bottom_side {
    transition: all ease-in-out 0.2s;
    opacity: ${(props) => (props.isOpenSettings ? "0" : "1")};
    transform: translateX(
      ${(props) => (props.isOpenSettings ? "-100%" : "0%")}
    );
  }
`;

export const SidebarLogo = styled.div`
  display: flex;
  align: center;
  margin-bottom: 0.5rem;
  svg {
    width: 140px;
  }
`;

export const SidebarLink = styled.div`
  height: 28px;
  & .logout {
    color: ${(props) => props.theme.colors.semiDark};
    opacity: 1;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    transition: opacity ease-in-out 0.3s;
    &:hover {
      opacity: 0.75;
    }
  }
  & > div > svg {
    opacity: 1;
    transition: opacity ease-in-out 0.3s;
  }
  &:hover > div > svg {
    opacity: 0.75;
  }
  &.disabled {
    pointer-events: none;
    a {
      p {
        color: ${(props) => props.theme.colors.semiLight};
      }
    }
  }
  a {
    height: 100%;
    display: flex;
    .active_border {
      left: 0;
      top: 0;
      position: absolute;
      width: 0px;
      height: 100%;
      background: ${(props) => props.theme.colors.brand600};
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
    p {
      color: ${(props) => props.theme.colors.semiDark};
      opacity: 1;
      transition: all ease-in-out 0.3s;
      &:hover {
        opacity: 0.75;
      }
    }
    &.link_active {
      position: relative;
      p {
        color: ${(props) => props.theme.colors.dark};
        &:hover {
          opacity: 0.75;
        }
      }
      .active_border {
        left: -16px;
        opacity: 100%;
        width: 2px;
      }
    }
  }
  margin-top: 0.5rem;
`;

export const ClientBadge = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.neutralGrey};
  border-radius: ${(props) => props.theme.rounded[6]};
  padding: 0 8px;
  height: 48px;
  margin-top: 16px;
  cursor: pointer;
  transition: 0.3s ease-in-out opacity;
  &:hover {
    opacity: 0.8;
  }
`;

export const SidebarDropdown = styled.div`
  margin-top: 0.5rem;
  user-select: none;
  & .main_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
    margin: 0 -0.5rem;
    background-color: rgba(78, 93, 120, 0);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    & svg {
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
    & p {
      color: ${(props) => props.theme.colors.semiDark};
      transition: opacity 0.3s ease-in-out;
    }
    &.link_active {
      background-color: ${(props) => props.theme.colors.greyBorder};
      & p {
        color: ${(props) => props.theme.colors.dark};
      }
    }
    :hover {
      & p, & svg {
        opacity: 0.75;
      }
    }
  }
  & .link_active {
    & svg {
      transform: rotate(180deg);
      path {
        fill: ${(props) => props.theme.colors.dark};
      }
    }
  }
  & a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      & p, & svg {
        opacity: 0.75;
      }
    }
    p {
      color: ${(props) => props.theme.colors.semiDark};
      transition: all ease-in-out 0.3s;
    }
    &.link_active {
      p {
        color: ${(props) => props.theme.colors.dark};
      }
      &:before {
        left: -10px;
        opacity: 1;
        width: 2px;
      }
    }
    &:before {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      width: 0px;
      height: 100%;
      background: ${(props) => props.theme.colors.brand600};
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }
  }
`;
