import React, { useEffect, useState } from "react";
import { Row, Col, Text, Input, Div, Anchor } from "atomize";
import PrefixDropdown from "../../PrefixDropdown/PrefixDropdown";
import { Country, useGetCountriesLazyQuery } from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";

const CreatePlaceholderUser: React.FC<{
  setUser: (user: any) => any;
  user: any;
  handleSubmit: (e: React.ChangeEvent<HTMLButtonElement>) => Promise<void>;
  isLoading: boolean;
  setStep: (step: number) => void;
  step: number;
  companyEmail: string;
}> = ({
  setUser,
  user,
  handleSubmit,
  isLoading,
  setStep,
  step,
  companyEmail,
}) => {
  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, isCountryDropOpen);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    if (step === 3 && user.email === "") {
      setUser({
        ...user,
        email: companyEmail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <Row d="column">
      <>
        <Col size="12" type="text">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            First name
          </Text>
          <Input
            placeholder="First name"
            name="firstName"
            textSize={"16"}
            value={user?.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            h="3rem"
            m={{ b: "0.5rem" }}
          />
        </Col>

        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Last name
          </Text>
          <Input
            placeholder="Last name"
            name="lastName"
            textSize={"16"}
            value={user?.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            h="3rem"
            m={{ b: "0.5rem" }}
          />
        </Col>

        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Email
          </Text>
          <Input
            placeholder="Email"
            name="email"
            textSize={"16"}
            value={user?.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            h="3rem"
            m={{ b: "0.5rem" }}
          />
        </Col>
        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Phone
          </Text>
          <PrefixDropdown
            inputValue={user?.phone}
            countryInputValue={countryInput}
            h="48px"
            placeholder="Phone"
            format="## ### ### ###"
            addNew={true}
            displayResult={
              user?.phoneCountryNumbers !== "" ? (
                <Div d="flex" h="24px" align="center" justify="center" w="25px">
                  <Text
                    textColor="dark"
                    textWeight="500"
                    m={{ l: "0rem" }}
                    textSize={14}
                  >{`${user?.phoneCountryNumbers}`}</Text>
                </Div>
              ) : (
                <div></div>
              )
            }
            isLoading={loadingCountries}
            isOpen={isCountryDropOpen}
            setIsOpen={setIsCountryDropOpen}
            setCountryInput={setCountryInput}
            handleChange={(value) => {
              setUser({
                ...user,
                phone: value,
              });
            }}
            menuList={countries?.getCountries.items?.map(
              (item: Country, i: number) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={i}
                  align="center"
                  onClick={() => {
                    setUser({
                      ...user,
                      phoneCountryNumbers: item.phoneCode,
                    });
                    setIsCountryDropOpen(false);
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.phoneCode}`}
                  </Text>
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.name}`}
                  </Text>
                </Anchor>
              )
            )}
          />
        </Col>
        <Div h="2rem"></Div>
        <Col>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={isLoading}
                text={"Next"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setStep(2);
                }}
                isLoading={isLoading}
                text={"Back"}
              />
            </Col>
          </Row>
        </Col>
      </>
    </Row>
  );
};

export default CreatePlaceholderUser;
