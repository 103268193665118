import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import React from "react";
import { modalData } from "../../../App";
import renderIcon from "../../../assets/Icons";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import DangerBtn from "../../DangerBtn/DangerBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const DeleteModal: React.FC = () => {
  const { name, props, isLoading } = useReactiveVar(modalData);

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "deleteModal")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "deleteModal" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Div d="flex" justify="center">
            {renderIcon("Delete")}
          </Div>
          <Row>
            <Col>
              <Text
                textColor={"dark"}
                textWeight="500"
                m={{ t: "0.5rem", b: "1.75rem" }}
                textAlign="center"
              >
                {props.subtext}
              </Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col size="auto">
              <DangerBtn
                w="144px"
                handleSubmit={props.handleSubmit}
                isLoading={isLoading}
                text={"Delete"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="144px"
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={isLoading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default DeleteModal;
