/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export function useDebouncedEffect(fn: () => void, deps: any[], time: number) {
  // const dependencies = [...deps, fn, time]
  useEffect(() => {
    const timeout = setTimeout(() => fn(), time);
    return () => clearTimeout(timeout);
  }, [...(deps || []), time]);
}
