import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import moment from "moment";
import React from "react";
import { modalData } from "../../../App";
import renderIcon from "../../../assets/Icons";
import { DefaultUserRole } from "../../../enums";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { indexToLetter } from "../../../pages/OrderDetails/utils/helpers";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const EditItinerary: React.FC = () => {
  const modalDataVar = useReactiveVar(modalData);
  const order = modalDataVar?.props?.order;
  const locationPoints = modalDataVar?.props?.locationPoints;
  const me = modalDataVar?.props?.me;
  const refetchOrder = modalDataVar?.props?.refetchOrder;

  const allowedRoles = [
    DefaultUserRole.ADMIN,
    DefaultUserRole.DISPATCHER,
    DefaultUserRole.COMPANY_OWNER,
    DefaultUserRole.SUPER_DISPATCHER,
  ];

  const canChangeLocationPoint = allowedRoles.includes(
    me?.getUser?.roles?.[0]?.id!
  );

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(modalDataVar.name === "editItinerary")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {modalDataVar.props && modalDataVar.name === "editItinerary" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {modalDataVar.msg}
          </Text>
          <Col d="flex" flexDir="column" style={{ paddingBottom: 20, gap: 5 }}>
            {(locationPoints || [])?.map(
              (locationPoint: any, index: number) => (
                <PointDetails
                  key={locationPoint.id}
                  id={locationPoint.id}
                  date={locationPoint?.loadingDate || ""}
                  pointName={indexToLetter(index)}
                  address={locationPoint?.locationInfo?.address || ""}
                  note={locationPoint.note || ""}
                  code={modalDataVar?.props?.orderCode}
                  timezone={locationPoint?.locationInfo?.timezone!}
                  canChangeLocationPoint={canChangeLocationPoint}
                  me={me}
                  refetchOrder={refetchOrder}
                />
              )
            )}
          </Col>
          <Row>
            <Col flexDir="row" d="flex" justify="center">
              <PrimaryBtn
                handleSubmit={() =>
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  })
                }
                w="100%"
                styleBtn={{ marginRight: "1rem" }}
                text={"Save"}
              />
              <SecondaryBtn
                w="100%"
                handleSubmit={() => {
                  modalData({
                    name: "addStopPoint",
                    msg: "Add new stop point",
                    returned_value: null,
                    props: {
                      prevModalVar: modalDataVar,
                      order: order?.getOrder,
                      refetchOrder,
                    },
                  });
                }}
                text={"Add new stop point"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

type PointDetailsProps = {
  id: number;
  address: string;
  date: string;
  note?: string;
  code: string;
  pointName: string;
  timezone: string;
  canChangeLocationPoint: boolean;
  me: any;
  refetchOrder: any;
};

const PointDetails: React.FC<PointDetailsProps> = ({
  id,
  address,
  date,
  note,
  code,
  pointName,
  timezone,
  canChangeLocationPoint = false,
  me,
  refetchOrder,
}) => {
  const formattedDate = moment(date).tz(timezone).format("D/MM/YYYY");
  const formattedTime = moment(date).tz(timezone).format("HH:mm");
  const dateString = `${formattedDate} at ${formattedTime} ${timezone}`;

  return (
    <Col d="flex" flexDir="column" style={{ gap: 5 }}>
      <Row justify="space-between">
        <Text textWeight="700" textColor="gray" d="flex" style={{ gap: 5 }}>
          Point <Text textColor="dark">{pointName}</Text>
        </Text>
        <Text
          m={{ l: "10px" }}
          textSize="14"
          textColor="brand600"
          hoverTextColor="brand700"
          opacity="1"
          cursor="pointer"
          onClick={() => {
            modalData({
              msg: "Change pickup date",
              name: "pickupDate",
              returned_value: null,
              props: {
                loadingDate: date,
                canChangeLocationPoint,
                id: id,
                orderCode: code,
                refetchOrder,
                me,
                timezone: timezone,
              },
            });
          }}
        >
          {renderIcon("Pen")} Edit
        </Text>
        <Col>
          <Text textWeight="500" textColor="dark" textAlign="right">
            {address}
          </Text>
          <Text textWeight="500" textColor="gray" textAlign="right">
            {dateString}
          </Text>
          {note && (
            <Text textWeight="500" textColor="gray" textAlign="right">
              Note: {note}
            </Text>
          )}
          <Text
            textSize="12"
            textWeight="500"
            textColor="gray600"
            textAlign="right"
          >
            {code}
          </Text>
        </Col>
      </Row>
      <Div bg="gray300" style={{ width: "100%", height: 1 }} />
    </Col>
  );
};

export default EditItinerary;
