import React, { useState } from "react";
import { Button, Row, Col, Text, Input, Icon, Div, Anchor } from "atomize";
import renderIcon from "../../../assets/Icons";
import Border from "../../../components/Border/Border";
import PrefixDropdown from "../../../components/PrefixDropdown/PrefixDropdown";
import { Country, useGetCountriesLazyQuery } from "../../../generated/graphql";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";

const DEFAULT_PHONE_CODE = "386";

interface StepOneProps {
  user: any;
  setUser: (user: any) => void;
  handleStep: (num: number) => void;
}

let schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required({ name: "First name", code: "REQUIRED_FIELD" }),
  lastName: yup
    .string()
    .trim()
    .required({ name: "Last name", code: "REQUIRED_FIELD" }),
  email: yup
    .string()
    .trim()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  password: yup
    .string()
    .required({ name: "Password", code: "REQUIRED_FIELD" })
    .min(8, { name: "Password", code: "MIN_CHARACTERS", var: "8" }),
  phone: yup
    .string()
    .trim()
    .required({ name: "Phone", code: "REQUIRED_FIELD" }),
  phoneCountryNumbers: yup
    .string()
    .trim()
    .required({ name: "Country phone code.", code: "REQUIRED_FIELD" }),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], {
    name: "Passwords",
    code: "PASSWORD_MATCH",
  }),
});

const StepOne: React.FC<StepOneProps> = ({ user, setUser, handleStep }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const { validator } = ValidationsFeHandler();

  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });

  const [getDefaultCountry] = queryCountries;

  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, {}, 300, isCountryDropOpen); //{ limit: 5 }

  React.useEffect(() => {
    if (!isCountryDropOpen) {
      getDefaultCountry({
        variables: {
          query: {
            search: "slovenia",
          },
        },
      }).then((response) => {
        const defaultCountry = response?.data?.getCountries?.items?.find(
          (country: Country) => country.phoneCode === DEFAULT_PHONE_CODE
        );

        if (defaultCountry) {
          setUser((prev: any) => ({
            ...prev,
            phoneCountryNumbers: defaultCountry.phoneCode,
          }));
        }
      });
    }
  }, []);

  React.useEffect(() => {
    if (!user?.phoneCountryNumbers && countries) {
      const sloveniaCountry = countries.getCountries.items?.find(
        (country: Country) => country.phoneCode === DEFAULT_PHONE_CODE
      );

      setUser((prev: any) => ({
        ...prev,
        phoneCountryNumbers: sloveniaCountry.phoneCode,
      }));
    }
  }, [setUser, user, countries]);

  const history = useHistory();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSubmit = () => {
    validator({
      schema: schema,
      data: user,
      success: () => {
        handleStep(1);
      },
    });
  };

  return (
    <Row flexDir="column" minW="100%" p={{ t: "3rem" }}>
      <Col d="flex" size="12" justify="center">
        {renderIcon("Logo")}
      </Col>
      <Col>
        <Text
          textSize="201"
          textAlign="center"
          textWeight="500"
          textColor="dark"
          m={{ x: { xs: "0rem" }, y: { xs: "2rem" } }}
        >
          Let’s get you set up with Cargobite.
        </Text>
      </Col>

      <Col size="12">
        <Input
          h="48px"
          zIndex="0"
          placeholder="First name"
          name="firstName"
          textSize={"16"}
          value={user.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          type="text"
          m={{ b: "0.5rem" }}
        />
      </Col>

      <Col size="12">
        <Input
          h="48px"
          placeholder="Last name"
          name="lastName"
          textSize={"16"}
          value={user.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          type="text"
          m={{ b: "0.5rem" }}
        />
      </Col>

      <Col size="12">
        <Input
          h="48px"
          placeholder="Email"
          name="email"
          textSize={"16"}
          value={user.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          type="text"
          m={{ b: "0.5rem" }}
        />
      </Col>
      <Col size="12">
        <PrefixDropdown
          inputValue={user.phone}
          countryInputValue={countryInput}
          h="48px"
          maxH="300px"
          addNew={true}
          placeholder="Phone"
          format="## ### ### ###"
          displayResult={
            <Div
              d="flex"
              h="24px"
              align="center"
              justify="center"
              m={{ l: "auto", r: "-0.75rem" }}
              w="35px"
            >
              <Text
                textColor="dark"
                textWeight="500"
                textSize={16}
                m={{ l: "0" }}
              >{`${user.phoneCountryNumbers}`}</Text>
            </Div>
          }
          isLoading={loadingCountries}
          isOpen={isCountryDropOpen}
          setIsOpen={setIsCountryDropOpen}
          setCountryInput={setCountryInput}
          handleChange={(value: string) => {
            setUser({
              ...user,
              phone: value,
            });
          }}
          menuList={countries?.getCountries.items?.map(
            (item: Country, i: number) => (
              <Anchor
                d="flex"
                p={{ y: "0.5rem", x: "0.75rem" }}
                key={i}
                align="center"
                onClick={() => {
                  setUser({
                    ...user,
                    phoneCountryNumbers: item.phoneCode,
                  });
                  setIsCountryDropOpen(false);
                }}
              >
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.phoneCode}`}
                </Text>
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.name}`}
                </Text>
              </Anchor>
            )
          )}
        />
      </Col>
      <Col size="12">
        <Input
          h="48px"
          placeholder="Password"
          textSize={"16"}
          type={showPassword ? "text" : "password"}
          m={{ y: "0.5rem" }}
          name="password"
          value={user.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          suffix={
            <Button
              pos="absolute"
              onClick={() => setShowPassword(!showPassword)}
              bg="transparent"
              right="0.5rem"
              top="50%"
              h="48px"
              transform="translateY(-50%)"
              rounded={{ r: "md" }}
            >
              <Icon
                name={showPassword ? "EyeSolid" : "Eye"}
                color={showPassword ? "primary" : "info700"}
                size="16px"
                hovercolor="light"
                transition
              />
            </Button>
          }
        />
      </Col>
      <Col size="12">
        <Input
          h="48px"
          placeholder="Confirm Password"
          textSize={"16"}
          type={showConfirmPassword ? "text" : "password"}
          m={{ b: "0.5rem" }}
          name="confirmPassword"
          value={user.confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          suffix={
            <Button
              pos="absolute"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              bg="transparent"
              right="0.5rem"
              top="50%"
              h="48px"
              transform="translateY(-50%)"
              rounded={{ r: "md" }}
            >
              <Icon
                name={showConfirmPassword ? "EyeSolid" : "Eye"}
                color={showConfirmPassword ? "primary" : "info700"}
                size="16px"
                hovercolor="light"
                transition
              />
            </Button>
          }
        />
      </Col>

      <Col size="12">
        <PrimaryBtn
          h="48px"
          w="100%"
          styleBtn={{ marginTop: "1.5rem" }}
          handleSubmit={handleSubmit}
          isLoading={false}
          text={"Next"}
        />
      </Col>
      <Col>
        <Border margin={"32px 0 15px"} />
      </Col>
      <Col>
        <Row justify="space-between">
          <Col d="flex" size={{ xs: "12", sm: "4" }} justify={{}}>
            <Anchor
              hoverTextColor="info700"
              transition
              textColor="primary"
              onClick={() => {
                history.push("/forgotpassword");
              }}
              textSize={12}
              textWeight="600"
            >
              Forgot password?
            </Anchor>
          </Col>
          <Col
            d="flex"
            size={{ xs: "12", sm: "8" }}
            justify={{ xs: "flex-start", sm: "flex-end" }}
          >
            <Text textSize={12} style={{ margin: "0 4px 0 0" }}>
              I already have an account
            </Text>
            <Anchor
              hoverTextColor="info700"
              textColor="primary"
              onClick={() => {
                history.push("/signin");
              }}
              textSize={12}
              textWeight="600"
            >
              {"Login instead ->"}
            </Anchor>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StepOne;
