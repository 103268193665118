import { useReactiveVar } from "@apollo/client";
import React from "react";
import { path } from "../../App";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import * as Styled from "./Main.styles";

interface MainProps {
  children?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const { width, height } = useWindowDimensions();
  const pathVar = useReactiveVar(path);

  return (
    <Styled.Main
      minHeight={height - (width > 700 ? 0 : 38)}
      margin={
        pathVar.to && pathVar.to.includes("/myorder/")
          ? width > 700
            ? "0 0 0 1.5rem"
            : "0 0 0 0.5rem"
          : pathVar.to === "/myorders"
          ? width > 700
            ? "0 0 0 1.5rem"
            : "0 0 0 0.5rem"
          : width > 700
          ? "0 1.5rem"
          : "0 0.5rem"
      }
    >
      {children}
    </Styled.Main>
  );
};

export default Main;
