import React from "react";
import * as Styled from "./TableElements.styles";

interface TableProps {
  margin?: string;
  width?: string;
  minWidth?: string;
  className?: string;
}

const Table: React.FC<TableProps> = ({
  children,
  margin,
  width,
  minWidth,
  className,
}) => {
  return (
    <Styled.Table
      tabIndex={5}
      margin={margin}
      width={width}
      minWidth={minWidth}
      className={className}
    >
      {children}
    </Styled.Table>
  );
};

export default Table;
