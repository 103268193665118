import { Div, Icon, Text } from "atomize";
import moment from "moment";
import { ChangeEvent, FC, useEffect } from "react";
import renderIcon from "../../../assets/Icons";
import { GlobalTheme } from "../../../components/GlobalTheme";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../../components/SecondaryBtn/SecondaryBtn";
import {
  GetActivityLogsDocument,
  GetInvoiceDataDocument,
  GetMeQuery,
  GetOrderQuery,
  InvoiceData,
  InvoiceDataStatus,
  useGetCompanyLazyQuery,
  useGetCurrencyLazyQuery,
  useUpdateInvoiceDataStatusMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import { showTimeDifference } from "../../../helpers/functions";

interface Props {
  order: GetOrderQuery | undefined;
  me: GetMeQuery | undefined;
  invoiceData: Partial<InvoiceData> | undefined;
}

const InvoiceDataNotification: FC<Props> = ({ order, me, invoiceData }) => {
  const { width } = useWindowDimensions();

  const [getCurrency, { data: invoiceDataCurrency }] =
    useGetCurrencyLazyQuery();
  const [getCompany, { data: payeeCompany }] = useGetCompanyLazyQuery();

  const [
    updateInvoiceDataStatus,
    { data: updateInvoiceDataData, error: updateInvoiceDataError },
  ] = useUpdateInvoiceDataStatusMutation();

  const handleUpdateInvoiceDataStatus = (status: InvoiceDataStatus) => {
    updateInvoiceDataStatus({
      variables: { status, id: invoiceData?.id as number },
      refetchQueries: [
        {
          query: GetInvoiceDataDocument,
          variables: { orderId: order?.getOrder.id },
        },
        GetActivityLogsDocument,
      ],
    });
  };

  ErrorsBeHandler({
    error: updateInvoiceDataError,
  });

  SuccessBeHandler({
    data: updateInvoiceDataData,
    code: `INVOICE_DATA_STATUS_UPDATED_${updateInvoiceDataData?.updateInvoiceDataStatus?.status}`,
  });

  const { status, amount, paymentDueDate, issuingCompany_id, payeeCompany_id } =
    invoiceData || {};

  const isConfirmed = status === InvoiceDataStatus.Confirmed;

  const isIssuer = me?.getUser?.company?.id === issuingCompany_id;
  const isReceiver = me?.getUser?.company?.id === payeeCompany_id;
  const timezone = me?.getUser?.company?.location?.timezone ?? "Europe/Ljubljana";

  const hasPassedDueDate = moment()
    .tz(timezone)
    .isAfter(moment(paymentDueDate));
  const formattedDueDate = moment(paymentDueDate)
    .tz(timezone)
    .format("DD MMM YYYY");
  const timeDifference = showTimeDifference(paymentDueDate, timezone);
  const symbol = invoiceDataCurrency?.getCurrency?.symbol ?? "";

  useEffect(() => {
    if (invoiceData?.currency_id) {
      getCurrency({
        variables: { id: invoiceData?.currency_id as number },
      });
    }
  }, [getCurrency, invoiceData?.currency_id]);

  useEffect(() => {
    if (invoiceData?.payeeCompany_id) {
      getCompany({
        variables: {
          id: invoiceData?.payeeCompany_id as number,
        },
      });
    }
  }, [getCompany, invoiceData?.payeeCompany_id]);

  if (
    ![InvoiceDataStatus.Confirmed, InvoiceDataStatus.Created].includes(
      status as InvoiceDataStatus
    )
  ) {
    return null;
  }

  const message = hasPassedDueDate ? (
    <>
      <Text m={{ r: "4px" }}>Your invoice for</Text>
      <Text m={{ r: "4px" }} textWeight="600">
        {symbol}
        {amount}
      </Text>
      <Text m={{ r: "4px" }}>is past due for </Text>
      <Text textWeight="600" m={{ r: "4px" }}>
        {timeDifference}
      </Text>
      <Text>({formattedDueDate}).</Text>
      {isIssuer && (
        <Text m={{ r: "4px" }}>
          A reminder is sent to {payeeCompany?.getCompany?.name} every second
          business day until either of you confirm the payment has been
          received.
        </Text>
      )}
    </>
  ) : (
    <>
      <Text m={{ r: "4px" }}>Your invoice for</Text>
      <Text m={{ r: "4px" }} textWeight="600">
        {symbol}
        {amount}
      </Text>
      <Text m={{ r: "4px" }}>is scheduled to be paid within</Text>
      <Text m={{ r: "4px" }} textWeight="600">
        {timeDifference}
      </Text>
      <Text>({formattedDueDate}).</Text>
    </>
  );

  const actionButtons = (isIssuer || isReceiver) && (
    <Div
      d="flex"
      m={{
        l: width > 980 ? "auto" : "44px",
        r: width > 980 ? "0" : "auto",
        t: width <= 980 && "1rem",
      }}
    >
      <PrimaryBtn
        handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
          e.preventDefault();
          handleUpdateInvoiceDataStatus(
            isIssuer || (isReceiver && isConfirmed)
              ? InvoiceDataStatus.Paid
              : InvoiceDataStatus.Confirmed
          );
        }}
        prefixIcon={
          <Icon
            name="Checked"
            m={{ l: "0px", r: "5px" }}
            size="20px"
            color="background"
          />
        }
        isLoading={false}
        h="2rem"
        styleBtn={{
          display: "flex",
          justifyContent: "left",
          margin: "0 8px 0 0",
          whiteSpace: "nowrap",
        }}
        text={
          isIssuer
            ? "Mark as paid"
            : isReceiver && isConfirmed
            ? "Mark as paid"
            : "Confirm"
        }
        textSize={12}
      />
      {!isConfirmed && (
        <SecondaryBtn
          styleBtn={{
            maxWidth: "144px",
            whiteSpace: "nowrap",
            ...(isReceiver
              ? {
                  background: GlobalTheme.colors.danger,
                  color: GlobalTheme.colors.background,
                }
              : {}),
          }}
          h="2rem"
          handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleUpdateInvoiceDataStatus(
              isIssuer
                ? InvoiceDataStatus.Discarded
                : InvoiceDataStatus.Rejected
            );
          }}
          {...(isReceiver
            ? {
                prefixIcon: (
                  <Icon
                    name="Cross"
                    m={{ l: "0px", r: "5px" }}
                    size="20px"
                    color="background"
                  />
                ),
              }
            : {})}
          isLoading={false}
          text={isIssuer ? "Discard invoice" : isReceiver ? "Reject" : ""}
        />
      )}
    </Div>
  );

  return (
    <Div
      p="0.75rem 1rem"
      bg={hasPassedDueDate || isReceiver ? "washedOrange" : "info200"}
      shadow="3"
      d="flex"
      m={{ b: "1rem" }}
      align="center"
      justify="flex-start"
      flexDir={width > 980 ? "row" : "column"}
    >
      <Div d="flex" justify="center" align="center">
        <Div
          d="flex"
          w="30px"
          h="30px"
          justify="center"
          align="center"
          m={{ r: "14px" }}
        >
          {renderIcon("Report")}
        </Div>
        <Div>
          <Div d="flex" flexWrap="wrap">
            {message}
          </Div>
        </Div>
      </Div>
      {actionButtons}
    </Div>
  );
};

export default InvoiceDataNotification;
