import React from "react";
import * as Styled from "./TableElements.styles";

interface TbodyProps {
  maxH: string;
  tBodyStyle?: any;
  children: React.ReactNode;
}

const Tbody: React.FC<TbodyProps> = ({ children, maxH, tBodyStyle }) => {
  return (
    <Styled.Tbody maxH={maxH} style={{ ...tBodyStyle }} id={"tbody"}>
      {children}
    </Styled.Tbody>
  );
};

export default Tbody;
