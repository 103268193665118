import { Anchor, Col, Div, Row, Text } from "atomize";
import { Dispatch, FC, SetStateAction } from "react";
import { modalData } from "../../../App";
import InputDropdown from "../../../components/InputDropdown/InputDropdown";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  AssignedUserType,
  CompanyStatus,
  GetActivityLogsDocument,
  GetMeQuery,
  GetOrderDocument,
  GetOrderQuery,
  GetUsersQuery,
} from "../../../generated/graphql";

interface Props {
  isLoading: boolean;
  order: GetOrderQuery | undefined;
  userHasAllowedRole: boolean;
  me: GetMeQuery | undefined;
  isAssigned: boolean;
  searchDispatcherPage: number;
  dispatchers: GetUsersQuery | undefined;
  dispatcherLoading: boolean;
  displayDispatcherLast: any;
  assignUserLoading: boolean;
  isDispatcherDropdownOpen: boolean;
  searchDispatcher: string;
  flags: {
    isOwner: boolean;
    isShipper: boolean;
    isReceiver: boolean;
    isTransport: boolean;
  };
  setSearchDispatcherPage: Dispatch<SetStateAction<number>>;
  setIsDispatcherDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setSearchDispatcher: Dispatch<SetStateAction<string>>;
  assignedUser: (item: any) => void;
}

const DispatcherDropdownColumn: FC<Props> = ({
  isLoading,
  order,
  userHasAllowedRole,
  me,
  isAssigned,
  searchDispatcherPage,
  dispatchers,
  dispatcherLoading,
  displayDispatcherLast,
  assignUserLoading,
  isDispatcherDropdownOpen,
  searchDispatcher,
  flags,
  setSearchDispatcherPage,
  setIsDispatcherDropdownOpen,
  setSearchDispatcher,
  assignedUser,
}) => {
  return (
    <Col>
      <Text
        textSize={12}
        textColor="semiDark"
        textWeight="500"
        m={{ t: "1rem", b: "4px" }}
      >
        Dispatcher
      </Text>
      {isLoading ? (
        <Skeleton width="100%" height="40px" />
      ) : (
        order?.getOrder && (
          <InputDropdown
            addNew={
              userHasAllowedRole &&
              me?.getUser.company?.status === CompanyStatus.Active
            }
            isLocked={
              me?.getUser.company?.status !== CompanyStatus.Active ||
              !isAssigned
            }
            page={searchDispatcherPage}
            setPage={setSearchDispatcherPage}
            totalPages={Math.ceil((dispatchers?.getUsers?.total ?? 0) / 5)}
            modal={{
              msg: "Create new dispatcher",
              name: "inviteUser",
              returned_value: null,
              props: {
                company_id: me?.getUser?.company?.id,
                role_id: 3,
              },
            }}
            isLoading={dispatcherLoading}
            displayResult={
              <Div d="flex" h="24px" align="center">
                {displayDispatcherLast ? (
                  <>
                    <PreloadingImage
                      imageSrc={
                        displayDispatcherLast.user?.profileImageUrl
                          ? displayDispatcherLast.user?.profileImageUrl
                          : ""
                      }
                      skeletonComponent={
                        <Skeleton
                          height={"24px"}
                          width={"24px"}
                          borderRadius={"24px"}
                        />
                      }
                      isLoading={assignUserLoading}
                      imgComponent={
                        <ShortNameLogo
                          name={`${displayDispatcherLast.user?.firstName} ${displayDispatcherLast.user?.lastName}`}
                          size="24px"
                          textSize={12}
                        />
                      }
                      imageClassNames="i_b f_l"
                      imageStyle={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "24px",
                        verticalAlign: "middle",
                      }}
                    />
                    {assignUserLoading ? (
                      <Skeleton
                        width="100px"
                        margin="0 0 0 0.5rem"
                        height="20px"
                      />
                    ) : (
                      <Text
                        textColor={"dark"}
                        textSize={14}
                        m={{ r: "auto", l: "0.5rem" }}
                      >
                        {`${displayDispatcherLast.user?.firstName} ${displayDispatcherLast.user?.lastName}`}
                      </Text>
                    )}
                  </>
                ) : assignUserLoading ? (
                  <>
                    <Skeleton
                      height={"24px"}
                      width={"24px"}
                      borderRadius={"24px"}
                    />
                    <Skeleton
                      width="100px"
                      margin="0 0 0 0.5rem"
                      height="20px"
                    />
                  </>
                ) : (
                  "Add new"
                )}
              </Div>
            }
            isOpen={isDispatcherDropdownOpen}
            setIsOpen={setIsDispatcherDropdownOpen}
            value={searchDispatcher}
            handleChange={setSearchDispatcher}
            menuList={dispatchers?.getUsers.items?.map(
              (item: any, i: number) => (
                <Anchor
                  key={i}
                  d="flex"
                  p={{ y: "0.5rem", x: "0.5rem" }}
                  textColor="semiDark"
                  justify="space-between"
                  hoverTextColor="dark"
                  align="center"
                  onClick={async () => {
                    const isAlreadySelected =
                      order?.getOrder?.assignedUsers?.filter(
                        (user: any) => user?.user_id === item?.id
                      );
                    if (isAlreadySelected!.length > 0) {
                      modalData({
                        msg: "User already assigned",
                        name: "readMoreTd",
                        returned_value: null,
                        props: {
                          children: (
                            <Row>
                              <Col d="flex" textAlign="center">
                                <Text>User is already assigned to order.</Text>
                              </Col>
                            </Row>
                          ),
                        },
                      });
                    } else {
                      await assignedUser({
                        variables: {
                          user_id: item.id,
                          type: flags.isOwner
                            ? AssignedUserType.Owner
                            : flags.isShipper
                            ? AssignedUserType.Shipper
                            : flags.isReceiver
                            ? AssignedUserType.Receiver
                            : AssignedUserType.Transporter,
                          id: Number(order?.getOrder?.id),
                        },
                        refetchQueries: [
                          GetOrderDocument,
                          GetActivityLogsDocument,
                        ],
                        awaitRefetchQueries: true,
                      });
                    }
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    m={{ l: "10px" }}
                  >
                    {`${item.firstName} ${item.lastName}`}
                  </Text>
                </Anchor>
              )
            )}
          />
        )
      )}
    </Col>
  );
};

export default DispatcherDropdownColumn;
