import React, { ReactElement } from "react";
import { Icon, Dropdown } from "atomize";
import { CSSProperties } from "styled-components";

interface DropDownProps {
  isOpen: boolean;
  menuList: ReactElement;
  setIsOpen?: (isOpen: boolean) => void;
  showDropIcon?: boolean;
  direction?: string;
  isLocked?: boolean;
  border?: boolean;
  isInTable?: boolean;
  h?: string;
  style?: CSSProperties;
  padding?: string;
}

const DropdownClassic: React.FC<DropDownProps> = ({
  isLocked,
  children,
  isOpen,
  menuList,
  setIsOpen,
  showDropIcon,
  direction,
  border,
  isInTable,
  h = "48px",
  style,
  padding = "0",
}) => {
  return (
    <Dropdown
      d="flex"
      position="unset"
      align="center"
      justify="center"
      h={h}
      tabIndex={1}
      isOpen={isOpen}
      onClick={() => setIsOpen && setIsOpen(!isOpen)}
      menu={menuList}
      direction={direction ? direction : "bottomright"}
      style={{
        pointerEvents: isLocked ? "none" : "auto",
        border: border === false ? "none" : "",
        padding: padding,
        ...style,
      }}
      bg={isLocked && !isInTable ? "grey" : "none"}
      focusBg={border ? "semiLight" : "none"}
      openSuffix={
        showDropIcon !== false && (
          <Icon
            m={{ l: "auto" }}
            name="UpArrow"
            size="18px"
            color={isLocked ? (isInTable ? "transparent" : "grey") : "semiDark"}
          />
        )
      }
      closeSuffix={
        showDropIcon !== false && (
          <Icon
            m={{ l: "auto" }}
            name="DownArrow"
            size="18px"
            color={isLocked ? (isInTable ? "transparent" : "grey") : "semiDark"}
          />
        )
      }
    >
      {children}
    </Dropdown>
  );
};

export default DropdownClassic;
