import styled from "styled-components";
interface ShortNameLogoProps {
  size: string;
  color: string;
}
export const ShortNameLogo = styled.div<ShortNameLogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  min-width: ${(props) => props.size};
  border-radius: ${(props) => props.size};
`;
