import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Icon, Label, Radiobox, Row, Text } from "atomize";
import { isFunction } from "lodash";
import React, { useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  Driver,
  GetActivityLogsDocument,
  GetOrderDocument,
  GetOrdersDocument,
  UserStatus,
  useAssignDriverMutation,
  useGetDriversLazyQuery,
  useGetMeQuery,
  useGetUserQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import Chip from "../../Chip/Chip";
import { GlobalTheme } from "../../GlobalTheme";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import TertiaryBtn from "../../TertiaryBtn/TertiaryBtn";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  id: yup
    .string()
    .trim()
    .required({ name: "Driver", code: "DATA_NOT_SELECTED" }),
});

const initialuser = {
  id: null,
  name: "",
  profileImageUrl: "",
  phone: "",
};

const ChangeVehicleAddDriver: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const [
    changeVehicle,
    { data: vehicleData, loading: vehicleDataLoading, error: vehicleDataError },
  ] = useAssignDriverMutation({
    errorPolicy: "all",
    refetchQueries: [
      {
        query: GetOrderDocument,
        variables: {
          code: props?.order_code,
        },
      },
    ],
  });

  const [selectOwner, setSelectOwner] = useState(true);

  const { validator } = ValidationsFeHandler();
  const { width } = useWindowDimensions();

  const { data: me } = useGetMeQuery();

  const [user, setUser] = useState<any>({ ...initialuser });
  const [owner, setOwner] = useState<any>({ ...initialuser });

  const { loading: loadingOwner } = useGetUserQuery({
    variables: { id: props?.driver_owner },
    skip:
      name !== "changeVehicleAddDriver" ||
      props === null ||
      props.driver_owner === null,
    onCompleted: (driver) => {
      setOwner({
        id: driver.getUser.id,
        name: `${driver.getUser.firstName} ${driver.getUser.lastName}`,
        profileImageUrl: driver.getUser.profileImageUrl,
        phone: driver.getUser.phone,
      });
    },
    fetchPolicy: "network-only",
  });

  const { loading } = useGetUserQuery({
    variables: { id: props?.driverUser_id },
    skip:
      name !== "changeVehicleAddDriver" ||
      props === null ||
      props.driverUser_id === null,
    onCompleted: (driver) => {
      setUser({
        id: driver.getUser.id,
        name: `${driver.getUser.firstName} ${driver.getUser.lastName}`,
        profileImageUrl: driver.getUser.profileImageUrl,
        phone: driver.getUser.phone,
      });
      if (props.driverUser_id === props.driver_owner) {
        setSelectOwner(true);
      } else {
        setSelectOwner(false);
      }
    },
    fetchPolicy: "network-only",
  });

  const queryUsers = useGetDriversLazyQuery();
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] =
    useState<boolean>(false);
  const {
    data: users,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
    page: searchUsersPage,
    setPage: setSearchUsersPage,
  } = useSearch(
    queryUsers,
    {
      limit: 3,
      status: [UserStatus.Active],
      externalDriver: [0, 1],
    },
    300,
    me && isUsersDropdownOpen,
    {
      id: me?.getUser.company?.id,
    }
  );

  const updateUser = (item: any) => {
    setUser({
      id: item.id,
      name: `${item.firstName} ${item.lastName}`,
      profileImageUrl: item.profileImageUrl,
      phone: item.phone,
    });
    setIsUsersDropdownOpen(false);
  };

  const { onUserItemClick } = props || {};

  const handleUserItemClick = async (item: any) => {
    if (isFunction(onUserItemClick)) {
      const result = await onUserItemClick(item);
      if (result === "noOverlapping") {
        updateUser(item);
      }
    } else {
      updateUser(item);
    }
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: { id: selectOwner ? owner.id : user.id },
      success: () => {
        selectOwner
          ? changeVehicle({
              variables: {
                vehicle_id: Number(props.vehicle_id),
                order_id: Number(props.order_id),
                id: Number(props.id),
                driver_id: owner.id,
                driver_contact: JSON.stringify({
                  fullName: owner.name,
                  phone: owner.phone,
                }),
              },
              refetchQueries: [
                GetOrdersDocument,
                GetOrderDocument,
                GetActivityLogsDocument,
              ],
              awaitRefetchQueries: true,
            })
          : changeVehicle({
              variables: {
                vehicle_id: Number(props.vehicle_id),
                order_id: Number(props.order_id),
                id: Number(props.id),
                driver_id: user.id,
                driver_contact: JSON.stringify({
                  fullName: user.name,
                  phone: user.phone,
                }),
              },
              refetchQueries: [
                GetOrdersDocument,
                GetOrderDocument,
                GetActivityLogsDocument,
              ],
              awaitRefetchQueries: true,
            });
      },
    });
  };

  ErrorsBeHandler({
    error: vehicleDataError,
    dep: name === "changeVehicleAddDriver",
  });

  SuccessBeHandler({
    data: vehicleData,
    code: "CHANGE_VEHICLE_DRIVER",
    dep: name === "changeVehicleAddDriver",
    fn: () => {
      setUser(initialuser);
      setOwner(initialuser);
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  return (
    <StyledModal
      isOpen={!!(name === "changeVehicleAddDriver")}
      onClose={() => {
        setUser(initialuser);
        setOwner(initialuser);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "changeVehicleAddDriver" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Text
            textSize="201"
            textAlign="left"
            textColor="dark"
            textWeight="700"
            m={{ b: "0.5rem" }}
          >
            Select Driver
          </Text>
          <Row>
            <Col>
              <Div d="flex">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "0rem", r: "0.5rem" }}
                >
                  Confirm which driver will be responsible for this transport.
                </Text>
              </Div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                align="center"
                textWeight="600"
                m={{ b: "0.5rem", r: "2rem" }}
                d="flex"
                flexWrap="nowrap"
              >
                <Radiobox
                  onChange={() => {
                    owner.id && setSelectOwner(true);
                  }}
                  checked={
                    owner.id && selectOwner
                    // loadingState.cargoType === TransportCargoType.VolumeWeight
                  }
                  name="count"
                />
                <Text>
                  {" "}
                  {owner.name ? (
                    <Div d="flex">
                      {owner.name}{" "}
                      <Text m={{ l: "0.3rem" }} textColor="light">
                        (default driver for selected vehicle)
                      </Text>
                    </Div>
                  ) : (
                    "None"
                  )}
                </Text>
              </Label>
              <Div d="flex" alignItems="center">
                <Label
                  align="center"
                  textWeight="600"
                  m={{ b: "0.5rem", r: "2rem" }}
                  d="flex"
                  flexWrap="nowrap"
                >
                  <Radiobox
                    onChange={() => {
                      setSelectOwner(false);
                    }}
                    checked={
                      !selectOwner
                      // loadingState.cargoType === TransportCargoType.VolumeWeight
                    }
                    name="count"
                  />
                  <Text>Other driver</Text>
                </Label>
              </Div>
            </Col>
          </Row>
          {!selectOwner && (
            <Row p={{ b: "1rem" }}>
              <Col>
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "0rem" }}
                >
                  Which driver will be responsible for this vehicle?
                </Text>
                <InputDropdown
                  page={searchUsersPage}
                  setPage={setSearchUsersPage}
                  totalPages={Math.ceil(users?.getDrivers?.total / 3)}
                  showSearchField={true}
                  value={searchUsers}
                  addNew={false}
                  additionalButtons={
                    <TertiaryBtn
                      text={"Add external driver"}
                      h="40px"
                      prefixIcon={
                        <Icon
                          name="Add"
                          color="info700"
                          size="18px"
                          m={{ r: "0.5rem" }}
                        />
                      }
                      w={"calc(100% - 1.5rem)"}
                      styleBtn={{ margin: "0.5rem 0.75rem" }}
                      handleSubmit={() =>
                        modalData({
                          msg: "Add external driver",
                          name: "addExternalDriver",
                          returned_value: null,
                          props: {
                            vehicle_id: props.vehicle_id,
                          },
                        })
                      }
                    />
                  }
                  isLocked={false}
                  displayResult={
                    <Div d="flex" align="center" zIndex="0">
                      <PreloadingImage
                        imageSrc={user.profileImageUrl}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          user.name === "" ? (
                            <></>
                          ) : (
                            <ShortNameLogo
                              size="24px"
                              name={user.name}
                              textSize={12}
                            />
                          )
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {user.name === "" ? "Select default driver" : user.name}
                      </Text>
                    </Div>
                  }
                  isLoading={loadingUsers}
                  isOpen={isUsersDropdownOpen}
                  setIsOpen={setIsUsersDropdownOpen}
                  handleChange={setSearchUsers}
                  menuList={users?.getDrivers.items?.map(
                    (item: Driver, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={() => {
                          handleUserItemClick(item);
                        }}
                      >
                        <PreloadingImage
                          imageSrc={item?.profileImageUrl ?? ""}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={`${item.firstName} ${item.lastName}`}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                          m={item?.isExternalDriver ? { r: "0.5rem" } : {}}
                        >
                          {`${item.firstName} ${item.lastName}`}
                        </Text>
                        {item?.isExternalDriver && (
                          <Chip
                            label="Is external"
                            size="small"
                            bgColor={GlobalTheme.colors.danger200}
                          />
                        )}
                      </Anchor>
                    )
                  )}
                />
              </Col>
            </Row>
          )}
          <Div h="2rem"></Div>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={vehicleDataLoading || loading || loadingOwner}
                text={"Add"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setUser(initialuser);
                  setOwner(initialuser);
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={vehicleDataLoading || loading || loadingOwner}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default ChangeVehicleAddDriver;
