import { useReactiveVar } from "@apollo/client";
import { Col, Div, Icon, Row, Text } from "atomize";
import React, { useEffect } from "react";
import { modalData, responseMsgs } from "../../../App";
import {
  GetActivityLogsDocument,
  GetOrderDocument,
  GetReceivedBidsDocument,
  useFlagOrderMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const FlagOrder: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const [FlagOrder, { data, loading, error }] = useFlagOrderMutation({
    errorPolicy: "all",
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    FlagOrder({
      variables: { id: props.id },
      refetchQueries: [
        GetReceivedBidsDocument,
        GetOrderDocument,
        GetActivityLogsDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  useEffect(() => {
    if (data !== undefined && data !== null) {
      responseMsgs([
        ...responseMsgs(),
        { type: "success", string: "Order was successfully flagged." },
      ]);
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (error !== undefined) {
        let errors = error?.graphQLErrors.map((error) => {
          return { type: "error", string: error.message };
        });
        responseMsgs([...responseMsgs(), ...errors]);
        modalData({
          msg: null,
          name: null,
          returned_value: null,
          props: null,
        });
      }
    }
    // eslint-disable-next-line
  }, [error]);

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "flagOrder")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "flagOrder" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Div d="flex" align="center">
            <Icon
              name={"AlertSolid"}
              size="20px"
              m={{ r: "0.5rem" }}
              color={"warning700"}
            />
            <Text
              textSize="201"
              textAlign="center"
              textWeight="700"
              textColor="dark"
            >
              Flag order
            </Text>
          </Div>
          <Row>
            <Col>
              <Text
                textColor={"semiDark"}
                m={{ t: "0.5rem", b: "2rem" }}
                textWeight="500"
                textAlign="left"
              >
                Flagging is used to mark orders with issues so you can spot them
                quicker.
              </Text>
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loading}
                text={"Flag order"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default FlagOrder;
