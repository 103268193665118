import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Input, Row, Text } from "atomize";
import { isNumber } from "lodash";
import React, { useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import { DefaultUserRole } from "../../../enums";
import { useInviteUserMutation } from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { getRoleName } from "../../../helpers/functions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import DropdownClassic from "../../DropdownClassic/DropdownClassic";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  email: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  company_id: yup.number().required({ name: "Email", code: "REQUIRED_FIELD" }),
  role_id: yup.number().required({ name: "User role", code: "REQUIRED_FIELD" }),
});

const InviteUser: React.FC = () => {
  const { name, props, msg } = useReactiveVar(modalData);
  const [rolesDropdown, setRolesDropdown] = useState(false);
  const { validator } = ValidationsFeHandler();
  const [
    inviteUser,
    {
      data: inviteUserData,
      loading: inviteUserLoading,
      error: inviteUserError,
    },
  ] = useInviteUserMutation({ errorPolicy: "all" });

  const [user, setUser] = useState({
    email: "",
    role_id: props?.role_id,
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: {
        ...user,
        company_id: props?.company_id,
        role_id: props.role_id || user.role_id,
      },
      success: () => {
        inviteUser({
          variables: {
            invite: {
              company_id: props?.company_id,
              email: user.email,
              role_id: props.role_id || user.role_id,
            },
          },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: inviteUserError,
    dep: name === "inviteUser",
  });

  SuccessBeHandler({
    data: inviteUserData,
    code: "INVITE_USER",
    dep: name === "inviteUser",
    fn: () => {
      setUser({
        email: "",
        role_id: null,
      });
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "inviteUser")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "inviteUser" && (
        <Div
          maxH={`${height - 100}px`}
          style={{ padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            {msg}
          </Text>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "0.5rem" }}
              >
                Email
              </Text>
              <Input
                placeholder="Janez@gmail.com"
                name="email"
                value={user.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  });
                }}
                textSize={"16"}
                type="text"
                h="48px"
                m={{ b: "1rem" }}
              />
            </Col>
          </Row>
          {!props.role_id && (
            <Row>
              <Col>
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem" }}
                >
                  Role
                </Text>
                <DropdownClassic
                  padding="0 0.75rem"
                  isOpen={rolesDropdown}
                  menuList={
                    <Div tabIndex={0}>
                      {Object.values(DefaultUserRole)
                        .filter(
                          (role) =>
                            isNumber(role) &&
                            ![
                              DefaultUserRole.MODULE_PERMISSIONS_MANAGER,
                            ].includes(role)
                        )
                        .map((role, i: number) => {
                          return (
                            <Div d="flex" align="center" key={i}>
                              <Anchor
                                textSize={142}
                                m={{ r: "auto", l: "0.5rem" }}
                                textColor="semiDark"
                                hoverTextColor="dark"
                                onClick={() => {
                                  setUser({
                                    ...user,
                                    role_id: role,
                                  });
                                  setRolesDropdown(false);
                                }}
                              >
                                {getRoleName(role as DefaultUserRole)}
                              </Anchor>
                            </Div>
                          );
                        })}
                    </Div>
                  }
                  setIsOpen={setRolesDropdown}
                >
                  <Text m={{ r: "auto" }} textSize={16}>
                    {user.role_id
                      ? getRoleName(user.role_id)
                      : "Select user role"}
                  </Text>
                </DropdownClassic>
              </Col>
            </Row>
          )}

          <Div h="2rem"></Div>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={inviteUserLoading}
                text={"Invite"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setUser({
                    email: "",
                    role_id: null,
                  });
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={inviteUserLoading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default InviteUser;
