import { Checkbox, Col, Div, Row, Text } from "atomize";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { modalData, responseMsgs } from "../../../App";
import renderIcon from "../../../assets/Icons";
import Section from "../../../components/Section/Section";
import Skeleton from "../../../components/Skeleton/Skeleton";
import Table from "../../../components/TableComponents/Table";
import Tbody from "../../../components/TableComponents/Tbody";
import Td from "../../../components/TableComponents/Td";
import Th from "../../../components/TableComponents/Th";
import Thead from "../../../components/TableComponents/Thead";
import Tr from "../../../components/TableComponents/Tr";
import { DefaultUserRole } from "../../../enums";
import {
  GetMeQuery,
  GetOrderQuery,
  LocationPoint,
  useUpdateLocationPointCompletedStateMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import { indexToLetter } from "../utils/helpers";

interface Props {
  locationPoints: any;
  order: GetOrderQuery | undefined;
  isLoading: boolean;
  me: GetMeQuery | undefined;
  refetchOrder: any;
}

const OrderItinerary: FC<Props> = ({
  locationPoints,
  order,
  isLoading,
  me,
  refetchOrder = () => {},
}) => {
  const { width } = useWindowDimensions();

  return (
    <Row m={{ r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      {((order && order?.getOrder) || isLoading) && (
        <>
          <Col size="12">
            <Text textSize={20} textColor="dark">
              Order Itinerary
            </Text>
          </Col>
          <Col size="12">
            <Section margin="1.25rem 0 1.25rem 0rem">
              {isLoading ? (
                <Skeleton classN="table_skeleton" height="100px" />
              ) : (
                <>
                  <Table minWidth="1600px">
                    <Thead>
                      <TableHeader />
                    </Thead>
                    <Tbody maxH="none">
                      {locationPoints?.map((item: any, index: any) => (
                        <TableRow
                          index={index}
                          key={item?.getLocation?.id}
                          item={item}
                          order={order}
                          me={me}
                          refetchOrder={refetchOrder}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </>
              )}
            </Section>
          </Col>
        </>
      )}
    </Row>
  );
};

export default OrderItinerary;

const TableHeader = () => {
  return (
    <Tr className="head" hidBorderLast={true}>
      <Th width={"15%"}>
        <Text textColor="dark" textWeight="500">
          Stop
        </Text>
      </Th>
      <Th width={"15%"}>
        <Text textColor="dark" textWeight="500">
          City
        </Text>
      </Th>
      <Th width={"7%"}>
        <Text textColor="dark" textWeight="500">
          ZIP
        </Text>
      </Th>
      <Th width={"25%"}>
        <Text textColor="dark" textWeight="500">
          Address
        </Text>
      </Th>
      <Th width={"15%"}>
        <Text textColor="dark" textWeight="500">
          Country
        </Text>
      </Th>
      <Th width={"15%"}>
        <Text textColor="dark" textWeight="500">
          Date
        </Text>
      </Th>
      <Th width={"16%"}>
        <Text textColor="dark" textWeight="500">
          Time
        </Text>
      </Th>
      <Th width={"16%"}>
        <Text textColor="dark" textWeight="500">
          Note
        </Text>
      </Th>
      <Th width={"7%"}>
        <Text textColor="dark" textWeight="500">
          Done
        </Text>
      </Th>
    </Tr>
  );
};

const TableRow = ({
  item,
  order,
  me,
  index,
  refetchOrder,
}: {
  item: LocationPoint | undefined;
  order: GetOrderQuery | undefined;
  me: GetMeQuery | undefined;
  index: number;
  refetchOrder: any;
}) => {
  const [isCompleted, setIsCompleted] = useState(item?.completed);

  const allowedRoles = [
    DefaultUserRole.ADMIN,
    DefaultUserRole.DISPATCHER,
    DefaultUserRole.COMPANY_OWNER,
    DefaultUserRole.SUPER_DISPATCHER,
  ];

  const canChangeLocationPoint = allowedRoles.includes(
    me?.getUser?.roles?.[0]?.id!
  );

  const [
    updateState,
    {
      data: updateStateData,
      error: updateStateError,
      loading: updateStateLoading,
    },
  ] = useUpdateLocationPointCompletedStateMutation({
    onCompleted: () => {
      refetchOrder?.();
    },
  });

  const handleUpdateState = (completed: boolean, id: number) => {
    if (canChangeLocationPoint) {
      updateState({
        variables: {
          id,
          completedValue: completed,
        },
      });
    } else {
      responseMsgs([
        {
          type: "error",
          string:
            "You don't have permission to change the state of the location point.",
        },
      ]);
    }
  };

  useEffect(() => {
    setIsCompleted(
      updateStateData?.updateLocationPointCompletedState?.completed
    );
  }, [updateStateData?.updateLocationPointCompletedState?.completed]);

  SuccessBeHandler({
    data: updateStateData,
    code: "LOCATION_POINT_COMPLETED_STATE_UPDATED",
  });

  ErrorsBeHandler({
    error: updateStateError,
  });

  if (updateStateLoading) {
    return <Skeleton height="3rem" />;
  }

  return (
    <Tr className="order-itinerary">
      <Td width={"15%"}>
        <Text textColor="dark" textWeight="600">
          {indexToLetter(index)}
        </Text>
      </Td>
      <Td width={"15%"}>
        <Text textColor="dark" textWeight="600">
          {item?.locationInfo?.postName}
        </Text>
      </Td>
      <Td width={"7%"}>
        <Text textColor={"dark"}>{item?.locationInfo?.postCode}</Text>
      </Td>
      <Td width={"25%"}>
        <Text textColor={"dark"}>{item?.locationInfo?.address}</Text>
      </Td>
      <Td width={"15%"}>
        <Text textColor={"dark"}>{item?.locationInfo?.country?.name}</Text>
      </Td>
      <Td width={"15%"}>
        <Text textColor="dark" textWeight="600" d="flex">
          {moment(item?.loadingDate)
            ?.tz(item?.locationInfo?.timezone!)
            ?.format("D MMM YYYY")}
          {order?.getOrder?.transports && canChangeLocationPoint && (
            <Text
              m={{ l: "10px" }}
              textSize="14"
              textColor="brand600"
              hoverTextColor="brand700"
              cursor="pointer"
              className="order-itinerary-edit"
              onClick={() => {
                order?.getOrder?.transports &&
                  modalData({
                    msg: "Change pickup date",
                    name: "pickupDate",
                    returned_value: null,
                    props: {
                      loadingDate: item?.loadingDate,
                      canChangeLocationPoint,
                      id: item?.id,
                      orderCode: order?.getOrder?.code,
                      refetchOrder,
                      me,
                      timezone: item?.locationInfo?.timezone,
                    },
                  });
              }}
            >
              {renderIcon("Pen")} Edit
            </Text>
          )}
        </Text>
      </Td>
      <Td width={"16%"}>
        <Text textColor={"dark"}>
          {moment(item?.loadingDate)
            ?.tz(item?.locationInfo?.timezone!)
            ?.format("HH:mm")}{" "}
          {item?.locationInfo?.timezone}
        </Text>
      </Td>
      <Td width={"16%"}>
        <Text textColor={"dark"}>{item?.note || "-"}</Text>
      </Td>
      <Td width={"7%"}>
        <Div onClick={() => handleUpdateState(!isCompleted, item?.id!)}>
          <Checkbox checked={isCompleted} />
        </Div>
      </Td>
    </Tr>
  );
};
