import { useReactiveVar } from "@apollo/client";
import { Col, Div, Icon, Row, Text } from "atomize";
import React from "react";
import { modalData } from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const ConfirmModal: React.FC = () => {
  const { name, msg, props } = useReactiveVar(modalData);
  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "confirmModal")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "confirmModal" && (
        <Div
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row>
            <Col d="flex" align="center">
              {props.icon && (
                <Icon
                  name={props.icon}
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color={props.iconColor}
                />
              )}
              <Text
                textSize="201"
                textAlign="center"
                textWeight="700"
                textColor="dark"
              >
                {msg}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Col d="flex">
                  <Text m={{ t: "0.5rem", b: "2rem" }}>{props.subtext}</Text>
                </Col>
              </Row>

              <Row>
                {props.primaryBtn && (
                  <Col size="auto">
                    <PrimaryBtn
                      w="120px"
                      handleSubmit={props.handleSubmit}
                      isLoading={props.isLoading}
                      text={props.primaryBtn}
                      styleBtn={
                        props.primaryBtnColor
                          ? {
                              backgroundColor: props.primaryBtnColor,
                            }
                          : {}
                      }
                    />
                  </Col>
                )}
                {props.secondaryBtn && (
                  <Col size="auto">
                    <SecondaryBtn
                      w="120px"
                      handleSubmit={() =>
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        })
                      }
                      isLoading={props.isLoading}
                      text={props.secondaryBtn}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default ConfirmModal;
