import { useReactiveVar } from "@apollo/client";
import {
  Anchor,
  Col,
  Collapse,
  Div,
  Dropdown,
  Icon,
  Input,
  Row,
  Text,
} from "atomize";
import { omit } from "lodash";
import { Moment } from "moment";
import { tz } from "moment-timezone";
import { useState } from "react";
import { modalData } from "../../../App";
import {
  Company,
  CompanyStatus,
  Country,
  Location,
  LocationStatus,
  LocationType,
  useAddLocationPointsMutation,
  useCreateLocationMutation,
  useGetCompaniesLazyQuery,
  useGetCountriesLazyQuery,
  useGetLocationsLazyQuery,
  useGetMeQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import Border from "../../Border/Border";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";
import { existingItemSchema, newItemSchema } from "./schema/schema.validation";

const AddStopPoint: React.FC = () => {
  const [createLocation] = useCreateLocationMutation();
  const [addLocationPoints] = useAddLocationPointsMutation();
  const [existingLocationNotes, setExistingLocationNotes] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const modalDataVar = useReactiveVar(modalData);
  const { width } = useWindowDimensions();

  const [isCountriesDropdownOpen, setIsCountriesDropdownOpen] = useState(false);

  const [collapse, setCollapse] = useState({
    existingLocation: false,
    customLocation: false,
  });

  const queryShipper = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isShipperDropdownOpen, setIsShipperDropdownOpen] =
    useState<boolean>(false);

  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [customLocationNotes, setCustomLocationNotes] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postName, setPostName] = useState("");
  const [loadingDate, setLoadingDate] = useState<Date | Moment | string | null>(
    null
  );
  const [loadingTime, setLoadingTime] = useState<Date | Moment | string | null>(
    null
  );
  const [timezone, setTimezone] = useState<string>("");

  const {
    data: companiesShipperData,
    loading: loadingShipperCompanies,
    search: searchShipper,
    setSearch: setSearchShipper,
    page: searchShipperPage,
    setPage: setSearchShipperPage,
  } = useSearch(
    queryShipper,
    {
      limit: 5,
      status: [
        CompanyStatus.Placeholder,
        CompanyStatus.Active,
        CompanyStatus.PlaceholderWaitingConfirm,
      ],
    },
    300,
    isShipperDropdownOpen
  );

  const {
    data: countriesData,
    loading: loadingCountries,
    search: searchCountries,
    setSearch: setSearchCountries,
    page: searchCountriesPage,
    setPage: setCountriesPage,
  } = useSearch(
    queryCountries,
    {
      limit: 5,
    },
    300,
    isCountriesDropdownOpen
  );

  const queryLocation = useGetLocationsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [isShipperLocationDropdownOpen, setIsShipperLocationDropdownOpen] =
    useState<boolean>(false);
  const {
    data: locationsShipper,
    loading: locationsShipperLoading,
    search: searchLocationShipper,
    setSearch: setSearchLocationShipper,
    page: searchLocationShipperPage,
    setPage: setSearchLocationShipperPage,
  } = useSearch(
    queryLocation,
    {
      company_id: selectedCompany?.id,
      limit: 5,
      desc: false,
      status: [
        LocationStatus.Active,
        LocationStatus.Draft,
        LocationStatus.Placeholder,
      ],
    },
    300,
    isShipperLocationDropdownOpen
  );

  const { validator } = ValidationsFeHandler();

  const refetchOrderWithTimeout = (timeout: number) => {
    setTimeout(() => {
      modalDataVar?.props?.refetchOrder();
    }, timeout);
  };

  const handleSubmit = () => {
    if (collapse.existingLocation) {
      const loadingDateFormatted = tz(
        `${loadingDate} ${loadingTime}`,
        timezone
      ).format();

      const payload = {
        locationPoints: {
          order_id: modalDataVar.props.order.id,
          transport_id: modalDataVar.props.order.transports[0].id,
          locationPoints: [
            {
              loadingDate:
                loadingDateFormatted === "Invalid date" || !timezone
                  ? undefined
                  : loadingDateFormatted,
              location_id: selectedLocation?.id!,
              note: existingLocationNotes,
              order: (
                modalDataVar.props.order.transports[0].locationPoints || []
              ).length,
            },
          ],
        },
      };

      validator({
        data: payload,
        schema: existingItemSchema,
        success: async () => {
          await addLocationPoints({
            variables: payload,
            onCompleted: () => {
              refetchOrderWithTimeout(2000);
            },
          });
          modalData({
            ...modalDataVar.props.prevModalVar,
          });
          setCollapse({
            customLocation: false,
            existingLocation: false,
          });
          setSelectedLocation(undefined);
          setSelectedCompany(undefined);
          setLoadingDate(null);
          setTimezone("");
          setExistingLocationNotes("");
        },
      });

      return;
    }

    if (collapse.customLocation) {
      const customTimezone = meData?.getUser?.company?.location?.timezone!;

      const loadingDateFormatted = tz(
        `${loadingDate} ${loadingTime}`,
        customTimezone
      ).format();

      const payload = {
        location: {
          type: LocationType.Other,
          country_id: selectedCountry?.id,
          address: city || address ? `${city}, ${address}` : undefined,
          postCode,
          postName,
          company_id: modalDataVar.props.order.company_id,
          timezone: customTimezone,
          loadingDate:
            loadingDateFormatted === "Invalid date"
              ? undefined
              : loadingDateFormatted,
        },
      };

      validator({
        data: payload,
        schema: newItemSchema,
        success: async () => {
          const { data: createLocationData } = await createLocation({
            // @ts-ignore
            variables: omit(payload, ["location.loadingDate"]),
          });

          if (createLocationData?.createLocation?.id) {
            await addLocationPoints({
              onCompleted: () => {
                refetchOrderWithTimeout(2000);
              },
              variables: {
                locationPoints: {
                  order_id: modalDataVar.props.order.id,
                  transport_id: modalDataVar.props.order.transports[0].id,
                  locationPoints: [
                    {
                      loadingDate: loadingDateFormatted,
                      location_id: createLocationData?.createLocation.id,
                      note: customLocationNotes,
                      order:
                        modalDataVar.props.order.transports[0].locationPoints
                          .length,
                    },
                  ],
                },
              },
            });
          }

          modalData({
            ...modalDataVar.props.prevModalVar,
          });
          setCollapse({
            customLocation: false,
            existingLocation: false,
          });
          setSelectedCountry(undefined);
          setLoadingDate(null);
          setTimezone("");
          setCustomLocationNotes("");
          setCity("");
          setAddress("");
          setPostCode("");
        },
      });
    }
  };

  const { data: meData } = useGetMeQuery();
  const [isTimezoneLoadingDropdownOpen, setIsTimezoneLoadingDropdownOpen] =
    useState(false);

  const dateSelectionRow = (
    <Row>
      <Col {...(collapse?.existingLocation ? { size: "4" } : {})}>
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "1rem" }}
        >
          Loading date
        </Text>
        <Input
          w="100%"
          placeholder="DD/MM/YYYY"
          h="2.5rem"
          name="loading_date"
          type="date"
          value={loadingDate}
          onChange={(e: any) => {
            setLoadingDate(e.target.value);
          }}
        />
      </Col>
      <Col {...(collapse?.existingLocation ? { size: "4" } : {})}>
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "1rem" }}
        >
          Time
        </Text>
        <Input
          placeholder="16.00"
          w="100%"
          h="2.5rem"
          name="loading_time"
          type="time"
          value={loadingTime}
          onChange={(e: any) => {
            setLoadingTime(e.target.value);
          }}
        />
      </Col>
      {collapse?.existingLocation && (
        <Col size="4">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1rem" }}
          >
            Timezone
          </Text>
          <Dropdown
            w="100%"
            style={{
              PointerEvent:
                selectedLocation?.country?.code && selectedLocation?.timezone
                  ? "auto"
                  : "none",
            }}
            bg={
              selectedLocation?.country?.code && selectedLocation?.timezone
                ? "transparent"
                : "grey"
            }
            h="2.5rem"
            isOpen={isTimezoneLoadingDropdownOpen}
            onClick={
              selectedLocation?.country?.code && selectedLocation?.timezone
                ? () =>
                    setIsTimezoneLoadingDropdownOpen(
                      !isTimezoneLoadingDropdownOpen
                    )
                : ""
            }
            menu={
              <Div maxH="300px" overflow="visible scroll">
                {meData?.getUser?.company?.location?.timezone && (
                  <Anchor
                    d="flex"
                    p={{ y: "0.5rem", x: "0.75rem" }}
                    align="center"
                    onClick={() => {
                      setTimezone(meData?.getUser.company?.location?.timezone!);
                      setIsTimezoneLoadingDropdownOpen(false);
                    }}
                  >
                    <Text textColor="semiDark" hoverTextColor="dark">
                      {meData?.getUser?.company?.location?.timezone +
                        " - Local"}
                    </Text>
                  </Anchor>
                )}
                {selectedLocation?.timezone && (
                  <Anchor
                    d="flex"
                    p={{ y: "0.5rem", x: "0.75rem" }}
                    align="center"
                    onClick={() => {
                      setTimezone(selectedLocation?.timezone!);
                      setIsTimezoneLoadingDropdownOpen(false);
                    }}
                  >
                    <Text textColor="semiDark" hoverTextColor="dark">
                      {selectedLocation.timezone + " - Loading"}
                    </Text>
                  </Anchor>
                )}
              </Div>
            }
          >
            <Text
              style={{
                whiteSpace: "nowrap",
                overflowX: "hidden",
                WebkitLineClamp: "1",
                textOverflow: "ellipsis",
              }}
            >
              {timezone}
            </Text>
          </Dropdown>
        </Col>
      )}
    </Row>
  );

  return (
    <StyledModal
      isOpen={!!(modalDataVar.name === "addStopPoint")}
      onClose={() => {
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
        setCollapse({
          customLocation: false,
          existingLocation: false,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {modalDataVar.props && modalDataVar.name === "addStopPoint" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {modalDataVar.msg}
          </Text>
          <Text
            m={{ b: "0.5rem" }}
            textWeight="600"
            textSize="14"
            textAlign="left"
            textColor="gray"
          >
            You can either add an existing location or add new one manually.
          </Text>
          <Col d="flex" flexDir="column" style={{ paddingBottom: 20, gap: 5 }}>
            <Col>
              <Div
                d="flex"
                justify="space-between"
                pos="relative"
                w={"calc(100% + 1rem)"}
                left="-0.5rem"
                right="-0.5rem"
                bottom="-1px"
                align="center"
                h="2.5rem"
                rounded="4"
                hoverBg={"grey"}
                transition
                cursor="pointer"
                onClick={() => {
                  setCollapse({
                    customLocation: false,
                    existingLocation: !collapse.existingLocation,
                  });
                  setIsShipperDropdownOpen(false);
                }}
              >
                <Text textColor="dark" textWeight={500} m={{ l: "0.5rem" }}>
                  Add existing location
                </Text>
                {!collapse.existingLocation ? (
                  <Icon
                    name="Plus"
                    size="20px"
                    m={{ r: "0.5rem" }}
                    color="semiDark"
                  />
                ) : (
                  <Icon
                    name="DownArrow"
                    size="20px"
                    m={{ r: "0.5rem" }}
                    color="semiDark"
                  />
                )}
              </Div>
              <Border></Border>
              <Collapse
                transition={"height 20s "}
                isOpen={collapse.existingLocation}
                m={{ t: "1rem" }}
                overflow={
                  isShipperDropdownOpen || isShipperLocationDropdownOpen
                    ? "visible"
                    : "hidden"
                }
              >
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem" }}
                >
                  Select company
                </Text>
                <InputDropdown
                  page={searchShipperPage}
                  setPage={setSearchShipperPage}
                  totalPages={Math.ceil(
                    companiesShipperData?.getCompanies?.total / 5
                  )}
                  isLoading={loadingShipperCompanies}
                  displayResult={
                    selectedCompany && (
                      <Div
                        d="flex"
                        h="24px"
                        align="center"
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "hidden",
                          WebkitLineClamp: "1",
                          width: "100%",
                        }}
                      >
                        <PreloadingImage
                          imageSrc={selectedCompany?.logoPath || ""}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={selectedCompany?.name}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            minWidth: "24px",
                            minHeight: "24px",
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Div
                          style={{
                            width: "calc(100% - 70px)",
                          }}
                        >
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            textWeight="500"
                            m={{ l: "0.5rem" }}
                            style={{
                              textOverflow: "ellipsis",
                              overflowX: "hidden",
                            }}
                          >
                            {selectedCompany?.name}
                          </Text>
                        </Div>
                        {selectedCompany?.status === CompanyStatus.Active && (
                          <Icon
                            m={{ l: "1rem" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Div>
                    )
                  }
                  isOpen={isShipperDropdownOpen}
                  setIsOpen={setIsShipperDropdownOpen}
                  value={searchShipper}
                  handleChange={setSearchShipper}
                  menuList={companiesShipperData?.getCompanies.items?.map(
                    (item: any, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={async () => {
                          setSelectedCompany(item);

                          setIsShipperDropdownOpen(false);
                        }}
                      >
                        <PreloadingImage
                          imageSrc={item.logoPath}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={item.name}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          m={{ l: "10px" }}
                        >
                          {" "}
                          {item.name}{" "}
                        </Text>
                        {item.status === CompanyStatus.Active && (
                          <Icon
                            m={{ l: "auto" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Anchor>
                    )
                  )}
                />

                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Existing location
                </Text>

                <InputDropdown
                  displayResult={
                    selectedLocation && (
                      <Div
                        d="flex"
                        h="24px"
                        align="center"
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "hidden",
                          WebkitLineClamp: "1",
                        }}
                      >
                        <Text
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "hidden",
                          }}
                          textColor="semiDark"
                          textWeight="500"
                          m={{ r: "auto", l: "0rem" }}
                          textSize={14}
                        >
                          {`${
                            selectedLocation.title
                              ? selectedLocation.title + ","
                              : ""
                          } ${selectedLocation.address}, ${
                            selectedLocation.postCode
                          }, ${selectedLocation.postName}`}
                        </Text>
                      </Div>
                    )
                  }
                  page={searchLocationShipperPage}
                  setPage={setSearchLocationShipperPage}
                  totalPages={Math.ceil(
                    locationsShipper?.getLocations?.total / 5
                  )}
                  isLoading={locationsShipperLoading}
                  isOpen={isShipperLocationDropdownOpen}
                  setIsOpen={setIsShipperLocationDropdownOpen}
                  value={searchLocationShipper}
                  handleChange={setSearchLocationShipper}
                  menuList={locationsShipper?.getLocations.items?.map(
                    (item: any, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={() => {
                          setSelectedLocation(item);
                          setIsShipperLocationDropdownOpen(false);
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                        >
                          {" "}
                          {`${item.title ? item.title + "," : ""} ${
                            item.address
                          }, ${item.postCode}, ${item.postName}`}
                        </Text>
                      </Anchor>
                    )
                  )}
                />
                {dateSelectionRow}

                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Note
                </Text>
                <Input
                  value={existingLocationNotes}
                  onChange={(e: any) =>
                    setExistingLocationNotes(e.target.value)
                  }
                />
              </Collapse>
            </Col>
            <Col>
              <Div
                d="flex"
                justify="space-between"
                pos="relative"
                w={"calc(100% + 1rem)"}
                left="-0.5rem"
                right="-0.5rem"
                bottom="-1px"
                align="center"
                h="2.5rem"
                rounded="4"
                hoverBg={"grey"}
                transition
                cursor="pointer"
                onClick={() => {
                  setCollapse({
                    existingLocation: false,
                    customLocation: !collapse.customLocation,
                  });
                  setIsShipperDropdownOpen(false);
                }}
              >
                <Text textColor="dark" textWeight={500} m={{ l: "0.5rem" }}>
                  Add custom location
                </Text>
                {!collapse.customLocation ? (
                  <Icon
                    name="Plus"
                    size="20px"
                    m={{ r: "0.5rem" }}
                    color="semiDark"
                  />
                ) : (
                  <Icon
                    name="DownArrow"
                    size="20px"
                    m={{ r: "0.5rem" }}
                    color="semiDark"
                  />
                )}
              </Div>
              <Border></Border>
              <Collapse
                transition={"height 20s"}
                isOpen={collapse.customLocation}
                // m={{ t: "1rem" }}
                overflow={
                  isShipperDropdownOpen || isShipperLocationDropdownOpen
                    ? "visible"
                    : "hidden"
                }
              >
                {dateSelectionRow}
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Country
                </Text>
                <InputDropdown
                  isLoading={loadingCountries}
                  displayResult={
                    selectedCountry && (
                      <Div
                        d="flex"
                        h="24px"
                        align="center"
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "hidden",
                          WebkitLineClamp: "1",
                        }}
                      >
                        <Text
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "hidden",
                          }}
                          textColor="semiDark"
                          textWeight="500"
                          m={{ r: "auto", l: "0rem" }}
                          textSize={14}
                        >
                          {selectedCountry?.name}
                        </Text>
                      </Div>
                    )
                  }
                  isOpen={isCountriesDropdownOpen}
                  setIsOpen={setIsCountriesDropdownOpen}
                  value={searchCountries}
                  handleChange={setSearchCountries}
                  page={searchCountriesPage}
                  setPage={setCountriesPage}
                  totalPages={Math.ceil(
                    countriesData?.getCountries?.getLocations?.total / 5
                  )}
                  menuList={countriesData?.getCountries?.items?.map(
                    (item: Country, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={async () => {
                          setSearchCountries("");
                          setIsCountriesDropdownOpen(false);
                          setSelectedCountry(item);
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          m={{ l: "10px" }}
                        >
                          {" "}
                          {item.name}{" "}
                        </Text>
                      </Anchor>
                    )
                  )}
                />
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  City
                </Text>
                <Input
                  value={city}
                  onChange={(e: any) => setCity(e.target?.value)}
                />
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Street address
                </Text>
                <Input
                  value={address}
                  onChange={(e: any) => setAddress(e.target.value)}
                />
                <Row>
                  <Col>
                    <Text
                      textColor="semiDark"
                      textSize={12}
                      textWeight={500}
                      m={{ b: "0.25rem", t: "1rem" }}
                    >
                      Post name
                    </Text>
                    <Input
                      value={postName}
                      onChange={(e: any) => setPostName(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Text
                      textColor="semiDark"
                      textSize={12}
                      textWeight={500}
                      m={{ b: "0.25rem", t: "1rem" }}
                    >
                      Post code
                    </Text>
                    <Input
                      value={postCode}
                      onChange={(e: any) => setPostCode(e.target.value)}
                    />
                  </Col>
                </Row>
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Note
                </Text>
                <Input
                  value={customLocationNotes}
                  onChange={(e: any) => setCustomLocationNotes(e.target.value)}
                />
              </Collapse>
            </Col>
          </Col>
          <Row>
            <Col flexDir="row" d="flex" justify="center">
              <PrimaryBtn
                handleSubmit={() => {
                  handleSubmit();
                }}
                w="100%"
                styleBtn={{ marginRight: "1rem" }}
                text={"Add"}
              />
              <SecondaryBtn
                w="100%"
                handleSubmit={() => {
                  modalData({
                    ...modalDataVar.props.prevModalVar,
                  });
                  setCollapse({
                    customLocation: false,
                    existingLocation: false,
                  });
                }}
                text={"Back"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default AddStopPoint;
