export enum DefaultUserRole {
  ADMIN = 1, // system admin
  COMPANY_OWNER = 2, // company admin
  DISPATCHER = 3,
  DRIVER = 4,
  ACCOUNTANT = 5,
  SUPER_DISPATCHER = 6,
  USER = 10, // basic user with access to platform
  MODULE_PERMISSIONS_MANAGER = 11,
}

export enum ModuleName {
  ORDER_MANAGEMENT = "Order Management",
  DOCUMENTS_AND_INVOICING = "Documents & Invoicing",
  FLEET_TRACKING = "Fleet Tracking",
  CONTRACTOR_MARKETPLACE = "Contractor Marketplace",
  ORDER_RECOGNITION = "Order Recognition",
  SLOT_SCHEDULING = "Slot Scheduling",
  INBOUND_SALES_AI = "Inbound Sales AI",
}

export enum ModuleId {
  ORDER_MANAGEMENT = 1,
  DOCUMENTS_AND_INVOICING = 2,
  FLEET_TRACKING = 3,
  CONTRACTOR_MARKETPLACE = 4,
  ORDER_RECOGNITION = 5,
  SLOT_SCHEDULING = 6,
  INBOUND_SALES_AI = 7,
}
