import { useReactiveVar } from "@apollo/client";
import { Checkbox, Col, Div, Label, Radiobox, Row, Text } from "atomize";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  modalData,
  myOrderView,
  myOrdersPag,
  responseMsgs,
} from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { LocalStorageKeys } from "../../../helpers/constants";
import { MyOrderView, initialView } from "../../../helpers/initialData";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

type ViewState = Array<{
  checked: boolean;
  colW: number;
  field: string;
  name: string;
}>;

const rowsCountOptions = ["Auto", 10, 25, 50, 100, 200];

const ViewTable: FC = () => {
  const { name } = useReactiveVar(modalData);
  const myOrdersViewVar = useReactiveVar(myOrderView);
  const myOrdersPagVar = useReactiveVar(myOrdersPag);
  const { width, height } = useWindowDimensions();

  const [viewState, setViewState] = useState<ViewState>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [limitState, setLimitState] = useState<number | null | string>(null);

  const isViewTable = name === "viewTable";

  const handleSelectAll = () => {
    const newViewState = initialView.map((col) => ({
      ...col,
      checked: !selectAll,
    }));
    setViewState(newViewState);
    setSelectAll(!selectAll);
  };

  const handleApply = (e: any) => {
    if (viewState.filter((v) => v.checked).length < 3) {
      return responseMsgs([
        {
          type: "warning",
          string: "At least three checkboxes need to be checked.",
        },
      ]);
    }

    e.preventDefault();
    const limit = limitState === "OPD" ? null : Number(limitState);
    myOrdersPag({
      ...myOrdersPagVar,
      page: 1,
      limit: limit,
    });
    myOrderView(viewState);
    localStorage.setItem(LocalStorageKeys.LIMIT, `${limitState}`);
    localStorage.setItem(
      LocalStorageKeys.MY_ORDERS_VIEW,
      JSON.stringify(viewState)
    );
    modalData({
      name: "",
      msg: "",
      returned_value: null,
      props: null,
    });
  };

  useEffect(() => {
    if (isViewTable) {
      const limit = localStorage.getItem(LocalStorageKeys.LIMIT);
      if (limit) {
        setLimitState(limit);
      } else {
        setLimitState(100);
      }

      const myordersview = localStorage.getItem(
        LocalStorageKeys.MY_ORDERS_VIEW
      );
      myordersview
        ? setViewState([...JSON.parse(myordersview)])
        : setViewState(myOrdersViewVar);
    } else {
      setViewState([]);
      setLimitState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <StyledModal
      isOpen={isViewTable}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {isViewTable && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row d="column">
            <Col>
              <Text
                textSize="20"
                textAlign="left"
                textWeight="700"
                textColor="dark"
                m={{ x: "0rem", b: "0.5rem" }}
              >
                Control what you see
              </Text>
            </Col>
            <Col>
              <Text
                textSize="14"
                textAlign="left"
                textWeight="500"
                textColor="light"
                m={{ x: "0rem", t: "0rem", b: "2rem" }}
              >
                Pick columns you’d like to display in your view.
              </Text>
            </Col>
            <Col>
              <Row>
                <Col size={width > 400 ? "6" : "12"}>
                  <Label
                    align="center"
                    textSize={width > 550 ? 14 : 12}
                    textTransform="capitalize"
                    textWeight="500"
                    m={{ b: "0.5rem" }}
                  >
                    <Checkbox onChange={handleSelectAll} checked={selectAll} />{" "}
                    Select all
                  </Label>
                </Col>
              </Row>
            </Col>
            <Div h="0.5rem" />
            <Col m={{ t: "2rem" }}>
              <Row>
                {initialView.map((col: MyOrderView) => {
                  const isChecked =
                    viewState.find((v) => v.name === col.name)?.checked ??
                    false;

                  return (
                    <CheckBox
                      setViewState={setViewState}
                      viewState={viewState}
                      name={col.name}
                      check={isChecked}
                      key={col.name}
                    />
                  );
                })}
              </Row>
            </Col>
          </Row>

          <Row d="column">
            <Col>
              <Text
                textSize="14"
                textAlign="left"
                textWeight="500"
                textColor="semiDark"
                m={{ x: "0rem", t: "2rem", b: "2rem" }}
              >
                How many rows do you want to see per page?
              </Text>
            </Col>
            <Col>
              <Row>
                {rowsCountOptions?.map((option) => {
                  const isAuto = option === "Auto";
                  return (
                    <Col size="auto" key={option}>
                      <Label
                        align="center"
                        textWeight="600"
                        m={{ b: "0.5rem" }}
                      >
                        <Radiobox
                          onChange={() => {
                            setLimitState(isAuto ? "OPD" : option);
                          }}
                          checked={
                            String(limitState) ===
                            (isAuto ? "OPD" : String(option))
                          }
                          name="count"
                        />
                        {isAuto ? "Auto" : option}
                      </Label>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Div h="1.5rem" />
            <Col>
              <Row>
                <Col size="auto">
                  <PrimaryBtn
                    w="120px"
                    handleSubmit={(e: ChangeEvent<HTMLButtonElement>) =>
                      handleApply(e)
                    }
                    isLoading={false}
                    text={"Save"}
                  />
                </Col>
                <Col size="auto">
                  <SecondaryBtn
                    w="120px"
                    handleSubmit={() => {
                      setViewState([]);
                      modalData({
                        name: "",
                        msg: "",
                        returned_value: null,
                        props: null,
                      });
                    }}
                    isLoading={false}
                    text={"Cancel"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default ViewTable;

const CheckBox: FC<{
  name: string;
  check: boolean;
  viewState: ViewState;
  setViewState: Dispatch<SetStateAction<ViewState>>;
}> = ({ name, check, viewState, setViewState }) => {
  const [checked, setChecked] = useState(check);

  const handleChange = () => {
    const itemToUpdate = viewState.find((item) => item.name === name);

    if (itemToUpdate) {
      const updatedViewState = viewState.map((col) =>
        col.name === name ? { ...col, checked: !col.checked } : col
      );
      setViewState(updatedViewState);
    } else {
      const initialItemData = initialView.find((item) => item.name === name);
      if (initialItemData) {
        const updatedData = { ...initialItemData, checked: true };
        setViewState((prevViewState) => [...prevViewState, updatedData]);
      }
    }

    setChecked((prevChecked) => !prevChecked);
  };

  const { width } = useWindowDimensions();

  useEffect(() => {
    setChecked(check);
  }, [check]);

  return (
    <Col size={width > 400 ? "6" : "12"}>
      <Label
        align="center"
        textSize={width > 550 ? 14 : 12}
        textTransform="capitalize"
        textWeight="500"
        m={{ b: "0.5rem" }}
      >
        <Checkbox onChange={handleChange} checked={checked} />
        {name}
      </Label>
    </Col>
  );
};
