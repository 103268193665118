/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Dropdown, Icon, Input, Row, Text } from "atomize";
import moment, { tz } from "moment";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetNotificationMessagesDocument,
  NotificationMessageStatus,
  useCreateNotificationMessageMutation,
  useGetUsersLazyQuery,
  User,
  useUpdateNotificationMessageMutation,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";

const initial = {
  text: "",
  label: "",
  date: "",
  time: "",
  timezone: "",
  status: NotificationMessageStatus.Draft,
  recipients: [],
};

const schema = yup.object().shape({
  text: yup
    .string()
    .required({ code: "REQUIRED_FIELD", var: "Message", name: "Message" }),
  label: yup
    .string()
    .max(20, { name: "Label", code: "MAX_CHARACTERS", var: "20" })
    .required({ code: "REQUIRED_FIELD", var: "Label", name: "Label" }),
  recipients: yup
    .array()
    .min(1, { code: "NOT_ZERO", var: "Recipients", name: "Recipients" })
    .required("You can't leave this blank."),
  currentDate: yup.date().max(yup.ref("scheduleDate"), {
    name: "Schedule date",
    code: "DATE_MIN",
    var: "Current date",
  }),
  scheduleDate: yup.date().min(yup.ref("currentDate")),
});

const NewReminder: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);
  const [createReminder, { data, loading, error }] =
    useCreateNotificationMessageMutation({
      errorPolicy: "all",
    });
  const [
    updateReminder,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useUpdateNotificationMessageMutation({
    errorPolicy: "all",
  });

  const [reminder, setReminder] = useState<any>(initial);

  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);

  const queryUser = useGetUsersLazyQuery();
  const [usersInputOpen, setUsersInputOpen] = useState(false);
  const {
    data: dataUsers,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
    page: searchUsersPage,
    setPage: setSearchUsersPage,
  } = useSearch(
    queryUser,
    { limit: 3, company_id: props?.company_id },
    300,
    usersInputOpen
  );

  useEffect(() => {
    if (name === "newReminder") {
      setReminder({
        ...reminder,
        timezone: props.timezone,
        date: moment().format("YYYY-MM-DD"),
        time: moment().add(1, "hours").format("HH:mm"),
      });
    }
    if (name === "updateReminder") {
      setReminder({
        text: props.text,
        label: props.label,
        date: props.date,
        time: props.time,
        timezone: props.timezone,
        status: props.status,
        recipients: props.recipients,
      });
    }
  }, [name]);

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = {
      currentDate: moment().format(),
      scheduleDate: tz(
        `${reminder.date} ${reminder.time}`,
        reminder.timezone
      ).format(),
      text: reminder.text,
      label: reminder.label,
      recipients: reminder.recipients.map(
        (r: { user_id: Number }) => r.user_id
      ),
    };

    validator({
      schema: schema,
      data: {
        ...result,
      },
      success: () => {
        const { currentDate, ...rest } = result;
        name === "newReminder"
          ? createReminder({
              variables: {
                data: { ...rest, status: reminder.status },
              },
              refetchQueries: [GetNotificationMessagesDocument],
              awaitRefetchQueries: true,
            })
          : updateReminder({
              variables: {
                data: { ...rest, status: reminder.status },
                id: props.id,
              },
              refetchQueries: [GetNotificationMessagesDocument],
              awaitRefetchQueries: true,
            });
      },
    });
  };

  ErrorsBeHandler({
    error: error,
    dep: name === "newReminder",
  });

  SuccessBeHandler({
    data: data,
    code: "REMINDER_CREATED",
    dep: name === "newReminder",
    fn: () => {
      setReminder(initial);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  ErrorsBeHandler({
    error: errorUpdate,
    dep: name === "updateReminder",
  });

  SuccessBeHandler({
    data: dataUpdate,
    code: "REMINDER_UPDATE",
    dep: name === "updateReminder",
    fn: () => {
      setReminder(initial);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();
  const [IsBinVisible, setIsBinVisible] = useState<any>(null);

  return (
    <StyledModal
      isOpen={name === "newReminder" || name === "updateReminder"}
      onClose={() => {
        setReminder(initial);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {((props && name === "newReminder") || name === "updateReminder") && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {msg}
          </Text>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1rem" }}
          >
            Message
          </Text>
          <Input
            w="100%"
            placeholder="Take our truck to service"
            h="2.5rem"
            name={"text"}
            type="text"
            value={reminder.text}
            onChange={(e: any) => {
              setReminder({
                ...reminder,
                text: e.target.value,
              });
            }}
          />
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1rem" }}
          >
            Label
          </Text>
          <Input
            w="100%"
            placeholder="Label your reminders"
            h="2.5rem"
            name={"label"}
            type="text"
            value={reminder.label}
            onChange={(e: any) => {
              setReminder({
                ...reminder,
                label: e.target.value,
              });
            }}
          />
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Date
              </Text>
              <Input
                w="100%"
                placeholder="DD/MM/YYYY"
                h="2.5rem"
                name={"date"}
                type="date"
                value={reminder.date}
                onChange={(e: any) => {
                  setReminder({
                    ...reminder,
                    date: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Time
              </Text>
              <Input
                placeholder="16.00"
                w="100%"
                h="2.5rem"
                name={"Time"}
                type="time"
                value={reminder.time}
                onChange={(e: any) => {
                  setReminder({
                    ...reminder,
                    time: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Timezone
              </Text>
              <Input
                placeholder="16.00"
                disabled={true}
                bg={"semiGrey"}
                w="100%"
                h="2.5rem"
                name={"Timezone"}
                type="timezone"
                value={reminder.timezone}
                onChange={(e: any) => {}}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Recipients
              </Text>
              <Div
                style={{ zIndex: 979, marginBottom: "0.5rem" }}
                pos="relative"
              >
                <InputDropdown
                  page={searchUsersPage}
                  setPage={setSearchUsersPage}
                  totalPages={Math.ceil(dataUsers?.getUsers?.total / 3)}
                  showSearchField={true}
                  value={searchUsers}
                  h="48px"
                  addNew={false}
                  displayResult={
                    <Div
                      d="flex"
                      h="auto"
                      align="center"
                      className="horizontalScroll"
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "auto",
                        WebkitLineClamp: "1",
                        width: "100%",
                        maxWidth: `${width - 110}px`,
                        scrollbarWidth: "thin",
                      }}
                    >
                      {reminder.recipients.length ? (
                        reminder.recipients.map(
                          (user: {
                            img: string;
                            lastName: string;
                            firstName: string;
                            user_id: number;
                          }) => (
                            <Div
                              pos="relative"
                              onMouseOver={() => setIsBinVisible(user.user_id)}
                              onMouseOut={() => setIsBinVisible(null)}
                              m={{ r: "0.5rem" }}
                            >
                              <Div
                                pos="absolute"
                                right="-8px"
                                top="0px"
                                style={{
                                  backgroundColor: "red",
                                  width: "15px",
                                  height: "15px",
                                  display: `${
                                    IsBinVisible === user.user_id
                                      ? "flex"
                                      : "none"
                                  }`,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "6px",
                                }}
                              >
                                <Icon
                                  name="Delete"
                                  color="background"
                                  bg="background"
                                  hovercolor="brand900"
                                  size="10px"
                                  transition="0.3s opacity"
                                  onClick={() => {
                                    let f = reminder.recipients.filter(
                                      (u: { user_id: number }) =>
                                        user.user_id !== u.user_id
                                    );
                                    setReminder({
                                      ...reminder,
                                      recipients: f,
                                    });
                                    setUsersInputOpen(true);
                                  }}
                                />
                              </Div>
                              <PreloadingImage
                                imageSrc={""}
                                skeletonComponent={
                                  <Skeleton
                                    height={"24px"}
                                    width={"24px"}
                                    borderRadius={"24px"}
                                  />
                                }
                                imgComponent={
                                  <ShortNameLogo
                                    size="24px"
                                    name={`${user.firstName} ${user.lastName}`}
                                    textSize={12}
                                  />
                                }
                                imageStyle={{
                                  minWidth: "24px",
                                  minHeight: "24px",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "24px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </Div>
                          )
                        )
                      ) : (
                        <Text
                          textColor={`semiLight`}
                          textWeight="500"
                          m={{ r: "auto" }}
                        >
                          {`Select contact name`}
                        </Text>
                      )}
                    </Div>
                  }
                  isLoading={loadingUsers}
                  isOpen={usersInputOpen}
                  setIsOpen={setUsersInputOpen}
                  handleChange={setSearchUsers}
                  menuList={
                    dataUsers &&
                    dataUsers?.getUsers.items?.map((user: User, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        justify="space-between"
                        onClick={() => {
                          if (
                            !reminder.recipients.find(
                              (u: { user_id: number }) => user.id === u.user_id
                            )
                          ) {
                            setReminder({
                              ...reminder,
                              recipients: [
                                ...reminder.recipients,
                                {
                                  user_id: user.id,
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                                  img: user.profileImageUrl,
                                },
                              ],
                            });
                          } else {
                            let f = reminder.recipients.filter(
                              (u: { user_id: number }) => user.id !== u.user_id
                            );
                            setReminder({
                              ...reminder,
                              recipients: f,
                            });
                          }
                          // setSearchUsers("");
                          // setUsersInputOpen(false);
                        }}
                      >
                        <Text textColor="semiDark" hoverTextColor="dark">
                          {`${user.firstName} ${user.lastName}`}
                        </Text>
                        {reminder.recipients.find((u: { user_id: number }) => {
                          return user.id === u.user_id;
                        }) && (
                          <Icon
                            m={{ l: "0.5rem" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Anchor>
                    ))
                  }
                />
              </Div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "0.5rem" }}
              >
                Status
              </Text>
              <Dropdown
                h="40px"
                m={{ t: "0.5rem" }}
                isOpen={isTypeDropdownOpen}
                onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
                menu={
                  <Div maxH="300px" overflow="visible scroll">
                    {
                      // eslint-disable-next-line array-callback-return
                      Object.values(NotificationMessageStatus).map(
                        (name: NotificationMessageStatus, i: number) => {
                          return (
                            name !== NotificationMessageStatus.Deleted &&
                            name !== NotificationMessageStatus.Sent && (
                              <Anchor
                                d="flex"
                                p={{ y: "0.5rem", x: "0.75rem" }}
                                align="center"
                                key={i}
                                onClick={() => {
                                  setReminder({
                                    ...reminder,
                                    status: name,
                                  });
                                  setIsTypeDropdownOpen(false);
                                }}
                              >
                                <Text
                                  textColor="semiDark"
                                  hoverTextColor="dark"
                                >
                                  {name}
                                </Text>
                              </Anchor>
                            )
                          );
                        }
                      )
                    }
                  </Div>
                }
              >
                <Text
                  textColor={reminder.status ? "dark" : `semiLight`}
                  textWeight="500"
                  m={{ r: "auto", l: "0" }}
                  textSize={16}
                >
                  {reminder.status ? reminder.status : "Select type"}
                </Text>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <Div flexDir="row" d="flex" m={{ t: "1rem" }}>
                <PrimaryBtn
                  w="100%"
                  styleBtn={{ maxWidth: "150px", marginRight: "1rem" }}
                  handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                    handleSubmit(e)
                  }
                  isLoading={loading || loadingUpdate}
                  text={
                    name === "updateReminder"
                      ? "Update reminder"
                      : "Create reminder"
                  }
                />
                <SecondaryBtn
                  w="100%"
                  styleBtn={{ maxWidth: "144px" }}
                  handleSubmit={() => {
                    setReminder(initial);
                    modalData({
                      name: "",
                      msg: "",
                      returned_value: null,
                      props: null,
                    });
                  }}
                  isLoading={loading || loadingUpdate}
                  text={"Cancel"}
                />
              </Div>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default NewReminder;
