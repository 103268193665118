import React from "react";
import { Row, Col, Text, Div } from "atomize";
import {
  GetOrderDocument,
  useAcceptBidMutation,
  GetReceivedBidsDocument,
  GetMarketOrdersDocument,
  GetActivityLogsDocument,
} from "../../../generated/graphql";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import SuccessBtn from "../../SuccessBtn/SuccessBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import renderIcon from "../../../assets/Icons";
import { modalData } from "../../../App";
import { useReactiveVar } from "@apollo/client";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { StyledModal } from "../utils/helpers";

const AcceptBid: React.FC = () => {
  const modalDataVar = useReactiveVar(modalData);
  const [
    acceptBid,
    { data: bidData, loading: loadingBidData, error: errorBidData },
  ] = useAcceptBidMutation();

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    acceptBid({
      variables: { id: modalDataVar.props.id },
      refetchQueries: [
        GetOrderDocument,
        GetReceivedBidsDocument,
        GetMarketOrdersDocument,
        GetActivityLogsDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  ErrorsBeHandler({
    error: errorBidData,
    dep: modalDataVar.name === "acceptBid",
  });

  SuccessBeHandler({
    data: bidData,
    dep: modalDataVar.name === "acceptBid",
    code: "ACCEPTED_BID",
    fn: () => {
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(modalDataVar.name === "acceptBid")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {modalDataVar.props && modalDataVar.name === "acceptBid" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Div d="flex" justify="center">
            {renderIcon("Success")}
          </Div>
          <Row>
            <Col>
              <Text
                textColor={"dark"}
                textWeight="500"
                m={{ t: "0.5rem", b: "1.75rem" }}
                textAlign="center"
              >
                Are you sure you want to accept this bit?
              </Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col size="12" flexDir="row" d="flex" justify="center">
              <SuccessBtn
                w="100%"
                styleBtn={{ maxWidth: "144px", marginRight: "1rem" }}
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loadingBidData}
                text={"Accept"}
              />
              <SecondaryBtn
                w="100%"
                styleBtn={{ maxWidth: "144px" }}
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loadingBidData}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default AcceptBid;
