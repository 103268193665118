import React from "react";
import { Button, Icon } from "atomize";
import { Document } from "../../../generated/graphql";
import { GlobalTheme } from "../../../components/GlobalTheme";
import Td from "../../../components/TableComponents/Td";
import Tr from "../../../components/TableComponents/Tr";
import { downloadFromUrl } from "../../../helpers/functions";

const DocumentsRowActions: React.FC<{ document: Document }> = ({
  document,
}) => {
  return (
    <Tr
      styleTr={{
        borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
        borderRight: `1px solid ${GlobalTheme.colors.grey}`,
        background: GlobalTheme.colors.background,
      }}
    >
      <Td className="no_p" isActionTd={true} disableReadMore>
        <Button
          h="28px"
          w="32px"
          bg="white"
          textSize="14"
          hoverBg="info200"
          textColor="semiDark"
          hoverTextColor="brand600"
          textWeight="600"
          border="1px solid"
          borderColor="greyBorder"
          shadow={GlobalTheme.shadows[1]}
          type="submit"
          download
          onClick={() => {
            document.fileUrl &&
              document.fileName &&
              downloadFromUrl(document.fileUrl, document.fileName);
          }}
        >
          <Icon name="Download" color="semiDark" size="16px" />
        </Button>
      </Td>
    </Tr>
  );
};

export default DocumentsRowActions;
