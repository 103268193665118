import { Anchor, Col, Text } from "atomize";
import { FC } from "react";
import { modalData } from "../../../App";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { AssignedUserType, GetOrderQuery } from "../../../generated/graphql";

interface Props {
  isLoading: boolean;
  order: GetOrderQuery | undefined;
  companyUserType: any;
}

const InternalOrderNumberColumn: FC<Props> = ({
  isLoading,
  companyUserType,
  order,
}) => {
  return (
    <Col size="auto" minW="0px" w="auto">
      <>
        <Text
          textSize={12}
          textColor="semiDark"
          textWeight="500"
          m={{ b: "0.5rem" }}
        >
          Internal order number
        </Text>
        {!isLoading ? (
          <Anchor
            textSize={20}
            transition
            textColor={
              companyUserType.includes(AssignedUserType.Transporter)
                ? order?.getOrder.transporterCompany_internalCode !== null
                  ? "semiDark"
                  : "semiLight"
                : companyUserType.includes(AssignedUserType.Owner)
                ? order?.getOrder?.internalCode !== null
                  ? "semiDark"
                  : "semiLight"
                : companyUserType.includes(AssignedUserType.Shipper)
                ? order?.getOrder?.transports![0]
                    .shipperCompany_internalCode !== null
                  ? "semiDark"
                  : "semiLight"
                : companyUserType.includes(AssignedUserType.Receiver)
                ? order?.getOrder?.transports![0]
                    .receiverCompany_internalCode !== null
                  ? "semiDark"
                  : "semiLight"
                : "semiLight"
            }
            hoverTextColor={"dark"}
            d="block"
            textWeight="400"
            onClick={() => {
              modalData({
                msg: "Create internal number",
                name: "internalNumber",
                returned_value: null,
                props: {
                  transport_id: 1,
                  order_id: order?.getOrder.id,
                  companyUserType,
                },
              });
            }}
          >
            {companyUserType.includes(AssignedUserType.Transporter)
              ? order?.getOrder.transporterCompany_internalCode !== null
                ? order?.getOrder?.transporterCompany_internalCode
                : "None"
              : companyUserType.includes(AssignedUserType.Owner)
              ? order?.getOrder?.internalCode !== null
                ? order?.getOrder?.internalCode
                : "None"
              : companyUserType.includes(AssignedUserType.Shipper)
              ? order?.getOrder?.transports![0].shipperCompany_internalCode !==
                null
                ? order?.getOrder?.transports![0].shipperCompany_internalCode
                : "None"
              : companyUserType.includes(AssignedUserType.Receiver)
              ? order?.getOrder?.transports![0].receiverCompany_internalCode !==
                null
                ? order?.getOrder?.transports![0].receiverCompany_internalCode
                : "None"
              : "None"}
          </Anchor>
        ) : (
          <Skeleton width="80px" height="32px" />
        )}
      </>
    </Col>
  );
};

export default InternalOrderNumberColumn;
