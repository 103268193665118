import { NotificationType } from "../../../generated/graphql";

export const notificationsIcon = (
  type: NotificationType,
  data: any
) => {
  switch (type) {
    case NotificationType.OrderStatus:
      return "Delivery";
    case NotificationType.Document:
      const type = JSON.parse(data).type;
      if (type === "INVOICE") {
        return "Documents";
      } else {
        return "Delivery";
      }
    case NotificationType.Message:
      return "Message";
    case NotificationType.DeliveryChangeRequest:
      return "AlertSchedule";
    case NotificationType.Invoice:
      return "Documents";
    default:
      return "Bell";
  }
};

export interface NotificationTypeFilterOption {
  label: string;
  value: NotificationType[];
  isSelected: boolean;
}
