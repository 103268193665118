import { setContext } from "@apollo/client/link/context";
import * as Sentry from "@sentry/react";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Styled from "./App.styles";
import AuthGuard from "./components/AuthGuard/AuthGuard";
import GuestGuard from "./components/GuestGuard/GuestGuard";
import AcceptBid from "./components/Modals/AcceptBid/AcceptBid";
import AssignUserToOrder from "./components/Modals/AssignUserToOrder/AssignUserToOrder";
import ChangeVehicleAddDriver from "./components/Modals/ChangeVehicleAddDriver/ChangeVehicleAddDriver";
import ConfirmModal from "./components/Modals/ConfirmModal/ConfirmModal";
import CreateFullPlaceholderCompany from "./components/Modals/CreateFullPlaceholderCompany/CreateFullPlaceholderCompany";
import CreateVehicle from "./components/Modals/CreateVehicle/CreateVehicle";
import DeclineBid from "./components/Modals/DeclineBid/DeclineBid";
import DeleteModal from "./components/Modals/DeleteModal/DeleteModal";
import DeleteOrder from "./components/Modals/DeleteOrder/DeleteOrder";
import FilterMarketTable from "./components/Modals/FilterMarketTable/FilterMarketTable";
import FiltersTable from "./components/Modals/FiltersTable/FiltersTable";
import FlagOrder from "./components/Modals/FlagOrder/FlagOrder";
import InternalNumber from "./components/Modals/InternalNumber/InternalNumber";
import InviteCompany from "./components/Modals/InviteCompany/InviteCompany";
import InviteUser from "./components/Modals/InviteUser/InviteUser";
import LocationModal from "./components/Modals/LocationModal/LocationModal";
import MakeBid from "./components/Modals/MakeBid/MakeBid";
import ReadMoreTd from "./components/Modals/ReadMoreTd/ReadMoreTd";
import Report from "./components/Modals/Report/Report";
import ReportBug from "./components/Modals/ReportBug/ReportBug";
import UserModal from "./components/Modals/UserModal/UserModal";
import ViewTable from "./components/Modals/ViewTable/ViewTable";
import GroupTable from "./components/Modals/GroupTable/GroupTable";
import Notification from "./components/Notification/Notification";
import { config } from "./config";
import { CompanyStatus } from "./generated/graphql";
import ScrollToTop from "./helpers/ScrollToTop";
import {
  MyOrderView,
  initialGetOrders,
  initialMarketQueryLink,
  initialMarketQueryPagination,
  initialView,
} from "./helpers/initialData";
import ActivateCompany from "./pages/ActivateCompany/ActivateCompany";
import ActivateUser from "./pages/ActivateUser/ActivateUser";
import Bids from "./pages/Bids/Bids";
import Market from "./pages/Bids/Market";
import PlacedBids from "./pages/Bids/PlacedBids";
import ReceivedBids from "./pages/Bids/ReceivedBids";
import CreateDocument from "./pages/CreateDocument/CreateDocument";
import Documents from "./pages/Documents/Documents";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Inbox from "./pages/Inbox/Inbox";
import MyOrders from "./pages/MyOrders/MyOrders";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import Page404 from "./pages/Page404/Page404";
import CheckOrder from "./pages/ReceivePricingBids/Steps/CheckOrder";
import CreateOrder from "./pages/ReceivePricingBids/Steps/CreateOrder";
import RegisterUser from "./pages/RegisterUser/RegisterUser";
import ResetEmail from "./pages/ResetEmail/ResetEmail.";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Settings from "./pages/Settings/Settings";
import SettingsCompany from "./pages/SettingsCompany/SettingsCompany";
import SettingsIndividual from "./pages/SettingsIndividual/SettingsIndividual";
import SettingsLocations from "./pages/SettingsLocations/SettingsLocations";
import SettingsTeam from "./pages/SettingsTeam/SettingsTeam";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";

import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "apollo-link-ws";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  makeVar,
  split,
} from "@apollo/client";
import AddExternalDriver from "./components/Modals/AddExternalDriver/AddExternalDriver";
import InvoiceCreateModal from "./components/Modals/InvoiceCreateModal";
import NewModulePermission from "./components/Modals/NewModulePermission";
import NewReminder from "./components/Modals/NewReminder/NewReminder";
import OrderType from "./components/Modals/OrderType/OrderType";
import RequestTransportChange from "./components/Modals/RequestTransportChange/RequestTransportChange";
import { DefaultUserRole, ModuleName } from "./enums";
import useLocalStorageVersioning from "./helpers/CustomHooks/useLocalStorageVersioning";
import { LocalStorageKeys } from "./helpers/constants";
import EditTransport from "./pages/ReceivePricingBids/Steps/EditTransport";
import Reminders from "./pages/Reminders/Reminders";
import SettingsDrivers from "./pages/SettingsDrivers/SettingsDrivers";
import SettingsExport from "./pages/SettingsExport";
import SettingsInvoicing from "./pages/SettingsInvoicing";
import SettingsModulePermissions from "./pages/SettingsModulePermissions";
import SettingsVehicles from "./pages/SettingsVehicles/SettingsVehicles";
import EditItinerary from "./components/Modals/EditItinerary/EditItinerary";
import AddStopPoint from "./components/Modals/AddStopPoint/AddStopPoint";

var createHost = require("cross-domain-storage/host");
export const storageHost = createHost([
  {
    origin: config.app_site_url,
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: config.static_site_url,
    allowedMethods: ["get"],
  },
]);

interface MsgsProps {
  type: string;
  string: string;
}

export const marketQueryPag = makeVar<any>(initialMarketQueryPagination);
export const marketQueryLink = makeVar<any>(initialMarketQueryLink);
export const myOrderView = makeVar<MyOrderView[]>(initialView);
export const myOrdersPag = makeVar<any>(initialGetOrders);
export const notificationsCount = makeVar<Number>(0);
export const notificationsCountSkip = makeVar<boolean>(true);
export const orderMsgCount = makeVar<Number>(0);
export const orderMsgCountSkip = makeVar<boolean>(true);

export const currentOrder = makeVar<{ id: null | number; chatIsOpen: boolean }>(
  { id: null, chatIsOpen: false }
);
export const responseMsgs = makeVar<MsgsProps[] | []>([]);
export const modalData = makeVar<any>({
  msg: "",
  name: "",
  returned_value: null,
  props: null,
});
export const lang = makeVar<"EN" | "SI">("EN");
export const path = makeVar<any>({
  current: "",
  from: "",
});

const App: React.FC = () => {
  console.log(process.env.REACT_APP_API_URL);
  console.log(process.env.REACT_APP_URL);

  useLocalStorageVersioning();

  const httpLink = new HttpLink({
    uri: config.api,
  });

  const token = localStorage.getItem(LocalStorageKeys.TOKEN);
  let wsLink: any;
  if (config.ws) {
    wsLink = new WebSocketLink({
      uri: config.ws, // use wss for a secure endpoint
      options: {
        reconnect: true,
        connectionParams: () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
  }
  // The split function takes three parameters:

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(LocalStorageKeys.TOKEN);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, splitLink]),
  });

  return (
    <Styled.App>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <GuestGuard path="/" exact component={() => null} />
            <GuestGuard path="/signin" exact component={SignIn} />
            <GuestGuard path="/signup" exact component={SignUp} />
            <GuestGuard
              path="/forgotpassword"
              exact
              component={ForgotPassword}
            />
            <GuestGuard
              path="/settings/security/resetpassword/:token"
              exact
              component={ResetPassword}
            />
            <GuestGuard
              path="/settings/team/activateuser/:token"
              exact
              component={ActivateUser}
            />
            <GuestGuard
              path="/settings/admin/activatecompany/:token"
              exact
              component={ActivateCompany}
            />
            <GuestGuard
              path="/register/user/:token"
              exact
              component={RegisterUser}
            />
            {/*<AuthGuard
              path="/bids"
              exact
              component={Bids}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/bids/receivedbids"
              exact
              component={ReceivedBids}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/bids/placedbids"
              exact
              component={PlacedBids}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/bids/market"
              exact
              component={Market}
              companyStatus={[CompanyStatus.Active]}
            />*/}
            <AuthGuard
              path="/myorder/:code"
              exact
              component={OrderDetails}
              companyStatus={[]}
            />
            <AuthGuard
              path="/myorders"
              exact
              component={MyOrders}
              companyStatus={[]}
            />
            <AuthGuard
              path="/inbox"
              exact
              component={Inbox}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/documents"
              component={Documents}
              exact
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/reminders"
              component={Reminders}
              exact
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/createdocument/:code?"
              component={CreateDocument}
              exact
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings"
              exact
              component={Settings}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/company"
              exact
              component={SettingsCompany}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/drivers"
              exact
              component={SettingsDrivers}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/vehicles"
              exact
              component={SettingsVehicles}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/team"
              exact
              component={SettingsTeam}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/invoicing"
              exact
              component={SettingsInvoicing}
              companyStatus={[CompanyStatus.Active]}
              moduleName={ModuleName.DOCUMENTS_AND_INVOICING}
            />
            <AuthGuard
              path="/settings/manage-module-permissions"
              exact
              component={SettingsModulePermissions}
              roleIds={[DefaultUserRole.MODULE_PERMISSIONS_MANAGER]}
            />
            <AuthGuard
              path="/settings/export"
              exact
              component={SettingsExport}
              companyStatus={[CompanyStatus.Active]}
              moduleName={ModuleName.DOCUMENTS_AND_INVOICING}
            />
            <AuthGuard
              path="/settings/locations"
              exact
              component={SettingsLocations}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/individual"
              exact
              component={SettingsIndividual}
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/settings/security/resetemail/:token"
              exact
              component={ResetEmail}
              companyStatus={[]}
            />
            <AuthGuard
              path="/createneworder/order/:code?/edit-transport"
              component={EditTransport}
              extraLayout="ReceivePricingBids"
              companyStatus={[CompanyStatus.Active]}
              exact
            />
            <AuthGuard
              path="/createneworder/checkorder"
              exact
              component={CheckOrder}
              extraLayout="ReceivePricingBids"
              companyStatus={[CompanyStatus.Active]}
            />
            <AuthGuard
              path="/createneworder/order/:code?"
              component={CreateOrder}
              extraLayout="ReceivePricingBids"
              companyStatus={[CompanyStatus.Active]}
            />
            <Route component={Page404} />
          </Switch>
          {/* Modals start*/}
          <Report />
          <CreateFullPlaceholderCompany />
          <MakeBid />
          <AcceptBid />
          <DeclineBid />
          <FlagOrder />
          <EditItinerary />
          <ChangeVehicleAddDriver />
          <ViewTable />
          <GroupTable />
          <FiltersTable />
          <ReadMoreTd />
          <CreateVehicle />
          <AddStopPoint />
          <InviteUser />
          <InviteCompany />
          <DeleteOrder />
          <AssignUserToOrder />
          <LocationModal />
          <InternalNumber />
          <UserModal />
          <ReportBug />
          <FilterMarketTable />
          <DeleteModal />
          <ConfirmModal />
          <InvoiceCreateModal />
          <OrderType />
          <RequestTransportChange />
          <NewReminder />
          <AddExternalDriver />
          <NewModulePermission />
        </BrowserRouter>
        <Notification />

        {/* Modals end*/}
      </ApolloProvider>
    </Styled.App>
  );
};

export default Sentry.withProfiler(App);
