import { Anchor, Col, Row, Tag, Text } from "atomize";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Section from "../../../components/Section/Section";
import Skeleton from "../../../components/Skeleton/Skeleton";
import Tbody from "../../../components/TableComponents/Tbody";
import Td from "../../../components/TableComponents/Td";
import Th from "../../../components/TableComponents/Th";
import Thead from "../../../components/TableComponents/Thead";
import Tr from "../../../components/TableComponents/Tr";
import OrderDocumentRow from "../../../components/Tables/OrderDocumentRow";
import {
  CompanyStatus,
  GetDocumentsQuery,
  GetMeQuery,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import DocumentsRowActions from "../../Documents/rows/DocumentRowActions";

interface Props {
  isLoading: boolean;
  documents: GetDocumentsQuery | undefined;
  documentsLoading: boolean;
  me: GetMeQuery | undefined;
  code: string;
}

const DocumentsTable: FC<Props> = ({
  isLoading,
  documents,
  documentsLoading,
  me,
  code,
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();

  const [documentItems, setDocumentItems] = useState<any>(null);

  useEffect(() => {
    if (documents?.getDocuments?.items && documents?.getDocuments?.items.length) {
      setDocumentItems(documents.getDocuments.items.filter((document) => document.company_id === me?.getUser?.company_id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents?.getDocuments, me?.getUser])

  return (
    <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      <Col size="12">
        <Text textSize={20} textColor="dark">
          Documents
        </Text>
      </Col>
      <Col size="12">
        <Section margin="1.25rem 0 1.25rem 0rem">
          {isLoading ? (
            <Skeleton height="100px" />
          ) : (
            <>
              {documentItems &&
              documentItems.length ? (
                <>
                  <Table
                    width={`calc(100% -  ${documentsLoading ? "0" : "0"})`}
                  >
                    <Tr hidBorderLast={true} className="border_none">
                      <Td width={"100%"} className="divider">
                        <Table minWidth="650px">
                          <Thead>
                            <Tr className="head" hidBorderLast={true}>
                              <Th width={"20%"}>
                                <Text
                                  textColor="dark"
                                  textWeight="500"
                                  d="inline!important"
                                >
                                  ID
                                </Text>
                              </Th>
                              <Th width={"20%"}>
                                <Text
                                  textColor="dark"
                                  textWeight="500"
                                  d="inline!important"
                                >
                                  Type
                                </Text>
                              </Th>
                              <Th width={"40%"}>
                                <Text
                                  textColor="dark"
                                  textWeight="500"
                                  d="inline!important"
                                >
                                  Creator
                                </Text>
                              </Th>
                              <Th width={"20%"}>
                                <Text
                                  textColor="dark"
                                  textWeight="500"
                                  d="inline!important"
                                >
                                  Date
                                </Text>
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody maxH="none">
                            {documentItems && (
                              <>
                                {documentItems.map(
                                  (document: any, i: number) => (
                                    <OrderDocumentRow
                                      document={document}
                                      key={i}
                                    ></OrderDocumentRow>
                                  )
                                )}
                              </>
                            )}
                          </Tbody>
                        </Table>
                      </Td>
                      {!documentsLoading && (
                        <Td width="50px" className="divider_right no_p">
                          <Table width="auto">
                            <Thead>
                              <Tr>
                                <Td className="head"></Td>
                              </Tr>
                            </Thead>
                            <Tbody maxH="none">
                              {documentItems?.map(
                                (row: any, i: number) => (
                                  <DocumentsRowActions document={row} key={i} />
                                )
                              )}
                            </Tbody>
                          </Table>
                        </Td>
                      )}
                    </Tr>
                    {me?.getUser.company?.status === CompanyStatus.Active && (
                      <Tr styleTr={{ border: "none" }}>
                        <Td width={`calc(100% + 80px)`}>
                          <Anchor
                            textColor="brand600"
                            textWeight="600"
                            onClick={() => {
                              history.push(`/createdocument/${code}`);
                            }}
                          >
                            Add document to this order {"->"}
                          </Anchor>
                        </Td>
                      </Tr>
                    )}
                  </Table>
                </>
              ) : (
                <Tag
                  p={{ x: "1rem", y: "1rem" }}
                  w="100%"
                  d="inline-block"
                  textAlign="left"
                  textColor="light"
                >
                  There are no documents yet.
                  {me?.getUser.company?.status === CompanyStatus.Active && (
                    <Anchor
                      p={{ l: "0.25rem" }}
                      textColor="brand600"
                      textWeight="600"
                      onClick={() => {
                        history.push(`/createdocument/${code}`);
                      }}
                    >
                      Add document to this order
                    </Anchor>
                  )}
                </Tag>
              )}
            </>
          )}
        </Section>
      </Col>
    </Row>
  );
};

export default DocumentsTable;
