/* eslint-disable react-hooks/rules-of-hooks */
import { useReactiveVar } from "@apollo/client";
import { Anchor, Button, Col, Div, Icon, Row } from "atomize";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  currentOrder,
  modalData,
  notificationsCountSkip,
  orderMsgCount,
  orderMsgCountSkip,
} from "../../App";
import Chat from "../../components/Chat/Chat";
import Skeleton from "../../components/Skeleton/Skeleton";
import ActivityTable from "../../components/Tables/ActivityTable";
import BidsTable from "../../components/Tables/BidsTable";
import {
  ActivityLogQuery,
  AssignedUserType,
  BidQuery,
  CompanyStatus,
  CompanyType,
  DocumentQuery,
  Driver,
  NotificationType,
  OrderStatus,
  SqlModelStatus,
  UserStatus,
  Vehicle,
  useAssignUserMutation,
  useDeleteVehicleMutation,
  useGetActivityLogsLazyQuery,
  useGetDocumentsLazyQuery,
  useGetLocationLazyQuery,
  useGetMeQuery,
  useGetNotificationsQuery,
  useGetOrderQuery,
  useGetOverlappedOrdersLazyQuery,
  useGetReceivedBidsLazyQuery,
  useGetUsersLazyQuery,
  useGetVehiclesLazyQuery,
  useRespondToChangeRequestMutation,
  useUpdateOrderStatusMutation,
} from "../../generated/graphql";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import { useSearch } from "../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";

import {
  initialActivityQueryPagination,
  initialGetNotifications,
  initialGetOrderDocuments,
  initialReceivedOrderQueryPagination,
} from "../../helpers/initialData";
import * as Styled from "./OrderDetails.styles";
import ActionDropdownMenu from "./components/ActionDropdownMenu";
import DispatcherDropdownColumn from "./components/DispatcherDropdownColumn";
import DocumentsTable from "./components/DocumentsTable";
import InternalOrderNumber from "./components/InternalOrderNumberColumn";
import LineSeparator from "./components/LineSeparator";
import Notifications from "./components/Notifications";
import OrderDescription from "./components/OrderDescription";
import OrderGroupTable from "./components/OrderGroupTable";
import OrderItinerary from "./components/OrderItinerary";
import OrderNumber from "./components/OrderNumber";
import PartiesInvolved from "./components/PartiesInvolved";
import StatusDropdownColumn from "./components/StatusDropdownColumn";
import TransportsDetailTable from "./components/TransportsDetailTable";
import VehicleDropdownColumn from "./components/VehicleDropdownColumn";
import { userHasModule } from "../../helpers/functions";
import { ModuleName } from "../../enums";

const OrderDetails: React.FC = () => {
  // Window width
  const { width } = useWindowDimensions();

  // URL params and history
  let { code } = useParams<{ code: string }>();
  const history = useHistory();

  // Refs
  const descriptionElement = useRef<HTMLParagraphElement>(null);

  // Reactive variables
  const currentOrderVar = useReactiveVar(currentOrder);
  const orderMsgCountVar = useReactiveVar(orderMsgCount);

  // State variables
  const [actionDropdown, setActionDropdown] = useState(false);
  const [readMore, setReadMore] = useState<boolean | null>(null);
  const [statusDropdown, setStatusDropdown] = useState<boolean>(false);
  const [orderDocumentsPag, setOrderDocumentsPag] = useState<DocumentQuery>(
    initialGetOrderDocuments
  );
  const [activityPag, setActivityPag] = useState<ActivityLogQuery>(
    initialActivityQueryPagination
  );
  const [receivedOrderPag, setReceivedOrderPag] = useState<BidQuery>(
    initialReceivedOrderQueryPagination
  );
  const [isAssigned, setIsAssigned] = useState(false);
  const [userRole, setUserRole] = useState<number | null>(null);
  const [companyUserType, setCompanyUserType] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [parties, setParties] = useState<any>([]);
  const [locationPoints, setLocationPoints] = useState<any>([]);
  const [isVehiclesDropdownOpen, setIsVehiclesDropdownOpen] =
    useState<boolean>(false);
  const [isDispatcherDropdownOpen, setIsDispatcherDropdownOpen] =
    useState<boolean>(false);
  const [flags, setFlags] = useState({
    isOwner: false,
    isShipper: false,
    isReceiver: false,
    isTransport: false,
  });

  // Queries and mutations
  const { data: me, loading: meLoading } = useGetMeQuery();
  const [
    receivedBids,
    { data: bids, loading: bidsLoading, error: receivdErr },
  ] = useGetReceivedBidsLazyQuery({
    fetchPolicy: "network-only",
  });
  const [
    activityLog,
    { data: activity, loading: activityLoading, error: activityError },
  ] = useGetActivityLogsLazyQuery({
    fetchPolicy: "network-only",
  });
  const [
    getDocuments,
    { data: documents, loading: documentsLoading, error: documentsError },
  ] = useGetDocumentsLazyQuery({
    fetchPolicy: "network-only",
  });

  const userHasOrderManagementModule = userHasModule(
    me,
    ModuleName.ORDER_MANAGEMENT
  );

  const initialFn: any = (order: any) => {
    const f = {
      isOwner: me?.getUser?.company_id === order?.getOrder?.company_id,
      isShipper:
        me?.getUser?.company_id ===
        order?.getOrder?.transports?.[0]?.shipperCompany_id,
      isReceiver:
        me?.getUser?.company_id ===
        order?.getOrder?.transports?.[0]?.receiverCompany_id,
      isTransport:  me?.getUser?.company_id === order?.getOrder?.transporterCompany_id,
    }
    setFlags(f)

    let owner = order.getOrder.ownerCompany;
    let shipper = order.getOrder.transports[0].shipperCompany;
    let receiver = order.getOrder.transports[0].receiverCompany;
    let transporter = order.getOrder.transporterCompany;

    let partiesArray = [
      {
        ...owner,
        types: [
          AssignedUserType.Owner,
          owner?.id === shipper?.id && AssignedUserType.Shipper,
          owner?.id === receiver?.id && AssignedUserType.Receiver,
          owner?.id === transporter?.id && AssignedUserType.Transporter,
        ].filter(Boolean),
        isMyCompany: owner?.id === me?.getUser.company?.id,
      },
      {
        ...shipper,
        types: [
          shipper?.id === owner?.id && AssignedUserType.Owner,
          AssignedUserType.Shipper,
          shipper?.id === receiver?.id && AssignedUserType.Receiver,
          shipper?.id === transporter?.id && AssignedUserType.Transporter,
        ].filter(Boolean),
        isMyCompany: shipper?.id === me?.getUser.company?.id,
      },
      {
        ...receiver,
        types: [
          receiver?.id === owner?.id && AssignedUserType.Owner,
          AssignedUserType.Receiver,
          receiver?.id === shipper?.id && AssignedUserType.Shipper,
          receiver?.id === transporter?.id && AssignedUserType.Transporter,
        ].filter(Boolean),
        isMyCompany: receiver?.id === me?.getUser.company?.id,
      },
      transporter && {
        ...transporter,
        types: [
          transporter?.id === owner?.id && AssignedUserType.Owner,
          AssignedUserType.Transporter,
          transporter?.id === shipper?.id && AssignedUserType.Shipper,
          transporter?.id === receiver?.id && AssignedUserType.Receiver,
        ].filter(Boolean),
        isMyCompany: transporter?.id === me?.getUser.company?.id,
      },
    ].filter(Boolean);

    let finalPartiesArray: any[] = [];

    for (let i = 0; i < partiesArray.length; i++) {
      const party = partiesArray[i];
      if (i === 0) {
        finalPartiesArray?.push(party);
      }
      if (finalPartiesArray.length > 0) {
        const p = finalPartiesArray.filter(
          (party_two: any) => party_two.id === party?.id
        );
        if (p.length === 0) {
          finalPartiesArray?.push(party);
        }
      }
    }
    setParties(finalPartiesArray);
    setUserRole(Number(me?.getUser.roles![0].id));

    const array = [
      me?.getUser.company?.id === order?.getOrder.company_id
        ? AssignedUserType.Owner
        : null,
      me?.getUser.company?.id ===
      order?.getOrder.transports[0].shipperCompany_id
        ? AssignedUserType.Shipper
        : null,
      me?.getUser.company?.id ===
      order?.getOrder.transports[0].receiverCompany_id
        ? AssignedUserType.Receiver
        : null,
      me?.getUser.company?.id === order?.getOrder.transporterCompany_id
        ? AssignedUserType.Transporter
        : null,
    ];
    const filtered = array.filter((el) => el != null);
    setCompanyUserType(filtered);
    return filtered;
  };

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
    refetch: refetchOrder,
  } = useGetOrderQuery({
    variables: { code: code },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onCompleted: (order) => {
      if (order?.getOrder?.transports) {
        const filtered = initialFn(order);

        if (
          (me?.getUser.company?.type === CompanyType.Transporter &&
            order.getOrder.status === OrderStatus.OnMarket) ||
          filtered.length > 0
        ) {
          if (filtered.length > 0) {
            setActivityPag({
              ...activityPag,
              order_id: Number(order.getOrder.id),
            });
          }
          getLoadingLocations({
            variables: {
              id: Number(order.getOrder.transports[0].loadingLocation_id),
            },
          });
          getUnloadingLocations({
            variables: {
              id: Number(order.getOrder.transports[0].unloadingLocation_id),
            },
          });

          if (me?.getUser.company?.id === order?.getOrder.company_id) {
            order.getOrder.status === OrderStatus.OnMarket &&
              setReceivedOrderPag({
                ...receivedOrderPag,
                order_id: Number(order.getOrder.id),
              });
          }
          setOrderDocumentsPag({
            ...orderDocumentsPag,
            order_id: Number(order.getOrder.id),
            company_id: me?.getUser.company?.id,
          });
        } else {
          history.push("/myorders");
        }

        if (me?.getUser.company?.status === CompanyStatus.Active) {
          currentOrder({
            id: order.getOrder.id,
            chatIsOpen: width > 1600 ? true : false,
          });
          notificationsCountSkip(false);
          orderMsgCountSkip(false);
        }
      }
      const newLocationPoints: any = [];
      if (
        userHasOrderManagementModule &&
        order?.getOrder?.tagOrders &&
        order?.getOrder?.tagOrders.length > 0
      ) {
        order.getOrder.tagOrders.forEach((tagOrder) => {
          tagOrder.transports &&
            tagOrder.transports.forEach((transport) => {
              transport.locationPoints &&
                transport.locationPoints.forEach((locationPoint) => {
                  newLocationPoints.push({
                    ...locationPoint,
                    order_id: tagOrder.id || null,
                    order_code: tagOrder.code || null,
                  });
                });
            });
        });
      } else if (order?.getOrder?.transports) {
        order.getOrder.transports.forEach((transport) => {
          transport.locationPoints &&
            transport.locationPoints.forEach((locationPoint) => {
              newLocationPoints.push({
                ...locationPoint,
                order_id: order.getOrder.id || null,
                order_code: order.getOrder.code || null,
              });
            });
        });
      }
      setLocationPoints(
        newLocationPoints.sort((a: any, b: any) => {
          const timeA: any = new Date(a.loadingDate || 0);
          const timeB: any = new Date(a.loadingDate || 0);
          return timeA - timeB;
        })
      );
    },
  });

  const { data: notificationData } = useGetNotificationsQuery({
    variables: {
      query: {
        ...initialGetNotifications,
        order_id: order?.getOrder.id,
        accepted: null,
        type: [
          NotificationType.DeliveryChangeRequest,
          NotificationType.OrderStatus,
        ],
      },
    },
    fetchPolicy: "network-only",
    skip: orderLoading,
  });

  const [
    acceptTransportRequest,
    {
      data: acceptTransportRequestData,
      error: acceptTransportRequestError,
      loading: acceptTransportRequestLoading,
    },
  ] = useRespondToChangeRequestMutation({ errorPolicy: "all" });

  ErrorsBeHandler({
    error: acceptTransportRequestError,
  });

  SuccessBeHandler({
    data: acceptTransportRequestData,
    code: "TRANSPORT_REQUEST",
    fn: () => {},
  });

  ErrorsBeHandler({
    error: orderError,
    fn: () => {
      history.push("/myorders");
    },
  });

  const [
    deleteVehicle,
    {
      data: deleteVehicleData,
      loading: deleteVehicleLoading,
      error: deleteVehicleError,
    },
  ] = useDeleteVehicleMutation();

  const [
    assignedUser,
    {
      data: assignUserData,
      loading: assignUserLoading,
      error: assignUserError,
    },
  ] = useAssignUserMutation();

  const [
    updateOrderStatus,
    { loading: updateOrderStatusLoading, error: updateOrderStatusError },
  ] = useUpdateOrderStatusMutation({ errorPolicy: "all" });

  const [
    getLoadingLocations,
    { data: locationLoading, loading: locationLoadingLoading },
  ] = useGetLocationLazyQuery();

  const [
    getUnloadingLocations,
    { data: locationUnloading, loading: locationUnloadingLoading },
  ] = useGetLocationLazyQuery();

  const queryVehicles = useGetVehiclesLazyQuery({
    fetchPolicy: "network-only",
  });

  const {
    data: vehicles,
    loading: loadingVehicles,
    search: searchVehicles,
    setSearch: setSearchVehicles,
    page: searchVehiclesPage,
    setPage: setSearchVehiclesPage,
  } = useSearch(
    queryVehicles,
    {
      limit: 5,
      company_id: me?.getUser.company?.id,
      status: [SqlModelStatus.Active],
    },
    300,
    isVehiclesDropdownOpen
  );

  const [getOverlappedOrders] = useGetOverlappedOrdersLazyQuery({
    fetchPolicy: "network-only",
  });

  const queryUsers = useGetUsersLazyQuery({ fetchPolicy: "network-only" });

  const {
    data: dispatchers,
    loading: dispatcherLoading,
    search: searchDispatcher,
    setSearch: setSearchDispatcher,
    page: searchDispatcherPage,
    setPage: setSearchDispatcherPage,
  } = useSearch(
    queryUsers,
    {
      limit: 5,
      company_id: me?.getUser.company?.id,
      status: [UserStatus.Active],
    },
    300,
    isDispatcherDropdownOpen
  );

  const displayDispatcher = order?.getOrder?.assignedUsers?.filter(
    (user) =>
      user.company_id === me?.getUser.company?.id &&
      user.type !== AssignedUserType.Driver
  );
  let displayDispatcherLast: any = undefined;

  if (displayDispatcher !== undefined) {
    displayDispatcherLast = displayDispatcher[displayDispatcher!.length - 1];
  }

  // Simple variables
  const ass: any =
    order &&
    order.getOrder &&
    order?.getOrder?.assignedUsers &&
    order?.getOrder?.assignedUsers.length > 0
      ? order?.getOrder?.assignedUsers
      : null;
  const isUserAdminRole = me?.getUser.roles![0].id === 1;
  const isUserCompanyAccountOwnerRole = me?.getUser.roles![0].id === 2;
  const isUserSuperDispatcherRole = me?.getUser.roles![0].id === 6;
  const userHasAllowedRole =
    isUserCompanyAccountOwnerRole ||
    isUserAdminRole ||
    isUserSuperDispatcherRole;
  const canEditOrder = flags.isOwner && (isAssigned || userHasAllowedRole);

  // Handlers
  const checkVehicleOrDriver = async ({
    vehicle,
    driver,
  }: {
    vehicle?: Vehicle;
    driver?: Driver;
  }) => {
    const query = {
      limit: 5,
      loadingTime:
        order?.getOrder?.transports?.[0]?.loadingTime ||
        order?.getOrder?.transports?.[0]?.requestedLoadingTime,
      unloadingTime:
        order?.getOrder?.transports?.[0]?.unloadingTime ||
        order?.getOrder?.transports?.[0]?.requestedUnloadingTime,
      ...(vehicle?.registration
        ? { vehicleRegistrations: [vehicle?.registration] }
        : {}),
      ...(driver?.id ? { driver_id: driver?.id } : {}),
    };

    const result = await getOverlappedOrders({
      variables: {
        query: query,
      },
    })?.then((res) => (res?.data?.getOverlappedOrders?.items?.length ?? 0) > 0);

    return result;
  };

  const handleOpenVehicleDriverAssignModal = (
    item: Vehicle,
    confirmedDriverSelection: boolean = false
  ) => {
    modalData({
      msg: "Add driver contact",
      name: "changeVehicleAddDriver",
      returned_value: null,
      props: {
        vehicle_id: item.id,
        order_id: order?.getOrder.id,
        driverUser_id: order?.getOrder.transports![0].driverUser_id
          ? order?.getOrder.transports[0]!.driverUser_id
          : item.driverUser_id,
        driver_owner: item.driverUser_id,
        id: order?.getOrder.transports![0].id,
        order_code: order?.getOrder.code,
        onUserItemClick: confirmedDriverSelection
          ? undefined
          : async (user: Driver) => {
              const check = await checkVehicleOrDriver({ driver: user });
              if (check) {
                return modalData({
                  msg: "Driver is already assigned",
                  name: "confirmModal",
                  returned_value: null,
                  props: {
                    icon: "AlertSolid",
                    iconColor: "warning700",
                    subtext:
                      "Current driver vehicle has multiple simultaneous transports!",
                    isLoading: false,
                    handleSubmit: () => {
                      handleOpenVehicleDriverAssignModal(item, true);
                    },
                    primaryBtn: "Confirm",
                    secondaryBtn: "Cancel",
                  },
                });
              } else {
                return "noOverlapping";
              }
            },
      },
    });
  };

  const handleSelectVehicle = async (item: Vehicle) => {
    if (await checkVehicleOrDriver({ vehicle: item })) {
      return modalData({
        msg: "Vehicle is already assigned",
        name: "confirmModal",
        returned_value: null,
        props: {
          icon: "AlertSolid",
          iconColor: "warning700",
          subtext:
            "Current assigned vehicle has multiple simultaneous transports!",
          isLoading: false,
          handleSubmit: () => {
            handleOpenVehicleDriverAssignModal(item);
          },
          primaryBtn: "Confirm",
          secondaryBtn: "Cancel",
        },
      });
    }

    handleOpenVehicleDriverAssignModal(item);
  };

  // BE handlers
  ErrorsBeHandler({
    error: deleteVehicleError,
    fn: () => {
      modalData({ msg: null, name: null, returned_value: null, props: null });
    },
  });

  ErrorsBeHandler({
    error: assignUserError,
    fn: () => {
      modalData({ msg: null, name: null, returned_value: null, props: null });
    },
  });

  ErrorsBeHandler({
    error: receivdErr,
  });

  ErrorsBeHandler({
    error: activityError,
  });

  ErrorsBeHandler({
    error: updateOrderStatusError,
  });

  ErrorsBeHandler({
    error: documentsError,
  });

  SuccessBeHandler({
    data: deleteVehicleData,
    code: "DELETE_VEHICLE",
    fn: () => {
      modalData({ msg: null, name: null, returned_value: null, props: null });
      setIsDispatcherDropdownOpen(false);
    },
  });

  SuccessBeHandler({
    data: assignUserData,
    code: "ASSIGN_USER",
    fn: () => {
      modalData({ msg: null, name: null, returned_value: null, props: null });
      setIsDispatcherDropdownOpen(false);
    },
  });

  // Effects
  useLayoutEffect(() => {
    if (
      descriptionElement?.current?.scrollHeight &&
      descriptionElement?.current?.clientHeight
    ) {
      if (
        descriptionElement?.current?.clientHeight >=
        descriptionElement?.current?.scrollHeight
      ) {
        setReadMore(null);
      } else {
        setReadMore(false);
      }
    }
  }, [order, width]);

  useDebouncedEffect(
    () => {
      setIsLoading(
        meLoading ||
          bidsLoading ||
          activityLoading ||
          documentsLoading ||
          orderLoading ||
          deleteVehicleLoading ||
          updateOrderStatusLoading ||
          locationLoadingLoading ||
          locationUnloadingLoading
      );
    },
    [
      meLoading,
      bidsLoading,
      activityLoading,
      documentsLoading,
      orderLoading,
      deleteVehicleLoading,
      updateOrderStatusLoading,
      locationLoadingLoading,
      locationUnloadingLoading,
    ],
    300
  );

  useEffect(() => {
    return () => {
      currentOrder({ id: null, chatIsOpen: false });
    };
  }, []);

  useEffect(() => {
    if (order && me) {
      let isAss = !!order?.getOrder?.assignedUsers?.filter(
        (user) => Number(user.user_id) === Number(me?.getUser.id)
      )[0];
      if (isAss) {
        setIsAssigned(isAss);
      } else {
        setIsAssigned(
          companyUserType.length > 0 &&
            !!me?.getUser.roles &&
            [2, 6].includes(me?.getUser.roles[0].id)
        );
      }
    }
  }, [order, me, companyUserType]);

  useEffect(() => {
    if (
      order?.getOrder.status === OrderStatus.Ready &&
      order?.getOrder.transports
    ) {
      const filtered = initialFn(order);
      if (filtered.length > 0 && !flags.isOwner) {
        setActivityPag({ ...activityPag, order_id: Number(order.getOrder.id) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.getOrder.status]);

  useEffect(() => {
    receivedBids({ variables: { query: { ...receivedOrderPag } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedOrderPag]);

  useEffect(() => {
    getDocuments({
      variables: {
        query: {
          ...orderDocumentsPag,
          company_id: order?.getOrder?.company_id,
        },
      },
    });
  }, [getDocuments, order?.getOrder?.company_id, orderDocumentsPag]);

  useEffect(() => {
    activityPag.order_id !== null &&
      activityLog({ variables: { query: { ...activityPag } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityPag]);

  return (
    <Div d="flex">
      <Styled.OrderDetails>
        <Row m={{ y: "1rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
          <Col>
            <Anchor
              textSize={12}
              textColor="primary"
              hoverTextColor="info700"
              textWeight="500"
              onClick={() => history.goBack()}
            >
              Go back
            </Anchor>
          </Col>
          <Col d="flex" justify="flex-end">
            {isLoading ? (
              <Skeleton height="2rem" width="84px" margin="0 0  0 0.5rem" />
            ) : (
              !(
                order?.getOrder.status === OrderStatus.Deleted ||
                me?.getUser.company?.status !== CompanyStatus.Active
              ) && (
                <ActionDropdownMenu
                  actionDropdown={actionDropdown}
                  setActionDropdown={setActionDropdown}
                  code={code}
                  order={order}
                  locationLoading={locationLoading}
                  locationUnloading={locationUnloading}
                  flags={flags}
                  userHasAllowedRole={userHasAllowedRole}
                  canEditOrder={canEditOrder}
                  isAssigned={isAssigned}
                  me={me}
                  locationPoints={locationPoints}
                  refetchOrder={refetchOrder}
                />
              )
            )}
            {!isLoading &&
              !(
                order?.getOrder.status === OrderStatus.Deleted ||
                me?.getUser.company?.status !== CompanyStatus.Active
              ) &&
              (flags.isOwner ||
                flags.isShipper ||
                flags.isReceiver ||
                flags.isTransport) &&
              (isAssigned || userHasAllowedRole) &&
              !currentOrderVar.chatIsOpen && (
                <Button
                  prefix={
                    <Icon
                      name="Message"
                      size="16px"
                      color="semiDark"
                      m={{ r: "0.5rem" }}
                    />
                  }
                  bg="background"
                  border="1px solid"
                  borderColor="greyBorder"
                  hoverBg="grey"
                  p={{ xs: "0.75rem" }}
                  textSize={12}
                  justify="center"
                  textColor="semiDark"
                  minW="100px"
                  h="2rem"
                  m={{ l: "0.5rem" }}
                  hoverShadow="2"
                  onClick={async () => {
                    currentOrder({
                      ...currentOrderVar,
                      chatIsOpen: !currentOrderVar.chatIsOpen,
                    });
                  }}
                >
                  <Div d="inline">Chat</Div>
                  {Number(orderMsgCountVar) > 0 && (
                    <Div
                      m={{ l: "0.25rem" }}
                      d="inline"
                      style={{
                        lineHeight: "1.5rem",
                        fontSize: "12px",
                        padding: "0 0.25rem",
                      }}
                      w="100%"
                      pos="relative"
                      minW="1.5rem"
                      h="1.5rem"
                      rounded="1.5rem"
                      bg="danger"
                      textColor="background"
                    >
                      {Number(orderMsgCountVar) > 99 ? 99 : orderMsgCountVar}
                    </Div>
                  )}
                </Button>
              )}
          </Col>
        </Row>
        {!!notificationData?.getNotifications.items?.length && (
          <Notifications
            me={me}
            notificationData={notificationData}
            loadingStates={{
              acceptTransportRequestLoading,
            }}
            callbackFunctions={{
              acceptTransportRequest,
            }}
            order={order}
          />
        )}
        <Row
          m={{ y: "0rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}
          justify="flex-start"
        >
          <OrderNumber isLoading={isLoading} code={order?.getOrder.code} />
          <Col size="1"></Col>
          {me?.getUser.company?.status === CompanyStatus.Active &&
            companyUserType.length !== 0 && (
              <InternalOrderNumber
                isLoading={isLoading}
                companyUserType={companyUserType}
                order={order}
              />
            )}
        </Row>
        {canEditOrder && order?.getOrder.status === OrderStatus.OnMarket && (
          <Row m={{ r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
            <Col>
              <BidsTable
                title="Bids received"
                bids={bids?.getReceivedBids}
                bidsLoading={isLoading || bidsLoading}
                query={receivedOrderPag}
                setQuery={setReceivedOrderPag}
                footer="pagination"
                noLink={true}
              />
            </Col>
          </Row>
        )}
        {isAssigned && (
          <Row
            m={{ t: "1rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}
            flexDir={width > 890 ? "row" : "column"}
          >
            <StatusDropdownColumn
              isLoading={isLoading}
              updateOrderStatusLoading={updateOrderStatusLoading}
              order={order}
              statusDropdown={statusDropdown}
              setStatusDropdown={setStatusDropdown}
              updateOrderStatus={updateOrderStatus}
            />

            <DispatcherDropdownColumn
              isLoading={isLoading}
              order={order}
              userHasAllowedRole={userHasAllowedRole}
              me={me}
              isAssigned={isAssigned}
              searchDispatcherPage={searchDispatcherPage}
              setSearchDispatcherPage={setSearchDispatcherPage}
              dispatchers={dispatchers}
              dispatcherLoading={dispatcherLoading}
              displayDispatcherLast={displayDispatcherLast}
              assignUserLoading={assignUserLoading}
              isDispatcherDropdownOpen={isDispatcherDropdownOpen}
              setIsDispatcherDropdownOpen={setIsDispatcherDropdownOpen}
              searchDispatcher={searchDispatcher}
              setSearchDispatcher={setSearchDispatcher}
              assignedUser={assignedUser}
              flags={flags}
            />

            <VehicleDropdownColumn
              isLoading={isLoading}
              userRole={userRole}
              searchVehiclesPage={searchVehiclesPage}
              vehicles={vehicles}
              flags={flags}
              order={order}
              me={me}
              searchVehicles={searchVehicles}
              loadingVehicles={loadingVehicles}
              deleteVehicleLoading={deleteVehicleLoading}
              isVehiclesDropdownOpen={isVehiclesDropdownOpen}
              activityPag={activityPag}
              setSearchVehiclesPage={setSearchVehiclesPage}
              setSearchVehicles={setSearchVehicles}
              setIsVehiclesDropdownOpen={setIsVehiclesDropdownOpen}
              handleSelectVehicle={handleSelectVehicle}
              deleteVehicle={deleteVehicle}
            />
          </Row>
        )}
        {order &&
          order?.getOrder &&
          order?.getOrder?.status === OrderStatus.InTransit &&
          !isLoading && <LineSeparator />}

        {(flags.isOwner ||
          flags.isShipper ||
          flags.isReceiver ||
          flags.isTransport) &&
          (isAssigned || userHasAllowedRole) && (
            <ActivityTable
              title="Activity log"
              activity={activity?.getActivityLogs}
              activityLoading={activityLoading}
              query={activityPag}
              setQuery={setActivityPag}
              footer="pagination"
            />
          )}

        <LineSeparator />

        {(order?.getOrder.description !== "" || orderLoading) && (
          <>
            <OrderDescription
              order={order}
              orderLoading={orderLoading}
              readMore={readMore}
              setReadMore={setReadMore}
            />

            <LineSeparator />
          </>
        )}

        <PartiesInvolved
          isLoading={isLoading}
          parties={parties}
          isAssigned={isAssigned}
          ass={ass}
          order={order}
          locationLoading={locationLoading}
          locationUnloading={locationUnloading}
        />

        <OrderItinerary
          locationPoints={locationPoints}
          order={order}
          isLoading={isLoading}
          me={me}
          refetchOrder={refetchOrder}
        />

        <TransportsDetailTable
          locationLoading={locationLoading}
          locationUnloading={locationUnloading}
          order={order}
          isLoading={isLoading}
        />

        {order?.getOrder?.tags?.[0] && order?.getOrder?.tagOrders && (
          <OrderGroupTable
            isLoading={isLoading}
            order={order}
            orderLoading={orderLoading}
          />
        )}

        {!isLoading && (
          <DocumentsTable
            isLoading={isLoading}
            documents={documents}
            documentsLoading={documentsLoading}
            me={me}
            code={code}
          />
        )}
      </Styled.OrderDetails>
      {companyUserType.length > 0 && (
        <Chat
          isLoading={isLoading}
          order_id={order?.getOrder.id}
          transport_id={order?.getOrder.transports![0].id}
          ownerCompany={order?.getOrder.ownerCompany}
          transporterCompany={order?.getOrder.transporterCompany}
          shipperCompany={order?.getOrder.transports![0].shipperCompany}
          receiverCompany={order?.getOrder.transports![0].receiverCompany}
          total_people={order?.getOrder.assignedUsers?.length}
        />
      )}
    </Div>
  );
};

export default OrderDetails;
