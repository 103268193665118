import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Row, Text } from "atomize";
import React, { useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetOrderDocument,
  GetOrdersDocument,
  useUpdateInternalMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  internalNumber: yup
    .string()
    .required({ name: "Internal number", code: "REQUIRED_FIELD" }),
});

const InternalNumber: React.FC = () => {
  const { name, msg, props } = useReactiveVar(modalData);

  const [
    updateOrder,
    {
      data: updateOrderData,
      loading: updateOrderLoading,
      error: updateOrderError,
    },
  ] = useUpdateInternalMutation();
  const { validator } = ValidationsFeHandler();
  const { width } = useWindowDimensions();

  const [internalNumber, setInternalNumber] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: { internalNumber: internalNumber },
      success: () => {
        updateOrder({
          variables: { id: props.order_id, code: internalNumber },
          refetchQueries: [GetOrderDocument, GetOrdersDocument],
          awaitRefetchQueries: true,
        });
      },
    });
  };

  ErrorsBeHandler({
    error: updateOrderError,
    dep: name === "internalNumber",
  });

  SuccessBeHandler({
    data: updateOrderData,
    code: "UPDATE_INTERNAL_NUMBER",
    dep: name === "internalNumber",
    fn: () => {
      setInternalNumber("");
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  return (
    <StyledModal
      isOpen={!!(name === "internalNumber")}
      onClose={() => {
        setInternalNumber("");
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "internalNumber" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            {msg}
          </Text>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ t: "0.25rem" }}
          >
            Basic modal body text.
          </Text>
          <Row>
            <Col>
              <Input
                placeholder="Internal number"
                name="internal_number"
                value={internalNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInternalNumber(e.target.value);
                }}
                textSize={"16"}
                type="text"
                h="40px"
                m={{ b: "2rem", t: "2rem" }}
              />
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={updateOrderLoading}
                text={"Create"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={updateOrderLoading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default InternalNumber;
