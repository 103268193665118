import { Anchor, Col, Div, Row, Tag, Text } from "atomize";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ActivityLog, ActivityLogListing } from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import Border from "../Border/Border";
import Pagination from "../Pagination/Pagination";
import Section from "../Section/Section";
import Skeleton from "../Skeleton/Skeleton";
import Table from "../TableComponents/Table";
import Tbody from "../TableComponents/Tbody";
import ActivityRow from "./ActivityRow";

interface ActivityLogsTableProps {
  activity?: ActivityLogListing;
  activityLoading: boolean;
  setQuery: (query?: any) => void;
  query: any;
  title: string;
  link?: string;
  footer: string;
}

const ActivityLogsTable: React.FC<ActivityLogsTableProps> = ({
  activity,
  activityLoading,
  setQuery,
  query,
  title,
  link,
  footer,
}) => {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (activity?.total && query?.limit) {
      setTotalPages(Math.ceil(activity.total / query.limit));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  return (
    <Row m={{ r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      <Col>
        <Div m={{ t: "2rem" }}>
          {(activity && activity.total && activity?.total > 0) ||
          activityLoading ? (
            <>
              <Div d="flex" m={{ b: "1rem" }}>
                <Text textSize={20} textColor="dark">
                  {title}
                </Text>
              </Div>
              {!activityLoading ? (
                <Section>
                  <Table width="100%" minWidth={"850px"}>
                    <Tbody maxH="none">
                      {activity?.items?.map((x: ActivityLog, i: number) => (
                        <ActivityRow key={i} activity={x} />
                      ))}
                    </Tbody>
                  </Table>
                </Section>
              ) : (
                <Skeleton height={`${50 * 5}px`} />
              )}
              {footer === "link" && activity?.total && activity?.total > 5 && (
                <>
                  <Anchor
                    cursor="pointer"
                    textSize={14}
                    textColor="primary"
                    m={{ l: "0.5rem" }}
                    fontWeight="600"
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      link && history.push(link);
                    }}
                  >
                    View all →
                  </Anchor>
                  <Border></Border>
                </>
              )}
              {footer === "pagination" && totalPages && (
                <Pagination
                  setQuery={setQuery}
                  query={query}
                  total={totalPages}
                />
              )}
            </>
          ) : (
            <>
              <Div>
                <Text textSize={20} textColor="dark" m={{ b: "1.25rem" }}>
                  {title}
                </Text>
                {activityLoading ? (
                  <Skeleton height={`${50 * 5}px`} />
                ) : (
                  <Tag
                    p={{ l: "1rem" }}
                    h="48px"
                    w="100%"
                    d="flex"
                    justify="start"
                    textColor="light"
                  >
                    There is no activity yet.
                  </Tag>
                )}
              </Div>
            </>
          )}
        </Div>
      </Col>
    </Row>
  );
};

export default ActivityLogsTable;
