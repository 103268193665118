import React, { useState } from "react";
import { Row, Col, Text, Input, Div, Anchor, Dropdown } from "atomize";
import { Country, useGetCountriesLazyQuery } from "../../../generated/graphql";
import InputDropdown from "../../InputDropdown/InputDropdown";
import { tz } from "moment";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";

interface SingUpProps {
  setLocation: (company: any) => void;
  location: any;
  handleSubmit: (e: any) => void;
  setStep: (step: number) => void;
}

const CreatePlaceholderLocation: React.FC<SingUpProps> = ({
  setLocation,
  location,
  handleSubmit,
  setStep,
}) => {
  const [isTimezoneOpen, setIsTimezoneOpen] = useState(false);

  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [countryInputOpen, setCountryInputOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, countryInputOpen);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setLocation({
      ...location,
      [target.name]: target.value,
    });
  };

  return (
    <>
      <Row flexDir="column" minW="100%">
        <Col m={{ b: "0.5rem" }} align="flex-end">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Country
          </Text>

          <InputDropdown
            h="48px"
            addNew={false}
            displayResult={
              <Div d="flex" align="center">
                <Text
                  textColor={
                    location.country.name !== "" ? "dark" : `semiLight`
                  }
                  textWeight="500"
                  m={{ r: "auto" }}
                  textSize={16}
                >
                  {location.country.name !== ""
                    ? location.country.name
                    : `Select country name`}
                </Text>
              </Div>
            }
            isLoading={loadingCountries}
            isOpen={countryInputOpen}
            setIsOpen={setCountryInputOpen}
            value={countryInput}
            handleChange={setCountryInput}
            menuList={countries?.getCountries?.items?.map(
              (country: Country, i: number) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={i}
                  align="center"
                  onClick={() => {
                    if (country.code) {
                      setLocation({
                        ...location,
                        country: {
                          id: country.id,
                          name: country.name,
                          code: country.code,
                        },
                        timezone: tz.zonesForCountry(country.code)[0],
                      });
                      setCountryInput("");
                      setCountryInputOpen(false);
                    }
                  }}
                >
                  <Text textColor="semiDark" hoverTextColor="dark">
                    {" "}
                    {`${country.name}`}
                  </Text>
                </Anchor>
              )
            )}
          />
        </Col>
        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Timezone
          </Text>
          <Dropdown
            w="auto"
            style={{
              PointerEvent: location.country.code ? "auto" : "none",
              margin: " 0",
            }}
            bg={location.country.code ? "transparent" : "grey"}
            h="3rem"
            isOpen={isTimezoneOpen}
            onClick={
              location.country.code
                ? () => setIsTimezoneOpen(!isTimezoneOpen)
                : ""
            }
            menu={
              location.country.code && (
                <Div maxH="300px" overflow="visible scroll">
                  {Array.from(
                    new Set([...tz.zonesForCountry(location.country.code)])
                  ).map((item: string, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        setLocation({
                          ...location,
                          timezone: item,
                        });
                        setIsTimezoneOpen(false);
                      }}
                    >
                      <Text textColor="semiDark" hoverTextColor="dark">
                        {item}
                      </Text>
                    </Anchor>
                  ))}
                </Div>
              )
            }
          >
            <Text
              textColor={location.timezone !== "" ? "dark" : `semiLight`}
              textWeight="500"
              m={{ r: "auto" }}
              textSize={16}
            >
              {location.timezone !== ""
                ? location.timezone
                : `Select country timezone`}
            </Text>
          </Dropdown>
        </Col>
        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Address
          </Text>
          <Input
            placeholder="Address"
            name="address"
            value={location.address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            textSize={"16"}
            h="48px"
            type="text"
          />
        </Col>

        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            City
          </Text>
          <Input
            placeholder="City"
            name="city"
            value={location.city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            textSize={"16"}
            type="text"
            h="48px"
          />
        </Col>

        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Post code
          </Text>
          <Input
            placeholder="Post code"
            name="postalCode"
            value={location.postalCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            textSize={"16"}
            type="text"
            h="48px"
            m={{ b: "2rem" }}
          />
        </Col>
        <Col>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={false}
                text={"Next"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setStep(1);
                }}
                isLoading={false}
                text={"Back"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreatePlaceholderLocation;
