import React, { ChangeEvent } from "react";
import { Button, Icon } from "atomize";
import { CSSProperties } from "styled-components";

interface DangerBtnProps {
  handleSubmit: (
    e: any | ChangeEvent<HTMLButtonElement>
  ) => Promise<void> | void;
  isLoading?: boolean;
  text?: string;
  w?: string;
  h?: string;
  textSize?: number;
  styleBtn?: CSSProperties;
  prefixIcon?: Element;
  disabled?: boolean;
}

const DangerBtn: React.FC<DangerBtnProps> = ({
  textSize = 14,
  styleBtn,
  prefixIcon,
  disabled = false,
  handleSubmit,
  isLoading,
  text,
  w = "100%",
  h = "48px",
}) => {
  return (
    <Button
      h={h}
      w={w}
      bg="danger"
      hoverBg="brand900"
      p={{ xs: "0.6rem" }}
      onClick={handleSubmit}
      prefix={prefixIcon}
      textSize={textSize}
      hoverShadow="2"
      disabled={disabled || isLoading}
      style={{ ...styleBtn }}
    >
      {isLoading ? (
        <Icon name="Loading" color="background" size="20px" />
      ) : text ? (
        text
      ) : (
        "Confirm"
      )}
    </Button>
  );
};

export default DangerBtn;
