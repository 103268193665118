import React, { useContext, useEffect } from "react";
import { Row, Col, Text, Div } from "atomize";
import {
  GetOrdersDocument,
  OrderStatus,
  TransportCargoType,
  useUpdateOrderStatusMutation,
} from "../../../generated/graphql";
import { useHistory } from "react-router";
import { OrderContext } from "../ReceivePricingBids";
import { loadingInitialState } from "../loading.reducer";
import moment from "moment";
import { initialGetOrders } from "../../../helpers/initialData";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../../components/SecondaryBtn/SecondaryBtn";
import DangerBtn from "../../../components/DangerBtn/DangerBtn";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";

const ViewOrders: React.FC = () => {
  const history = useHistory();
  const { loadingState, dispatch } = useContext(OrderContext);
  const [
    updateOrderStatus,
    {
      data: updateOrderStatusData,
      loading: updateOrderStatusLoading,
      error: updateOrderStatusError,
    },
  ] = useUpdateOrderStatusMutation({ errorPolicy: "all" });

  const { width } = useWindowDimensions();

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateOrderStatus({
      variables: {
        status: loadingState.onMarket
          ? OrderStatus.OnMarket
          : OrderStatus.Pending,
        id: Number(loadingState.id),
      },
      refetchQueries: [
        {
          query: GetOrdersDocument,
          variables: {
            query: {
              ...initialGetOrders,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  useEffect(() => {
    if (loadingState.id === null) {
      history.push("/myorders");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitDraft = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch({
      type: "ORDER_SENT_DRAFT",
      field: "",
      payload: {
        ...loadingInitialState,
      },
    });
    history.push(`/myorder/${loadingState.code}`);
  };

  ErrorsBeHandler({
    error: updateOrderStatusError,
  });

  SuccessBeHandler({
    data: updateOrderStatusData,
    code: "ORDER_SENT_ON_MARKET",
    fn: () => {
      dispatch({
        type: "HANDLE_RESET",
        field: "",
        payload: {
          ...loadingInitialState,
        },
      });
      history.push(`/myorder/${updateOrderStatusData?.updateOrderStatus.code}`);
    },
  });

  return (
    <Row
      m={{ x: "0rem", t: "1rem", b: width > 700 ? "1rem" : "4rem" }}
      d="flex"
      flexDir="column"
      w="100%"
    >
      <Col w="100%">
        <Row>
          <Col
            d="flex"
            w="100%"
            justify={width > 900 ? "space-between" : "flex-start"}
          >
            <Text textSize={20} textColor="dark">
              Order id:
            </Text>
            <Text textSize={20} textColor="dark" m={{ l: "1rem" }}>
              {loadingState?.code}
            </Text>
          </Col>
        </Row>
        <Row w="100%">
          <Col>
            <Text textSize={20} textColor="dark" m={{ t: "1rem", b: "0.5rem" }}>
              Loading Information
            </Text>
          </Col>
        </Row>
        <Row d="column">
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Shipper Company:
            </Text>
            <Text textColor={"dark"} textWeight={"600"}>
              {loadingState?.shipper.name}
            </Text>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Loading Location:
            </Text>
            <Div d="flex" minH="24px" align="center" w="fit-content">
              <Text
                textColor="dark"
                textWeight="600"
                m={{ r: "auto", l: "0rem" }}
                textSize={14}
              >
                {`${loadingState.loadingLocation.title}, ${loadingState.loadingLocation.address}, ${loadingState.loadingLocation.postCode}, ${loadingState.loadingLocation.postName}`}
              </Text>
            </Div>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Loading Time:
            </Text>
            <Text textColor={"dark"} textWeight={"600"} w="auto">
              {`${moment
                .tz(
                  `${loadingState.loading_date} ${loadingState.loading_time}`,
                  loadingState.loading_timezone
                )
                .format("HH:mm DD/MM/YYYY")}, ${loadingState.loading_timezone}`}
            </Text>
          </Col>
        </Row>

        <Row w="100%">
          <Col>
            <Text textSize={20} textColor="dark" m={{ t: "1rem", b: "0.5rem" }}>
              Unloading Information
            </Text>
          </Col>
        </Row>
        <Row d="column">
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Receiver Company:
            </Text>
            <Text textColor={"dark"} textWeight={"600"} w="auto">
              {loadingState?.receiver.name}
            </Text>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Unloading Location:
            </Text>
            <Div d="flex" minH="24px" align="center" w="fit-content">
              <Text
                textColor="dark"
                textWeight="600"
                m={{ r: "auto", l: "0rem" }}
                textSize={14}
              >
                {`${loadingState.unloadingLocation.title}, ${loadingState.unloadingLocation.address}, ${loadingState.unloadingLocation.postCode}, ${loadingState.unloadingLocation.postName}`}
              </Text>
            </Div>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Unloading Time:
            </Text>
            <Text textColor={"dark"} textWeight={"600"} w="auto">
              {`${moment
                .tz(
                  `${loadingState.unloading_date} ${loadingState.unloading_time}`,
                  loadingState.unloading_timezone
                )
                .format("HH:mm DD/MM/YYYY")}, ${
                loadingState.unloading_timezone
              }`}
            </Text>
          </Col>
        </Row>

        <Row w="100%">
          <Col>
            <Text textSize={20} textColor="dark" m={{ t: "1rem", b: "0.5rem" }}>
              Transport Information
            </Text>
          </Col>
        </Row>
        {!loadingState.onMarket && (
          <Row d="column">
            <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
              <Text
                textColor={"semiDark"}
                w="160px"
                m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
              >
                Transporter Company:
              </Text>
              <Text textColor={"dark"} textWeight={"600"}>
                {loadingState?.transporter?.name}
              </Text>
            </Col>
            <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
              <Text
                textColor={"semiDark"}
                w="160px"
                m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
              >
                Price:
              </Text>
              <Text textColor={"dark"} textWeight={"600"}>
                {loadingState?.price}€
              </Text>
            </Col>
          </Row>
        )}
        <Row d="column">
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Cargo:
            </Text>
            <Text textColor={"dark"} textWeight={"600"} w="fit-content">
              {loadingState.cargoType !== TransportCargoType.Ftl &&
              loadingState.parcels.length ? (
                <>
                  {loadingState.parcels.map((parcel: any, i: number) => (
                    <Row m={{ l: "0px" }} w="fit-content" key={i}>
                      <span>
                        {typeof parcel.quantity === "number" &&
                          parcel.quantity + "x "}
                      </span>
                      {typeof parcel.weight === "number" && (
                        <span
                          style={{ marginLeft: parcel.quantity && "0.3rem" }}
                        >
                          {parcel.weight + "kg x"}
                        </span>
                      )}
                      {typeof parcel.volume === "number" && (
                        <span style={{ marginLeft: "0.3rem" }}>
                          {parcel.volume + "LDM"}
                        </span>
                      )}
                      {typeof parcel.width === "number" && (
                        <span style={{ marginLeft: "0.3rem" }}>
                          {parcel.width + "m x"}
                        </span>
                      )}
                      {typeof parcel.length === "number" && (
                        <span style={{ marginLeft: "0.3rem" }}>
                          {parcel.length + "m x"}
                        </span>
                      )}
                      {typeof parcel.height === "number" && (
                        <span style={{ marginLeft: "0.3rem" }}>
                          {parcel.height + "m"}
                        </span>
                      )}
                      {!parcel.weight &&
                        !parcel.volume &&
                        !parcel.width &&
                        !parcel.length &&
                        !parcel.height && (
                          <>
                            <span style={{ marginLeft: "0.3rem" }}>
                              {loadingState.cargoType ===
                                TransportCargoType.EurPallet && " Euro pallet"}
                            </span>
                            <span style={{ marginLeft: "0.3rem" }}>
                              {loadingState.cargoType ===
                                TransportCargoType.IndustryPallet &&
                                " Industry pallet"}
                            </span>
                          </>
                        )}
                    </Row>
                  ))}
                </>
              ) : (
                "Full truck load"
              )}
            </Text>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Dangerous goods:
            </Text>
            <Text textColor={"dark"} textWeight={"600"}>
              {loadingState.dangerous_goods ? "Yes" : "No"}
            </Text>
          </Col>
          <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
            <Text
              textColor={"semiDark"}
              w="160px"
              m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Stackable goods:
            </Text>
            <Text textColor={"dark"} textWeight={"600"}>
              {loadingState.stackable_goods ? "Yes" : "No"}
            </Text>
          </Col>
        </Row>
        {loadingState.onMarket && (
          <Row d="column">
            <Col d="flex" flexDir={width > 900 ? "row" : "column"}>
              <Text
                textColor={"semiDark"}
                w="160px"
                m={{ r: "auto", t: width > 900 ? "0rem" : "0.5rem" }}
              >
                Expiry Time:
              </Text>
              <Text textColor={"dark"} textWeight={"600"}>
                {`${moment
                  .tz(
                    `${loadingState.expiry_date} ${loadingState.expiry_time}`,
                    loadingState.expiry_timezone
                  )
                  .format("HH:mm DD/MM/YYYY")}, ${
                  loadingState.expiry_timezone
                }`}
              </Text>
            </Col>
          </Row>
        )}
        <Row d="column">
          <Col d="flex" flexDir={"column"}>
            <Text
              textColor="dark"
              w="160px"
              m={{ r: "auto", b: "1rem", t: width > 900 ? "0rem" : "0.5rem" }}
            >
              Description:
            </Text>
            <Text textColor={"dark"} textWeight={"600"}>
              {loadingState.description ? loadingState.description : "None"}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col d="flex" size={width > 850 ? "4" : "12"}>
            <DangerBtn
              h="48px"
              w="100%"
              handleSubmit={() => {
                history.push(`/createneworder/order/${loadingState.code}`);
              }}
              styleBtn={{ marginTop: "1.5rem" }}
              isLoading={updateOrderStatusLoading}
              text={"Edit order"}
            />
          </Col>
          <Col d="flex" size={width > 850 ? "4" : "12"}>
            <SecondaryBtn
              h="48px"
              w="100%"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                handleSubmitDraft(e)
              }
              styleBtn={{ marginTop: "1.5rem" }}
              isLoading={updateOrderStatusLoading}
              text={"Save as draft"}
            />
          </Col>
          <Col d="flex" size={width > 850 ? "4" : "12"}>
            <PrimaryBtn
              w="100%"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                handleSubmit(e)
              }
              styleBtn={{ marginTop: "1.5rem" }}
              isLoading={updateOrderStatusLoading}
              text={"Confirm order"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewOrders;
