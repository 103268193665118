import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import React, { ChangeEvent, useState } from "react";
import { modalData, responseMsgs } from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { success } from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { LocalStorageKeys } from "../../../helpers/constants";
import { downloadInvoicePdf } from "../../../helpers/functions";
import InvoiceData from "../../../pages/CreateDocument/components/InvoiceData";
import { InvoiceState } from "../../../pages/CreateDocument/components/helpers/helpers";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";
import {
  invoiceGenerateSchema,
  invoiceSetupSchema,
} from "./schema/schema.validation";

const InvoiceCreateModal: React.FC = () => {
  const { name, msg, props } = useReactiveVar(modalData);
  const { width } = useWindowDimensions();

  const shipperCompanyId = props?.shipperCompanyId ?? 0;
  const orderId = props?.orderId ?? 0;
  const orderCode = props?.orderCode ?? "";
  const isGenerateModal = props?.modalType === "generate";
  const isSetupModal = props?.modalType === "setup";
  const isOpen =
    name === "invoiceCreateModal" && (isGenerateModal || isSetupModal);

  const { validator } = ValidationsFeHandler();

  const [invoice, setInvoice] = useState<InvoiceState>({
    amount: null,
    invoiceNumber: null,
    paymentDueDate: null,
    payingCompany: null,
    note: null,
    taxation: null,
    currency: null,
  });

  const handleClose = () =>
    modalData({
      name: "",
      msg: "",
      returned_value: null,
      props: null,
    });

  const handleSubmit = async (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const payload = isGenerateModal
      ? {
          amount: invoice?.amount,
          invoiceNumber: invoice?.invoiceNumber,
          paymentDueDate: invoice?.paymentDueDate,
          payeeCompanyId: invoice?.payingCompany?.id,
          taxationId: invoice?.taxation?.id,
          currencyId: invoice?.currency?.id,
          note: invoice?.note,
          orderCode: orderCode,
        }
      : {
          amount: invoice?.amount,
          paymentDueDate: invoice?.paymentDueDate,
          payeeCompanyId: invoice?.payingCompany?.id,
        };

    validator({
      schema: isGenerateModal ? invoiceGenerateSchema : invoiceSetupSchema,
      data: payload,
      success: () => {
        if (isGenerateModal) {
          const token = localStorage.getItem(LocalStorageKeys.TOKEN) ?? "";
          downloadInvoicePdf(token, payload);
          responseMsgs([
            {
              type: "success",
              string:
                success?.find((item) => item?.code === "INVOICE_GENERATED")
                  ?.msg ?? "",
            },
          ]);
          modalData({
            name: "",
            msg: "",
            returned_value: null,
            props: null,
          });
          props?.successCallback();
        }
      },
    });
  };

  const handleFieldValueChange = (value: any, field: keyof InvoiceState) => {
    setInvoice((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={handleClose}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {isOpen && (
        <Div
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row>
            <Col d="flex" align="center">
              <Text
                textSize="201"
                textAlign="center"
                textWeight="700"
                textColor="dark"
              >
                {msg}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <InvoiceData
                invoice={invoice}
                setInvoice={setInvoice}
                handleFieldValueChange={handleFieldValueChange}
                isGenerateModal={isGenerateModal}
                subtext={props.subtext}
                shipperCompanyId={shipperCompanyId}
                isOpen={isOpen}
                orderId={orderId}
              />

              <Row>
                <Col size="6">
                  <PrimaryBtn
                    w="100%"
                    handleSubmit={handleSubmit}
                    isLoading={false}
                    text="Save"
                  />
                </Col>
                <Col size="6">
                  <SecondaryBtn
                    w="100%"
                    handleSubmit={() => handleClose()}
                    isLoading={false}
                    text="Cancel"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default InvoiceCreateModal;
