import { Checkbox, Col, Div, Input, Label, Radiobox, Row, Text } from "atomize";
import { isEmpty } from "lodash";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { responseMsgs } from "../../App";
import { GlobalTheme } from "../../components/GlobalTheme";
import LoaderScreen from "../../components/LoaderScreen";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import { useExportLazyQuery } from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import { downloadFromUrl } from "../../helpers/functions";
import { MyOrderView, initialView } from "../../helpers/initialData";
import {
  ExportConfig,
  ExportOptionValues,
  ExportOptions,
} from "./helpers/utils";
import { getExportConfigSchema } from "./schema/schema.validation";

const initialExportConfig: ExportConfig = {
  from: null,
  to: null,
  enableCustomTimeFrame: false,
  exportMethod: ExportOptionValues.All,
  selectedColumns: initialView?.map((col) => col.name) ?? [],
};

const SettingsExport = () => {
  const { width } = useWindowDimensions();
  const { validator } = ValidationsFeHandler();

  const [exportConfig, setExportConfig] =
    useState<ExportConfig>(initialExportConfig);
  const [showLoader, setShowLoader] = useState(false);

  const isExportingOrders = [
    ExportOptionValues.Orders,
    ExportOptionValues.All,
  ].includes(exportConfig?.exportMethod);
  const isExportingDocuments = [
    ExportOptionValues.Documents,
    ExportOptionValues.All,
  ].includes(exportConfig?.exportMethod);

  const [
    queryExport,
    { data: dataExport, loading: loadingExport, error: errorExport },
  ] = useExportLazyQuery();

  const handleFieldValueChange = (value: any, field: keyof ExportConfig) => {
    setExportConfig((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleOrderColumnSelectionChange = (
    isChecked: boolean,
    col: MyOrderView
  ) => {
    const selectedColumns = exportConfig?.selectedColumns;
    if (isChecked) {
      handleFieldValueChange(
        selectedColumns?.filter((c) => c !== col.name),
        "selectedColumns"
      );
    } else {
      handleFieldValueChange(
        [...(selectedColumns ?? []), col.name],
        "selectedColumns"
      );
    }
  };

  const handleReset = () => {
    setExportConfig(initialExportConfig);
  };

  const handleSubmitExport = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const payload = {
      ...(exportConfig?.enableCustomTimeFrame
        ? {
            from: exportConfig?.from,
            to: exportConfig?.to,
          }
        : {}),
      orders: isExportingOrders,
      documents: isExportingDocuments,
      selectedColumns: isEmpty(exportConfig?.selectedColumns)
        ? undefined
        : exportConfig?.selectedColumns,
    };

    validator({
      schema: getExportConfigSchema(
        exportConfig?.enableCustomTimeFrame ?? false
      ),
      data: payload,
      success: async () => {
        setShowLoader(true);
        const result = await queryExport({
          variables: {
            options: payload,
          },
        });

        const { documents, orders } = result?.data?.export || {};

        const messages = [];

        if (!result?.error) {
          if (!orders?.csvPath && isExportingOrders) {
            messages.push({
              type: "warning",
              string: "No orders data to export",
            });
          }
          if (!documents?.csvPath && isExportingDocuments) {
            messages.push({
              type: "warning",
              string: "No documents CSV data to export",
            });
          }
          if (!documents?.zipPath && isExportingDocuments) {
            messages.push({
              type: "warning",
              string: "No documents ZIP data to export",
            });
          }
        }
        if (messages?.length === 0) {
          messages.push({
            type: "success",
            string: "Data exported successfully.",
          });
        }

        responseMsgs(messages);
        setShowLoader(false);
      },
    });
  };

  useEffect(() => {
    if (dataExport?.export?.orders?.csvPath) {
      downloadFromUrl(dataExport?.export?.orders?.csvPath, "export_orders");
    }
    if (dataExport?.export?.documents?.csvPath) {
      downloadFromUrl(
        dataExport?.export?.documents?.csvPath,
        "export_documents"
      );
    }
    if (dataExport?.export?.documents?.zipPath) {
      downloadFromUrl(
        dataExport?.export?.documents?.zipPath,
        "export_documents"
      );
    }
  }, [dataExport, dataExport?.export?.orders?.csvPath]);

  useEffect(() => {
    if (exportConfig?.enableCustomTimeFrame) {
      const from =
        exportConfig?.from ?? moment().subtract(1, "w").format("YYYY-MM-DD");
      const to = exportConfig?.to ?? moment().format("YYYY-MM-DD");
      handleFieldValueChange(from, "from");
      handleFieldValueChange(to, "to");
    }
  }, [
    exportConfig?.enableCustomTimeFrame,
    exportConfig?.from,
    exportConfig?.to,
  ]);

  ErrorsBeHandler({
    error: errorExport,
  });

  if (showLoader) {
    return (
      <LoaderScreen
        isOpen={showLoader}
        title={
          "Stay on this page while we prepare your export. The files will be downloaded automatically."
        }
      />
    );
  }

  return (
    <>
      <Row m={{ x: "0", y: "4rem" }}>
        <Col align="center" jc="center" d="flex" flexDir="column">
          <Div
            d="flex"
            align="flex-start"
            flexDir="column"
            w="100%"
            style={{ maxWidth: "650px" }}
          >
            <Text textSize="28px" textWeight="700" m={{ b: "3rem" }}>
              Export data
            </Text>
            <Text textSize="20px" textWeight="700" m={{ b: "1rem" }}>
              Time period
            </Text>
            <Text textColor="semiDark" m={{ b: "1rem" }}>
              By default you will export all your orders. You can however adjust
              the data by selecting a custom frame.
            </Text>

            <Row>
              <Col>
                <Div>
                  <Label align="center" textWeight="500" m={{ b: "0.5rem" }}>
                    <Checkbox
                      checked={exportConfig?.enableCustomTimeFrame}
                      onChange={() =>
                        handleFieldValueChange(
                          !exportConfig?.enableCustomTimeFrame,
                          "enableCustomTimeFrame"
                        )
                      }
                    />
                    <Text>Enable custom time frame</Text>
                  </Label>
                </Div>
              </Col>
            </Row>

            <Row w="100%">
              <Col>
                <Row d="vertical">
                  <Col>
                    <Text
                      textColor="semiDark"
                      textSize={12}
                      textWeight={500}
                      m={{ b: "0.25rem" }}
                    >
                      From
                    </Text>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Input
                          h="48px"
                          name="from"
                          placeholder="Enter date from"
                          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (isEmpty(exportConfig?.from)) {
                              e.target.type = "date";
                              handleFieldValueChange(
                                moment(new Date()).format("YYYY-MM-DD"),
                                "from"
                              );
                            }
                          }}
                          value={exportConfig?.from ? exportConfig?.from : ""}
                          type={exportConfig?.from ? "date" : "text"}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (e.target.value !== "") {
                              handleFieldValueChange(e.target.value, "from");
                            }
                          }}
                          style={
                            !exportConfig?.enableCustomTimeFrame
                              ? {
                                  backgroundColor: GlobalTheme.colors.grey,
                                }
                              : {}
                          }
                          disabled={!exportConfig?.enableCustomTimeFrame}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row d="vertical">
                  <Col>
                    <Text
                      textColor="semiDark"
                      textSize={12}
                      textWeight={500}
                      m={{ b: "0.25rem" }}
                    >
                      To
                    </Text>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Input
                          h="48px"
                          name="to"
                          placeholder="Enter date to"
                          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (isEmpty(exportConfig?.to)) {
                              e.target.type = "date";
                              handleFieldValueChange(
                                moment(new Date()).format("YYYY-MM-DD"),
                                "to"
                              );
                            }
                          }}
                          value={exportConfig?.to ? exportConfig?.to : ""}
                          type={exportConfig?.to ? "date" : "text"}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (e.target.value !== "") {
                              handleFieldValueChange(e.target.value, "to");
                            }
                          }}
                          style={
                            !exportConfig?.enableCustomTimeFrame
                              ? {
                                  backgroundColor: GlobalTheme.colors.grey,
                                }
                              : {}
                          }
                          disabled={!exportConfig?.enableCustomTimeFrame}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Text textSize="20px" textWeight="700" m={{ b: "1rem", t: "3rem" }}>
              Export method
            </Text>

            <Row>
              <Col>
                <Div>
                  {ExportOptions.map((option) => (
                    <Label
                      align="center"
                      textWeight="500"
                      m={{ b: "0.5rem" }}
                      key={option?.value}
                    >
                      <Radiobox
                        onChange={(e: any) => {
                          e.preventDefault();
                          handleFieldValueChange(option?.value, "exportMethod");
                        }}
                        checked={exportConfig?.exportMethod === option?.value}
                        name="exportMethod"
                      ></Radiobox>
                      <Text>{option.name}</Text>
                    </Label>
                  ))}
                </Div>
              </Col>
            </Row>

            {/* {isExportingOrders && (
              <>
                <Text
                  textSize="20px"
                  textWeight="700"
                  m={{ b: "1rem", t: "3rem" }}
                >
                  Order columns to export
                </Text>
                <Row>
                  <Col m={{ t: "2rem" }}>
                    <Row>
                      {initialView.map((col: MyOrderView) => {
                        const isChecked =
                          exportConfig?.selectedColumns?.includes(col.name) ??
                          false;

                        return (
                          <Col size={width > 400 ? "6" : "12"} key={col.name}>
                            <Label
                              align="center"
                              textSize={width > 550 ? 14 : 12}
                              textTransform="capitalize"
                              textWeight="500"
                              m={{ b: "0.5rem" }}
                            >
                              <Checkbox
                                onChange={() => {
                                  handleOrderColumnSelectionChange(
                                    isChecked,
                                    col
                                  );
                                }}
                                checked={isChecked}
                              />
                              {col.name}
                            </Label>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </>
            )} */}

            <Div h="2rem" />
            <Row w="100%">
              <Col>
                <SecondaryBtn
                  w="100%"
                  handleSubmit={() => {
                    handleReset();
                  }}
                  isLoading={loadingExport}
                  text="Cancel"
                />
              </Col>
              <Col>
                <PrimaryBtn
                  w="100%"
                  handleSubmit={handleSubmitExport}
                  isLoading={loadingExport}
                  text="Export"
                />
              </Col>
            </Row>
          </Div>
        </Col>
      </Row>
    </>
  );
};

export default SettingsExport;
