import { Col, Div, Icon, Input, Row, Tag, Text } from "atomize";
import moment from "moment";
import { Dispatch, FC, SetStateAction } from "react";
import renderIcon from "../../assets/Icons";
import {
  CompanyStatus,
  GetDriversQuery,
  GetMeQuery,
} from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { InitialUsersPag } from "../../helpers/initialData";
import Pagination from "../Pagination/Pagination";
import PrimaryBtn from "../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../SecondaryBtn/SecondaryBtn";
import Skeleton from "../Skeleton/Skeleton";
import Table from "../TableComponents/Table";
import Tbody from "../TableComponents/Tbody";
import Td from "../TableComponents/Td";
import Th from "../TableComponents/Th";
import Thead from "../TableComponents/Thead";
import Tr from "../TableComponents/Tr";
import UsersRowActions from "../Tables/UsersRowActions";

interface Props {
  title: string;
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  usersLoading: boolean;
  users: GetDriversQuery | undefined;
  me: GetMeQuery | undefined;
  isLoadingMe: boolean;
  totalPages: number | null;
  usersPag: InitialUsersPag;
  setUsersPag: Dispatch<SetStateAction<InitialUsersPag>>;
  noResults?: {
    text: string;
    buttonText?: string;
  };
  onDriverCreate?: () => void;
  userPermissions?: {
    canUserEdit?: boolean;
    canUserDelete?: boolean;
    canUserCreate?: boolean;
  };
}

const InternalDriversTable: FC<Props> = ({
  title,
  searchInput,
  setSearchInput,
  usersLoading,
  users,
  me,
  isLoadingMe,
  totalPages,
  usersPag,
  setUsersPag,
  onDriverCreate,
  noResults = {
    text: "You have not made any Drivers.",
    buttonText: "Invite driver",
  },
  userPermissions,
}) => {
  const { width } = useWindowDimensions();

  const { canUserCreate } = userPermissions || {};

  return (
    <Div>
      <Div d="flex" h="72px" overflow="hidden" align="center" />
      <Div w="100%" d="flex" m={{ b: "1rem" }}>
        <Text
          textSize={width > 750 || width < 700 ? 20 : 16}
          textColor="dark"
          m={{ r: "1rem" }}
        >
          {title}
        </Text>
        <Div flexGrow={1}>
          <Input
            h="32px"
            w="100%"
            p={{ x: "2rem" }}
            borderColor="greyBorder"
            textSize="12"
            placeholder="Search..."
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.target.value)
            }
            prefix={
              <Icon
                name="Search"
                size="16px"
                cursor="pointer"
                pos="absolute"
                color="semiDark"
                top="50%"
                left="0.5rem"
                transform="translateY(-50%)"
              />
            }
          />
        </Div>
        {onDriverCreate && canUserCreate && (
          <Div d="flex" justify="flex-end">
            {usersLoading ? (
              <Skeleton height="2rem" width="115px" margin="0 0  0 0.5rem" />
            ) : (
              <SecondaryBtn
                w="115px"
                h="2rem"
                handleSubmit={onDriverCreate}
                prefixIcon={
                  <Icon
                    m={{ r: "6px" }}
                    name="Add"
                    size="18px"
                    color="semiDark"
                  />
                }
                isLoading={false}
                styleBtn={{
                  display: "flex",
                  justifyContent: "left",
                  margin: "0 0 0 0.5rem",
                  whiteSpace: "nowrap",
                }}
                text={"Add driver"}
                textSize={12}
              />
            )}
          </Div>
        )}
      </Div>
      {isLoadingMe || usersLoading ? (
        <>
          <Skeleton
            classN="table_skeleton"
            height={`${49 * (usersPag.limit || 0) + 60}px`}
          />

          {totalPages && (
            <Pagination
              setQuery={setUsersPag}
              query={usersPag}
              total={totalPages}
            />
          )}
        </>
      ) : users?.getDrivers.total && Number(users?.getDrivers!.total) > 0 ? (
        <>
          <Table
            width={`calc(100% -  ${isLoadingMe || usersLoading ? "0" : "0"})`}
          >
            <tbody>
              <Tr hidBorderLast={true} className="border_none">
                <Td width={"100%"} className="divider">
                  <Table>
                    <Thead>
                      <Tr className="head">
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            First name
                          </Text>
                        </Th>
                        <Th width={"100px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Last name
                          </Text>
                        </Th>
                        <Th width={"200px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Email
                          </Text>
                        </Th>
                        <Th width={"120px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Phone
                          </Text>
                        </Th>
                        <Th width={"140px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Vehicles
                          </Text>
                        </Th>
                        <Th width={"150px"}>
                          <Text
                            textColor="dark"
                            textWeight="500"
                            overflow="hidden"
                            d="inline!important"
                          >
                            Date joined
                          </Text>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {users?.getDrivers?.items &&
                        me?.getUser &&
                        users?.getDrivers?.items!.map(
                          (user: any, i: number) => (
                            <Tr>
                              <Td width={"100px"}>
                                <Text textColor={"dark"}>
                                  {user?.firstName}
                                </Text>
                              </Td>
                              <Td width={"100px"}>
                                <Text textColor={"dark"}>{user?.lastName}</Text>
                              </Td>
                              <Td width={"200px"}>
                                <Text textColor={"dark"}>{user?.email}</Text>
                              </Td>
                              <Td width={"120px"}>
                                <Text textColor="dark">
                                  {user?.phone ? user?.phone : "None"}
                                </Text>
                              </Td>
                              <Td width={"140px"}>
                                <Text textColor="dark">
                                  {user?.vehicleRegistration.length
                                    ? user?.vehicleRegistration.map(
                                        (reg: any, i: number) =>
                                          user?.vehicleRegistration.length -
                                            1 ===
                                          i
                                            ? `${reg.reg}`
                                            : `${reg.reg}, `
                                      )
                                    : "None"}
                                </Text>
                              </Td>
                              <Td width={"150px"}>
                                <Text textColor={"semiDark"} textSize={142}>
                                  {moment(user?.createTime).format(
                                    "DD/MMM/YYYY HH:mm"
                                  )}
                                </Text>
                              </Td>
                            </Tr>
                          )
                        )}
                    </Tbody>
                  </Table>
                </Td>
                <Td width="40px" className="divider_right no_p">
                  <Table width="auto">
                    <Thead>
                      <Tr>
                        <Td className="head"></Td>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {users?.getDrivers?.items?.map((user: any, i: number) => (
                        <UsersRowActions
                          userPermissions={userPermissions}
                          user={user}
                          key={i}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            </tbody>
          </Table>
        </>
      ) : null}
      {users?.getDrivers.total === 0 && !usersLoading && (
        <>
          {searchInput ? (
            <Row m={{ x: "0rem", t: "0rem" }}>
              <Col>
                <Tag
                  p={{ l: "1rem" }}
                  h="48px"
                  w="100%"
                  d="flex"
                  justify="start"
                  textColor="light"
                >
                  We couldn’t find any driver related to {usersPag.search}.
                </Tag>
              </Col>
            </Row>
          ) : (
            <Row
              maxW="440px"
              align="center"
              p="32px 0px"
              d="flex"
              m={{ x: "auto" }}
            >
              <Col d="flex" flexDir="column" align="center">
                <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                  {renderIcon("AddOrder")}
                </Div>
                <Text
                  textColor="dark"
                  textWeight="500"
                  textSize={16}
                  textAlign="center"
                >
                  {noResults?.text}
                </Text>

                {me?.getUser.company?.status === CompanyStatus.Active &&
                  noResults?.buttonText && (
                    <PrimaryBtn
                      w="100%"
                      handleSubmit={(
                        e: React.ChangeEvent<HTMLButtonElement>
                      ) => {
                        e.preventDefault();
                        onDriverCreate?.();
                      }}
                      styleBtn={{ marginTop: "1.5rem" }}
                      isLoading={false}
                      text={noResults?.buttonText}
                    />
                  )}
              </Col>
            </Row>
          )}
        </>
      )}
      {totalPages ? (
        <Row m={{ x: "0" }}>
          <Col>
            <Pagination
              setQuery={setUsersPag}
              query={usersPag}
              total={totalPages}
            />
          </Col>
        </Row>
      ) : null}
    </Div>
  );
};

export default InternalDriversTable;
