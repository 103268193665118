import { FC } from "react";
import Select from "react-select";
import { Props } from "react-select/dist/declarations/src/Select";
import { GlobalTheme } from "../GlobalTheme";

export interface MultiSelectOption {
  label: string | number;
  value: string | number;
  key?: string | number;
}

interface MultiInputDropdownProps
  extends Partial<Props<MultiSelectOption, true, any>> {
  defaultValue?: MultiSelectOption[];
}

const MultiInputDropdown: FC<MultiInputDropdownProps> = (props) => {
  return (
    <Select
      {...props}
      isMulti
      styles={{
        placeholder: (base) => ({
          ...base,
          color: GlobalTheme.colors.semiDark,
          fontSize: "14px",
          lineHeight: "24px",
          fontWeight: 500,
        }),
        valueContainer: (base) => ({
          ...base,
          borderRadius: "6px",
          padding: "2px 8px",
        }),
        control: (base, state) => ({
          ...base,
          borderRadius: "6px",
          borderColor: state.isFocused
            ? "rgb(152, 161, 177)"
            : "rgb(201, 206, 214)",
          boxShadow: "none",
          "&:hover": {
            borderColor: "none",
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: "6px",
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: GlobalTheme.colors.primary,
          borderRadius: "4px",
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: GlobalTheme.colors.background,
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: GlobalTheme.colors.background,
          "&:hover": {
            backgroundColor: GlobalTheme.colors.light,
            color: GlobalTheme.colors.background,
          },
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 6,
        colors: {
          ...theme.colors,
          primary: GlobalTheme.colors.primary,
          neutral0: GlobalTheme.colors.background,
          neutral20: GlobalTheme.colors.brand100,
          neutral30: GlobalTheme.colors.brand400,
        },
      })}
    />
  );
};

export default MultiInputDropdown;
