import { Anchor, Container, Div, Icon, Input, Tag, Text } from "atomize";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { responseMsgs } from "../../App";
import InputDropdown from "../../components/InputDropdown/InputDropdown";
import PrefixDropdown from "../../components/PrefixDropdown/PrefixDropdown";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import Skeleton from "../../components/Skeleton/Skeleton";
import TertiaryBtn from "../../components/TertiaryBtn/TertiaryBtn";
import {
  CompanyStatus,
  CompanyType,
  Country,
  Document,
  DocumentType,
  SqlModelStatus,
  User,
  useCreateDocumentMutation,
  useGetCountriesLazyQuery,
  useGetMeQuery,
  useGetUserQuery,
  useGetUsersLazyQuery,
  useReplaceInsuranceDocumentMutation,
  useUpdateCompanyMutation,
  useUpdateDocumentExpiryDateMutation,
} from "../../generated/graphql";
import { useSearch } from "../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import { DocumentInputAcceptExtensions } from "../../helpers/constants";
import { downloadFromUrl, uploadFileToAWS } from "../../helpers/functions";
import { companySchema, expiryDateSchema } from "./schema/schema.validation";

const SettingsCompany: React.FC = () => {
  const { width, height } = useWindowDimensions();

  const history = useHistory();
  const { validator } = ValidationsFeHandler();
  const [company, setCompany] = useState<{
    id: number | null | undefined;
    name: string | null | undefined;
    ibanCountry: string | null | undefined;
    iban: string | null | undefined;
    bic: string | null | undefined;
    taxNumberCountry: string | null | undefined;
    taxNumber: string | null | undefined;
    contactUser_id: number | null | undefined;
    contactUserName: string | null | undefined;
  }>({
    id: null,
    name: null,
    ibanCountry: null,
    iban: null,
    bic: null,
    taxNumberCountry: null,
    taxNumber: null,
    contactUser_id: null,
    contactUserName: null,
  });
  const [insuranceDocument, setInsuranceDocument] = useState<Document | null>(
    null
  );
  const [insuranceDocumentFile, setInsuranceDocumentFile] =
    useState<File | null>(null);
  const [expiryDate, setExpiryDate] = useState<Date | string | null>(null);

  const fileInputRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

  // Queries
  const {
    data: me,
    loading: meLoading,
    refetch: refetchMe,
  } = useGetMeQuery({
    onCompleted: (me) => {
      if (
        me?.getUser?.company?.type === CompanyType.Transporter &&
        !isEmpty(me?.getUser?.company?.insuranceCertificate)
      ) {
        setInsuranceDocument(
          me?.getUser?.company?.insuranceCertificate as Document
        );
        setExpiryDate(me?.getUser?.company?.insuranceCertificate?.expiryDate);
      }
      if (
        (me?.getUser.roles![0].id !== 2 && me?.getUser.roles![0].id !== 1) ||
        me?.getUser.company?.status === CompanyStatus.WaitingConfirm
      ) {
        history.push("/settings/individual");
      }
    },
  });

  const {
    data: user,
    loading: userLoading,
    error: userError,
    refetch: refetchUser,
  } = useGetUserQuery({
    variables: { id: me?.getUser.company?.contactUser_id },
    onCompleted: (user) => {
      const clear = user?.getUser?.company?.taxNumber?.replace("-", "")?.trim();
      const resultPre = clear?.split(/[0-9]/)[0]?.trim();
      const resultAfter = clear?.split(/^[a-zA-Z]+/)[1]?.trim();
      const clearIban = user?.getUser?.company?.IBAN?.replace("-", "")?.trim();
      const resultIbanPre = clearIban?.split(/[0-9]/)[0]?.trim();
      const resultAIbanfter = clearIban?.split(/^[a-zA-Z]+/)[1]?.trim();

      setCompany({
        ...company,
        id: Number(user?.getUser?.company?.id),
        name: user?.getUser.company?.name,
        taxNumberCountry: resultPre,
        taxNumber: resultAfter,
        contactUser_id: user?.getUser.company?.contactUser_id,
        iban: resultAIbanfter,
        ibanCountry: resultIbanPre,
        bic: user?.getUser.company?.BIC,
        contactUserName: `${user?.getUser.firstName} ${user?.getUser.lastName}`,
      });
    },
    skip: meLoading || !me?.getUser.company?.contactUser_id,
  });

  // Mutations
  const [
    updateExpiryDate,
    {
      data: dataUpdateExpiryDate,
      loading: loadingUpdateExpiryDate,
      error: errorUpdateExpiryDate,
    },
  ] = useUpdateDocumentExpiryDateMutation();

  const [
    updateCompanyMutation,
    {
      data: dataUpdateCompany,
      loading: loadingUpdateCompany,
      error: errorUpdateCompany,
    },
  ] = useUpdateCompanyMutation();

  const [
    createDocument,
    {
      data: dataCreateDocument,
      loading: loadingCreateDocument,
      error: errorCreateDocument,
    },
  ] = useCreateDocumentMutation();

  const [
    replaceInsuranceDocument,
    {
      data: dataReplaceInsuranceDocument,
      loading: loadingReplaceInsuranceDocument,
      error: errorReplaceInsuranceDocument,
    },
  ] = useReplaceInsuranceDocumentMutation();

  // Lazy queries
  const queryUser = useGetUsersLazyQuery();
  const [usersInputOpen, setUsersInputOpen] = useState(false);
  const {
    data: dataUsers,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
    page: searchUsersPage,
    setPage: setSearchUsersPage,
  } = useSearch(
    queryUser,
    { limit: 3, company_id: user?.getUser.company?.id },
    300,
    usersInputOpen
  );

  const queryVatCountries = useGetCountriesLazyQuery();
  const [isVatCountryDropOpen, setIsVatCountryDropOpen] = useState(false);
  const {
    data: dataVatCountries,
    loading: loadingVatCountries,
    search: searchVatCountries,
    setSearch: setSearchVatCountries,
  } = useSearch(queryVatCountries, { limit: 5 }, 300, isVatCountryDropOpen);

  const queryIbanCountries = useGetCountriesLazyQuery();
  const [isIbanCountryDropOpen, setIsIbanCountryDropOpen] = useState(false);
  const {
    data: dataIbanCountries,
    loading: loadingIbanCountries,
    search: searchIbanCountries,
    setSearch: setSearchIbanCountries,
  } = useSearch(queryIbanCountries, { limit: 5 }, 300, isIbanCountryDropOpen);

  // Handlers
  const handleUpdate = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const companyPayload = {
      id: Number(user?.getUser?.company?.id),
      name: company.name,
      taxNumber: `${company?.taxNumberCountry}${company?.taxNumber}`,
      contactUser_id: company.contactUser_id,
      IBAN: `${company?.ibanCountry} ${company?.iban}`,
      BIC: company.bic,
    };
    const initialCompany = {
      id: Number(user?.getUser?.company?.id),
      name: user?.getUser?.company?.name,
      taxNumber: user?.getUser?.company?.taxNumber,
      contactUser_id: user?.getUser?.company?.contactUser_id,
      IBAN: user?.getUser?.company?.IBAN,
      BIC: user?.getUser?.company?.BIC,
    };

    if (!isEqual(companyPayload, initialCompany)) {
      validator({
        schema: companySchema,
        data: {
          id: company.id,
          name: company.name,
          contactUser_id: company.contactUser_id,
        },
        vat: `${company?.taxNumberCountry}${company?.taxNumber}`,
        iban: `${company?.ibanCountry} ${company?.iban}`,
        bic: company.bic,
        success: () => {
          updateCompanyMutation({
            variables: {
              CompanyUpdate: companyPayload,
            },
          });
        },
      });
    }

    if (
      !isEqual(
        new Date(me?.getUser?.company?.insuranceCertificate?.expiryDate),
        new Date(expiryDate!)
      ) &&
      expiryDate
    ) {
      const payload = {
        expiryDate: moment(expiryDate)
          .tz(me?.getUser?.company?.location?.timezone!)
          ?.format(),
        id: me?.getUser?.company?.insuranceCertificate?.id!,
      };

      validator({
        schema: expiryDateSchema(),
        data: {
          ...payload,
          expiryDate: new Date(expiryDate),
        },
        success: () => {
          updateExpiryDate({
            variables: payload,
          });
        },
      });
    }

    if (
      insuranceDocumentFile &&
      insuranceDocumentFile?.name &&
      insuranceDocumentFile?.size &&
      expiryDate
    ) {
      if (!me?.getUser?.company?.insuranceCertificate?.id) {
        createDocument({
          variables: {
            document: {
              company_id: me?.getUser?.company?.id!,
              type: DocumentType.Insurance,
              expiryDate: moment(expiryDate)
                .tz(me?.getUser?.company?.location?.timezone!)
                ?.format(),
              fileName: insuranceDocumentFile?.name,
              fileSize: insuranceDocumentFile?.size,
              fileType: insuranceDocumentFile?.type,
              date: moment().format(),
              status: SqlModelStatus.Active,
            },
          },
        });
      } else {
        replaceInsuranceDocument({
          variables: {
            id:
              me?.getUser?.company?.insuranceCertificate?.id! ||
              user?.getUser?.company?.insuranceCertificate?.id!,
            document: {
              company_id:
                me?.getUser?.company?.id! || user?.getUser?.company?.id!,
              type: DocumentType.Insurance,
              expiryDate: moment(expiryDate)
                .tz(me?.getUser?.company?.location?.timezone!)
                ?.format(),
              fileName: insuranceDocumentFile?.name,
              fileSize: insuranceDocumentFile?.size,
              fileType: insuranceDocumentFile?.type,
              date: moment().format(),
            },
          },
        });
      }
    }
  };

  const uploadInsuranceFile = (e: any) => {
    const file = e.target.files[0];

    if (!DocumentInputAcceptExtensions.includes(file?.type)) {
      return responseMsgs([
        {
          type: "error",
          string: "Please upload a valid file type.",
        },
      ]);
    }

    setInsuranceDocumentFile(file);
  };

  // Simple variables
  const isDisabledCompany = useMemo(() => {
    const companyState = {
      name: company.name,
      taxNumber: company.taxNumberCountry! + company.taxNumber,
      iban: `${company.ibanCountry} ${company.iban}`,
      BIC: company.bic,
      contactUser_id: company.contactUser_id,
    };
    const initialCompanyState = {
      name: user?.getUser?.company?.name,
      taxNumber: user?.getUser?.company?.taxNumber,
      iban: user?.getUser?.company?.IBAN,
      BIC: user?.getUser?.company?.BIC,
      contactUser_id: user?.getUser?.company?.contactUser_id,
    };

    const isExpiryDateChanged =
      moment(me?.getUser?.company?.insuranceCertificate?.expiryDate).format(
        "YYYY-MM-DD"
      ) !== moment(expiryDate).format("YYYY-MM-DD");

    const isInsuranceDocumentChanged = !isEqual(
      {
        name: insuranceDocumentFile?.name || insuranceDocument?.fileName,
        size: insuranceDocumentFile?.size || insuranceDocument?.fileSize,
        type: insuranceDocumentFile?.type || insuranceDocument?.fileType,
      },
      {
        name: me?.getUser?.company?.insuranceCertificate?.fileName,
        size: me?.getUser?.company?.insuranceCertificate?.fileSize,
        type: me?.getUser?.company?.insuranceCertificate?.fileType,
      }
    );

    return (
      isEqual(companyState, initialCompanyState) &&
      !isExpiryDateChanged &&
      !isInsuranceDocumentChanged
    );
  }, [
    insuranceDocument?.fileName,
    insuranceDocument?.fileSize,
    insuranceDocument?.fileType,
    insuranceDocumentFile?.name,
    insuranceDocumentFile?.size,
    insuranceDocumentFile?.type,
    company.bic,
    company.contactUser_id,
    company.iban,
    company.ibanCountry,
    company.name,
    company.taxNumber,
    company.taxNumberCountry,
    expiryDate,
    me?.getUser?.company?.insuranceCertificate?.expiryDate,
    me?.getUser?.company?.insuranceCertificate?.fileName,
    me?.getUser?.company?.insuranceCertificate?.fileSize,
    me?.getUser?.company?.insuranceCertificate?.fileType,
    user?.getUser?.company?.BIC,
    user?.getUser?.company?.IBAN,
    user?.getUser?.company?.contactUser_id,
    user?.getUser?.company?.name,
    user?.getUser?.company?.taxNumber,
  ]);

  const loading =
    userLoading ||
    loadingUpdateCompany ||
    !me?.getUser.company?.contactUser_id ||
    loadingUpdateExpiryDate ||
    loadingReplaceInsuranceDocument ||
    loadingCreateDocument;

  // Effects
  useEffect(() => {
    if (
      dataUpdateCompany ||
      dataUpdateExpiryDate ||
      dataReplaceInsuranceDocument
    ) {
      refetchMe();
      refetchUser();
    }
  }, [
    dataReplaceInsuranceDocument,
    dataUpdateCompany,
    dataUpdateExpiryDate,
    refetchMe,
    refetchUser,
  ]);

  useEffect(() => {
    async function uploadFile() {
      try {
        if (
          dataReplaceInsuranceDocument?.replaceInsuranceDocument?.fileUrl &&
          dataReplaceInsuranceDocument?.replaceInsuranceDocument?.fileSize
        ) {
          const res = await uploadFileToAWS(
            dataReplaceInsuranceDocument?.replaceInsuranceDocument?.fileUrl,
            insuranceDocumentFile as File
          );

          if (res?.status !== 200) {
            responseMsgs([
              {
                type: "error",
                string: "Something went wrong, please contact our support team",
              },
            ]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (
      dataReplaceInsuranceDocument &&
      insuranceDocumentFile?.name &&
      insuranceDocumentFile?.size &&
      insuranceDocumentFile?.type
    ) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReplaceInsuranceDocument, insuranceDocumentFile]);

  useEffect(() => {
    async function uploadFile() {
      try {
        if (dataCreateDocument?.createDocument?.fileUrl) {
          const res = await uploadFileToAWS(
            dataCreateDocument?.createDocument?.fileUrl,
            insuranceDocumentFile as File
          );

          if (res?.status !== 200) {
            responseMsgs([
              {
                type: "error",
                string: "Something went wrong, please contact our support team",
              },
            ]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (
      dataCreateDocument &&
      insuranceDocumentFile?.name &&
      insuranceDocumentFile?.size &&
      insuranceDocumentFile?.type
    ) {
      uploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreateDocument, insuranceDocumentFile]);

  // BE handlers
  ErrorsBeHandler({
    error: errorUpdateCompany,
  });

  SuccessBeHandler({
    data: dataUpdateCompany,
    code: "COMPANY_SETTINGS_UPDATE",
  });

  ErrorsBeHandler({
    error: userError,
  });

  ErrorsBeHandler({
    error: errorUpdateExpiryDate,
  });

  SuccessBeHandler({
    data: dataUpdateExpiryDate,
    code: "DOCUMENT_EXPIRY_DATE_UPDATE",
  });

  SuccessBeHandler({
    data: dataReplaceInsuranceDocument,
    code: "DOCUMENT_REPLACE",
  });

  ErrorsBeHandler({
    error: errorReplaceInsuranceDocument,
  });

  SuccessBeHandler({
    data: dataCreateDocument,
    code: "DOCUMENT_CREATED",
  });

  ErrorsBeHandler({
    error: errorCreateDocument,
  });

  return (
    <Container
      maxW="440px"
      align={width > 700 ? "center" : "flex-start"}
      minH="680px"
      h={`${height}px`}
      p={{ x: "0.5rem", t: "1rem" }}
      d="flex"
    >
      <Div w="100%">
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          Company legal name
        </Text>
        {loading ? (
          <Skeleton height="46px" />
        ) : (
          <Input
            name="name"
            textSize={"16"}
            h="48px"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCompany({
                ...company,
                [e.target.name]: e.target.value,
              })
            }
            value={company.name}
            autoComplete="false"
          />
        )}
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          VAT number
        </Text>
        {loading ? (
          <Skeleton height="46px" />
        ) : (
          <Div style={{ zIndex: 981 }} pos="relative">
            <PrefixDropdown
              inputValue={company?.taxNumber ?? ""}
              countryInputValue={searchVatCountries}
              h="48px"
              format="## ### ### ### #"
              addNew={true}
              displayResult={
                <Div
                  d="flex"
                  h="24px"
                  align="center"
                  justify="center"
                  w="25px"
                  m={{ l: "0", r: "-0.75rem" }}
                >
                  <Text
                    textColor="dark"
                    textWeight="500"
                    textSize={16}
                    m={{ l: "0" }}
                  >{`${company?.taxNumberCountry}`}</Text>
                </Div>
              }
              isLoading={loadingVatCountries}
              isOpen={isVatCountryDropOpen}
              setIsOpen={setIsVatCountryDropOpen}
              setCountryInput={setSearchVatCountries}
              handleChange={(value: any) => {
                setCompany({
                  ...company,
                  taxNumber: value,
                });
              }}
              menuList={dataVatCountries?.getCountries.items?.map(
                (item: Country, i: number) => (
                  <Anchor
                    d="flex"
                    p={{ y: "0.5rem", x: "0.75rem" }}
                    key={i}
                    align="center"
                    onClick={() => {
                      setCompany({
                        ...company,
                        taxNumberCountry: item.code,
                      });
                      setIsVatCountryDropOpen(false);
                    }}
                  >
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      p={{ l: "10px" }}
                    >
                      {" "}
                      {`${item.code}`}
                    </Text>
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      p={{ l: "10px" }}
                    >
                      {" "}
                      {`${item.name}`}
                    </Text>
                  </Anchor>
                )
              )}
            />
          </Div>
        )}

        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          IBAN
        </Text>
        {loading ? (
          <Skeleton height="46px" />
        ) : (
          <Div style={{ zIndex: 980 }} pos="relative">
            <PrefixDropdown
              inputValue={company?.iban ?? ""}
              countryInputValue={searchIbanCountries}
              h="48px"
              format="## #### #### #### ###"
              addNew={true}
              displayResult={
                <Div
                  d="flex"
                  h="24px"
                  align="center"
                  justify="center"
                  w="25px"
                  m={{ l: "0", r: "-0.75rem" }}
                >
                  <Text
                    textColor="dark"
                    textWeight="500"
                    textSize={16}
                    m={{ l: "0" }}
                  >{`${company?.ibanCountry}`}</Text>
                </Div>
              }
              isLoading={loadingIbanCountries}
              isOpen={isIbanCountryDropOpen}
              setIsOpen={setIsIbanCountryDropOpen}
              setCountryInput={setSearchIbanCountries}
              handleChange={(value: any) => {
                setCompany({
                  ...company,
                  iban: value,
                });
              }}
              menuList={dataIbanCountries?.getCountries.items?.map(
                (item: any, i: number) => (
                  <Anchor
                    d="flex"
                    p={{ y: "0.5rem", x: "0.75rem" }}
                    key={i}
                    align="center"
                    onClick={() => {
                      setCompany({
                        ...company,
                        ibanCountry: item.code,
                      });
                      setIsIbanCountryDropOpen(false);
                    }}
                  >
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      p={{ l: "10px" }}
                    >
                      {" "}
                      {`${item.code}`}
                    </Text>
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      p={{ l: "10px" }}
                    >
                      {" "}
                      {`${item.name}`}
                    </Text>
                  </Anchor>
                )
              )}
            />
          </Div>
        )}
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          BIC
        </Text>
        {loading ? (
          <Skeleton height="46px" />
        ) : (
          <Input
            name="bic"
            textSize={"16"}
            h="48px"
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCompany({
                ...company,
                [e.target.name]: e.target.value,
              })
            }
            value={company.bic}
            autoComplete="false"
          />
        )}
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          Contact person
        </Text>

        {loading ? (
          <Skeleton height="46px" classN="table_skeleton" />
        ) : (
          <>
            <Div style={{ zIndex: 979 }} pos="relative">
              <InputDropdown
                page={searchUsersPage}
                setPage={setSearchUsersPage}
                totalPages={Math.ceil(dataUsers?.getUsers?.total / 3)}
                showSearchField={true}
                value={searchUsers}
                h="48px"
                addNew={false}
                displayResult={
                  <Div d="flex" h="24px" align="center">
                    <Text
                      textColor={company?.id !== null ? "dark" : `semiLight`}
                      textWeight="500"
                      m={{ r: "auto" }}
                      textSize={16}
                    >
                      {company?.id !== null
                        ? ` ${company.contactUserName}`
                        : `Select contact name`}
                    </Text>
                  </Div>
                }
                isLoading={loadingUsers}
                isOpen={usersInputOpen}
                setIsOpen={setUsersInputOpen}
                handleChange={setSearchUsers}
                menuList={
                  dataUsers &&
                  dataUsers?.getUsers.items?.map((user: User, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        setCompany({
                          ...company,
                          contactUser_id: user.id,
                          contactUserName: `${user.firstName} ${user.lastName}`,
                        });
                        setSearchUsers("");
                        setUsersInputOpen(false);
                      }}
                    >
                      <Text textColor="semiDark" hoverTextColor="dark">
                        {" "}
                        {`${user.firstName} ${user.lastName}`}
                      </Text>
                    </Anchor>
                  ))
                }
              />
            </Div>
          </>
        )}
        {user?.getUser?.company?.type === CompanyType.Transporter && (
          <>
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem", t: "0.5rem" }}
            >
              Insurance certificate's expiry date
            </Text>
            <Input
              w="100%"
              placeholder="DD/MM/YYYY"
              h="3rem"
              m={{ b: "0.5rem" }}
              name="insurance_date"
              type="date"
              value={expiryDate ? moment(expiryDate).format("YYYY-MM-DD") : ""}
              onChange={(e: any) => {
                setExpiryDate(e.target.value);
              }}
            />
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem", t: "0.5rem" }}
            >
              Insurance certificate
            </Text>
            <TertiaryBtn
              text={"Upload new"}
              h="48px"
              prefixIcon={
                <Icon
                  name="Upload"
                  color="primary"
                  m={{ r: "0.5rem" }}
                  size="20px"
                />
              }
              styleBtn={{ margin: "0 0 0.5rem 0" }}
              handleSubmit={() => fileInputRef.current.click()}
            />
            <input
              onChange={uploadInsuranceFile}
              type="file"
              hidden
              ref={fileInputRef}
              key={1}
              accept={DocumentInputAcceptExtensions.join(",")}
            />
            {(insuranceDocument?.fileName || insuranceDocumentFile?.name) && (
              <Div
                d="flex"
                justify="space-between"
                align="center"
                m={{ b: "1rem" }}
              >
                <Tag bg="primary" textColor="background" m={{ r: "0.5rem" }}>
                  {insuranceDocumentFile?.name ||
                    insuranceDocument?.fileName ||
                    "No name provided"}
                </Tag>
                <Icon
                  cursor="pointer"
                  onClick={() => {
                    me?.getUser?.company?.insuranceCertificate?.fileUrl &&
                      me?.getUser?.company?.insuranceCertificate?.fileName &&
                      downloadFromUrl(
                        me?.getUser?.company?.insuranceCertificate?.fileUrl,
                        me?.getUser?.company?.insuranceCertificate?.fileName
                      );
                  }}
                  name="Download"
                  size="1.25rem"
                />
              </Div>
            )}
          </>
        )}
        <PrimaryBtn
          w="100%"
          handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
            handleUpdate(e)
          }
          styleBtn={{ marginTop: "2rem" }}
          isLoading={loading}
          disabled={isDisabledCompany}
          text={"Update"}
        />
      </Div>
    </Container>
  );
};

export default SettingsCompany;
