import React, { useEffect, useState } from "react";
import { Row, Col, Text, Div, Anchor } from "atomize";
import {
  AssignedUserType,
  GetActivityLogsDocument,
  GetOrderDocument,
  GetOrdersDocument,
  useAssignUserMutation,
  useGetMeQuery,
  useGetUsersLazyQuery,
  User,
} from "../../../generated/graphql";
import InputDropdown from "../../InputDropdown/InputDropdown";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import * as yup from "yup";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { useReactiveVar } from "@apollo/client";
import { modalData } from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { StyledModal } from "../utils/helpers";

const schema = yup
  .number()
  .required({ name: "User", code: "DATA_NOT_SELECTED" });

const AssignUserToOrder: React.FC = () => {
  const { name, msg, props } = useReactiveVar(modalData);

  const { data: me } = useGetMeQuery();

  const [
    assignedUser,
    {
      data: assignUserData,
      loading: assignUserLoading,
      error: assignUserError,
    },
  ] = useAssignUserMutation();
  const [selected, setSelected] = useState<AssignedUserType | null>(null);
  const { validator } = ValidationsFeHandler();

  const [user, setUser] = useState<{ name: string; id: null | number }>({
    name: "",
    id: null,
  });

  useEffect(() => {
    if (name === "assignUserToOrder") {
      setUser({
        ...user,
        id: props.user_id,
      });
      const type: AssignedUserType =
        name === "assignUserToOrder" &&
        props &&
        props.options &&
        props.options.includes(AssignedUserType.Owner)
          ? AssignedUserType.Owner
          : props.options.includes(AssignedUserType.Shipper)
          ? AssignedUserType.Shipper
          : props.options.includes(AssignedUserType.Receiver)
          ? AssignedUserType.Receiver
          : AssignedUserType.Transporter;
      setSelected(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  ErrorsBeHandler({
    error: assignUserError,
    dep: name === "assignUserToOrder",
    fn: () => {
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  SuccessBeHandler({
    data: assignUserData,
    code: "ASSIGN_USER",
    dep: name === "assignUserToOrder",
    fn: () => {
      setSelected(null);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const queryUser = useGetUsersLazyQuery();
  const [usersInputOpen, setUsersInputOpen] = useState(false);
  const {
    data: dataUsers,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
    page: searchUsersPage,
    setPage: setSearchUsersPage,
  } = useSearch(
    queryUser,
    { limit: 5, company_id: me?.getUser.company?.id },
    300,
    usersInputOpen
  );

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "assignUserToOrder")}
      onClose={() => {
        setSelected(null);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "assignUserToOrder" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            {msg}
          </Text>

          <Row>
            <Col>
              <Row>
                <Col d="flex">
                  <Text m={{ t: "0.5rem", b: "2rem" }} textColor="semiDark">
                    {"Are you sure you want add dispacher to follow the order?"}
                  </Text>
                </Col>
              </Row>

              {props.user_id === null && (
                <InputDropdown
                  page={searchUsersPage}
                  setPage={setSearchUsersPage}
                  totalPages={Math.ceil(dataUsers?.getUsers?.total / 5)}
                  showSearchField={true}
                  value={searchUsers}
                  addNew={false}
                  displayResult={
                    <Div d="flex" h="24px" align="center">
                      <Text
                        textColor={user?.id !== null ? "dark" : `semiLight`}
                        textWeight="500"
                        m={{ r: "auto" }}
                        textSize={14}
                      >
                        {user?.id !== null
                          ? ` ${user.name}`
                          : `Select dispacher`}
                      </Text>
                    </Div>
                  }
                  isLoading={loadingUsers}
                  isOpen={usersInputOpen}
                  setIsOpen={setUsersInputOpen}
                  handleChange={setSearchUsers}
                  menuList={
                    dataUsers
                      ? dataUsers?.getUsers.items?.map(
                          (user: User, i: number) => (
                            <Anchor
                              d="flex"
                              p={{ y: "0.5rem", x: "0.75rem" }}
                              key={i}
                              align="center"
                              onClick={() => {
                                if (user.id) {
                                  setUser({
                                    name: `${user.firstName} ${user.lastName}`,
                                    id: Number(user.id),
                                  });
                                  setSearchUsers("");
                                  setUsersInputOpen(false);
                                }
                              }}
                            >
                              <Text textColor="semiDark" hoverTextColor="dark">
                                {" "}
                                {`${user.firstName} ${user.lastName}`}
                              </Text>
                            </Anchor>
                          )
                        )
                      : ""
                  }
                />
              )}
              <Div h="2rem"></Div>
              <Row>
                <Col size="auto">
                  <PrimaryBtn
                    w="120px"
                    handleSubmit={async (e: any) => {
                      e.preventDefault();
                      validator({
                        schema: schema,
                        data: Number(user!.id),
                        success: () => {
                          selected &&
                            assignedUser({
                              variables: {
                                user_id: Number(user!.id),
                                type: selected,
                                id: Number(props.order_id),
                              },
                              refetchQueries: [
                                GetOrderDocument,
                                GetOrdersDocument,
                                GetActivityLogsDocument,
                              ],
                              awaitRefetchQueries: true,
                            });
                        },
                      });
                    }}
                    isLoading={assignUserLoading}
                    text={"Assign"}
                  />
                </Col>
                <Col size="auto">
                  <SecondaryBtn
                    w="120px"
                    handleSubmit={() => {
                      modalData({
                        name: "",
                        msg: "",
                        returned_value: null,
                        props: null,
                      });
                    }}
                    isLoading={assignUserLoading}
                    text={"Cancel"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default AssignUserToOrder;
