import React, { useEffect, useState } from "react";
import { Container, Row, Col, Text, Input, Label, Checkbox } from "atomize";
import {
  GetUserNotificationSettingsDocument,
  NotificationChannel,
  useGetMeQuery,
  useGetUserNotificationSettingsQuery,
  useRequestEmailChangeMutation,
  useRequestPasswordResetMutation,
  useSetAllNotificationChannelMutation,
} from "../../generated/graphql";
import Skeleton from "../../components/Skeleton/Skeleton";
import * as yup from "yup";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import Border from "../../components/Border/Border";

let schema = yup
  .string()
  .trim()
  .required({ name: "Email", code: "REQUIRED_FIELD" })
  .email({ name: "Email", code: "INVALID_EMAIL" });

const SettingsIndividual: React.FC = () => {
  const { width } = useWindowDimensions();

  const [
    requestPasswordReset,
    {
      data: requestPasswordResetData,
      loading: requestPasswordResetDataLoading,
      error: requestPasswordResetError,
    },
  ] = useRequestPasswordResetMutation({ errorPolicy: "all" });
  const [
    requestEmailReset,
    {
      data: requestEmailResetData,
      loading: requestEmailResetDataLoading,
      error: requestEmailResetError,
    },
  ] = useRequestEmailChangeMutation({ errorPolicy: "all" });
  const [
    setAllNotificationChannel,
    { data: AllNotificationChannel, error: AllNotificationChannelError },
  ] = useSetAllNotificationChannelMutation({ errorPolicy: "all" });

  const [subscribe, setSubscribe] = useState<{
    email: boolean;
    notification: boolean;
  }>({
    email: true,
    notification: true,
  });

  const { data: me, loading: meLoading } = useGetMeQuery();
  const { loading: notificationSettingsLoading } =
    useGetUserNotificationSettingsQuery({
      skip: !me,
      onCompleted: (data) => {
        if (data.getUserNotificationSettings) {
          if (
            data.getUserNotificationSettings![0].channel ===
            NotificationChannel.AllChannels
          ) {
            setSubscribe({ email: true, notification: true });
          } else if (
            data.getUserNotificationSettings![0].channel ===
            NotificationChannel.None
          ) {
            setSubscribe({ email: false, notification: false });
          } else if (
            data.getUserNotificationSettings![0].channel ===
            NotificationChannel.Email
          ) {
            setSubscribe({ email: true, notification: false });
          } else if (
            data.getUserNotificationSettings![0].channel ===
            NotificationChannel.Notification
          ) {
            setSubscribe({ email: false, notification: true });
          }
        }
      },
    });

  const { validator } = ValidationsFeHandler();

  const [state, setState] = useState({
    email: "",
    new_email: "",
  });

  const [notificationsChange, setNotificationsChange] = useState(false);

  useEffect(() => {
    if (me?.getUser.email) {
      setState({
        ...state,
        email: me.getUser.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmitPassword = async (
    e: React.ChangeEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (me?.getUser.email) {
      requestPasswordReset({ variables: { email: me.getUser.email } });
    }
  };

  const handleSubmitEmail = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validator({
      schema: schema,
      data: state.new_email,
      success: () => {
        if (me?.getUser?.email) {
          requestEmailReset({
            variables: { newEmail: state.new_email, email: me.getUser.email },
          });
        }
      },
    });
  };

  const handleSubscribeSubmit = () => {
    let select: NotificationChannel | null = null;

    if (subscribe.email && subscribe.notification) {
      select = NotificationChannel.AllChannels;
    } else if (subscribe.email && !subscribe.notification) {
      select = NotificationChannel.Email;
    } else if (!subscribe.email && subscribe.notification) {
      select = NotificationChannel.Notification;
    } else {
      select = NotificationChannel.None;
    }

    if (select) {
      setAllNotificationChannel({
        variables: { user_id: me?.getUser.id, channel: select },
        refetchQueries: [GetUserNotificationSettingsDocument],
        awaitRefetchQueries: true,
      });
    }
  };

  useEffect(() => {
    if (notificationsChange) {
      handleSubscribeSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsChange]);

  ErrorsBeHandler({
    error: requestPasswordResetError,
  });
  ErrorsBeHandler({
    error: requestEmailResetError,
  });
  ErrorsBeHandler({
    error: AllNotificationChannelError,
    fn: () => {
      setNotificationsChange(false);
    },
  });

  SuccessBeHandler({
    data: requestPasswordResetData,
    code: "REQUEST_PASSWORD_RESET",
  });

  SuccessBeHandler({
    data: requestEmailResetData,
    code: "REQUEST_EMAIL_RESET",
  });

  SuccessBeHandler({
    data: AllNotificationChannel,
    code: "SUBSCRIBE_CHANGE_SUCCESS",
    fn: () => {
      setNotificationsChange(false);
    },
  });

  return (
    <Container
      maxW="440px"
      align={width > 700 ? "center" : "flex-start"}
      h="100%"
      p={{ x: "0.5rem", y: "1rem" }}
      d="flex"
      justify="center"
      w="-webkit-fill-available"
    >
      <Row flexDir="column" minH="730px" w="100%">
        <Col size="12">
          <Text
            textColor="dark"
            textSize={20}
            textWeight={500}
            textAlign="center"
            m={{ b: "1rem" }}
          >
            Change your email
          </Text>

          <Border></Border>

          <Text
            textColor="semiLight"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1rem" }}
          >
            Your email account
          </Text>
          {meLoading ? (
            <Skeleton height="2.1rem" />
          ) : (
            <Input
              textSize={"16"}
              type="text"
              bg="grey"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeEmail(e)
              }
              name="email"
              value={state.email}
              disabled={true}
              h="48px"
              textColor="semiLight"
            />
          )}
        </Col>

        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "0.5rem" }}
          >
            Your new email account
          </Text>

          <Input
            textSize={"16"}
            type="text"
            h="48px"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeEmail(e)
            }
            name="new_email"
            value={state.new_email}
          />
        </Col>

        <Col size="12">
          <PrimaryBtn
            w="100%"
            handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
              handleSubmitEmail(e)
            }
            styleBtn={{ marginTop: "1rem" }}
            isLoading={requestEmailResetDataLoading || meLoading}
            text={"Update Email"}
          />
        </Col>

        <Col size="12">
          <Text
            textColor="dark"
            textSize={20}
            textWeight={500}
            textAlign="center"
            m={{ b: "1rem", t: "4rem" }}
          >
            Change your password
          </Text>
          <Border></Border>
          <PrimaryBtn
            w="100%"
            handleSubmit={handleSubmitPassword}
            styleBtn={{ marginTop: "1rem" }}
            isLoading={requestPasswordResetDataLoading || meLoading}
            text={"Request password change"}
          />
        </Col>

        <Col size="12">
          <Text
            textColor="dark"
            textSize={20}
            textWeight={500}
            textAlign="center"
            m={{ b: "1rem", t: "4rem" }}
          >
            Update your notification settings
          </Text>
          <Border></Border>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            textAlign="center"
            m={{ b: "1rem", t: "1rem" }}
          >
            How would you like to receive your notifications?
          </Text>

          <Row justify="center">
            <Col size="auto">
              <Label
                align="center"
                textWeight="500"
                textColor={subscribe.email ? "dark" : "greyBorder"}
              >
                {meLoading || notificationSettingsLoading ? (
                  <Skeleton
                    width="18px"
                    height="18px"
                    borderRadius="4"
                    margin="0 0.5rem 0 0"
                  />
                ) : (
                  <Checkbox
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setSubscribe({
                        ...subscribe,
                        email: !subscribe.email,
                      });
                      setNotificationsChange(true);
                    }}
                    inactiveColor={"greyBorder"}
                    checked={subscribe.email}
                  />
                )}
                Email {width > 390 ? "notifications" : ""}
              </Label>
            </Col>
            <Col size="auto">
              <Label
                align="center"
                textWeight="500"
                textColor={subscribe.notification ? "dark" : "greyBorder"}
              >
                {meLoading || notificationSettingsLoading ? (
                  <Skeleton
                    width="18px"
                    height="18px"
                    borderRadius="4"
                    margin="0 0.5rem 0 0"
                  />
                ) : (
                  <Checkbox
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setSubscribe({
                        ...subscribe,
                        notification: !subscribe.notification,
                      });
                      setNotificationsChange(true);
                    }}
                    inactiveColor={"greyBorder"}
                    checked={subscribe.notification}
                  />
                )}
                Website {width > 390 ? "notifications" : ""}
              </Label>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SettingsIndividual;
