import { Tag, Text } from "atomize";
import { isString } from "lodash";
import React from "react";
import Moment from "react-moment";
import { ActivityLog } from "../../generated/graphql";
import {
  getAssignedTypeName,
  getDocumentType,
  parseJSON,
} from "../../helpers/functions";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";

const ActivityRow: React.FC<{ activity: ActivityLog }> = ({ activity }) => {
  const { status, text, color, bg } = getInfo(
    activity.text as string,
    activity.data
  );

  return (
    <>
      <Tr hidBorderLast={true}>
        <Td width="15%">
          <Text textColor={"dark"} textWeight="500">
            <Tag p={{ x: "8px", y: "2px" }} textColor={color} bg={bg}>
              {status}
            </Tag>
          </Text>
        </Td>
        <Td width="65%">
          <Text textColor={"dark"}>{text}</Text>
        </Td>
        <Td width={"12%"}>
          <Text textColor="dark">
            <Moment format="D MMM YYYY" withTitle>
              {activity.ts}
            </Moment>
          </Text>
        </Td>
        <Td width={"8%"}>
          <Text textColor={"light"}>
            <Moment format="HH:mm" local>
              {activity.ts}
            </Moment>
          </Text>
        </Td>
      </Tr>
    </>
  );
};

export default ActivityRow;

function getInfo(action: string, data: any) {
  let status, text, color, bg;
  data = parseJSON(data, {});

  switch (action) {
    case "order/create":
      status = "Created";
      text = `Order has been created by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    case "order/assignUser":
      status = "Assigned";
      text = `${data.assignedUser_name} has been assigned to the order${
        getAssignedTypeName(data.type)
          ? " as " + getAssignedTypeName(data.type)
          : ""
      } by ${data.user_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    case "order/deleteAssignedUser":
      status = "Unassigned";
      text = `${data.assignedUser_name} has been removed from order by ${data.user_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    case "order/updateStatus":
    case "order/update":
      status = "Update";
      text = `Order updated by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    case "order/draft":
      status = "Draft";
      text = `Order has been moved to draft by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    case "order/onMarket":
      status = "On market";
      text = `Order sent to market by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/inTransit":
      status = "In transit";
      text = `Cargo is on the move and tracked by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/pending":
      status = "Pending";
      text = `Order set pending by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/ready":
      status = "Ready";
      text = `Order set ready by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/complete":
      status = "Completed";
      text = `Order completed by ${data.user_name} from ${data.userCompany_name}.`;
      color = "success900";
      bg = "success100";
      break;
    case "order/stored":
      status = "Stored";
      text = `Cargo moved to storage by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/onHold":
      status = "On hold";
      text = `Order put on hold by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;
    case "order/flag":
      status = "Flagged";
      text = `Order flagged by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;
    case "order/damage":
      status = "Damaged";
      text = `Cargo marked as damaged by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;
    case "order/cancel":
      status = "Cancel";
      text = `Order cancelled by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;
    case "order/delete":
      status = "Deleted";
      text = `Order deleted by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;
    case "bid/accept":
      status = "Accepted";
      text = `${data.user_name} from ${data.userCompany_name} accepted a bid from ${data.company_name}.`; //+ (data.price ? ' for ' + data.price + '€' : '');
      color = "info";
      bg = "info100";
      break;
    case "bid/decline":
      status = "Declined";
      text = `${data.user_name} from ${data.userCompany_name} declined a bid from ${data.company_name}.`; //+ (data.price ? ' for ' + data.price + '€' : '');
      color = "info";
      bg = "info100";
      break;
    case "document/create":
      status = getDocumentType(data.type);
      text = `${getDocumentType(data.type)} has been added by ${
        data.userCompany_name
      }.`;
      color = "info";
      bg = "info100";
      break;
    case "invoice/paid":
      status = "Invoice Paid";
      text = `Invoice has been paid by ${data?.payeeCompanyName}`;
      color = "success900";
      bg = "success100";
      break;
    case "invoice/discarded":
      status = "Invoice Discarded";
      text = `Invoice has been discarded by ${data?.payeeCompanyName}`;
      color = "grey900";
      bg = "grey";
      break;
    case "invoice/confirmed":
      status = "Invoice Confirmed";
      text = `Invoice has been confirmed by ${data?.issuerCompanyName}`;
      color = "info";
      bg = "info100";
      break;
    case "invoice/rejected":
      status = "Invoice Rejected";
      text = `Invoice has been rejected by ${data?.issuerCompanyName}`;
      color = "danger700";
      bg = "danger100";
      break;
    case "transport/update":
      status = "Transport Details Update";
      text = `Transport details were updated by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;

    case "transport/create":
      status = "Transport Details Created";
      text = `Transport was created by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;

    case "transport/delete":
      status = "Transport Details Deleted";
      text = `Transport was deleted by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;

    case "transport/assign-driver": {
      const driverContact = JSON.parse(data?.driver_contact ?? "{}");

      status = "Driver Assigned";
      text = `Driver ${driverContact?.fullName} has been assigned to the transport by ${data.user_name}.`;
      color = "grey900";
      bg = "grey";
      break;
    }

    case "transport/draft":
      status = "Draft";
      text = `Transport has been moved to draft by ${data.user_name} from ${data.userCompany_name}.`;
      color = "grey900";
      bg = "grey";
      break;

    case "transport/waiting_loading":
      status = "Waiting for loading";
      text = `Transport is waiting for loading by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;

    case "transport/in_transit":
      status = "In transit";
      text = `Transport is on the move and tracked by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;

    case "transport/unloaded":
      status = "Unloaded";
      text = `Transport has been unloaded by ${data.user_name} from ${data.userCompany_name}.`;
      color = "info";
      bg = "info100";
      break;

    case "transport/completed":
      status = "Completed";
      text = `Transport has been completed by ${data.user_name} from ${data.userCompany_name}.`;
      color = "success900";
      bg = "success100";
      break;

    case "transport/flagged":
      status = "Flagged";
      text = `Transport has been flagged by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;

    case "transport/damaged":
      status = "Damaged";
      text = `Transport has been marked as damaged by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;

    case "transport/deleted":
      status = "Deleted";
      text = `Transport has been deleted by ${data.user_name} from ${data.userCompany_name}.`;
      color = "danger700";
      bg = "danger100";
      break;

    case "location_point/added":
      status = "Location Point Added";
      text = `A new stop point has been added by ${data?.user_name}: ${[
        data?.locationInfo?.title,
        data?.locationInfo?.address,
        data?.locationInfo?.postCode,
        data?.locationInfo?.postName,
      ]
        .filter(isString)
        .join(", ")}`;
      color = "grey900";
      bg = "grey";
      break;

    default:
      status = action;
      text = action;
      color = "grey900";
      bg = "grey";
      break;
  }
  return { status, text, color, bg };
}
