import { Checkbox, Label, Text, Tag } from "atomize";
import moment from "moment";
import React from "react";
import Td from "../../../components/TableComponents/Td";
import Tr from "../../../components/TableComponents/Tr";

interface Props {
  row: any;
  view: any;
  onAccessChange: (
    hasAccess: boolean,
    company_id: number,
    module_id: number
  ) => void;
  timezone?: string;
}

const ModulePermissionsRow: React.FC<Props> = ({
  row,
  view,
  timezone = "",
  onAccessChange,
}) => {
  return (
    <Tr>
      <Td width={`${view[0].colW}px`}>
        <Tag>
          <Text>{row?.company_id}</Text>
        </Tag>
      </Td>
      <Td width={`${view[1].colW}px`}>
        <Text>{row?.companyInfo?.[0]?.name}</Text>
      </Td>
      <Td width={`${view[2].colW}px`}>
        <Text>{row?.module_name}</Text>
      </Td>
      <Td width={`${view[3].colW}px`}>
        <Label
          align="center"
          textSize={14}
          textTransform="capitalize"
          textWeight="500"
          m={{ b: "0.5rem" }}
        >
          <Checkbox
            onChange={(e: any) => {
              e.preventDefault();
              onAccessChange(!row?.hasAccess, row?.company_id, row?.module_id);
            }}
            checked={row?.hasAccess}
          />
          Has access
        </Label>
      </Td>
      <Td width={`${view[4].colW}px`}>
        <Text>{row?.companyInfo?.[0]?.contactEmail}</Text>
      </Td>
      <Td width={`${view[5].colW}px`}>
        <Text>
          {moment(row?.companyInfo?.[0]?.createTime)
            .tz(timezone)
            // ?.tz(me?.getUser?.company?.location?.timezone ?? "")
            ?.format("YYYY-MM-DD - HH:mm")}
        </Text>
      </Td>
    </Tr>
  );
};

export default ModulePermissionsRow;
