import { Col, Div, Row, Text } from "atomize";
import InvoiceSettings from "./components/InvoiceSettings";
import TaxationClauses from "./components/TaxationClauses";

const SettingsInvoicing = () => {
  return (
    <Row m={{ x: "0", y: "4rem" }}>
      <Col align="center" jc="center" d="flex" flexDir="column">
        <Div
          d="flex"
          align="flex-start"
          flexDir="column"
          w="100%"
          style={{ maxWidth: "650px" }}
        >
          <Text textSize="28px" textWeight="700">
            Automatic invoicing settings
          </Text>
          <InvoiceSettings />
          <TaxationClauses />
        </Div>
      </Col>
    </Row>
  );
};

export default SettingsInvoicing;
