import React from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text } from "atomize";
import PreloadingImage from "../PreloadingImage/PreloadingImage";
import Skeleton from "../Skeleton/Skeleton";
import { BidListingItem } from "../../generated/graphql";
import Moment from "react-moment";
import ShortNameLogo from "../ShortNameLogo/ShortNameLogo";
import { useHistory } from "react-router-dom";

const BidRow: React.FC<{ bid: BidListingItem; noLink?: boolean }> = ({
  bid,
  noLink,
}) => {
  const history = useHistory();

  return (
    <>
      <Tr hidBorderLast={true} className={noLink === true ? "" : "link"}>
        <Td
          width="100px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <Text textColor={"dark"} textWeight="600">
            {bid.price} EUR
          </Text>
        </Td>
        <Td
          width="180px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <PreloadingImage
            imageSrc={bid.company_logo || ""}
            skeletonComponent={
              <Skeleton height="32px" width="32px" borderRadius="32px" />
            }
            imgComponent={<ShortNameLogo name={bid.company_name || ""} />}
            imageClassNames="i_b f_l"
            imageStyle={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              verticalAlign: "middle",
            }}
          />

          <Text
            textColor={"semiDark"}
            m={{ l: "0.75rem" }}
            style={{ verticalAlign: "middle" }}
            className="i_b"
            textSize={142}
          >
            {bid.company_name}
          </Text>
        </Td>
        <Td
          width="200px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <Text p={{ l: "0.5rem" }}>
            {`${bid.loadingLocation_postName} -> ${bid.unloadingLocation_postName} `}
          </Text>
        </Td>
        <Td
          width="130px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <Text p={{ l: "0.5rem" }} textColor={"semiDark"}>
            <Moment format="D MMM" withTitle>
              {bid.requestedLoadingTime}
            </Moment>
            <span style={{ margin: "0 0.25rem" }}>→</span>
            <Moment format="D MMM" withTitle>
              {bid.requestedUnloadingTime}
            </Moment>
          </Text>
        </Td>
        <Td
          width="200px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <Text textColor={"dark"} textWeight="500">
            {bid.comment ? bid.comment : "None"}
          </Text>
        </Td>
        <Td
          width="100px"
          handleClick={() => {
            history.push(`/myorder/${bid.order_code}`);
          }}
        >
          <Text textColor={"semiDark"}>
            {bid.expirationDate ? (
              <Moment to={bid.expirationDate}>{Date.now()}</Moment>
            ) : (
              "None"
            )}
          </Text>
        </Td>
      </Tr>
    </>
  );
};

export default BidRow;
