import * as Yup from "yup";

export const newModulePermissionSchema = Yup.object().shape({
  companyId: Yup.number().required({
    name: "Company",
    code: "DATA_NOT_SELECTED",
  }),
  moduleId: Yup.number().required({
    name: "Module",
    code: "DATA_NOT_SELECTED",
  }),
  hasAccess: Yup.boolean().required({
    name: "Has Access",
    code: "REQUIRED_FIELD",
  }),
});
