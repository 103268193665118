import { Col, Div, Icon, Input, Row, Tag, Text } from "atomize";
import React, { useEffect, useState } from "react";
import { modalData } from "../../App";
import renderIcon from "../../assets/Icons";
import Pagination from "../../components/Pagination/Pagination";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import Section from "../../components/Section/Section";
import Skeleton from "../../components/Skeleton/Skeleton";
import SortIcon from "../../components/SortIcon/SortIcon";
import Table from "../../components/TableComponents/Table";
import Tbody from "../../components/TableComponents/Tbody";
import Td from "../../components/TableComponents/Td";
import Th from "../../components/TableComponents/Th";
import Thead from "../../components/TableComponents/Thead";
import Tr from "../../components/TableComponents/Tr";
import {
  CompanyStatus,
  GetModulePermissionsDocument,
  useGetMeQuery,
  useGetModulePermissionsQuery,
  useUpdateModulePermissionMutation,
} from "../../generated/graphql";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import { initialModulePermissions } from "../../helpers/initialData";
import ModulePermissionsRow from "./components/ModulePermissionsRow";

const initialView = [
  { field: "company_id", name: "Company ID", sort: true, colW: 50 },
  { field: "company_name", name: "Company Name", sort: false, colW: 100 },
  { field: "module_id", name: "Module", sort: true, colW: 100 },
  { field: "hasAccess", name: "Has Access", sort: true, colW: 100 },
  {
    field: "contactEmail",
    name: "Contact Email",
    sort: false,
    colW: 100,
  },
  { field: "createTime", name: "Created At", sort: false, colW: 100 },
];

const SettingsModulePermissions: React.FC = () => {
  const { height, width } = useWindowDimensions();
  const [modulePermissionsPags, setModulePermissionsPags] = useState({
    ...initialModulePermissions,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });

  const { data: me, loading: meLoading } = useGetMeQuery();

  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState("");

  const {
    data: modulePermissions,
    loading: modulePermissionsLoading,
    error: modulePermissionsError,
    refetch: refetchModulePermissions,
  } = useGetModulePermissionsQuery({
    variables: {
      query: { ...modulePermissionsPags },
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    updateModulePermission,
    {
      data: updateModulePermissionData,
      loading: updateModulePermissionLoading,
      error: updateModulePermissionError,
    },
  ] = useUpdateModulePermissionMutation({
    refetchQueries: [GetModulePermissionsDocument],
  });

  const isLoading =
    updateModulePermissionLoading || modulePermissionsLoading || meLoading;

  const handleChangeAccessField = (
    hasAccess: boolean,
    company_id: number,
    module_id: number
  ) => {
    updateModulePermission({
      variables: {
        moduleId: module_id,
        companyId: company_id,
        hasAccess,
      },
    });
  };

  ErrorsBeHandler({
    error: modulePermissionsError,
  });

  ErrorsBeHandler({
    error: updateModulePermissionError,
  });

  SuccessBeHandler({
    data: updateModulePermissionData,
    code: "MODULE_PERMISSION_UPDATED",
  });

  useDebouncedEffect(
    () =>
      setModulePermissionsPags({
        ...modulePermissionsPags,
        search: searchInput,
      }),
    [searchInput],
    searchInput === "" ? 0 : 300
  );

  useEffect(() => {
    if (modulePermissions && modulePermissionsPags.limit) {
      setTotalPages(
        Math.ceil(
          modulePermissions.getModulePermissions.total! /
            modulePermissionsPags.limit
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulePermissions?.getModulePermissions]);

  return (
    <Div h="100%">
      <Div
        d="flex"
        m={{ y: "0rem", x: "0.5rem" }}
        h="72px"
        overflow="hidden"
        align="center"
      >
        <Div w="100%">
          <Input
            h="32px"
            w="100%"
            p={{ x: "2rem" }}
            borderColor="greyBorder"
            textSize="12"
            placeholder="Search..."
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.target.value)
            }
            prefix={
              <Icon
                name="Search"
                size="16px"
                cursor="pointer"
                pos="absolute"
                color="semiDark"
                top="50%"
                left="0.5rem"
                transform="translateY(-50%)"
              />
            }
          />
        </Div>
        <Div d="flex" justify="flex-end">
          {isLoading || meLoading ? (
            <Skeleton height="2rem" width="40px" margin="0 0 0 0.5rem" />
          ) : (
            <SecondaryBtn
              w="40px"
              h="2rem"
              handleSubmit={() => refetchModulePermissions()}
              styleBtn={{ marginLeft: "0.5rem" }}
              isLoading={false}
              text={<Icon name="Refresh" size="16px" color="semiDark" />}
            />
          )}
        </Div>
        <Div d="flex" justify="flex-end">
          {isLoading ? (
            <Skeleton height="2rem" width="135px" margin="0 0  0 0.5rem" />
          ) : (
            <SecondaryBtn
              h="2rem"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                modalData({
                  msg: "Assign module permission",
                  name: "newModulePermission",
                  returned_value: null,
                  props: {
                    timezone: me?.getUser.company?.location?.timezone,
                    company_id: me?.getUser.company?.id,
                  },
                })
              }
              prefixIcon={
                <Icon
                  m={{ r: "6px" }}
                  name="Add"
                  size="18px"
                  color="semiDark"
                />
              }
              isLoading={meLoading}
              styleBtn={{
                display: "flex",
                justifyContent: "left",
                margin: "0 0 0 0.5rem",
                whiteSpace: "nowrap",
              }}
              text={"Add module permission"}
              textSize={12}
            />
          )}
        </Div>
      </Div>
      {modulePermissionsLoading && modulePermissionsPags?.limit ? (
        <Section margin="0rem 0.5rem">
          <Skeleton
            classN="table_skeleton"
            height={`${(modulePermissionsPags?.limit + 1) * 49 + 10}px`}
          />
        </Section>
      ) : (
        <>
          {modulePermissions?.getModulePermissions.total &&
          Number(modulePermissions?.getModulePermissions!.total) > 0 ? (
            <Section margin="0rem 0.5rem">
              <Table
                width={`calc(100% -  ${modulePermissionsLoading ? "0" : "0"})`}
              >
                <Tbody maxH="none">
                  <Tr hidBorderLast={true} className="border_none">
                    <Td width={"auto"} className="divider">
                      <Table>
                        <Thead>
                          <Tr className="head">
                            {initialView.map((col, i) => {
                              return (
                                <Th width={`${col.colW}px`} key={i}>
                                  <Text d="inline!important">{col.name}</Text>
                                  {col.sort && (
                                    <SortIcon
                                      sortBy={modulePermissionsPags}
                                      name={col.field}
                                      //   @ts-ignore
                                      setSortBy={setModulePermissionsPags}
                                    />
                                  )}
                                </Th>
                              );
                            })}
                          </Tr>
                        </Thead>
                        <Tbody maxH="none">
                          {modulePermissions?.getModulePermissions.items?.map(
                            (row: any, i: number) => (
                              <ModulePermissionsRow
                                // query={{ ...modulePermissionsPags }}
                                row={row}
                                view={initialView}
                                key={i}
                                onAccessChange={handleChangeAccessField}
                                timezone={
                                  me?.getUser.company?.location?.timezone ?? ""
                                }
                              />
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Section>
          ) : (
            ""
          )}
        </>
      )}
      {modulePermissions?.getModulePermissions.total === 0 &&
        !modulePermissionsLoading && (
          <>
            {searchInput ? (
              <Row m={{ x: "0rem", t: "0rem" }}>
                <Col>
                  <Tag
                    p={{ l: "1rem" }}
                    h="48px"
                    w="100%"
                    d="flex"
                    justify="start"
                    textColor="light"
                  >
                    We couldn’t find anything related to{" "}
                    {modulePermissionsPags.search}.
                  </Tag>
                </Col>
              </Row>
            ) : (
              <Row
                maxW="440px"
                h={`${height - 162}px`}
                align="center"
                p="32px 0px"
                d="flex"
                m={{ x: "auto" }}
              >
                <Col d="flex" flexDir="column" align="center">
                  <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                    {renderIcon("GreenBell")}
                  </Div>
                  <Text
                    textColor="dark"
                    textWeight="500"
                    textSize={20}
                    textAlign="center"
                    m={{ y: "2rem" }}
                  >
                    You don’t have any reminders yet.
                  </Text>
                  <Text
                    textColor="semiDark"
                    textWeight="400"
                    textSize={14}
                    textAlign="center"
                  >
                    With reminders you gain an ability to send custom alerts to
                    any team member. This way you can bring attention to
                    important events within your operations.
                  </Text>

                  {me?.getUser.company?.status === CompanyStatus.Active && (
                    <PrimaryBtn
                      w="100%"
                      handleSubmit={(
                        e: React.ChangeEvent<HTMLButtonElement>
                      ) => {
                        e.preventDefault();
                        modalData({
                          msg: "New reminder",
                          name: "newReminder",
                          returned_value: null,
                          props: {
                            timezone: me?.getUser.company?.location?.timezone,
                            company_id: me.getUser.company?.id,
                          },
                        });
                      }}
                      styleBtn={{ marginTop: "1.5rem" }}
                      isLoading={false}
                      text={"Create your first reminder"}
                    />
                  )}
                </Col>
              </Row>
            )}
          </>
        )}
      {totalPages ? (
        <Row m={{ x: "0" }}>
          <Col>
            <Pagination
              setQuery={setModulePermissionsPags}
              query={modulePermissionsPags}
              total={totalPages}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Div>
  );
};

export default SettingsModulePermissions;
