import { useReactiveVar } from "@apollo/client";
import { Col, Row, Text } from "atomize";
import { FC } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { myOrderView } from "../../../App";
import Section from "../../../components/Section/Section";
import Skeleton from "../../../components/Skeleton/Skeleton";
import Table from "../../../components/TableComponents/Table";
import Tbody from "../../../components/TableComponents/Tbody";
import Tr from "../../../components/TableComponents/Tr";
import { GetOrderQuery } from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import MyOrdersTableHead from "../../MyOrders/head/MyOrdersTableHead";
import MyOrderRow from "../../MyOrders/rows/MyOrderRow";

interface Props {
  isLoading: boolean;
  order: GetOrderQuery;
  orderLoading: boolean;
}

const OrderGroupTable: FC<Props> = ({ isLoading, order, orderLoading }) => {
  const { width } = useWindowDimensions();
  const myOrderViewVar = useReactiveVar(myOrderView);

  return (
    <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      <Col size="12">
        {isLoading ? (
          <Text textSize={20} textColor="dark">
            Group Name
          </Text>
        ) : (
          <Text textSize={20} textColor="dark">
            {order?.getOrder?.tags?.[0]?.tag || ""}
          </Text>
        )}
      </Col>
      <Col size="12">
        <Section margin="1.25rem 0 1.25rem 0rem">
          {isLoading ? (
            <Skeleton height="100px" />
          ) : (
            <Table>
              <MyOrdersTableHead />
              <Tbody
                maxH="none"
                tBodyStyle={{ overflowY: "auto", overflowX: "hidden" }}
              >
                <DragDropContext onDragEnd={() => {}}>
                  <Droppable droppableId="orderRows">
                    {(provided) => (
                      <Tr
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {order.getOrder.tagOrders?.map(
                          (row: any, index: number) => (
                            <>
                              {row.id !== order.getOrder.id && (
                                <Draggable
                                  key={row.id}
                                  draggableId={row.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <MyOrderRow
                                      row={row}
                                      view={myOrderViewVar}
                                      isLoading={orderLoading}
                                      key={row.id}
                                    />
                                  )}
                                </Draggable>
                              )}
                            </>
                          )
                        )}
                        {provided.placeholder}
                      </Tr>
                    )}
                  </Droppable>
                </DragDropContext>
              </Tbody>
            </Table>
          )}
        </Section>
      </Col>
    </Row>
  );
};

export default OrderGroupTable;
