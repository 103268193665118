import React, { useEffect } from "react";
import { Icon, Div } from "atomize";
import { useChangeEmailMutation } from "../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import { getParams } from "../../helpers/functions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import * as yup from "yup";

let schema = yup.object().shape({
  token: yup
    .string()
    .nullable()
    .required({ name: "Token", code: "TOKEN_MISSING" }),
});

const ResetEmail: React.FC = () => {
  const search = useLocation().search;
  const { validator } = ValidationsFeHandler();

  const token = getParams(search, "token", "?token=");
  const [changeEmail, { data: changeEmailData, error: changeEmailError }] =
    useChangeEmailMutation({ errorPolicy: "all" });
  const history = useHistory();

  useEffect(() => {
    validator({
      schema: schema,
      data: { token },
      success: () => {
        changeEmail({ variables: { token: token! } });
      },
      error: () => {
        history.push("/settings/individual");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ErrorsBeHandler({
    error: changeEmailError,
    fn: () => {
      history.push("/settings/individual");
    },
  });

  SuccessBeHandler({
    data: changeEmailData,
    code: "RESET_EMAIL",
    fn: () => {
      history.push("/settings/individual");
    },
  });

  return (
    <Div d="flex" align="center" justify="center" w="100%" h="100vh">
      <Icon name="Loading" size="200px" color="grey" />
    </Div>
  );
};

export default ResetEmail;
