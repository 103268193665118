import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import React, { useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetCompaniesDocument,
  GetLocationsDocument,
  LocationType,
  useCreatePlaceholderMutation,
} from "../../../generated/graphql";
import { GoogleAPI } from "../../../helpers/CustomHooks/googleAPIHook";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import ProgressLine from "../../ProgressLine/ProgressLine";
import { StyledModal } from "../utils/helpers";
import CreatePlaceholderCompany from "./CreatePlaceholderCompany";
import CreatePlaceholderLocation from "./CreatePlaceholderLocation";
import CreatePlaceholderUser from "./CreatePlaceholderUser";

const one = {
  type: null,
  name: "",
  taxNumberCountry: "SI",
  taxNumber: "",
  contactEmail: "",
};

const two = {
  address: "",
  city: "",
  postalCode: "",
  timezone: "",
  country: {
    id: null,
    name: "",
    code: "",
  },
};

const three = {
  firstName: "",
  lastName: "",
  phone: "",
  phoneCountryNumbers: "386",
  email: "",
};

let schemaOne = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required({ name: "Company name", code: "REQUIRED_FIELD" }),
  contactEmail: yup
    .string()
    .required({ name: "Company email", code: "REQUIRED_FIELD" })
    .email({ name: "Company email", code: "INVALID_EMAIL" }),
  type: yup
    .string()
    .nullable()
    .required({ name: "Company type", code: "REQUIRED_FIELD" }),
});

let schemaTwo = yup.object().shape({
  country_id: yup
    .number()
    .nullable()
    .required({ name: "Country", code: "REQUIRED_FIELD" }),
  address: yup
    .string()
    .trim()
    .required({ name: "Address", code: "REQUIRED_FIELD" }),
  postCode: yup
    .string()
    .trim()
    .required({ name: "Post code", code: "REQUIRED_FIELD" }),
  postName: yup
    .string()
    .trim()
    .required({ name: "Post name", code: "REQUIRED_FIELD" }),
  email: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  timezone: yup
    .string()
    .trim()
    .required({ name: "Timezone", code: "REQUIRED_FIELD" }),
});

let schemaThree = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required({ name: "First name", code: "REQUIRED_FIELD" }),
  lastName: yup
    .string()
    .trim()
    .required({ name: "Last name", code: "REQUIRED_FIELD" }),
  phone: yup
    .string()
    .trim()
    .required({ name: "Phone", code: "REQUIRED_FIELD" }),
  phoneCountryNumbers: yup
    .string()
    .trim()
    .required({ name: "Country phone code", code: "REQUIRED_FIELD" }),
  email: yup
    .string()
    .trim()
    .required({ name: "Email", code: "REQUIRED_FIELD" }),
});

const CreateFullPlaceholderCompany: React.FC = () => {
  const { name } = useReactiveVar(modalData);

  const [
    createPlaceholderCompany,
    {
      data,
      loading: placeholderCompanyLoading,
      error: placeholderCompanyError,
    },
  ] = useCreatePlaceholderMutation({ errorPolicy: "all" });
  const { getCoordinates, isLoading: googleApiLoading } = GoogleAPI();

  const { validator } = ValidationsFeHandler();
  const [stepOne, setStepOne] = useState(one);
  const [stepTwo, setStepTwo] = useState(two);
  const [stepThree, setStepThree] = useState(three);
  const [step, setStep] = useState<number>(1);

  const handleSubmitCompany = async (
    e: React.ChangeEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const { taxNumberCountry, taxNumber, ...one } = stepOne;
    validator({
      schema: schemaOne,
      data: one,
      vat: taxNumberCountry + taxNumber,
      success: () => {
        setStep(2);
      },
    });
  };

  const handleSubmitLocation = async (
    e: React.ChangeEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    const companyLocation = {
      type: LocationType.CompanyBillingAddress,
      address: stepTwo.address,
      postCode: stepTwo.postalCode,
      postName: stepTwo.city,
      country_id: stepTwo.country.id,
      email: stepOne.contactEmail,
      timezone: stepTwo.timezone,
    };

    validator({
      schema: schemaTwo,
      data: companyLocation,
      success: () => {
        setStep(3);
      },
    });
  };

  const handleResetData = () => {
    setStepOne(one);
    setStepTwo(two);
    setStepThree(three);
    setStep(1);
    modalData({
      name: "",
      msg: "",
    });
  };

  const handleSubmitUser = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const company = {
      name: stepOne.name,
      contactEmail: stepOne.contactEmail,
      type: stepOne.type,
      taxNumber: stepOne.taxNumberCountry + stepOne.taxNumber,
    };

    const userLocation = {
      type: LocationType.CompanyBillingAddress,
      address: stepTwo.address,
      postCode: stepTwo.postalCode,
      postName: stepTwo.city,
      country_id: stepTwo.country.id,
      email: stepThree.email,
      phone: `(${stepThree.phoneCountryNumbers})${stepThree.phone}`,
      timezone: stepTwo.timezone,
      contactName: `${stepThree.firstName} ${stepThree.lastName}`,
    };

    validator({
      schema: schemaThree,
      data: stepThree,
      success: async () => {
        const location = await getCoordinates(
          stepTwo.address,
          stepTwo.city,
          stepTwo.postalCode,
          stepTwo.country.name
        );
        if (location) {
          createPlaceholderCompany({
            variables: {
              company: {
                ...company,
                country_id: Number(stepTwo.country.id),
                location: {
                  ...userLocation,
                  geoLocation: JSON.stringify(location),
                },
              },
            },
            refetchQueries: [GetLocationsDocument, GetCompaniesDocument],
            awaitRefetchQueries: true,
          });
        }
      },
    });
  };

  ErrorsBeHandler({
    error: placeholderCompanyError,
    dep:
      name === "createPlaceholderReceiverCompany" ||
      name === "createPlaceholderShipperCompany" ||
      name === "createPlaceholderTransporterCompany",
  });

  SuccessBeHandler({
    data: data,
    dep:
      name === "createPlaceholderReceiverCompany" ||
      name === "createPlaceholderShipperCompany" ||
      name === "createPlaceholderTransporterCompany",
    code: "PLACEHOLDER_COMPANY",
    fn: () => {
      setStepOne(one);
      setStepTwo(two);
      setStepThree(three);
      setStep(1);
      if (
        name === "createPlaceholderReceiverCompany" ||
        name === "createPlaceholderShipperCompany" ||
        name === "createPlaceholderTransporterCompany"
      )
        modalData({
          msg: "",
          name: "",
          props: null,
          returned_value: {
            action:
              name === "createPlaceholderShipperCompany"
                ? "HANDLE_SHIPPER_PLACEHOLDER"
                : name === "createPlaceholderReceiverCompany"
                ? "HANDLE_RECEIVER_PLACEHOLDER"
                : "HANDLE_TRANSPORTER_PLACEHOLDER",
            company: {
              name: data?.createPlaceholder.name,
              logoPath: data?.createPlaceholder.logoPath,
              id: data?.createPlaceholder.id,
              type: data?.createPlaceholder.type,
              taxNumber: data?.createPlaceholder.taxNumber,
              status: data?.createPlaceholder.status,
            },
            location: {
              id: data?.createPlaceholder.location?.id,
              status: data?.createPlaceholder.location?.status,
              postName: data?.createPlaceholder.location?.postName,
              postCode: data?.createPlaceholder.location?.postCode,
              address: data?.createPlaceholder.location?.address,
              title: data?.createPlaceholder.location?.title,
              country_id: data?.createPlaceholder.country_id,
              country_shortName: "",
              timezone: data?.createPlaceholder.location?.timezone,
            },
          },
        });
    },
  });

  const isLoading = placeholderCompanyLoading || googleApiLoading;
  const { width, height } = useWindowDimensions();

  return (
    <>
      <StyledModal
        isOpen={
          name === "createPlaceholderReceiverCompany" ||
          name === "createPlaceholderShipperCompany" ||
          name === "createPlaceholderTransporterCompany"
        }
        onClose={() => {
          handleResetData();
        }}
        m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
        rounded="md"
        p="0"
      >
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            {step === 1 && "Add partner company details"}
            {step === 2 && "Add partner location informations"}
            {step === 3 && "Add partner location contact"}
          </Text>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ t: "0.5rem", b: "2rem" }}
          >
            Fill in the company details and it will be added to Cargobite.
          </Text>

          <Row d="column">
            <Col>
              {step === 1 && (
                <CreatePlaceholderCompany
                  setCompany={setStepOne}
                  handleResetData={handleResetData}
                  company={stepOne}
                  handleSubmit={handleSubmitCompany}
                />
              )}
              {step === 2 && (
                <CreatePlaceholderLocation
                  setLocation={setStepTwo}
                  setStep={setStep}
                  location={stepTwo}
                  handleSubmit={handleSubmitLocation}
                />
              )}
              {step === 3 && (
                <CreatePlaceholderUser
                  setUser={setStepThree}
                  companyEmail={stepOne.contactEmail}
                  step={step}
                  isLoading={isLoading}
                  setStep={setStep}
                  user={stepThree}
                  handleSubmit={handleSubmitUser}
                />
              )}
            </Col>
            <Div h="3rem"></Div>
            <Col>
              <ProgressLine allSteps={3} step={step} />
            </Col>
          </Row>
        </Div>
      </StyledModal>
    </>
  );
};

export default CreateFullPlaceholderCompany;
