import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Input, Row, Text, Textarea } from "atomize";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetVehiclesDocument,
  useCreateVehicleMutation,
  useGetDriversLazyQuery,
  useGetMeQuery,
  UserStatus,
  useUpdateVehicleMutation,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { registrationPlateNumberFormat } from "../../../helpers/functions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  registration: yup.string().trim().min(8, {
    name: "Vehicle registration number",
    code: "MIN_CHARACTERS",
    var: "7",
  }),
  maxCargoVolume: yup
    .number()
    .required({ name: "Max cargo volume", code: "REQUIRED_FIELD" })
    .max(15.4, {
      name: "Max cargo volume",
      code: "MAX_CHARACTERS",
      var: "15.40 LDM",
    }),
  maxCargoWeight: yup
    .number()
    .required({ name: "Max cargo weight", code: "REQUIRED_FIELD" })
    .max(26400, {
      name: "Max cargo weight",
      code: "MAX_CHARACTERS",
      var: "26500 KG",
    }),
});

const CreateVehicle: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);

  const [createVehicle, { data, loading, error }] = useCreateVehicleMutation();
  const { data: me } = useGetMeQuery();

  const [
    updateVehicle,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateVehicleMutation();
  const { validator } = ValidationsFeHandler();

  const [vehicle, setVehicle] = useState<any>({
    company_id: "",
    registration: "",
    comment: "",
    maxCargoVolume: "",
    maxCargoWeight: "",
    driverUser_id: null,
  });

  useEffect(() => {
    if (name === "updateVehicle") {
      setVehicle({
        ...vehicle,
        company_id: props.company_id,
        registration: props.registration,
        comment: props.comment,
        maxCargoVolume: props.maxCargoVolume,
        maxCargoWeight: props.maxCargoWeight,
        driverUser_id: props.driverUser_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const [user, setUser] = useState({
    name: "",
    profileImageUrl: "",
  });

  const queryUsers = useGetDriversLazyQuery();
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] =
    useState<boolean>(false);
  const {
    data: users,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
    page: searchUsersPage,
    setPage: setSearchUsersPage,
  } = useSearch(
    queryUsers,
    {
      limit: 3,
      status: [UserStatus.Active],
    },
    300,
    me && isUsersDropdownOpen,
    { id: me?.getUser.company?.id }
  );

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: {
        registration: vehicle.registration,
        maxCargoVolume: Number(vehicle.maxCargoVolume),
        maxCargoWeight: Number(vehicle.maxCargoWeight),
      },
      success: () => {
        name === "createVehicle" &&
          createVehicle({
            variables: {
              vehicle: {
                company_id: props.company_id,
                registration: vehicle.registration,
                description: vehicle.comment,
                maxCargoVolume: Number(vehicle.maxCargoVolume),
                maxCargoWeight: Number(vehicle.maxCargoWeight),
                driverUser_id: vehicle.driverUser_id,
              },
            },
            refetchQueries: [GetVehiclesDocument],
            awaitRefetchQueries: true,
          });

        name === "updateVehicle" &&
          updateVehicle({
            variables: {
              vehicle: {
                id: props.id,
                registration: vehicle.registration,
                description: vehicle.comment,
                maxCargoVolume: Number(vehicle.maxCargoVolume),
                maxCargoWeight: Number(vehicle.maxCargoWeight),
                driverUser_id: vehicle.driverUser_id,
              },
            },
            refetchQueries: [GetVehiclesDocument],
            awaitRefetchQueries: true,
          });
      },
    });
  };

  ErrorsBeHandler({
    error: error || updateError,
    dep: name === "createVehicle" || name === "updateVehicle",
  });

  SuccessBeHandler({
    data: data || updateData,
    code: name === "createVehicle" ? "CREATE_VEHICLE" : "UPDATE_VEHICLE",
    dep: name === "createVehicle" || name === "updateVehicle",
    fn: () => {
      setVehicle({
        company_id: "",
        registration: "",
        comment: "",
        maxCargoVolume: "",
        maxCargoWeight: "",
        driverUser_id: null,
      });
      setUser({
        name: "",
        profileImageUrl: "",
      });
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setVehicle({
      ...vehicle,
      [e.target.name]: e.target.value,
    });
  };

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "createVehicle" || name === "updateVehicle")}
      onClose={() => {
        setVehicle({
          company_id: "",
          registration: "",
          comment: "",
          maxCargoVolume: "",
          maxCargoWeight: "",
          driverUser_id: null,
        });
        setUser({
          name: "",
          profileImageUrl: "",
        });
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && (name === "createVehicle" || name === "updateVehicle") && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            Create Vehicle
          </Text>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "0.5rem" }}
              >
                Vehicle registration number
              </Text>
              <Input
                placeholder="GO 22 666"
                name="registration"
                value={vehicle.registration}
                maxLength={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setVehicle({
                    ...vehicle,
                    registration: registrationPlateNumberFormat(
                      e.target.value.toUpperCase()
                    ),
                  });
                }}
                textSize={"16"}
                type="text"
                h="48px"
                m={{ b: "1rem" }}
              />
            </Col>
          </Row>
          <Row p={{ b: "1rem" }}>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "0rem" }}
              >
                Select Driver
              </Text>
              <InputDropdown
                page={searchUsersPage}
                setPage={setSearchUsersPage}
                totalPages={Math.ceil(users?.getDrivers?.total / 3)}
                showSearchField={true}
                value={searchUsers}
                addNew={false}
                isLocked={false}
                displayResult={
                  <Div d="flex" align="center" zIndex="0">
                    <PreloadingImage
                      imageSrc={user.profileImageUrl}
                      skeletonComponent={
                        <Skeleton
                          height={"24px"}
                          width={"24px"}
                          borderRadius={"24px"}
                        />
                      }
                      imgComponent={
                        user.name === "" ? (
                          <></>
                        ) : (
                          <ShortNameLogo
                            size="24px"
                            name={user.name}
                            textSize={12}
                          />
                        )
                      }
                      imageStyle={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "24px",
                        verticalAlign: "middle",
                      }}
                    />
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      p={{ l: "10px" }}
                    >
                      {user.name === "" ? "Select default driver" : user.name}
                    </Text>
                  </Div>
                }
                isLoading={loadingUsers}
                isOpen={isUsersDropdownOpen}
                setIsOpen={setIsUsersDropdownOpen}
                handleChange={setSearchUsers}
                menuList={users?.getDrivers.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        setVehicle({
                          ...vehicle,
                          driverUser_id: item.id,
                        });
                        setUser({
                          name: `${item.firstName} ${item.lastName}`,
                          profileImageUrl: item.profileImageUrl,
                        });
                        setIsUsersDropdownOpen(false);
                      }}
                    >
                      <PreloadingImage
                        imageSrc={item.profileImageUrl}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={`${item.firstName} ${item.lastName}`}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {" "}
                        {`${item.firstName} ${item.lastName}`}
                      </Text>
                    </Anchor>
                  )
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Max cargo volume(LDM)
              </Text>
              <NumberFormat
                m={{ b: "1rem" }}
                suffix={" LDM"}
                style={{ height: "48px" }}
                placeholder="30 LDM"
                value={vehicle.maxCargoVolume}
                onValueChange={(values) => {
                  setVehicle({
                    ...vehicle,
                    maxCargoVolume: values.value,
                  });
                }}
                customInput={Input}
              />
            </Col>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Max cargo weight(kg)
              </Text>
              <NumberFormat
                m={{ b: "1rem" }}
                style={{ height: "48px" }}
                suffix={" kg"}
                placeholder="2000 kg"
                value={vehicle.maxCargoWeight}
                onValueChange={(values) => {
                  setVehicle({
                    ...vehicle,
                    maxCargoWeight: values.value,
                  });
                }}
                customInput={Input}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Comment{" "}
              </Text>
              <Textarea
                placeholder="Enter any notes you’d like to include in your vehicle..."
                h="4rem"
                m={{ b: "2rem" }}
                value={vehicle.comment}
                name="comment"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loading || updateLoading}
                text={
                  name === "createVehicle" ? "Create vehicle" : "Update vehicle"
                }
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setVehicle({
                    company_id: "",
                    registration: "",
                    comment: "",
                    maxCargoVolume: "",
                    maxCargoWeight: "",
                  });
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loading || updateLoading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default CreateVehicle;
