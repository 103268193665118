/* eslint-disable array-callback-return */
import BIC from "bic-validator";
import IBAN from "iban";
import { checkVAT, countries } from "jsvat";
import { responseMsgs } from "../../App";

const errorsInitials = [
  { code: "REQUIRED_FIELD", msg: "(NAME) field is required." },
  { code: "INVALID_EMAIL", msg: "(NAME) format is invalid." },
  {
    code: "MIN_CHARACTERS",
    msg: "(NAME) field requires at least (VAR) characters.",
  },
  {
    code: "MAX_CHARACTERS",
    msg: "(NAME) field has a limit of (VAR) characters.",
  },
  { code: "TOKEN_MISSING", msg: "(NAME) is missing." },
  { code: "PASSWORD_MATCH", msg: "(NAME) doesn't match." },
  { code: "DATA_NOT_SELECTED", msg: "(NAME) is not selected." },
  { code: "DATE_MIN", msg: "(NAME) should be after (VAR)." },
  { code: "DATE_MAX", msg: "(NAME) should be before (VAR)." },
  { code: "EMPTY_DATA", msg: "Unable to send empty data." },
  { code: "NOT_ZERO", msg: "(NAME) can't be empty." },
  { code: "DATA_NOT_SELECTED_MULTIPLE", msg: "(NAME) are not selected." },
];

const ValidationsFeHandler: () => any = () => {
  const validator: (s: any) => any = ({
    schema,
    data,
    success,
    error,
    context,
    vat,
    iban,
    bic,
  }) => {
    schema
      .validate(data, {
        abortEarly: false,
        context,
      })
      .then(() => {
        const errVat: any = vat ? validatorVat(vat) : [];
        const errIban: any = iban ? validatorIban(iban) : [];
        const errBic: any = bic ? validatorBic(bic) : [];
        const ar: any = [...errVat, ...errIban, ...errBic];
        ar.length === 0 ? success() : responseMsgs([...ar]);
      })
      .catch((err: any) => {
        let errors = err.errors;
        if (!errors) return;
        const res = errorsInitials.map((erIn: any) => {
          let ress = errors?.map((er: any) => {
            if (er.code === erIn.code) {
              return { ...er, ...erIn };
            }
          });
          return ress.filter(Boolean);
        });
        const result = res.filter((e) => e.length);

        let re: any[] = [];
        result.map((r: any) => {
          r.map((p: any) => {
            re.push(p);
          });
        });

        const ress = re.map((r: any) => {
          let msg;
          if (r.hasOwnProperty("name") && r.hasOwnProperty("var")) {
            msg = r.msg.replace("(NAME)", r.name).replace("(VAR)", r.var);
          } else if (r.hasOwnProperty("name")) {
            msg = r.msg.replace("(NAME)", r.name);
          } else if (r.hasOwnProperty("name")) {
            msg = r.msg.replace("(NAME)", r.name);
          } else {
            msg = r.msg;
          }

          return { type: "error", string: msg };
        });
        const errVat: any = vat ? validatorVat(vat) : [];
        const errIban: any = iban ? validatorIban(iban) : [];
        const errBic: any = bic ? validatorBic(bic) : [];
        responseMsgs([...errVat, ...errIban, ...errBic, ...ress]);

        error !== undefined && error();
      });
  };

  const validatorVat = (vat: any) => {
    if (vat) {
      let allErrors: any[] = [];
      const { isValid, isSupportedCountry, isValidFormat } = checkVAT(
        vat,
        countries
      );

      if (!isSupportedCountry) {
        allErrors.push({
          type: "error",
          string: "VAT country code is not supported.",
        });
      }
      if (!isValidFormat) {
        allErrors.push({
          type: "error",
          string: "VAT number format is invalid.",
        });
      }
      if (!isValid && isSupportedCountry && isValidFormat) {
        allErrors.push({ type: "error", string: "VAT number is invalid." });
      }
      return allErrors;
    }
  };

  const validatorIban = (iban: any) => {
    if (iban) {
      if (IBAN.isValid(iban)) {
        return [];
      } else {
        return [{ type: "error", string: "IBAN number is invalid." }];
      }
    }
  };

  const validatorBic = (bic: any) => {
    if (bic) {
      if (BIC.isValid(bic)) {
        return [];
      } else {
        return [{ type: "error", string: "BIC number is invalid." }];
      }
    }
  };

  return { validator };
};

export default ValidationsFeHandler;
