import { Anchor, Col, Div, Icon, Text } from "atomize";
import { Dispatch, FC, SetStateAction } from "react";
import { modalData } from "../../../App";
import InputDropdown from "../../../components/InputDropdown/InputDropdown";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  ActivityLogQuery,
  CompanyStatus,
  GetActivityLogsDocument,
  GetMeQuery,
  GetOrderQuery,
  GetVehiclesDocument,
  GetVehiclesQuery,
  OrderStatus,
  SqlModelStatus,
} from "../../../generated/graphql";
import { registrationPlateNumberFormat } from "../../../helpers/functions";

interface Props {
  isLoading: boolean;
  userRole: number | null;
  searchVehiclesPage: number;
  setSearchVehiclesPage: Dispatch<SetStateAction<number>>;
  vehicles: GetVehiclesQuery | undefined;
  flags: {
    isOwner: boolean;
    isShipper: boolean;
    isReceiver: boolean;
    isTransport: boolean;
  };
  order: GetOrderQuery | undefined;
  me: GetMeQuery | undefined;
  searchVehicles: string;
  setSearchVehicles: Dispatch<SetStateAction<string>>;
  loadingVehicles: boolean;
  deleteVehicleLoading: boolean;
  isVehiclesDropdownOpen: boolean;
  setIsVehiclesDropdownOpen: Dispatch<SetStateAction<boolean>>;
  handleSelectVehicle: (vehicle: any) => void;
  deleteVehicle: (item: any) => void;
  activityPag: ActivityLogQuery;
}

const VehicleDropdownColumn: FC<Props> = ({
  isLoading,
  userRole,
  searchVehiclesPage,
  vehicles,
  flags,
  order,
  me,
  searchVehicles,
  loadingVehicles,
  deleteVehicleLoading,
  isVehiclesDropdownOpen,
  activityPag,
  setSearchVehiclesPage,
  setSearchVehicles,
  setIsVehiclesDropdownOpen,
  handleSelectVehicle,
  deleteVehicle,
}) => {
  return (
    <Col>
      <Text
        textSize={12}
        textColor="semiDark"
        textWeight="500"
        m={{ t: "1rem", b: "4px" }}
      >
        Vehicle
      </Text>
      {isLoading ? (
        <Skeleton width="100%" height="40px" borderRadius="0.5rem" />
      ) : (
        <InputDropdown
          addNew={userRole === 2 || userRole === 1 || userRole === 6}
          page={searchVehiclesPage}
          setPage={setSearchVehiclesPage}
          totalPages={Math.ceil((vehicles?.getVehicles?.total ?? 0) / 5)}
          isLocked={
            !(
              flags.isTransport &&
              [1, 2, 3, 6].includes(me?.getUser?.roles?.[0]?.id as any) &&
              [CompanyStatus.Active].includes(me?.getUser?.company?.status as any)
            )
          }
          modal={{
            msg: "Create Vehicle",
            name: "createVehicle",
            returned_value: null,
            props: {
              company_id: order?.getOrder.transporterCompany_id,
              order_id: order?.getOrder.id,
              searchVehicles,
            },
          }}
          isLoading={loadingVehicles || deleteVehicleLoading}
          displayResult={
            <Div d="flex" h="24px" align="center">
              <Text
                textColor="semiDark"
                hoverTextColor="dark"
                textWeight="500"
                textSize={16}
              >
                {order?.getOrder?.transports![0].vehicle
                  ? order?.getOrder.transports[0].vehicle.registration
                  : "None"}
              </Text>
            </Div>
          }
          isOpen={isVehiclesDropdownOpen}
          setIsOpen={setIsVehiclesDropdownOpen}
          value={searchVehicles}
          handleChange={(v: string) =>
            setSearchVehicles(registrationPlateNumberFormat(v.toUpperCase()))
          }
          menuList={vehicles?.getVehicles.items?.map((item: any) => (
            <Anchor
              d="flex"
              p={{ y: "0.5rem", x: "0.75rem" }}
              key={item?.id}
              align="center"
              justify="space-between"
            >
              <Text
                textColor="semiDark"
                hoverTextColor="dark"
                m={{ l: "10px" }}
                onClick={() => handleSelectVehicle(item)}
              >
                {" "}
                {item.registration}{" "}
              </Text>
              {(userRole === 2 || userRole === 1 || userRole === 6) && (
                <Icon
                  name="Delete"
                  color="danger"
                  hovercolor="brand900"
                  size="20px"
                  onClick={() => {
                    modalData({
                      msg: "",
                      name: "deleteModal",
                      isLoading: deleteVehicleLoading,
                      returned_value: null,
                      props: {
                        subtext: "Are you sure you want to delete vehicle?",
                        handleSubmit: async (e: any) => {
                          e.preventDefault();
                          deleteVehicle({
                            variables: { id: item.id },
                            refetchQueries: [
                              {
                                query: GetVehiclesDocument,
                                variables: {
                                  query: {
                                    search: searchVehicles,
                                    limit: 5,
                                    company_id: me?.getUser.company?.id,
                                    status: [SqlModelStatus.Active],
                                  },
                                },
                              },
                              {
                                query: GetActivityLogsDocument,
                                variables: { query: activityPag },
                              },
                            ],
                            awaitRefetchQueries: true,
                          });
                        },
                      },
                    });
                  }}
                />
              )}
            </Anchor>
          ))}
        />
      )}
    </Col>
  );
};

export default VehicleDropdownColumn;
