import Styled from "styled-components";
import { GlobalTheme } from "../GlobalTheme";

interface ChatProps {
  // color?: string
  // margin?: string
  // direction: string
}

export const Chat = Styled.div<ChatProps>`
    padding: 0;
    max-width: 0px;
    width: 100%;
    height: 100%;
    z-index: 1005;

    transition: 0.3s ease-in-out max-width;
    border-left: 1px solid ${GlobalTheme.colors.greyBorder};
    background:  ${GlobalTheme.colors.grey};
    &.open_chat {
      max-width: 366px;
    }

    &.side_chat {
      position: fixed;
      right: 0;
    }

    .chat_close_wrapper {
      background: linear-gradient(180deg, #F1F2F4 0%, rgba(241, 242, 244, 0) 100%);
    }

    .msgsContainer {
      padding-top: 62px;
      padding-bottom: 1rem;
      overflow-y: auto;
      overflow-X: hidden;
      height: 100%;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
      background:  ${GlobalTheme.colors.grey};
      ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
      }
      /* Optional: show position indicator in red */
      ::-webkit-scrollbar-thumb {
          // background: #FFF;
      }

    }
    
    .msgInputContainer {
      background:  ${GlobalTheme.colors.grey};
      // height: 60px;
      div {
        width: 100%;
        .sent_container {
          position: absolute;
          right: 10px;
          left: auto;
          top: auto;
          bottom: 2px;
          height: auto;
          display: flex;
          justify-items: center;
          align-items: center;
          cursor: pointer;
          width: auto;
          .sent {
            transition: 0.3s color ease-in-out;
            margin-top: 2px;
            color: ${GlobalTheme.colors.semiDark};
            &.sent_loading {
              margin-right: -2px;
              margin-bottom: 4px;
            }
            &:hover {
              color: ${GlobalTheme.colors.dark};
            }
          }
        }
      }
    }
    textarea {
      line-height: 20px;
      padding: 6px 8px;
      height: calc(2rem - 16px);
      width: 100%;
      font-family: ${(props) => props.theme.fontFamily.primary}!important;
    }

    @media only screen and (max-width: 700px) {
      &.open_chat {
        max-width: 100%;
      }
  
      &.side_chat {
        position: fixed;
        top: 0;
        height calc(100% - 38px);
      }
  }
`;
