import React, { useEffect, useState } from "react";
import { Row, Col, Text, Icon, Div, Anchor, Label, Switch, Tag } from "atomize";
import Skeleton from "../../components/Skeleton/Skeleton";
import InputDropdown from "../../components/InputDropdown/InputDropdown";
import {
  useGetLocationsQuery,
  useGetMeQuery,
  useGetCompaniesLazyQuery,
  CompanyStatus,
  LocationStatus,
} from "../../generated/graphql";
import Table from "../../components/TableComponents/Table";
import Th from "../../components/TableComponents/Th";
import Tr from "../../components/TableComponents/Tr";
import Td from "../../components/TableComponents/Td";
import Thead from "../../components/TableComponents/Thead";
import Tbody from "../../components/TableComponents/Tbody";
import LocationRow from "../../components/Tables/LocationRow";
import { initialMyLocationsPagination } from "../../helpers/initialData";
import Pagination from "../../components/Pagination/Pagination";
import { useHistory } from "react-router";
import LocationRowActions from "../../components/Tables/LocationRowActions";
import PreloadingImage from "../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../components/ShortNameLogo/ShortNameLogo";
import { useSearch } from "../../helpers/CustomHooks/useSearch";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import { modalData } from "../../App";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";

interface selectedProps {
  id: null | number;
  logoPath: string;
  name: string;
  status: CompanyStatus;
}

interface selectedProps {
  id: null | number;
  logoPath: string;
  name: string;
  status: CompanyStatus;
}

const SettingsLocations: React.FC = () => {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const { height, width } = useWindowDimensions();

  const [myLocationsPag, setMyLocationsPag] = useState<any>({
    ...initialMyLocationsPagination,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });

  const [selectedCompany, setSelectedCompany] = useState<selectedProps | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [toggle, setToggle] = useState(true);

  const { data: me } = useGetMeQuery({
    onCompleted: (me) => {
      if (
        me?.getUser.company?.status === CompanyStatus.WaitingConfirm ||
        (me?.getUser.roles![0].id !== 2 &&
          me?.getUser.roles![0].id !== 1 &&
          me?.getUser.roles![0].id !== 6)
      ) {
        history.push("/settings/security");
      }
    },
  });

  useEffect(() => {
    if (me) {
      setMyLocationsPag({
        ...myLocationsPag,
        company_id: Number(me.getUser.company?.id),
      });
      setSelectedCompany({
        id: Number(me.getUser.company?.id),
        logoPath: me.getUser.company?.logoPath
          ? me.getUser.company?.logoPath
          : "",
        name: me?.getUser?.company!.name,
        status: me.getUser.company!.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const {
    data,
    loading: locationLoading,
    error: locationError,
    refetch,
  } = useGetLocationsQuery({
    variables: {
      query: {
        ...myLocationsPag,
        status: toggle
          ? [
              LocationStatus.Active,
              LocationStatus.Draft,
              LocationStatus.Placeholder,
            ]
          : [LocationStatus.Deactivated],
      },
    },
    fetchPolicy: "network-only",
    skip: !selectedCompany,
    onCompleted: (data) => {
      if (data?.getLocations?.total)
        setTotalPages(
          Math.ceil(data?.getLocations?.total / myLocationsPag.limit)
        );
      setIsLoading(false);
    },
  });

  ErrorsBeHandler({
    error: locationError,
  });

  const queryCompany = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] =
    useState<boolean>(false);
  const [queryCompanyStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.PlaceholderWaitingConfirm,
    CompanyStatus.Active,
    CompanyStatus.Incomplete,
    CompanyStatus.WaitingConfirm,
  ]);
  const {
    data: companiesData,
    loading: loadingCompanies,
    search: searchCompany,
    setSearch: setSearchCompany,
    page: searchCompanyPage,
    setPage: setSearchCompanyPage,
  } = useSearch(
    queryCompany,
    { limit: 5, status: queryCompanyStatus },
    300,
    queryCompanyStatus
  );

  return (
    <Row m={{ x: "0rem" }}>
      <Col>
        <Div d="flex" justify="space-between" m={{ y: "1rem" }}>
          {me?.getUser.roles![0].id === 1 && (
            <Div w="300px">
              <InputDropdown
                page={searchCompanyPage}
                setPage={setSearchCompanyPage}
                totalPages={Math.ceil(companiesData?.getCompanies?.total / 5)}
                addNew={false}
                modal={{
                  msg: "Create Company",
                  name: "createPlaceholderShipperCompany",
                  returned_value: null,
                  props: null,
                }}
                isLoading={loadingCompanies}
                displayResult={
                  selectedCompany !== null ? (
                    <Div d="flex" h="24px" align="center">
                      <PreloadingImage
                        imageSrc={selectedCompany?.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={selectedCompany?.name}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        textWeight="500"
                        m={{ l: "0.5rem" }}
                      >
                        {selectedCompany.name}
                      </Text>
                      {selectedCompany.status === CompanyStatus.Active && (
                        <Icon
                          m={{ l: "0.5rem" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Div>
                  ) : (
                    <Text
                      textColor="semiDark"
                      hoverTextColor="dark"
                      textWeight="500"
                      m={{ l: "0.5rem" }}
                    >
                      Select company
                    </Text>
                  )
                }
                isOpen={isCompanyDropdownOpen}
                setIsOpen={setIsCompanyDropdownOpen}
                value={searchCompany}
                handleChange={setSearchCompany}
                menuList={companiesData?.getCompanies.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={async (e: any) => {
                        e.preventDefault();
                        setSelectedCompany({
                          id: item.id,
                          logoPath: item.logoPath,
                          name: item.name,
                          status: item.status,
                        });
                        setMyLocationsPag({
                          ...myLocationsPag,
                          company_id: Number(item.id),
                        });
                        setSearchCompany("");
                        setIsCompanyDropdownOpen(false);
                      }}
                    >
                      <PreloadingImage
                        imageSrc={item.logoPath}
                        skeletonComponent={
                          <Skeleton
                            height={"24px"}
                            width={"24px"}
                            borderRadius={"24px"}
                          />
                        }
                        imgComponent={
                          <ShortNameLogo
                            size="24px"
                            name={item.name}
                            textSize={12}
                          />
                        }
                        imageStyle={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "24px",
                          verticalAlign: "middle",
                        }}
                      />
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        m={{ l: "10px" }}
                      >
                        {" "}
                        {item.name}{" "}
                      </Text>
                      {item.status === CompanyStatus.Active && (
                        <Icon
                          m={{ l: "auto" }}
                          name="Checked"
                          size="20px"
                          color="success"
                        />
                      )}
                    </Anchor>
                  )
                )}
              />
              {/* ) */}
            </Div>
          )}
          <Div d="flex" m={{ l: "auto" }} align="center">
            <Label onClick={() => setToggle(!toggle)} align="center">
              {toggle ? "Active" : "Deactivated"}
              {isLoading || locationLoading ? (
                <Skeleton
                  height="28px"
                  borderRadius="2rem"
                  width="42px"
                  margin="0 0  0 0.25rem"
                />
              ) : (
                <Switch
                  checked={toggle}
                  m={{ l: "0.25rem" }}
                  style={{ boxShadow: "none", overflow: "hidden" }}
                />
              )}
            </Label>
          </Div>
          <Div d="flex">
            {isLoading || locationLoading ? (
              <Skeleton height="2rem" width="40px" margin="0 0  0 0.5rem" />
            ) : (
              <SecondaryBtn
                w="40px"
                h="2rem"
                handleSubmit={() => refetch()}
                styleBtn={{ marginLeft: "0.5rem" }}
                isLoading={false}
                text={<Icon name="Refresh" size="16px" color="semiDark" />}
              />
            )}
            {isLoading || locationLoading ? (
              <Skeleton height="2rem" width="150px" margin="0 0 0 0.5rem" />
            ) : (
              <SecondaryBtn
                prefixIcon={
                  <Icon
                    name="Edit"
                    size="16px"
                    color="semiDark"
                    m={{ r: "0.4rem" }}
                  />
                }
                w="150px"
                textSize={12}
                h="2rem"
                handleSubmit={() =>
                  modalData({
                    msg: "Create Location",
                    name: "locationModal",
                    returned_value: null,
                    props: { company_id: selectedCompany?.id },
                  })
                }
                styleBtn={{ marginLeft: "0.5rem" }}
                isLoading={false}
                text={" Create Location"}
              />
            )}
          </Div>
        </Div>
        {(isLoading || locationLoading) === false ? (
          data?.getLocations.items?.length === 0 ? (
            <Tag
              p={{ l: "1rem" }}
              h="48px"
              w="100%"
              d="flex"
              justify="start"
              textColor="light"
            >
              No locations found.
            </Tag>
          ) : (
            <Table width={`calc(100% -  ${selectedCompany?.id ? "0" : "0"})`}>
              <tbody>
                <Tr hidBorderLast={true} className="border_none">
                  <Td width={"100%"} className="divider">
                    <Table>
                      <Thead>
                        <Tr className="head">
                          <Th width={"90px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Type
                            </Text>
                          </Th>
                          <Th width={"140px"}>
                            <Text
                              textColor="dark"
                              m={{ l: "1.25rem" }}
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Status
                            </Text>
                          </Th>
                          <Th width={"120px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Title
                            </Text>
                          </Th>
                          <Th width={"140px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Address
                            </Text>
                          </Th>
                          <Th width={"150px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Post name
                            </Text>
                          </Th>
                          <Th width={"80px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Post Code
                            </Text>
                          </Th>
                          <Th width={"120px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Country
                            </Text>
                          </Th>
                          <Th width={"120px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Timezone
                            </Text>
                          </Th>
                          <Th width={"120px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Contact Person
                            </Text>
                          </Th>
                          <Th width={"140px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Contact Phone
                            </Text>
                          </Th>
                          <Th width={"180px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Contact Email
                            </Text>
                          </Th>
                          <Th width={"200px"}>
                            <Text
                              textColor="dark"
                              textWeight="500"
                              overflow="hidden"
                              d="inline!important"
                            >
                              Description
                            </Text>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody maxH="none">
                        {data?.getLocations?.items &&
                          data?.getLocations?.items!.map(
                            (location: any, i: number) => (
                              <LocationRow location={location} key={i} />
                            )
                          )}
                      </Tbody>
                    </Table>
                  </Td>
                  <Td width="40px" className="divider_right no_p">
                    <Table width="auto">
                      <Thead>
                        <Tr>
                          <Td className="head"></Td>
                        </Tr>
                      </Thead>
                      <Tbody maxH="none">
                        {data?.getLocations.items?.map(
                          (location: any, i: number) => (
                            <LocationRowActions location={location} key={i} />
                          )
                        )}
                      </Tbody>
                    </Table>
                  </Td>
                </Tr>
              </tbody>
            </Table>
          )
        ) : (
          <Skeleton
            classN="table_skeleton"
            height={`${49 * myLocationsPag.limit + 60}px`}
          />
        )}
        <Div>
          {totalPages && (
            <Pagination
              setQuery={setMyLocationsPag}
              query={myLocationsPag}
              total={totalPages}
            />
          )}
        </Div>
      </Col>
    </Row>
  );
};

export default SettingsLocations;
