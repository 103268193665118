import React from "react";
import { Button, Icon } from "atomize";
import { CSSProperties } from "styled-components";

interface SuccessBtnProps {
  handleSubmit?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  text?: string | SVGRectElement;
  w?: string;
  h?: string;
  textSize?: number;
  styleBtn: CSSProperties;
}

const SuccessBtn: React.FC<SuccessBtnProps> = ({
  handleSubmit,
  isLoading,
  text,
  w = "100%",
  h = "48px",
  styleBtn,
}) => {
  return (
    <Button
      style={{ ...styleBtn }}
      h={h}
      w={w}
      bg="success"
      hoverBg="success600"
      p={{ xs: "0.6rem" }}
      textSize={12}
      disabled={isLoading}
      onClick={handleSubmit}
    >
      {isLoading ? (
        <Icon name="Loading" color="background" size="20px" />
      ) : (
        text
      )}
    </Button>
  );
};

export default SuccessBtn;
