import React from "react";
import * as Styled from "./TableElements.styles";

interface TheadProps {}

const Thead: React.FC<TheadProps> = ({ children }) => {
  return <Styled.Thead>{children}</Styled.Thead>;
};

export default Thead;
