import React, { ReactElement } from "react";
import { Button, Icon } from "atomize";
import { CSSProperties } from "styled-components";

interface SecondaryBtnProps {
  handleSubmit: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  text?: string | ReactElement;
  w?: string;
  h?: string;
  textSize?: number;
  styleBtn?: CSSProperties;
  prefixIcon?: ReactElement;
  disabled?: boolean;
}

const SecondaryBtn: React.FC<SecondaryBtnProps> = ({
  textSize = 14,
  styleBtn,
  prefixIcon,
  disabled = false,
  handleSubmit,
  isLoading,
  text,
  w = "100%",
  h = "48px",
}) => {
  return (
    <Button
      bg="background"
      border="1px solid"
      borderColor="greyBorder"
      hoverBg="grey"
      p={{ xs: "0.6rem" }}
      justify="center"
      textColor="semiDark"
      h={h}
      w={w}
      prefix={prefixIcon}
      textSize={textSize}
      focusBorderColor="neutralGrey"
      hoverShadow="2"
      disabled={disabled || isLoading}
      style={{ ...styleBtn }}
      onClick={handleSubmit}
    >
      {isLoading ? (
        <Icon name="Loading" color="semiDark" size="20px" />
      ) : text ? (
        text
      ) : (
        "Confirm"
      )}
    </Button>
  );
};

export default SecondaryBtn;
