import { Anchor, Div, Dropdown, Icon } from "atomize";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { modalData } from "../../../App";
import { GlobalTheme } from "../../../components/GlobalTheme";
import {
  FeedbackType,
  GetLocationQuery,
  GetMeQuery,
  GetOrderQuery,
  LocationPoint,
  OrderStatus,
} from "../../../generated/graphql";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";

interface Props {
  actionDropdown: boolean;
  setActionDropdown: (value: boolean) => void;
  code: string;
  order: GetOrderQuery | undefined;
  locationLoading: GetLocationQuery | undefined;
  locationUnloading: GetLocationQuery | undefined;
  flags: {
    isOwner: boolean;
    isShipper: boolean;
    isReceiver: boolean;
    isTransport: boolean;
  };
  userHasAllowedRole: boolean;
  canEditOrder: boolean;
  isAssigned: boolean;
  me: GetMeQuery | undefined;
  locationPoints: LocationPoint[];
  refetchOrder: any;
}

const ActionDropdownMenu: FC<Props> = ({
  actionDropdown,
  setActionDropdown,
  code,
  order,
  locationLoading,
  locationUnloading,
  flags,
  userHasAllowedRole,
  canEditOrder,
  isAssigned,
  me,
  locationPoints,
  refetchOrder,
}) => {
  const history = useHistory();
  const { name } = useReactiveVar(modalData);

  useEffect(() => {
    if (name === "editItinerary" && locationPoints) {
      modalData({
        msg: "Edit Itinerary",
        name: "editItinerary",
        returned_value: null,
        props: {
          me,
          order: order,
          locationPoints: locationPoints,
          orderCode: code,
          locationLoading: {
            ...locationLoading?.getLocation,
            date: moment(order?.getOrder?.transports![0].requestedLoadingTime)
              .tz(locationLoading?.getLocation.timezone || "")
              .format("DD/MM/YYYY [at] HH:mm [GMT]Z"),
          },
          locationUnloading: {
            ...locationUnloading?.getLocation,
            date: moment(order?.getOrder?.transports![0].requestedUnloadingTime)
              .tz(locationUnloading?.getLocation.timezone || "")
              .format("DD/MM/YYYY [at] HH:mm [GMT]Z"),
          },
          refetchOrder,
        },
      });
    }
  }, [
    code,
    locationLoading?.getLocation,
    locationPoints,
    locationUnloading?.getLocation,
    me,
    name,
    order,
    refetchOrder,
  ]);

  return (
    <Dropdown
      bg="background"
      hoverBg="grey"
      transition
      borderColor="greyBorder"
      hoverShadow="2"
      p={{ xs: "0rem" }}
      textSize={12}
      justify="center"
      textColor="semiDark"
      w="84px"
      h="2rem"
      m={{ l: "0.5rem" }}
      isOpen={actionDropdown}
      onClick={() => setActionDropdown(!actionDropdown)}
      menu={
        <Div
          w="250px"
          shadow={GlobalTheme.shadows[4]}
          style={{
            background: GlobalTheme.colors.background,
            padding: "0 0.75rem",
            borderRadius: GlobalTheme.rounded[4],
          }}
          tabIndex={0}
        >
          <Div d="flex" align="center">
            <Anchor
              textSize={142}
              textColor="semiDark"
              hoverTextColor="dark"
              d="block"
              p={{ y: "0.25rem" }}
              onClick={() => {
                modalData({
                  msg: "Edit Itinerary",
                  name: "editItinerary",
                  returned_value: null,
                  props: {
                    me,
                    order: order,
                    locationPoints: locationPoints,
                    orderCode: code,
                    locationLoading: {
                      ...locationLoading?.getLocation,
                      date: moment(
                        order?.getOrder?.transports![0].requestedLoadingTime
                      )
                        .tz(locationLoading?.getLocation.timezone || "")
                        .format("DD/MM/YYYY [at] HH:mm [GMT]Z"),
                    },
                    locationUnloading: {
                      ...locationUnloading?.getLocation,
                      date: moment(
                        order?.getOrder?.transports![0].requestedUnloadingTime
                      )
                        .tz(locationUnloading?.getLocation.timezone || "")
                        .format("DD/MM/YYYY [at] HH:mm [GMT]Z"),
                    },
                    refetchOrder,
                  },
                });
              }}
            >
              Edit Itinerary
            </Anchor>
          </Div>
          {canEditOrder &&
            order?.getOrder?.status !== OrderStatus.Completed && (
              <>
                <Div d="flex" align="center">
                  <Anchor
                    textSize={142}
                    textColor="semiDark"
                    hoverTextColor="dark"
                    d="block"
                    p={{ y: "0.25rem" }}
                    onClick={() => {
                      history.push(`/createneworder/order/${code}`);
                      setActionDropdown(false);
                    }}
                  >
                    Edit order
                  </Anchor>
                </Div>

                <Div d="flex" align="center">
                  <Anchor
                    textSize={142}
                    textColor="semiDark"
                    hoverTextColor="dark"
                    d="block"
                    p={{ y: "0.25rem" }}
                    onClick={() => {
                      setActionDropdown(false);
                      modalData({
                        msg: "Delete order",
                        name: "deleteOrder",
                        returned_value: null,
                        props: {
                          id: order?.getOrder.id,
                          company_id: order?.getOrder.company_id,
                          order_id: order?.getOrder.id,
                          expirationDate: order?.getOrder.marketExpirationDate,
                          loadingLocation_postName:
                            locationLoading?.getLocation.postName,
                          unloadingLocation_postName:
                            locationUnloading?.getLocation.postName,
                          requestedLoadingTime:
                            order?.getOrder.transports![0].requestedLoadingTime,
                          requestedUnloadingTime:
                            order?.getOrder.transports![0]
                              .requestedUnloadingTime,
                          status: order?.getOrder.status,
                        },
                      });
                    }}
                  >
                    Delete order
                  </Anchor>
                </Div>
                {order?.getOrder.status !== OrderStatus.Flagged && (
                  <Div d="flex" align="center">
                    <Anchor
                      textSize={142}
                      textColor="semiDark"
                      hoverTextColor="dark"
                      d="block"
                      p={{ y: "0.25rem" }}
                      onClick={() => {
                        modalData({
                          msg: "Flag order",
                          name: "flagOrder",
                          returned_value: null,
                          props: {
                            id: order?.getOrder.id,
                          },
                        });
                        setActionDropdown(false);
                      }}
                    >
                      Flagged status
                    </Anchor>
                  </Div>
                )}
              </>
            )}

          {userHasAllowedRole &&
            order?.getOrder?.status !== OrderStatus.Completed && (
              <Div d="flex" align="center">
                <Anchor
                  textSize={142}
                  textColor="semiDark"
                  hoverTextColor="dark"
                  d="block"
                  p={{ y: "0.25rem" }}
                  onClick={() => {
                    history.push(
                      `/createneworder/order/${code}/edit-transport`
                    );
                    setActionDropdown(false);
                  }}
                >
                  Edit transport details
                </Anchor>
              </Div>
            )}

          <Div d="flex" align="center">
            <Anchor
              textSize={142}
              textColor="semiDark"
              hoverTextColor="dark"
              d="block"
              p={{ y: "0.25rem" }}
              onClick={() => {
                if (order?.getOrder?.transports) {
                  modalData({
                    msg: `Report`,
                    name: "report",
                    returned_value: null,
                    props: {
                      type: FeedbackType.ReportOrder,
                      order_id: order.getOrder.id,
                      bid_id: null,
                      subtext: "Please tell us why are you reporting this.",
                    },
                  });

                  setActionDropdown(false);
                }
              }}
            >
              Report order
            </Anchor>
          </Div>

          {!flags.isOwner &&
            !flags.isReceiver &&
            !flags.isShipper &&
            !flags.isTransport &&
            order?.getOrder.status === OrderStatus.OnMarket && (
              <Div d="flex" align="center">
                <Anchor
                  textSize={142}
                  textColor="semiDark"
                  hoverTextColor="dark"
                  d="block"
                  p={{ y: "0.25rem" }}
                  onClick={() => {
                    if (order?.getOrder?.transports) {
                      modalData({
                        msg: "Make a bid",
                        name: "makeBid",
                        returned_value: null,
                        props: {
                          id: order.getOrder.id,
                          loadingLocation_postName:
                            locationLoading?.getLocation.postName,
                          expirationDate: order.getOrder.marketExpirationDate,
                          unloadingLocation_postName:
                            locationUnloading?.getLocation.postName,
                          requestedLoadingTime:
                            order?.getOrder?.transports[0].requestedLoadingTime,
                          requestedUnloadingTime:
                            order?.getOrder?.transports[0]
                              .requestedUnloadingTime,
                        },
                      });

                      setActionDropdown(false);
                    }
                  }}
                >
                  Make a bid
                </Anchor>
              </Div>
            )}

          <Div d="flex" align="center">
            <Anchor
              textSize={142}
              textColor="light"
              cursor="not-allowed"
              hoverTextColor="light"
              d="block"
              p={{ y: "0.25rem" }}
            >
              Export order
            </Anchor>
          </Div>
          {isAssigned && (
            <Div d="flex" align="center">
              <Anchor
                textSize={142}
                textColor="semiDark"
                hoverTextColor="dark"
                d="block"
                p={{ y: "0.25rem" }}
                onClick={() => {
                  history.push(`/createdocument/${code}`);
                }}
              >
                Add document
              </Anchor>
            </Div>
          )}

          {isAssigned &&
            !flags.isOwner &&
            (order?.getOrder?.status === OrderStatus.Ready ||
              order?.getOrder?.status === OrderStatus.Pending) && (
              <Div d="flex" align="center">
                <Anchor
                  textSize={14}
                  textColor="semiDark"
                  hoverTextColor="dark"
                  d="block"
                  p={{ y: "0.25rem" }}
                  onClick={() => {
                    order?.getOrder?.transports &&
                      modalData({
                        msg: "Change pickup date",
                        name: "pickupDate",
                        returned_value: null,
                        subtext: `The previous date was already agreed upon by all parties. To change this date you will require approval from `,
                        props: {
                          order_id: order?.getOrder.id,
                          requestedLoadingTime:
                            order?.getOrder?.transports[0]
                              .requestedLoadingTime!,
                          requestedUnloadingTime:
                            order?.getOrder?.transports[0]
                              .requestedUnloadingTime!,
                          loadingTimezone:
                            locationLoading?.getLocation.timezone,
                          unloadingTimezone:
                            locationUnloading?.getLocation.timezone,
                          localTimezone:
                            me?.getUser?.company?.location?.timezone,
                          ownerCompany: order?.getOrder?.ownerCompany?.name,
                          transport_id: order.getOrder.transports[0].id,
                        },
                      });
                  }}
                >
                  Change pickup date
                </Anchor>
              </Div>
            )}
          {flags.isOwner && (
            <Div d="flex" align="center">
              <Anchor
                textSize={142}
                textColor="semiDark"
                hoverTextColor="dark"
                d="block"
                p={{ y: "0.25rem" }}
                onClick={() => {
                  order?.getOrder.status === OrderStatus.OnMarket ||
                  order?.getOrder.status === OrderStatus.Draft
                    ? history.push("/createneworder/order", {
                        type: order?.getOrder.transporterCompany_id
                          ? "EXISTING"
                          : "BIDS",
                        code: order?.getOrder.code,
                      })
                    : modalData({
                        msg: "Select order type",
                        name: "orderType",
                        returned_value: null,
                        subtext: "",
                        props: {
                          code: order?.getOrder.code,
                        },
                      });

                  setActionDropdown(false);
                }}
              >
                {"Copy order"}
              </Anchor>
            </Div>
          )}
        </Div>
      }
      direction="lefttop"
      closeSuffix={
        <Icon
          name="OptionsVertical"
          size="16px"
          color="semiDark"
          position="absolute"
          m={{ r: "0.5rem" }}
          order="-1"
        />
      }
      openSuffix={
        <Icon
          name="OptionsVertical"
          size="16px"
          color="semiDark"
          m={{ r: "0.5rem" }}
          order="-1"
        />
      }
    >
      Actions
    </Dropdown>
  );
};

export default ActionDropdownMenu;
