import styled from "styled-components";

interface PropsTable {
  margin?: string;
  width?: string;
  minWidth?: string;
}

export const Table = styled.table<PropsTable>`
  border-collapse: collapse;
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

interface TrProps {
  hidBorderLast?: boolean;
}

export const Tr = styled.tr<TrProps>`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: table;
  table-layout: fixed;
  width: 100%;
  &.link {
    td {
      cursor: pointer !important;
    }
    &:hover {
      background: ${(props) => props.theme.colors.grey};
      transition: all ease-in-out 0.3s;
    }
  }
  & .row-group > div > .row-edit {
    cursor: pointer;
    pointer-events: none;
    touch-action: none;
    user-select: none;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
  }
  & .row-group:hover > div > .row-edit {
    pointer-events: all;
    touch-action: auto;
    opacity: 1;
  }

  ${(props) =>
    props.hidBorderLast === true &&
    `
        .tr:last-child {
            border-bottom: none;
        }`}

  &.head {
    background: ${(props) => props.theme.colors.grey};
    color: ${(props) => props.theme.colors.dark};
    text-align: left;
    overflow: hidden;
  }

  &.border_none {
    border-bottom: none;
  }

  &.order-outerrow {
    position: relative;
    width: 100%;
    height: 49px;
    border: none;
  }
  
  &.order-row {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    &:not(:active) {
      transform: none !important;
    }
    &:active > tr {
      border: none;
    }
  }
`;

interface ThProps {
  width: string;
}

export const Th = styled.th<ThProps>`
  padding: 0 13px;
  height: 48px;
  min-width: ${(props) => props.width};
  white-space: nowrap;
  .sort_icon {
    display: inline-block;
    margin-left: 5px;
  }
  p {
    display: inline-block;
  }
`;

interface TdProps {
  width: string;
  d?: string;
  isActionTd: boolean;
}

export const Td = styled.td<TdProps>`
  min-width: ${(props) => props.width};
  padding: 0 13px;
  height: 48px;
  // width: 100%
  ${(props) => props.d && "display: inline;"}
  // vertical-align: middle;
    white-space: nowrap;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .dropdwon_input {
    background: transparent;
  }

  .i_b {
    display: inline-block;
    line-height: 32px;
  }

  .f_l {
    float: left;
  }

  .f_r {
    float: right;
  }

  &.divider_right {
    padding: 0 !important;
    vertical-align: top;
    .td_wrapper {
      background: ${(props) => props.theme.colors.background};
    }
  }

  &.no_p {
    padding: ${(props) => (props.isActionTd ? "0 0.5rem" : "0")};
  }

  &.head {
    background: ${(props) => props.theme.colors.grey};
  }

  &.documents {
    .td_wrapper {
      overflow: visible;
    }
  }

  &.divider {
    padding: 0 !important;
    .td_wrapper {
      vertical-align: top;
      overflow: auto;
      scrollbar-width: thin;

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.neutralGrey};
        height: 5px;
        border-radius: ${(props) => props.theme.rounded[2]};
        border-left: 3px solid ${(props) => props.theme.colors.grey};
        border-right: 3px solid ${(props) => props.theme.colors.grey};
        border-top: 3px solid ${(props) => props.theme.colors.grey};
        border-bottom: 3px solid ${(props) => props.theme.colors.grey};
      }

      ::-webkit-scrollbar {
        height: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        width: 100%;
        height: 10px;
        background: ${(props) => props.theme.colors.grey};
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.semiLight};
        border-left: 3px solid ${(props) => props.theme.colors.grey};
        border-right: 3px solid ${(props) => props.theme.colors.grey};
        border-top: 3px solid ${(props) => props.theme.colors.grey};
        border-bottom: 3px solid ${(props) => props.theme.colors.grey};
      }
    }
  }
`;
interface TbodyProps {
  maxH: string;
}
export const Tbody = styled.tbody<TbodyProps>`
  display: block;
  transition: all 0.5s;
  max-height: ${(props) => (props.maxH ? props.maxH : "none")};
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Thead = styled.thead`
  display: block;
`;
