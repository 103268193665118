import { Col, Text } from "atomize";
import { Maybe } from "graphql/jsutils/Maybe";
import { FC } from "react";
import Skeleton from "../../../components/Skeleton/Skeleton";

interface Props {
  code: Maybe<string> | undefined;
  isLoading: boolean;
}

const OrderNumber: FC<Props> = ({ isLoading, code }) => {
  return (
    <Col size="auto" minW="0px" w="140px">
      <Text
        textSize={12}
        textColor="semiDark"
        textWeight="500"
        m={{ b: "0.5rem" }}
      >
        Order number
      </Text>
      {!isLoading ? (
        <Text textSize={20} textColor="dark" textWeight="400">
          {code}
        </Text>
      ) : (
        <Skeleton width="140px" height="32px" />
      )}
    </Col>
  );
};

export default OrderNumber;
