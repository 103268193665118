import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Collapse, Div, Icon, Input, Row, Text } from "atomize";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  marketQueryLink,
  marketQueryPag,
  modalData,
  responseMsgs,
} from "../../../App";
import {
  CompanyStatus,
  Country,
  useGetCompaniesLazyQuery,
  useGetCompanyLazyQuery,
  useGetCountriesLazyQuery,
  useGetCountryLazyQuery,
  useGetMeQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import {
  initialMarketQueryLink,
  initialMarketQueryPagination,
} from "../../../helpers/initialData";
import Border from "../../Border/Border";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";

const FilterMarketTable: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const marketQueryPagVar = useReactiveVar(marketQueryPag);
  const marketQueryLinkVar = useReactiveVar(marketQueryLink);

  const { height, width } = useWindowDimensions();

  const [company, setCompany] = useState({
    name: "",
    logoPath: "",
  });

  const [collapse, setCollapse] = useState({
    date: false,
    users: false,
    companies: false,
    loadingLocation: false,
    unloadingLocation: false,
    other: false,
  });
  const [marketState, setMarketState] = useState(
    name === "filterMarketTableLink"
      ? initialMarketQueryLink
      : initialMarketQueryPagination
  );
  const { data: me } = useGetMeQuery();

  const queryCompanies = useGetCompaniesLazyQuery();
  const [isCompaniesDropdownOpen, setIsCompaniesDropdownOpen] =
    useState<boolean>(false);
  const {
    data: companies,
    loading: loadingCompanies,
    setSearch: setCompaniesInput,
    search: companiesInput,
    page: companiesInputPage,
    setPage: setCompaniesInputPage,
  } = useSearch(
    queryCompanies,
    {
      limit: 3,
      status: [
        CompanyStatus.Placeholder,
        CompanyStatus.Active,
        CompanyStatus.PlaceholderWaitingConfirm,
      ],
    },
    300,
    me && isCompaniesDropdownOpen
  );

  const [getCompany, { data: savedCompany, loading: savedLoading }] =
    useGetCompanyLazyQuery({ fetchPolicy: "cache-and-network" });
  const [
    getLoadingCountry,
    { data: savedLoadingCountry, loading: savedLoadingCountryLoading },
  ] = useGetCountryLazyQuery({ fetchPolicy: "cache-and-network" });
  const [
    getUnloadingCountry,
    { data: savedUnloadingCountry, loading: savedUnloadingCountryLoading },
  ] = useGetCountryLazyQuery({ fetchPolicy: "cache-and-network" });

  const [inputs, setInputs] = useState<any>(
    name === "filterMarketTableLink"
      ? {
          loading_from_time: marketQueryLinkVar?.loadingTime_from
            ? moment(marketQueryLinkVar?.loadingTime_from).format("HH:mm")
            : null,
          loading_from_date: marketQueryLinkVar?.unloadingTime_from
            ? moment(marketQueryLinkVar?.loadingTime_from).format("YYYY-MM-DD")
            : null,
          loading_to_time: marketQueryLinkVar?.loadingTime_to
            ? moment(marketQueryLinkVar?.loadingTime_to).format("HH:mm")
            : null,
          loading_to_date: marketQueryLinkVar?.loadingTime_to
            ? moment(marketQueryLinkVar?.loadingTime_to).format("YYYY-MM-DD")
            : null,
          unloading_from_time: marketQueryLinkVar?.unloadingTime_from
            ? moment(marketQueryLinkVar?.unloadingTime_from).format("HH:mm")
            : null,
          unloading_from_date: marketQueryLinkVar?.unloadingTime_from
            ? moment(marketQueryLinkVar?.unloadingTime_from).format(
                "YYYY-MM-DD"
              )
            : null,
          unloading_to_time: marketQueryLinkVar?.unloadingTime_to
            ? moment(marketQueryLinkVar?.unloadingTime_to).format("HH:mm")
            : null,
          unloading_to_date: marketQueryLinkVar?.unloadingTime_to
            ? moment(marketQueryLinkVar?.unloadingTime_to).format("YYYY-MM-DD")
            : null,
          loadingPostCode: "",
          loadingPostName: "",
          loadingCountry_id: "",
          loadingCountryName: "",
          unloadingPostCode: "",
          unloadingPostName: "",
          unloadingCountry_id: "",
          unloadingCountryName: "",
        }
      : {
          loading_from_time: marketQueryPagVar?.loadingTime_from
            ? moment(marketQueryPagVar?.loadingTime_from).format("HH:mm")
            : null,
          loading_from_date: marketQueryPagVar?.unloadingTime_from
            ? moment(marketQueryPagVar?.loadingTime_from).format("YYYY-MM-DD")
            : null,
          loading_to_time: marketQueryPagVar?.loadingTime_to
            ? moment(marketQueryPagVar?.loadingTime_to).format("HH:mm")
            : null,
          loading_to_date: marketQueryPagVar?.loadingTime_to
            ? moment(marketQueryPagVar?.loadingTime_to).format("YYYY-MM-DD")
            : null,
          unloading_from_time: marketQueryPagVar?.unloadingTime_from
            ? moment(marketQueryPagVar?.unloadingTime_from).format("HH:mm")
            : null,
          unloading_from_date: marketQueryPagVar?.unloadingTime_from
            ? moment(marketQueryPagVar?.unloadingTime_from).format("YYYY-MM-DD")
            : null,
          unloading_to_time: marketQueryPagVar?.unloadingTime_to
            ? moment(marketQueryPagVar?.unloadingTime_to).format("HH:mm")
            : null,
          unloading_to_date: marketQueryPagVar?.unloadingTime_to
            ? moment(marketQueryPagVar?.unloadingTime_to).format("YYYY-MM-DD")
            : null,
          loadingPostCode: "",
          loadingPostName: "",
          loadingCountry_id: "",
          loadingCountryName: "",
          unloadingPostCode: "",
          unloadingPostName: "",
          unloadingCountry_id: "",
          unloadingCountryName: "",
        }
  );
  const queryLoadingCountries = useGetCountriesLazyQuery();
  const [isLoadingLocationDropdownOpen, setIsLoadingLocationDropdownOpen] =
    useState(false);
  const {
    data: loadingCountries,
    loading: loadingLoadingCountries,
    search: countryLoadingInput,
    setSearch: setLoadingCountryInput,
  } = useSearch(
    queryLoadingCountries,
    { limit: 3 },
    300,
    me && isLoadingLocationDropdownOpen
  );

  const queryUnloadingCountries = useGetCountriesLazyQuery();
  const [isUnloadingLocationDropdownOpen, setIsUnloadingLocationDropdownOpen] =
    useState(false);
  const {
    data: unloadingCountries,
    loading: loadingUnloadingCountries,
    search: countryUnloadingInput,
    setSearch: setUnloadingCountryInput,
  } = useSearch(
    queryUnloadingCountries,
    { limit: 3 },
    300,
    me && isUnloadingLocationDropdownOpen
  );

  useEffect(() => {
    if (name === "filterMarketTableLink" || name === "filterMarketTablePag") {
      setCollapse({
        date: false,
        users: false,
        companies: false,
        unloadingLocation: false,
        loadingLocation: false,
        other: false,
      });
      setCompany({
        name: "",
        logoPath: "",
      });

      const market =
        name === "filterMarketTableLink"
          ? marketQueryLinkVar
          : marketQueryPagVar;
      if (market.hasOwnProperty("search")) {
        // const marketPag
        const { search, ...marketRest } = market;
        setMarketState(marketRest);
      } else {
        setMarketState(market);
      }
      let storageQuery: any = localStorage.getItem(name);
      if (storageQuery) {
        storageQuery = JSON.parse(storageQuery);

        if (storageQuery?.company_id) {
          getCompany({ variables: { id: storageQuery?.company_id } });
        }
        if (storageQuery?.loadingCountry_id) {
          setInputs({
            ...inputs,
            loadingCountry_id: storageQuery?.loadingCountry_id,
          });
          getLoadingCountry({
            variables: { id: storageQuery?.loadingCountry_id },
          });
        }
        if (storageQuery?.unloadingCountry_id) {
          setInputs({
            ...inputs,
            unloadingCountry_id: storageQuery?.unloadingCountry_id,
          });
          getUnloadingCountry({
            variables: { id: storageQuery?.unloadingCountry_id },
          });
        }
        setInputs({
          ...inputs,
          loading_from_time: storageQuery?.loadingTime_from
            ? moment(storageQuery?.loadingTime_from).format("HH:mm")
            : null,
          loading_from_date: storageQuery?.loadingTime_from
            ? moment(storageQuery?.loadingTime_from).format("YYYY-MM-DD")
            : null,
          loading_to_time: storageQuery?.loadingTime_to
            ? moment(storageQuery?.loadingTime_to).format("HH:mm")
            : null,
          loading_to_date: storageQuery?.loadingTime_to
            ? moment(storageQuery?.loadingTime_to).format("YYYY-MM-DD")
            : null,
          unloading_from_time: storageQuery?.unloadingTime_from
            ? moment(storageQuery?.unloadingTime_from).format("HH:mm")
            : null,
          unloading_from_date: storageQuery?.unloadingTime_from
            ? moment(storageQuery?.unloadingTime_from).format("YYYY-MM-DD")
            : null,
          unloading_to_time: storageQuery?.unloadingTime_to
            ? moment(storageQuery?.unloadingTime_to).format("HH:mm")
            : null,
          unloading_to_date: storageQuery?.unloadingTime_to
            ? moment(storageQuery?.unloadingTime_to).format("YYYY-MM-DD")
            : null,
          loadingPostCode: storageQuery.loadingPostCode,
          loadingPostName: storageQuery.loadingPostName,
          loadingCountry_id: storageQuery.loadingCountry_id,
          unloadingPostCode: storageQuery.unloadingPostCode,
          unloadingPostName: storageQuery.unloadingPostName,
          unloadingCountry_id: storageQuery.unloadingCountry_id,
        });
      } else {
        setInputs({
          ...inputs,
          loading_from_time: null,
          loading_from_date: null,
          loading_to_time: null,
          loading_to_date: null,
          unloading_from_time: null,
          unloading_from_date: null,
          unloading_to_time: null,
          unloading_to_date: null,
          loadingPostCode: "",
          loadingPostName: "",
          loadingCountry_id: "",
          loadingCountryName: "",
          unloadingPostCode: "",
          unloadingPostName: "",
          unloadingCountry_id: "",
          unloadingCountryName: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (
      savedCompany &&
      (name === "filterMarketTableLink" || name === "filterMarketTablePag")
    ) {
      setCompany({
        name: savedCompany.getCompany.name,
        logoPath: savedCompany.getCompany.logoPath
          ? savedCompany.getCompany.logoPath
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedLoading]);

  useEffect(() => {
    if (
      savedLoadingCountry &&
      (name === "filterMarketTableLink" || name === "filterMarketTablePag")
    ) {
      setInputs({
        ...inputs,
        loadingCountryName: savedLoadingCountry.getCountry.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedLoadingCountryLoading]);

  useEffect(() => {
    if (
      savedUnloadingCountry &&
      (name === "filterMarketTableLink" || name === "filterMarketTablePag")
    ) {
      setInputs({
        ...inputs,
        unloadingCountryName: savedUnloadingCountry.getCountry.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUnloadingCountryLoading]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    let errors: any = [];

    if (inputs.loading_from_date !== null) {
      moment(
        inputs.loading_from_date + " " + inputs.loading_from_time
      ).format() === "Invalid date" &&
        errors.push({ type: "error", string: "Loading from is invalid date" });
    }
    if (inputs.loading_to_date !== null) {
      moment(inputs.loading_to_date + " " + inputs.loading_to_time).format() ===
        "Invalid date" &&
        errors.push({ type: "error", string: "Loading to is invalid date" });
    }
    if (inputs.unloading_from_date !== null) {
      moment(
        inputs.unloading_from_date + " " + inputs.unloading_from_time
      ).format() === "Invalid date" &&
        errors.push({
          type: "error",
          string: "Unloading from is invalid date",
        });
    }
    if (inputs.unloading_to_date !== null) {
      moment(
        inputs.unloading_to_date + " " + inputs.unloading_to_time
      ).format() === "Invalid date" &&
        errors.push({ type: "error", string: "Unloading to is invalid date" });
    }

    if (errors.length === 0) {
      const datas = {
        ...marketState,
        page: 1,
        loadingTime_from: inputs.loading_from_date
          ? moment(
              inputs.loading_from_date + " " + inputs.loading_from_time
            ).format()
          : null,
        loadingTime_to: inputs.loading_to_date
          ? moment(
              inputs.loading_to_date + " " + inputs.loading_to_time
            ).format()
          : null,
        unloadingTime_from: inputs.unloading_from_date
          ? moment(
              inputs.unloading_from_date + " " + inputs.unloading_from_time
            ).format()
          : null,
        unloadingTime_to: inputs.unloading_to_date
          ? moment(
              inputs.unloading_to_date + " " + inputs.unloading_to_time
            ).format()
          : null,
        loadingPostCode:
          inputs.loadingPostCode === "" ? null : inputs.loadingPostCode,
        loadingPostName:
          inputs.loadingPostName === "" ? null : inputs.loadingPostName,
        loadingCountry_id:
          inputs.loadingCountry_id === "" ? null : inputs.loadingCountry_id,
        unloadingPostCode:
          inputs.unloadingPostCode === "" ? null : inputs.unloadingPostCode,
        unloadingPostName:
          inputs.unloadingPostName === "" ? null : inputs.unloadingPostName,
        unloadingCountry_id:
          inputs.unloadingCountry_id === "" ? null : inputs.unloadingCountry_id,
      };
      props.setSearchInput("");
      name === "filterMarketTableLink"
        ? marketQueryLink({
            ...datas,
            limit: 5,
          })
        : marketQueryPag({
            ...datas,
            limit: Math.floor(
              (height - (width > 700 ? 200 : width > 470 ? 250 : 300)) / 49
            ),
          });
      localStorage.setItem(
        name,
        JSON.stringify({ ...datas, desc: true, orderBy: "id" })
      );

      modalData({
        name: "",
        msg: "",
        returned_value: null,
        marketPag: null,
      });
    } else {
      responseMsgs(errors);
    }
  };

  return (
    <StyledModal
      isOpen={
        !!(name === "filterMarketTableLink" || name === "filterMarketTablePag")
      }
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          marketPag: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {(name === "filterMarketTableLink" ||
        name === "filterMarketTablePag") && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row d="column">
            <Col>
              <Text
                textSize="20"
                textAlign="left"
                textWeight="700"
                textColor="dark"
                m={{ x: "0rem", b: "0.5rem" }}
              >
                Filter orders on the market
              </Text>
            </Col>
            <Col>
              <Text
                textSize="16"
                textAlign="left"
                textWeight="400"
                textColor="semiDark"
                m={{ x: "0rem", b: "0rem" }}
              >
                Display only orders based on your conditions.
              </Text>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        date: !collapse.date,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.date ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Date range{" "}
                    </Text>
                    {!collapse.date ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.date ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.date ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border></Border>
                  <Collapse isOpen={collapse.date}>
                    <Row>
                      <Col>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Loading from
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="loading_from_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.loading_from_time === null) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        loading_from_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                        loading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_from_time}
                                  type={
                                    inputs.loading_from_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="loading_from_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.loading_from_date === null) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        loading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_from_date}
                                  type={
                                    inputs.loading_from_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Loading to
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="loading_to_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.loading_to_time === null) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        loading_to_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                        loading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_to_time}
                                  type={
                                    inputs.loading_to_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="loading_to_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.loading_to_date === null) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        loading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_to_date}
                                  type={
                                    inputs.loading_to_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Unloading from
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="unloading_from_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.unloading_from_time === null) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        unloading_from_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                        unloading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_from_time}
                                  type={
                                    inputs.unloading_from_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="unloading_from_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.unloading_from_date === null) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        unloading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_from_date}
                                  type={
                                    inputs.unloading_from_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Unloading to
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="unloading_to_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.unloading_to_time === null) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        unloading_to_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                        unloading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_to_time}
                                  type={
                                    inputs.unloading_to_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="unloading_to_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (inputs.unloading_to_date === null) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        unloading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_to_date}
                                  type={
                                    inputs.unloading_to_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>

            <Col m={{ t: "0rem" }}>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        companies: !collapse.companies,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.companies ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Company
                    </Text>
                    {!collapse.companies ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.companies ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.companies ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.companies}
                    overflow={isCompaniesDropdownOpen ? "visible" : "hidden"}
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        <InputDropdown
                          page={companiesInputPage}
                          setPage={setCompaniesInputPage}
                          totalPages={Math.ceil(
                            companies?.getCompanies?.total / 3
                          )}
                          showSearchField={true}
                          value={companiesInput}
                          addNew={false}
                          isLocked={false}
                          displayResult={
                            <Div d="flex" align="center">
                              <PreloadingImage
                                imageSrc={company.logoPath}
                                skeletonComponent={
                                  <Skeleton
                                    height={"24px"}
                                    width={"24px"}
                                    borderRadius={"24px"}
                                  />
                                }
                                imgComponent={
                                  company.name === "" ? (
                                    <></>
                                  ) : (
                                    <ShortNameLogo
                                      size="24px"
                                      name={company.name}
                                      textSize={12}
                                    />
                                  )
                                }
                                imageStyle={{
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "24px",
                                  verticalAlign: "middle",
                                }}
                              />
                              <Text
                                textColor="semiDark"
                                hoverTextColor="dark"
                                p={{ l: "10px" }}
                              >
                                {company.name === ""
                                  ? "Select company"
                                  : company.name}
                              </Text>
                            </Div>
                          }
                          isLoading={loadingCompanies}
                          isOpen={isCompaniesDropdownOpen}
                          setIsOpen={setIsCompaniesDropdownOpen}
                          handleChange={setCompaniesInput}
                          menuList={companies?.getCompanies.items?.map(
                            (item: any, i: number) => (
                              <Anchor
                                d="flex"
                                p={{ y: "0.5rem", x: "0.75rem" }}
                                key={i}
                                align="center"
                                onClick={() => {
                                  setMarketState({
                                    ...marketState,
                                    company_id: item.id,
                                  });
                                  setCompany({
                                    name: `${item.name}`,
                                    logoPath: item.logoPath,
                                  });
                                  setIsCompaniesDropdownOpen(false);
                                }}
                              >
                                <PreloadingImage
                                  imageSrc={item.logoPath}
                                  skeletonComponent={
                                    <Skeleton
                                      height={"24px"}
                                      width={"24px"}
                                      borderRadius={"24px"}
                                    />
                                  }
                                  imgComponent={
                                    <ShortNameLogo
                                      size="24px"
                                      name={`${item.name}`}
                                      textSize={12}
                                    />
                                  }
                                  imageStyle={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "24px",
                                    verticalAlign: "middle",
                                  }}
                                />
                                <Text
                                  textColor="semiDark"
                                  hoverTextColor="dark"
                                  p={{ l: "10px" }}
                                >
                                  {" "}
                                  {`${item.name}`}
                                </Text>
                              </Anchor>
                            )
                          )}
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        loadingLocation: !collapse.loadingLocation,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.loadingLocation ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Loading Location
                    </Text>
                    {!collapse.loadingLocation ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.loadingLocation ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.loadingLocation ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.loadingLocation}
                    overflow={
                      isLoadingLocationDropdownOpen ? "visible" : "hidden"
                    }
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        {
                          <InputDropdown
                            addNew={false}
                            displayResult={
                              <Div d="flex" h="24px" align="center">
                                <Text
                                  textColor={"dark"}
                                  textWeight="500"
                                  m={{ r: "auto", l: "0.5rem" }}
                                  textSize={14}
                                >
                                  {inputs.loadingCountryName
                                    ? inputs.loadingCountryName
                                    : `Select country name`}
                                </Text>
                              </Div>
                            }
                            isLoading={loadingLoadingCountries}
                            isOpen={isLoadingLocationDropdownOpen}
                            setIsOpen={setIsLoadingLocationDropdownOpen}
                            value={countryLoadingInput}
                            handleChange={setLoadingCountryInput}
                            menuList={
                              <>
                                {loadingCountries &&
                                  loadingCountries?.getCountries?.items?.map(
                                    (country: Country, i: number) => (
                                      <Anchor
                                        d="flex"
                                        p={{ y: "0.5rem", x: "0.75rem" }}
                                        key={i}
                                        align="center"
                                        onClick={() => {
                                          setInputs({
                                            ...inputs,
                                            loadingCountryName: country.name,
                                            loadingCountry_id: country.id,
                                          });
                                          setLoadingCountryInput("");
                                          setIsLoadingLocationDropdownOpen(
                                            false
                                          );
                                        }}
                                      >
                                        <Text
                                          textColor="semiDark"
                                          hoverTextColor="dark"
                                        >
                                          {" "}
                                          {`${country?.name}`}
                                        </Text>
                                      </Anchor>
                                    )
                                  )}
                              </>
                            }
                          />
                        }
                      </Col>
                    </Row>
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col size="4">
                        {
                          <Input
                            placeholder="Post code"
                            h="2.5rem"
                            name="loadingPostCode"
                            value={inputs.loadingPostCode}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>

                      <Col size="8">
                        {
                          <Input
                            placeholder="Post name"
                            h="2.5rem"
                            name="loadingPostName"
                            value={inputs.loadingPostName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        unloadingLocation: !collapse.unloadingLocation,
                      })
                    }
                  >
                    <Text
                      textColor={
                        collapse.unloadingLocation ? "dark" : "semiDark"
                      }
                      textSize={14}
                      textWeight={500}
                    >
                      Unloading Location
                    </Text>
                    {!collapse.unloadingLocation ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.unloadingLocation ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.unloadingLocation ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    m={{ b: "2rem" }}
                    isOpen={collapse.unloadingLocation}
                    overflow={
                      isUnloadingLocationDropdownOpen ? "visible" : "hidden"
                    }
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        {
                          <InputDropdown
                            addNew={false}
                            displayResult={
                              <Div d="flex" h="24px" align="center">
                                <Text
                                  textColor={"dark"}
                                  textWeight="500"
                                  m={{ r: "auto", l: "0.5rem" }}
                                  textSize={14}
                                >
                                  {inputs.unloadingCountryName
                                    ? inputs.unloadingCountryName
                                    : `Select country name`}
                                </Text>
                              </Div>
                            }
                            isLoading={loadingUnloadingCountries}
                            isOpen={isUnloadingLocationDropdownOpen}
                            setIsOpen={setIsUnloadingLocationDropdownOpen}
                            value={countryUnloadingInput}
                            handleChange={setUnloadingCountryInput}
                            menuList={
                              <>
                                {unloadingCountries &&
                                  unloadingCountries?.getCountries?.items?.map(
                                    (country: Country, i: number) => (
                                      <Anchor
                                        d="flex"
                                        p={{ y: "0.5rem", x: "0.75rem" }}
                                        key={i}
                                        align="center"
                                        onClick={() => {
                                          setInputs({
                                            ...inputs,
                                            unloadingCountryName: country.name,
                                            unloadingCountry_id: country.id,
                                          });
                                          setUnloadingCountryInput("");
                                          setIsUnloadingLocationDropdownOpen(
                                            false
                                          );
                                        }}
                                      >
                                        <Text
                                          textColor="semiDark"
                                          hoverTextColor="dark"
                                        >
                                          {" "}
                                          {`${country?.name}`}
                                        </Text>
                                      </Anchor>
                                    )
                                  )}
                              </>
                            }
                          />
                        }
                      </Col>
                    </Row>
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col size="4">
                        {
                          <Input
                            placeholder="Post code"
                            h="2.5rem"
                            name="unloadingPostCode"
                            value={inputs.unloadingPostCode}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>

                      <Col size="8">
                        {
                          <Input
                            placeholder="Post name"
                            h="2.5rem"
                            name="unloadingPostName"
                            value={inputs.unloadingPostName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={handleFilter}
                isLoading={false}
                text={"Apply filter"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setInputs({
                    ...inputs,
                    loading_from_time: null,
                    loading_from_date: null,
                    loading_to_time: null,
                    loading_to_date: null,
                    unloading_from_time: null,
                    unloading_from_date: null,
                    unloading_to_time: null,
                    unloading_to_date: null,
                    loadingPostCode: "",
                    loadingPostName: "",
                    loadingCountry_id: "",
                    loadingCountryName: "",
                    unloadingPostCode: "",
                    unloadingPostName: "",
                    unloadingCountry_id: "",
                    unloadingCountryName: "",
                  });

                  setCompany({
                    name: "",
                    logoPath: "",
                  });

                  name === "filterMarketTableLink"
                    ? marketQueryLink({
                        ...initialMarketQueryLink,
                        limit: marketQueryLinkVar.limit,
                        page: 1,
                      })
                    : marketQueryPag({
                        ...initialMarketQueryPagination,
                        limit: Math.floor(
                          (height -
                            (width > 700 ? 200 : width > 470 ? 250 : 300)) /
                            49
                        ),
                        page: 1,
                      });

                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    marketPag: null,
                  });

                  localStorage.removeItem(name);
                }}
                isLoading={false}
                text={"Reset filter"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default FilterMarketTable;
