import React, { useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Div, Anchor } from "atomize";
import renderIcon from "../../assets/Icons";
import { LocationType, useGetMeQuery } from "../../generated/graphql";
import { GlobalTheme } from "../GlobalTheme";
import { disassemblePhoneNumber } from "../../helpers/functions";
import DropDown from "../DropdownClassic/DropdownClassic";
import { modalData } from "../../App";

const LocationRowActions: React.FC<any> = ({ location }) => {
  const [actionDropdown, setActionDropdown] = useState<boolean>(false);
  const { data: me } = useGetMeQuery();

  return (
    <Tr>
      <Td
        className="no_p"
        styleTd={{
          borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
          borderRight: `1px solid ${GlobalTheme.colors.grey}`,
          padding: 0,
        }}
        isActionTd={true}
      >
        {location.type === LocationType.CompanyBillingAddress &&
        me?.getUser.roles![0].id !== 1 &&
        me?.getUser.roles![0].id !== 2 ? (
          ""
        ) : (
          <DropDown
            h="auto"
            showDropIcon={false}
            border={false}
            direction="lefttop"
            style={{ width: "auto!important" }}
            isOpen={actionDropdown}
            menuList={
              <Div
                shadow={GlobalTheme.shadows[4]}
                style={{
                  background: GlobalTheme.colors.background,
                  padding: "0 0.75rem",
                  borderRadius: GlobalTheme.rounded[4],
                }}
                tabIndex={0}
              >
                {
                  <Div d="flex" align="center">
                    <Anchor
                      textSize={142}
                      textColor="semiDark"
                      hoverTextColor="dark"
                      d="block"
                      p={{ y: "0.25rem" }}
                      onClick={() => {
                        modalData({
                          msg: "Update Location",
                          name: "locationEditModal",
                          returned_value: null,
                          props: {
                            id: location.id,
                            company_id: me?.getUser.company?.id,
                            title: location.title,
                            address: location.address,
                            postName: location.postName,
                            postCode: location.postCode,
                            contactName: location.contactName,
                            phone: disassemblePhoneNumber(location.phone)
                              .phoneNumber,
                            phoneCountryNumbers: disassemblePhoneNumber(
                              location.phone
                            ).preNumber,
                            email: location.email,
                            geoLocation: location.geoLocation,
                            type: location.type,
                            description: location.description,
                            country: {
                              id: location.country.id,
                              name: location.country.name,
                              code: location.country.code,
                            },
                            timezone: location.timezone,
                          },
                        });
                        setActionDropdown(false);
                      }}
                    >
                      Edit
                    </Anchor>
                  </Div>
                }
              </Div>
            }
            setIsOpen={setActionDropdown}
          >
            <Text cursor="pointer" hoverTextColor="info700">
              {renderIcon("ActionDots")}
            </Text>
          </DropDown>
        )}
      </Td>
    </Tr>
  );
};

export default LocationRowActions;
