import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Row, Text, Textarea } from "atomize";
import moment from "moment";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetMarketOrdersDocument,
  GetPlacedBidsDocument,
  useCreateBidMutation,
  useGetMeQuery,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { DateWithGtm, GetGtm } from "../../../helpers/functions";
import Border from "../../Border/Border";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  order_id: yup.number().required({ name: "Oder", code: "DATA_NOT_SELECTED" }),
  price: yup
    .number()
    .min(1, { name: "Price", code: "MIN_CHARACTERS", var: "1" })
    .required({ name: "Price", code: "REQUIRED_FIELD" }),
  company_id: yup
    .number()
    .required({ name: "Company", code: "DATA_NOT_SELECTED" }),
  comment: yup
    .string()
    .max(2000, { name: "Comment", code: "MAX_CHARACTERS", var: "2000" }),
  expirationDate: yup
    .date()
    .min(yup.ref("todayDate"), {
      name: "Expiry datae",
      code: "DATE_MIN",
      var: "current date",
    })
    .max(yup.ref("orderExpiryDate"), {
      name: "Loading date",
      code: "DATE_MAX",
      var: "order loading date",
    }),
  orderExpiryDate: yup.date(),
  todayDate: yup.date(),
});

const initial = {
  price: "",
  comment: "",
  date: moment().format("YYYY-MM-DD"),
  time: moment()
    .utcOffset(0)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .format("HH:mm"),
  timezone: "",
};

const MakeBid: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const { data: me } = useGetMeQuery();
  const [bid, setBid] = useState(initial);
  const [
    createBid,
    { data: bidData, loading: loadingBidData, error: errorBidData },
  ] = useCreateBidMutation();
  const { validator } = ValidationsFeHandler();

  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setBid({
      ...bid,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const bidVar = {
      order_id: props.id,
      price: Number(bid.price),
      company_id: me?.getUser.company_id,
      comment: bid.comment,
      expirationDate: tz(`${bid.date} ${bid.time}`, bid.timezone).format(),
    };

    validator({
      schema: schema,
      data: {
        ...bidVar,
        orderExpiryDate: props.expirationDate,
        todayDate: moment().format(),
      },
      success: () => {
        createBid({
          variables: { bid: bidVar },
          refetchQueries: [GetPlacedBidsDocument, GetMarketOrdersDocument],
          awaitRefetchQueries: true,
        });
      },
    });
  };

  useEffect(() => {
    if (name === "makeBid" && me?.getUser.company?.location?.timezone) {
      setBid({
        ...bid,
        timezone: me?.getUser.company?.location?.timezone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  ErrorsBeHandler({
    error: errorBidData,
    dep: name === "makeBid",
  });

  SuccessBeHandler({
    data: bidData,
    code: "MAKE_BID",
    dep: name === "makeBid",
    fn: () => {
      setBid(initial);
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "makeBid")}
      onClose={() => {
        setBid(initial);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      maxW="496px"
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "makeBid" && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            Make a bid
          </Text>

          <Row d="column">
            {props.loadingLocation_postName && (
              <>
                <Col
                  d="flex"
                  flexDir={width > 500 ? "row" : "column"}
                  justify="space-between"
                >
                  <Text textColor={"semiDark"} m={{ r: "0.5rem" }}>
                    Experation Time
                  </Text>
                  <Text
                    textColor={"dark"}
                    m={{ y: "0.25rem" }}
                    textWeight={"500"}
                  >
                    {props.expirationDate ? (
                      <Moment to={props.expirationDate}>{Date.now()}</Moment>
                    ) : (
                      " None"
                    )}
                  </Text>
                </Col>
                <Col
                  d="flex"
                  flexDir={width > 500 ? "row" : "column"}
                  justify="space-between"
                >
                  <Text textColor={"semiDark"} m={{ r: "0.5rem" }}>
                    Destination
                  </Text>
                  <Text
                    textColor={"dark"}
                    m={{ y: "0.25rem" }}
                    textWeight={"600"}
                  >
                    {` ${props.loadingLocation_postName}-> ${props.unloadingLocation_postName}`}
                  </Text>
                </Col>
                <Col
                  d="flex"
                  flexDir={width > 500 ? "row" : "column"}
                  justify="space-between"
                >
                  <Text textColor={"semiDark"} m={{ r: "0.5rem" }}>
                    Loading date
                  </Text>
                  <Text
                    textColor={"dark"}
                    m={{ y: "0.25rem" }}
                    textWeight={"600"}
                  >
                    {me?.getUser.company?.location?.timezone &&
                      DateWithGtm(
                        props.requestedLoadingTime,
                        me?.getUser?.company?.location?.timezone
                      )}
                  </Text>
                </Col>
                <Col
                  d="flex"
                  flexDir={width > 500 ? "row" : "column"}
                  justify="space-between"
                >
                  <Text textColor={"semiDark"} m={{ r: "0.5rem" }}>
                    Unloading date
                  </Text>
                  <Text
                    textColor={"dark"}
                    m={{ t: "0.25rem" }}
                    textWeight={"600"}
                  >
                    {me?.getUser.company?.location?.timezone &&
                      DateWithGtm(
                        props.requestedUnloadingTime,
                        me?.getUser?.company?.location?.timezone
                      )}
                  </Text>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col>
              <Border margin="2rem 0"></Border>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Your price
              </Text>
              <NumberFormat
                thousandSeparator={true}
                style={{ height: "48px" }}
                value={bid.price}
                placeholder="€"
                suffix={" €"}
                onValueChange={(values) => {
                  setBid({
                    ...bid,
                    price: values.value,
                  });
                }}
                customInput={Input}
              />
            </Col>
          </Row>

          <Row size="12">
            <Col size={width > 550 ? "6" : "12"}>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Bid experiation date{" "}
              </Text>
              <Input
                placeholder="DD/MM/YYYY"
                h="48px"
                name="date"
                type="date"
                w="100%"
                value={bid.date}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col size={width > 550 ? "3" : "6"}>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Time
              </Text>
              <Input
                placeholder="16.00"
                h="48px"
                name="time"
                type="time"
                w="100%"
                value={bid.time}
                onChange={(e: any) => {
                  handleChange(e);
                }}
              />
            </Col>
            <Col size={width > 550 ? "3" : "6"}>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Timezone
              </Text>
              <Div
                d="flex"
                h="48px"
                align="center"
                border="1px solid"
                borderColor="neutralGrey"
                rounded={6}
                p={{ xs: "0.75rem" }}
                bg="grey"
              >
                <Text
                  textColor="dark"
                  textTransform="capitalize"
                  textWeight="500"
                  m={{ r: "auto" }}
                  textSize={14}
                >
                  {me?.getUser?.company?.location?.timezone &&
                    GetGtm(me?.getUser?.company?.location?.timezone)}
                </Text>
              </Div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Comment
              </Text>
              <Textarea
                placeholder="Enter notes you would like to include with this bid..."
                h="4rem"
                m={{ b: "2rem" }}
                value={bid.comment}
                name="comment"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange(e)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loadingBidData}
                text={"Make a bid"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setBid(initial);
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loadingBidData}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default MakeBid;
