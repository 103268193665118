import React, { useEffect, useState } from "react";
import Tr from "../TableComponents/Tr";
import { Text, Icon, Div, Anchor, Tag } from "atomize";
import Skeleton from "../Skeleton/Skeleton";
import { BidListing, BidListingItem } from "../../generated/graphql";
import Pagination from "../Pagination/Pagination";
import Thead from "../TableComponents/Thead";
import Th from "../TableComponents/Th";
import SortIcon from "../SortIcon/SortIcon";
import Tbody from "../TableComponents/Tbody";
import Table from "../TableComponents/Table";
import Border from "../Border/Border";
import BidsRow from "./BidRow";
import { useHistory } from "react-router";
import Td from "../TableComponents/Td";
import BidsRowActions from "./BidsRowActions";
import SecondaryBtn from "../SecondaryBtn/SecondaryBtn";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";

interface BidsTableProps {
  bids?: BidListing;
  bidsLoading: boolean;
  setQuery: (query?: any) => void;
  query: any;
  title: string;
  link?: string;
  footer: string;
  refetch?: () => void;
  noLink?: boolean;
}

const BidsTable: React.FC<BidsTableProps> = ({
  bids,
  bidsLoading,
  setQuery,
  query,
  title,
  link,
  footer,
  refetch,
  noLink,
}) => {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (bids?.total && query?.limit) {
      setTotalPages(Math.ceil(bids.total / query.limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids]);

  // height - (width > 700 ? 200 : 300)

  return (
    <Div m={{ t: "1rem" }}>
      {(bids && bids.total && bids?.total > 0) || bidsLoading ? (
        <>
          <Div d="flex" justify="space-between" m={{ b: "1rem" }}>
            <Text
              textSize={width > 750 || width < 700 ? 20 : 16}
              textColor="dark"
            >
              {title}
            </Text>
            {refetch &&
              (bidsLoading ? (
                <Skeleton height="2rem" width="40px" />
              ) : (
                <SecondaryBtn
                  w="40px"
                  h="2rem"
                  handleSubmit={() => refetch()}
                  styleBtn={{ marginLeft: "0.5rem" }}
                  isLoading={false}
                  text={<Icon name="Refresh" size="16px" color="semiDark" />}
                />
              ))}
          </Div>
          {!bidsLoading ? (
            <Table width={`calc(100% -  ${bidsLoading ? "0" : "0"})`}>
              <Tr hidBorderLast={true} className="border_none">
                <Td width={"auto"} className="divider">
                  <Table>
                    <Thead>
                      <Tr className="head" hidBorderLast={true}>
                        <Th width="100px">
                          <Text overflow="hidden" d="inline!important">
                            Bids
                          </Text>
                          <SortIcon
                            sortBy={query}
                            name="price"
                            setSortBy={setQuery}
                          />
                        </Th>
                        <Th width="180px">
                          <Text overflow="hidden" d="inline!important">
                            Transporter
                          </Text>
                          <SortIcon
                            sortBy={query}
                            name="company_name"
                            setSortBy={setQuery}
                          />
                        </Th>
                        <Th width="200px">
                          <Text
                            p={{ l: "0.5rem" }}
                            overflow="hidden"
                            d="inline!important"
                          >
                            Destination
                          </Text>
                        </Th>
                        <Th width="130px">
                          <Text
                            p={{ l: "0.5rem" }}
                            overflow="hidden"
                            d="inline!important"
                          >
                            Date
                          </Text>
                        </Th>
                        <Th width="200px">
                          <Text overflow="hidden" d="inline!important">
                            Notes
                          </Text>
                        </Th>
                        <Th width="100px">
                          <Text overflow="hidden" d="inline!important">
                            Expires in
                          </Text>
                          <SortIcon
                            sortBy={query}
                            name="expirationDate"
                            setSortBy={setQuery}
                          />
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody maxH="none">
                      {bids?.items?.map((bid: BidListingItem, i: number) => (
                        <BidsRow key={i} bid={bid} noLink={noLink} />
                      ))}
                    </Tbody>
                  </Table>
                </Td>
                {!bidsLoading && (
                  <Td
                    width={title === "Bids placed" ? "92px" : "145px"}
                    className="divider_right no_p"
                  >
                    <Table width="auto">
                      <Thead>
                        <Tr>
                          <Td className="head"></Td>
                        </Tr>
                      </Thead>
                      <Tbody maxH="none">
                        {bids?.items?.map((bid: BidListingItem, i: number) => (
                          <BidsRowActions bid={bid} noLink={noLink} key={i} />
                        ))}
                      </Tbody>
                    </Table>
                  </Td>
                )}
              </Tr>
            </Table>
          ) : (
            <Skeleton
              classN="table_skeleton"
              height={`${49 * query.limit + 49}px`}
              // style={{ maxHeight: }}
            />
          )}
          {footer === "link" && bids?.total && bids?.total > 5 ? (
            <>
              <Div h="47px" d="flex" align="center">
                <Anchor
                  cursor="pointer"
                  textSize={14}
                  textColor="primary"
                  fontWeight="600"
                  onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    link && history.push(link);
                  }}
                >
                  View all →
                </Anchor>
              </Div>
              <Border></Border>
            </>
          ) : (
            ""
          )}
          {footer === "pagination" && totalPages && (
            <Pagination setQuery={setQuery} query={query} total={totalPages} />
          )}
        </>
      ) : (
        <Div>
          <Div d="flex" justify="space-between">
            <Text
              textSize={width > 750 || width < 700 ? 20 : 16}
              textColor="dark"
              m={{ b: "1rem" }}
            >
              {title}
            </Text>
            {refetch &&
              (bidsLoading ? (
                <Skeleton height="2rem" width="40px" />
              ) : (
                <SecondaryBtn
                  w="40px"
                  h="2rem"
                  handleSubmit={() => refetch()}
                  styleBtn={{ marginLeft: "0.5rem" }}
                  isLoading={false}
                  text={<Icon name="Refresh" size="16px" color="semiDark" />}
                />
              ))}
          </Div>
          <Tag
            p={{ l: "1rem" }}
            h="48px"
            w="100%"
            d="flex"
            justify="start"
            textColor="light"
            style={{ whiteSpace: "nowrap" }}
          >
            There are no bids yet.
          </Tag>
        </Div>
      )}
    </Div>
  );
};

export default BidsTable;
