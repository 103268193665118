import { useApolloClient } from "@apollo/client";
import { Div } from "atomize";
import { createElement, FC, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { path } from "../../App";
import { config } from "../../config";
import { useGetMeLazyQuery } from "../../generated/graphql";
import { LocalStorageKeys } from "../../helpers/constants";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import Cnt from "../../layout/Cnt/Cnt";
import Header from "../../layout/Header/Header";

interface guestProps {
  component: FC;
  path: string;
  exact: boolean;
}

const GuestGuard = ({ component, ...rest }: guestProps) => {
  const [GetMe, { data }] = useGetMeLazyQuery({
    onCompleted: () => {
      history.push("/myorders");
    },
  });

  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();

  const { width } = useWindowDimensions();

  document.addEventListener("visibilitychange", async (event) => {
    if (document.visibilityState === "visible") {
      if (localStorage.getItem(LocalStorageKeys.TOKEN)) {
        await client.cache.reset();
        GetMe();
      }
    }
  });

  useEffect(() => {
    localStorage.getItem(LocalStorageKeys.TOKEN) && history.push("/myorders");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    path({ to: "", from: path().to });
  }, [location]);

  SuccessBeHandler({
    data: data,
    code: "USER_VERIFIED",
    fn: () => {
      history.push("/myorders");
    },
  });

  const isLanding = rest.path === "/";

  return (
    <Div overflowX="hidden" maxW={`${width}px`}>
      {
        <Div overflow="hidden">
          <Header />
          <Cnt maxW={"440px"} flexDir={"row"}>
            {isLanding && !localStorage.getItem(LocalStorageKeys.TOKEN) ? (
              <Route
                {...rest}
                component={() => {
                  window.location.href = config.static_site_url;
                  return null;
                }}
              />
            ) : (
              <Route {...rest} render={() => createElement(component)} />
            )}
          </Cnt>
        </Div>
      }
    </Div>
  );
};

export default GuestGuard;
