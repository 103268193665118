import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalTheme } from "./components/GlobalTheme";
import { ThemeProvider as ThemeProviderAtomize, StyleReset } from "atomize";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const debug =
  process.env.REACT_APP_ENV === "production" ? void 0 : new DebugEngine();
// 1. Create a client engine instance
const engine = new Styletron();

process.env.REACT_APP_ENV === "production" &&
  Sentry.init({
    dsn: "https://3ebc712e89e8462086827b42614ba93a@o1145519.ingest.sentry.io/6212981",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
  <React.StrictMode>
    <ThemeProviderStyled theme={GlobalTheme}>
      <ThemeProviderAtomize theme={GlobalTheme}>
        <StyleReset />
        <StyletronProvider value={engine} debug={debug} debugAfterHydration>
          <App />
        </StyletronProvider>
      </ThemeProviderAtomize>
    </ThemeProviderStyled>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
