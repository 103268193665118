import React, { useEffect } from "react";
import { Icon, Div } from "atomize";
import { useActivateUserMutation } from "../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import { getParams } from "../../helpers/functions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import * as yup from "yup";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";

let schema = yup.object().shape({
  token: yup
    .string()
    .nullable()
    .required({ name: "Token", code: "TOKEN_MISSING" }),
});
const ActivateUser: React.FC = () => {
  const search = useLocation().search;
  const token = getParams(search, "token", "?token=");
  const [activateUser, { data: activateUserData, error: activateUserError }] =
    useActivateUserMutation({ errorPolicy: "all" });
  const history = useHistory();
  const { validator } = ValidationsFeHandler();

  useEffect(() => {
    validator({
      schema: schema,
      data: { token },
      error: () => {
        history.push("/signin");
      },
      success: () => {
        activateUser({ variables: { token: token! } });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ErrorsBeHandler({
    error: activateUserError,
    fn: () => {
      history.push("/signin");
    },
  });

  SuccessBeHandler({
    data: activateUserData,
    code: "ACTIVATED_USER",
    fn: () => {
      history.push("/signin");
    },
  });

  return (
    <Div d="flex" align="center" justify="center" w="100%" h="100%">
      <Icon name="Loading" size="200px" color="grey" />
    </Div>
  );
};

export default ActivateUser;
