import { useReactiveVar } from "@apollo/client";
import { Anchor, Checkbox, Col, Div, Icon, Label, Row, Text } from "atomize";
import { isEmpty } from "lodash";
import { ChangeEvent, useState } from "react";
import { modalData } from "../../../App";
import { ModuleId, ModuleName } from "../../../enums";
import {
  Company,
  CompanyStatus,
  GetModulePermissionsDocument,
  useCreateModulePermissionMutation,
  useGetCompaniesLazyQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PreloadingImage from "../../PreloadingImage/PreloadingImage";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import ShortNameLogo from "../../ShortNameLogo/ShortNameLogo";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";
import { newModulePermissionSchema } from "./schema/schema.validation";

interface ModulePermissionState {
  company: Company | null;
  moduleId: number | null;
  hasAccess: boolean;
}

const initialModulePermissionState = {
  company: null,
  moduleId: null,
  hasAccess: false,
};

const moduleOptions = [
  {
    value: ModuleId.ORDER_MANAGEMENT,
    label: ModuleName.ORDER_MANAGEMENT,
  },
  {
    value: ModuleId.DOCUMENTS_AND_INVOICING,
    label: ModuleName.DOCUMENTS_AND_INVOICING,
  },
  {
    value: ModuleId.FLEET_TRACKING,
    label: ModuleName.FLEET_TRACKING,
  },
  {
    value: ModuleId.CONTRACTOR_MARKETPLACE,
    label: ModuleName.CONTRACTOR_MARKETPLACE,
  },
  {
    value: ModuleId.ORDER_RECOGNITION,
    label: ModuleName.ORDER_RECOGNITION,
  },
  {
    value: ModuleId.SLOT_SCHEDULING,
    label: ModuleName.SLOT_SCHEDULING,
  },
  {
    value: ModuleId.INBOUND_SALES_AI,
    label: ModuleName.INBOUND_SALES_AI,
  },
];

const NewModulePermission = () => {
  const { name, msg } = useReactiveVar(modalData);
  const { width } = useWindowDimensions();

  const isOpen = name === "newModulePermission";

  const { validator } = ValidationsFeHandler();

  const [modulePermission, setModulePermission] =
    useState<ModulePermissionState>(initialModulePermissionState);

  const [isCompaniesDropdownOpen, setIsCompaniesDropdownOpen] = useState(false);
  const [isModulesDropdownOpen, setIsModulesDropdownOpen] = useState(false);

  const queryCompanies = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const {
    data: companiesCompanies,
    loading: loadingCompaniesCompanies,
    search: searchCompanies,
    setSearch: setSearchCompanies,
    page: searchCompaniesPage,
    setPage: setSearchCompaniesPage,
  } = useSearch(queryCompanies, { limit: 5 }, 300, isCompaniesDropdownOpen);

  const [
    createModulePermission,
    {
      data: createModulePermissionData,
      loading: createModulePermissionLoading,
      error: createModulePermissionError,
    },
  ] = useCreateModulePermissionMutation();

  const handleClose = () =>
    modalData({
      name: "",
      msg: "",
      returned_value: null,
      props: null,
    });

  const handleSubmit = async (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const payload = {
      companyId: modulePermission?.company?.id ?? 0,
      moduleId: modulePermission?.moduleId ?? 0,
      hasAccess: modulePermission?.hasAccess ?? false,
    };

    validator({
      schema: newModulePermissionSchema,
      data: payload,
      success: () => {
        createModulePermission({
          variables: payload,
          refetchQueries: [GetModulePermissionsDocument],
        });
        setModulePermission(initialModulePermissionState);
        handleClose();
      },
    });
  };

  const handleFieldValueChange = (
    value: any,
    field: keyof ModulePermissionState
  ) => {
    setModulePermission((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  ErrorsBeHandler({
    error: createModulePermissionError,
  });

  SuccessBeHandler({
    data: createModulePermissionData,
    code: "MODULE_PERMISSION_CREATED",
  });

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={handleClose}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {isOpen && (
        <Div
          style={{
            overflowY: "auto",
            padding: width > 550 ? "2rem" : "1rem",
          }}
        >
          <Row>
            <Col d="flex" align="center">
              <Text
                textSize="201"
                textAlign="center"
                textWeight="700"
                textColor="dark"
              >
                {msg}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Col>
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem" }}
                  >
                    Payee
                  </Text>
                  <InputDropdown
                    h="48px"
                    page={searchCompaniesPage}
                    setPage={setSearchCompaniesPage}
                    totalPages={Math.ceil(
                      companiesCompanies?.getCompanies?.total / 5
                    )}
                    addNew={false}
                    isLoading={loadingCompaniesCompanies}
                    displayResult={
                      <Div
                        d={
                          !isEmpty(modulePermission?.company?.name)
                            ? "flex"
                            : "none"
                        }
                        h="24px"
                        align="center"
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "hidden",
                          WebkitLineClamp: "1",
                          width: "100%",
                        }}
                      >
                        <PreloadingImage
                          imageSrc={modulePermission?.company?.logoPath ?? ""}
                          skeletonComponent={
                            <Skeleton
                              height={"24px"}
                              width={"24px"}
                              borderRadius={"24px"}
                            />
                          }
                          imgComponent={
                            <ShortNameLogo
                              size="24px"
                              name={modulePermission?.company?.name ?? ""}
                              textSize={12}
                            />
                          }
                          imageStyle={{
                            minWidth: "24px",
                            minHeight: "24px",
                            width: "24px",
                            height: "24px",
                            borderRadius: "24px",
                            verticalAlign: "middle",
                          }}
                        />
                        <Div
                          style={{
                            width: "calc(100% - 70px)",
                          }}
                        >
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            textWeight="500"
                            m={{ l: "0.5rem" }}
                            style={{
                              textOverflow: "ellipsis",
                              overflowX: "hidden",
                            }}
                          >
                            {modulePermission?.company?.name}
                          </Text>
                        </Div>
                        {modulePermission?.company?.status ===
                          CompanyStatus.Active && (
                          <Icon
                            m={{ l: "1rem" }}
                            name="Checked"
                            size="20px"
                            color="success"
                          />
                        )}
                      </Div>
                    }
                    isOpen={isCompaniesDropdownOpen}
                    setIsOpen={setIsCompaniesDropdownOpen}
                    value={searchCompanies}
                    handleChange={setSearchCompanies}
                    menuList={companiesCompanies?.getCompanies.items?.map(
                      (item: Company) => (
                        <Anchor
                          d="flex"
                          p={{ y: "0.5rem", x: "0.75rem" }}
                          key={item?.id}
                          align="center"
                          onClick={() => {
                            handleFieldValueChange(item, "company");
                            setSearchCompanies("");
                            setIsCompaniesDropdownOpen(false);
                          }}
                        >
                          <PreloadingImage
                            imageSrc={item.logoPath ?? ""}
                            skeletonComponent={
                              <Skeleton
                                height={"24px"}
                                width={"24px"}
                                borderRadius={"24px"}
                              />
                            }
                            imgComponent={
                              <ShortNameLogo
                                size="24px"
                                name={item.name}
                                textSize={12}
                              />
                            }
                            imageStyle={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "24px",
                              verticalAlign: "middle",
                            }}
                          />
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            m={{ l: "10px" }}
                          >
                            {item.name}
                          </Text>
                          {item.status === CompanyStatus.Active && (
                            <Icon
                              m={{ l: "auto" }}
                              name="Checked"
                              size="20px"
                              color="success"
                            />
                          )}
                        </Anchor>
                      )
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem" }}
                  >
                    Module
                  </Text>
                  <InputDropdown
                    h="48px"
                    addNew={false}
                    isLoading={false}
                    showSearchField={false}
                    displayResult={
                      <Div>
                        <Text>
                          {
                            moduleOptions?.find(
                              (item) =>
                                item.value === modulePermission?.moduleId
                            )?.label
                          }
                        </Text>
                      </Div>
                    }
                    isOpen={isModulesDropdownOpen}
                    setIsOpen={setIsModulesDropdownOpen}
                    menuList={moduleOptions?.map(
                      (item: { value: number; label: string }) => (
                        <Anchor
                          d="flex"
                          p={{ y: "0.5rem", x: "0.75rem" }}
                          key={item.value}
                          align="center"
                          onClick={() => {
                            handleFieldValueChange(item?.value, "moduleId");
                            setIsModulesDropdownOpen(false);
                          }}
                        >
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            m={{ l: "10px" }}
                          >
                            {item.label}
                          </Text>
                        </Anchor>
                      )
                    )}
                  />
                </Col>
              </Row>
              <Div h="2rem" />
              <Row>
                <Col>
                  <Label
                    align="center"
                    textSize={14}
                    textTransform="capitalize"
                    textWeight="500"
                    m={{ b: "0.5rem" }}
                  >
                    <Checkbox
                      onChange={(e: any) => {
                        handleFieldValueChange(
                          !modulePermission?.hasAccess,
                          "hasAccess"
                        );
                      }}
                      checked={modulePermission?.hasAccess}
                    />
                    Has access
                  </Label>
                </Col>
              </Row>

              <Div h="3rem" />
              <Row>
                <Col size="6">
                  <PrimaryBtn
                    w="100%"
                    handleSubmit={handleSubmit}
                    isLoading={createModulePermissionLoading}
                    text="Save"
                  />
                </Col>
                <Col size="6">
                  <SecondaryBtn
                    w="100%"
                    handleSubmit={() => handleClose()}
                    isLoading={createModulePermissionLoading}
                    text="Cancel"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default NewModulePermission;
