import React, { ReactElement } from "react";
import { Button, Icon } from "atomize";
import { CSSProperties } from "styled-components";

interface PrimaryBtnProps {
  handleSubmit?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  text?: string | ReactElement;
  w?: string;
  h?: string;
  textSize?: number;
  styleBtn?: CSSProperties;
  prefixIcon?: ReactElement;
  suffixIcon?: ReactElement;
  disabled?: boolean;
}

const PrimaryBtn: React.FC<PrimaryBtnProps> = ({
  textSize = 14,
  styleBtn,
  prefixIcon,
  suffixIcon,
  disabled = false,
  handleSubmit,
  isLoading,
  text,
  w = "100%",
  h = "48px",
}) => {
  return (
    <Button
      h={h}
      w={w}
      bg="primary"
      hoverBg="brand600"
      p={{ xs: "0.6rem" }}
      textSize={textSize}
      prefix={prefixIcon}
      suffix={suffixIcon}
      onClick={handleSubmit}
      hoverShadow="2"
      disabled={disabled || isLoading}
      style={{ ...styleBtn }}
    >
      {isLoading ? (
        <Icon name="Loading" color="background" size="20px" />
      ) : text ? (
        text
      ) : (
        "Confirm"
      )}
    </Button>
  );
};

export default PrimaryBtn;
