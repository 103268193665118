import React, { createContext, useReducer } from "react";
import * as Styled from "./ReceivePricingBids.styles";
import { Row, Col } from "atomize";
import renderIcon from "../../assets/Icons";
import { loadingInitialState, LoadingReducer } from "./loading.reducer";
import { useLocation } from "react-router";

export const OrderContext: any = createContext(null);

const ReceivePricingBids: React.FC = ({ children }) => {
  const [loadingState, dispatch] = useReducer(
    LoadingReducer,
    loadingInitialState
  );
  const location = useLocation();

  if (location.pathname.includes("/createneworder/")) {
    return (
      <OrderContext.Provider value={{ loadingState, dispatch }}>
        <Styled.ReceivePricingBids>
          <Row m={{ y: "1rem" }} d="flex" flexDir="column" w="100%">
            <Col align="center" d="flex" flexDir="column" w="100%" size="12">
              {renderIcon("ReceivingPricingBids")}
            </Col>
          </Row>
          {children}
        </Styled.ReceivePricingBids>
      </OrderContext.Provider>
    );
  } else {
    return <></>;
  }
};

export default ReceivePricingBids;
