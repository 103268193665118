import { useReactiveVar } from "@apollo/client";
import {
  Anchor,
  Button,
  Checkbox,
  Col,
  Collapse,
  Div,
  Icon,
  Input,
  Label,
  Row,
  Text,
} from "atomize";
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { modalData, myOrdersPag, responseMsgs } from "../../../App";
import {
  OrderStatus,
  UserStatus,
  useGetCompaniesLazyQuery,
  useGetCountriesLazyQuery,
  useGetCountryLazyQuery,
  useGetDriversLazyQuery,
  useGetMeQuery,
  useGetUsersLazyQuery,
  useGetVehiclesLazyQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { LocalStorageKeys } from "../../../helpers/constants";
import { StatusOptions } from "../../../helpers/functions";
import { initialGetOrders } from "../../../helpers/initialData";
import Border from "../../Border/Border";
import InputDropdown from "../../InputDropdown/InputDropdown";
import MultiInputDropdown, {
  MultiSelectOption,
} from "../../MultiInputDropdown/MultiInputDropdown";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";
import {
  mapCompanyOptions,
  mapDriverOptions,
  mapVehiclesOptions,
} from "./helpers/helpers";

const FilterTable: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const myOrdersPagVar = useReactiveVar(myOrdersPag);

  const isFilterTable = name === "filterTable";

  const [localUsers, setLocalUsers] = useState<MultiSelectOption[]>([]);
  const [localDrivers, setLocalDrivers] = useState<MultiSelectOption[]>([]);
  const [localCompanies, setLocalCompanies] = useState<MultiSelectOption[]>([]);
  const [localVehicles, setLocalVehicles] = useState<MultiSelectOption[]>([]);

  const [collapse, setCollapse] = useState<{
    date: boolean;
    users: boolean;
    partners: boolean;
    status: boolean;
    loadingLocation: boolean;
    unloadingLocation: boolean;
    other: boolean;
    vehicles: boolean;
    drivers: boolean;
  }>({
    date: false,
    users: false,
    partners: false,
    status: false,
    loadingLocation: false,
    unloadingLocation: false,
    other: false,
    vehicles: false,
    drivers: false,
  });

  const [inputs, setInputs] = useState<{
    loading_from_time: string | null;
    loading_from_date: string | null;
    loading_to_time: string | null;
    loading_to_date: string | null;
    unloading_from_time: string | null;
    unloading_from_date: string | null;
    unloading_to_time: string | null;
    unloading_to_date: string | null;
    loadingPostCode: string | null;
    loadingPostName: string | null;
    loadingCountry_id: string | null;
    loadingCountryName: string | null;
    unloadingPostCode: string | null;
    unloadingPostName: string | null;
    unloadingCountry_id: string | null;
    unloadingCountryName: string | null;
  }>({
    loading_from_time: myOrdersPagVar?.loadingTime_from
      ? moment(myOrdersPagVar?.loadingTime_from).format("HH:mm")
      : null,
    loading_from_date: myOrdersPagVar?.unloadingTime_from
      ? moment(myOrdersPagVar?.loadingTime_from).format("YYYY-MM-DD")
      : null,
    loading_to_time: myOrdersPagVar?.loadingTime_to
      ? moment(myOrdersPagVar?.loadingTime_to).format("HH:mm")
      : null,
    loading_to_date: myOrdersPagVar?.loadingTime_to
      ? moment(myOrdersPagVar?.loadingTime_to).format("YYYY-MM-DD")
      : null,
    unloading_from_time: myOrdersPagVar?.unloadingTime_from
      ? moment(myOrdersPagVar?.unloadingTime_from).format("HH:mm")
      : null,
    unloading_from_date: myOrdersPagVar?.unloadingTime_from
      ? moment(myOrdersPagVar?.unloadingTime_from).format("YYYY-MM-DD")
      : null,
    unloading_to_time: myOrdersPagVar?.unloadingTime_to
      ? moment(myOrdersPagVar?.unloadingTime_to).format("HH:mm")
      : null,
    unloading_to_date: myOrdersPagVar?.unloadingTime_to
      ? moment(myOrdersPagVar?.unloadingTime_to).format("YYYY-MM-DD")
      : null,
    loadingPostCode: "",
    loadingPostName: "",
    loadingCountry_id: "",
    loadingCountryName: "",
    unloadingPostCode: "",
    unloadingPostName: "",
    unloadingCountry_id: "",
    unloadingCountryName: "",
  });

  const [getLoadingCountry, { data: savedLoadingCountry }] =
    useGetCountryLazyQuery();
  const [getUnloadingCountry, { data: savedUnloadingCountry }] =
    useGetCountryLazyQuery();
  const [getCompanies] = useGetCompaniesLazyQuery();
  const [getUsers] = useGetUsersLazyQuery();
  const [getDrivers] = useGetDriversLazyQuery();
  const [getVehicles] = useGetVehiclesLazyQuery();

  const [myOrdersPagState, setMyOrdersPagState] = useState(initialGetOrders);
  const { data: me } = useGetMeQuery();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = () => {
    let errors: any = [];

    const format = "YYYY-MM-DD HH:mm:ss";

    console.log('inputs: ', inputs)

    if (inputs.loading_from_date || inputs.loading_from_time) {
      moment(inputs.loading_from_date + " " + inputs.loading_from_time).format(
        format
      ) === "Invalid date" &&
        errors.push({ type: "error", string: "Loading from is invalid date" });
    }
    if (inputs.loading_to_date || inputs.loading_to_time) {
      moment(inputs.loading_to_date + " " + inputs.loading_to_time).format(
        format
      ) === "Invalid date" &&
        errors.push({ type: "error", string: "Loading to is invalid date" });
    }
    if (inputs.unloading_from_date || inputs.unloading_from_time) {
      moment(
        inputs.unloading_from_date + " " + inputs.unloading_from_time
      ).format(format) === "Invalid date" &&
        errors.push({
          type: "error",
          string: "Unloading from is invalid date",
        });
    }
    if (inputs.unloading_to_date || inputs.unloading_to_time) {
      moment(inputs.unloading_to_date + " " + inputs.unloading_to_time).format(
        format
      ) === "Invalid date" &&
        errors.push({ type: "error", string: "Unloading to is invalid date" });
    }

    if (errors.length === 0) {
      const datas = {
        ...myOrdersPagState,
        page: 1,
        loadingTime_from: inputs.loading_from_date
          ? moment(
              inputs.loading_from_date + " " + inputs.loading_from_time
            ).format(format)
          : null,
        loadingTime_to: inputs.loading_to_date
          ? moment(
              inputs.loading_to_date + " " + inputs.loading_to_time
            ).format(format)
          : null,
        unloadingTime_from: inputs.unloading_from_date
          ? moment(
              inputs.unloading_from_date + " " + inputs.unloading_from_time
            ).format(format)
          : null,
        unloadingTime_to: inputs.unloading_to_date
          ? moment(
              inputs.unloading_to_date + " " + inputs.unloading_to_time
            ).format(format)
          : null,
        loadingPostCode: isEmpty(inputs.loadingPostCode)
          ? null
          : inputs.loadingPostCode,
        loadingPostName: isEmpty(inputs.loadingPostName)
          ? null
          : inputs.loadingPostName,
        loadingCountry_id: isEmpty(inputs.loadingCountry_id)
          ? null
          : inputs.loadingCountry_id,
        unloadingPostCode: isEmpty(inputs.unloadingPostCode)
          ? null
          : inputs.unloadingPostCode,
        unloadingPostName: isEmpty(inputs.unloadingPostName)
          ? null
          : inputs.unloadingPostName,
        unloadingCountry_id: isEmpty(inputs.unloadingCountry_id)
          ? null
          : inputs.unloadingCountry_id,
        vehicleRegistrations: localVehicles.map((item) => item?.value),
        driver_ids: localDrivers.map((item) => Number(item?.value)),
        user_ids: localUsers.map((item) => Number(item?.value)),
        company_ids: localCompanies.map((item) => Number(item?.value)),
      };
      props.setSearchInput("");
      myOrdersPag({ ...datas, limit: myOrdersPagVar.limit });
      localStorage.setItem(
        LocalStorageKeys.MY_ORDERS_FILTER,
        JSON.stringify({ ...datas, desc: true, orderBy: "id" })
      );
      localStorage.setItem("vehicleIds", JSON.stringify(localVehicles));
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        myOrdersPag: null,
      });
    } else {
      responseMsgs(errors);
    }
  };

  const handleReset = () => {
    setInputs({
      ...inputs,
      loading_from_time: null,
      loading_from_date: null,
      loading_to_time: null,
      loading_to_date: null,
      unloading_from_time: null,
      unloading_from_date: null,
      unloading_to_time: null,
      unloading_to_date: null,
      loadingPostCode: "",
      loadingPostName: "",
      loadingCountry_id: "",
      loadingCountryName: "",
      unloadingPostCode: "",
      unloadingPostName: "",
      unloadingCountry_id: "",
      unloadingCountryName: "",
    });

    setLocalUsers([]);
    setLocalCompanies([]);
    setLocalDrivers([]);
    setLocalVehicles([]);

    setMyOrdersPagState({
      ...initialGetOrders,
      limit: myOrdersPagVar.limit,
      page: 1,
    });

    myOrdersPag({
      ...initialGetOrders,
      limit: myOrdersPagVar.limit,
      page: 1,
    });

    localStorage.removeItem(LocalStorageKeys.MY_ORDERS_FILTER);

    modalData({
      name: "",
      msg: "",
      returned_value: null,
      myOrdersPag: null,
    });
  };

  const handleStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: OrderStatus | "all"
  ) => {
    e.preventDefault();
    if (value === "all") {
      if (myOrdersPagState.status.length === StatusOptions().length) {
        setMyOrdersPagState({
          ...myOrdersPagState,
          page: 1,
          status: [],
        });
        return;
      } else {
        setMyOrdersPagState({
          ...myOrdersPagState,
          page: 1,
          status:
            StatusOptions()?.map(
              (status) => status?.value ?? OrderStatus.Draft
            ) ?? [],
        });
        return;
      }
    }

    if (myOrdersPagState.status.includes(value)) {
      const filteredStatus = myOrdersPagState.status.filter(
        (status: string) => status !== value
      );
      setMyOrdersPagState({
        ...myOrdersPagState,
        page: 1,
        status: filteredStatus,
      });
    } else {
      setMyOrdersPagState({
        ...myOrdersPagState,
        page: 1,
        status: [...myOrdersPagState.status, value],
      });
    }
  };

  useEffect(() => {
    if (isFilterTable) {
      setCollapse({
        date: false,
        users: false,
        partners: false,
        status: false,
        unloadingLocation: false,
        loadingLocation: false,
        other: false,
        vehicles: false,
        drivers: false,
      });

      setLocalUsers([]);
      setLocalCompanies([]);
      setLocalDrivers([]);
      setLocalVehicles([]);

      let storageQuery: any = localStorage.getItem(
        LocalStorageKeys.MY_ORDERS_FILTER
      );
      let storageVehicleIds = localStorage.getItem("vehicleIds");

      if (storageQuery) {
        storageQuery = JSON.parse(storageQuery);
        storageVehicleIds = JSON.parse(storageVehicleIds ?? "") ?? [];
        setMyOrdersPagState({
          ...storageQuery,
        });
        if (storageQuery?.loadingCountry_id) {
          setInputs({
            ...inputs,
            loadingCountry_id: storageQuery?.loadingCountry_id,
          });
          getLoadingCountry({
            variables: { id: storageQuery?.loadingCountry_id },
          });
        }
        if (storageQuery?.unloadingCountry_id) {
          setInputs({
            ...inputs,
            unloadingCountry_id: storageQuery?.unloadingCountry_id,
          });
          getUnloadingCountry({
            variables: { id: storageQuery?.unloadingCountry_id },
          });
        }
        if (
          storageQuery?.driver_ids &&
          isArray(storageQuery?.driver_ids) &&
          !isEmpty(storageQuery?.driver_ids)
        ) {
          getDrivers({
            variables: {
              query: {
                ids: storageQuery?.driver_ids,
              },
              id: me?.getUser?.company?.id ?? 0,
            },
          })?.then((res) => {
            setLocalDrivers(
              mapDriverOptions(res?.data?.getDrivers?.items ?? [])
            );
          });
        }
        if (
          storageQuery?.user_ids &&
          isArray(storageQuery?.user_ids) &&
          !isEmpty(storageQuery?.user_ids)
        ) {
          getUsers({
            variables: {
              query: {
                ids: storageQuery?.user_ids,
                company_id: me?.getUser.company?.id,
              },
            },
          })?.then((res) => {
            setLocalUsers(mapDriverOptions(res?.data?.getUsers?.items ?? []));
          });
        }
        if (
          storageQuery?.company_ids &&
          isArray(storageQuery?.company_ids) &&
          !isEmpty(storageQuery?.company_ids)
        ) {
          getCompanies({
            variables: {
              query: {
                ids: storageQuery?.company_ids,
              },
            },
          })?.then((res) => {
            setLocalCompanies(
              mapCompanyOptions(res?.data?.getCompanies?.items ?? [])
            );
          });
        }
        if (
          storageVehicleIds &&
          isArray(storageVehicleIds) &&
          !isEmpty(storageVehicleIds)
        ) {
          getVehicles({
            variables: {
              query: {
                ids: storageVehicleIds?.map((item: MultiSelectOption) =>
                  Number(item?.key)
                ),
              },
            },
          })?.then((res) => {
            setLocalVehicles(
              mapVehiclesOptions(res?.data?.getVehicles?.items ?? [])
            );
          });
        }
        setInputs({
          ...inputs,
          loading_from_time: storageQuery?.loadingTime_from
            ? moment(storageQuery?.loadingTime_from).format("HH:mm")
            : null,
          loading_from_date: storageQuery?.loadingTime_from
            ? moment(storageQuery?.loadingTime_from).format("YYYY-MM-DD")
            : null,
          loading_to_time: storageQuery?.loadingTime_to
            ? moment(storageQuery?.loadingTime_to).format("HH:mm")
            : null,
          loading_to_date: storageQuery?.loadingTime_to
            ? moment(storageQuery?.loadingTime_to).format("YYYY-MM-DD")
            : null,
          unloading_from_time: storageQuery?.unloadingTime_from
            ? moment(storageQuery?.unloadingTime_from).format("HH:mm")
            : null,
          unloading_from_date: storageQuery?.unloadingTime_from
            ? moment(storageQuery?.unloadingTime_from).format("YYYY-MM-DD")
            : null,
          unloading_to_time: storageQuery?.unloadingTime_to
            ? moment(storageQuery?.unloadingTime_to).format("HH:mm")
            : null,
          unloading_to_date: storageQuery?.unloadingTime_to
            ? moment(storageQuery?.unloadingTime_to).format("YYYY-MM-DD")
            : null,
          loadingPostCode: storageQuery.loadingPostCode,
          loadingPostName: storageQuery.loadingPostName,
          loadingCountry_id: storageQuery.loadingCountry_id,
          unloadingPostCode: storageQuery.unloadingPostCode,
          unloadingPostName: storageQuery.unloadingPostName,
          unloadingCountry_id: storageQuery.unloadingCountry_id,
        });
      } else {
        setMyOrdersPagState({
          ...initialGetOrders,
        });
        setInputs({
          ...inputs,
          loading_from_time: null,
          loading_from_date: null,
          loading_to_time: null,
          loading_to_date: null,
          unloading_from_time: null,
          unloading_from_date: null,
          unloading_to_time: null,
          unloading_to_date: null,
          loadingPostCode: "",
          loadingPostName: "",
          loadingCountry_id: "",
          loadingCountryName: "",
          unloadingPostCode: "",
          unloadingPostName: "",
          unloadingCountry_id: "",
          unloadingCountryName: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (savedLoadingCountry && isFilterTable) {
      setInputs({
        ...inputs,
        loadingCountryName: savedLoadingCountry.getCountry.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedLoadingCountry]);

  useEffect(() => {
    if (savedUnloadingCountry && isFilterTable) {
      setInputs({
        ...inputs,
        unloadingCountryName: savedUnloadingCountry.getCountry.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUnloadingCountry]);

  const queryLoadingCountries = useGetCountriesLazyQuery();
  const [isLoadingLocationDropdownOpen, setIsLoadingLocationDropdownOpen] =
    useState(false);
  const {
    data: loadingCountries,
    loading: loadingLoadingCountries,
    search: countryLoadingInput,
    setSearch: setLoadingCountryInput,
  } = useSearch(
    queryLoadingCountries,
    { limit: 3 },
    300,
    me && isLoadingLocationDropdownOpen
  );

  const queryUnloadingCountries = useGetCountriesLazyQuery();
  const [isUnloadingLocationDropdownOpen, setIsUnloadingLocationDropdownOpen] =
    useState(false);
  const {
    data: unloadingCountries,
    loading: loadingUnloadingCountries,
    search: countryUnloadingInput,
    setSearch: setUnloadingCountryInput,
  } = useSearch(
    queryUnloadingCountries,
    { limit: 3 },
    300,
    me && isUnloadingLocationDropdownOpen
  );

  const [isVehicleDropdownOpen, setIsVehicleDropdownOpen] =
    useState<boolean>(false);
  const queryVehicles = useGetVehiclesLazyQuery();
  const {
    data: vehicles,
    loading: loadingVehicles,
    search: searchVehicles,
    setSearch: setSearchVehicles,
  } = useSearch(
    queryVehicles,
    { limit: 15 },
    300,
    !!me && isVehicleDropdownOpen
  );

  const vehicleOptions = mapVehiclesOptions(vehicles?.getVehicles?.items ?? []);

  const [isDriversDropdownOpen, setIsDriversDropdownOpen] =
    useState<boolean>(false);
  const queryDrivers = useGetDriversLazyQuery();
  const {
    data: drivers,
    loading: loadingDrivers,
    search: searchDrivers,
    setSearch: setSearchDrivers,
  } = useSearch(
    queryDrivers,
    { limit: 15 },
    300,
    !!me && isDriversDropdownOpen,
    {
      id: me?.getUser.company?.id,
    }
  );

  const driverOptions = mapDriverOptions(drivers?.getDrivers?.items ?? []);

  const queryUsers = useGetUsersLazyQuery();
  const [isUsersDropdownOpen, setIsUsersDropdownOpen] =
    useState<boolean>(false);
  const {
    data: users,
    loading: loadingUsers,
    search: searchUsers,
    setSearch: setSearchUsers,
  } = useSearch(
    queryUsers,
    {
      limit: 15,
      company_id: me?.getUser.company?.id,
      status: [UserStatus.Active],
    },
    300,
    me && isUsersDropdownOpen
  );

  const userOptions = mapDriverOptions(users?.getUsers?.items ?? []);

  const queryPartners = useGetCompaniesLazyQuery();
  const [isPartnersDropdownOpen, setIsPartnersDropdownOpen] =
    useState<boolean>(false);
  const {
    data: partners,
    loading: loadingPartners,
    setSearch: setPartnersInput,
    search: partnersInput,
  } = useSearch(
    queryPartners,
    { limit: 15 },
    300,
    me && isPartnersDropdownOpen,
    { id: me?.getUser.company?.id! }
  );

  const partnerOptions =
    mapCompanyOptions(partners?.getCompanies?.items ?? []) ?? [];

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!isFilterTable}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          myOrdersPag: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {isFilterTable && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row d="column">
            <Col>
              <Text
                textSize="20"
                textAlign="left"
                textWeight="700"
                textColor="dark"
                m={{ x: "0rem", b: "0.5rem" }}
              >
                Segment your orders
              </Text>
            </Col>
            <Col>
              <Text
                textSize="14"
                textAlign="left"
                textWeight="500"
                textColor="semiDark"
                m={{ x: "0rem", b: "0rem" }}
              >
                Display only the orders based on your conditions.
              </Text>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        date: !collapse.date,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.date ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Date range
                    </Text>
                    {!collapse.date ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.date ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.date ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border></Border>
                  <Collapse isOpen={collapse.date}>
                    <Row>
                      <Col>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Loading from
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="loading_from_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.loading_from_time)) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        loading_from_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_from_time}
                                  type={
                                    inputs.loading_from_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Date"
                                  name="loading_from_date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.loading_from_date)) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        loading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_from_date}
                                  type={
                                    inputs.loading_from_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                              <Col size="1">
                                {(inputs.loading_from_time ||
                                  inputs.loading_from_date) && (
                                  <Div d="flex">
                                    <Button
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setInputs((prevState) => ({
                                          ...prevState,
                                          loading_from_time: "",
                                          loading_from_date: "",
                                        }));
                                      }}
                                      bg="transparent"
                                      p={0}
                                    >
                                      <Icon
                                        name="Cross"
                                        size="20px"
                                        color="semiDark"
                                      />
                                    </Button>
                                  </Div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Loading to
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="loading_to_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.loading_to_time)) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        loading_to_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_to_time}
                                  type={
                                    inputs.loading_to_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="loading_to_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.loading_to_date)) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        loading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.loading_to_date}
                                  type={
                                    inputs.loading_to_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                              <Col size="1">
                                {(inputs.loading_to_time ||
                                  inputs.loading_to_date) && (
                                  <Div d="flex">
                                    <Button
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setInputs((prevState) => ({
                                          ...prevState,
                                          loading_to_time: "",
                                          loading_to_date: "",
                                        }));
                                      }}
                                      bg="transparent"
                                      p={0}
                                    >
                                      <Icon
                                        name="Cross"
                                        size="20px"
                                        color="semiDark"
                                      />
                                    </Button>
                                  </Div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Unloading from
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="unloading_from_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.unloading_from_time)) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        unloading_from_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_from_time}
                                  type={
                                    inputs.unloading_from_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="unloading_from_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.unloading_from_date)) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        unloading_from_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_from_date}
                                  type={
                                    inputs.unloading_from_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                              <Col size="1">
                                {(inputs.unloading_from_time ||
                                  inputs.unloading_from_date) && (
                                  <Div d="flex">
                                    <Button
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setInputs((prevState) => ({
                                          ...prevState,
                                          unloading_from_time: "",
                                          unloading_from_date: "",
                                        }));
                                      }}
                                      bg="transparent"
                                      p={0}
                                    >
                                      <Icon
                                        name="Cross"
                                        size="20px"
                                        color="semiDark"
                                      />
                                    </Button>
                                  </Div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row d="vertical">
                          <Col>
                            <Text
                              textColor="semiDark"
                              textSize={12}
                              textWeight={500}
                              m={{ b: "0.25rem", t: "1rem" }}
                            >
                              Unloading to
                            </Text>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  placeholder="Time"
                                  name="unloading_to_time"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.unloading_to_time)) {
                                      e.target.type = "time";
                                      setInputs({
                                        ...inputs,
                                        unloading_to_time: moment(
                                          new Date().setHours(0, 0, 0, 0)
                                        ).format("HH:mm"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_to_time}
                                  type={
                                    inputs.unloading_to_time ? "time" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handleChange(e);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Input
                                  h="2.5rem"
                                  name="unloading_to_date"
                                  placeholder="Date"
                                  onFocus={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (isEmpty(inputs.unloading_to_date)) {
                                      e.target.type = "date";
                                      setInputs({
                                        ...inputs,
                                        unloading_to_date: moment(
                                          new Date()
                                        ).format("YYYY-MM-DD"),
                                      });
                                    }
                                  }}
                                  value={inputs.unloading_to_date}
                                  type={
                                    inputs.unloading_to_date ? "date" : "text"
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value !== "") {
                                      handleChange(e);
                                    }
                                  }}
                                />
                              </Col>
                              <Col size="1">
                                {(inputs.unloading_to_time ||
                                  inputs.unloading_to_date) && (
                                  <Div d="flex">
                                    <Button
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        setInputs((prevState) => ({
                                          ...prevState,
                                          unloading_to_time: "",
                                          unloading_to_date: "",
                                        }));
                                      }}
                                      bg="transparent"
                                      p={0}
                                    >
                                      <Icon
                                        name="Cross"
                                        size="20px"
                                        color="semiDark"
                                      />
                                    </Button>
                                  </Div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>

            <Col m={{ t: "0rem" }}>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        partners: !collapse.partners,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.partners ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Company
                    </Text>
                    {!collapse.partners ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.partners ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.partners ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.partners}
                    overflow={isPartnersDropdownOpen ? "visible" : "hidden"}
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        <MultiInputDropdown
                          onInputChange={(newValue) =>
                            setPartnersInput(newValue)
                          }
                          placeholder="Select company"
                          value={localCompanies}
                          inputValue={partnersInput}
                          isLoading={loadingPartners}
                          name="partners"
                          options={partnerOptions}
                          onChange={(newValue: any) =>
                            setLocalCompanies(newValue)
                          }
                          menuIsOpen={isPartnersDropdownOpen}
                          onMenuOpen={() => setIsPartnersDropdownOpen(true)}
                          onMenuClose={() => setIsPartnersDropdownOpen(false)}
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>

            {/* Users */}
            {(me?.getUser.roles![0].id === 2 ||
              me?.getUser.roles![0].id === 1 ||
              me?.getUser.roles![0].id === 6) && (
              <Col m={{ t: "0rem" }}>
                <Row>
                  <Col>
                    <Div
                      d="flex"
                      justify="space-between"
                      m={{ t: "2rem", b: "0.5rem" }}
                      cursor="pointer"
                      onClick={() =>
                        setCollapse({
                          ...collapse,
                          users: !collapse.users,
                        })
                      }
                    >
                      <Text
                        textColor={collapse.users ? "dark" : "semiDark"}
                        textSize={14}
                        textWeight={500}
                      >
                        Users
                      </Text>
                      {!collapse.users ? (
                        <Icon
                          name="Plus"
                          size="18px"
                          color={collapse.users ? "dark" : "semiDark"}
                        />
                      ) : (
                        <Icon
                          name="Minus"
                          size="18px"
                          color={collapse.users ? "dark" : "semiDark"}
                        />
                      )}
                    </Div>
                    <Border margin="0 0 0 0"></Border>
                    <Collapse
                      isOpen={collapse.users}
                      overflow={isUsersDropdownOpen ? "visible" : "hidden"}
                    >
                      <Row p={{ t: "1rem", x: "0" }}>
                        <Col>
                          <MultiInputDropdown
                            onInputChange={(newValue) =>
                              setSearchUsers(newValue)
                            }
                            placeholder="Select users"
                            value={localUsers}
                            inputValue={searchUsers}
                            isLoading={loadingUsers}
                            name="users"
                            options={userOptions}
                            onChange={(newValue: any) =>
                              setLocalUsers(newValue)
                            }
                            menuIsOpen={isUsersDropdownOpen}
                            onMenuOpen={() => setIsUsersDropdownOpen(true)}
                            onMenuClose={() => setIsUsersDropdownOpen(false)}
                          />
                        </Col>
                      </Row>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            )}
            {/* Drivers */}
            <Col m={{ t: "0rem" }}>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        drivers: !collapse.drivers,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.drivers ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Drivers
                    </Text>
                    {!collapse.drivers ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.drivers ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.drivers ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.drivers}
                    overflow={collapse.drivers ? "visible" : "hidden"}
                  >
                    <MultiInputDropdown
                      onInputChange={(newValue) => setSearchDrivers(newValue)}
                      placeholder="Select drivers"
                      value={localDrivers}
                      inputValue={searchDrivers}
                      isLoading={loadingDrivers}
                      name="drivers"
                      options={driverOptions}
                      onChange={(newValue: any) => setLocalDrivers(newValue)}
                      menuIsOpen={isDriversDropdownOpen}
                      onMenuOpen={() => setIsDriversDropdownOpen(true)}
                      onMenuClose={() => setIsDriversDropdownOpen(false)}
                    />
                  </Collapse>
                </Col>
              </Row>
            </Col>
            {/* Vehicles */}
            <Col m={{ t: "0rem" }}>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        vehicles: !collapse.vehicles,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.vehicles ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Vehicles
                    </Text>
                    {!collapse.vehicles ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.vehicles ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.vehicles ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.vehicles}
                    overflow={collapse.vehicles ? "visible" : "hidden"}
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        <MultiInputDropdown
                          onInputChange={(newValue) =>
                            setSearchVehicles(newValue)
                          }
                          placeholder="Select vehicles"
                          value={localVehicles}
                          inputValue={searchVehicles}
                          isLoading={loadingVehicles}
                          name="vehicles"
                          options={vehicleOptions}
                          onChange={(newValue: any) =>
                            setLocalVehicles(newValue)
                          }
                          menuIsOpen={isVehicleDropdownOpen}
                          onMenuOpen={() => setIsVehicleDropdownOpen(true)}
                          onMenuClose={() => setIsVehicleDropdownOpen(false)}
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
            {/* Status */}
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        status: !collapse.status,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.status ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Status
                    </Text>
                    {!collapse.status ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.status ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.status ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse isOpen={collapse.status} overflow={"hidden"}>
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col size="12">
                        <CheckBox
                          name="Select all"
                          check={
                            myOrdersPagState.status.length ===
                            StatusOptions().length
                          }
                          handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => handleStatus(e, "all")}
                        />
                      </Col>
                      {StatusOptions().map((status, i) => {
                        return (
                          <Col size="6" key={i}>
                            <CheckBox
                              name={status?.name}
                              check={myOrdersPagState.status.includes(
                                status!.value
                              )}
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                status?.value && handleStatus(e, status?.value)
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
            {/* ////// */}
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        loadingLocation: !collapse.loadingLocation,
                      })
                    }
                  >
                    <Text
                      textColor={collapse.loadingLocation ? "dark" : "semiDark"}
                      textSize={14}
                      textWeight={500}
                    >
                      Loading Location
                    </Text>
                    {!collapse.loadingLocation ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.loadingLocation ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.loadingLocation ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    isOpen={collapse.loadingLocation}
                    overflow={
                      isLoadingLocationDropdownOpen ? "visible" : "hidden"
                    }
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        {
                          <InputDropdown
                            addNew={false}
                            displayResult={
                              <Div d="flex" h="24px" align="center" w="100%">
                                <Text
                                  textColor={"dark"}
                                  textWeight="500"
                                  m={{ r: "auto", l: "0.5rem" }}
                                  textSize={14}
                                >
                                  {inputs.loadingCountryName
                                    ? inputs.loadingCountryName
                                    : `Select country name`}
                                </Text>
                                {!isEmpty(inputs?.loadingCountryName) && (
                                  <Button
                                    m={{ l: "auto" }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setInputs((prevState) => ({
                                        ...prevState,
                                        loadingCountryName: "",
                                        loadingCountry_id: "",
                                      }));
                                    }}
                                    bg="transparent"
                                  >
                                    <Icon
                                      name="Cross"
                                      size="20px"
                                      color="semiDark"
                                    />
                                  </Button>
                                )}
                              </Div>
                            }
                            isLoading={loadingLoadingCountries}
                            isOpen={isLoadingLocationDropdownOpen}
                            setIsOpen={setIsLoadingLocationDropdownOpen}
                            value={countryLoadingInput}
                            handleChange={setLoadingCountryInput}
                            menuList={
                              <>
                                {loadingCountries &&
                                  loadingCountries?.getCountries?.items?.map(
                                    (country: any, i: number) => (
                                      <Anchor
                                        d="flex"
                                        p={{ y: "0.5rem", x: "0.75rem" }}
                                        key={i}
                                        align="center"
                                        onClick={() => {
                                          setInputs({
                                            ...inputs,
                                            loadingCountryName: country.name,
                                            loadingCountry_id: country.id,
                                          });
                                          setLoadingCountryInput("");
                                          setIsLoadingLocationDropdownOpen(
                                            false
                                          );
                                        }}
                                      >
                                        <Text
                                          textColor="semiDark"
                                          hoverTextColor="dark"
                                        >
                                          {`${country?.name}`}
                                        </Text>
                                      </Anchor>
                                    )
                                  )}
                              </>
                            }
                          />
                        }
                      </Col>
                    </Row>
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col size="4">
                        {
                          <Input
                            placeholder="Post code"
                            h="2.5rem"
                            name="loadingPostCode"
                            value={inputs.loadingPostCode}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>

                      <Col size="8">
                        {
                          <Input
                            placeholder="Post name"
                            h="2.5rem"
                            name="loadingPostName"
                            value={inputs.loadingPostName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Div
                    d="flex"
                    justify="space-between"
                    m={{ t: "2rem", b: "0.5rem" }}
                    cursor="pointer"
                    onClick={() =>
                      setCollapse({
                        ...collapse,
                        unloadingLocation: !collapse.unloadingLocation,
                      })
                    }
                  >
                    <Text
                      textColor={
                        collapse.unloadingLocation ? "dark" : "semiDark"
                      }
                      textSize={14}
                      textWeight={500}
                    >
                      Unloading Location
                    </Text>
                    {!collapse.unloadingLocation ? (
                      <Icon
                        name="Plus"
                        size="18px"
                        color={collapse.unloadingLocation ? "dark" : "semiDark"}
                      />
                    ) : (
                      <Icon
                        name="Minus"
                        size="18px"
                        color={collapse.unloadingLocation ? "dark" : "semiDark"}
                      />
                    )}
                  </Div>
                  <Border margin="0 0 0 0"></Border>
                  <Collapse
                    m={{ b: "2rem" }}
                    isOpen={collapse.unloadingLocation}
                    overflow={
                      isUnloadingLocationDropdownOpen ? "visible" : "hidden"
                    }
                  >
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col>
                        {
                          <InputDropdown
                            addNew={false}
                            displayResult={
                              <Div d="flex" h="24px" align="center" w="100%">
                                <Text
                                  textColor={"dark"}
                                  textWeight="500"
                                  m={{ r: "auto", l: "0.5rem" }}
                                  textSize={14}
                                >
                                  {inputs.unloadingCountryName
                                    ? inputs.unloadingCountryName
                                    : `Select country name`}
                                </Text>
                                {!isEmpty(inputs.unloadingCountryName) && (
                                  <Button
                                    m={{ l: "auto" }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setInputs((prevState) => ({
                                        ...prevState,
                                        unloadingCountryName: "",
                                        unloadingCountry_id: "",
                                      }));
                                    }}
                                    bg="transparent"
                                  >
                                    <Icon
                                      name="Cross"
                                      size="20px"
                                      color="semiDark"
                                    />
                                  </Button>
                                )}
                              </Div>
                            }
                            isLoading={loadingUnloadingCountries}
                            isOpen={isUnloadingLocationDropdownOpen}
                            setIsOpen={setIsUnloadingLocationDropdownOpen}
                            value={countryUnloadingInput}
                            handleChange={setUnloadingCountryInput}
                            menuList={
                              <>
                                {unloadingCountries &&
                                  unloadingCountries?.getCountries?.items?.map(
                                    (country: any, i: number) => (
                                      <Anchor
                                        d="flex"
                                        p={{ y: "0.5rem", x: "0.75rem" }}
                                        key={i}
                                        align="center"
                                        onClick={() => {
                                          setInputs({
                                            ...inputs,
                                            unloadingCountryName: country.name,
                                            unloadingCountry_id: country.id,
                                          });
                                          setUnloadingCountryInput("");
                                          setIsUnloadingLocationDropdownOpen(
                                            false
                                          );
                                        }}
                                      >
                                        <Text
                                          textColor="semiDark"
                                          hoverTextColor="dark"
                                        >
                                          {`${country?.name}`}
                                        </Text>
                                      </Anchor>
                                    )
                                  )}
                              </>
                            }
                          />
                        }
                      </Col>
                    </Row>
                    <Row p={{ t: "1rem", x: "0" }}>
                      <Col size="4">
                        {
                          <Input
                            placeholder="Post code"
                            h="2.5rem"
                            name="unloadingPostCode"
                            value={inputs.unloadingPostCode}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>

                      <Col size="8">
                        {
                          <Input
                            placeholder="Post name"
                            h="2.5rem"
                            name="unloadingPostName"
                            value={inputs.unloadingPostName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(e);
                            }}
                          />
                        }
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={handleFilter}
                isLoading={false}
                text={"Apply filter"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={handleReset}
                isLoading={false}
                text={"Reset filter"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default FilterTable;

const CheckBox: React.FC<any> = ({ name, check, handleChange }) => {
  return (
    <Label
      align="center"
      textTransform="capitalize"
      textWeight="500"
      m={{ b: "0.5rem" }}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
    >
      <Checkbox checked={check} readOnly />
      {name}
    </Label>
  );
};
