import React, { useEffect, useState } from "react";
import { CSSProperties } from "styled-components";

interface Props {
  imageClassNames?: string;
  imageStyle?: CSSProperties;
  imageSrc: string;
  skeletonComponent: JSX.Element;
  imgComponent?: JSX.Element;
  isLoading?: boolean;
}

const PreloadingImage: React.FC<
  Props & React.ImgHTMLAttributes<HTMLImageElement>
> = ({
  imageClassNames,
  imageStyle,
  skeletonComponent,
  imageSrc,
  imgComponent,
  isLoading,
  ...props
}) => {
  const [imageLoaded, setImageLaoded] = useState(false);

  useEffect(() => {
    const newImg = new Image();
    newImg.onload = () => {
      setImageLaoded(true);
    };
    newImg.src = imageSrc;
  }, [imageSrc]);

  if (isLoading || imageLoaded) return skeletonComponent;

  if (imgComponent && !imageSrc) return imgComponent;

  return (
    <img
      className={imageClassNames}
      {...props}
      src={imageSrc}
      style={imageStyle ? imageStyle : {}}
      alt=""
    />
  );
};

export default PreloadingImage;
