import * as yup from "yup";

export const stepTwoValidation = yup.object().shape({
  type: yup.string().required({ name: "Type", code: "REQUIRED_FIELD" }),
  name: yup
    .string()
    .trim()
    .required({ name: "Company name", code: "REQUIRED_FIELD" }),
  address: yup
    .string()
    .trim()
    .required({ name: "Address", code: "REQUIRED_FIELD" }),
  city: yup.string().trim().required({ name: "City", code: "REQUIRED_FIELD" }),
  postalCode: yup
    .string()
    .trim()
    .required({ name: "Post code", code: "REQUIRED_FIELD" }),
  country: yup.object().shape({
    name: yup
      .string()
      .trim()
      .required({ name: "Country", code: "REQUIRED_FIELD" }),
  }),
  BIC: yup.string().trim().required({ name: "BIC", code: "REQUIRED_FIELD" }),
  timezone: yup
    .string()
    .trim()
    .required({ name: "Timezone", code: "REQUIRED_FIELD" }),
});
