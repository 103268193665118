import React from "react";
import { Icon, Tag, Text, Div } from "atomize";
import Tr from "../../../components/TableComponents/Tr";
import Td from "../../../components/TableComponents/Td";
import Moment from "react-moment";
import moment from "moment";
import { NotificationMessageStatus } from "../../../generated/graphql";
import renderIcon from "../../../assets/Icons";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import Skeleton from "../../../components/Skeleton/Skeleton";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
import { GlobalTheme } from "../../../components/GlobalTheme";
Moment.globalLocal = true;
const color: [string, string][] = [
  ["#F2FCFF", "#0A89B1"],
  ["#D9DDFF", "#2A3DE1"],
  ["#CFF8EB", "#00865A"],
];

const RemindersRow: React.FC<any> = ({ row, view }) => {
  const randomColor = color[Math.floor(Math.random() * color.length)];
  const status =
    row.status === NotificationMessageStatus.Scheduled ? (
      renderIcon("Schedule")
    ) : row.status === NotificationMessageStatus.Sent ? (
      <Icon m={{ l: "auto" }} name="Checked" size="20px" color="success" />
    ) : (
      "Draft"
    );
  return (
    <Tr
      styleTr={{
        backgroundColor:
          row.status === NotificationMessageStatus.Draft
            ? GlobalTheme.colors.grey
            : "none",
      }}
    >
      <Td width={`${view[0].colW}px`}>{row.text}</Td>
      <Td width={`${view[1].colW}px`}>
        <Div textColor={status === "Draft" ? "warning1" : "dark"}>{status}</Div>
      </Td>
      <Td width={`${view[2].colW}px`}>
        <Tag
          m={{ l: "-0.5rem" }}
          bg={randomColor[0]}
          textWeight="500"
          textSize={14}
        >
          <Text
            p={{ y: "0.25rem", x: "0.5rem" }}
            textSize="12"
            textColor={randomColor[1]}
          >
            {row.label}
          </Text>
        </Tag>
      </Td>
      <Td width={`${view[3].colW}px`}>
        {row.recipients.length === 0
          ? "None"
          : row.recipients.map((user: any) => (
              <Div m={{ r: "0.5rem" }}>
                <PreloadingImage
                  imageSrc={""}
                  skeletonComponent={
                    <Skeleton height="32px" width="32px" borderRadius="32px" />
                  }
                  imgComponent={
                    <ShortNameLogo
                      name={`${user.firstName} ${user.lastName}`}
                    />
                  }
                  imageClassNames="i_b f_l"
                  imageStyle={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "32px",
                    verticalAlign: "middle",
                  }}
                />
              </Div>
            ))}
      </Td>
      <Td width={`${view[4].colW}px`}>
        {row.scheduleDate === null
          ? "None"
          : moment(row.scheduleDate).format("D MMM YYYY - HH:mm")}
      </Td>
    </Tr>
  );
};

export default RemindersRow;
