import { Anchor, Col, Div, Text } from "atomize";
import { isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, FC, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { modalData } from "../../../App";
import DropDown from "../../../components/DropdownClassic/DropdownClassic";
import { GlobalTheme } from "../../../components/GlobalTheme";
import Skeleton from "../../../components/Skeleton/Skeleton";
import StatusBadge from "../../../components/StatusBadge/StatusBadge";
import {
  GetActivityLogsDocument,
  GetOrderDocument,
  GetOrderQuery,
  OrderStatus,
} from "../../../generated/graphql";
import {
  StatusOption,
  StatusOptions,
  toReadValue,
  toReadValueReturnBg,
  toReadValueReturnColor,
} from "../../../helpers/functions";

interface Props {
  isLoading: boolean;
  updateOrderStatusLoading: boolean;
  order: GetOrderQuery | undefined;
  statusDropdown: boolean;
  setStatusDropdown: Dispatch<SetStateAction<boolean>>;
  updateOrderStatus: (item: any) => void;
}

const StatusDropdownColumn: FC<Props> = ({
  isLoading,
  updateOrderStatusLoading,
  order,
  statusDropdown,
  setStatusDropdown,
  updateOrderStatus,
}) => {
  const history = useHistory();

  const statusOptions = StatusOptions();

  return (
    <Col>
      <Text
        textSize={12}
        textColor="semiDark"
        textWeight="500"
        m={{ t: "1rem", b: "4px" }}
      >
        Status
      </Text>
      {isLoading || updateOrderStatusLoading ? (
        <Skeleton width="100%" height="40px" />
      ) : (
        order?.getOrder.status && (
          <DropDown
            padding={"0  0.75rem"}
            h="40px"
            isLocked={statusOptions.length === 0}
            isOpen={statusDropdown}
            menuList={
              <Div
                shadow={GlobalTheme.shadows[4]}
                style={{
                  background: GlobalTheme.colors.background,
                  padding: "0 0.75rem",
                  width: "100%",
                  borderRadius: GlobalTheme.rounded[4],
                }}
                tabIndex={0}
              >
                {statusOptions.map((status: StatusOption, index: number) => {
                  if (status === undefined || isEmpty(status)) return null;

                  return (
                    <Div d="flex" align="center" key={index}>
                      <StatusBadge
                        color={status.color}
                        bgColor={status.bgColor}
                        minus={
                          status.value === OrderStatus.Cancelled ||
                          status.value === OrderStatus.Damaged
                        }
                      />
                      <Anchor
                        textSize={142}
                        m={{ r: "auto", l: "0.5rem" }}
                        textColor="semiDark"
                        hoverTextColor="dark"
                        textTransform="capitalize"
                        key={index}
                        onClick={() => {
                          setStatusDropdown(false);
                          if (
                            !(
                              order.getOrder.marketExpirationDate >
                              moment().format()
                            ) &&
                            status.value === OrderStatus.OnMarket
                          ) {
                            modalData({
                              msg: "Order expired",
                              name: "confirmModal",
                              returned_value: null,
                              props: {
                                icon: "AlertSolid",
                                iconColor: "warning700",
                                subtext:
                                  "Your order expiration date has expired. To receive bids on this order, you need to update the expiration date.",
                                isLoading: false,
                                handleSubmit: () => {
                                  history.push(
                                    `/createneworder/order/${order?.getOrder.code}`
                                  );
                                  modalData({
                                    name: "",
                                    msg: "",
                                    returned_value: null,
                                    props: null,
                                  });
                                },
                                primaryBtn: "Update Order",
                                secondaryBtn: "Cancel",
                              },
                            });
                          } else {
                            updateOrderStatus({
                              variables: {
                                status: status.value,
                                id: Number(order?.getOrder.id),
                              },
                              refetchQueries: [
                                GetOrderDocument,
                                GetActivityLogsDocument,
                              ],
                              awaitRefetchQueries: true,
                            });
                          }
                        }}
                      >
                        {status.name}
                      </Anchor>
                    </Div>
                  );
                })}
              </Div>
            }
            setIsOpen={setStatusDropdown}
          >
            <StatusBadge
              color={toReadValueReturnColor(order.getOrder.status)}
              bgColor={toReadValueReturnBg(order.getOrder.status)}
              minus={
                order.getOrder.status === OrderStatus.Cancelled ||
                order.getOrder.status === OrderStatus.Damaged
              }
            />
            <Text
              textColor={"dark"}
              textSize={16}
              m={{ r: "auto", l: "0.5rem" }}
              textTransform="capitalize"
            >
              {toReadValue(order.getOrder.status)}
            </Text>
          </DropDown>
        )
      )}
    </Col>
  );
};

export default StatusDropdownColumn;
