import { Div, Icon, Text } from "atomize";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import renderIcon from "../../assets/Icons";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import PrimaryBtn from "../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../SecondaryBtn/SecondaryBtn";

interface Props {
  isOpen: boolean;
  isExpiredInsurance?: boolean;
}

const CompanyInsuranceAlert: FC<Props> = ({ isOpen, isExpiredInsurance }) => {
  const { width } = useWindowDimensions();
  const [showNotification, setShowNotification] = useState(isOpen);

  const history = useHistory();
  const location = useLocation();

  const handleDismiss = () => {
    setShowNotification(false);
    setTimeout(() => {
      setShowNotification(isOpen);
    }, 30000);
  };

  const handleAddDocument = () => {
    setShowNotification(false);
    history.push("/settings/company");
  };

  useEffect(() => {
    if (location?.pathname === "/settings/company") {
      setShowNotification(false);
    } else {
      setShowNotification(isOpen);
    }
  }, [isOpen, location?.pathname]);

  const isSmallScreen = width > 980;

  if (!showNotification) {
    return null;
  }

  return (
    <Div
      p="0.75rem 1rem"
      bg="washedOrange"
      shadow="3"
      d="flex"
      m={{ b: "1rem" }}
      justify={"flex-start"}
      flexDir={isSmallScreen ? "row" : "column"}
      style={{
        alignItems: isSmallScreen ? "center" : "flex-start",
        position: "fixed",
        zIndex: 9999,
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <Div d="flex" justify="center" align="center" m={{ r: "1rem" }}>
        <Div
          d="flex"
          w="30px"
          h="30px"
          justify="center"
          align="center"
          m={{ r: "14px" }}
        >
          {renderIcon("WarningRed")}
        </Div>
        <Div>
          <Div d="flex" flexWrap="wrap">
            <Text>
              {isExpiredInsurance
                ? "Your Insurance Certificate has expired. Please update it in the company settings."
                : "Add your Insurance Certificate to activate your company account."}
            </Text>
          </Div>
        </Div>
      </Div>
      <Div
        d="flex"
        m={{
          l: isSmallScreen ? "auto" : "0",
          r: isSmallScreen ? "0" : "auto",
          t: width <= 980 && "1rem",
        }}
      >
        <PrimaryBtn
          handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleAddDocument();
          }}
          prefixIcon={
            <Icon
              name={isExpiredInsurance ? "Rename" : "Plus"}
              m={{ l: "0px", r: "5px" }}
              size="20px"
              color="background"
            />
          }
          isLoading={false}
          h="2rem"
          styleBtn={{
            display: "flex",
            justifyContent: "left",
            margin: "0 8px 0 0",
            whiteSpace: "nowrap",
          }}
          text={isExpiredInsurance ? "Update" : "Add document"}
          textSize={12}
        />
        <SecondaryBtn
          styleBtn={{
            maxWidth: "144px",
            whiteSpace: "nowrap",
          }}
          h="2rem"
          handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleDismiss();
          }}
          isLoading={false}
          text="Dismiss"
        />
      </Div>
    </Div>
  );
};

export default CompanyInsuranceAlert;
