export const DocumentInputAcceptExtensions: string[] = [
  ".doc",
  ".docx",
  ".xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/png",
  "image/jpeg",
  "image/gif",
  ".xls",
  ".xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.presentationml.slide",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.ms-powerpoint",
];

export const LocalStorageKeys = {
  LIMIT: "limit",
  ORDER_BY: "orderBy",
  DESC: "desc",
  MY_ORDERS_VIEW: "myordersview",
  MY_ORDERS_FILTER: "myordersfilter",
  PLACEHOLDER_COLORS: "placeholderColors",
  TOKEN: "token",
  FILTER_MARKET_TABLE_LINK: "filterMarketTableLink",
  FILTER_MARKET_TABLE_PAG: "filterMarketTablePag",
  LOCAL_STORAGE_VERSION_KEY: "localStorageVersion",
};
