import React, { useState, useEffect } from "react";
import { Row, Col, Text, Icon, Div, Input, Tag } from "atomize";
import Table from "../../components/TableComponents/Table";
import Th from "../../components/TableComponents/Th";
import Tr from "../../components/TableComponents/Tr";
import SortIcon from "../../components/SortIcon/SortIcon";
import Section from "../../components/Section/Section";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  CompanyStatus,
  useGetMeQuery,
  NotificationMessageQuery,
  useGetNotificationMessagesQuery,
} from "../../generated/graphql";
import Thead from "../../components/TableComponents/Thead";
import Tbody from "../../components/TableComponents/Tbody";
import renderIcon from "../../assets/Icons";
import Pagination from "../../components/Pagination/Pagination";
import { initialGetNotificatinMessages } from "../../helpers/initialData";
import RemindersRow from "./rows/RemindersRow";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import Td from "../../components/TableComponents/Td";
import RemindersRowActions from "./rows/RemindersRowActions";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { modalData } from "../../App";

const initialView = [
  { field: "text", name: "Message", sort: false, colW: 80 }, //tuki
  { field: "status", name: "Status", sort: false, colW: 100 },
  { field: "label", name: "Label", sort: false, colW: 100 },
  { field: "recipients", name: "Recipients", sort: false, colW: 100 },
  { field: "scheduleDate", name: "Date", sort: false, colW: 170 },
];

const Reminders: React.FC = () => {
  const { height, width } = useWindowDimensions();
  const [notificationsMessagePags, setNotificationsMessagePags] = useState<
    NotificationMessageQuery | any
  >({
    ...initialGetNotificatinMessages,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });

  const { data: me, loading: meLoading } = useGetMeQuery();

  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState("");

  const {
    data: notification,
    loading: notificationLoading,
    error: notificationError,
    refetch: refetchMarket,
  } = useGetNotificationMessagesQuery({
    variables: { query: { ...notificationsMessagePags } },
    fetchPolicy: "cache-and-network",
  });

  ErrorsBeHandler({
    error: notificationError,
  });

  useDebouncedEffect(
    () =>
      setNotificationsMessagePags({
        ...notificationsMessagePags,
        search: searchInput,
      }),
    [searchInput],
    searchInput === "" ? 0 : 300
  );

  useEffect(() => {
    if (notification && notificationsMessagePags.limit) {
      setTotalPages(
        Math.ceil(
          notification.getNotificationMessages.total! /
            notificationsMessagePags.limit
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification?.getNotificationMessages]);

  return (
    <Div h="100%">
      <Div
        d="flex"
        m={{ y: "0rem", x: "0.5rem" }}
        h="72px"
        overflow="hidden"
        align="center"
      >
        <Div w="100%">
          <Input
            h="32px"
            w="100%"
            p={{ x: "2rem" }}
            borderColor="greyBorder"
            textSize="12"
            placeholder="Search..."
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.target.value)
            }
            prefix={
              <Icon
                name="Search"
                size="16px"
                cursor="pointer"
                pos="absolute"
                color="semiDark"
                top="50%"
                left="0.5rem"
                transform="translateY(-50%)"
              />
            }
          />
        </Div>
        <Div d="flex" justify="flex-end">
          {notificationLoading || meLoading ? (
            <Skeleton height="2rem" width="40px" margin="0 0 0 0.5rem" />
          ) : (
            <SecondaryBtn
              w="40px"
              h="2rem"
              handleSubmit={() => refetchMarket()}
              styleBtn={{ marginLeft: "0.5rem" }}
              isLoading={false}
              text={<Icon name="Refresh" size="16px" color="semiDark" />}
            />
          )}
          {meLoading || notificationLoading ? (
            <Skeleton height="2rem" width="135px" margin="0 0  0 0.5rem" />
          ) : (
            <SecondaryBtn
              w="135px"
              h="2rem"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                modalData({
                  msg: "New reminder",
                  name: "newReminder",
                  returned_value: null,
                  props: {
                    timezone: me?.getUser.company?.location?.timezone,
                    company_id: me?.getUser.company?.id,
                  },
                })
              }
              prefixIcon={
                <Icon
                  m={{ r: "6px" }}
                  name="Add"
                  size="18px"
                  color="semiDark"
                />
              }
              isLoading={meLoading}
              styleBtn={{
                display: "flex",
                justifyContent: "left",
                margin: "0 0 0 0.5rem",
              }}
              text={"Add reminder"}
              textSize={12}
            />
          )}
        </Div>
      </Div>
      {notificationLoading && notificationsMessagePags?.limit ? (
        <Section margin="0rem 0.5rem">
          <Skeleton
            classN="table_skeleton"
            height={`${(notificationsMessagePags?.limit + 1) * 49 + 10}px`}
          />
        </Section>
      ) : (
        <>
          {notification?.getNotificationMessages.total &&
          Number(notification?.getNotificationMessages!.total) > 0 ? (
            <Section margin="0rem 0.5rem">
              <Table width={`calc(100% -  ${notificationLoading ? "0" : "0"})`}>
                <Tbody maxH="none">
                  <Tr hidBorderLast={true} className="border_none">
                    <Td width={"auto"} className="divider">
                      <Table>
                        <Thead>
                          <Tr className="head">
                            {initialView.map((col, i) => {
                              return (
                                <Th width={`${col.colW}px`} key={i}>
                                  <Text d="inline!important">{col.name}</Text>
                                  {col.sort && (
                                    <SortIcon
                                      sortBy={notificationsMessagePags}
                                      name={col.field}
                                      setSortBy={setNotificationsMessagePags}
                                    />
                                  )}
                                </Th>
                              );
                            })}
                          </Tr>
                        </Thead>
                        <Tbody maxH="none">
                          {notification?.getNotificationMessages.items?.map(
                            (row: any, i: number) => (
                              <RemindersRow
                                query={{ ...notificationsMessagePags }}
                                row={row}
                                view={initialView}
                                key={i}
                              />
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Td>
                    {!notificationLoading && (
                      <Td width="70px" className="divider_right no_p">
                        <Table width="auto">
                          <Thead>
                            <Tr>
                              <Td className="head"></Td>
                            </Tr>
                          </Thead>
                          <Tbody maxH="none">
                            {notification?.getNotificationMessages.items?.map(
                              (row: any, i: number) => (
                                <RemindersRowActions reminder={row} key={i} />
                              )
                            )}
                          </Tbody>
                        </Table>
                      </Td>
                    )}
                  </Tr>
                </Tbody>
              </Table>
            </Section>
          ) : (
            ""
          )}
        </>
      )}
      {notification?.getNotificationMessages.total === 0 &&
        !notificationLoading && (
          <>
            {searchInput ? (
              <Row m={{ x: "0rem", t: "0rem" }}>
                <Col>
                  <Tag
                    p={{ l: "1rem" }}
                    h="48px"
                    w="100%"
                    d="flex"
                    justify="start"
                    textColor="light"
                  >
                    We couldn’t find anything related to{" "}
                    {notificationsMessagePags.search}.
                  </Tag>
                </Col>
              </Row>
            ) : (
              <Row
                maxW="440px"
                h={`${height - 162}px`}
                align="center"
                p="32px 0px"
                d="flex"
                m={{ x: "auto" }}
              >
                <Col d="flex" flexDir="column" align="center">
                  <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                    {renderIcon("GreenBell")}
                  </Div>
                  <Text
                    textColor="dark"
                    textWeight="500"
                    textSize={20}
                    textAlign="center"
                    m={{ y: "2rem" }}
                  >
                    You don’t have any reminders yet.
                  </Text>
                  <Text
                    textColor="semiDark"
                    textWeight="400"
                    textSize={14}
                    textAlign="center"
                  >
                    With reminders you gain an ability to send custom alerts to
                    any team member. This way you can bring attention to
                    important events within your operations.
                  </Text>

                  {me?.getUser.company?.status === CompanyStatus.Active && (
                    <PrimaryBtn
                      w="100%"
                      handleSubmit={(
                        e: React.ChangeEvent<HTMLButtonElement>
                      ) => {
                        e.preventDefault();
                        modalData({
                          msg: "New reminder",
                          name: "newReminder",
                          returned_value: null,
                          props: {
                            timezone: me?.getUser.company?.location?.timezone,
                            company_id: me.getUser.company?.id,
                          },
                        });
                      }}
                      styleBtn={{ marginTop: "1.5rem" }}
                      isLoading={false}
                      text={"Create your first reminder"}
                    />
                  )}
                </Col>
              </Row>
            )}
          </>
        )}
      {totalPages ? (
        <Row m={{ x: "0" }}>
          <Col>
            <Pagination
              setQuery={setNotificationsMessagePags}
              query={notificationsMessagePags}
              total={totalPages}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Div>
  );
};

export default Reminders;
