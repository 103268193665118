import React from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Tag } from "atomize";
import PreloadingImage from "../PreloadingImage/PreloadingImage";
import Skeleton from "../Skeleton/Skeleton";
import { Document, DocumentType } from "../../generated/graphql";
import ShortNameLogo from "../ShortNameLogo/ShortNameLogo";
import moment from "moment";

const OrderDocumentRow: React.FC<{ document: Document }> = ({ document }) => {
  return (
    <>
      <Tr hidBorderLast={true}>
        <Td width={"20%"}>
          <Text textColor={"dark"}>{document.code}</Text>
        </Td>
        <Td width={"20%"}>
          <Text textColor={"dark"}>
            {getTypeTag(document?.type as DocumentType)}
          </Text>
        </Td>
        <Td width={"40%"}>
          {document.company === null ? (
            "None"
          ) : (
            <>
              <PreloadingImage
                imageSrc={document?.company?.logoPath as string}
                skeletonComponent={
                  <Skeleton height="32px" width="32px" borderRadius="32px" />
                }
                imgComponent={
                  <ShortNameLogo name={document?.company?.name as string} />
                }
                imageClassNames="i_b f_l"
                imageStyle={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  verticalAlign: "middle",
                }}
              />
              <Text
                textColor={"semiDark"}
                m={{ l: "0.75rem" }}
                className="i_b"
                style={{ verticalAlign: "middle" }}
                textSize={142}
              >
                {document?.company?.name as string}
              </Text>
            </>
          )}
        </Td>
        <Td width={"20%"}>
          {document.date === null
            ? "None"
            : moment(document.date).format("D MMM YYYY")}
        </Td>
      </Tr>
    </>
  );
};

export default OrderDocumentRow;

function getTypeTag(type: DocumentType) {
  switch (type) {
    case DocumentType.Invoice:
      return (
        <Tag bg="#F2FCFF" style={{ lineHeight: "24px" }}>
          <Text textColor="#0A89B1" textSize={12}>
            Invoice
          </Text>
        </Tag>
      );
    case DocumentType.Pod:
      return (
        <Tag bg="#EAFCF7">
          <Text textColor="#006242" textSize={12}>
            Proof of delivery
          </Text>
        </Tag>
      );
    default:
      return (
        <Tag>
          <Text textSize={12}>{type}</Text>
        </Tag>
      );
  }
}
