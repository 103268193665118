import styled from "styled-components";

export const App = styled.div`
  input::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.semiLight}!important;
    opacity: mone;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer */
    color: ${(props) => props.theme.colors.semiLight}!important;
    opacity: mone;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.semiLight}!important;
    opacity: mone;
  }

  input[type="date"],
  input[type="time"] {
    -webkit-appearance: none;
    -webkit-min-logical-width: 100%;
  }

  textarea::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.semiLight}!important;
    font-family: ${(props) => props.theme.fontFamily.primary}!important;
    opacity: mone;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: ${(props) => props.theme.colors.semiLight}!important;
    font-family: ${(props) => props.theme.fontFamily.primary}!important;
    opacity: mone;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(props) => props.theme.colors.semiLight}!important;
    font-family: ${(props) => props.theme.fontFamily.primary}!important;
    opacity: mone;
  }

  textarea:-ms-input-placeholder {
    color: ${(props) => props.theme.colors.semiLight}!important;
    font-family: ${(props) => props.theme.fontFamily.primary}!important;
    opacity: mone;
  }

  textarea::placeholder {
    color: ${(props) => props.theme.colors.semiLight}!important;
    font-family: ${(props) => props.theme.fontFamily.primary}!important;
    opacity: mone;
  }

  .horizontalScroll::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralGrey};
    height: 5px;
    border-radius: ${(props) => props.theme.rounded[2]};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }

  .horizontalScroll::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  .horizontalScroll::-webkit-scrollbar-track {
    width: 100%;
    height: 10px;
    background: ${(props) => props.theme.colors.grey};
  }

  .horizontalScroll::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.semiLight};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }

  .group-order-remove {
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
  }
  .group-order-top {
    &:hover {
      & .group-order-remove, & .group-order-menu {
        opacity: 1;
      }
    }
    &:active {
      border: none;
    }
  }
  .order-itinerary-edit {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .order-itinerary:hover .order-itinerary-edit {
    opacity: 1;
  }
`;
