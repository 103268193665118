import React, { ReactElement } from "react";
import { Button } from "atomize";
import { CSSProperties } from "styled-components";

interface TertiaryBtnProps {
  handleSubmit?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  text?: string | ReactElement;
  w?: string;
  h?: string;
  textSize?: number;
  styleBtn?: CSSProperties;
  prefixIcon?: ReactElement;
  suffixIcon?: ReactElement;
  disabled?: boolean;
}

const TertiaryBtn: React.FC<TertiaryBtnProps> = ({
  textSize = 14,
  styleBtn,
  prefixIcon,
  disabled = false,
  handleSubmit,
  isLoading,
  text,
  w = "100%",
  h = "48px",
}) => {
  return (
    <Button
      h={h}
      w={w}
      prefix={prefixIcon}
      bg="white"
      hoverBg="brand100"
      border="1px solid"
      borderColor="#7288FF"
      color="info700"
      hovercolor="info900"
      textColor="info700"
      hoverShadow="2"
      textSize={textSize}
      disabled={disabled || isLoading}
      style={{ ...styleBtn }}
      onClick={handleSubmit}
    >
      {text}
    </Button>
  );
};

export default TertiaryBtn;
