import React, { useState, useEffect } from "react";
import { Row, Col, Text, Icon, Div, Input, Tag } from "atomize";
import Table from "../../components/TableComponents/Table";
import Th from "../../components/TableComponents/Th";
import Tr from "../../components/TableComponents/Tr";
import SortIcon from "../../components/SortIcon/SortIcon";
import Section from "../../components/Section/Section";
import Skeleton from "../../components/Skeleton/Skeleton";
import {
  CompanyStatus,
  useGetMeQuery,
  useGetDocumentsQuery,
  DocumentQuery,
} from "../../generated/graphql";
import Thead from "../../components/TableComponents/Thead";
import Tbody from "../../components/TableComponents/Tbody";
import { useHistory } from "react-router-dom";
import renderIcon from "../../assets/Icons";
import Pagination from "../../components/Pagination/Pagination";
import { initialGetDocuments } from "../../helpers/initialData";
import DocumentRow from "./rows/DocumentRow";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import Td from "../../components/TableComponents/Td";
import DocumentsRowActions from "./rows/DocumentRowActions";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";

const initialView = [
  { field: "type", name: "Type", sort: true, colW: 80 },
  { field: "code", name: "Document ID", sort: true, colW: 100 },
  { field: "order_code", name: "Order ID", sort: true, colW: 100 },
  { field: "company", name: "Creator", sort: true, colW: 150 },
  { field: "date", name: "Date created", sort: true, colW: 100 },
];

const Documents: React.FC = () => {
  const { height, width } = useWindowDimensions();
  const [documentsPags, setDocumentsPags] = useState<DocumentQuery | any>({
    ...initialGetDocuments,
    limit: Math.floor((height - (width > 700 ? 200 : 250)) / 49),
  });
  const history = useHistory();

  const { data: me, loading: meLoading } = useGetMeQuery();

  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const [documentItems, setDocumentItems] = useState<any>(null)

  const {
    data: documents,
    loading: documentsLoading,
    error: documentsError,
    refetch: refetchMarket,
  } = useGetDocumentsQuery({
    variables: { query: { ...documentsPags, company_id: me?.getUser?.company_id! } },
    fetchPolicy: "cache-and-network",
  });

  ErrorsBeHandler({
    error: documentsError,
  });

  useDebouncedEffect(
    () =>
      setDocumentsPags({
        ...documentsPags,
        search: searchInput,
      }),
    [searchInput],
    searchInput === "" ? 0 : 300
  );

  useEffect(() => {
    if (documents && documentsPags.limit) {
      setTotalPages(
        Math.ceil(documents.getDocuments.total! / documentsPags.limit)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents?.getDocuments]);

  useEffect(() => {
    if (documents?.getDocuments?.items && documents?.getDocuments?.items.length) {
      setDocumentItems(documents.getDocuments.items.filter((document) => document.company_id === me?.getUser?.company_id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents?.getDocuments, me?.getUser])

  return (
    <Div h="100%">
      <Div
        d="flex"
        m={{ y: "0rem", x: "0.5rem" }}
        h="72px"
        overflow="hidden"
        align="center"
      >
        <Div w="100%">
          <Input
            h="32px"
            w="100%"
            p={{ x: "2rem" }}
            borderColor="greyBorder"
            textSize="12"
            placeholder="Search..."
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.target.value)
            }
            prefix={
              <Icon
                name="Search"
                size="16px"
                cursor="pointer"
                pos="absolute"
                color="semiDark"
                top="50%"
                left="0.5rem"
                transform="translateY(-50%)"
              />
            }
          />
        </Div>
        <Div d="flex" justify="flex-end">
          {documentsLoading || meLoading ? (
            <Skeleton height="2rem" width="40px" margin="0 0 0 0.5rem" />
          ) : (
            <SecondaryBtn
              w="40px"
              h="2rem"
              handleSubmit={() => refetchMarket()}
              styleBtn={{ marginLeft: "0.5rem" }}
              isLoading={false}
              text={<Icon name="Refresh" size="16px" color="semiDark" />}
            />
          )}
          {meLoading || documentsLoading ? (
            <Skeleton height="2rem" width="135px" margin="0 0  0 0.5rem" />
          ) : (
            <PrimaryBtn
              w="135px"
              h="2rem"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                history.push("/createdocument")
              }
              prefixIcon={
                <Icon
                  m={{ r: "6px" }}
                  name="Add"
                  size="18px"
                  color="background"
                />
              }
              isLoading={meLoading}
              styleBtn={{
                display: "flex",
                justifyContent: "left",
                margin: "0 0 0 0.5rem",
              }}
              text={"Add document"}
              textSize={12}
            />
          )}
        </Div>
      </Div>
      {documentsLoading && documentsPags?.limit ? (
        <Section margin="0rem 0.5rem">
          <Skeleton
            classN="table_skeleton"
            height={`${(documentsPags?.limit + 1) * 49 + 10}px`}
          />
        </Section>
      ) : (
        <>
          {documentItems?.length ? (
            <Section margin="0rem 0.5rem">
              <Table width={`calc(100% -  ${documentsLoading ? "0" : "0"})`}>
                <Tbody maxH="none">
                  <Tr hidBorderLast={true} className="border_none">
                    <Td width={"auto"} className="divider">
                      <Table>
                        <Thead>
                          <Tr className="head">
                            {initialView.map((col, i) => {
                              return (
                                <Th width={`${col.colW}px`} key={i}>
                                  <Text d="inline!important">{col.name}</Text>
                                  {col.sort && (
                                    <SortIcon
                                      sortBy={documentsPags}
                                      name={col.field}
                                      setSortBy={setDocumentsPags}
                                    />
                                  )}
                                </Th>
                              );
                            })}
                          </Tr>
                        </Thead>
                        <Tbody maxH="none">
                          {documentItems?.map(
                            (row: any, i: number) => (
                              <DocumentRow
                                query={{ ...documentsPags }}
                                row={row}
                                view={initialView}
                                key={i}
                              />
                            )
                          )}
                        </Tbody>
                      </Table>
                    </Td>
                    {!documentsLoading && (
                      <Td width="50px" className="divider_right no_p">
                        <Table width="auto">
                          <Thead>
                            <Tr>
                              <Td className="head"></Td>
                            </Tr>
                          </Thead>
                          <Tbody maxH="none">
                            {documentItems?.map(
                              (row: any, i: number) => (
                                <DocumentsRowActions document={row} key={i} />
                              )
                            )}
                          </Tbody>
                        </Table>
                      </Td>
                    )}
                  </Tr>
                </Tbody>
              </Table>
            </Section>
          ) : (
            ""
          )}
        </>
      )}
      {documentItems?.length === 0 && !documentsLoading && (
        <>
          {searchInput ? (
            <Row m={{ x: "0rem", t: "0rem" }}>
              <Col>
                <Tag
                  p={{ l: "1rem" }}
                  h="48px"
                  w="100%"
                  d="flex"
                  justify="start"
                  textColor="light"
                >
                  We couldn’t find anything related to {documentsPags.search}.
                </Tag>
              </Col>
            </Row>
          ) : (
            <Row
              maxW="440px"
              h={`${height - 162}px`}
              align="center"
              p="32px 0px"
              d="flex"
              m={{ x: "auto" }}
            >
              <Col d="flex" flexDir="column" align="center">
                <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                  {renderIcon("AddOrder")}
                </Div>
                <Text
                  textColor="dark"
                  textWeight="500"
                  textSize={16}
                  textAlign="center"
                >
                  {" "}
                  You have not made any documents on Cargobite.
                </Text>

                {me?.getUser.company?.status === CompanyStatus.Active && (
                  <PrimaryBtn
                    w="100%"
                    handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      history.push("/createdocument");
                    }}
                    styleBtn={{ marginTop: "1.5rem" }}
                    isLoading={false}
                    text={"Create a new document"}
                  />
                )}
              </Col>
            </Row>
          )}
        </>
      )}
      {totalPages ? (
        <Row m={{ x: "0" }}>
          <Col>
            <Pagination
              setQuery={setDocumentsPags}
              query={documentsPags}
              total={totalPages}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Div>
  );
};

export default Documents;
