import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Row, Text } from "atomize";
import moment from "moment";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData, responseMsgs } from "../../../App";
import { useUpdateLocationPointLoadingDateMutation } from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const initial = {
  loadingDate: "",
  loadingTime: "",
};

const schema = yup.object().shape({
  loadingTime: yup.string().required({
    name: "Loading time",
    code: "REQUIRED_FIELD",
  }),
  loadingDate: yup.string().required({
    name: "Loading date",
    code: "REQUIRED_FIELD",
  }),
});

const RequestTransportChange: React.FC = () => {
  const { width } = useWindowDimensions();

  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);

  const [updateLoadingDate, { data, error, loading }] =
    useUpdateLocationPointLoadingDateMutation({
      onCompleted: () => {
        props?.refetchOrder?.();
      },
    });

  const [localState, setLocalState] = useState(initial);

  const isOpen = name === "pickupDate" || name === "deliveryDate";
  const { canChangeLocationPoint, timezone, loadingDate } = props || {};

  useEffect(() => {
    if (isOpen) {
      setLocalState({
        ...localState,
        loadingDate: moment(loadingDate).tz(timezone).format("YYYY-MM-DD"),
        loadingTime: moment(loadingDate).tz(timezone).format("HH:mm"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleSubmit = () => {
    validator({
      schema: schema,
      data: localState,
      success: () => {
        if (canChangeLocationPoint) {
          updateLoadingDate({
            variables: {
              id: props?.id,
              loadingDate: tz(
                `${localState?.loadingDate} ${localState?.loadingTime}`,
                timezone
              ).format(),
            },
          });
        } else {
          responseMsgs([
            {
              type: "error",
              string:
                "You don't have permission to change the state of the location point.",
            },
          ]);
        }
      },
    });
  };

  ErrorsBeHandler({
    error: error,
    dep: isOpen,
  });

  SuccessBeHandler({
    data: data,
    code: "LOCATION_POINT_LOADING_DATE_UPDATED",
    dep: isOpen,
    fn: () => {
      setLocalState(initial);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        setLocalState(initial);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && isOpen && (
        <Div
          maxH="80vh"
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {msg}
          </Text>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Loading date
              </Text>
              <Input
                w="100%"
                placeholder="DD/MM/YYYY"
                h="2.5rem"
                name={"loadingDate"}
                type="date"
                value={localState?.loadingDate}
                onChange={(e: any) => {
                  setLocalState({
                    ...localState,
                    loadingDate: e.target.value,
                  });
                }}
              />
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Loading Time
              </Text>
              <Input
                placeholder="16.00"
                w="100%"
                h="2.5rem"
                name={
                  name === "pickupDate"
                    ? "requestedLoadingTime"
                    : "requestedUnloadingTime"
                }
                type="time"
                value={localState?.loadingTime}
                onChange={(e: any) => {
                  setLocalState({
                    ...localState,
                    loadingTime: e.target.value,
                  });
                }}
              />
            </Col>
            <Col size="12">
              <Div flexDir="row" d="flex" m={{ t: "2rem" }}>
                <PrimaryBtn
                  w="100%"
                  styleBtn={{ maxWidth: "211px", marginRight: "1rem" }}
                  handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  isLoading={loading}
                  text={"Update"}
                />
                <SecondaryBtn
                  w="100%"
                  styleBtn={{ maxWidth: "144px" }}
                  handleSubmit={() => {
                    modalData({
                      name: "",
                      msg: "",
                      returned_value: null,
                      props: null,
                    });
                  }}
                  isLoading={loading}
                  text={"Cancel"}
                />
              </Div>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default RequestTransportChange;
