import * as yup from "yup";

export const companySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup
    .string()
    .trim()
    .required({ name: "Company name", code: "REQUIRED_FIELD" }),
  contactUser_id: yup
    .string()
    .trim()
    .required({ name: "Contact", code: "REQUIRED_FIELD" }),
});

export const expiryDateSchema = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return yup.object().shape({
    id: yup.number().required(),
    expiryDate: yup.date().min(today, {
      name: "Expiry Date",
      code: "DATE_MIN",
      var: "today's date",
    }),
  });
};
