import React from "react";
import { Row, Col, Text, Div } from "atomize";
import {
  GetReceivedBidsDocument,
  useDeclineBidMutation,
  GetPlacedBidsDocument,
  GetActivityLogsDocument,
  GetOrderDocument,
  GetNotificationCountDocument,
} from "../../../generated/graphql";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import renderIcon from "../../../assets/Icons";
import DangerBtn from "../../DangerBtn/DangerBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { modalData } from "../../../App";
import { useReactiveVar } from "@apollo/client";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { StyledModal } from "../utils/helpers";

const DeclineBid: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const [
    declineBid,
    { data: bidData, loading: loadingBidData, error: errorBidData },
  ] = useDeclineBidMutation({ errorPolicy: "all" });

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    declineBid({
      variables: { id: props.id },
      refetchQueries: [
        GetOrderDocument,
        GetReceivedBidsDocument,
        GetPlacedBidsDocument,
        GetActivityLogsDocument,
        GetNotificationCountDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  ErrorsBeHandler({
    error: errorBidData,
    dep: name === "declineBid" || name === "cancelBid",
  });

  SuccessBeHandler({
    data: bidData,
    code: name === "declineBid" ? "DECLINE_BID" : "CANCEL_BID",
    dep: name === "declineBid" || name === "cancelBid",
    fn: () => {
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "declineBid" || name === "cancelBid")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && (name === "declineBid" || name === "cancelBid") && (
        <Div
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Div d="flex" justify="center">
            {renderIcon("Delete")}
          </Div>
          <Row>
            <Col>
              <Text
                textColor={"dark"}
                textWeight={"500"}
                m={{ t: "0.5rem", b: "1.75rem" }}
                textAlign="center"
              >
                {name === "cancelBid"
                  ? "Are you sure you want to cancel this bid?"
                  : "Are you sure you want to reject this order?"}
              </Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col size="12" flexDir="row" d="flex" justify="center">
              <DangerBtn
                w="100%"
                styleBtn={{ maxWidth: "144px", marginRight: "1rem" }}
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loadingBidData}
                text={"Confirm"}
              />
              <SecondaryBtn
                w="100%"
                styleBtn={{ maxWidth: "144px" }}
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loadingBidData}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default DeclineBid;
