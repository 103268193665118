import { Col, Row } from "atomize";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { responseMsgs } from "../../App";
import renderIcon from "../../assets/Icons";
import ProgressLine from "../../components/ProgressLine/ProgressLine";
import {
  CompanyType,
  DocumentType,
  LocationType,
  useRegisterUserAndCompanyMutation,
} from "../../generated/graphql";
import { GoogleAPI } from "../../helpers/CustomHooks/googleAPIHook";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import { uploadFileToAWS } from "../../helpers/functions";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";

interface StepTwoProps {
  type: CompanyType;
  name: string;
  taxNumber: string;
  address: string;
  city: string;
  postalCode: string;
  taxNumberCountry: string;
  country: {
    id: number;
    name: string;
    code: string;
  };
  BIC: string;
  IBAN: string;
  IBANCountry: string;
  timezone: string;
  insuranceExpiryDate: string | null;
  insuranceFile: File | null;
}

const SignUp: React.FC = () => {
  const [registerUserAndCompany, { data, loading, error }] =
    useRegisterUserAndCompanyMutation({ errorPolicy: "all" });
  const { getCoordinates, isLoading } = GoogleAPI();
  const history = useHistory();
  const search = useLocation().search;

  const [stepOne, setStepOne] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    phoneCountryNumbers: "",
    confirmPassword: "",
  });

  const [stepTwo, setStepTwo] = useState<StepTwoProps>({
    type: CompanyType.Shipper,
    name: "",
    taxNumber: "",
    address: "",
    city: "",
    postalCode: "",
    taxNumberCountry: "",
    country: {
      id: 0,
      name: "",
      code: "",
    },
    IBANCountry: "",
    BIC: "",
    IBAN: "",
    timezone: "",
    insuranceFile: null,
    insuranceExpiryDate: null,
  });

  const [step, setStep] = useState<number>(1);

  const handleStep = (num: number) => {
    setStep(step + num);
  };

  const isTransporter = stepTwo.type === CompanyType.Transporter;

  const handleSubmit = async () => {
    const location = await getCoordinates(
      stepTwo.address,
      stepTwo.city,
      stepTwo.postalCode,
      stepTwo.country.name
    );
    const fullTaxNumber = stepTwo.taxNumberCountry + stepTwo.taxNumber;

    if (location) {
      await registerUserAndCompany({
        variables: {
          company: {
            location: {
              geoLocation: JSON.stringify(location),
              address: stepTwo.address,
              postCode: stepTwo.postalCode,
              postName: stepTwo.city,
              country_id: stepTwo.country.id,
              contactName: `${stepOne.firstName} ${stepOne.lastName}`,
              phone: `(${stepOne.phoneCountryNumbers})${stepOne.phone}`.trim(),
              email: stepOne.email,
              type: LocationType.CompanyBillingAddress,
              timezone: stepTwo.timezone,
            },

            name: stepTwo.name,
            taxNumber: fullTaxNumber.trim(),
            type: stepTwo.type,
            contactEmail: stepOne.email,
            IBAN: `${stepTwo.IBANCountry} ${stepTwo.IBAN}`.trim(),
            BIC: stepTwo.BIC.trim(),
            logoPath: "",
            country_id: stepTwo.country.id,
          },
          user: {
            email: stepOne.email,
            firstName: stepOne.firstName,
            lastName: stepOne.lastName,
            password: stepOne.password,
            phone: `(${stepOne.phoneCountryNumbers})${stepOne.phone}`.trim(),
            profileImageUrl: "",
          },
          ...(stepTwo?.insuranceExpiryDate && stepTwo?.insuranceFile?.name
            ? {
                insuranceCertificate: {
                  type: DocumentType.Insurance,
                  fileName: stepTwo?.insuranceFile?.name,
                  fileType: stepTwo?.insuranceFile?.type,
                  fileSize: stepTwo?.insuranceFile?.size,
                  date: moment(),
                  expiryDate: stepTwo?.insuranceExpiryDate,
                },
              }
            : {}),
        },
      });
    }

    if (!(stepTwo?.insuranceExpiryDate && stepTwo?.insuranceFile?.name)) {
      history.push("/signin");
    }
  };

  useEffect(() => {
    async function uploadFile() {
      try {
        if (
          data?.registerUserAndCompany?.company?.insuranceCertificate?.fileUrl
        ) {
          const res = await uploadFileToAWS(
            data?.registerUserAndCompany?.company?.insuranceCertificate
              ?.fileUrl,
            stepTwo?.insuranceFile as File
          );

          if (res?.status === 200) {
            history.push("/signin");
          } else {
            responseMsgs([
              {
                type: "error",
                string: "Something went wrong, please contact our support team",
              },
            ]);
          }
        }
      } catch (error) {
        console.log("Error when uploading a file", error);
      }
    }
    if (data && isTransporter) {
      uploadFile();
    }
  }, [data, stepTwo?.insuranceFile, isTransporter, history]);

  useEffect(() => {
    const sp = new URLSearchParams(search);
    if (sp.has("name")) {
      setStepTwo({
        ...stepTwo,
        name: sp.get("name") as string,
      });
    }
    if (sp.has("email")) {
      setStepOne({
        ...stepOne,
        email: sp.get("email") as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  ErrorsBeHandler({
    error: error,
  });

  SuccessBeHandler({
    data: data,
    code: "SIGN_UP",
  });

  return (
    <Row
      flexDir="column"
      minW="100%"
      minH="700px"
      textAlign="justify"
      style={{ minHeight: "calc(100vh - 100px)" }}
    >
      <Col>
        <ProgressLine allSteps={2} step={step} />
      </Col>
      <Col>
        {step === 1 && (
          <StepOne
            setUser={setStepOne}
            user={stepOne}
            handleStep={handleStep}
          />
        )}
        {(step === 2 || step === 3) && (
          <StepTwo
            setCompany={setStepTwo}
            isLoading={loading || isLoading}
            company={stepTwo}
            handleStep={handleStep}
            submitForm={handleSubmit}
          />
        )}
      </Col>
      <Col
        d="flex"
        m={{ t: "auto" }}
        minH="80px"
        align="flex-end"
        justify="center"
      >
        {renderIcon("SmallLogo")}
      </Col>
    </Row>
  );
};

export default SignUp;
