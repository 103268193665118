import { Button, Col, Div, Input, Row, Text } from "atomize";
import { ChangeEvent, useState } from "react";
import NumberFormat from "react-number-format";
import { modalData } from "../../../App";
import { GlobalTheme } from "../../../components/GlobalTheme";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import {
  GetAllTaxationClauseDocument,
  useAddTaxationClauseMutation,
  useGetAllTaxationClauseQuery,
  useRemoveTaxationClauseMutation,
  useUpdateTaxationClauseMutation,
} from "../../../generated/graphql";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { taxationSchema } from "../schemas/taxation.validation";

const TaxationClauses = () => {
  const { validator } = ValidationsFeHandler();

  const {
    data: taxationClauses,
    loading: loadingTaxationClauses,
    error: errorTaxationClauses,
  } = useGetAllTaxationClauseQuery({
    variables: {
      query: {},
    },
  });

  const [
    addTaxationClause,
    {
      data: dataAddTaxationClause,
      loading: loadingAddTaxationClause,
      error: errorAddTaxationClause,
    },
  ] = useAddTaxationClauseMutation({
    onCompleted() {
      setLocalState({
        clause: "",
        vat: "",
      });
    },
  });

  const [
    removeTaxationClause,
    {
      data: dataRemoveTaxationClause,
      loading: loadingRemoveTaxationClause,
      error: errorRemoveTaxationClause,
    },
  ] = useRemoveTaxationClauseMutation();

  const [
    updateTaxationClause,
    {
      data: dataUpdateTaxationClause,
      loading: loadingUpdateTaxationClause,
      error: errorUpdateTaxationClause,
    },
  ] = useUpdateTaxationClauseMutation();

  const [localState, setLocalState] = useState({
    clause: "",
    vat: "",
  });

  const isLoading =
    loadingTaxationClauses ||
    loadingAddTaxationClause ||
    loadingRemoveTaxationClause ||
    loadingUpdateTaxationClause;

  const handleChange = (field: string, value: any) => {
    setLocalState({
      ...localState,
      [field]: value,
    });
  };

  const handleAddTaxationClause = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const payload = {
      ...localState,
      vat: Number(localState?.vat),
      isDefault: false,
    };

    validator({
      schema: taxationSchema,
      data: payload,
      success: () => {
        addTaxationClause({
          variables: {
            taxationClause: payload,
          },
          refetchQueries: [GetAllTaxationClauseDocument],
        });
      },
    });
  };

  const handleRemoveTaxationClause = (id: string | number) => {
    modalData({
      msg: "Are you sure?",
      name: "confirmModal",
      returned_value: null,
      props: {
        icon: "AlertSolid",
        iconColor: "warning700",
        subtext:
          "Do you really want to remove this taxation clause? This action cannot be undone.",
        isLoading: false,
        handleSubmit: () => {
          removeTaxationClause({
            variables: {
              id: Number(id),
            },
            refetchQueries: [GetAllTaxationClauseDocument],
          });
          modalData({
            name: "",
            msg: "",
            returned_value: null,
            props: null,
          });
        },
        primaryBtn: "Remove",
        primaryBtnColor: GlobalTheme.colors.danger,
        secondaryBtn: "Cancel",
      },
    });
  };

  const handleMarkAsDefault = (id: string | number) => {
    updateTaxationClause({
      variables: {
        taxationClause: {
          id: Number(id),
          isDefault: true,
        },
      },
      refetchQueries: [GetAllTaxationClauseDocument],
    });
  };

  SuccessBeHandler({
    data: dataAddTaxationClause,
    code: "TAXATION_CLAUSE_ADDED",
  });

  SuccessBeHandler({
    data: dataRemoveTaxationClause,
    code: "TAXATION_CLAUSE_REMOVED",
  });

  SuccessBeHandler({
    data: dataUpdateTaxationClause,
    code: "TAXATION_CLAUSE_MARKED_AS_DEFAULT",
  });

  ErrorsBeHandler({
    error: errorRemoveTaxationClause,
  });

  ErrorsBeHandler({
    error: errorTaxationClauses,
  });

  ErrorsBeHandler({
    error: errorAddTaxationClause,
  });

  ErrorsBeHandler({
    error: errorUpdateTaxationClause,
  });

  return (
    <Row p={{ t: "4rem" }}>
      <Col>
        <Text textWeight="700" textSize="20px" m={{ b: "1rem" }}>
          Taxation clauses
        </Text>
        <Text textColor="semiDark" m={{ b: "2rem" }}>
          Add all your taxation clauses that you’d like to have on your
          invoices. Before the invoice is generated, you can pick any of these
          clauses. We will automatically select a clause that you’ve been using
          most recently.
        </Text>

        <Row>
          <Col>
            <Div>
              {taxationClauses?.getAllTaxationClause?.items?.map((item) => (
                <Div
                  key={item?.id}
                  d="flex"
                  align="center"
                  h="58px"
                  p={{ y: "0.5rem" }}
                  style={{
                    borderBottom: `1px solid ${GlobalTheme.colors.greyBorder}`,
                  }}
                >
                  <Div w="100%" m={{ l: "0.5rem" }}>
                    <Div d="flex" align="center" m={{ b: "0.25rem" }}>
                      {item?.isDefault && (
                        <Div
                          d="flex"
                          align="center"
                          justify="center"
                          p={{ x: "0.5rem", y: "0.25rem" }}
                          style={{
                            backgroundColor: GlobalTheme.colors.grey,
                            borderRadius: "4px",
                          }}
                          m={{ r: "0.25rem" }}
                        >
                          {
                            <Text
                              textSize="12px"
                              textWeight="500"
                              textColor="semiDark"
                            >
                              Default
                            </Text>
                          }
                        </Div>
                      )}
                      <Text
                        textSize="14px"
                        textWeight="500"
                        textColor="dark"
                        m={{ b: "2px", lineHeight: "24px" }}
                      >
                        {item?.clause}
                      </Text>
                    </Div>
                    <Text textSize="14px" textWeight="400" textColor="semiDark">
                      VAT {item?.vat}%
                    </Text>
                  </Div>
                  {!item?.isDefault && (
                    <Button
                      bg="transparent"
                      textColor={GlobalTheme.colors.primary}
                      onClick={() => handleMarkAsDefault(item?.id)}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Set as default
                    </Button>
                  )}
                  <Button
                    bg="transparent"
                    textColor={GlobalTheme.colors.danger}
                    onClick={() => handleRemoveTaxationClause(item?.id)}
                  >
                    Remove
                  </Button>
                </Div>
              ))}
            </Div>
          </Col>
        </Row>

        <Row m={{ t: "2rem" }}>
          <Col size="8">
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem" }}
            >
              Clause shown on invoice
            </Text>
            <Input
              placeholder="Reverse charge exemption"
              name="clause"
              value={localState?.clause}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange("clause", e.target.value)
              }
              textSize="16"
              type="text"
              h="48px"
              m={{ b: "1rem" }}
              maxLength="255"
            />
          </Col>
          <Col size="2">
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem" }}
            >
              VAT %
            </Text>
            <NumberFormat
              m={{ b: "1rem" }}
              style={{ height: "48px" }}
              placeholder="0%"
              value={localState?.vat}
              suffix=" %"
              onValueChange={(values) => {
                handleChange("vat", values.value);
              }}
              customInput={Input}
              maxLength={5}
            />
          </Col>
          <Col size="2" d="flex" align="flex-end" jc="flex-end">
            <PrimaryBtn
              w="100%"
              handleSubmit={handleAddTaxationClause}
              isLoading={isLoading}
              disabled={isLoading}
              text="Add"
              styleBtn={{ marginBottom: "1rem" }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TaxationClauses;
