import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import React from "react";
import { modalData } from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const ReadMoreTd: React.FC = () => {
  const { name, props, msg } = useReactiveVar(modalData);
  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "readMoreTd")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "readMoreTd" && (
        <Div
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "1rem" }}
          >
            {msg ? msg : "Read More"}
          </Text>
          <Row>
            <Col>
              <Text m={{ b: "2rem" }} d="flex">
                {props.children}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <SecondaryBtn
                text="Cancel"
                handleSubmit={() =>
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  })
                }
                w="120px"
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default ReadMoreTd;
