import { Anchor, Col, Div, Dropdown, Icon, Input, Row, Text } from "atomize";
import { capitalize } from "lodash";
import { tz } from "moment";
import React, { useRef, useState } from "react";
import { responseMsgs } from "../../../App";
import InputDropdown from "../../../components/InputDropdown/InputDropdown";
import PrefixDropdown from "../../../components/PrefixDropdown/PrefixDropdown";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../../components/SecondaryBtn/SecondaryBtn";
import TertiaryBtn from "../../../components/TertiaryBtn/TertiaryBtn";
import {
  CompanyType,
  useGetCountriesLazyQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { DocumentInputAcceptExtensions } from "../../../helpers/constants";
import { stepTwoValidation } from "../schema/StepTwo.validation";

interface SingUpProps {
  setCompany: (company: any) => void;
  company: any;
  submitForm: () => void;
  isLoading: boolean;
  handleStep: (i: number) => void;
}

const SignTwo: React.FC<SingUpProps> = ({
  setCompany,
  company,
  handleStep,
  submitForm,
  isLoading,
}) => {
  //tax number
  const queryTaxCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const fileInputRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const [countryTaxOpen, setCountryTaxOpen] = useState(false);
  const {
    data: taxCountries,
    loading: loadingTAxCountries,
    search: countryTaxInput,
    setSearch: setCountryTaxInput,
  } = useSearch(queryTaxCountries, { limit: 5 }, 300, countryTaxOpen);

  //Country selcect
  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, isCountryDropOpen);

  const queryIbanCountries = useGetCountriesLazyQuery();
  const [isIbanCountryDropOpen, setIsIbanCountryDropOpen] = useState(false);
  const {
    data: dataIbanCountries,
    loading: loadingIbanCountries,
    search: searchIbanCountries,
    setSearch: setSearchIbanCountries,
  } = useSearch(queryIbanCountries, { limit: 5 }, 300, isIbanCountryDropOpen);

  const [isTypeDropDownOpen, setIsTypeDropDownOpen] = useState(false);
  const [isTimezoneOpen, setIsTimezoneOpen] = useState(false);

  const { validator } = ValidationsFeHandler();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setCompany({
      ...company,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const {
      taxNumber,
      taxNumberCountry,
      IBANCountry,
      BIC,
      IBAN,
      insuranceFile,
      expiryDate,
      ...rest
    } = company;
    const schema = stepTwoValidation;
    const data = {
      ...rest,
      BIC,
      insuranceFileName: insuranceFile?.name,
      expiryDate,
    };
    validator({
      schema: schema,
      data: data,
      vat: `${taxNumberCountry} ${taxNumber}`,
      iban: `${IBANCountry} ${IBAN}`,
      bic: BIC,
      success: () => {
        submitForm();
      },
    });
  };

  const handleStepBack = () => {
    handleStep(-1);
  };

  const fileUpload = (e: any) => {
    const file = e.target.files[0];

    if (!DocumentInputAcceptExtensions.includes(file?.type)) {
      return responseMsgs([
        {
          type: "error",
          string: "Please upload a valid file type.",
        },
      ]);
    }

    setCompany({ ...company, insuranceFile: file });
  };

  return (
    <Row flexDir="column" minW="100%" p={{ t: "1rem" }}>
      <Col minW="100%" size="12">
        <Text
          textSize="201"
          textAlign="center"
          textWeight="500"
          textColor="dark"
          m={{ x: { xs: "0rem" }, y: { xs: "2rem" } }}
        >
          Enter your company details
        </Text>
      </Col>
      <Col size="12">
        <Input
          placeholder="Company legal name"
          h="48px"
          className="input"
          name="name"
          value={company.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          textSize={"16"}
          type="text"
        />
      </Col>
      <Col size="12">
        <Dropdown
          w="auto"
          style={{ margin: "0.5rem 0" }}
          h="3rem"
          isOpen={isTypeDropDownOpen}
          onClick={() => setIsTypeDropDownOpen(!isTypeDropDownOpen)}
          menu={
            <Div maxH="300px" overflow="visible scroll">
              {Object.values(CompanyType).map((type: any, index: number) => (
                <Anchor
                  d="block"
                  p={{ y: "0.25rem", x: "0.75rem" }}
                  onClick={() => {
                    setCompany({
                      ...company,
                      type: type,
                    });
                    setIsTypeDropDownOpen(false);
                  }}
                  key={index}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    textTransform="capitalization"
                  >
                    {" "}
                    {capitalize(type)}{" "}
                  </Text>
                </Anchor>
              ))}
            </Div>
          }
        >
          <Text
            textColor={company.type !== "" ? "dark" : `semiLight`}
            textWeight="500"
            m={{ r: "auto", l: "0rem" }}
            textSize={16}
            textTransform="capitalization"
          >
            {company.type !== ""
              ? capitalize(company.type)
              : `Select company type`}
          </Text>
        </Dropdown>
      </Col>
      {company.type === CompanyType.Transporter && (
        <>
          <Col>
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem" }}
            >
              Expiry Date
            </Text>
            <Input
              w="100%"
              placeholder="DD/MM/YYYY"
              h="3rem"
              m={{ b: "0.5rem" }}
              name="loading_date"
              type="date"
              value={company.insuranceExpiryDate || ""}
              onChange={(e: any) =>
                setCompany({ ...company, insuranceExpiryDate: e.target.value })
              }
            />
          </Col>
          <Col>
            <TertiaryBtn
              text={"Upload Insurance Certificate"}
              h="48px"
              prefixIcon={
                <Icon
                  name="Upload"
                  color="primary"
                  m={{ r: "0.5rem" }}
                  size="20px"
                />
              }
              isLoading={isLoading}
              disabled={isLoading}
              styleBtn={{ margin: "0 0 0.5rem 0" }}
              handleSubmit={() => fileInputRef.current.click()}
            />
            <Div m={{ b: "0.75rem" }}>
              {company?.insuranceFile && company?.insuranceFile?.name && (
                <Div d="flex" justify="space-between">
                  <Div d="flex">
                    <Icon
                      m={{ r: "0.75rem" }}
                      name="Checked"
                      color="success600"
                      size="20px"
                    />
                    <Text textColor="semiDark" textWeight="500">
                      {company?.insuranceFile?.name}
                    </Text>
                  </Div>
                  <Icon
                    name="Delete"
                    cursor="pointer"
                    color="semiDark"
                    size="20px"
                    right="0"
                    disabled={isLoading}
                    onClick={() => {
                      setCompany({ ...company, insuranceFile: null });
                    }}
                  />
                </Div>
              )}
            </Div>
          </Col>
          <input
            onChange={fileUpload}
            type="file"
            hidden
            ref={fileInputRef}
            key={1}
            accept={DocumentInputAcceptExtensions.join(",")}
          />
        </>
      )}
      <Col h="56px" d="flex" align="flex-end">
        <InputDropdown
          h="48px"
          addNew={false}
          displayResult={
            <Div d="flex" h="24px" align="center">
              <Text
                textColor={company.country.name !== "" ? "dark" : `semiLight`}
                textWeight="500"
                m={{ r: "auto", l: "0rem" }}
                textSize={16}
              >
                {company.country.name !== ""
                  ? company.country.name
                  : `Select country name`}
              </Text>
            </Div>
          }
          isLoading={loadingCountries}
          isOpen={isCountryDropOpen}
          setIsOpen={setIsCountryDropOpen}
          value={countryInput}
          handleChange={setCountryInput}
          menuList={
            countries &&
            countries?.getCountries.items?.map((country: any, i: number) => (
              <Anchor
                d="flex"
                p={{ y: "0.5rem", x: "0.75rem" }}
                key={i}
                align="center"
                onClick={() => {
                  setCompany({
                    ...company,
                    country: {
                      id: country.id,
                      name: country.name,
                      code: country.code,
                    },
                    timezone: tz.zonesForCountry(country.code)[0],
                  });
                  setCountryInput("");
                  setIsCountryDropOpen(false);
                }}
              >
                <Text textColor="semiDark" hoverTextColor="dark">
                  {" "}
                  {`${country.name}`}
                </Text>
              </Anchor>
            ))
          }
        />
      </Col>
      <Col size="12">
        <Dropdown
          w="auto"
          style={{
            PointerEvent: company.country.code ? "auto" : "none",
            margin: "0.5rem 0 ",
          }}
          bg={company.country.code ? "transparent" : "grey"}
          h="3rem"
          isOpen={isTimezoneOpen}
          onClick={
            company.country.code ? () => setIsTimezoneOpen(!isTimezoneOpen) : ""
          }
          menu={
            company.country.code && (
              <Div maxH="300px" overflow="visible scroll">
                {Array.from(
                  new Set([...tz.zonesForCountry(company.country.code)])
                ).map((item: any, i: number) => (
                  <Anchor
                    d="flex"
                    p={{ y: "0.5rem", x: "0.75rem" }}
                    key={i}
                    align="center"
                    onClick={() => {
                      setCompany({
                        ...company,
                        timezone: item,
                      });
                      setIsTimezoneOpen(false);
                    }}
                  >
                    <Text textColor="semiDark" hoverTextColor="dark">
                      {item}
                    </Text>
                  </Anchor>
                ))}
              </Div>
            )
          }
        >
          <Text
            textColor={company.timezone !== "" ? "dark" : `semiLight`}
            textWeight="500"
            m={{ r: "auto", l: "0rem" }}
            textSize={16}
          >
            {company.timezone !== ""
              ? company.timezone
              : `Select country timezone`}
          </Text>
        </Dropdown>
      </Col>
      <Col size="12">
        <PrefixDropdown
          inputValue={company.taxNumber}
          countryInputValue={countryTaxInput}
          h="48px"
          placeholder="VAT number"
          format="## #### #### #### ####"
          addNew={true}
          displayResult={
            <Div
              d="flex"
              h="24px"
              align="center"
              justify="center"
              m={{ l: "auto", r: "-0.75rem" }}
              w="25px"
            >
              <Text
                textColor="dark"
                textWeight="500"
                textSize={16}
                m={{ l: "0" }}
              >{`${company.taxNumberCountry}`}</Text>
            </Div>
          }
          isLoading={loadingTAxCountries}
          isOpen={countryTaxOpen}
          setIsOpen={setCountryTaxOpen}
          setCountryInput={setCountryTaxInput}
          handleChange={(value: any) => {
            setCompany({
              ...company,
              taxNumber: value,
            });
          }}
          menuList={taxCountries?.getCountries.items?.map(
            (item: any, i: number) => (
              <Anchor
                d="flex"
                p={{ y: "0.5rem", x: "0.75rem" }}
                key={i}
                align="center"
                onClick={() => {
                  setCompany({
                    ...company,
                    taxNumberCountry: item.code,
                  });
                  setCountryTaxOpen(false);
                }}
              >
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.code}`}
                </Text>
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.name}`}
                </Text>
              </Anchor>
            )
          )}
        />
      </Col>
      <Div h="8px"></Div>
      <Col size="12">
        <PrefixDropdown
          inputValue={company?.IBAN}
          countryInputValue={searchIbanCountries}
          h="48px"
          placeholder="IBAN"
          format="## #### #### #### #### #### ####"
          addNew={true}
          displayResult={
            <Div
              d="flex"
              h="24px"
              align="center"
              justify="center"
              w="25px"
              m={{ l: "0", r: "-0.75rem" }}
            >
              <Text
                textColor="dark"
                textWeight="500"
                textSize={16}
                m={{ l: "0" }}
              >{`${company?.IBANCountry}`}</Text>
            </Div>
          }
          isLoading={loadingIbanCountries}
          isOpen={isIbanCountryDropOpen}
          setIsOpen={setIsIbanCountryDropOpen}
          setCountryInput={setSearchIbanCountries}
          handleChange={(value: any) => {
            setCompany({
              ...company,
              IBAN: value,
            });
          }}
          menuList={dataIbanCountries?.getCountries.items?.map(
            (item: any, i: number) => (
              <Anchor
                d="flex"
                p={{ y: "0.5rem", x: "0.75rem" }}
                key={i}
                align="center"
                onClick={() => {
                  setCompany({
                    ...company,
                    IBANCountry: item.code,
                  });
                  setIsIbanCountryDropOpen(false);
                }}
              >
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.code}`}
                </Text>
                <Text
                  textColor="semiDark"
                  hoverTextColor="dark"
                  p={{ l: "10px" }}
                >
                  {" "}
                  {`${item.name}`}
                </Text>
              </Anchor>
            )
          )}
        />
      </Col>
      <Col size="12">
        <Input
          placeholder="BIC"
          name="BIC"
          value={company.BIC}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length <= 11) {
              setCompany({
                ...company,
                BIC: e.target.value,
              });
            }
          }}
          textSize={"16"}
          h="48px"
          type="text"
          m={{ y: { xs: "0.5rem" } }}
        />
      </Col>
      <Col size="12">
        <Input
          placeholder="Address"
          name="address"
          value={company.address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          textSize={"16"}
          h="48px"
          type="text"
          m={{ b: "0.5rem" }}
        />
      </Col>
      <Col size="12">
        <Input
          placeholder="City"
          name="city"
          value={company.city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          textSize={"16"}
          type="text"
          h="48px"
          m={{ b: "0.5rem" }}
        />
      </Col>
      <Col size="12">
        <Input
          placeholder="Post code"
          name="postalCode"
          value={company.postalCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          textSize={"16"}
          type="text"
          h="48px"
          m={{ b: "0.5rem" }}
        />
      </Col>
      <Col size="12" p={{ b: "2rem" }}>
        <Row>
          <Col>
            <SecondaryBtn
              h="48px"
              w="100%"
              styleBtn={{ margin: "1.5rem 0" }}
              handleSubmit={handleStepBack}
              isLoading={isLoading}
              text={"Back"}
            />
          </Col>
          <Col>
            <PrimaryBtn
              h="48px"
              w="100%"
              styleBtn={{ margin: "1.5rem 0" }}
              handleSubmit={(e: any) => handleSubmit(e)}
              isLoading={isLoading}
              text={"Submit"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignTwo;
