import { Checkbox, Col, Div, Input, Label, Row, Text } from "atomize";
import { isEqual, omit, pick } from "lodash";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { GlobalTheme } from "../../../components/GlobalTheme";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import {
  GetInvoiceSettingsDocument,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceSettingsMutation,
} from "../../../generated/graphql";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import moment from "moment";

const InvoiceSequence = () => {
  const {
    data: invoiceSettings,
    loading: loadingInvoiceSettings,
    error: errorInvoiceSettings,
  } = useGetInvoiceSettingsQuery();

  const [
    updateInvoiceSettings,
    {
      data: dataUpdateInvoiceSettings,
      loading: loadingUpdateInvoiceSettings,
      error: errorUpdateInvoiceSettings,
    },
  ] = useUpdateInvoiceSettingsMutation({
    refetchQueries: [GetInvoiceSettingsDocument],
  });

  const {
    addChronologicalSequence = false,
    addPayeePrefix = false,
    customPrefix = "",
    defaultDueDate = 90,
  } = invoiceSettings?.getInvoiceSettings || {};

  const [localState, setLocalState] = useState({
    addChronologicalSequence,
    addPayeePrefix,
    customPrefix,
    defaultDueDate,
  });

  const isLoading = loadingUpdateInvoiceSettings || loadingInvoiceSettings;

  const isDefaultDueDateSavable = !isEqual(
    localState?.defaultDueDate,
    defaultDueDate
  );
  const isInvoiceSequenceSavable = !isEqual(
    omit(localState, "defaultDueDate"),
    pick(invoiceSettings?.getInvoiceSettings, [
      "addChronologicalSequence",
      "addPayeePrefix",
      "customPrefix",
    ])
  );

  const handleUpdate = () => {
    const payload = {
      ...localState,
      defaultDueDate: Number(localState.defaultDueDate),
    };

    updateInvoiceSettings({
      variables: {
        invoiceSettings: payload,
      },
    });
  };

  SuccessBeHandler({
    data: dataUpdateInvoiceSettings,
    code: "INVOICE_SETTINGS_UPDATED",
  });

  ErrorsBeHandler({
    error: errorUpdateInvoiceSettings,
  });

  ErrorsBeHandler({
    error: errorInvoiceSettings,
  });

  const handleChange = (field: string, value: any) => {
    setLocalState({
      ...localState,
      [field]: value,
    });
  };

  useEffect(() => {
    setLocalState({
      addChronologicalSequence,
      addPayeePrefix,
      customPrefix,
      defaultDueDate,
    });
  }, [
    addChronologicalSequence,
    addPayeePrefix,
    customPrefix,
    defaultDueDate,
    invoiceSettings,
  ]);

  return (
    <>
      <Row
        p={{ y: "4rem" }}
        style={{
          borderBottom: `1px solid ${GlobalTheme.colors.greyBorder}`,
        }}
      >
        <Col>
          <Text textWeight="700" textSize="20px" m={{ b: "1rem" }}>
            Invoice sequence
          </Text>
          <Text textColor="semiDark">
            Invoice number can consist of a chronological number, sequential
            number and two different prefix types that you customize.
          </Text>

          <Row
            style={{
              backgroundColor: GlobalTheme.colors.grey,
              borderRadius: "6px",
            }}
            p={{ x: "1rem", y: "2rem" }}
            m={{ x: 0, y: "2rem" }}
          >
            <Col textAlign="center">
              <Text
                textTransform="uppercase"
                textSize="10px"
                textWeight="400"
                textColor="dark"
                m={{ b: "0.5rem" }}
                style={{ letterSpacing: "1.4px" }}
              >
                Current sequence example
              </Text>
              <Div p="1rem">
                <Text
                  textSize="32px"
                  textWeight="700"
                  textColor="semiDark"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {localState?.customPrefix}{" "}
                  {localState?.addPayeePrefix && "ABCLTD"}{" "}
                  {localState?.addChronologicalSequence &&
                    moment().format("YYYYMMDD")}{" "}
                  00000001
                </Text>
              </Div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Custom prefix shown in front of invoice number (6 letter limit)
              </Text>
              <Input
                placeholder="BA-2024-894"
                name="customPrefix"
                value={localState?.customPrefix}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("customPrefix", e.target.value)
                }
                textSize="16"
                type="text"
                h="48px"
                m={{ b: "1rem" }}
                maxLength="6"
              />

              <Label>
                <Checkbox
                  checked={localState?.addPayeePrefix}
                  onClick={() =>
                    handleChange("addPayeePrefix", !localState?.addPayeePrefix)
                  }
                  value={localState?.addPayeePrefix}
                  label={
                    "Dynamically add prefix based on payee company name with up to 6 letters."
                  }
                />
                <Text textSize={14} textWeight="500">
                  Dynamically add prefix based on payee company name with up to
                  6 letters.
                </Text>
              </Label>

              <Div h="2rem" />

              <Label>
                <Checkbox
                  checked={localState?.addChronologicalSequence}
                  onClick={() =>
                    handleChange(
                      "addChronologicalSequence",
                      !localState?.addChronologicalSequence
                    )
                  }
                  value={localState?.addChronologicalSequence}
                  label={
                    "Use chronological sequence with current year, month and day. "
                  }
                />
                <Text textSize={14} textWeight="500">
                  Use chronological sequence with current year, month and day.
                </Text>
              </Label>

              <PrimaryBtn
                w="100%"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  handleUpdate();
                }}
                disabled={!isInvoiceSequenceSavable}
                isLoading={isLoading}
                text="Update"
                styleBtn={{ marginTop: "2rem" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        p={{ y: "4rem" }}
        style={{
          borderBottom: `1px solid ${GlobalTheme.colors.greyBorder}`,
        }}
      >
        <Col>
          <Text textWeight="700" textSize="20px" m={{ b: "1rem" }}>
            Default invoice due date
          </Text>
          <Text textColor="semiDark" m={{ b: "2rem" }}>
            Specify your preferred payment duration conditions. This date range
            will be automatically applied to all your generated invoices.
          </Text>

          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Payment due date in days (default 90 days)
              </Text>
              <NumberFormat
                m={{ b: "1rem" }}
                style={{ height: "48px" }}
                placeholder="€250"
                value={localState?.defaultDueDate}
                onValueChange={(values) => {
                  handleChange("defaultDueDate", values.value);
                }}
                customInput={Input}
              />
            </Col>
          </Row>

          <PrimaryBtn
            w="100%"
            handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
              e.preventDefault();
              handleUpdate();
            }}
            disabled={!isDefaultDueDateSavable}
            isLoading={isLoading}
            text="Update"
            styleBtn={{ marginTop: "2rem" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InvoiceSequence;
