import React, { useState } from "react";
import { Row, Col, Text, Input, Anchor } from "atomize";
import renderIcon from "../../assets/Icons";
import Border from "../../components/Border/Border";
import { useRequestPasswordResetMutation } from "../../generated/graphql";
import * as yup from "yup";
import { useHistory } from "react-router";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";

let schema = yup
  .string()
  .trim()
  .required({ name: "Email", code: "REQUIRED_FIELD" })
  .email({ name: "Email", code: "INVALID_EMAIL" });

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [
    requestPasswordReset,
    {
      data: requestPasswordResetData,
      loading: requestPasswordResetDataLoading,
      error: requestPasswordResetError,
    },
  ] = useRequestPasswordResetMutation({ errorPolicy: "all" });
  const history = useHistory();
  const { validator } = ValidationsFeHandler();

  const handleSubmitPassword = async (
    e: React.ChangeEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: email,
      success: () => {
        requestPasswordReset({ variables: { email: email } });
      },
    });
  };

  ErrorsBeHandler({
    error: requestPasswordResetError,
  });

  SuccessBeHandler({
    data: requestPasswordResetData,
    code: "FORGOT_PASSWORD",
    fn: () => {
      history.push("/signin");
    },
  });

  return (
    <Row flexDir="column">
      <Col size="12" style={{ display: "flex" }} justify="center">
        {renderIcon("Logo")}
      </Col>
      <Col>
        <Text
          textSize="201"
          textAlign="center"
          textWeight="500"
          textColor="dark"
          m={{ x: { xs: "0rem" }, y: { xs: "2rem" } }}
        >
          Improve efficiency and reduce cost of your logistic operations.
        </Text>
      </Col>
      <Col size="12">
        <Input
          placeholder="Email"
          h="48px"
          textSize={"16"}
          type="text"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
      </Col>

      <Col size="12">
        <PrimaryBtn
          h="48px"
          w="100%"
          styleBtn={{ marginTop: "1.5rem" }}
          handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
            handleSubmitPassword(e)
          }
          isLoading={requestPasswordResetDataLoading}
          text={"Send"}
        />
      </Col>
      <Col>
        <Border margin={"32px 0 15px"} />
      </Col>
      <Col d="flex" justify="center">
        <Anchor
          hoverTextColor="info700"
          textColor="primary"
          onClick={() => {
            history.push("/signin");
          }}
          textSize={12}
          textWeight="600"
        >
          {"Back to sing in"}
        </Anchor>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
