import { useReactiveVar } from "@apollo/client";
import { Col, Div, Icon, Row, Text } from "atomize";
import React from "react";
import { useHistory } from "react-router";
import { modalData } from "../../../App";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const OrderType: React.FC = () => {
  const { name, msg, props } = useReactiveVar(modalData);
  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <StyledModal
      isOpen={!!(name === "orderType")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "orderType" && (
        <Div
          style={{
            overflowY: "auto",
            padding: width > 550 ? "1.5rem" : "0.5rem",
          }}
        >
          <Row>
            <Col d="flex" align="center">
              {props.icon && (
                <Icon
                  name={props.icon}
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color={props.iconColor}
                />
              )}
              <Text
                textSize="201"
                textAlign="center"
                textWeight="700"
                textColor="dark"
              >
                {msg}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Col d="flex">
                  <Text m={{ t: "0.5rem", b: "2rem" }}>{props.subtext}</Text>
                </Col>
              </Row>

              <Row>
                {
                  <Col size="auto">
                    <SecondaryBtn
                      w="auto"
                      handleSubmit={() => {
                        history.push("/createneworder/order", {
                          type: "BIDS",
                          code: props.code,
                        });
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        });
                      }}
                      text={"Receive pricing bids"}
                      styleBtn={{ marginBottom: "0.5rem" }}
                    />
                  </Col>
                }
                {
                  <Col size="auto">
                    <SecondaryBtn
                      w="auto"
                      handleSubmit={() => {
                        history.push("/createneworder/order", {
                          type: "EXISTING",
                          code: props.code,
                        });
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        });
                      }}
                      text={"Existing pricing"}
                      styleBtn={{ marginBottom: "0.5rem" }}
                    />
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default OrderType;
