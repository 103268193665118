import { useApolloClient } from "@apollo/client";
import { isEmpty } from "lodash";
import moment from "moment";
import { createElement, FC, useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { notificationsCountSkip, path, responseMsgs } from "../../App";
import { ModuleName } from "../../enums";
import { CompanyType, useGetMeQuery } from "../../generated/graphql";
import { LocalStorageKeys } from "../../helpers/constants";
import { userHasModule } from "../../helpers/functions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ContainerDashboard from "../../layout/ContainerDashboard/ContainerDashboard";
import Main from "../../layout/Main/Main";
import Sidebar from "../../layout/Sidebar/Sidebar";
import ReceivePricingBids from "../../pages/ReceivePricingBids/ReceivePricingBids";
import CompanyInsuranceAlert from "../CompanyInsuranceAlert";

const AuthGuard = ({
  path: pathLink,
  exact,
  component,
  extraLayout,
  companyStatus = [],
  moduleName = null,
  roleIds,
  ...rest
}: {
  path: string;
  exact?: boolean;
  component: FC;
  extraLayout?: string;
  companyStatus?: any[];
  moduleName?: ModuleName | null;
  roleIds?: number[];
}) => {
  const [token, setToken] = useState<String | null>(null);
  const [insuranceCertificate, setInsuranceCertificate] = useState<any>(null);

  const history = useHistory();
  const location = useLocation();

  const { data, loading, error } = useGetMeQuery({
    skip: !localStorage.getItem(LocalStorageKeys.TOKEN),
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.getUser.roles![0].id === 4) {
        localStorage.removeItem(LocalStorageKeys.TOKEN);
        responseMsgs([
          { type: "error", string: "Driver can't access web app." },
        ]);
        history.push("/signin");
      }
    },
  });
  const client = useApolloClient();

  useEffect(() => {
    if (!isEmpty(data?.getUser?.company?.insuranceCertificate)) {
      setInsuranceCertificate(data?.getUser?.company?.insuranceCertificate);
    }
  }, [data?.getUser?.company?.insuranceCertificate]);

  document.addEventListener("visibilitychange", async (event) => {
    if (document.visibilityState === "visible") {
      if (localStorage.getItem(LocalStorageKeys.TOKEN)) {
        if (token !== localStorage.getItem(LocalStorageKeys.TOKEN)) {
          await client.cache.reset();
          notificationsCountSkip(false);
        }
      } else {
        await client.cache.reset();
        setToken(null);
      }
    }
  });

  useEffect(() => {
    path({ to: location.pathname, from: path().to });
  }, [location]);

  const showErrorMessage = (msg: string) => {
    responseMsgs([{ type: "error", string: msg }]);
  };

  const routeComponent = () => {
    if (!isEmpty(roleIds) && !loading) {
      if (!roleIds?.includes(data?.getUser.roles![0].id!)) {
        showErrorMessage("You don't have access to this page.");
        return <Redirect to={{ pathname: "/myorders" }} />;
      }
    }

    if (moduleName) {
      switch (moduleName) {
        case ModuleName.DOCUMENTS_AND_INVOICING:
          if (
            !userHasModule(data, ModuleName.DOCUMENTS_AND_INVOICING) &&
            !loading
          ) {
            showErrorMessage("You don't have access to this module.");
            return <Redirect to={{ pathname: "/myorders" }} />;
          }
          break;

        default:
          break;
      }
    }

    return loading || data?.getUser ? (
      companyStatus.length === 0 ? (
        createElement(component)
      ) : !companyStatus.includes(data?.getUser.company!.status) &&
        !loading ? null : (
        createElement(component)
      )
    ) : (
      <Redirect to={{ pathname: "/signin" }} />
    );
  };

  ErrorsBeHandler({
    error: error,
    fn: () => {
      localStorage.removeItem(LocalStorageKeys.TOKEN);
      history.push("/signin");
    },
  });

  SuccessBeHandler({
    data: data,
    code: "USER_VERIFIED",
    fn: () => {
      setToken(localStorage.getItem(LocalStorageKeys.TOKEN));
    },
  });
  const isExpiredInsurance = moment(insuranceCertificate?.expiryDate).isBefore(
    moment()
  );
  const shouldAlertBeDisplayed =
    (!insuranceCertificate?.id &&
      data?.getUser.company?.type === CompanyType.Transporter) ||
    isExpiredInsurance;

  return (
    <>
      <ContainerDashboard>
        <CompanyInsuranceAlert
          isOpen={shouldAlertBeDisplayed}
          isExpiredInsurance={isExpiredInsurance}
        />
        <Sidebar />
        <Main>
          {extraLayout === "ReceivePricingBids" ? (
            <ReceivePricingBids>
              <Route {...rest} render={routeComponent} />
            </ReceivePricingBids>
          ) : (
            <Route {...rest} render={routeComponent} />
          )}
        </Main>
      </ContainerDashboard>
    </>
  );
};

export default AuthGuard;
