import { Col, Row, Text } from "atomize";
import { FC } from "react";
import ContactCard from "../../../components/ContactCard/ContactCard";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  AssignedUserType,
  GetLocationQuery,
  GetOrderQuery,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";

interface Props {
  isLoading: boolean;
  parties: any;
  isAssigned: boolean;
  ass: any;
  order: GetOrderQuery | undefined;
  locationLoading: GetLocationQuery | undefined;
  locationUnloading: GetLocationQuery | undefined;
}

const PartiesInvolved: FC<Props> = ({
  isLoading,
  parties,
  isAssigned,
  ass,
  order,
  locationLoading,
  locationUnloading,
}) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
        <Col>
          <Text textSize={20} textColor="dark">
            Parties involved
          </Text>
        </Col>
      </Row>
      <Row
        m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}
        justify="auto"
      >
        {isLoading ? (
          <>
            <Col size={width > 880 ? "auto" : "12"}>
              <Skeleton
                height="calc(280px - 4rem)"
                width={width > 880 ? "280px" : "100%"}
                margin="0 0 0.5rem 0"
              />
            </Col>
            <Col size={width > 880 ? "auto" : "12"}>
              <Skeleton
                height="calc(280px - 4rem)"
                width={width > 880 ? "280px" : "100%"}
                margin="0 0 0.5rem 0"
              />
            </Col>
            <Col size={width > 880 ? "auto" : "12"}>
              <Skeleton
                height="calc(280px - 4rem)"
                width={width > 880 ? "280px" : "100%"}
                margin="0 0 0.5rem 0"
              />
            </Col>
          </>
        ) : (
          parties.map((party: any, i: number) => {
            return (
              <ContactCard
                key={i}
                isAssigned={isAssigned}
                assignedUsers={ass}
                order_id={order?.getOrder.id ? order?.getOrder.id : null}
                party={{
                  ...party,
                  loadingLocation: party.types.includes(
                    AssignedUserType.Shipper
                  )
                    ? locationLoading?.getLocation
                    : null,
                  unloadingLocation: party.types.includes(
                    AssignedUserType.Receiver
                  )
                    ? locationUnloading?.getLocation
                    : null,
                }}
              />
            );
          })
        )}
      </Row>
    </>
  );
};

export default PartiesInvolved;
