import { MutableRefObject, useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

function useAvailableElHeight(
  refEl: MutableRefObject<HTMLTableElement | null>
) {
  const [heightEl, setHeightEl] = useState(0);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    const element = refEl;
    let elH: number = 0;
    if (width > 700) {
      elH = height - element.current!.offsetTop;
    } else {
      elH = height - (element.current!.offsetTop + 38);
    }
    setHeightEl(elH - 80);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return heightEl;
}

export default useAvailableElHeight;
