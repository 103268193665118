import { useReactiveVar } from "@apollo/client";
import { Col, Div, Icon, Input, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { modalData, myOrdersPag } from "../../App";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import Skeleton from "../../components/Skeleton/Skeleton";
import { CompanyStatus, useGetMeQuery } from "../../generated/graphql";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { LocalStorageKeys } from "../../helpers/constants";
import { initialGetOrders } from "../../helpers/initialData";
import MyOrdersTable from "./table/MyOrdersTable";
import { isEqual } from "lodash";

const MyOrders: React.FC = () => {
  const { width } = useWindowDimensions();

  const myOrdersPagVar = useReactiveVar(myOrdersPag);
  const { data: me, loading: meLoading } = useGetMeQuery({
    errorPolicy: "all",
  });
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();

  const [k, setK] = useState<boolean>(false);
  const [total, setTotal] = useState(0);

  useDebouncedEffect(
    () => {
      if (searchInput === "") {
        const storageFilterQuery = localStorage.getItem(
          LocalStorageKeys.MY_ORDERS_FILTER
        );
        let storageLimitQuery: any = localStorage.getItem(
          LocalStorageKeys.LIMIT
        );
        let storageOrderByQuery: any = localStorage.getItem(
          LocalStorageKeys.ORDER_BY
        );
        let storageDescQuery: any = localStorage.getItem(LocalStorageKeys.DESC);
        let query: any = initialGetOrders;

        if (
          storageFilterQuery ||
          storageLimitQuery ||
          storageOrderByQuery ||
          storageDescQuery
        ) {
          if (storageFilterQuery) {
            query = { ...JSON.parse(storageFilterQuery) };
          }

          if (storageLimitQuery) {
            storageLimitQuery =
              storageLimitQuery === "OPD" ? null : Number(storageLimitQuery);
            query = {
              ...query,
              limit: storageLimitQuery ?? 10,
            };
          }

          if (storageOrderByQuery && storageDescQuery) {
            query = {
              ...query,
              orderBy: storageOrderByQuery,
              desc: storageDescQuery === "true",
            };
          }
        }
        if (!isEqual(query, myOrdersPagVar)) {
          myOrdersPag({
            ...query,
          });
        }
      } else {
        myOrdersPag({
          search: searchInput,
          limit: myOrdersPagVar.limit,
          orderBy: myOrdersPagVar.orderBy,
          desc: myOrdersPagVar.desc,
          page: 1,
        });
      }
    },
    [searchInput],
    500
  );

  useEffect(() => {
    const storageFilterQuery = localStorage.getItem(
      LocalStorageKeys.MY_ORDERS_FILTER
    );
    let storageLimitQuery: any = localStorage.getItem(LocalStorageKeys.LIMIT);

    let storageOrderByQuery: any = localStorage.getItem(
      LocalStorageKeys.ORDER_BY
    );
    let storageDescQuery: any = localStorage.getItem(LocalStorageKeys.DESC);
    let query: any = initialGetOrders;

    if (
      storageFilterQuery ||
      storageLimitQuery ||
      storageOrderByQuery ||
      storageDescQuery
    ) {
      if (storageFilterQuery) {
        query = { ...JSON.parse(storageFilterQuery) };
      }

      if (storageLimitQuery) {
        storageLimitQuery =
          storageLimitQuery === "OPD" ? null : Number(storageLimitQuery);
        query = {
          ...query,
          limit: storageLimitQuery ?? 10,
        };
      }

      if (storageOrderByQuery && storageDescQuery) {
        query = {
          ...query,
          orderBy: storageOrderByQuery,
          desc: storageDescQuery === "true",
        };
      }
    }
    const newQuery = {
      ...myOrdersPagVar,
      ...query,
    };

    if (!isEqual(newQuery, myOrdersPagVar)) {
      myOrdersPag(newQuery);
    }
    return () => {
      const storageFilterQuery = localStorage.getItem(
        LocalStorageKeys.MY_ORDERS_FILTER
      );
      let storageLimitQuery: any = localStorage.getItem(LocalStorageKeys.LIMIT);
      let query: any = initialGetOrders;
      if (myOrdersPag?.hasOwnProperty("search")) {
        if (storageFilterQuery || storageLimitQuery) {
          if (storageFilterQuery) {
            query = { ...JSON.parse(storageFilterQuery) };
          }

          if (storageLimitQuery) {
            storageLimitQuery =
              storageLimitQuery === "OPD" ? null : Number(storageLimitQuery);
            query = { ...query, limit: storageLimitQuery };
          }
        }
        myOrdersPag({
          ...query,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div>
      <Row
        m={{ r: width > 700 ? "1.5rem" : "0.5rem" }}
        align="center"
        overflow="hidden"
      >
        <Col d="flex" align="center" size="12" h="64px">
          <Text
            textSize={12}
            minW="60px"
            m={{ r: "0.5rem" }}
            textColor="semiDark"
            textWeight="500"
          >
            My orders
          </Text>
          {(me?.getUser.company?.status === CompanyStatus.Active ||
            meLoading ||
            total > 0) &&
          width > 440 ? (
            <Div w="-webkit-fill-available">
              <Input
                h="32px"
                p={{ x: "2rem" }}
                maxW="none"
                m={{ r: "0" }}
                borderColor="greyBorder"
                textSize="12"
                placeholder="Search..."
                value={searchInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchInput(e.target.value);
                }}
                prefix={
                  <Icon
                    name="Search"
                    size="16px"
                    cursor="pointer"
                    pos="absolute"
                    color="semiDark"
                    top="50%"
                    left="0.5rem"
                    transform="translateY(-50%)"
                  />
                }
              />
            </Div>
          ) : (
            <Div m={{ r: "auto" }}></Div>
          )}

          {meLoading ? (
            <Skeleton
              height="2rem"
              minWidth="40px"
              width="40px"
              margin="0 0 0 0.5rem"
            />
          ) : (
            (me?.getUser.company?.status === CompanyStatus.Active ||
              total > 0) && (
              <SecondaryBtn
                w="40px"
                h="2rem"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setK(true);
                }}
                styleBtn={{ margin: "0 0 0 0.5rem" }}
                isLoading={false}
                text={<Icon name="Refresh" size="16px" color="semiDark" />}
              />
            )
          )}

          {meLoading ? (
            <Skeleton
              height="2rem"
              minWidth="70px"
              width="70px"
              margin="0 0 0 0.5rem"
            />
          ) : (
            (me?.getUser.company?.status === CompanyStatus.Active ||
              total > 0) && (
              <SecondaryBtn
                prefixIcon={
                  <Icon
                    name="Edit"
                    size="16px"
                    color="semiDark"
                    m={{ r: "0.4rem" }}
                  />
                }
                w="70px"
                styleBtn={{ margin: "0 0 0 0.5rem" }}
                textSize={12}
                h="2rem"
                handleSubmit={() =>
                  modalData({
                    msg: "Control what you see",
                    name: "filterTable",
                    props: { setSearchInput: setSearchInput },
                    returned_value: null,
                  })
                }
                isLoading={false}
                text={"Filter"}
              />
            )
          )}
          {meLoading ? (
            <Skeleton
              height="2rem"
              minWidth="70px"
              width="70px"
              margin="0 0 0 0.5rem"
            />
          ) : (
            (me?.getUser.company?.status === CompanyStatus.Active ||
              total > 0) && (
              <SecondaryBtn
                prefixIcon={
                  <Icon
                    name="Eye"
                    size="16px"
                    color="semiDark"
                    m={{ r: "0.4rem" }}
                  />
                }
                w="70px"
                textSize={12}
                h="2rem"
                handleSubmit={() =>
                  modalData({
                    msg: "Segment your orders",
                    name: "viewTable",
                    returned_value: null,
                  })
                }
                styleBtn={{ marginLeft: "0.5rem" }}
                isLoading={false}
                text={"View"}
              />
            )
          )}
          {width > 780 &&
            (meLoading ? (
              <Skeleton
                height="2rem"
                minWidth="32px"
                width="32px"
                margin="0 0  0 0.5rem"
              />
            ) : (
              (me?.getUser.company?.status === CompanyStatus.Active ||
                total > 0) && (
                <PrimaryBtn
                  h="2rem"
                  w="2rem"
                  handleSubmit={() => history.push("/createneworder/order")}
                  styleBtn={{ marginLeft: "0.5rem" }}
                  isLoading={meLoading}
                  text={<Icon name="Plus" size="18px" color="background" />}
                />
              )
            ))}
        </Col>
        <Col size="12" d="flex" align="center">
          {(me?.getUser.company?.status === CompanyStatus.Active ||
            meLoading) &&
            width <= 440 && (
              <Div w="-webkit-fill-available">
                <Input
                  h="32px"
                  p={{ x: "2rem" }}
                  maxW="none"
                  m={{ b: "1.5rem" }}
                  borderColor="greyBorder"
                  textSize="12"
                  placeholder="Search..."
                  value={searchInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(e.target.value);
                  }}
                  prefix={
                    <Icon
                      name="Search"
                      size="16px"
                      cursor="pointer"
                      pos="absolute"
                      color="semiDark"
                      top="50%"
                      left="0.5rem"
                      transform="translateY(-50%)"
                    />
                  }
                />
              </Div>
            )}
        </Col>
      </Row>
      <MyOrdersTable k={k} setK={setK} total={total} setTotal={setTotal} />
    </Div>
  );
};

export default MyOrders;
