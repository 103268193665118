import { Col, Div, Row } from "atomize";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";

import DriversTable from "../../components/DriversTable/DriversTable";
import {
  CompanyStatus,
  CompanyType,
  useGetDriversQuery,
  useGetMeQuery,
} from "../../generated/graphql";
import { useDebouncedEffect } from "../../helpers/CustomHooks/useDebounceEffect";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import {
  InitialUsersPag,
  initialGetExternalDrivers,
  initialGetUsers,
} from "../../helpers/initialData";
import { modalData } from "../../App";
import { DefaultUserRole } from "../../enums";

const mainRoles = [
  DefaultUserRole.ADMIN,
  DefaultUserRole.COMPANY_OWNER,
  DefaultUserRole.DISPATCHER,
  DefaultUserRole.SUPER_DISPATCHER,
];

const SettingsDrivers: FC = () => {
  // History
  const history = useHistory();

  // Window dimensions
  const { height, width } = useWindowDimensions();

  const initialLimit = Math.floor(
    (height / 2 - (width > 700 ? 200 : 250)) / 49
  );

  // State variables
  const [usersSearchInput, setUsersSearchInput] = useState<string>("");
  const [externalDriversSearchInput, setExternalDriversSearchInput] =
    useState<string>("");
  const [usersPag, setUsersPag] = useState<InitialUsersPag>({
    ...initialGetUsers,
    limit: initialLimit,
  });
  const [externalDriversPag, setExternalDriversPag] = useState<InitialUsersPag>(
    {
      ...initialGetExternalDrivers,
      limit: initialLimit,
    }
  );
  const [totalPages, setTotalPages] = useState<{
    users: number;
    externalDrivers: number;
  }>({
    users: 0,
    externalDrivers: 0,
  });

  // Get me query
  const { data: me, loading: isLoadingMe } = useGetMeQuery({
    onCompleted: (me) => {
      if (
        me?.getUser.company?.status === CompanyStatus.WaitingConfirm ||
        me?.getUser.company?.type !== CompanyType.Transporter ||
        (me?.getUser.roles![0].id !== 2 &&
          me?.getUser.roles![0].id !== 1 &&
          me?.getUser.roles![0].id !== 6)
      ) {
        history.push("/settings/individual");
      }
    },
  });

  const canUserEdit = mainRoles.includes(me?.getUser?.roles?.[0]?.id ?? 0);
  const canUserDelete = mainRoles.includes(me?.getUser?.roles?.[0]?.id ?? 0);
  const canUserCreate = mainRoles.includes(me?.getUser?.roles?.[0]?.id ?? 0);

  // Tables queries
  const {
    data: users,
    loading: usersLoading,
    error: usersError,
  } = useGetDriversQuery({
    variables: {
      query: {
        ...usersPag,
        company_id: me?.getUser.company?.id,
        externalDriver: [0],
      },
      id: me?.getUser.company!.id!,
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
    skip: !me,
    onCompleted: (data) => {
      if (data?.getDrivers?.total)
        setTotalPages((prevState) => ({
          ...prevState,
          users: Math.ceil(
            (data?.getDrivers?.total ?? 0) / (usersPag.limit || 0)
          ),
        }));
    },
  });

  const {
    data: externalDrivers,
    loading: externalDriversLoading,
    error: externalDriversError,
  } = useGetDriversQuery({
    variables: {
      query: {
        ...externalDriversPag,
        company_id: me?.getUser.company?.id || null,
        externalDriver: [1],
      },
      id: me?.getUser.company!.id!,
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
    skip: !me,
    onCompleted: (data) => {
      if (data?.getDrivers?.total)
        setTotalPages((prevState) => ({
          ...prevState,
          externalDrivers: Math.ceil(
            (data?.getDrivers?.total ?? 0) / (externalDriversPag.limit || 0)
          ),
        }));
    },
  });

  const handleInternalDriversInvite = () =>
    modalData({
      msg: "Invite Driver",
      name: "inviteUser",
      returned_value: null,
      props: {
        company_id: me?.getUser.company?.id,
        user: me?.getUser,
        role_id: DefaultUserRole.DRIVER,
      },
    });

  const handleExternalDriversInvite = () =>
    modalData({
      msg: "Add external driver",
      name: "addExternalDriver",
      returned_value: null,
    });

  // Debounced search input
  useDebouncedEffect(
    () =>
      setUsersPag((prevState: any) => ({
        ...prevState,
        search: usersSearchInput,
      })),
    [usersSearchInput],
    usersSearchInput === "" ? 0 : 300
  );

  useDebouncedEffect(
    () =>
      setExternalDriversPag((prevState: any) => ({
        ...prevState,
        search: externalDriversSearchInput,
      })),
    [externalDriversSearchInput],
    externalDriversSearchInput === "" ? 0 : 300
  );

  // BE Error handlers
  ErrorsBeHandler({
    error: usersError,
  });

  ErrorsBeHandler({
    error: externalDriversError,
  });

  return (
    <Row m={{ x: "0rem" }}>
      <Col>
        <DriversTable
          title="Internal Drivers"
          searchInput={usersSearchInput}
          setSearchInput={setUsersSearchInput}
          usersLoading={usersLoading}
          users={users}
          me={me}
          isLoadingMe={isLoadingMe}
          totalPages={totalPages?.users}
          usersPag={usersPag}
          setUsersPag={setUsersPag}
          onDriverCreate={handleInternalDriversInvite}
          noResults={{
            text: "You have not made any Internal Drivers.",
            buttonText: "Invite drivers",
          }}
          userPermissions={{
            canUserEdit,
            canUserDelete,
            canUserCreate,
          }}
        />

        <Div h="1rem" />

        <DriversTable
          title="External Drivers"
          searchInput={externalDriversSearchInput}
          setSearchInput={setExternalDriversSearchInput}
          usersLoading={externalDriversLoading}
          users={externalDrivers}
          me={me}
          isLoadingMe={isLoadingMe}
          totalPages={totalPages?.externalDrivers}
          usersPag={externalDriversPag}
          setUsersPag={setExternalDriversPag}
          onDriverCreate={handleExternalDriversInvite}
          noResults={{
            text: "You have not made any External Drivers.",
          }}
          userPermissions={{
            canUserEdit,
            canUserDelete,
            canUserCreate,
          }}
        />
      </Col>
    </Row>
  );
};

export default SettingsDrivers;
