import React, { useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Div, Anchor } from "atomize";
import renderIcon from "../../assets/Icons";
import {
  BidListingItem,
  FeedbackType,
  useGetMeQuery,
} from "../../generated/graphql";
import DropdownTh from "../DropdownClassic/DropdownClassic";
import { GlobalTheme } from "../GlobalTheme";
import { useHistory } from "react-router";
import { modalData } from "../../App";

const BidsRowActions: React.FC<{ bid: BidListingItem; noLink?: boolean }> = ({
  bid,
  noLink,
}) => {
  const history = useHistory();
  const [actionDropdown, setActionDropdown] = useState<boolean>(false);
  const { data } = useGetMeQuery();

  return (
    <Tr
      styleTr={{
        borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
        borderRight: `1px solid ${GlobalTheme.colors.grey}`,
        background: GlobalTheme.colors.background,
      }}
    >
      <Td className="no_p" isActionTd={true}>
        {bid.company_id !== data?.getUser?.company?.id && (
          <Text
            textSize={12}
            textWeight="700"
            textColor="success"
            hoverTextColor="success900"
            cursor="pointer"
            transition
            // m={{ x: "0.75rem" }}
            onClick={() => {
              modalData({
                name: "acceptBid",
                props: {
                  id: bid.id,
                  price: bid.price,
                  company_id: bid.company_id,
                  order_id: bid.order_id,
                  expirationDate: bid.expirationDate,
                  loadingLocation_postName: bid.loadingLocation_postName,
                  unloadingLocation_postName: bid.unloadingLocation_postName,
                  requestedLoadingTime: bid.requestedLoadingTime,
                  requestedUnloadingTime: bid.requestedUnloadingTime,
                },
              });
              setActionDropdown(false);
            }}
          >
            ACCEPT
          </Text>
        )}
        <Text
          textSize={12}
          textWeight="700"
          textColor="danger"
          hoverTextColor="brand900"
          cursor="pointer"
          transition
          // m={{ l: (bid.company_id !== data?.getUser?.company?.id) ? "auto" : "0" }}
          onClick={() => {
            modalData({
              name:
                bid.company_id !== data?.getUser?.company?.id
                  ? "declineBid"
                  : "cancelBid",
              returned_value: null,
              props: {
                id: bid.id,
              },
            });
            setActionDropdown(false);
          }}
        >
          {bid.company_id !== data?.getUser?.company?.id ? "REJECT" : "CANCEL"}
        </Text>
        <Div w="auto">
          <DropdownTh
            h="auto"
            showDropIcon={false}
            border={false}
            direction="lefttop"
            style={{ width: "auto!important" }}
            isOpen={actionDropdown}
            menuList={
              <Div
                shadow={GlobalTheme.shadows[4]}
                style={{
                  background: GlobalTheme.colors.background,
                  padding: "0 0.5rem",
                  borderRadius: GlobalTheme.rounded[4],
                }}
                tabIndex={0}
              >
                {data?.getUser.roles![0].id !== 1 && (
                  <Div d="flex" align="center">
                    <Anchor
                      textSize={142}
                      textColor="semiDark"
                      hoverTextColor="dark"
                      d="block"
                      p={{ y: "0.25rem" }}
                      onClick={() => {
                        modalData({
                          msg: `Report`,
                          name: "report",
                          returned_value: null,
                          props: {
                            type: FeedbackType.ReportBid,
                            order_id: null,
                            bid_id: bid.id,
                            subtext:
                              "Please tell us why are you reporting this.",
                          },
                        });
                        setActionDropdown(false);
                      }}
                    >
                      Report bid
                    </Anchor>
                  </Div>
                )}

                {noLink !== true && (
                  <Div d="flex" align="center">
                    <Anchor
                      textSize={142}
                      textColor="semiDark"
                      hoverTextColor="dark"
                      d="block"
                      p={{ y: "0.25rem" }}
                      onClick={() => {
                        history.push(`/myorder/${bid.order_code}`);
                        setActionDropdown(false);
                      }}
                    >
                      {"View order"}
                    </Anchor>
                  </Div>
                )}
              </Div>
            }
            setIsOpen={setActionDropdown}
          >
            <Text
              w="auto"
              cursor="pointer"
              m={{ b: "6px" }}
              h="auto"
              hoverTextColor="info700"
            >
              {renderIcon("ActionDots")}
            </Text>
          </DropdownTh>
        </Div>
      </Td>
    </Tr>
  );
};

export default BidsRowActions;
