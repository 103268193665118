import * as Yup from "yup";

export const invoiceGenerateSchema = Yup.object().shape({
  amount: Yup.number()
    .nullable()
    .required({ name: "Amount", code: "REQUIRED_FIELD" }),
  invoiceNumber: Yup.string()
    .nullable()
    .required({ name: "Invoice Number", code: "REQUIRED_FIELD" }),
  paymentDueDate: Yup.date()
    .nullable()
    .required({ name: "Payment Due Date", code: "REQUIRED_FIELD" }),
  payeeCompanyId: Yup.string()
    .nullable()
    .required({ name: "Paying Company", code: "DATA_NOT_SELECTED" }),
  taxationId: Yup.string()
    .nullable()
    .required({ name: "Taxation", code: "DATA_NOT_SELECTED" }),
  currencyId: Yup.string()
    .nullable()
    .required({ name: "Currency", code: "DATA_NOT_SELECTED" }),
  note: Yup.string().nullable(),
});

export const invoiceSetupSchema = Yup.object().shape({
  amount: Yup.number()
    .nullable()
    .required({ name: "Amount", code: "REQUIRED_FIELD" }),
  paymentDueDate: Yup.date()
    .nullable()
    .required({ name: "Payment Due Date", code: "REQUIRED_FIELD" }),
  payeeCompanyId: Yup.string()
    .nullable()
    .required({ name: "Paying Company", code: "DATA_NOT_SELECTED" }),
});
