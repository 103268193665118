import { Col, Row, Tag, Text } from "atomize";
import { FC } from "react";
import Section from "../../../components/Section/Section";
import Skeleton from "../../../components/Skeleton/Skeleton";
import Table from "../../../components/TableComponents/Table";
import Tbody from "../../../components/TableComponents/Tbody";
import Td from "../../../components/TableComponents/Td";
import Th from "../../../components/TableComponents/Th";
import Thead from "../../../components/TableComponents/Thead";
import Tr from "../../../components/TableComponents/Tr";
import {
  GetLocationQuery,
  GetOrderQuery,
  TransportCargoType,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { getTransportCargoType } from "../../../helpers/functions";

interface Props {
  locationLoading: GetLocationQuery | undefined;
  locationUnloading: GetLocationQuery | undefined;
  order: GetOrderQuery | undefined;
  isLoading: boolean;
}

const TransportsDetailTable: FC<Props> = ({
  locationLoading,
  locationUnloading,
  order,
  isLoading,
}) => {
  const { width } = useWindowDimensions();

  return (
    <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      {((locationLoading && locationUnloading && order) || isLoading) && (
        <>
          <Col size="12">
            <Text textSize={20} textColor="dark">
              Transport details
            </Text>
          </Col>
          <Col size="12">
            <Section margin="1.25rem 0 1.25rem 0rem">
              {isLoading ? (
                <Skeleton height="100px" />
              ) : (
                <Table minWidth="1400px">
                  <Thead>
                    <Tr className="head" hidBorderLast={true}>
                      <Th width={"8%"}>
                        <Text textColor="dark" textWeight="500">
                          Quantity
                        </Text>
                      </Th>
                      <Th width={"12%"}>
                        <Text textColor="dark" textWeight="500">
                          Type
                        </Text>
                      </Th>
                      <Th width={"15%"}>
                        <Text textColor="dark" textWeight="500">
                          Volume
                        </Text>
                      </Th>
                      <Th width={"15%"}>
                        <Text textColor="dark" textWeight="500">
                          Weight
                        </Text>
                      </Th>
                      <Th width={"19%"}>
                        <Text textColor="dark" textWeight="500">
                          L x W x H
                        </Text>
                      </Th>
                      <Th width={"8%"}>
                        <Text textColor="dark" textWeight="500">
                          Dangerous?
                        </Text>
                      </Th>
                      <Th width={"8%"}>
                        <Text textColor="dark" textWeight="500">
                          Stackable?
                        </Text>
                      </Th>
                      <Th width={"15%"}>
                        <Text textColor="dark" textWeight="500">
                          Cost
                        </Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody maxH="none">
                    {order?.getOrder.transports &&
                    order?.getOrder.transports[0] &&
                    order?.getOrder.transports[0].parcels &&
                    order?.getOrder.transports[0].parcels.length &&
                    order?.getOrder.transports[0].cargoType !==
                      TransportCargoType.Ftl ? (
                      order?.getOrder.transports[0].parcels.map(
                        (parcel: any, i: number) => (
                          <Tr key={i}>
                            <Td width={"8%"}>
                              <Text
                                textColor={
                                  parcel.quantity ? "dark" : "semiDark"
                                }
                                textWeight="500"
                              >
                                {parcel.quantity ? parcel.quantity : "1"}
                              </Text>
                            </Td>
                            <Td width={"12%"}>
                              <Tag
                                bg={
                                  order?.getOrder.transports &&
                                  order?.getOrder.transports[0].type
                                    ? `warning500`
                                    : "grey"
                                }
                                textColor={`dark`}
                                textWeight="500"
                                m={{ r: "0.5rem", b: "0rem" }}
                              >
                                {order?.getOrder.transports &&
                                order?.getOrder.transports[0].cargoType
                                  ? getTransportCargoType(
                                      order?.getOrder.transports[0].cargoType
                                    )
                                  : "FTL"}
                              </Tag>
                            </Td>
                            <Td width={"15%"}>
                              <Text textColor={"dark"}>
                                <span style={{ fontWeight: 600 }}>
                                  {" "}
                                  {parcel.volume
                                    ? `${parcel.volume} LDM`
                                    : "None"}
                                </span>
                              </Text>
                            </Td>
                            <Td width={"15%"}>
                              <Text textColor={"dark"}>
                                <span style={{ fontWeight: 600 }}>
                                  {" "}
                                  {parcel.weight
                                    ? `${parcel.weight} kg`
                                    : "None"}
                                </span>
                              </Text>
                            </Td>
                            <Td width={"19%"}>
                              <Text textColor={"dark"}>
                                {typeof parcel.length === "number" ||
                                typeof parcel.width === "number" ||
                                typeof parcel.height === "number" ? (
                                  <>
                                    <span style={{ fontWeight: 600 }}>
                                      {typeof parcel.length === "number" &&
                                        parcel.length}
                                    </span>{" "}
                                    <span>
                                      {typeof parcel.length === "number" &&
                                        "cm x "}
                                    </span>
                                    <span style={{ fontWeight: 600 }}>
                                      {typeof parcel.width === "number" &&
                                        parcel.width}
                                    </span>{" "}
                                    <span>
                                      {typeof parcel.width === "number" &&
                                        "cm x "}
                                    </span>
                                    <span style={{ fontWeight: 600 }}>
                                      {typeof parcel.height === "number" &&
                                        parcel.height}
                                    </span>{" "}
                                    <span>
                                      {typeof parcel.height === "number" &&
                                        "cm"}
                                    </span>
                                  </>
                                ) : (
                                  "None"
                                )}
                              </Text>
                            </Td>
                            <Td width={"8%"}>
                              <Text
                                textWeight="600"
                                textColor={
                                  order?.getOrder.transports &&
                                  order?.getOrder.transports[0].dangerous
                                    ? "dark"
                                    : "semiDark"
                                }
                              >
                                {order?.getOrder.transports &&
                                order?.getOrder.transports[0].dangerous
                                  ? "Yes"
                                  : "No"}
                              </Text>
                            </Td>
                            <Td width={"8%"}>
                              <Text
                                textWeight="600"
                                textColor={
                                  order?.getOrder.transports &&
                                  order?.getOrder.transports[0].stackable
                                    ? "dark"
                                    : "semiDark"
                                }
                              >
                                {order?.getOrder.transports &&
                                order?.getOrder.transports[0].stackable
                                  ? "Yes"
                                  : "No"}
                              </Text>
                            </Td>
                            <Td width={"15%"}>
                              <Text textColor={"dark"}>
                                <span>
                                  {order?.getOrder.price
                                    ? `${order?.getOrder.price} EUR`
                                    : "None"}{" "}
                                </span>
                              </Text>
                            </Td>
                          </Tr>
                        )
                      )
                    ) : (
                      <Tr>
                        <Td width={"8%"}>
                          <Text textColor={"dark"} textWeight="500">
                            {"1"}
                          </Text>
                        </Td>
                        <Td width={"12%"}>
                          <Tag
                            bg={
                              order?.getOrder.transports &&
                              order?.getOrder.transports[0].type
                                ? `warning500`
                                : "grey"
                            }
                            textColor={`dark`}
                            textWeight="500"
                            m={{ r: "0.5rem", b: "0rem" }}
                          >
                            {order?.getOrder.transports &&
                            order?.getOrder.transports[0].cargoType
                              ? getTransportCargoType(
                                  order?.getOrder.transports[0].cargoType
                                )
                              : "FTL"}
                          </Tag>
                        </Td>
                        <Td width={"15%"}>
                          <Text textColor={"dark"}>
                            <span>None</span>
                          </Text>
                        </Td>
                        <Td width={"15%"}>
                          <Text textColor={"dark"}>
                            <span>None</span>
                          </Text>
                        </Td>
                        <Td width={"19%"}>
                          <Text textColor={"dark"}>None</Text>
                        </Td>
                        <Td width={"8%"}>
                          <Text
                            textWeight="600"
                            textColor={
                              order?.getOrder.transports &&
                              order?.getOrder.transports[0].dangerous
                                ? "dark"
                                : "semiDark"
                            }
                          >
                            {order?.getOrder.transports &&
                            order?.getOrder.transports[0].dangerous
                              ? "Yes"
                              : "No"}
                          </Text>
                        </Td>
                        <Td width={"8%"}>
                          <Text
                            textWeight="600"
                            textColor={
                              order?.getOrder.transports &&
                              order?.getOrder.transports[0].stackable
                                ? "dark"
                                : "semiDark"
                            }
                          >
                            {order?.getOrder.transports &&
                            order?.getOrder.transports[0].stackable
                              ? "Yes"
                              : "No"}
                          </Text>
                        </Td>
                        <Td width={"15%"}>
                          <Text textColor={"dark"}>
                            <span>
                              {order?.getOrder.price
                                ? `${order?.getOrder.price} EUR`
                                : "None"}{" "}
                            </span>
                          </Text>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              )}
            </Section>
          </Col>
        </>
      )}
    </Row>
  );
};

export default TransportsDetailTable;
