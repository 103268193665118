import { Moment } from "moment";

export enum ExportOptionValues {
  All = "all",
  Orders = "orders",
  Documents = "documents",
}

export interface ExportConfig {
  from: string | Date | Moment | null | undefined;
  to: string | Date | Moment | null | undefined;
  enableCustomTimeFrame: boolean;
  exportMethod: ExportOptionValues;
  selectedColumns: string[];
}

export interface ExportConfigPayload {
  from?: string | Date | Moment | null;
  to?: string | Date | Moment | null;
  selectedColumns: string[] | undefined;
  orders: boolean;
  documents: boolean;
}

export const ExportOptions = [
  {
    name: "Orders & Documents",
    value: ExportOptionValues.All,
  },
  {
    name: "Orders only",
    value: ExportOptionValues.Orders,
  },
  {
    name: "Documents only",
    value: ExportOptionValues.Documents,
  },
];
