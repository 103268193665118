import React, { useEffect } from "react";
import * as Styled from "./Notification.styles";
import { Notification as Noti } from "atomize";
import { responseMsgs } from "../../App";
import { useReactiveVar } from "@apollo/client";

interface MsgsProps {
  type: string;
  string: string;
}

const Notification: React.FC = () => {
  const responseMsgsVar = useReactiveVar(responseMsgs);

  useEffect(() => {
    let timeId: any;
    if (responseMsgsVar.length > 0) {
      timeId = setTimeout(() => {
        responseMsgs([]);
      }, 3000);
    }

    return () => {
      clearTimeout(timeId);
    };
    // eslint-disable-next-line
  }, [responseMsgsVar]);

  return (
    <Styled.Notification>
      {responseMsgsVar?.length > 0 &&
        responseMsgsVar.map((msg: MsgsProps, i: number) => {
          return (
            <Noti
              key={i}
              bg={
                msg.type === "success"
                  ? "success700"
                  : msg.type === "warning"
                  ? "warning700"
                  : msg.type === "notification"
                  ? "info700"
                  : "danger700"
              }
              isOpen={true}
              top={``}
              m={{ b: "1rem" }}
              bottom={`${(i + 1) * 4}rem`}
              pos="static"
            >
              {msg.string}
            </Noti>
          );
        })}
    </Styled.Notification>
  );
};

export default Notification;
