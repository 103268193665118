import React from "react";
import { useHistory } from "react-router";
import { Text, Div } from "atomize";
import renderIcon from "../../assets/Icons";
import { useGetMeQuery } from "../../generated/graphql";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";

const Page404: React.FC = () => {
  let history = useHistory();
  const { data, loading } = useGetMeQuery();
  const { height } = useWindowDimensions();

  return (
    <Div
      w="100%"
      flexDir="column"
      justify="center"
      align="center"
      minH={`${height}px`}
      d="flex"
    >
      {renderIcon("Logo")}
      <Text textSize={100} textWeight={600} m={{ t: "2rem" }}>
        404
      </Text>
      <Text
        textSize={20}
        textColor="semiLight"
        textWeight={500}
        m={{ b: "1rem" }}
      >
        Page not found
      </Text>
      <PrimaryBtn
        h="48px"
        w="100%"
        styleBtn={{ marginTop: "1.5rem", maxWidth: "334px" }}
        handleSubmit={() => history.push(data ? "/myorders" : "/")}
        isLoading={loading}
        text={"Go back"}
      />
    </Div>
  );
};

export default Page404;
