/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Label, Radiobox, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  FeedbackType,
  useCreateFeedbackMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import DangerBtn from "../../DangerBtn/DangerBtn";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const initial = {
  message: "",
  type: FeedbackType.Feedback,
  object_id: null,
};

const ObjectOfCommonReports = {
  first: { msg: "Offensive and inappropriate language", checked: false },
  second: { msg: "Misleading price", checked: false },
  third: { msg: "Does not respond to communication requests", checked: false },
  other: { msg: "Other", checked: true },
};

const schema = yup.object().shape({
  type: yup.string(),
  report: yup.object().shape(
    {
      paramsMsgs: yup.string().when("message", {
        is: (message: string) => !message,
        then: yup.string().trim().required({ code: "" }),
      }),
      message: yup.string().when("paramsMsgs", {
        is: (paramsMsgs: string) => !paramsMsgs,
        then: yup.string().trim().required({ code: "EMPTY_DATA" }),
      }),
    },
    [
      ["paramsMsgs", "message"],
      ["message", "paramsMsgs"],
    ]
  ),
});

const Report: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);
  const [createReport, { data, loading, error }] = useCreateFeedbackMutation({
    errorPolicy: "all",
  });
  const [paramMsg, setParamMsg] = useState("Other");

  const [report, setReport] = useState(initial);
  const [reports, setReports] = useState(ObjectOfCommonReports);

  useEffect(() => {
    if (name === "report") {
      const object_id = props.bid_id
        ? props.bid_id
        : props.order_id
        ? props.order_id
        : null;
      setReports(ObjectOfCommonReports);
      setReport({
        ...report,
        type: props.type,
        object_id,
      });
    }
  }, [name]);

  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setReport({
      ...report,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validator({
      schema: schema,
      data: {
        type: report.type,
        report: {
          message: report.message,
          paramsMsgs: JSON.stringify([paramMsg]),
        },
      },
      success: () => {
        createReport({
          variables: {
            feedback: { ...report, params: JSON.stringify([paramMsg]) },
          },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: error,
    dep: name === "report",
  });

  SuccessBeHandler({
    data: data,
    code: "REPORT_CREATED",
    dep: name === "report",
    fn: () => {
      setReport(initial);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "report")}
      onClose={() => {
        setReport(initial);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "report" && (
        <Div
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="500"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {msg}
          </Text>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "2rem" }}
          >
            {props.subtext}
          </Text>
          <Row>
            <Col size="12">
              <Label
                align="center"
                d="flex"
                flexWrap="nowrap"
                flexDir="row"
                textTransform="capitalize"
                textWeight="500"
                m={{ b: "0.5rem" }}
              >
                <Radiobox
                  onChange={() => {
                    setReports({
                      first: {
                        msg: "Offensive and inappropriate language",
                        checked: true,
                      },
                      second: { msg: "Misleading price", checked: false },
                      third: {
                        msg: "Does not respond to communication requests",
                        checked: false,
                      },
                      other: { msg: "Other", checked: false },
                    });
                    setParamMsg("Offensive and inappropriate language");
                  }}
                  checked={reports.first.checked}
                />
                <Text>{reports.first.msg}</Text>
              </Label>
            </Col>

            <Col size="12">
              <Label
                align="center"
                d="flex"
                flexWrap="nowrap"
                flexDir="row"
                textTransform="capitalize"
                textWeight="500"
                m={{ b: "0.5rem" }}
              >
                <Radiobox
                  onChange={() => {
                    setReports({
                      first: {
                        msg: "Offensive and inappropriate language",
                        checked: false,
                      },
                      second: { msg: "Misleading price", checked: true },
                      third: {
                        msg: "Does not respond to communication requests",
                        checked: false,
                      },
                      other: { msg: "Other", checked: false },
                    });
                    setParamMsg("Misleading price");
                  }}
                  checked={reports.second.checked}
                />
                <Text>{reports.second.msg}</Text>
              </Label>
            </Col>

            <Col size="12">
              <Label
                align="center"
                d="flex"
                flexWrap="nowrap"
                flexDir="row"
                textTransform="capitalize"
                textWeight="500"
                m={{ b: "0.5rem" }}
              >
                <Radiobox
                  onChange={() => {
                    setReports({
                      first: {
                        msg: "Offensive and inappropriate language",
                        checked: false,
                      },
                      second: { msg: "Misleading price", checked: false },
                      third: {
                        msg: "Does not respond to communication requests",
                        checked: true,
                      },
                      other: { msg: "Other", checked: false },
                    });
                    setParamMsg("Does not respond to communication requests");
                  }}
                  checked={reports.third.checked}
                />
                <Text>{reports.third.msg}</Text>
              </Label>
            </Col>

            <Col size="12">
              <Label
                align="center"
                textTransform="capitalize"
                textWeight="500"
                m={{ b: "0.5rem" }}
              >
                <Radiobox
                  onChange={() => {
                    setReports({
                      first: {
                        msg: "Offensive and inappropriate language",
                        checked: false,
                      },
                      second: { msg: "Misleading price", checked: false },
                      third: {
                        msg: "Does not respond to communication requests",
                        checked: false,
                      },
                      other: { msg: "Other", checked: true },
                    });
                    setParamMsg("Other");
                  }}
                  checked={reports.other.checked}
                />
                {reports.other.msg}
              </Label>
            </Col>
          </Row>
          <Row d="column">
            {
              <Col>
                <Input
                  value={report.message}
                  placeholder="Tell us more about this issue..."
                  name="message"
                  m={{ b: "2rem" }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleChange(e)
                  }
                />
              </Col>
            }
          </Row>
          <Row>
            <Col size="auto">
              {FeedbackType.ReportError === props.type ? (
                <PrimaryBtn
                  w="120px"
                  handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                    handleSubmit(e)
                  }
                  isLoading={loading}
                  text={"Send"}
                />
              ) : (
                <DangerBtn
                  w="144px"
                  handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                    handleSubmit(e)
                  }
                  isLoading={loading}
                  text={"Send report"}
                />
              )}
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setReport(initial);
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loading}
                text={"Cancel"}
              />
            </Col>
          </Row>
          {/* <Row >
                        <Col d="flex">
                            <Button
                                h="48px"
                                w="100%"
                                bg="danger"
                                hoverBg="brand900"
                                p={{ xs: '0.6rem' }}
                                textSize={12}
                                m={{ t: "1.5rem", r: "0.5rem" }}
                                onClick={() => {
                                    setReport(initial)
                                    modalData({
                                        name: "",
                                        msg: "",
                                        returned_value: null,
                                        props: null
                                    })
                                }
                                }
                            >
                                {loading ?
                                    <Icon name="Loading" color="background" size="20px" />
                                    :
                                    "Cancel"
                                }
                            </Button>
                            <Button
                                h="48px"
                                w="100%"
                                bg="primary"
                                hoverBg="info700"
                                p={{ xs: '0.6rem' }}
                                textSize={12}
                                m={{ t: "1.5rem", l: "0.5rem" }}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => handleSubmit(e)}
                                disabled={loading}
                            >


                                {loading ?
                                    <Icon name="Loading" color="background" size="20px" />
                                    :
                                    "Report"
                                }
                            </Button>
                        </Col>
                    </Row> */}
        </Div>
      )}
    </StyledModal>
  );
};

export default Report;
