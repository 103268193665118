import React from "react";
import * as Styled from "./SortIcon.styles";
import { LocalStorageKeys } from "../../helpers/constants";

interface SortIconProps {
  sortBy: { desc: boolean; orderBy: string };
  setSortBy: (sortBy: { desc: boolean; orderBy: string }) => void;
  name: string;
  shouldUpdateLocalStorage?: boolean;
}

const SortIcon: React.FC<SortIconProps> = ({
  sortBy,
  setSortBy,
  name,
  shouldUpdateLocalStorage = false,
}) => {
  const handleSort = () => {
    if (shouldUpdateLocalStorage) {
      localStorage?.setItem(LocalStorageKeys.ORDER_BY, name);
      localStorage?.setItem(LocalStorageKeys.DESC, String(!sortBy.desc));
    }

    setSortBy({
      ...sortBy,
      orderBy: name,
      desc: !sortBy.desc,
    });
  };

  return (
    <Styled.SortIcon onClick={handleSort} className="sort_icon">
      <Styled.Line
        className="top"
        width={sortBy.orderBy === name && !sortBy.desc ? "10px" : "5px"}
      ></Styled.Line>
      <Styled.Line className="middle" width={"7.5px"}></Styled.Line>
      <Styled.Line
        className="bottom"
        width={sortBy.orderBy === name && !sortBy.desc ? "5px" : "10px"}
      ></Styled.Line>
    </Styled.SortIcon>
  );
};

export default SortIcon;
