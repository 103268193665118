import {
  BidStatus,
  LocationStatus,
  Maybe,
  NotificationMessageStatus,
  NotificationStatus,
  NotificationType,
  OrderStatus,
  SqlModelStatus,
  UserStatus,
} from "../generated/graphql";
import { NotificationTypeFilterOption } from "../pages/Inbox/utils/helpers";

export const initialPlacedQueryLink = {
  status: [BidStatus.Pending],
  orderBy: "price",
  desc: true,
  page: 1,
  limit: 5,
};

export const initialPlacedQueryPagination = {
  status: [BidStatus.Pending],
  orderBy: "id",
  desc: true,
  page: 1,
  limit: null,
};

export const initialReceivedQueryLink = {
  status: [BidStatus.Pending],
  orderBy: "price",
  desc: true,
  page: 1,
  limit: null,
};

export const initialReceivedQueryPagination = {
  status: [BidStatus.Pending],
  orderBy: "id",
  desc: true,
  page: 1,
  limit: null,
};

export const initialReceivedOrderQueryPagination = {
  status: [BidStatus.Pending],
  desc: true,
  orderBy: "price",
  limit: 5,
  page: 1,
  order_id: null,
};

export const initialActivityQueryPagination = {
  desc: true,
  orderBy: "ts",
  limit: 5,
  page: 1,
  order_id: null,
};

export const initialMarketQueryLink = {
  status: [OrderStatus.OnMarket],
  loadingTime_from: null,
  loadingTime_to: null,
  unloadingTime_from: null,
  unloadingTime_to: null,
  user_id: null,
  loadingPostCode: null,
  loadingPostName: null,
  loadingCountry_id: null,
  unloadingPostCode: null,
  unloadingPostName: null,
  unloadingCountry_id: null,
  company_id: null,
  desc: true,
  orderBy: "id",
  page: 1,
  limit: 5,
};

export const initialMarketQueryPagination = {
  status: [OrderStatus.OnMarket],
  loadingTime_from: null,
  loadingTime_to: null,
  unloadingTime_from: null,
  unloadingTime_to: null,
  user_id: null,
  loadingPostCode: null,
  loadingPostName: null,
  loadingCountry_id: null,
  unloadingPostCode: null,
  unloadingPostName: null,
  unloadingCountry_id: null,
  location_id: null,
  company_id: null,
  desc: true,
  orderBy: "id",
  page: 1,
  limit: null,
};

export const initialMyLocationsPagination = {
  status: [LocationStatus.Deactivated, LocationStatus.Active],
  company_id: null,
  limit: null,
  page: 1,
};

export const initialGetNotifications = {
  status: [
    NotificationStatus.Unread,
    NotificationStatus.Read,
    NotificationStatus.EmailSent,
  ],
  limit: 40,
  page: 1,
  orderBy: "id",
  desc: true,
};

export interface MyOrdersPage {
  status: OrderStatus[];
  loadingTime_from: null | string;
  loadingTime_to: null | string;
  unloadingTime_from: null | string;
  unloadingTime_to: null | string;
  user_ids: null | number[];
  loadingPostCode: null | string;
  loadingPostName: null | string;
  loadingCountry_id: null | string;
  unloadingPostCode: null | string;
  unloadingPostName: null | string;
  unloadingCountry_id: null | string;
  company_ids: null | number[];
  desc: boolean;
  orderBy: string;
  page: number;
  limit: number | null;
  hasInternalCode: null | string;
  driver_ids: null | number[];
  vehicleRegistrations: null | string[];
}

export const initialGetOrders: MyOrdersPage = {
  status: [
    OrderStatus.Draft,
    OrderStatus.Cancelled,
    OrderStatus.Completed,
    OrderStatus.Damaged,
    OrderStatus.Deleted,
    OrderStatus.InTransit,
    OrderStatus.Flagged,
    OrderStatus.OnHold,
    OrderStatus.OnMarket,
    OrderStatus.Pending,
    OrderStatus.Ready,
    OrderStatus.Stored,
  ],
  loadingTime_from: null,
  loadingTime_to: null,
  unloadingTime_from: null,
  unloadingTime_to: null,
  user_ids: null,
  loadingPostCode: null,
  loadingPostName: null,
  loadingCountry_id: null,
  unloadingPostCode: null,
  unloadingPostName: null,
  unloadingCountry_id: null,
  company_ids: null,
  desc: true,
  orderBy: "id",
  page: 1,
  limit: 100,
  hasInternalCode: null,
  driver_ids: null,
  vehicleRegistrations: null,
};

export interface InitialUsersPag {
  status: UserStatus[];
  company_id?: Maybe<number> | undefined;
  limit: null | number;
  page: number;
  externalDriver?: null | number;
  search?: string;
}

export const initialGetUsers: InitialUsersPag = {
  status: [UserStatus.Active, UserStatus.Incomplete],
  company_id: null,
  limit: null,
  page: 1,
};

export const initialGetExternalDrivers: InitialUsersPag = {
  status: [UserStatus.Active, UserStatus.Incomplete],
  company_id: null,
  limit: null,
  page: 1,
  externalDriver: 1,
};

export const initialGetDocuments = {
  search: "",
  company_id: null,
  limit: null,
  page: 1,
  orderBy: "date",
  desc: true,
};

export const initialGetNotificatinMessages = {
  search: "",
  status: [
    NotificationMessageStatus.Draft,
    NotificationMessageStatus.Scheduled,
    NotificationMessageStatus.Sent,
  ],
  desc: true,
  orderBy: "scheduleDate",
  company_id: null,
  limit: null,
  page: 1,
};

export const initialModulePermissions = {
  search: "",
  status: [],
  desc: true,
  orderBy: "company_id",
  limit: null,
  page: 1,
};

export const initialMyVehiclesPagination = {
  search: "",
  status: [
    SqlModelStatus.Draft,
    SqlModelStatus.Active,
    SqlModelStatus.Deactivated,
  ],
  desc: true,
  orderBy: "createTime",
  company_id: null,
  limit: null,
  page: 1,
};

export const initialGetOrderDocuments = {
  search: "",
  company_id: null,
  order_id: null,
  limit: 5,
  page: 1,
  orderBy: "date",
  desc: true,
};

export interface MyOrderView {
  field: string;
  name: string;
  checked: boolean;
  colW: number;
}

export const initialView: MyOrderView[] = [
  { field: "code", name: "Order ID", checked: true, colW: 100 },
  { field: "internalCode", name: "Internal number", checked: true, colW: 130 },
  { field: "status", name: "Status", checked: true, colW: 150 },
  { field: "vehicleRegistration", name: "Vehicle", checked: true, colW: 140 },
  { field: "dispatchers", name: "Dispatcher", checked: true, colW: 180 },

  { field: "shipperCompany_name", name: "Shipper", checked: true, colW: 200 },
  { field: "receiverCompany_name", name: "Receiver", checked: true, colW: 200 },
  {
    field: "transporterCompany_name",
    name: "Transporter",
    checked: true,
    colW: 200,
  },

  {
    field: "loadingLocation_country_name",
    name: "Loading Country",
    checked: true,
    colW: 140,
  },
  {
    field: "loadingLocation_postName",
    name: "Loading city",
    checked: true,
    colW: 140,
  },
  {
    field: "loadingLocation_postCode",
    name: "Loading post code",
    checked: true,
    colW: 160,
  },
  {
    field: "loadingLocation_address",
    name: "Loading address",
    checked: true,
    colW: 200,
  },

  {
    field: "unloadingLocation_country_name",
    name: "Unloading Country",
    checked: true,
    colW: 140,
  },
  {
    field: "unloadingLocation_postName",
    name: "Unloading city",
    checked: true,
    colW: 140,
  },
  {
    field: "unloadingLocation_postCode",
    name: "Unloading post code",
    checked: true,
    colW: 160,
  },
  {
    field: "unloadingLocation_address",
    name: "Unloading address",
    checked: true,
    colW: 200,
  },

  { field: "stackable", name: "Stackable", checked: true, colW: 90 },
  { field: "dangerous", name: "Dangerous", checked: true, colW: 90 },

  {
    field: "requestedLoadingTime",
    name: "Loading date",
    checked: true,
    colW: 140,
  },
  {
    field: "requestedUnloadingTime",
    name: "Unloading date",
    checked: true,
    colW: 140,
  },
  {
    field: "marketExpirationDate",
    name: "Expires in",
    checked: true,
    colW: 130,
  },
  {
    field: "price",
    name: "Price",
    checked: true,
    colW: 100,
  },
  {
    field: "hasPoD",
    name: "Has PoD",
    checked: true,
    colW: 100,
  },
  {
    field: "hasInvoice",
    name: "Has Invoice",
    checked: true,
    colW: 100,
  },
];

export const initialInboxFilters: NotificationTypeFilterOption[] = [
  {
    label: "Order updates",
    value: [NotificationType.OrderStatus],
    isSelected: false,
  },
  {
    label: "Warnings",
    value: [
      NotificationType.DeliveryChangeRequest,
      NotificationType.DeliveryChanged,
    ],
    isSelected: false,
  },
  {
    label: "Messages",
    value: [NotificationType.Message],
    isSelected: false,
  },
  {
    label: "Documents",
    value: [NotificationType.Document],
    isSelected: false,
  },
  {
    label: "Assignments",
    value: [NotificationType.Assigned, NotificationType.Unassigned],
    isSelected: false,
  },
  { label: "Reminders", value: [NotificationType.Custom], isSelected: false },
  {
    label: "Bids",
    value: [
      NotificationType.BidReceived,
      NotificationType.BidCancelled,
      NotificationType.BidAccepted,
    ],
    isSelected: false,
  },
];
