import { useReactiveVar } from "@apollo/client";
import {
  Col,
  Collapse,
  Div,
  Icon,
  Input,
  Label,
  Radiobox,
  Row,
  Switch,
  Text,
} from "atomize";
import moment from "moment";
import { tz } from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useLocation, useParams } from "react-router";
import { modalData } from "../../../App";
import Border from "../../../components/Border/Border";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  GetOrdersDocument,
  TransportCargoType,
  useGetCompanyLazyQuery,
  useGetLocationLazyQuery,
  useGetMeQuery,
  useGetOrderQuery,
  useUpdateTransportMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { OrderContext } from "../ReceivePricingBids";
import { transportOnlySchema as schema } from "../schema.validation.";
import { loadingInitialState } from "./../loading.reducer";

const CreateOrder: React.FC = () => {
  // Location and routing hooks/variables
  const { code: codes } = useParams<{ code: string }>();
  const location = useLocation<any>();
  const code = codes || location.state?.code;
  const history = useHistory();

  // Window dimensions
  const { width, height } = useWindowDimensions();

  // Order context
  const { loadingState, dispatch }: any = useContext(OrderContext);

  // Reactive vars
  const { returned_value } = useReactiveVar(modalData);

  // State variables
  const [detailsChanged, setDetailsChanged] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<{
    transport: boolean;
    pricing: boolean;
  }>({
    transport: false,
    pricing: false,
  });

  // Validation functions
  const { validator } = ValidationsFeHandler();

  // Mutations
  const [
    updateTransport,
    {
      data: updateTransportData,
      loading: updateTransportLoading,
      error: updateTransportError,
    },
  ] = useUpdateTransportMutation({ errorPolicy: "all" });

  // Lazy queries
  const [
    getReceiverCompany,
    { data: companyReceiver, loading: companyReceiverLoading },
  ] = useGetCompanyLazyQuery();
  const [
    getShipperCompany,
    { data: companyShipper, loading: companyShipperLoading },
  ] = useGetCompanyLazyQuery();
  const [
    getLoadingLocations,
    { data: locationLoading, loading: locationLoadingLoading },
  ] = useGetLocationLazyQuery();
  const [
    getUnloadingLocations,
    { data: locationUnloading, loading: locationUnloadingLoading },
  ] = useGetLocationLazyQuery();

  // Queries
  const { data: meData, loading: meLoading } = useGetMeQuery({
    onCompleted: (data) => {
      dispatch({
        type: "USER_TIMEZONE",
        field: "timezone",
        payload: data.getUser.company?.location?.timezone,
      });
    },
  });

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
  } = useGetOrderQuery({
    variables: { code },
    onCompleted: () => {
      if (order?.getOrder.transports) {
        getLoadingLocations({
          variables: {
            id: Number(order?.getOrder.transports[0].loadingLocation_id),
          },
        });
        getUnloadingLocations({
          variables: {
            id: Number(order?.getOrder.transports[0].unloadingLocation_id),
          },
        });
        getShipperCompany({
          variables: {
            id: Number(order?.getOrder.transports[0].shipperCompany_id),
          },
        });
        getReceiverCompany({
          variables: {
            id: Number(order?.getOrder.transports[0].receiverCompany_id),
          },
        });
      }
    },
    fetchPolicy: "no-cache",
    skip: !code,
  });

  // Handlers
  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const transport = {
      shipperCompany_id: loadingState.shipper.id,
      receiverCompany_id: loadingState.receiver.id,
      loadingLocation_id: loadingState.loadingLocation.id,
      unloadingLocation_id: loadingState.unloadingLocation.id,
      requestedLoadingTime: tz(
        `${loadingState.loading_date} ${loadingState.loading_time}`,
        loadingState.loading_timezone
      ).format(),
      requestedUnloadingTime: tz(
        `${loadingState.unloading_date} ${loadingState.unloading_time}`,
        loadingState.unloading_timezone
      ).format(),
      cargoType: loadingState.cargoType,
      parcels: loadingState.parcels,
      dangerous: loadingState.dangerous_goods,
      stackable: loadingState.stackable_goods,
    };

    const order = loadingState.onMarket
      ? {
          description: loadingState.description,
          company_id: Number(meData?.getUser.company?.id),
          marketExpirationDate: tz(
            `${loadingState.expiry_date} ${loadingState.expiry_time}`,
            loadingState.expiry_timezone
          ).format(),
        }
      : {
          description: loadingState.description,
          company_id: Number(meData?.getUser.company?.id),
          transporterCompany_id: loadingState.transporter.id,
          price: Number(loadingState.price),
        };

    validator({
      schema: schema,
      data: {
        ...transport,
        ...order,
        currentDate: moment().format(),
      },
      context: {
        cargoType: loadingState.cargoType,
        onMarket: loadingState.onMarket,
      },
      success: async () => {
        await updateTransport({
          variables: {
            transport: {
              ...transport,
              order_id: Number(loadingState.id),
              id: Number(loadingState.transport_id),
            },
          },
          refetchQueries: [GetOrdersDocument],
        });
      },
    });
  };

  // Loading variables state
  const isLoading =
    meLoading ||
    ((companyReceiverLoading ||
      companyShipperLoading ||
      locationLoadingLoading ||
      locationUnloadingLoading ||
      orderLoading ||
      updateTransportLoading) &&
      !order);

  // BE handlers
  ErrorsBeHandler({
    error: updateTransportError,
  });

  ErrorsBeHandler({
    error: orderError,
  });

  SuccessBeHandler({
    data: updateTransportData,
    code: "TRANSPORT_UPDATED",
    fn: () => {
      dispatch({
        type: "HANDLE_CHECK_ORDER",
        field: "",
        payload: {
          id: order?.getOrder.id,
          code: order?.getOrder.code,
          transport_id: updateTransportData?.updateTransport.id,
        },
      });
      history.push(`/myorder/${order?.getOrder.code}`);
    },
  });

  // Effects
  useEffect(() => {
    if (returned_value) {
      if (
        returned_value.action === "HANDLE_SHIPPER_PLACEHOLDER" ||
        returned_value.action === "HANDLE_RECEIVER_PLACEHOLDER" ||
        returned_value.action === "HANDLE_TRANSPORTER_PLACEHOLDER"
      ) {
        dispatch({
          type: returned_value.action,
          field: "",
          payload: {
            company: returned_value.company,
            location: returned_value.location,
          },
        });
      }
      if (
        returned_value.action === "HANDLE_SHIPPER_PLACEHOLDER_LOCATION" ||
        returned_value.action === "HANDLE_RECEIVER_PLACEHOLDER_LOCATION"
      ) {
        dispatch({
          type: returned_value.action,
          field: "",
          payload: {
            location: returned_value.location,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returned_value]);

  useEffect(() => {
    dispatch({
      type: "HANDLE_RESET",
      field: "",
      payload: {
        ...loadingInitialState,
      },
    });

    if (meData) {
      dispatch({
        type: "USER_TIMEZONE",
        field: "timezone",
        payload: meData.getUser.company?.location?.timezone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      companyShipper &&
      companyReceiver &&
      locationLoading &&
      locationUnloading &&
      code &&
      meData &&
      order
    ) {
      dispatch({
        type: "HANDLE_INITIAL",
        field: "",
        payload: {
          order: order,
          update: codes ? true : false,
          companyReceiver: companyReceiver,
          companyShipper: companyShipper,
          companyTransporter:
            location?.state?.type! === "EXISTING" || codes
              ? order.getOrder?.transporterCompany
              : null,
          locationLoading: locationLoading,
          locationUnloading: locationUnloading,
          user_timezone: meData?.getUser.company?.location?.timezone,
        },
      });

      setCollapse({
        transport: true,
        pricing: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    meData,
    companyShipper,
    companyReceiver,
    companyShipper,
    locationLoading,
    companyShipper,
    locationUnloading,
  ]);

  return (
    <>
      <Row>
        <Col>
          <Text
            m={{ t: "2rem" }}
            p={{ x: "1rem" }}
            textColor="dark"
            textAlign="center"
            textSize={20}
            textWeight="500"
          >
            Update transport details
          </Text>
        </Col>
      </Row>
      <Row m={{ x: "0", y: { xs: "0rem" } }} w="100%" d="flex" flexDir="column">
        {isLoading ? (
          <Col>
            <Skeleton
              height="2.5rem"
              borderRadius="0.25rem"
              width="calc(100% - 0rem)"
              margin="0 0 2.5rem 0"
            />
          </Col>
        ) : (
          <Col>
            <Div
              d="flex"
              justify="space-between"
              pos="relative"
              w={"calc(100% + 1rem)"}
              left="-0.5rem"
              right="-0.5rem"
              bottom="-1px"
              align="center"
              h="2.5rem"
              rounded="4"
              hoverBg={"grey"}
              transition
              m={{ t: "2.5rem" }}
              cursor="pointer"
              onClick={() => {
                setCollapse({ ...collapse, transport: !collapse.transport });
              }}
            >
              <Text textColor="dark" m={{ l: "0.5rem" }} textWeight={500}>
                Transport details
              </Text>
              {!collapse.transport ? (
                <Icon
                  name="Plus"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              ) : (
                <Icon
                  name="DownArrow"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="semiDark"
                />
              )}
            </Div>
            <Border></Border>
            <Collapse
              isOpen={collapse.transport}
              m={{ t: "1rem" }}
              h={{
                xs:
                  detailsChanged && collapse.transport
                    ? "auto !important"
                    : "0",
              }}
            >
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Space requirements
              </Text>
              <Div position="relative" bg="grey" h="1px" w="100%">
                <Div
                  position="absolute"
                  bg="brand600"
                  h="2px"
                  w="50%"
                  transform={`translate(${
                    loadingState.cargoType === TransportCargoType.Ftl
                      ? "0%"
                      : "100%"
                  })`}
                  transition
                ></Div>
              </Div>
              <Div
                d="flex"
                justify="space-around"
                textWeight={600}
                m={{ t: "0.25rem", b: "1.5rem" }}
              >
                <Text
                  textSize={width > 420 ? 14 : 12}
                  cursor="pointer"
                  textColor={
                    loadingState.cargoType === TransportCargoType.Ftl
                      ? "dark"
                      : "light"
                  }
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE_CARGO",
                      payload: TransportCargoType.Ftl,
                    });
                    setDetailsChanged(true);
                  }}
                >
                  Full truck load (FTL)
                </Text>
                <Text
                  textSize={width > 420 ? 14 : 12}
                  cursor="pointer"
                  textColor={
                    !(loadingState.cargoType === TransportCargoType.Ftl)
                      ? "dark"
                      : "light"
                  }
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE_CARGO",
                      payload: TransportCargoType.VolumeWeight,
                    });
                    setDetailsChanged(true);
                  }}
                >
                  Less than truck load (LTL)
                </Text>
              </Div>
              {loadingState.cargoType === TransportCargoType.Ftl ? (
                <Div
                  bg={"gray100"}
                  style={{ borderRadius: "0.5rem" }}
                  p={{ x: "1rem" }}
                  h="100px"
                  d="flex"
                  align="center"
                  justify="center"
                >
                  <Text textColor="semiDark">
                    Your transport can have up to 25,000 kg and 13.6 LTD
                  </Text>
                </Div>
              ) : (
                <>
                  <Div
                    bg={"gray100"}
                    style={{ borderRadius: "0.5rem" }}
                    p={{ xs: "1rem" }}
                  >
                    <Label
                      align="flex-start"
                      textWeight="600"
                      m={{ b: "0.5rem", r: "2rem" }}
                      d="flex"
                      flexWrap="nowrap"
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.VolumeWeight,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.VolumeWeight
                        }
                        name="count"
                      />
                      <Text>
                        Define space requirements by volume and weight
                      </Text>
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.VolumeWeight && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Volume
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={"LDM"}
                            placeholder={"LDM"}
                            value={loadingState.parcels[0].volume}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "volume",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                            tabIndex={-1}
                          >
                            Weight
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            suffix={" kg"}
                            placeholder={"kg"}
                            value={loadingState.parcels[0].weight}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "weight",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      align="center"
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.IndustryPallet,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.IndustryPallet
                        }
                        name="count"
                      />
                      I have industry pallets
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.IndustryPallet && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                          >
                            Amount of industry pallets
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={loadingState.parcels[0].quantity}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "quantity",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      align="center"
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.EurPallet,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.EurPallet
                        }
                        name="count"
                      />
                      I have Euro pallets (EPAL)
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.EurPallet && (
                      <Row>
                        <Col>
                          <Label
                            m={{ b: "0.25rem", t: "0.5rem" }}
                            textColor="semiDark"
                            textSize={12}
                            textWeight="500"
                          >
                            Amount of EPAL
                          </Label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={loadingState.parcels[0].quantity}
                            onValueChange={(values) => {
                              dispatch({
                                type: "HANDLE_CHANGE_PARCEL",
                                field: "quantity",
                                payload: values.value,
                              });
                            }}
                            customInput={Input}
                          />
                        </Col>
                      </Row>
                    )}
                    <Label
                      textWeight="600"
                      m={{ y: "0.75rem", r: "2rem" }}
                      align="flex-start"
                      d="flex"
                      flexWrap="nowrap"
                    >
                      <Radiobox
                        onChange={() => {
                          dispatch({
                            type: "HANDLE_CHANGE_CARGO",
                            payload: TransportCargoType.SizeWeight,
                          });
                          setDetailsChanged(true);
                        }}
                        checked={
                          loadingState.cargoType ===
                          TransportCargoType.SizeWeight
                        }
                        name="count"
                      />
                      <Text>Define space requirements by sizes and weight</Text>
                    </Label>
                    {loadingState.cargoType ===
                      TransportCargoType.SizeWeight && (
                      <Div>
                        {loadingState.parcels.map(
                          (parcel: any, index: number) => (
                            <Row
                              key={index}
                              size="15"
                              flexWrap="wrap"
                              pos="relative"
                            >
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Quantity
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  value={parcel.quantity || 1}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "quantity"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "3"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Length
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.length || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "length"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "4"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Width
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.width || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "width"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "2"
                                    : "6"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Height
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" cm"}
                                  placeholder={"cm"}
                                  value={parcel.height || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "height"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Col
                                size={
                                  (width > 830 || width <= 700) && width > 500
                                    ? "3"
                                    : "6"
                                }
                              >
                                <Label
                                  m={{ b: "0.25rem", t: "0.5rem" }}
                                  textColor="semiDark"
                                  textSize={12}
                                  textWeight="500"
                                  tabIndex={-1}
                                >
                                  Weight
                                </Label>
                                <NumberFormat
                                  thousandSeparator={true}
                                  suffix={" kg"}
                                  placeholder={"kg"}
                                  value={parcel.weight || ""}
                                  onValueChange={(values) => {
                                    dispatch({
                                      type: "HANDLE_CHANGE_PARCEL",
                                      field: [index, "weight"],
                                      payload: values.value,
                                    });
                                  }}
                                  customInput={Input}
                                />
                              </Col>
                              <Div pos="absolute" bottom="8px" right="-6px">
                                {loadingState.parcels &&
                                loadingState.parcels.length &&
                                loadingState.parcels.length > 1 ? (
                                  <Icon
                                    name="Cross"
                                    m={{ t: "0.8rem" }}
                                    size="14px"
                                    color="semiDark"
                                    cursor="pointer"
                                    onClick={(e: any) => {
                                      dispatch({
                                        type: "HANDLE_CHANGE",
                                        field: "parcels",
                                        payload:
                                          loadingState.parcels.splice(
                                            index,
                                            1
                                          ) && loadingState.parcels,
                                      });
                                    }}
                                  />
                                ) : (
                                  <Div w="14px" />
                                )}
                              </Div>
                            </Row>
                          )
                        )}
                        <Text
                          textColor="primary"
                          textSize={10}
                          textWeight={600}
                          m={{ t: "1rem" }}
                          cursor="pointer"
                          w="fit-content"
                          onClick={(e: any) => {
                            dispatch({
                              type: "HANDLE_CHANGE",
                              field: "parcels",
                              payload: loadingState.parcels.concat([
                                {
                                  weight: null,
                                  volume: null,
                                  quantity: 1,
                                  length: null,
                                  width: null,
                                  height: null,
                                },
                              ]),
                            });
                            setDetailsChanged(true);
                          }}
                        >
                          + ADD PARCEL
                        </Text>
                      </Div>
                    )}
                  </Div>
                </>
              )}
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ y: "1rem" }}
              >
                Other transport requirements
              </Text>
              <Div d="flex">
                <Label
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "dangerous_goods",
                      payload: !loadingState.dangerous_goods,
                    });
                    setDetailsChanged(true);
                  }}
                  align="center"
                  textWeight="600"
                  m={{ b: "1rem" }}
                >
                  <Switch
                    checked={loadingState.dangerous_goods}
                    style={{ boxShadow: "none", overflow: "hidden" }}
                  />
                  <Text textSize={14} textColor="dark" textWeight={500}>
                    Dangerous goods
                  </Text>
                </Label>
              </Div>
              <Div d="flex">
                <Label
                  onClick={() => {
                    dispatch({
                      type: "HANDLE_CHANGE",
                      field: "stackable_goods",
                      payload: !loadingState.stackable_goods,
                    });
                    setDetailsChanged(true);
                  }}
                  align="center"
                  textWeight="600"
                  m={{ b: "1rem" }}
                >
                  <Switch
                    checked={loadingState.stackable_goods}
                    style={{ boxShadow: "none", overflow: "hidden" }}
                  />
                  <Text textSize={14} textWeight={500} textColor="dark">
                    Stackable goods
                  </Text>
                </Label>
              </Div>
            </Collapse>
          </Col>
        )}
        <Col>
          <PrimaryBtn
            w="100%"
            handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
              handleSubmit(e)
            }
            styleBtn={{
              marginTop: "2rem",
              marginBottom: height > 1290 ? "2rem" : "10rem",
            }}
            isLoading={updateTransportLoading}
            disabled={
              !loadingState.shipper.id ||
              !loadingState.loadingLocation.id ||
              !loadingState.receiver.id ||
              !loadingState.unloadingLocation.id
            }
            text={"Submit"}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateOrder;
