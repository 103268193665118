/* eslint-disable array-callback-return */
import moment from "moment";
import { DefaultUserRole, ModuleName } from "../enums";
import {
  AssignedUserType,
  DocumentType,
  GetMeQuery,
  LocationType,
  OrderStatus,
  TransportCargoType,
  User,
} from "../generated/graphql";
import { GlobalTheme } from "../components/GlobalTheme";
import { config } from "../config";

export const getParams = (url: any, param_name: string, remove: string) => {
  let token = new URLSearchParams(url).get(param_name);
  token?.replace(remove, "");
  return token;
};

export const toReadValue = (name: string) => {
  let new_name = name.toLowerCase();

  new_name.replace("_", " ");

  return new_name.replace("_", " ");
};

export const toReadValueReturnColor = (name?: string) => {
  if (name === OrderStatus.OnMarket) {
    return "aqua";
  } else if (name === OrderStatus.Ready) {
    return "brand600";
  } else if (
    name === OrderStatus.Draft ||
    name === OrderStatus.Pending ||
    name === OrderStatus.Cancelled
  ) {
    return "grey";
  } else if (name === OrderStatus.InTransit) {
    return "warning";
  } else if (name === OrderStatus.Completed || name === OrderStatus.Stored) {
    return "success";
  } else if (
    name === OrderStatus.Deleted ||
    name === OrderStatus.Damaged ||
    name === OrderStatus.Flagged
  ) {
    return "danger";
  } else if (name === OrderStatus.OnHold) {
    return "dark";
  }
  return "grey";
};

export const toReadValueReturnBg = (name?: string) => {
  if (
    name === OrderStatus.Draft ||
    name === OrderStatus.Ready ||
    name === OrderStatus.Stored ||
    name === OrderStatus.OnHold ||
    name === OrderStatus.Flagged
  ) {
    return "transparent";
  }
  if (name === OrderStatus.OnMarket) {
    return "aqua";
  } else if (name === OrderStatus.Pending || name === OrderStatus.Cancelled) {
    return "grey";
  } else if (name === OrderStatus.InTransit) {
    return "warning";
  } else if (name === OrderStatus.Completed) {
    return "success";
  } else if (name === OrderStatus.Deleted || name === OrderStatus.Damaged) {
    return "danger";
  }
  return "grey";
};

export type StatusOption =
  | {
      value: OrderStatus;
      name: string;
      color: keyof typeof GlobalTheme.colors;
      bgColor: keyof typeof GlobalTheme.colors;
    }
  | undefined;

export const StatusOptions = (): StatusOption[] => {
  // eslint-disable-next-line array-callback-return
  const array = Object.values(OrderStatus).map((name: string) => {
    return {
      value: name,
      name: toReadValue(name),
      color: toReadValueReturnColor(name),
      bgColor: toReadValueReturnBg(name),
    };
  });

  return array.filter((item) => item) as StatusOption[];
};

export function OrderStatusOptions(
  currentStatus: string,
  isOrderOwner?: boolean,
  isShipper?: boolean,
  isTransporter?: boolean,
  IsReceiver?: boolean,
  isExisting?: boolean
) {
  const array = StatusOptions();

  const filtered_array = array.map((status: any) => {
    if (status) {
      if (currentStatus === OrderStatus.Draft && isOrderOwner) {
        if (
          (status.value === OrderStatus.OnMarket && !isExisting) ||
          (status.value === OrderStatus.Pending && isExisting)
        ) {
          return status;
        }
      }

      if (currentStatus === OrderStatus.Pending && isTransporter) {
        if (status.value === OrderStatus.Ready) {
          return status;
        }
      }

      if (currentStatus === OrderStatus.Ready && isTransporter) {
        if (
          status.value === OrderStatus.InTransit ||
          status.value === OrderStatus.Damaged ||
          status.value === OrderStatus.OnHold
        ) {
          return status;
        }
      }

      if (
        currentStatus === OrderStatus.InTransit &&
        (isTransporter || IsReceiver)
      ) {
        if (
          status.value === OrderStatus.Damaged ||
          status.value === OrderStatus.Completed
        ) {
          return status;
        }
      }

      if (currentStatus === OrderStatus.Completed && IsReceiver) {
        if (
          status.value === OrderStatus.Damaged ||
          status.value === OrderStatus.Stored
        ) {
          return status;
        }
      }

      if (
        currentStatus === OrderStatus.OnHold &&
        (isTransporter || isShipper)
      ) {
        if (
          status.value === OrderStatus.Damaged ||
          status.value === OrderStatus.Completed ||
          status.value === OrderStatus.InTransit
        ) {
          return status;
        }
      }
      if (currentStatus === OrderStatus.Damaged && IsReceiver) {
        if (
          status.value === OrderStatus.Completed ||
          status.value === OrderStatus.Stored
        ) {
          return status;
        }
      }
    }
  });

  return filtered_array.filter((item) => item);
}

export const disassemblePhoneNumber = (phone: string) => {
  if (phone) {
    if (phone.includes(")") || phone.includes("(")) {
      const indexFirst = phone.indexOf("(");
      const indexLast = phone.indexOf(")");
      let preNumber = phone.slice(indexFirst, indexLast + 1);
      let phoneNumber = phone.replace(preNumber, "");
      preNumber = preNumber.replace("(", "");
      preNumber = preNumber.replace(")", "");
      return { preNumber, phoneNumber };
    } else {
      return { preNumber: "", phoneNumber: phone };
    }
  } else {
    return { preNumber: "", phoneNumber: "" };
  }
};

export const registrationPlateNumberFormat = (number: string) => {
  let array = number.replaceAll(" ", "").split("");
  return `${array.length >= 1 ? array[0] : ""}${
    array.length >= 2 ? array[1] : ""
  } ${array.length >= 3 ? array[2] : ""}${array.length >= 4 ? array[3] : ""} ${
    array.length >= 5 ? array[4] : ""
  }${array.length >= 6 ? array[5] : ""}${
    array.length >= 7 ? array[6] : ""
  }`.trimRight();
};

export const LocationTypeReadFormat: (type: string | null) => {
  name: String;
  type: LocationType;
} = (type) => {
  if (type === LocationType.CompanyBillingAddress) {
    return { name: "Headquarter", type: LocationType.CompanyBillingAddress };
  }
  if (type === LocationType.DefaultLodaing) {
    return { name: "Loading", type: LocationType.DefaultLodaing };
  }
  if (type === LocationType.DefaultUnlodaing) {
    return { name: "Unloading", type: LocationType.DefaultUnlodaing };
  }
  return { name: "Other", type: LocationType.Other };
};

export function getRoleName(role: DefaultUserRole) {
  switch (role) {
    case DefaultUserRole.ADMIN:
      return "Admin";

    case DefaultUserRole.COMPANY_OWNER:
      return "Company owner";

    case DefaultUserRole.SUPER_DISPATCHER:
      return "Super dispatcher";

    case DefaultUserRole.DISPATCHER:
      return "Dispatcher";

    case DefaultUserRole.DRIVER:
      return "Driver";

    case DefaultUserRole.ACCOUNTANT:
      return "Accountant";

    case DefaultUserRole.USER:
      return "User";

    case DefaultUserRole.MODULE_PERMISSIONS_MANAGER:
      return "Module Permissions Manager";
    default:
      return "";
  }
}

export function getRoleValue(role: string): number {
  switch (role) {
    case "Admin":
      return DefaultUserRole.ADMIN;

    case "Company owner":
      return DefaultUserRole.COMPANY_OWNER;

    case "Super dispatcher":
      return DefaultUserRole.SUPER_DISPATCHER;

    case "Dispatcher":
      return DefaultUserRole.DISPATCHER;

    case "Driver":
      return DefaultUserRole.DRIVER;

    case "Accountant":
      return DefaultUserRole.ACCOUNTANT;

    case "User":
      return DefaultUserRole.USER;

    case "Module Permissions Manager":
      return DefaultUserRole.MODULE_PERMISSIONS_MANAGER;

    default:
      return 0;
  }
}

export function getAssignedTypeName(type: AssignedUserType) {
  switch (type) {
    case AssignedUserType.Owner:
      return "Owner";

    case AssignedUserType.Receiver:
      return "Receiver";

    case AssignedUserType.Shipper:
      return "Shipper";

    case AssignedUserType.Transporter:
      return "Transporter";

    case AssignedUserType.Driver:
      return "Driver";

    default:
      return "";
  }
}

export function getDocumentType(type: DocumentType) {
  switch (type) {
    case DocumentType.Invoice:
      return "Invoice";

    case DocumentType.Pod:
      return "Proof of delivery";

    case DocumentType.Image:
      return "Image";

    default:
      return "Document";
  }
}

export function hasRole(user: User, role: DefaultUserRole) {
  return user?.roles?.some((x) => x.id === role);
}

export function getTransportCargoType(type: TransportCargoType) {
  switch (type) {
    case TransportCargoType.Ftl:
      return "FTL";

    case TransportCargoType.EurPallet:
      return "EPAL";

    case TransportCargoType.IndustryPallet:
      return "Industry";

    case TransportCargoType.VolumeWeight:
    case TransportCargoType.SizeWeight:
      return "Custom";
    default:
      return "";
  }
}

export function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  var fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
}

export const uploadFileToAWS = async (url: string, file: File) => {
  if (!url || !file) {
    return;
  }

  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      Host: "http://localhost:3000/",
    },
    body: file,
  });
};

export const downloadFromUrl = (url: string, name: string) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", name);
      a.click();
    });
  });
};

export const downloadInvoicePdf = async (
  token: string,
  payload: any,
  downloadPdf?: boolean,
  successCallback?: any
) => {
  fetch(`${config.api?.split("gql")?.[0]}invoice/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(payload),
  }).then((response) => {
    if (response?.ok) {
      if (downloadPdf) {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "invoice.pdf";
          alink.click();
        });
      }
      successCallback?.();
    }
    return response;
  });
};

export const DateWithGtm = (date: string, timezone: string) => {
  let gtm = moment().tz(timezone).format("Z");
  const gtmSpit = gtm.split(":");
  return `${moment(date).format("DD/MM/YYYY")} at ${moment(date).format(
    "HH:mm"
  )} GTM${gtmSpit[0]}`;
};

export const GetGtm = (timezone: string) => {
  let gtm = moment().tz(timezone).format("Z");
  const gtmSpit = gtm.split(":");
  return `GTM${gtmSpit[0]}`;
};

export const parseJSON = (data: any, fallback = {}) => {
  try {
    return JSON.parse(data) ?? fallback;
  } catch {
    return fallback;
  }
};

export const showTimeDifference = (
  targetDate: Date | string,
  timezone: string
) => {
  const now = moment().tz(timezone);
  const target = moment(targetDate).tz(timezone);
  let diff = target.diff(now);

  diff = Math.abs(diff);
  const duration = moment.duration(diff);

  let result = "";

  if (duration.years() < 1) {
    const durationInMonths = duration.months();
    const durationInDays = duration.days();
    const durationInHours = duration.hours();

    const parts = [];

    if (durationInMonths > 0) {
      parts.push(`${durationInMonths} months`);
    }
    if (durationInDays > 0) {
      parts.push(`${durationInDays} days`);
    }
    if (durationInHours > 0) {
      parts.push(`${durationInHours} hours`);
    }

    result = parts.join(", ");
  }

  return result;
};

export const userHasModule = (
  user: GetMeQuery | undefined,
  moduleName: ModuleName
) => {
  return !!user?.getUser?.modules?.find((item) => item?.name === moduleName);
};
