import { useCallback, useEffect, useState } from "react";

export const useSearch = (
  useLazy: any,
  query: any,
  delay = 300,
  con: any,
  specialArg: any = null
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState(1);

  const [getLazy, { data: lazyData, loading, error: lazyError }] = useLazy;

  const callback = useCallback(async () => {
    con &&
      (specialArg
        ? getLazy({
            variables: {
              query: { ...query, search: search, page: page },
              ...specialArg,
            },
          })
        : getLazy({
            variables: { query: { ...query, search: search, page: page } },
          }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, con]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    const handler = setTimeout(async () => {
      await callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyData]);

  useEffect(() => {
    updateError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyError]);

  const updateData = useCallback(() => {
    if (lazyData) {
      setData(lazyData);
    }
  }, [lazyData]);

  const updateError = useCallback(() => {
    if (lazyError) setError(lazyError);
  }, [lazyError]);

  return { data, search, loading, error, setSearch, page, setPage };
};
