import styled from "styled-components";

export const ProgressLine = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.colors.grey};
`;

interface PropsLine {
  width: string;
}

export const Line = styled.div<PropsLine>`
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  display: block;
  background: ${(props) => props.theme.colors.brand600};
  width: ${(props) => props.width};
`;
