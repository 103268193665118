import React, { useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Div, Anchor } from "atomize";
import renderIcon from "../../assets/Icons";
import { GlobalTheme } from "../GlobalTheme";
import { disassemblePhoneNumber } from "../../helpers/functions";
import DropDown from "../DropdownClassic/DropdownClassic";
import {
  GetUsersDocument,
  useDeactivateUserMutation,
  User,
} from "../../generated/graphql";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import { modalData } from "../../App";

const UsersRowActions: React.FC<{
  user?: User;
  me?: User | any;
  userPermissions?: {
    canUserEdit?: boolean;
    canUserDelete?: boolean;
  };
}> = ({ user, me, userPermissions }) => {
  const isAbleToEditOrDelete =
    userPermissions?.canUserEdit || userPermissions?.canUserDelete;

  const [actionDropdown, setActionDropdown] = useState<boolean>(false);

  const [deleteUser, { data, loading, error }] = useDeactivateUserMutation({
    errorPolicy: "all",
  });

  ErrorsBeHandler({
    error: error,
  });

  SuccessBeHandler({
    data: data,
    code: "DELETED_USER",
    fn: () => {
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  if (!isAbleToEditOrDelete) {
    return null;
  }

  return (
    <Tr>
      <Td
        className="no_p"
        styleTd={{
          borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
          borderRight: `1px solid ${GlobalTheme.colors.grey}`,
          padding: 0,
        }}
        isActionTd={true}
      >
        {user?.roles && (
          <DropDown
            h="auto"
            showDropIcon={false}
            border={false}
            direction="lefttop"
            style={{ width: "auto!important" }}
            isOpen={actionDropdown}
            menuList={
              <Div
                shadow={GlobalTheme.shadows[4]}
                style={{
                  background: GlobalTheme.colors.background,
                  padding: "0 0.75rem",
                  borderRadius: GlobalTheme.rounded[4],
                }}
                tabIndex={0}
              >
                <Div d="flex" align="center">
                  <Anchor
                    textSize={142}
                    textColor="semiDark"
                    hoverTextColor="dark"
                    d="block"
                    p={{ y: "0.25rem" }}
                    onClick={() => {
                      if (user.phone) {
                        modalData({
                          msg: "Update User",
                          name: "userEditModal",
                          returned_value: null,
                          props: {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: disassemblePhoneNumber(user.phone)
                              .phoneNumber,
                            phoneCountryNumbers: disassemblePhoneNumber(
                              user.phone
                            ).preNumber,
                            company_id: user.company_id,
                            roles: user.roles,
                            isExternalDriver: user.isExternalDriver,
                          },
                        });
                        setActionDropdown(false);
                      }
                    }}
                  >
                    Edit
                  </Anchor>
                </Div>

                <Div d="flex" align="center">
                  <Anchor
                    textSize={142}
                    textColor="semiDark"
                    hoverTextColor="dark"
                    d="block"
                    p={{ y: "0.25rem" }}
                    onClick={() => {
                      modalData({
                        msg: "",
                        name: "deleteModal",
                        isLoading: loading,
                        returned_value: null,
                        props: {
                          subtext: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
                          handleSubmit: async (
                            e: React.ChangeEvent<HTMLButtonElement>
                          ) => {
                            e.preventDefault();
                            await deleteUser({
                              variables: { id: user.id },
                              refetchQueries: [GetUsersDocument],
                              awaitRefetchQueries: true,
                            });
                            modalData({
                              name: "",
                              msg: "",
                              returned_value: null,
                              props: null,
                            });
                          },
                        },
                      });

                      setActionDropdown(false);
                    }}
                  >
                    Delete
                  </Anchor>
                </Div>
              </Div>
            }
            setIsOpen={setActionDropdown}
          >
            <Text cursor="pointer" hoverTextColor="info700">
              {renderIcon("ActionDots")}
            </Text>
          </DropDown>
        )}
      </Td>
    </Tr>
  );
};

export default UsersRowActions;
