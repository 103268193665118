import React, { CSSProperties, useEffect, useRef, useState } from "react";
import * as Styled from "./TableElements.styles";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { modalData } from "../../App";

interface TdProps {
  width?: string;
  styleTd?: CSSProperties;
  handleClick?: () => void;
  className?: string;
  isActionTd?: boolean;
  children?: React.ReactNode;
  disableReadMore?: boolean;
}

const Td: React.FC<TdProps> = ({
  children,
  width: elWidth,
  styleTd,
  handleClick,
  className,
  isActionTd = false,
  disableReadMore = true,
}) => {
  const el = useRef<any>(null);
  const [readMore, setReadMore] = useState(false);

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (disableReadMore) {
      return;
    }

    if (
      el.current &&
      !(className === "divider" || className === "divider_right")
    ) {
      const children = el.current.children;
      let text_width = 0;
      for (let i = 0; i < children.length; i++) {
        const element = children[i];
        text_width += element.scrollWidth;
      }
      if (text_width > el.current.clientWidth) {
        setReadMore(true);
      } else {
        setReadMore(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, disableReadMore]);

  const handleClickReadMore = (
    e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) => {
    if (!disableReadMore) {
      e.preventDefault();
      modalData({
        msg: "Read More",
        name: "readMoreTd",
        returned_value: null,
        props: { children },
      });
    }
  };

  return (
    <Styled.Td
      className={className}
      isActionTd={isActionTd}
      width={elWidth ? elWidth : "10%"}
      style={{
        ...styleTd,
        cursor: readMore ? "pointer" : "auto",
      }}
      onClick={
        !readMore
          ? handleClick
          : (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) =>
              handleClickReadMore(e)
      }
      tabIndex={0}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: isActionTd ? "space-between" : "flex-start",
        }}
        ref={el}
        className="td_wrapper"
      >
        {children}
      </div>
    </Styled.Td>
  );
};

export default Td;
