import { Col, Row } from "atomize";
import Border from "../../../components/Border/Border";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";

const LineSeparator = () => {
  const { width } = useWindowDimensions();

  return (
    <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      <Col>
        <Border></Border>
      </Col>
    </Row>
  );
};

export default LineSeparator;
