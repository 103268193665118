import styled from "styled-components";

interface SectionProps {
  margin?: string;
  innerWidth?: string;
  overflowDir?: string;
}

export const Section = styled.div<SectionProps>`
  margin: ${(props) => props.margin};

  overflow-x: auto;
  scrollbar-width: thin;
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralGrey};
    height: 5px;
    border-radius: ${(props) => props.theme.rounded[2]};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }

  ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 100%;
    height: 10px;
    background: ${(props) => props.theme.colors.grey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.semiLight};
    border-left: 3px solid ${(props) => props.theme.colors.grey};
    border-right: 3px solid ${(props) => props.theme.colors.grey};
    border-top: 3px solid ${(props) => props.theme.colors.grey};
    border-bottom: 3px solid ${(props) => props.theme.colors.grey};
  }
`;
