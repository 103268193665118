import { Col, Div, Icon, Row, Text } from "atomize";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { modalData } from "../../../App";
import renderIcon from "../../../assets/Icons";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../../components/SecondaryBtn/SecondaryBtn";
import { ModuleName } from "../../../enums";
import {
  GetLocationDocument,
  GetMeQuery,
  GetNotificationsDocument,
  GetNotificationsQuery,
  GetOrderDocument,
  GetOrderQuery,
  NotificationListingItem,
  NotificationType,
  OrderStatus,
  useGetInvoiceDataQuery,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import { parseJSON, userHasModule } from "../../../helpers/functions";
import InvoiceDataNotification from "./InvoiceDataNotification";

interface Props {
  me: GetMeQuery | undefined;
  notificationData: GetNotificationsQuery | undefined;
  loadingStates: {
    acceptTransportRequestLoading: boolean;
  };
  callbackFunctions: {
    acceptTransportRequest: (item: any) => void;
  };
  order: GetOrderQuery | undefined;
}

const Notifications: FC<Props> = ({
  me,
  loadingStates,
  notificationData,
  callbackFunctions,
  order,
}) => {
  const { width } = useWindowDimensions();

  const {
    data: invoiceData,
    error: invoiceDataError,
    loading: invoiceDataLoading,
  } = useGetInvoiceDataQuery({
    variables: {
      orderId: order?.getOrder?.id ?? 0,
    },
  });

  const [hiddenNotifications, setHiddenNotifications] = useState<number[]>([]);

  const handleHideNotification = (notificationId: number) => {
    setHiddenNotifications((prevState) => [...prevState, notificationId]);
  };

  const userHasDocumentInvoicingModule = userHasModule(
    me,
    ModuleName.DOCUMENTS_AND_INVOICING
  );

  const renderNotificationByType = (notification: NotificationListingItem) => {
    if (hiddenNotifications?.includes(notification?.id ?? 0)) {
      return null;
    }

    switch (notification?.type) {
      case NotificationType.DeliveryChangeRequest: {
        const acceptTransportRequest =
          callbackFunctions?.acceptTransportRequest;
        const acceptTransportRequestLoading =
          loadingStates?.acceptTransportRequestLoading;
        const changeData = parseJSON(notification?.data ?? "", {})?.changeData;

        const renderDateString = (date: any) => {
          const timezone = me?.getUser.company?.location?.timezone;

          return `${moment(date).tz(timezone!).format("D MMM YYYY")}(${moment(
            date
          )
            .tz(timezone!)
            .format("HH:mm")} ${timezone})`;
        };

        return (
          <Div
            p="10px 16px"
            bg="washedOrange"
            shadow="3"
            d="flex"
            m={{ b: "1rem" }}
            align="center"
            justify="flex-start"
            flexDir={width > 980 ? "row" : "column"}
            key={notification?.id}
          >
            <Div d="flex" justify="center" align="center">
              <Div
                d="flex"
                w="30px"
                h="30px"
                justify="center"
                align="center"
                m={{ r: "14px" }}
              >
                {renderIcon("AlertSchedule")}
              </Div>
              <Div>
                <Div d="flex" flexWrap="wrap">
                  <Text m={{ r: "4px" }}>
                    {`New ${
                      changeData?.hasOwnProperty("unloadingTime")
                        ? "delivery date"
                        : "pickup date"
                    } requested by `}{" "}
                  </Text>
                  <Text textWeight="800">
                    {notification.actionCompany_name}
                  </Text>
                </Div>
                <Div d="flex" flexWrap="wrap">
                  <Div d="flex" flexWrap="wrap" m={{ r: "4px" }}>
                    <Text m={{ r: "4px" }}>New date:</Text>
                    <Text textWeight="800">
                      {changeData?.hasOwnProperty("unloadingTime")
                        ? renderDateString(changeData?.unloadingTime)
                        : renderDateString(changeData?.loadingTime)}
                    </Text>
                  </Div>
                  <Div d="flex" flexWrap="wrap">
                    <Text m={{ r: "4px" }}>Previous date:</Text>
                    <Text textWeight="800">
                      {changeData?.hasOwnProperty("unloadingTime")
                        ? renderDateString(
                            changeData?.currentData?.unloadingTime
                          )
                        : renderDateString(
                            changeData?.currentData?.loadingTime
                          )}
                    </Text>
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div
              d="flex"
              m={{
                l: width > 980 ? "auto" : "44px",
                r: width > 980 ? "0" : "auto",
                t: "1rem",
              }}
            >
              <PrimaryBtn
                handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  acceptTransportRequest({
                    variables: {
                      response: {
                        notification_id: notification?.id,
                        accept: true,
                      },
                    },
                    refetchQueries: [
                      GetNotificationsDocument,
                      GetOrderDocument,
                      GetLocationDocument,
                      GetLocationDocument,
                    ],
                    awaitRefetchQueries: true,
                  });
                }}
                prefixIcon={
                  <Icon
                    name="Checked"
                    m={{ l: "0px", r: "5px" }}
                    size="20px"
                    color="background"
                  />
                }
                isLoading={acceptTransportRequestLoading}
                h="2rem"
                styleBtn={{
                  display: "flex",
                  justifyContent: "left",
                  margin: "0 8px 0 0",
                }}
                text={"Accept"}
                textSize={12}
              />
              <SecondaryBtn
                w="100%"
                styleBtn={{ maxWidth: "144px" }}
                h="2rem"
                handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  acceptTransportRequest({
                    variables: {
                      response: {
                        notification_id: notification?.id,
                        accept: false,
                      },
                    },
                    refetchQueries: [
                      GetNotificationsDocument,
                      GetOrderDocument,
                    ],
                    awaitRefetchQueries: true,
                  });
                }}
                prefixIcon={
                  <Icon
                    name="Cross"
                    size="20px"
                    m={{ l: "0px", r: "5px" }}
                    color="semiDark"
                  />
                }
                isLoading={acceptTransportRequestLoading}
                text={"Decline"}
              />
            </Div>
          </Div>
        );
      }

      case NotificationType.OrderStatus: {
        const changeData = JSON.parse(notification?.data ?? "");

        if (
          changeData?.status === "COMPLETED" &&
          order?.getOrder.status === OrderStatus.Completed &&
          userHasDocumentInvoicingModule &&
          !invoiceData?.getInvoiceData?.items?.length &&
          !invoiceDataLoading
        ) {
          return (
            <Div
              p="0.75rem 1rem"
              bg="info200"
              shadow="3"
              d="flex"
              m={{ b: "1rem" }}
              align="center"
              justify="flex-start"
              flexDir={width > 980 ? "row" : "column"}
              key={notification?.id}
            >
              <Div d="flex" justify="center" align="center">
                <Div
                  d="flex"
                  w="30px"
                  h="30px"
                  justify="center"
                  align="center"
                  m={{ r: "14px" }}
                >
                  {renderIcon("Report")}
                </Div>
                <Div>
                  <Div d="flex" flexWrap="wrap">
                    <Text>
                      You can now generate and submit an invoice for your
                      services.
                    </Text>
                  </Div>
                </Div>
              </Div>
              <Div
                d="flex"
                m={{
                  l: width > 980 ? "auto" : "44px",
                  r: width > 980 ? "0" : "auto",
                  t: width <= 980 && "1rem",
                }}
              >
                <PrimaryBtn
                  handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    modalData({
                      msg: "Generate an invoice",
                      name: "invoiceCreateModal",
                      returned_value: null,
                      props: {
                        modalType: "generate",
                        subtext:
                          "Select your preferences and we’ll automatically generate an invoice on your behalf.",
                        isLoading: false,
                        orderId: order?.getOrder.id,
                        orderCode: order?.getOrder?.code,
                        shipperCompanyId:
                          order?.getOrder?.transports?.[0].shipperCompany_id,
                        successCallback: () => {
                          handleHideNotification(notification?.id ?? 0);
                        },
                      },
                    });
                  }}
                  prefixIcon={
                    <Icon
                      name="Add"
                      m={{ l: "0px", r: "5px" }}
                      size="20px"
                      color="background"
                    />
                  }
                  isLoading={false}
                  h="2rem"
                  styleBtn={{
                    display: "flex",
                    justifyContent: "left",
                    margin: "0 8px 0 0",
                  }}
                  text={"Start"}
                  textSize={12}
                />
                <SecondaryBtn
                  w="100%"
                  styleBtn={{ maxWidth: "144px" }}
                  h="2rem"
                  handleSubmit={(e: ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    handleHideNotification(notification?.id ?? 0);
                  }}
                  isLoading={false}
                  text="Dismiss"
                />
              </Div>
            </Div>
          );
        }
        return null;
      }

      default:
        return null;
    }
  };

  ErrorsBeHandler({
    error: invoiceDataError,
  });

  useEffect(() => {
    let selected = false;
    notificationData?.getNotifications?.items?.forEach((notification) => {
      if (notification?.type === NotificationType.OrderStatus) {
        const changeData = JSON.parse(notification?.data ?? "");

        if (changeData?.status === "COMPLETED") {
          if (selected) {
            handleHideNotification(notification?.id ?? 0);
          }
          selected = true;
        }
      }
    });
  }, [notificationData?.getNotifications?.items]);

  return (
    <Row m={{ b: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
      <Col>
        {userHasDocumentInvoicingModule &&
          invoiceData?.getInvoiceData?.items?.filter((invoice) => 
            [invoice.issuingCompany_id, invoice.payeeCompany_id].includes(me?.getUser?.company_id)
        ).map((item) => {
            return (
              <InvoiceDataNotification
                invoiceData={item}
                order={order}
                me={me}
              />
            );
          })}
        {notificationData?.getNotifications?.items?.map(
          (notification: NotificationListingItem) =>
            renderNotificationByType(notification)
        )}
      </Col>
    </Row>
  );
};

export default Notifications;
