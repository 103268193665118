import styled from "styled-components";

export const ReceivePricingBids = styled.div`
  min-height: calc(100vh - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 616px;
  padding: 0rem;
  margin: 0 auto;
  width: 100%;
`;
