import * as Yup from "yup";

export const getExportConfigSchema = (enableCustomTimeFrame: boolean) => {
  return Yup.object().shape({
    ...(enableCustomTimeFrame
      ? {
          from: Yup.date()
            .required({ name: "Date from", code: "REQUIRED_FIELD" })
            .max(Yup.ref("to"), {
              name: "Export start date",
              code: "DATE_MAX",
              var: "export end date",
            }),
          to: Yup.date()
            .required({ name: "Date to", code: "REQUIRED_FIELD" })
            .min(Yup.ref("from"), {
              name: "Export end date",
              code: "DATE_MIN",
              var: "export start date",
            }),
        }
      : {}),
    selectedColumns: Yup.array().of(Yup.string()).required({
      name: "Order columns",
      code: "DATA_NOT_SELECTED_MULTIPLE",
    }),
  });
};
