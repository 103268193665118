import React from "react";
import { Text } from "atomize";
import {
  NotificationMessageStatus,
  useGetMeQuery,
} from "../../../generated/graphql";
import { GlobalTheme } from "../../../components/GlobalTheme";
import Td from "../../../components/TableComponents/Td";
import Tr from "../../../components/TableComponents/Tr";
import moment from "moment";

const RemindersRowActions: React.FC<{ reminder: any }> = ({ reminder }) => {
  const { data: me } = useGetMeQuery();
  return (
    <Tr
      styleTr={{
        borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
        borderRight: `1px solid ${GlobalTheme.colors.grey}`,
        background: GlobalTheme.colors.background,
      }}
    >
      <Td className="no_p" isActionTd={true}>
        <Text
          m={{ l: "10px" }}
          textSize="14"
          textColor={
            reminder.status === NotificationMessageStatus.Sent
              ? "lightGrey"
              : "brand600"
          }
          hoverTextColor={
            reminder.status === NotificationMessageStatus.Sent
              ? "lightGrey"
              : "brand700"
          }
          textWeight="600"
          cursor="pointer"
          onClick={(e: React.ChangeEvent<HTMLButtonElement>) =>
            reminder.status !== NotificationMessageStatus.Sent &&
            reminder.modalData({
              msg: "Edit reminder",
              name: "updateReminder",
              returned_value: null,
              props: {
                timezone: me?.getUser.company?.location?.timezone,
                company_id: me?.getUser.company?.id,
                text: reminder.text,
                label: reminder.label,
                date: moment(reminder.scheduleDate).format("YYYY-MM-DD"),
                time: moment(reminder.scheduleDate).format("HH:mm"),
                status: reminder.status,
                recipients: reminder.recipients,
                id: reminder.id,
              },
            })
          }
        >
          Edit
        </Text>
      </Td>
    </Tr>
  );
};

export default RemindersRowActions;
