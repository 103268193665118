import { useReactiveVar } from "@apollo/client";
import {
  Anchor,
  Col,
  Div,
  Dropdown,
  Input,
  Row,
  Text,
  Textarea,
} from "atomize";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetLocationsDocument,
  LocationType,
  useCreateLocationMutation,
  useCreatePlaceholderLocationMutation,
  useGetCountriesLazyQuery,
  useUpdateLocationMutation,
} from "../../../generated/graphql";
import { GoogleAPI } from "../../../helpers/CustomHooks/googleAPIHook";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { LocationTypeReadFormat } from "../../../helpers/functions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PrefixDropdown from "../../PrefixDropdown/PrefixDropdown";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const locationInitials = {
  company_id: null,
  title: "",
  address: "",
  postName: "",
  postCode: "",
  contactName: "",
  phoneCountryNumbers: "386",
  phone: "",
  email: "",
  geoLocation: "",
  type: "",
  description: "",
  country: {
    id: null,
    name: "",
    code: "",
  },
  timezone: "",
};

let schema = yup.object().shape({
  company_id: yup
    .number()
    .nullable()
    .required({ name: "Company", code: "DATA_NOT_SELECTED" }),
  title: yup
    .string()
    .trim()
    .required({ name: "Title", code: "REQUIRED_FIELD" }),
  address: yup
    .string()
    .trim()
    .required({ name: "Address", code: "REQUIRED_FIELD" }),
  postName: yup
    .string()
    .trim()
    .required({ name: "Post Name", code: "REQUIRED_FIELD" }),
  postCode: yup
    .string()
    .trim()
    .required({ name: "Post code", code: "REQUIRED_FIELD" }),
  contactName: yup
    .string()
    .trim()
    .required({ name: "Contact name", code: "REQUIRED_FIELD" }),
  phone: yup
    .string()
    .trim()
    .required({ name: "Phone", code: "REQUIRED_FIELD" }),
  email: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  geoLocation: yup
    .string()
    .nullable()
    .required({ name: "Location", code: "REQUIRED_FIELD" }),
  description: yup.string().trim().notRequired().nullable(),
  country_id: yup
    .number()
    .required({ name: "Country", code: "DATA_NOT_SELECTED" }),
  timezone: yup.string().required({ name: "Timezone", code: "REQUIRED_FIELD" }),
  type: yup.string().required({ name: "Type", code: "REQUIRED_FIELD" }),
});

const LocationModal: React.FC = () => {
  const { name, props, msg } = useReactiveVar(modalData);
  const { getCoordinates, isLoading } = GoogleAPI();

  const [location, setLocation] = useState(locationInitials);

  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);

  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [countryInputOpen, setCountryInputOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, countryInputOpen);

  const queryPhoneCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: phoneCountries,
    loading: loadingPhoneCountries,
    search: phoneCountryInput,
    setSearch: setPhoneCountryInput,
  } = useSearch(queryPhoneCountries, { limit: 3 }, 300, isCountryDropOpen);

  const { validator } = ValidationsFeHandler();

  const [
    createLocation,
    {
      data: createdLocation,
      loading: loadingCreatedLocation,
      error: errorCreatedLocation,
    },
  ] = useCreateLocationMutation();
  const [
    createPlaceholderLocation,
    {
      data: createPlaceholderdLocation,
      loading: loadingCreatePlaceholderdLocation,
      error: errorCreatePlaceholderdLocation,
    },
  ] = useCreatePlaceholderLocationMutation();
  const [
    updateLocation,
    {
      data: dataUpdateLocation,
      loading: loadingUpdateLocation,
      error: errorUpdateLocation,
    },
  ] = useUpdateLocationMutation();

  const [isTimezoneUnloadingDropdownOpen, setIsTimezoneUnloadingDropdownOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (name === "locationEditModal") {
      setLocation({ ...props });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setLocation({
      ...location,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const coordinates = await getCoordinates(
      location.address,
      location.postName,
      location.postCode,
      location.country.name
    );

    const variables = {
      company_id: props.company_id,
      title: location.title,
      address: location.address,
      postName: location.postName,
      postCode: location.postCode,
      contactName: location.contactName,
      phone: `(${location.phoneCountryNumbers})${location.phone}`,
      email: location.email,
      geoLocation: coordinates ? JSON.stringify(coordinates) : null,
      description: location.description,
      country_id: location.country.id,
      timezone: location.timezone,
      type: LocationTypeReadFormat(location.type).type,
    };

    validator({
      schema: schema,
      data: { ...variables },
      success: async () => {
        if (name === "locationModal") {
          await createLocation({
            variables: {
              location: {
                ...variables,
                type: LocationTypeReadFormat(location.type).type,
              },
            },
            refetchQueries: [GetLocationsDocument],
            awaitRefetchQueries: true,
          });
        }

        if (name === "locationEditModal") {
          const { company_id, ...updateVar } = variables;
          await updateLocation({
            variables: {
              location: {
                ...updateVar,
                type: LocationTypeReadFormat(location.type).type,
                id: props.id,
              },
            },
            refetchQueries: [GetLocationsDocument],
            awaitRefetchQueries: true,
          });
        }

        if (name === "locationPlaceholderModal") {
          await createPlaceholderLocation({
            variables: {
              location: {
                ...variables,
                type: LocationTypeReadFormat(location.type).type,
              },
            },
            refetchQueries: [GetLocationsDocument],
            awaitRefetchQueries: true,
          });
        }
      },
    });
  };

  ErrorsBeHandler({
    error: errorCreatedLocation,
    dep: name === "locationModal",
  });

  SuccessBeHandler({
    data: createdLocation,
    code: "LOCATION_CREATED",
    dep: name === "locationModal",
    fn: () => {
      setLocation(locationInitials);
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  ErrorsBeHandler({
    error: errorUpdateLocation,
    dep: name === "locationEditModal",
  });

  SuccessBeHandler({
    data: dataUpdateLocation,
    code: "LOCATION_UPDATED",
    dep: name === "locationEditModal",
    fn: () => {
      setLocation(locationInitials);
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  ErrorsBeHandler({
    error: errorCreatePlaceholderdLocation,
    dep: name === "locationPlaceholderModal",
  });

  SuccessBeHandler({
    data: createPlaceholderdLocation,
    code: "LOCATION_CREATED",
    dep: name === "locationPlaceholderModal",
    fn: () => {
      setLocation(locationInitials);
      modalData({
        msg: null,
        name: null,
        returned_value: {
          action: props.action,
          location: {
            id: createPlaceholderdLocation?.createPlaceholderLocation.id,
            status:
              createPlaceholderdLocation?.createPlaceholderLocation.status,
            postName:
              createPlaceholderdLocation?.createPlaceholderLocation.postName,
            postCode:
              createPlaceholderdLocation?.createPlaceholderLocation.postCode,
            address:
              createPlaceholderdLocation?.createPlaceholderLocation.address,
            title: createPlaceholderdLocation?.createPlaceholderLocation.title,
            country_id:
              createPlaceholderdLocation?.createPlaceholderLocation.country_id,
            country_shortName: "",
            timezone:
              createPlaceholderdLocation?.createPlaceholderLocation.timezone,
          },
        },
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={
        !!(
          name === "locationModal" ||
          name === "locationEditModal" ||
          name === "locationPlaceholderModal"
        )
      }
      onClose={() => {
        setLocation(locationInitials);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      <Div
        maxH={`${height - 100}px`}
        style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
      >
        <Text textSize="201" textAlign="left" textWeight="700" textColor="dark">
          {msg}
        </Text>

        <Row d="column">
          {props &&
            (name === "locationModal" ||
              name === "locationEditModal" ||
              name === "locationPlaceholderModal") && (
              <>
                <Col>
                  <Row>
                    <Col size="6">
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Title
                      </Text>
                      <Input
                        placeholder="Title"
                        name="title"
                        value={location.title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        h="40px"
                        type="text"
                        m={{ y: "0.5rem" }}
                      />
                    </Col>
                    <Col size="6">
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Address
                      </Text>
                      <Input
                        placeholder="address"
                        name="address"
                        value={location.address}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        h="40px"
                        type="text"
                        m={{ y: "0.5rem" }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col size={width > 520 ? "4" : "6"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        Post code
                      </Text>
                      <Input
                        placeholder="Post code"
                        name="postCode"
                        value={location.postCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        type="text"
                        h="40px"
                        m={{ b: "0.5rem" }}
                      />
                    </Col>

                    <Col size={width > 520 ? "8" : "6"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        City
                      </Text>
                      <Input
                        placeholder="City"
                        name="postName"
                        m={{ b: "0.5rem" }}
                        value={location.postName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        type="text"
                        h="40px"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        Country
                      </Text>
                      <InputDropdown
                        addNew={false}
                        displayResult={
                          <Div d="flex" h="24px" align="center">
                            <Text
                              textColor={
                                location.country.name ? "dark" : `semiLight`
                              }
                              textWeight="500"
                              m={{ r: "auto", l: "0" }}
                              textSize={16}
                            >
                              {location.country?.id
                                ? location.country?.name
                                : `Select country`}
                            </Text>
                          </Div>
                        }
                        isLoading={loadingCountries}
                        isOpen={countryInputOpen}
                        setIsOpen={setCountryInputOpen}
                        value={countryInput}
                        handleChange={setCountryInput}
                        menuList={
                          countries &&
                          countries?.getCountries.items?.map(
                            (country: any, i: number) => (
                              <Anchor
                                d="flex"
                                p={{ y: "0.5rem", x: "0.75rem" }}
                                key={i}
                                align="center"
                                onClick={() => {
                                  setLocation({
                                    ...location,
                                    country: {
                                      id: country.id,
                                      name: country.name,
                                      code: country.code,
                                    },
                                  });
                                  setCountryInputOpen(false);
                                }}
                              >
                                <Text
                                  textColor="semiDark"
                                  hoverTextColor="dark"
                                >
                                  {" "}
                                  {`${country?.name}`}
                                </Text>
                              </Anchor>
                            )
                          )
                        }
                      />
                    </Col>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        Timezone
                      </Text>
                      <Dropdown
                        style={{
                          PointerEvent: location.country.code ? "auto" : "none",
                        }}
                        bg={location.country.code ? "transparent" : "grey"}
                        h="40px"
                        isOpen={isTimezoneUnloadingDropdownOpen}
                        onClick={
                          location.country.id
                            ? () =>
                                setIsTimezoneUnloadingDropdownOpen(
                                  !isTimezoneUnloadingDropdownOpen
                                )
                            : ""
                        }
                        menu={
                          <Div maxH="300px" overflow="visible scroll">
                            {location.country.code &&
                              Array.from(
                                new Set([
                                  ...moment.tz.zonesForCountry(
                                    location.country.code
                                  ),
                                ])
                              ).map((item: string, i: number) => (
                                <Anchor
                                  d="flex"
                                  p={{ y: "0.5rem", x: "0.75rem" }}
                                  align="center"
                                  key={i}
                                  onClick={() => {
                                    setLocation({
                                      ...location,
                                      timezone: item,
                                    });
                                    setIsTimezoneUnloadingDropdownOpen(false);
                                  }}
                                >
                                  <Text
                                    textColor="semiDark"
                                    hoverTextColor="dark"
                                  >
                                    {item}
                                  </Text>
                                </Anchor>
                              ))}
                          </Div>
                        }
                      >
                        <Text
                          textColor={location.timezone ? "dark" : `semiLight`}
                          textWeight="500"
                          m={{ r: "auto", l: "0" }}
                          textSize={16}
                        >
                          {location.timezone
                            ? location.timezone
                            : "Select timezone"}
                        </Text>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Type
                      </Text>
                      <Dropdown
                        h="40px"
                        m={{ t: "0.5rem" }}
                        isOpen={isTypeDropdownOpen}
                        onClick={() =>
                          setIsTypeDropdownOpen(!isTypeDropdownOpen)
                        }
                        menu={
                          <Div maxH="300px" overflow="visible scroll">
                            {
                              // eslint-disable-next-line array-callback-return
                              Object.values(LocationType).map(
                                (name: LocationType, i: number) => {
                                  if (
                                    name !== LocationType.CompanyBillingAddress
                                  ) {
                                    return (
                                      <Anchor
                                        d="flex"
                                        p={{ y: "0.5rem", x: "0.75rem" }}
                                        align="center"
                                        key={i}
                                        onClick={() => {
                                          setLocation({
                                            ...location,
                                            type: name,
                                          });
                                          setIsTypeDropdownOpen(false);
                                        }}
                                      >
                                        <Text
                                          textColor="semiDark"
                                          hoverTextColor="dark"
                                        >
                                          {LocationTypeReadFormat(name).name}
                                        </Text>
                                      </Anchor>
                                    );
                                  }
                                }
                              )
                            }
                          </Div>
                        }
                      >
                        <Text
                          textColor={location.type ? "dark" : `semiLight`}
                          textWeight="500"
                          m={{ r: "auto", l: "0" }}
                          textSize={16}
                        >
                          {location.type
                            ? LocationTypeReadFormat(location.type).name
                            : "Select type"}
                        </Text>
                      </Dropdown>
                    </Col>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "1rem" }}
                      >
                        Contact full name
                      </Text>
                      <Input
                        placeholder="Contact Full name"
                        name="contactName"
                        value={location.contactName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        h="40px"
                        type="text"
                        m={{ y: "0.5rem" }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        Email
                      </Text>
                      <Input
                        placeholder="Email"
                        name="email"
                        value={location.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                        textSize={"16"}
                        h="40px"
                        type="text"
                        m={{ b: "0.5rem" }}
                      />
                    </Col>
                    <Col size={width > 520 ? "6" : "12"}>
                      <Text
                        textColor="semiDark"
                        textSize={12}
                        textWeight={500}
                        m={{ b: "0.25rem", t: "0.5rem" }}
                      >
                        Phone
                      </Text>
                      <PrefixDropdown
                        inputValue={location.phone}
                        countryInputValue={phoneCountryInput}
                        h="40px"
                        format="## ### ### ###"
                        placeholder="Phone"
                        addNew={true}
                        displayResult={
                          <Div
                            d="flex"
                            h="24px"
                            align="center"
                            justify="center"
                            m={{ l: "auto", r: "-0.75rem" }}
                            w="35px"
                          >
                            <Text
                              textColor="dark"
                              textWeight="500"
                              m={{ l: "0" }}
                              textSize={16}
                            >{`${location.phoneCountryNumbers}`}</Text>
                          </Div>
                        }
                        isLoading={loadingPhoneCountries}
                        isOpen={isCountryDropOpen}
                        setIsOpen={setIsCountryDropOpen}
                        setCountryInput={setPhoneCountryInput}
                        handleChange={(value: any) =>
                          setLocation({
                            ...location,
                            phone: value,
                          })
                        }
                        menuList={phoneCountries?.getCountries.items?.map(
                          (item: any, i: number) => (
                            <Anchor
                              d="flex"
                              p={{ y: "0.5rem", x: "0.75rem" }}
                              key={i}
                              align="center"
                              onClick={() => {
                                setLocation({
                                  ...location,
                                  phoneCountryNumbers: item.phoneCode,
                                });
                                setIsCountryDropOpen(false);
                              }}
                            >
                              <Text
                                textColor="semiDark"
                                hoverTextColor="dark"
                                p={{ l: "10px" }}
                              >
                                {" "}
                                {`${item.phoneCode}`}
                              </Text>
                              <Text
                                textColor="semiDark"
                                hoverTextColor="dark"
                                p={{ l: "10px" }}
                              >
                                {" "}
                                {`${item.name}`}
                              </Text>
                            </Anchor>
                          )
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col size="12">
                  <Text
                    textColor="semiDark"
                    textSize={12}
                    textWeight={500}
                    m={{ b: "0.25rem", t: "0.5rem" }}
                  >
                    Comment
                  </Text>
                  <Textarea
                    m={{ b: "2rem" }}
                    placeholder="Enter any description you’d like to include in your bid..."
                    value={location.description}
                    name="description"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                  />
                </Col>

                <Col>
                  <Row>
                    <Col size="auto">
                      <PrimaryBtn
                        w="120px"
                        handleSubmit={(
                          e: React.ChangeEvent<HTMLButtonElement>
                        ) => handleSubmit(e)}
                        isLoading={
                          isLoading ||
                          loadingCreatedLocation ||
                          loadingUpdateLocation ||
                          loadingCreatePlaceholderdLocation
                        }
                        text={
                          name === "locationEditModal" ? "Update" : "Create"
                        }
                      />
                    </Col>
                    <Col size="auto">
                      <SecondaryBtn
                        w="120px"
                        handleSubmit={() => {
                          setLocation(locationInitials);
                          modalData({
                            name: "",
                            msg: "",
                            returned_value: null,
                            props: null,
                          });
                        }}
                        isLoading={
                          isLoading ||
                          loadingCreatedLocation ||
                          loadingUpdateLocation ||
                          loadingCreatePlaceholderdLocation
                        }
                        text={"Cancel"}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
        </Row>
      </Div>
    </StyledModal>
  );
};

export default LocationModal;
