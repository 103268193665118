import React from "react";
import PreloadingImage from "../PreloadingImage/PreloadingImage";
import Skeleton from "../Skeleton/Skeleton";
import { Icon, Col, Text, Div, Tag } from "atomize";
import ShortNameLogo from "../ShortNameLogo/ShortNameLogo";
import Border from "../Border/Border";
import { GlobalTheme } from "../GlobalTheme";
import {
  AssignedUser,
  AssignedUserType,
  Company,
  GetActivityLogsDocument,
  GetOrderDocument,
  Location,
  useDeleteAssignedUserMutation,
  useGetMeQuery,
} from "../../generated/graphql";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { modalData } from "../../App";

interface CompanyT extends Company {
  types: AssignedUserType[];
  isMyCompany: boolean;
  defaultContact: any;
  loadingLocation?: Location;
  unloadingLocation?: Location;
}

interface ContactCardProps {
  party: CompanyT;
  assignedUsers: AssignedUser[] | null;
  order_id: number | null;
  isAssigned: boolean;
}

const ContactCard: React.FC<ContactCardProps> = ({
  assignedUsers,
  order_id,
  party,
  isAssigned,
}) => {
  const ass = assignedUsers
    ? assignedUsers.filter((user: AssignedUser) => party.id === user.company_id)
    : [];
  const { data: me } = useGetMeQuery();
  const { width } = useWindowDimensions();

  const [
    deleteAssignUser,
    { data: deleteAssignUserData, error: deleteAssignUserError },
  ] = useDeleteAssignedUserMutation({ errorPolicy: "all" });

  SuccessBeHandler({
    data: deleteAssignUserData,
    code: "DELETE_ASSIGNED_USER",
  });

  ErrorsBeHandler({
    error: deleteAssignUserError,
    fn: () => {
      modalData({ msg: null, name: null, returned_value: null, props: null });
    },
  });

  return (
    <Col size={width > 880 ? "auto" : "12"} h="100%">
      <Div
        minW="280px"
        d="flex"
        h="auto"
        flexDir="column"
        align="flex-start"
        pos="relative"
        shadow={1}
        border="1px solid"
        bg={`${GlobalTheme.colors.background}`}
        borderColor="grey"
        p={{ x: "1rem", t: "1rem", b: "0.5rem" }}
        className="box disabled"
        m={{ b: "1rem" }}
      >
        <Div d="flex" align="center" m={{ b: "0.75rem" }}>
          <PreloadingImage
            imageSrc={party?.logoPath ? party.logoPath : ""}
            skeletonComponent={
              <Skeleton height={"42px"} width={"42px"} borderRadius={"42px"} />
            }
            isLoading={false}
            imgComponent={<ShortNameLogo name={party?.name} size="42px" />}
            imageClassNames="i_b f_l"
            imageStyle={{
              width: "42px",
              height: "42px",
              borderRadius: "42px",
              verticalAlign: "middle",
            }}
          />
          <Div m={{ l: "10px" }} d="flex" flexDir="column">
            <Text textSize={16} textColor="dark" textWeight="600">
              {party?.name}
            </Text>
            <Div d="flex">
              {party.types.map((type: AssignedUserType, i: number) => (
                <Tag
                  bg={`${GlobalTheme.colors.background}`}
                  border="1px solid"
                  borderColor={`${
                    me?.getUser.company?.id === party.id
                      ? GlobalTheme.colors.primary
                      : GlobalTheme.colors.semiLight
                  }`}
                  textColor={`${
                    me?.getUser.company?.id === party.id
                      ? GlobalTheme.colors.primary
                      : GlobalTheme.colors.semiLight
                  }`}
                  h="20px"
                  m={{ r: "0.5rem", b: "0rem" }}
                  key={i}
                >
                  {type}
                </Tag>
              ))}
            </Div>
          </Div>
        </Div>
        {ass?.length === 0 && party?.defaultContact.id ? (
          <Div m={{ b: "0.5rem" }} pos="relative" w="100%">
            <Border color={`${GlobalTheme.colors.greyBorder}`}></Border>
            <Text
              m={{ t: "0.5rem" }}
              textSize={10}
              textColor="semiDark"
              style={{ letterSpacing: "0.1px" }}
              textWeight="600"
            >
              DEFAULT
            </Text>
            <Text
              m={{ t: "0.5rem" }}
              textSize={16}
              textColor="dark"
              textWeight="600"
            >{`${party.defaultContact?.firstName} ${party.defaultContact?.lastName} `}</Text>
            <Text
              m={{ t: "0.5rem" }}
              textSize={14}
              textColor="semiDark"
              textWeight="400"
            >{`${party.defaultContact?.email}`}</Text>
            <Text
              m={{ t: "0.5rem" }}
              textSize={14}
              textColor="semiDark"
              textWeight="400"
            >{`${party.defaultContact?.phone}`}</Text>
          </Div>
        ) : (
          ass?.map((a_user: any, i: number) => (
            <Div key={i} m={{ b: "0.5rem" }} pos="relative" w="100%">
              <Border color={`${GlobalTheme.colors.greyBorder}`}></Border>
              {isAssigned && party.isMyCompany && (
                <Icon
                  onClick={() => {
                    deleteAssignUser({
                      variables: {
                        user_id: a_user.user_id,
                        id: Number(order_id),
                      },
                      refetchQueries: [
                        GetOrderDocument,
                        GetActivityLogsDocument,
                      ],
                      awaitRefetchQueries: true,
                    });
                  }}
                  name="Cross"
                  color={"danger"}
                  hovercolor="brand900"
                  left="auto"
                  right="0rem"
                  m={{ t: "10px" }}
                  size="20px"
                  pos="absolute"
                  cursor="pointer"
                />
              )}
              <Text
                m={{ t: "0.5rem" }}
                textSize={10}
                textColor="semiDark"
                style={{ letterSpacing: "0.1px" }}
                textWeight="600"
              >
                {a_user.type === AssignedUserType.Driver
                  ? "DRIVER"
                  : "DISPATCHER"}
              </Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={16}
                textColor="dark"
                textWeight="600"
              >{`${a_user.user.firstName} ${a_user.user.lastName} `}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${a_user.user.email}`}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${a_user.user.phone}`}</Text>
            </Div>
          ))
        )}
        {party.loadingLocation !== null && (
          <Div m={{ b: "0.5rem" }} w="100%">
            <Border color={`${GlobalTheme.colors.grey}`}></Border>
            <Text
              m={{ t: "0.5rem" }}
              textSize={10}
              textColor="semiDark"
              style={{ letterSpacing: "0.1px" }}
              textWeight="600"
            >
              LOADING CONTACT
            </Text>
            <>
              <Text
                m={{ t: "0.5rem" }}
                textSize={16}
                textColor="dark"
                textWeight="600"
              >{`${party?.loadingLocation?.contactName}`}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${party?.loadingLocation?.email}`}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${party?.loadingLocation?.phone}`}</Text>
            </>
          </Div>
        )}
        {party?.unloadingLocation !== null && (
          <Div m={{ b: "0.5rem" }} w="100%">
            <Border color={`${GlobalTheme.colors.grey}`}></Border>
            <Text
              m={{ t: "0.5rem" }}
              textSize={10}
              textColor="semiDark"
              style={{ letterSpacing: "0.1px" }}
              textWeight="600"
            >
              UNLOADING CONTACT
            </Text>
            <>
              <Text
                m={{ t: "0.5rem" }}
                textSize={16}
                textColor="dark"
                textWeight="600"
              >{`${party?.unloadingLocation?.contactName}`}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${party?.unloadingLocation?.email}`}</Text>
              <Text
                m={{ t: "0.5rem" }}
                textSize={14}
                textColor="semiDark"
                textWeight="400"
              >{`${party?.unloadingLocation?.phone}`}</Text>
            </>
          </Div>
        )}
      </Div>
    </Col>
  );
};

export default ContactCard;
