import { Div, Text } from "atomize";
import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { GlobalTheme } from "../GlobalTheme";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 8px solid ${GlobalTheme.colors.greyBorder};
  border-top: 8px solid ${GlobalTheme.colors.primary};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 0.75s linear infinite;
`;

interface Props {
  isOpen: boolean;
  title?: string;
}

const LoaderScreen: FC<Props> = ({ isOpen = false, title }) => {
  if (!isOpen) return null;

  return (
    <>
      <Div
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="100vw"
        h="100vh"
        d="flex"
        flexDir="column"
        align="center"
        justify="center"
        bg={GlobalTheme.colors.background}
        zIndex="1000"
        style={{
          position: "fixed",
        }}
      >
        <Loader />
        {title && (
          <Text textAlign="center" textSize="heading" m={{ t: "20px" }}>
            {title}
          </Text>
        )}
      </Div>
    </>
  );
};

export default LoaderScreen;
