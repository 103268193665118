import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Input, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  GetDriversDocument,
  GetUsersDocument,
  useGetCountriesLazyQuery,
  useUpdateUserMutation,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrefixDropdown from "../../PrefixDropdown/PrefixDropdown";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const userInitials = {
  firstName: "",
  lastName: "",
  email: "",
  phoneCountryNumbers: "386",
  phone: "",
  roles: [] as any,
  isExternalDriver: false,
};

let schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required({ name: "First name", code: "REQUIRED_FIELD" }),
  lastName: yup
    .string()
    .trim()
    .required({ name: "Last name", code: "REQUIRED_FIELD" }),
  phone: yup.string(),
});

const UserModal: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);
  const [user, setUser] = useState(userInitials);
  const queryPhoneCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: phoneCountries,
    loading: loadingPhoneCountries,
    search: phoneCountryInput,
    setSearch: setPhoneCountryInput,
  } = useSearch(queryPhoneCountries, { limit: 3 }, 300, isCountryDropOpen);

  const [
    updateUser,
    {
      data: dataUpdateUser,
      loading: loadingUpdateUser,
      error: errorUpdateUser,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (name === "userEditModal") {
      setUser({
        ...props,
        phoneCountryNumbers:
          props.phoneCountryNumbers || userInitials.phoneCountryNumbers,
        isExternalDriver:
          props.isExternalDriver || userInitials.isExternalDriver,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const variables = {
      id: props.id,
      company_id: props.company_id,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone ? `(${user.phoneCountryNumbers})${user.phone}` : ``,
      isExternalDriver: true,
    };

    validator({
      schema: schema,
      data: variables,
      success: () => {
        const { company_id, ...updateVar } = variables;
        updateUser({
          variables: { user: { ...updateVar, id: props.id } },
          refetchQueries: [GetUsersDocument, GetDriversDocument],
          awaitRefetchQueries: true,
        });
      },
    });
  };

  ErrorsBeHandler({
    error: errorUpdateUser,
  });

  SuccessBeHandler({
    data: dataUpdateUser,
    code: "UPDATED_USER",
    fn: () => {
      setUser(userInitials);
      modalData({
        msg: "",
        name: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "userModal" || name === "userEditModal")}
      onClose={() => {
        setUser(userInitials);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      <Div
        maxH={`${height * 0.7}px`}
        style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
      >
        <Text textSize="201" textAlign="left" textWeight="700" textColor="dark">
          {msg}
        </Text>

        <Row d="column">
          {props && (name === "userModal" || name === "userEditModal") && (
            <>
              <Col size="12">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  First name
                </Text>
                <Input
                  placeholder="First name"
                  name="firstName"
                  value={user.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                  textSize={"16"}
                  h="48px"
                  type="text"
                  m={{ y: "0.5rem" }}
                />
              </Col>

              <Col size="12">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Last name
                </Text>
                <Input
                  placeholder="Last name"
                  name="lastName"
                  value={user.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                  textSize={"16"}
                  h="48px"
                  type="text"
                  m={{ y: "0.5rem" }}
                />
              </Col>
              <Col size="12">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Phone
                </Text>
                <PrefixDropdown
                  inputValue={user.phone}
                  countryInputValue={phoneCountryInput}
                  h="48px"
                  format="## ### ### ###"
                  placeholder="Phone"
                  addNew={true}
                  displayResult={
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      m={{ l: "auto", r: "-0.75rem" }}
                    >
                      <Text
                        textColor="dark"
                        textWeight="500"
                        m={{ l: "auto" }}
                        textSize={16}
                      >{`${user.phoneCountryNumbers}`}</Text>
                    </Div>
                  }
                  isLoading={loadingPhoneCountries}
                  isOpen={isCountryDropOpen}
                  setIsOpen={setIsCountryDropOpen}
                  setCountryInput={setPhoneCountryInput}
                  handleChange={(value: any) =>
                    setUser({
                      ...user,
                      phone: value,
                    })
                  }
                  menuList={phoneCountries?.getCountries.items?.map(
                    (item: any, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={() => {
                          setUser({
                            ...user,
                            phoneCountryNumbers: item.phoneCode,
                          });
                          setIsCountryDropOpen(false);
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                        >
                          {" "}
                          {`${item.phoneCode}`}
                        </Text>
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                        >
                          {" "}
                          {`${item.name}`}
                        </Text>
                      </Anchor>
                    )
                  )}
                />
              </Col>
              <Div h="2rem"> </Div>
              <Col>
                <Row>
                  <Col size="auto">
                    <PrimaryBtn
                      w="120px"
                      handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                        handleSubmit(e)
                      }
                      isLoading={loadingUpdateUser}
                      text={"Update user"}
                    />
                  </Col>
                  <Col size="auto">
                    <SecondaryBtn
                      w="120px"
                      handleSubmit={() => {
                        setUser(userInitials);
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        });
                      }}
                      isLoading={loadingUpdateUser}
                      text={"Cancel"}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Div>
    </StyledModal>
  );
};

export default UserModal;
