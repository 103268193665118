import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Row, Text } from "atomize";
import React, { useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import { useInviteCompanyMutation } from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

let schema = yup.object().shape({
  email: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  name: yup.string().required({ name: "Name", code: "REQUIRED_FIELD" }),
});

const InviteCompany: React.FC = () => {
  const { name, msg } = useReactiveVar(modalData);

  const { validator } = ValidationsFeHandler();
  const [
    inviteCompany,
    {
      data: inviteCompanyData,
      loading: inviteCompanyLoading,
      error: inviteCompanyError,
    },
  ] = useInviteCompanyMutation({ errorPolicy: "all" });

  const [company, setCompany] = useState({
    email: "",
    name: "",
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: { ...company },
      success: () => {
        inviteCompany({
          variables: { name: company?.name, email: company.email },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: inviteCompanyError,
    dep: name === "inviteCompany",
  });

  SuccessBeHandler({
    data: inviteCompanyData,
    code: "INVITE_USER",
    dep: name === "inviteCompany",
    fn: () => {
      setCompany({
        email: "",
        name: "",
      });
      modalData({
        msg: null,
        name: null,
        returned_value: null,
      });
    },
  });
  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "inviteCompany")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {name === "inviteCompany" && (
        <Div
          maxH="80vh"
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
          >
            {msg}
          </Text>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "1rem" }}
              >
                Company name
              </Text>
              <Input
                placeholder="Company"
                name="companyName"
                value={company.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCompany({
                    ...company,
                    name: e.target.value,
                  });
                }}
                textSize={"16"}
                type="text"
                h="48px"
                m={{ b: "1rem" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem", t: "0.5rem" }}
              >
                Company email
              </Text>
              <Input
                placeholder="example@mail.com"
                name="email"
                value={company.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCompany({
                    ...company,
                    email: e.target.value,
                  });
                }}
                textSize={"16"}
                type="text"
                h="48px"
                m={{ b: "2rem" }}
              />
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={inviteCompanyLoading}
                text={"Invite"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setCompany({
                    email: "",
                    name: "",
                  });
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={inviteCompanyLoading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default InviteCompany;
