import styled from "styled-components";

export const SortIcon = styled.div`
  cursor: pointer;
  position: relative;
  padding: 5px;
`;

interface LineProps {
  width: string;
}

export const Line = styled.div<LineProps>`
  background: ${(props) => props.theme.colors.dark};
  height: 1.8px;
  transition: all ease-in-out 0.3s;

  &.middle {
    margin: 1.45px 0;
  }

  width: ${(props) => props.width};
`;
