import * as yup from "yup";
import { TransportCargoType } from "../../generated/graphql";

export const schema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .max(400, { name: "Description", code: "MAX_CHARACTERS", var: "8" }),
  shipperCompany_id: yup
    .number()
    .required({ name: "Shipper Company", code: "DATA_NOT_SELECTED" }),
  receiverCompany_id: yup
    .number()
    .required({ name: "Receiver Company", code: "DATA_NOT_SELECTED" }),
  loadingLocation_id: yup
    .number()
    .required({ name: "Loading location", code: "DATA_NOT_SELECTED" }),
  unloadingLocation_id: yup
    .number()
    .required({ name: "Unloading location", code: "DATA_NOT_SELECTED" }),
  requestedLoadingTime: yup
    .date()
    .max(yup.ref("requestedUnloadingTime"), {
      name: "Loading date",
      code: "DATE_MIN",
      var: "order unloading date",
    })
    .when("$onMarket", {
      is: true,
      then: yup
        .date()
        .min(yup.ref("marketExpirationDate"), {
          name: "Loading date",
          code: "DATE_MIN",
          var: "order expiry date",
        })
        .max(yup.ref("requestedUnloadingTime"), {
          name: "Loading date",
          code: "DATE_MIN",
          var: "order unloading date",
        }),
    }),
  requestedUnloadingTime: yup.date().min(yup.ref("requestedLoadingTime"), {
    name: "Unloading date",
    code: "DATE_MIN",
    var: "order unloading date",
  }),
  maxCargoVolume: yup
    .number()
    .max(15.4, { name: "Volume", code: "MAX_CHARACTERS", var: "15.40 LDM" }),
  maxCargoWeight: yup
    .number()
    .max(26400, { name: "Weight", code: "MAX_CHARACTERS", var: "26500 KG" }),
  dangerous: yup.boolean(),
  stackable: yup.boolean(),
  cargoType: yup.string(),
  parcels: yup.array().of(
    yup.object().shape({
      weight: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) =>
            val === TransportCargoType.VolumeWeight ||
            val === TransportCargoType.SizeWeight ||
            val === TransportCargoType.IndustryPallet ||
            val === TransportCargoType.EurPallet,
          then: yup
            .number()
            .required({ name: "Transport weight", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport weight", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport weight",
              code: "NOT_ZERO",
              var: " kg",
            }),
        }),
      width: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport width", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport width", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport width",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      length: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport length", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport length", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport length",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      height: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport height", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport height", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport height",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      volume: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.VolumeWeight,
          then: yup
            .number()
            .required({ name: "Transport volume", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport volume", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport volume",
              code: "NOT_ZERO",
              var: " LDM",
            }),
        }),
      quantity: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) =>
            val !== TransportCargoType.VolumeWeight &&
            val !== TransportCargoType.Ftl,
          then: yup
            .number()
            .required({ name: "Transport quantity", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport quantity", code: "REQUIRED_FIELD" })
            .min(1, { name: "Transport quantity", code: "NOT_ZERO", var: "" }),
        }),
    })
  ),
  transporterCompany_id: yup.number().when("$onMarket", {
    is: false,
    then: yup
      .number()
      .nullable(),
  }),
  price: yup.number().when("$onMarket", {
    is: false,
    then: yup
      .number()
      .nullable(),
  }),
  company_id: yup.number(),
  id: yup.number(),
  marketExpirationDate: yup.date().when("$onMarket", {
    is: true,
    then: yup
      .date()
      .max(yup.ref("requestedLoadingTime"), {
        name: "Order market expiry date",
        code: "DATE_MAX",
        var: "order loading date",
      })
      .min(yup.ref("currentDate"), {
        name: "Expiry date ",
        code: "DATE_MIN",
        var: "current date",
      }),
  }),
  currentDate: yup.date(),
});

export const transportOnlySchema = yup.object().shape({
  shipperCompany_id: yup
    .number()
    .required({ name: "Shipper Company", code: "DATA_NOT_SELECTED" }),
  receiverCompany_id: yup
    .number()
    .required({ name: "Receiver Company", code: "DATA_NOT_SELECTED" }),
  loadingLocation_id: yup
    .number()
    .required({ name: "Loading location", code: "DATA_NOT_SELECTED" }),
  unloadingLocation_id: yup
    .number()
    .required({ name: "Unloading location", code: "DATA_NOT_SELECTED" }),
  requestedLoadingTime: yup
    .date()
    .max(yup.ref("requestedUnloadingTime"), {
      name: "Loading date",
      code: "DATE_MIN",
      var: "order unloading date",
    })
    .when("$onMarket", {
      is: true,
      then: yup
        .date()
        .min(yup.ref("marketExpirationDate"), {
          name: "Loading date",
          code: "DATE_MIN",
          var: "order expiry date",
        })
        .max(yup.ref("requestedUnloadingTime"), {
          name: "Loading date",
          code: "DATE_MIN",
          var: "order unloading date",
        }),
    }),
  requestedUnloadingTime: yup.date().min(yup.ref("requestedLoadingTime"), {
    name: "Unloading date",
    code: "DATE_MIN",
    var: "order unloading date",
  }),
  dangerous: yup.boolean(),
  stackable: yup.boolean(),
  cargoType: yup.string(),
  parcels: yup.array().of(
    yup.object().shape({
      weight: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) =>
            val === TransportCargoType.VolumeWeight ||
            val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport weight", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport weight", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport weight",
              code: "NOT_ZERO",
              var: " kg",
            }),
        }),
      width: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport width", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport width", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport width",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      length: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport length", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport length", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport length",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      height: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.SizeWeight,
          then: yup
            .number()
            .required({ name: "Transport height", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport height", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport height",
              code: "NOT_ZERO",
              var: " cm",
            }),
        }),
      volume: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) => val === TransportCargoType.VolumeWeight,
          then: yup
            .number()
            .required({ name: "Transport volume", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport volume", code: "REQUIRED_FIELD" })
            .moreThan(0, {
              name: "Transport volume",
              code: "NOT_ZERO",
              var: " LDM",
            }),
        }),
      quantity: yup
        .number()
        .nullable()
        .when("$cargoType", {
          is: (val: any) =>
            val !== TransportCargoType.VolumeWeight &&
            val !== TransportCargoType.Ftl,
          then: yup
            .number()
            .required({ name: "Transport quantity", code: "REQUIRED_FIELD" })
            .typeError({ name: "Transport quantity", code: "REQUIRED_FIELD" })
            .min(1, { name: "Transport quantity", code: "NOT_ZERO", var: "" }),
        }),
    })
  ),
  id: yup.number(),
});
