import React, { useState } from "react";
import { Row, Col, Text, Input, Div, Anchor, Dropdown } from "atomize";
import {
  CompanyType,
  Country,
  useGetCountriesLazyQuery,
} from "../../../generated/graphql";
import PrefixDropdown from "../../PrefixDropdown/PrefixDropdown";
import { capitalize } from "lodash";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";

interface SingUpProps {
  setCompany: (company: any) => void;
  company: any;
  handleSubmit: (e: any) => void;
  handleResetData: () => void;
}

const CreatePlaceholderCompany: React.FC<SingUpProps> = ({
  setCompany,
  company,
  handleSubmit,
  handleResetData,
}) => {
  const [typeOpen, setTypeOpen] = useState<boolean>(false);

  const queryCountries = useGetCountriesLazyQuery();

  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, isCountryDropOpen);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setCompany({
      ...company,
      [target.name]: target.value,
    });
  };

  const handleTax = (value: string) => {
    setCompany({
      ...company,
      taxNumber: value,
    });
  };

  return (
    <>
      <Row flexDir="column" minW="100%">
        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Company legal name
          </Text>
          <Input
            placeholder="Company "
            h="48px"
            className="input"
            name="name"
            value={company.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            textSize={"16"}
            type="text"
          />
        </Col>

        <Col size="12" minH="56px">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            VAT number
          </Text>
          <PrefixDropdown
            inputValue={company.taxNumber}
            countryInputValue={countryInput}
            h="48px"
            format="## ### ### ### #"
            addNew={true}
            displayResult={
              <Div
                d="flex"
                h="24px"
                align="center"
                justify="center"
                m={{ l: "auto", r: "-0.75rem" }}
                w="25px"
              >
                <Text
                  textColor="dark"
                  textWeight="500"
                  textSize={16}
                  m={{ l: "0" }}
                >{`${company?.taxNumberCountry as string}`}</Text>
              </Div>
            }
            isLoading={loadingCountries}
            isOpen={isCountryDropOpen}
            setIsOpen={setIsCountryDropOpen}
            setCountryInput={setCountryInput}
            handleChange={(value: string) => handleTax(value)}
            menuList={countries?.getCountries.items?.map(
              (item: Country, i: number) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={i}
                  align="center"
                  onClick={() => {
                    setCompany({
                      ...company,
                      taxNumberCountry: item.code,
                    });
                    setIsCountryDropOpen(false);
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.code}`}
                  </Text>
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.name}`}
                  </Text>
                </Anchor>
              )
            )}
          />
        </Col>
        <Col>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Company type
          </Text>
          <Dropdown
            w="auto"
            h="3rem"
            isOpen={typeOpen}
            onClick={() => setTypeOpen(!typeOpen)}
            menu={
              <Div maxH="300px" overflow="visible scroll">
                {Object.values(CompanyType).map(
                  (type: CompanyType, index: number) => (
                    <Anchor
                      d="block"
                      p={{ y: "0.25rem", x: "0.75rem" }}
                      onClick={() => {
                        setCompany({
                          ...company,
                          type: type,
                        });
                        setTypeOpen(false);
                      }}
                      key={index}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        textTransform="capitalization"
                      >
                        {" "}
                        {capitalize(type)}{" "}
                      </Text>
                    </Anchor>
                  )
                )}
              </Div>
            }
          >
            {company.type ? (
              <Text
                textColor="semiDark"
                textSize={"16"}
                textTransform="capitalization"
              >
                {" "}
                {capitalize(company.type)}{" "}
              </Text>
            ) : (
              <Text
                textColor="semiLight"
                textSize="16"
                textTransform="capitalization"
              >
                Select type
              </Text>
            )}
          </Dropdown>
        </Col>
        <Col size="12">
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem", t: "1.5rem" }}
          >
            Company email
          </Text>
          <Input
            placeholder="company@domain.com"
            name="contactEmail"
            textSize={"16"}
            value={company.contactEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            h="3rem"
            m={{ t: "0.25rem", b: "2rem" }}
          />
        </Col>
        <Col>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={false}
                text={"Next"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={handleResetData}
                isLoading={false}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreatePlaceholderCompany;
