import React, { useEffect, useRef, useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { LocationTypeReadFormat, toReadValue } from "../../helpers/functions";
import { Text, Div } from "atomize";
import Skeleton from "../Skeleton/Skeleton";
import DropdownClassic from "../DropdownClassic/DropdownClassic";
import {
  GetLocationsDocument,
  Location,
  LocationStatus,
  SqlModelStatus,
  useActivateLocationMutation,
  useDectivateLocationMutation,
} from "../../generated/graphql";
import DropdownOptionsTd from "../DropdownOptionsTd/DropdownOptionsTd";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";

const LocationRow: React.FC<{ location: Location }> = ({ location }) => {
  const setDivPositionRef = useRef<HTMLDivElement>(null);
  const [
    activateLocation,
    { data: activateData, loading: activateDataLoading, error: activateError },
  ] = useActivateLocationMutation();
  const [
    deactivateLocation,
    {
      data: deactivateData,
      loading: deactivateDataLoading,
      error: deactivateError,
    },
  ] = useDectivateLocationMutation();
  const [dropdownCordinates, setdropdownCordinates] = useState({
    l: 0,
    t: 0,
    w: 0,
    h: 0,
  });

  const handleSubmitStatus = (status: SqlModelStatus) => {
    status === SqlModelStatus.Active &&
      activateLocation({
        variables: { id: location.id },
        refetchQueries: [GetLocationsDocument],
        awaitRefetchQueries: true,
      });
    status === SqlModelStatus.Deactivated &&
      deactivateLocation({
        variables: { id: location.id },
        refetchQueries: [GetLocationsDocument],
        awaitRefetchQueries: true,
      });
  };

  const [statusDropdown, setstatusDropdown] = useState<boolean>(false);

  useEffect(() => {
    setdropdownCordinates({
      l: Number(setDivPositionRef.current?.getBoundingClientRect().x),
      t: Number(setDivPositionRef.current?.getBoundingClientRect().top),
      w: Number(setDivPositionRef.current?.getBoundingClientRect().width),
      h: 80,
    });
  }, [setDivPositionRef, statusDropdown]);

  ErrorsBeHandler({
    error: activateError,
  });
  ErrorsBeHandler({
    error: deactivateError,
  });

  SuccessBeHandler({
    data: activateData,
    code: "LOCATION_ACTIVATE",
  });

  SuccessBeHandler({
    data: deactivateData,
    code: "LOCATION_DEACTIVATE",
  });

  let isLoading = activateDataLoading || deactivateDataLoading;
  return (
    <Tr>
      <Td width={"90px"}>
        <Text textColor="dark">
          {location.type && LocationTypeReadFormat(location?.type).name}
        </Text>
      </Td>
      <Td width={"140px"}>
        <Div w="100%" ref={setDivPositionRef}>
          <DropdownClassic
            isInTable={true}
            border={false}
            isOpen={statusDropdown}
            menuList={
              <DropdownOptionsTd
                handleSubmitStatus={handleSubmitStatus}
                dropdownCordinates={dropdownCordinates}
                statusDropdown={statusDropdown}
                options={
                  location.status === LocationStatus.Active
                    ? [LocationStatus.Deactivated]
                    : location.status === LocationStatus.Draft ||
                      location.status === LocationStatus.Placeholder
                    ? [LocationStatus.Active, LocationStatus.Deactivated]
                    : [LocationStatus.Active]
                }
              />
            }
            setIsOpen={setstatusDropdown}
          >
            {isLoading ? (
              <Skeleton width="90%" height="30px" />
            ) : (
              <Text
                textColor={"semiDark"}
                textSize={142}
                m={{ r: "auto", l: "1.5rem" }}
                textTransform="capitalize"
              >
                {location.status && toReadValue(location.status)}
              </Text>
            )}
          </DropdownClassic>
        </Div>
      </Td>
      <Td width={"120px"}>
        <Text textColor={"dark"}>
          {location.title ? location.title : "None"}
        </Text>
      </Td>
      <Td width={"140px"}>
        <Text textColor={"dark"}>{location.address}</Text>
      </Td>
      <Td width={"150px"}>
        <Text textColor={"dark"}>{location.postName}</Text>
      </Td>
      <Td width={"80px"}>
        <Text textColor={"dark"}>{location.postCode}</Text>
      </Td>
      <Td width={"120px"}>
        <Text textColor={"dark"}>{location?.country?.name}</Text>
      </Td>
      <Td width={"120px"}>
        <Text textColor="dark">
          {location.timezone ? location.timezone : "None"}
        </Text>
      </Td>
      <Td width={"120px"}>
        <Text textColor={"dark"}>
          {location.contactName ? location.contactName : "None"}
        </Text>
      </Td>
      <Td width={"140px"}>
        <Text textColor={"dark"}>
          {location.phone ? location.phone : "None"}
        </Text>
      </Td>
      <Td width={"180px"}>
        <Text textColor={"dark"}>
          {location.email ? location.email : "None"}
        </Text>
      </Td>
      <Td width={"200px"}>
        <Text textColor="dark">
          {location.description ? location.description : "None"}
        </Text>
      </Td>
    </Tr>
  );
};

export default LocationRow;
