/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Div } from "atomize";
import Skeleton from "../Skeleton/Skeleton";
import DropdownClassic from "../DropdownClassic/DropdownClassic";
import {
  GetMeDocument,
  GetUsersDocument,
  useChangeRoleMutation,
  User,
} from "../../generated/graphql";
import { DefaultUserRole } from "../../enums";
import DropdownOptionsTd from "../DropdownOptionsTd/DropdownOptionsTd";
import { getRoleName, getRoleValue, hasRole } from "../../helpers/functions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";

const UsersRow: React.FC<{ user?: User; me?: User | any }> = ({ user, me }) => {
  const setDivPositionRef = useRef<HTMLDivElement>(null);
  const [
    changeRole,
    { data: roleData, loading: changeRoleLoading, error: changeRoleError },
  ] = useChangeRoleMutation();
  const [rolesDropdown, setRolesDropdown] = useState<boolean>(false);

  const values = Object.values(DefaultUserRole).map((role) => {
    if (user && user.roles && user.roles[0]) {
      if (
        role !== user.roles[0].id &&
        role !== DefaultUserRole.ADMIN &&
        role !== DefaultUserRole.ACCOUNTANT &&
        role !== DefaultUserRole.DRIVER &&
        role !== DefaultUserRole.USER &&
        ((me && hasRole(me, DefaultUserRole.COMPANY_OWNER)) ||
          role !== DefaultUserRole.COMPANY_OWNER)
      ) {
        return getRoleName(role as DefaultUserRole);
      } else {
        return null;
      }
    }
  });

  const [dropdownCordinates, setdropdownCordinates] = useState({
    l: 0,
    t: 0,
    w: 0,
    h: 0,
  });

  useEffect(() => {
    setdropdownCordinates({
      l: Number(setDivPositionRef.current?.getBoundingClientRect().x),
      t: Number(setDivPositionRef.current?.getBoundingClientRect().top),
      w: Number(setDivPositionRef.current?.getBoundingClientRect().width),
      h: 80,
    });
  }, [setDivPositionRef, rolesDropdown]);

  const handleSubmitStatus = (role: string) => {
    changeRole({
      variables: { id: user!.id, role_id: getRoleValue(role) },
      refetchQueries: [GetUsersDocument, GetMeDocument],
      awaitRefetchQueries: true,
    });
  };

  ErrorsBeHandler({
    error: changeRoleError,
  });

  SuccessBeHandler({
    data: roleData,
    code: "ROLE_CHANGED",
  });

  return (
    <Tr>
      <Td width={"155px"}>
        <Div w="100%" ref={setDivPositionRef}>
          {changeRoleLoading ? (
            <Skeleton width="90%" height="30px" margin="5px 0 0 0" />
          ) : (
            <DropdownClassic
              isInTable={true}
              border={false}
              isLocked={user?.roles?.some(
                (x: any) =>
                  (x.id === DefaultUserRole.COMPANY_OWNER &&
                    !(
                      me.roles &&
                      me.roles[0].id === DefaultUserRole.COMPANY_OWNER
                    )) ||
                  x.id === DefaultUserRole.ADMIN
              )}
              isOpen={rolesDropdown}
              menuList={
                <DropdownOptionsTd
                  padding="0 0.5rem"
                  handleSubmitStatus={handleSubmitStatus}
                  dropdownCordinates={dropdownCordinates}
                  statusDropdown={rolesDropdown}
                  options={values}
                />
              }
              setIsOpen={setRolesDropdown}
            >
              <Text
                textColor={"semiDark"}
                textSize={142}
                m={{ r: "auto", l: "1rem" }}
                textTransform="capitalize"
              >
                {user?.roles &&
                  getRoleName(user.roles[0].id as DefaultUserRole)}
              </Text>
            </DropdownClassic>
          )}
        </Div>
      </Td>
      <Td width={"100px"}>
        <Text textColor={"dark"}>{user?.firstName}</Text>
      </Td>
      <Td width={"100px"}>
        <Text textColor={"dark"}>{user?.lastName}</Text>
      </Td>
      <Td width={"200px"}>
        <Text textColor={"dark"}>{user?.email}</Text>
      </Td>
      <Td width={"120px"}>
        <Text textColor="dark">{user?.phone ? user?.phone : "None"}</Text>
      </Td>
    </Tr>
  );
};

export default UsersRow;
