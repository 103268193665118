import * as Yup from "yup";

export const defaultSchema = Yup.object().shape({
  order_code: Yup.string()
    .trim()
    .required({ name: "Order ID", code: "REQUIRED_FIELD" }),
  fileName: Yup.string()
    .trim()
    .required({ name: "File", code: "REQUIRED_FIELD" }),
});

export const getInvoiceSchema = (isGenerateModal: boolean) =>
  Yup.object().shape({
    ...(isGenerateModal
      ? {
          taxationId: Yup.string().required({
            name: "Taxation",
            code: "DATA_NOT_SELECTED",
          }),
        }
      : {
          order_code: Yup.string()
            .trim()
            .required({ name: "Order ID", code: "REQUIRED_FIELD" }),
          fileName: Yup.string()
            .trim()
            .required({ name: "File", code: "REQUIRED_FIELD" }),
        }),
    amount: Yup.string().required({ name: "Amount", code: "REQUIRED_FIELD" }),
    paymentDueDate: Yup.date().required({
      name: "Payment Due Date",
      code: "REQUIRED_FIELD",
    }),
    payeeCompany_id: Yup.number().required({
      name: "Paying Company",
      code: "DATA_NOT_SELECTED",
    }),
    currency_id: Yup.number().required({
      name: "Currency",
      code: "DATA_NOT_SELECTED",
    }),
  });
