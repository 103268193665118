/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text, Textarea } from "atomize";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  FeedbackType,
  useCreateFeedbackMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const initial = {
  message: "",
  type: FeedbackType.Feedback,
  object_id: null,
};

const schema = yup.object().shape({
  type: yup.string(),
  message: yup
    .string()
    .trim()
    .required({ name: "Message", code: "REQUIRED_FIELD" }),
});

const ReportBug: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);
  const [createReport, { data, loading, error }] = useCreateFeedbackMutation({
    errorPolicy: "all",
  });

  const [report, setReport] = useState(initial);

  useEffect(() => {
    if (name === "reportBug") {
      setReport({
        ...report,
        type: props.type,
      });
    }
  }, [name]);

  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setReport({
      ...report,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: { type: report.type, message: report.message },
      success: () => {
        createReport({
          variables: { feedback: { ...report } },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: error,
    dep: name === "reportBug",
  });

  SuccessBeHandler({
    data: data,
    code: "REPORT_CREATED",
    dep: name === "reportBug",
    fn: () => {
      setReport(initial);
      modalData({
        name: "",
        msg: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "reportBug")}
      onClose={() => {
        setReport(initial);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "reportBug" && (
        <Div
          maxH="80vh"
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Text
            textSize="201"
            textAlign="left"
            textWeight="700"
            textColor="dark"
            m={{ b: "0.5rem" }}
          >
            {msg}
          </Text>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "2rem" }}
          >
            {props.subtext}
          </Text>

          <Row d="column">
            <Col>
              <Textarea
                value={report.message}
                placeholder="Tell us more about the issue you’re experiencing..."
                h="6rem"
                name="message"
                m={{ b: "2rem" }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleChange(e)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col size="auto">
              <PrimaryBtn
                w="120px"
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loading}
                text={"Send"}
              />
            </Col>
            <Col size="auto">
              <SecondaryBtn
                w="120px"
                handleSubmit={() => {
                  setReport(initial);
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default ReportBug;
