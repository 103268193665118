import styled from "styled-components";

export const OrderDetails = styled.div`
  flex: 1;
  overflow-x: hidden;
  .description_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all ease-in-out 0.2s;

    &.read_more_open {
      overflow: visible;
      display: block;
    }
  }
`;
