import { Anchor, Col, Row, Text } from "atomize";
import { Dispatch, SetStateAction, forwardRef } from "react";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { GetOrderQuery } from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";

interface Props {
  orderLoading: boolean;
  readMore: boolean | null;
  order: GetOrderQuery | undefined;
  setReadMore: Dispatch<SetStateAction<boolean | null>>;
}

const OrderDescription = forwardRef<HTMLParagraphElement, Props>(
  ({ orderLoading, readMore, order, setReadMore }, ref) => {
    const { width } = useWindowDimensions();

    return (
      <Row m={{ y: "2rem", r: width > 700 ? "1.5rem" : "0.5rem", l: "0" }}>
        <Col>
          <Text textSize={20} textColor="dark">
            Description
          </Text>
          {orderLoading ? (
            <Skeleton height="48px" />
          ) : (
            <>
              <Text
                textSize={141}
                textColor="dark"
                className={`description_text ${
                  readMore === true ? "read_more_open" : ""
                }`}
                ref={ref}
              >
                {order?.getOrder.description === ""
                  ? "None"
                  : order?.getOrder.description}
              </Text>
              {readMore !== null && (
                <Anchor
                  d="block"
                  m={{ t: "0.5rem" }}
                  textColor="light"
                  hoverTextColor="dark"
                  onClick={() => setReadMore(!readMore)}
                >
                  {readMore === true ? "Show less.." : "Read more..."}
                </Anchor>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
);

export default OrderDescription;
