import { Text } from "atomize";
import React from "react";
import { CSSProperties } from "styled-components";
import { LocalStorageKeys } from "../../helpers/constants";
import * as Styled from "./ShortNameLogo.style";

interface ShortNameLogoProps {
  name: string;
  size?: string;
  textSize?: number;
  style?: CSSProperties;
}

const color: string[] = [
  "#C9CED6",
  "#455571",
  "#78CBE6",
  "#FF5D5D",
  "#3ED3A3",
  "#FFCA65",
];

const ShortNameLogo: React.FC<ShortNameLogoProps> = ({
  name,
  size = "32px",
  textSize = 14,
  style,
}) => {
  let shortName: string = "";
  const nameArray = name.split(" ");
  if (nameArray.length > 1) {
    shortName = `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`;
  } else {
    shortName = `${nameArray[0].slice(0, 2)}`;
  }

  const placeholderColors: any = localStorage.getItem(
    LocalStorageKeys.PLACEHOLDER_COLORS
  );
  let randomColor: string = "";
  if (placeholderColors) {
    const placeholderColorsParesed = JSON.parse(placeholderColors);
    const placeholder = placeholderColorsParesed?.filter(
      (placeholder: any) => placeholder.name === name
    );
    if (placeholder.length > 0) {
      randomColor = placeholder[0].color;
    } else {
      randomColor = color[Math.floor(Math.random() * color.length)];
      localStorage.setItem(
        LocalStorageKeys.PLACEHOLDER_COLORS,
        JSON.stringify([
          ...placeholderColorsParesed,
          { name: name, color: randomColor },
        ])
      );
    }
  } else {
    randomColor = color[Math.floor(Math.random() * color.length)];
    localStorage.setItem(
      LocalStorageKeys.PLACEHOLDER_COLORS,
      JSON.stringify([{ name: name, color: randomColor }])
    );
  }

  return (
    <Styled.ShortNameLogo size={size} color={randomColor} style={{ ...style }}>
      <Text
        textColor="background"
        textTransform="uppercase"
        textSize={textSize}
      >
        {shortName}
      </Text>
    </Styled.ShortNameLogo>
  );
};

export default ShortNameLogo;
