import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as Styled from "./Chat.styles";
import { Text, Icon, Div, Button } from "atomize";
import PreloadingImage from "../PreloadingImage/PreloadingImage";
import Skeleton from "../Skeleton/Skeleton";
import ShortNameLogo from "../ShortNameLogo/ShortNameLogo";
import {
  Company,
  MessageListingItem,
  SqlModelStatus,
  useCreateMessageMutation,
  useGetMeQuery,
  useGetMessagesLazyQuery,
  useGetMessagesQuery,
  useNewMessageSubscription,
} from "../../generated/graphql";
import renderIcon from "../../assets/Icons";
import moment from "moment";
import Border from "../Border/Border";
import { GlobalTheme } from "../GlobalTheme";
import * as yup from "yup";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import { dateToFromNowDaily } from "../../helpers/functions";
import {
  currentOrder,
  notificationsCountSkip,
  orderMsgCountSkip,
} from "../../App";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import { useReactiveVar } from "@apollo/client";

moment.updateLocale("en", {
  calendar: {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[Last] dddd",
    nextWeek: "[Next] dddd",
    sameElse: "L",
  },
});

interface ChatProps {
  order_id?: number;
  transport_id?: number;
  ownerCompany?: Company | null;
  transporterCompany?: Company | null;
  shipperCompany?: Company | null;
  receiverCompany?: Company | null;
  total_people?: number;
  isLoading: boolean;
}

let schema = yup.object().shape({
  msg: yup.string().trim().required({ name: "Message", code: "" }),
});

const Chat: React.FC<ChatProps> = ({
  order_id,
  transport_id,
  ownerCompany,
  transporterCompany,
  shipperCompany,
  receiverCompany,
  isLoading,
}) => {
  const msgsContainer = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const { validator } = ValidationsFeHandler();
  const { width, height } = useWindowDimensions();
  const { data: me } = useGetMeQuery();
  const currentOrderVar = useReactiveVar(currentOrder);

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [
    createMessage,
    {
      data: createMessagesData,
      loading: createMessagesLoading,
      error: createMessagesError,
    },
  ] = useCreateMessageMutation({ errorPolicy: "all" });

  const [pastScrollHeight, setPastScrollHeight] = useState(0);
  const [pages, setPages] = useState<number>(1);

  const [countNew, setCountNew] = useState(0);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [msgs, setMsgs] = useState<MessageListingItem[] | []>([]);
  const [total, setTotal] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [getMessages, { data: messagess, loading: loadingMessagess }] =
    useGetMessagesLazyQuery({ fetchPolicy: "network-only" });

  const { loading: loadingMessages } = useGetMessagesQuery({
    variables: {
      query: {
        order_id: order_id,
        limit: 20,
        page: 1,
        desc: true,
        orderBy: "sendDate",
      },
    },
    skip: !currentOrderVar.chatIsOpen,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getMessages.total && data.getMessages.items) {
        data.getMessages.items.length > 0 && setMsgs(data.getMessages.items);
        setTotal(data?.getMessages?.total);
        notificationsCountSkip(false);
        orderMsgCountSkip(false);
      }
    },
  });

  useNewMessageSubscription({
    variables: {
      order_id: Number(order_id),
    },
    skip: !currentOrderVar.chatIsOpen,
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.newMessage.user_id !== me?.getUser.id) {
        setIsNew(true);
        setMsgs([
          {
            company_id: data.subscriptionData.data?.newMessage.company_id,
            id: Number(data.subscriptionData.data?.newMessage.id),
            order_id: data.subscriptionData.data?.newMessage.order_id,
            sendDate: data.subscriptionData.data?.newMessage.sendDate,
            status: data.subscriptionData.data?.newMessage
              .status as SqlModelStatus,
            text: data.subscriptionData.data?.newMessage.text,
            type: data.subscriptionData.data?.newMessage.type,
            user_firstName:
              data.subscriptionData.data?.newMessage.user?.firstName,
            user_id: data.subscriptionData.data?.newMessage.user_id,
            user_lastName:
              data.subscriptionData.data?.newMessage.user?.lastName,
            user_profileImageUrl:
              data.subscriptionData.data?.newMessage.user?.profileImageUrl,
          },
          ...msgs,
        ]);
      }
    },
  });

  useLayoutEffect(() => {
    if (!loadingMessagess && messagess?.getMessages.items && pages > 1) {
      setMsgs([...msgs, ...messagess.getMessages.items]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMessagess]);

  useLayoutEffect(() => {
    if (msgsContainer.current && msgs) {
      if (pages === 1 && !isNew) {
        msgsContainer.current.scrollTop = msgsContainer.current.scrollHeight;
      }
      if (pages >= 2 && !isNew) {
        const chat_msgs = document.getElementsByClassName("chat_msg");

        let height = 0;
        for (let i = 0; i < chat_msgs.length; i++) {
          const el = chat_msgs[i];
          height = height + Number(el.scrollHeight);
        }
        if (pages === 2) {
          msgsContainer.current.scrollTop = height - pastScrollHeight + 62;
        } else {
          msgsContainer.current.scrollTop = height - pastScrollHeight;
        }
      }
      if (isNew) {
        scrollToBottom();
        setCountNew(countNew + 1);
        setIsNew(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgs, msgsContainer]);

  const [msg, setMsg] = useState("");

  const onScroll = async () => {
    if (msgsContainer.current && msgs) {
      const { scrollTop } = msgsContainer.current;
      if (scrollTop === 0) {
        if (total - pages * 20 >= 0) {
          const chat_msgs = document.getElementsByClassName("chat_msg");
          let height = 0;
          for (let i = 0; i < chat_msgs.length; i++) {
            const el = chat_msgs[i];
            height = height + Number(el.scrollHeight);
          }

          setPastScrollHeight(height);
          if (pages !== 0) {
            getMessages({
              variables: {
                query: {
                  order_id: order_id,
                  limit: 20 + countNew,
                  page: pages + 1,
                  desc: true,
                  orderBy: "sendDate",
                },
              },
            });
            setPages(pages + 1);
          }
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (!loadingMessages && !isLoading) {
      if (msgsContainer.current) {
        msgsContainer.current.scrollTop = msgsContainer.current.scrollHeight;
      }
    }
  }, [isLoading, loadingMessages]);

  useEffect(() => {
    if (refInput.current) {
      refInput.current.style.height = "0px";
      const scrollHeight = refInput?.current?.scrollHeight - 16;
      refInput.current.style.height = scrollHeight + "px";
    }
  }, [msg]);

  const something = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && event.shiftKey) {
      setMsg(msg + "\n");
    } else {
      if (event.keyCode === 13) {
        validator({
          schema: schema,
          data: { msg: msg },
          success: () => {
            createMessage({
              variables: {
                message: {
                  order_id: Number(order_id),
                  transport_id: Number(transport_id),
                  text: msg,
                },
              },
            });
            setMsg("");
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!currentOrderVar.chatIsOpen) {
      setPastScrollHeight(0);
      setPages(1);
      setCountNew(0);
      setMsgs([]);
      setTotal(0);
      setIsNew(false);
    }
  }, [currentOrderVar.chatIsOpen]);

  ErrorsBeHandler({
    error: createMessagesError,
  });

  SuccessBeHandler({
    data: createMessagesData,
    code: "NEW_MSG",
    fn: () => {
      setMsgs([
        {
          company_id: createMessagesData?.createMessage?.company_id,
          id: Number(createMessagesData?.createMessage?.id),
          order_id: createMessagesData?.createMessage?.order_id,
          sendDate: createMessagesData?.createMessage?.sendDate,
          status: createMessagesData?.createMessage?.status as SqlModelStatus,
          text: createMessagesData?.createMessage?.text,
          type: createMessagesData?.createMessage?.type,
          user_firstName: createMessagesData?.createMessage?.user?.firstName,
          user_id: createMessagesData?.createMessage?.user_id,
          user_lastName: createMessagesData?.createMessage?.user?.lastName,
          user_profileImageUrl:
            createMessagesData?.createMessage?.user?.profileImageUrl,
        },
        ...msgs,
      ]);
    },
  });

  return (
    <Styled.Chat
      className={
        width > 1600
          ? currentOrderVar.chatIsOpen
            ? "open_chat"
            : ""
          : currentOrderVar.chatIsOpen
          ? "open_chat side_chat"
          : "side_chat"
      }
    >
      <Div
        pos="fixed"
        h={`${height}px`}
        w={width > 700 ? "calc(386px - 20px)" : "100%"}
        d="flex"
        flexDir="column"
        justify="space-between"
      >
        <Div
          pos="absolute"
          h="4rem"
          w="100%"
          p={{ t: "1rem" }}
          d="flex"
          align="top"
          justify="center"
          className="chat_close_wrapper"
        >
          <Button
            suffix={
              !loadingMessagess && (
                <Icon
                  name="CloseSolid"
                  size="16px"
                  m={{ l: "10px" }}
                  color="semiDark"
                />
              )
            }
            bg="background"
            border="1px solid"
            borderColor="greyBorder"
            hoverBg="grey"
            p={{ xs: "0rem" }}
            textSize={12}
            justify="center"
            textColor="semiDark"
            w="120px"
            m={{ l: "0.5rem" }}
            onClick={async () => {
              currentOrder({
                ...currentOrderVar,
                chatIsOpen: false,
              });
            }}
          >
            {loadingMessagess ? (
              <Icon
                name="Loading"
                m={{ x: "auto" }}
                color="semiDark"
                size="16px"
              />
            ) : (
              "Close chat"
            )}
          </Button>
        </Div>
        <Div
          ref={msgsContainer}
          p={{ y: "0px" }}
          className="msgsContainer"
          onScroll={() => onScroll()}
        >
          {loadingMessages || isLoading ? (
            <>
              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="108px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="162px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>
              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="108px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>
              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>
              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="108px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>
              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>

              <Div m={{ t: "2rem", x: "10px" }} d="flex">
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="32px"
                  width="32px"
                  borderRadius="32px"
                  margin="0 8px 0 0"
                />
                <Skeleton
                  color={["#FFF", "#fafafa"]}
                  height="54px"
                  width="100%"
                  margin="0 0 0 8px"
                />
              </Div>
            </>
          ) : (
            <ChatContainerMemo
              ownerCompany={ownerCompany}
              transporterCompany={transporterCompany}
              shipperCompany={shipperCompany}
              receiverCompany={receiverCompany}
              msgs={msgs}
            />
          )}
          <Div h="0" ref={messagesEndRef}></Div>
        </Div>
        <Div
          className="msgInputContainer"
          bottom="0"
          top="auto"
          d="flex"
          w="100%"
          h="auto"
          align="center"
          p={{ x: "0.5rem" }}
        >
          <Div
            pos="relative"
            p={{ r: "1.5rem" }}
            top="-1rem"
            rounded={4}
            transition
            bg="background"
            style={{
              border: `1px solid ${
                focused
                  ? GlobalTheme.colors.semiDark
                  : GlobalTheme.colors.greyBorder
              }`,
            }}
            onClick={() => {}}
          >
            <textarea
              ref={refInput}
              onFocus={onFocus}
              onBlur={onBlur}
              style={{
                fontSize: `${GlobalTheme.textSize.size[12]}px`,
                lineHeight: `${GlobalTheme.textSize.height[12]}px`,
                display: "block",
                overflow: "hidden",
                resize: "none",
                width: "calc(100% - 16px)",
                borderRadius: `${GlobalTheme.rounded[2]}`,
                border: "none",
                minHeight: "20px",
              }}
              placeholder="Enter your message..."
              value={msg}
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) =>
                something(e)
              }
              onChange={(e: React.BaseSyntheticEvent<InputEvent>) => {
                if (e?.nativeEvent!.inputType !== "insertLineBreak") {
                  setMsg(e.target.value);
                }
              }}
            />
            <Div className="sent_container">
              {createMessagesLoading ? (
                <Icon
                  name="Loading"
                  color="semiDark"
                  size="15px"
                  className="sent sent_loading"
                />
              ) : (
                <span
                  className="sent"
                  onClick={() => {
                    validator({
                      schema: schema,
                      data: { msg: msg },
                      success: () => {
                        createMessage({
                          variables: {
                            message: {
                              order_id: Number(order_id),
                              transport_id: Number(transport_id),
                              text: msg,
                            },
                          },
                        });
                        setMsg("");
                      },
                    });
                  }}
                >
                  {renderIcon("Send")}
                </span>
              )}
            </Div>
          </Div>
        </Div>
      </Div>
    </Styled.Chat>
  );
};

export default React.memo(Chat);

const ChatContainer: React.FC<{
  ownerCompany?: Company | null;
  transporterCompany?: Company | null;
  shipperCompany?: Company | null;
  receiverCompany?: Company | null;
  msgs?: MessageListingItem[];
}> = ({
  ownerCompany,
  transporterCompany,
  shipperCompany,
  receiverCompany,
  msgs,
}) => {
  const reverse_msgs = msgs?.slice().reverse();
  return (
    <>
      {reverse_msgs?.map((item: MessageListingItem, i: number) => {
        const n = item.sendDate.split("T")[0];
        let p = reverse_msgs[i - 1];
        if (p) {
          p = p.sendDate.split("T")[0];
        }
        return <ChatMsgMemo date={n !== p} msg={item} key={i} />;
      })}
    </>
  );
};

const ChatContainerMemo = React.memo(ChatContainer);

const ChatMsg: React.FC<{ date: boolean; msg: MessageListingItem }> = ({
  date,
  msg,
}) => {
  return (
    <>
      {date && (
        <Div p={{ y: "0.5rem", x: "0.5rem" }}>
          <Border margin="0" color={GlobalTheme.colors.greyBorder}></Border>
          <Div d="flex" justify="center" h="24px" textColor="light">
            {dateToFromNowDaily(msg.sendDate)}
          </Div>
        </Div>
      )}
      <Div m={{ y: "0", x: "10px" }} className="chat_msg" w="auto">
        <Div
          p={{ t: "0.5rem" }}
          d="flex"
          justify={"flex-start"}
          align="top"
          w="100%"
        >
          <PreloadingImage
            imageSrc={msg.user_profileImageUrl ? msg.user_profileImageUrl : ""}
            skeletonComponent={
              <Skeleton height={"2rem"} width={"2rem"} borderRadius={"2rem"} />
            }
            imgComponent={
              <ShortNameLogo
                size="2rem"
                name={`${msg.user_firstName} ${msg.user_lastName}`}
                textSize={12}
              />
            }
            imageStyle={{
              width: "2rem",
              height: "2rem",
              borderRadius: "2rem",
              verticalAlign: "middle",
            }}
          />
          <Text m={{ x: "0.5rem" }} textWeight="700">
            {`${msg.user_firstName} ${msg.user_lastName}`}{" "}
          </Text>
          <Text m={{ l: "auto", r: 0 }} textColor="light" textWeight="400">
            {moment(msg.sendDate).format("HH:mm")}{" "}
          </Text>
        </Div>
        <Div
          bg="grey"
          rounded={4}
          p={{ l: "40px", r: "0rem", b: "0.5rem" }}
          m={{ r: "auto" }}
          w="fit-content"
        >
          <Text
            textSize="14"
            m={{ t: "-10px" }}
            textAlign={"left"}
            textWeight="400"
            style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}
          >
            {msg.text}
          </Text>
        </Div>
      </Div>
    </>
  );
};

const ChatMsgMemo = React.memo(ChatMsg);
