import { useReactiveVar } from "@apollo/client";
import { lang } from "../../App";

const en = {
  get_in_touch: "Get in touch",
  log_in: "Log in",
  create_account: "Create account",
  btn: {
    create_company_account: "Create company account",
  },
  landing: {
    title: "Easy & transparent logistic operations",
    subtitle:
      "Cargobite is a platform to automate the procurement, execution and administration of logistics for all parties involved.",
    freight_quotes: {
      title: "Freight quotes",
      text: "Get market quotes for all your freight orders with just a few clicks. Reduce your transport cost and pick the quality of service that fits your needs.",
      tags: {
        order_creation: "Order creation",
        market_bids: "Market bids",
      },
    },
    real_time: {
      title: "Real-time order updates",
      text: "Track your orders and receive instant confirmations whenever your shipment is picked up or delivered to your partners. All confirmations are documented to provide full visibility between everyone involved in your supply chain.",
      tags: {
        dispatcher_management: "Dispatcher management",
        order_statuses_reports: "Order statuses & reports",
        live_tracking: "Live tracking",
        document_management: "Document management",
      },
    },
    live_communication: {
      title: "Live communication",
      text: "No more emails and phone calls. Use live chat to instantly sync up with your partners on operational processes. Assign dispatchers to your orders and have them manage communication with your partners directly from the platform.",
      tags: {
        live_chat: "Live chat",
        notifications: "Notifications",
        data_exports: "Data exports",
      },
    },
    hub: {
      title: "Hub for everything",
      text: "Manage all aspects of your operations across your supply chain. Cargobite uses smart integrations to sync all your operations in a single hub to simplify administration for your team.",
      tags: {
        Integrations_ERP_systems: "Integrations with ERP systems",
        bulk_order_imports: "Bulk order imports",
        data_exports: "Data exports",
      },
    },
    business_intelligence: {
      title: "Business intelligence",
      text: "Make impactful decisions based on data from your ongoing operations. Get structured shipping and partner insights that allow you to reduce cost of your supply chain and help you boost efficiency across your team.",
      tags: {
        operational_metrics: "Operational metrics",
        prediction_insights: "Prediction insights",
      },
    },
    Service_provider_rating: {
      title: "Service provider rating",
      text: "Enter the open market where every provider has a community sourced rating that you can rely on. Pick a provider that can execute your order based on your requirements.",
      tags: {
        Company_peer_reviews: "Company peer reviews",
      },
    },
  },
};

const si = {
  get_in_touch: "Get in touch",
  log_in: "Log in",
  create_account: "Create account",
  btn: {
    create_company_account: "Create company account",
  },
  landing: {
    title: "Enostavne in pregledne logistične operacije",
    subtitle:
      "Cargobite is a platform to automate the procurement, execution and administration of logistics for all parties involved.",
    freight_quotes: {
      title: "Freight quotes",
      text: "Get market quotes for all your freight orders with just a few clicks. Reduce your transport cost and pick the quality of service that fits your needs.",
      tags: {
        order_creation: "Order creation",
        market_bids: "Market bids",
      },
    },
    real_time: {
      title: "Real-time order updates",
      text: "Track your orders and receive instant confirmations whenever your shipment is picked up or delivered to your partners. All confirmations are documented to provide full visibility between everyone involved in your supply chain.",
      tags: {
        dispatcher_management: "Dispatcher management",
        order_statuses_reports: "Order statuses & reports",
        live_tracking: "Live tracking",
        document_management: "Document management",
      },
    },
    live_communication: {
      title: "Live communication",
      text: "No more emails and phone calls. Use live chat to instantly sync up with your partners on operational processes. Assign dispatchers to your orders and have them manage communication with your partners directly from the platform.",
      tags: {
        live_chat: "Live chat",
        notifications: "Notifications",
      },
    },
    hub: {
      title: "Hub for everything",
      text: "Manage all aspects of your operations across your supply chain. Cargobite uses smart integrations to sync all your operations in a single hub to simplify administration for your team.",
      tags: {
        Integrations_ERP_systems: "Integrations with ERP systems",
        bulk_order_imports: "Bulk order imports",
        data_exports: "Data exports",
      },
    },
    business_intelligence: {
      title: "Business intelligence",
      text: "Make impactful decisions based on data from your ongoing operations. Get structured shipping and partner insights that allow you to reduce cost of your supply chain and help you boost efficiency across your team.",
      tags: {
        operational_metrics: "Operational metrics",
        prediction_insights: "Prediction insights",
      },
    },
    Service_provider_rating: {
      title: "Service provider rating",
      text: "Enter the open market where every provider has a community sourced rating that you can rely on. Pick a provider that can execute your order based on your requirements.",
      tags: {
        Company_peer_reviews: "Company peer reviews",
      },
    },
  },
};

const Lang = () => {
  const langVar = useReactiveVar(lang);

  switch (langVar) {
    case "EN":
      return en;
    case "SI":
      return si;
  }
};

export default Lang;
