import React, { ReactElement } from "react";
import { Text, Div } from "atomize";
import Skeleton from "../../components/Skeleton/Skeleton";
import { NotificationType, OrderStatus } from "../../generated/graphql";
import PreloadingImage from "../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../components/ShortNameLogo/ShortNameLogo";
import { parseJSON, toReadValue } from "../../helpers/functions";

const NotiContent: React.FC<{
  noti: any;
  orderCode: string;
  myCompanyId: number;
  dateEl: ReactElement;
}> = ({ noti, orderCode, myCompanyId, dateEl }) => {
  if (
    noti.type === NotificationType.OrderStatus &&
    Object.values(OrderStatus).includes(JSON.parse(noti.data).status)
  ) {
    const status = JSON.parse(noti.data).status;

    switch (status) {
      case OrderStatus.OnMarket: {
        if (noti.actionCompany_id === noti.company_id) {
          return (
            <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
              <Text m={{ r: "4px" }}>You’ve created new order</Text>{" "}
              <Text d="inline" m={{ r: "4px" }} textWeight="700">
                {orderCode}.
              </Text>
              {dateEl}
            </Div>
          );
        } else {
          return (
            <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
              <Text m={{ r: "4px" }}>You’ve been added to the new order</Text>{" "}
              <Text d="inline" m={{ r: "4px" }} textWeight="700">
                {orderCode}.
              </Text>
              {dateEl}
            </Div>
          );
        }
      }
      case OrderStatus.Flagged: {
        return (
          <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
            <Text m={{ r: "4px" }}>Order</Text>{" "}
            <Text d="inline" m={{ r: "4px" }} textWeight="700">
              {orderCode}
            </Text>
            <Text m={{ r: "4px" }}>has been flagged by </Text>
            <PreloadingImage
              imageSrc={noti.actionUser_profileImageUrl}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  size="24px"
                  style={{ marginRight: "4px" }}
                  name={`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              d="inline"
              m={{ r: "4px" }}
              textWeight="700"
            >{`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}</Text>
            <Text m={{ x: "4px" }}>from</Text>
            <PreloadingImage
              imageSrc={noti.actionCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  size="24px"
                  style={{ marginRight: "4px" }}
                  name={`${noti.actionCompany_name}`}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text d="inline" m={{ r: "4px" }} textWeight="700">
              {noti.actionCompany_name}.
            </Text>
            {dateEl}
          </Div>
        );
      }
      case OrderStatus.Damaged: {
        return (
          <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
            <Text m={{ r: "4px" }}>Order</Text>{" "}
            <Text d="inline" m={{ r: "4px" }} textWeight="700">
              {orderCode}
            </Text>
            <Text m={{ r: "4px" }}>has been marked as damaged by </Text>
            <PreloadingImage
              imageSrc={noti.actionUser_profileImageUrl}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  size="24px"
                  style={{ marginRight: "4px" }}
                  name={`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              d="inline"
              m={{ r: "4px" }}
              textWeight="700"
            >{`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}</Text>
            <Text m={{ r: "4px" }}>from</Text>
            <PreloadingImage
              imageSrc={noti.dataCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  size="24px"
                  style={{ marginRight: "4px" }}
                  name={`${noti.actionCompany_name}`}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text d="inline" m={{ r: "4px" }} textWeight="700">
              {noti.actionCompany_name}.
            </Text>
            {dateEl}
          </Div>
        );
      }
      default:
        return (
          <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
            <Text m={{ r: "4px" }}>Your order</Text>{" "}
            <Text m={{ r: "4px" }} d="inline" textWeight="700">
              {orderCode}
            </Text>
            <Text m={{ r: "4px" }}> changed status to </Text>{" "}
            <Text textWeight={700} m={{ r: "4px" }}>
              {toReadValue(JSON.parse(noti.data).status)}.
            </Text>
            {dateEl}
          </Div>
        );
    }
  }

  switch (noti.type) {
    case NotificationType.AccountingWarning:
    case NotificationType.OperationalWarning:
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text textWeight="700">{noti?.title}</Text>
          <Text m={{ r: "4px" }}>{noti?.text}</Text>
          {dateEl}
        </Div>
      );

    case NotificationType.Message:
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>New message from </Text>
          <PreloadingImage
            imageSrc={noti.actionUser_profileImageUrl}
            skeletonComponent={
              <Skeleton height={"24px"} width={"24px"} borderRadius={"24px"} />
            }
            imgComponent={
              <ShortNameLogo
                style={{ marginRight: "4px" }}
                size="24px"
                name={`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}
                textSize={12}
              />
            }
            imageStyle={{
              width: "24px",
              height: "24px",
              borderRadius: "24px",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          />
          <Text textWeight={"700"} m={{ r: "4px" }} textTransform="capitalize">
            {`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}
          </Text>
          <Text m={{ r: "4px" }}>on order </Text>
          <Text textWeight={"700"} m={{ r: "4px" }}>
            {orderCode}.
          </Text>
          {dateEl}
        </Div>
      );

    case NotificationType.BidAccepted: {
      const price = JSON.parse(noti.data).price;

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          {noti.dataCompany_id === myCompanyId ? (
            <Text m={{ r: "4px" }}> Your bid </Text>
          ) : (
            <>
              <Text m={{ r: "4px" }}> Bid by </Text>
              <Div d="flex">
                <PreloadingImage
                  imageSrc={noti.dataCompany_logo}
                  skeletonComponent={
                    <Skeleton
                      height={"24px"}
                      width={"24px"}
                      borderRadius={"24px"}
                    />
                  }
                  imgComponent={
                    <ShortNameLogo
                      style={{ marginRight: "4px" }}
                      size="24px"
                      name={noti.dataCompany_name ? noti.dataCompany_name : ""}
                      textSize={12}
                    />
                  }
                  imageStyle={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "24px",
                    verticalAlign: "middle",
                    marginRight: "4px",
                  }}
                />
                <Text
                  textColor="dark"
                  hoverTextColor="dark"
                  textWeight="700"
                  m={{ r: "4px" }}
                >
                  {noti.dataCompany_name}.
                </Text>
              </Div>
            </>
          )}

          <Text m={{ r: "4px" }}> on order</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}> was accepted for </Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>{`€${price}.`}</Text>
          {noti.dataCompany_id === myCompanyId ? (
            <Text m={{ r: "4px" }}> Please prepare the transport.</Text>
          ) : (
            ""
          )}
          {dateEl}
        </Div>
      );
    }

    case NotificationType.Document: {
      const document = JSON.parse(noti.data).type;

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          {document === "INVOICE" ? (
            <Text m={{ r: "4px" }}>Invoice </Text>
          ) : (
            <Text m={{ r: "4px" }}>Proof of delivery</Text>
          )}
          <Text m={{ r: "4px" }}> has been added to order</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>by</Text>
          <Div d="flex">
            <PreloadingImage
              imageSrc={noti.dataCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  size="24px"
                  style={{ marginRight: "4px" }}
                  name={noti.dataCompany_name ? noti.dataCompany_name : ""}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              textColor="dark"
              hoverTextColor="dark"
              textWeight="700"
              m={{ r: "4px" }}
            >
              {noti.dataCompany_name}.
            </Text>
          </Div>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.BidReceived: {
      const price = JSON.parse(noti.data).price;

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>New bid on</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>for</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>{`€${price}`}</Text>
          <Text m={{ r: "4px" }}>from</Text>
          <Div d="flex" m={{ r: "4px" }}>
            <PreloadingImage
              imageSrc={noti.actionCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  style={{ marginRight: "4px" }}
                  size="24px"
                  name={noti.actionCompany_name ? noti.actionCompany_name : ""}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text textColor="dark" hoverTextColor="dark" textWeight="700">
              {noti.actionCompany_name}.
            </Text>
          </Div>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.BidRejected: {
      const price = JSON.parse(noti.data).price;

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>Bid on</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>for</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>{`€${price}`}</Text>
          <Text m={{ r: "4px" }}>from</Text>
          <Div d="flex">
            <PreloadingImage
              imageSrc={noti.actionCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  style={{ marginRight: "4px" }}
                  size="24px"
                  name={noti.actionCompany_name ? noti.actionCompany_name : ""}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              textColor="dark"
              hoverTextColor="dark"
              textWeight="700"
              m={{ r: "4px" }}
            >
              {noti.actionCompany_name}
            </Text>
          </Div>
          <Text>was rejected.</Text>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.BidCancelled: {
      const price = JSON.parse(noti.data).price;

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>Bid on order </Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>was cancelled by</Text>
          <Div d="flex">
            <PreloadingImage
              imageSrc={noti.actionCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  style={{ marginRight: "4px" }}
                  size="24px"
                  name={noti.actionCompany_name ? noti.actionCompany_name : ""}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              textColor="dark"
              hoverTextColor="dark"
              textWeight="700"
              m={{ r: "4px" }}
            >
              {noti.actionCompany_name}
            </Text>
          </Div>
          <Text m={{ r: "4px" }}>worth</Text>
          <Text m={{ r: "4px" }} textWeight={"700"}>{`€${price}.`}</Text>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.Assigned: {
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>Order</Text>
          <Text textWeight={"700"} m={{ r: "4px" }}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>has been assigned to</Text>
          <Div d="flex">
            <PreloadingImage
              imageSrc={noti.dataUser_profileImageUrl}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  style={{ marginRight: "4px" }}
                  size="24px"
                  name={`${noti.dataUser_firstName} ${noti.dataUser_lastName}`}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text textWeight={"700"} m={{ r: "4px" }}>
              {`${noti.dataUser_firstName} ${noti.dataUser_lastName}`}.
            </Text>
          </Div>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.DeliveryChangeRequest: {
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Div d="flex">
            <PreloadingImage
              imageSrc={noti.dataCompany_logo}
              skeletonComponent={
                <Skeleton
                  height={"24px"}
                  width={"24px"}
                  borderRadius={"24px"}
                />
              }
              imgComponent={
                <ShortNameLogo
                  style={{ marginRight: "4px" }}
                  size="24px"
                  name={noti.dataCompany_name ? noti.dataCompany_name : ""}
                  textSize={12}
                />
              }
              imageStyle={{
                width: "24px",
                height: "24px",
                borderRadius: "24px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
            />
            <Text
              textColor="dark"
              hoverTextColor="dark"
              textWeight="700"
              m={{ r: "4px" }}
            >
              {noti.dataCompany_name}
            </Text>
          </Div>
          <Text>has requested achange for order</Text>
          <Text textWeight={"700"} m={{ x: "4px" }}>
            {orderCode}
          </Text>
          <Text m={{ r: "4px" }}>
            You need to review and confirm this date.
          </Text>
        </Div>
      );
    }

    case NotificationType.Custom: {
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>A reminder has been sent by </Text>
          <PreloadingImage
            imageSrc={noti.actionUser_profileImageUrl}
            skeletonComponent={
              <Skeleton height={"24px"} width={"24px"} borderRadius={"24px"} />
            }
            imgComponent={
              <ShortNameLogo
                size="24px"
                style={{ marginRight: "4px" }}
                name={`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}
                textSize={12}
              />
            }
            imageStyle={{
              width: "24px",
              height: "24px",
              borderRadius: "24px",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          />
          <Text
            d="inline"
            m={{ r: "4px" }}
            textWeight="700"
          >{`${noti.actionUser_firstName} ${noti.actionUser_lastName}`}</Text>
          <Text m={{ r: "4px" }}>from</Text>
          <PreloadingImage
            imageSrc={noti.dataCompany_logo}
            skeletonComponent={
              <Skeleton height={"24px"} width={"24px"} borderRadius={"24px"} />
            }
            imgComponent={
              <ShortNameLogo
                size="24px"
                style={{ marginRight: "4px" }}
                name={`${noti.actionCompany_name}`}
                textSize={12}
              />
            }
            imageStyle={{
              width: "24px",
              height: "24px",
              borderRadius: "24px",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          />
          <Text d="inline" m={{ r: "4px" }} textWeight="700">
            {noti.actionCompany_name}.
          </Text>
          {dateEl}
        </Div>
      );
    }

    case NotificationType.Invoice: {
      const data = parseJSON(noti?.data);

      const { amount, currencySymbol, transporterCompanyName, order_code } =
        data || {};

      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <Text m={{ r: "4px" }}>
            A{" "}
            <b>
              {currencySymbol}
              {amount}
            </b>{" "}
            payment on the invoice <b>{order_code}</b> order from{" "}
            <b>{transporterCompanyName}</b> was past due on 12 Mar 2024.
          </Text>
          {dateEl}
        </Div>
      );
    }

    default:
      return (
        <Div d="flex" flexWrap="wrap" p={{ y: "1rem" }}>
          <PreloadingImage
            imageSrc={noti.dataUser_profileImageUrl}
            skeletonComponent={
              <Skeleton height={"24px"} width={"24px"} borderRadius={"24px"} />
            }
            imgComponent={
              <ShortNameLogo
                style={{ marginRight: "4px" }}
                size="24px"
                name={`${noti.dataUser_firstName} ${noti.dataUser_lastName}`}
                textSize={12}
              />
            }
            imageStyle={{
              width: "24px",
              height: "24px",
              borderRadius: "24px",
              verticalAlign: "middle",
              marginRight: "4px",
            }}
          />
          <Text textWeight={"700"} m={{ r: "4px" }}>
            {`${noti.dataUser_firstName} ${noti.dataUser_lastName}`}
          </Text>
          <Text m={{ r: "4px" }}>has been removed from order</Text>
          <Text textWeight={"700"} m={{ r: "4px" }}>
            {orderCode}.
          </Text>
          {dateEl}
        </Div>
      );
  }
};

export default NotiContent;
