import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  type?: Maybe<ActivityLogType>;
  /** id */
  id: Scalars['Float'];
  /** status */
  status?: Maybe<Scalars['Float']>;
  /** ts */
  ts: Scalars['DateTime'];
  /** data */
  data: Scalars['String'];
  /** text */
  text?: Maybe<Scalars['String']>;
  /** order_id */
  order_id: Scalars['Float'];
  /** transport_id */
  transport_id?: Maybe<Scalars['Float']>;
  /** transportState_id */
  transportState_id?: Maybe<Scalars['Float']>;
};

export type ActivityLogListing = {
  __typename?: 'ActivityLogListing';
  items?: Maybe<Array<ActivityLog>>;
  /** Activity log's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type ActivityLogQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** TransportState ID */
  transportState_id?: Maybe<Scalars['Float']>;
};

export enum ActivityLogType {
  Public = 'PUBLIC',
  Owner = 'OWNER'
}

export type AddLocationPoints = {
  transport_id: Scalars['Float'];
  order_id: Scalars['Float'];
  locationPoints: Array<LocationPointItem>;
};

export type AddTaxationClause = {
  clause: Scalars['String'];
  vat: Scalars['Float'];
  isDefault: Scalars['Boolean'];
};

export type AssignedUser = {
  __typename?: 'AssignedUser';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: AssignedUserType;
  user?: Maybe<User>;
  /** user_id */
  user_id: Scalars['Float'];
  /** company_id */
  company_id: Scalars['Float'];
  /** order_id */
  order_id: Scalars['Float'];
};

export enum AssignedUserType {
  Owner = 'OWNER',
  Transporter = 'TRANSPORTER',
  Driver = 'DRIVER',
  Receiver = 'RECEIVER',
  Shipper = 'SHIPPER'
}

export type Bid = {
  __typename?: 'Bid';
  status?: Maybe<BidStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  /** Bid's order ID */
  order_id: Scalars['Float'];
  /** Bid's company ID */
  company_id: Scalars['Float'];
  /** Bid's price */
  price?: Maybe<Scalars['Float']>;
  /** Bid's currency id */
  currency_id?: Maybe<Scalars['Float']>;
  /** Bid's expiration date. */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Bid's accepted date. */
  acceptedDate?: Maybe<Scalars['DateTime']>;
  /** Bid's comment */
  comment?: Maybe<Scalars['String']>;
};

export type BidCreate = {
  /** Bid's order ID */
  order_id: Scalars['Float'];
  /** Bid's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Bid's price */
  price: Scalars['Float'];
  /** Bid's currency id */
  currency_id?: Maybe<Scalars['Float']>;
  /** Bid's expirational date. */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Bid's comment */
  comment?: Maybe<Scalars['String']>;
};

export type BidListing = {
  __typename?: 'BidListing';
  items?: Maybe<Array<BidListingItem>>;
  /** Bid's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type BidListingItem = {
  __typename?: 'BidListingItem';
  /** Bid's ID */
  id: Scalars['Float'];
  /** Bid's status */
  status: BidStatus;
  /** Bid's order ID */
  order_id: Scalars['Float'];
  /** Bid's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Bid's company name */
  company_name?: Maybe<Scalars['String']>;
  /** Bid's company logo path */
  company_logo?: Maybe<Scalars['String']>;
  /** Bid's price */
  price: Scalars['Float'];
  /** Bid's currency id */
  currency_id?: Maybe<Scalars['Float']>;
  /** Bid's expirational date. */
  expirationDate?: Maybe<Scalars['DateTime']>;
  /** Bid's comment */
  comment?: Maybe<Scalars['String']>;
  /** Bid's order code */
  order_code?: Maybe<Scalars['String']>;
  /** Bid's order unloading location post name */
  unloadingLocation_postName?: Maybe<Scalars['String']>;
  /** Bid's order loading location post name */
  loadingLocation_postName?: Maybe<Scalars['String']>;
  /** Bid's order requested loading time */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Bid's order requested unloading time */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Bid's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type BidQuery = {
  status?: Maybe<Array<BidStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Float']>;
};

export enum BidStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Declined = 'DECLINED'
}

export type BidUpdate = {
  /** Bid's ID */
  id: Scalars['Float'];
  /** Bid's comment */
  comment?: Maybe<Scalars['String']>;
};

export enum BillingInterval {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type Company = {
  __typename?: 'Company';
  status: CompanyStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: CompanyType;
  insuranceCertificate?: Maybe<Document>;
  users?: Maybe<Array<User>>;
  location?: Maybe<Location>;
  defaultContact?: Maybe<CompanyContact>;
  assignedUsers?: Maybe<Array<Dispatcher>>;
  /** Company's location id */
  location_id?: Maybe<Scalars['Float']>;
  /** Company's country id */
  country_id?: Maybe<Scalars['Float']>;
  /** Company's contact user id */
  contactUser_id?: Maybe<Scalars['Float']>;
  /** Company's owner user id */
  accountOwnerUser_id?: Maybe<Scalars['Float']>;
  /** Company's name. */
  name: Scalars['String'];
  /** Company's tax number. */
  taxNumber?: Maybe<Scalars['String']>;
  /** Company's contact email. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Company's contact phone number. */
  contactPhone?: Maybe<Scalars['String']>;
  /** Company's IBAN number */
  IBAN?: Maybe<Scalars['String']>;
  /** Company's BIC number */
  BIC?: Maybe<Scalars['String']>;
  /** Company's logo path */
  logoPath?: Maybe<Scalars['String']>;
};

export type CompanyContact = {
  __typename?: 'CompanyContact';
  /** User id. */
  id?: Maybe<Scalars['Float']>;
  /** First name. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Profile image url. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** Email. */
  email?: Maybe<Scalars['String']>;
  /** Phone. */
  phone?: Maybe<Scalars['String']>;
};

export type CompanyCreate = {
  type?: Maybe<CompanyType>;
  location?: Maybe<LocationCreate>;
  /** Company's country ID. */
  country_id: Scalars['Float'];
  /** Company's name. */
  name: Scalars['String'];
  /** Company's tax number. */
  taxNumber: Scalars['String'];
  /** Company's contact email. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Company's contact phone number. */
  contactPhone?: Maybe<Scalars['String']>;
  /** Company's IBAN number */
  IBAN?: Maybe<Scalars['String']>;
  /** Company's BIC number */
  BIC?: Maybe<Scalars['String']>;
  /** Company's logo path */
  logoPath?: Maybe<Scalars['String']>;
};

export type CompanyInvite = {
  /** Company's ID. */
  company_id: Scalars['Float'];
  /** Invitee's email. */
  email: Scalars['String'];
  /** Invitee's company role ID. */
  role_id: Scalars['Float'];
};

export type CompanyListing = {
  __typename?: 'CompanyListing';
  items?: Maybe<Array<Company>>;
  /** Company's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type CompanyPartner = {
  __typename?: 'CompanyPartner';
  status: CompanyStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: CompanyType;
  insuranceCertificate?: Maybe<Document>;
  users?: Maybe<Array<User>>;
  location?: Maybe<Location>;
  defaultContact?: Maybe<CompanyContact>;
  assignedUsers?: Maybe<Array<Dispatcher>>;
  /** Company's location id */
  location_id?: Maybe<Scalars['Float']>;
  /** Company's country id */
  country_id?: Maybe<Scalars['Float']>;
  /** Company's contact user id */
  contactUser_id?: Maybe<Scalars['Float']>;
  /** Company's owner user id */
  accountOwnerUser_id?: Maybe<Scalars['Float']>;
  /** Company's name. */
  name: Scalars['String'];
  /** Company's tax number. */
  taxNumber?: Maybe<Scalars['String']>;
  /** Company's contact email. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Company's contact phone number. */
  contactPhone?: Maybe<Scalars['String']>;
  /** Company's IBAN number */
  IBAN?: Maybe<Scalars['String']>;
  /** Company's BIC number */
  BIC?: Maybe<Scalars['String']>;
  /** Company's logo path */
  logoPath?: Maybe<Scalars['String']>;
  /** Number of orders with partner. */
  orderCount: Scalars['Float'];
};

export type CompanyPartnerListing = {
  __typename?: 'CompanyPartnerListing';
  items?: Maybe<Array<CompanyPartner>>;
  /** CompanyPartner's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type CompanyPlaceholder = {
  type?: Maybe<CompanyType>;
  location?: Maybe<LocationCreate>;
  /** Company's country ID. */
  country_id: Scalars['Float'];
  /** Company's name. */
  name: Scalars['String'];
  /** Company's tax number. */
  taxNumber: Scalars['String'];
  /** Company's contact email. */
  contactEmail: Scalars['String'];
};

export type CompanyQuery = {
  status?: Maybe<Array<CompanyStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Multiple company's IDs */
  ids?: Maybe<Array<Scalars['Float']>>;
};

export enum CompanyStatus {
  Incomplete = 'INCOMPLETE',
  WaitingConfirm = 'WAITING_CONFIRM',
  PlaceholderWaitingConfirm = 'PLACEHOLDER_WAITING_CONFIRM',
  Placeholder = 'PLACEHOLDER',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export enum CompanyType {
  Shipper = 'SHIPPER',
  Receiver = 'RECEIVER',
  Transporter = 'TRANSPORTER'
}

export type CompanyUpdate = {
  type?: Maybe<CompanyType>;
  location?: Maybe<LocationUpdate>;
  /** Company's country ID. */
  country_id?: Maybe<Scalars['Float']>;
  /** Company's name. */
  name?: Maybe<Scalars['String']>;
  /** Company's tax number. */
  taxNumber?: Maybe<Scalars['String']>;
  /** Company's contact email. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Company's contact phone number. */
  contactPhone?: Maybe<Scalars['String']>;
  /** Company's IBAN number */
  IBAN?: Maybe<Scalars['String']>;
  /** Company's BIC number */
  BIC?: Maybe<Scalars['String']>;
  /** Company's logo path */
  logoPath?: Maybe<Scalars['String']>;
  /** Company's ID */
  id: Scalars['Float'];
  /** Company's contact user id */
  contactUser_id?: Maybe<Scalars['Float']>;
  /** Company's owner user id */
  accountOwnerUser_id?: Maybe<Scalars['Float']>;
};

export type Country = {
  __typename?: 'Country';
  /** id */
  id: Scalars['Float'];
  /** name */
  name: Scalars['String'];
  /** international phone code */
  phoneCode?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
};

export type CountryListing = {
  __typename?: 'CountryListing';
  items?: Maybe<Array<Country>>;
  /** Country's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type CountryQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Lisiting page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
};

export type CreateNotificationMessage = {
  status?: Maybe<Array<NotificationMessageStatus>>;
  /** Notification's title. */
  title?: Maybe<Scalars['String']>;
  /** Notification's message body. */
  text?: Maybe<Scalars['String']>;
  /** Notification's label. */
  label?: Maybe<Scalars['String']>;
  /** A date when message should be delivered. */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Notification's recipients (ids). Users should be from company with company_id. */
  recipients?: Maybe<Array<Scalars['Float']>>;
  /** Notification's sender. */
  user_id?: Maybe<Scalars['Float']>;
};

export type Currency = {
  __typename?: 'Currency';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** name */
  name: Scalars['String'];
  /** code */
  code?: Maybe<Scalars['String']>;
  /** symbol */
  symbol?: Maybe<Scalars['String']>;
  /** exchange rate */
  exchangeRate?: Maybe<Scalars['Float']>;
};

export type CurrencyListing = {
  __typename?: 'CurrencyListing';
  items?: Maybe<Array<Currency>>;
  /** Currency's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type CurrencyQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
};


export type Dispatcher = {
  __typename?: 'Dispatcher';
  /** User id. */
  id?: Maybe<Scalars['Float']>;
  /** First name. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Profile image url. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** Email. */
  email?: Maybe<Scalars['String']>;
  /** Phone. */
  phone?: Maybe<Scalars['String']>;
  type: AssignedUserType;
  /** Dispatchers user id. */
  user_id: Scalars['Float'];
};

export type Document = {
  __typename?: 'Document';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type?: Maybe<DocumentType>;
  company?: Maybe<Company>;
  /** Document's company ID */
  company_id: Scalars['Float'];
  /** Document's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Document's order code */
  order_code?: Maybe<Scalars['String']>;
  /** Document's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Document's invoice ID */
  invoice_id?: Maybe<Scalars['Float']>;
  /** Document's code */
  code?: Maybe<Scalars['String']>;
  /** Document's date */
  date?: Maybe<Scalars['DateTime']>;
  /** Document's file path */
  path?: Maybe<Scalars['String']>;
  /** Document's description */
  description?: Maybe<Scalars['String']>;
  /** Document's private notes */
  privateNotes?: Maybe<Scalars['String']>;
  /** Document's file name */
  fileName?: Maybe<Scalars['String']>;
  /** Document's file size */
  fileSize?: Maybe<Scalars['Float']>;
  /** Document's file type */
  fileType?: Maybe<Scalars['String']>;
  /** Virtual field for image url. */
  fileUrl?: Maybe<Scalars['String']>;
  /** Document's expiry date */
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type DocumentCreate = {
  type?: Maybe<DocumentType>;
  /** Document's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Document's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Document's order code */
  order_code?: Maybe<Scalars['String']>;
  /** Document's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Document's invoice ID */
  invoice_id?: Maybe<Scalars['Float']>;
  /** Document's code */
  code?: Maybe<Scalars['String']>;
  /** Document's date */
  date?: Maybe<Scalars['DateTime']>;
  /** Document's description */
  description?: Maybe<Scalars['String']>;
  /** Document's private notes */
  privateNotes?: Maybe<Scalars['String']>;
  /** Document's file name */
  fileName?: Maybe<Scalars['String']>;
  /** Document's file size */
  fileSize?: Maybe<Scalars['Float']>;
  /** Document's file type */
  fileType?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  paymentDueDate?: Maybe<Scalars['String']>;
  payeeCompany_id?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SqlModelStatus>;
};

export type DocumentListing = {
  __typename?: 'DocumentListing';
  items?: Maybe<Array<Document>>;
  /** Document's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type DocumentQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Document's from company */
  company_id?: Maybe<Scalars['Float']>;
  /** Document's from order */
  order_id?: Maybe<Scalars['Float']>;
  /** Document's company ID */
  fileType?: Maybe<Scalars['String']>;
};

export enum DocumentType {
  Invoice = 'INVOICE',
  Pod = 'POD',
  Image = 'IMAGE',
  Insurance = 'INSURANCE',
  Ioc = 'IOC',
  Iod = 'IOD',
  Cmr = 'CMR',
  Ddt = 'DDT',
  Other = 'OTHER'
}

export type DownloadOrderDocumentResponse = {
  __typename?: 'DownloadOrderDocumentResponse';
  fileUrl: Scalars['String'];
};

export type Driver = {
  __typename?: 'Driver';
  status: UserStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Role>>;
  modules?: Maybe<Array<Module>>;
  company?: Maybe<Company>;
  isExternalDriver?: Maybe<Scalars['Boolean']>;
  /** User's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** User's first name property definition. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name property definition. */
  lastName?: Maybe<Scalars['String']>;
  /** User's email property definition. */
  email?: Maybe<Scalars['String']>;
  /** phone */
  phone?: Maybe<Scalars['String']>;
  /** User's profile image url property definition. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** User's password hash property definition. */
  password?: Maybe<Scalars['String']>;
  vehicleRegistration?: Maybe<Array<VehicleRegistration>>;
  /** user token */
  token?: Maybe<Scalars['String']>;
};

export type DriverListing = {
  __typename?: 'DriverListing';
  items?: Maybe<Array<Driver>>;
  /** Driver's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type ExportDocumentsResponse = {
  __typename?: 'ExportDocumentsResponse';
  csvPath?: Maybe<Scalars['String']>;
  zipPath?: Maybe<Scalars['String']>;
};

export type ExportInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  selectedColumns?: Maybe<Array<Scalars['String']>>;
  orders: Scalars['Boolean'];
  documents: Scalars['Boolean'];
};

export type ExportOrdersResponse = {
  __typename?: 'ExportOrdersResponse';
  csvPath?: Maybe<Scalars['String']>;
};

export type ExportResponse = {
  __typename?: 'ExportResponse';
  orders?: Maybe<ExportOrdersResponse>;
  documents?: Maybe<ExportDocumentsResponse>;
};

export type Feedback = {
  __typename?: 'Feedback';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: FeedbackType;
  /** user_id */
  user_id: Scalars['Float'];
  /** object_id */
  object_id?: Maybe<Scalars['Float']>;
  /** message */
  message?: Maybe<Scalars['String']>;
  /** params */
  params?: Maybe<Scalars['String']>;
};

export type FeedbackCreate = {
  type: FeedbackType;
  /** Feedback's object ID */
  object_id?: Maybe<Scalars['Float']>;
  /** Feedback's message */
  message?: Maybe<Scalars['String']>;
  /** Feedback's params */
  params?: Maybe<Scalars['String']>;
};

export type FeedbackListing = {
  __typename?: 'FeedbackListing';
  items?: Maybe<Array<Feedback>>;
  /** Feedback's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type FeedbackQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Array<FeedbackType>>;
  /** Feedback's user ID */
  user_id?: Maybe<Scalars['Float']>;
};

export enum FeedbackType {
  ReportOrder = 'REPORT_ORDER',
  ReportBid = 'REPORT_BID',
  ReportUser = 'REPORT_USER',
  ReportError = 'REPORT_ERROR',
  Feedback = 'FEEDBACK'
}

export type Invoice = {
  __typename?: 'Invoice';
  status?: Maybe<InvoiceStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<InvoiceItem>>;
  documents?: Maybe<Array<Document>>;
  /** Invoice's company ID */
  company_id: Scalars['Float'];
  /** Invoice's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Invoice's subscription ID */
  subscription_id?: Maybe<Scalars['Float']>;
  /** Invoice's user ID */
  user_id: Scalars['Float'];
  /** Invoice's customer company ID */
  customerCompany_id: Scalars['Float'];
  /** Invoice's code */
  code?: Maybe<Scalars['String']>;
  /** Invoice's price */
  amount?: Maybe<Scalars['Float']>;
  /** Invoice's tax amount */
  taxAmount?: Maybe<Scalars['Float']>;
  /** Invoice's total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  /** Invoice's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
};

export type InvoiceCreate = {
  newItems?: Maybe<Array<InvoiceItemWi>>;
  /** Invoice's company ID */
  company_id: Scalars['Float'];
  /** Invoice's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Invoice's subscription ID */
  subscription_id?: Maybe<Scalars['Float']>;
  /** Invoice's user ID */
  user_id: Scalars['Float'];
  /** Invoice's customer company ID */
  customerCompany_id: Scalars['Float'];
  /** Invoice's code */
  code?: Maybe<Scalars['String']>;
  /** Invoice's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
};

export type InvoiceData = {
  __typename?: 'InvoiceData';
  status?: Maybe<InvoiceDataStatus>;
  currency?: Maybe<Currency>;
  id: Scalars['Float'];
  amount?: Maybe<Scalars['Float']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  payeeCompany_id?: Maybe<Scalars['Float']>;
  document_id?: Maybe<Scalars['Float']>;
  issuingCompany_id?: Maybe<Scalars['Float']>;
  creator_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  createTime: Scalars['DateTime'];
};

export type InvoiceDataListing = {
  __typename?: 'InvoiceDataListing';
  items?: Maybe<Array<InvoiceData>>;
};

export enum InvoiceDataStatus {
  Created = 'CREATED',
  Paid = 'PAID',
  Discarded = 'DISCARDED',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED'
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  /** status */
  status?: Maybe<Scalars['Float']>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  /** Invoice item's invoice ID */
  invoice_id: Scalars['Float'];
  /** Invoice item's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Invoice item's description */
  description?: Maybe<Scalars['String']>;
  /** Invoice item's price */
  price?: Maybe<Scalars['Float']>;
  /** Invoice item's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
  /** Invoice item's tax amount */
  taxAmount?: Maybe<Scalars['Float']>;
  /** Invoice item's total amount */
  totalAmount?: Maybe<Scalars['Float']>;
};

export type InvoiceItemCreate = {
  /** Invoice item's invoice ID */
  invoice_id: Scalars['Float'];
  /** Invoice item's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Invoice item's description */
  description?: Maybe<Scalars['String']>;
  /** Invoice item's price */
  price: Scalars['Float'];
  /** Invoice item's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
};

export type InvoiceItemUpdate = {
  /** Invoice item's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Invoice item's description */
  description?: Maybe<Scalars['String']>;
  /** Invoice item's price */
  price?: Maybe<Scalars['Float']>;
  /** Invoice item's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
  /** InvoiceItem's ID */
  id: Scalars['Float'];
};

export type InvoiceItemWi = {
  /** Invoice item's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Invoice item's description */
  description?: Maybe<Scalars['String']>;
  /** Invoice item's price */
  price: Scalars['Float'];
  /** Invoice item's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
};

export type InvoiceListing = {
  __typename?: 'InvoiceListing';
  items?: Maybe<Array<Invoice>>;
  /** Invoice's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type InvoicePrefilledData = {
  __typename?: 'InvoicePrefilledData';
  amount?: Maybe<Scalars['Float']>;
  invoiceNumber: Scalars['String'];
  paymentDueDate: Scalars['DateTime'];
};

export type InvoiceQuery = {
  status?: Maybe<Array<InvoiceStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  company_id: Scalars['Float'];
  customPrefix: Scalars['String'];
  addPayeePrefix: Scalars['Boolean'];
  addChronologicalSequence: Scalars['Boolean'];
  defaultDueDate: Scalars['Float'];
  createTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Sent = 'SENT',
  Deactivated = 'DEACTIVATED'
}

export type InvoiceUpdate = {
  newItems?: Maybe<Array<InvoiceItemWi>>;
  /** Invoice's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Invoice's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Invoice's subscription ID */
  subscription_id?: Maybe<Scalars['Float']>;
  /** Invoice's user ID */
  user_id?: Maybe<Scalars['Float']>;
  /** Invoice's customer company ID */
  customerCompany_id?: Maybe<Scalars['Float']>;
  /** Invoice's code */
  code?: Maybe<Scalars['String']>;
  /** Invoice's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  removeItems?: Maybe<Array<Scalars['Int']>>;
  /** Invoice's ID */
  id: Scalars['Float'];
};

export type Location = {
  __typename?: 'Location';
  status?: Maybe<LocationStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type?: Maybe<LocationType>;
  country?: Maybe<Country>;
  /** Location's company_id */
  company_id?: Maybe<Scalars['Float']>;
  /** Location's title */
  title?: Maybe<Scalars['String']>;
  /** Location's description */
  description?: Maybe<Scalars['String']>;
  /** Location's address */
  address?: Maybe<Scalars['String']>;
  /** Location's postCode */
  postCode?: Maybe<Scalars['String']>;
  /** Location's postName */
  postName?: Maybe<Scalars['String']>;
  /** Location's country_id */
  country_id?: Maybe<Scalars['Float']>;
  /** Location's contactName */
  contactName?: Maybe<Scalars['String']>;
  /** Location's phone */
  phone?: Maybe<Scalars['String']>;
  /** Location's email */
  email?: Maybe<Scalars['String']>;
  /** Location's geoLocation */
  geoLocation?: Maybe<Scalars['String']>;
  /** Location's notes */
  notes?: Maybe<Scalars['String']>;
  /** Location's timezone */
  timezone?: Maybe<Scalars['String']>;
};

export type LocationCreate = {
  /** Location's company_id */
  company_id?: Maybe<Scalars['Float']>;
  /** Location's type */
  type?: Maybe<LocationType>;
  /** Location's title */
  title?: Maybe<Scalars['String']>;
  /** Location's description */
  description?: Maybe<Scalars['String']>;
  /** Location's address */
  address?: Maybe<Scalars['String']>;
  /** Location's postCode */
  postCode?: Maybe<Scalars['String']>;
  /** Location's postName */
  postName?: Maybe<Scalars['String']>;
  /** Location's country_id */
  country_id?: Maybe<Scalars['Float']>;
  /** Location's contactName */
  contactName?: Maybe<Scalars['String']>;
  /** Location's phone */
  phone?: Maybe<Scalars['String']>;
  /** Location's email */
  email?: Maybe<Scalars['String']>;
  /** Location's geoLocation */
  geoLocation?: Maybe<Scalars['String']>;
  /** Location's notes */
  notes?: Maybe<Scalars['String']>;
  /** Location's timezone */
  timezone?: Maybe<Scalars['String']>;
};

export type LocationListing = {
  __typename?: 'LocationListing';
  items?: Maybe<Array<Location>>;
  /** Location's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type LocationPoint = {
  __typename?: 'LocationPoint';
  locationInfo?: Maybe<Location>;
  id: Scalars['Float'];
  transport_id: Scalars['Float'];
  location_id: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  loadingDate: Scalars['DateTime'];
  order: Scalars['Float'];
  createTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
};

export type LocationPointItem = {
  location_id: Scalars['Float'];
  order: Scalars['Float'];
  loadingDate: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
};

export type LocationPointListingItem = {
  __typename?: 'LocationPointListingItem';
  id: Scalars['Float'];
  transport_id: Scalars['Float'];
  location_id: Scalars['Float'];
  order: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type LocationPointQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Location Point IDs */
  ids?: Maybe<Array<Scalars['Float']>>;
  /** Location Point's location ID */
  location_id?: Maybe<Scalars['Float']>;
  /** Location Point's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
};

export type LocationQuery = {
  status?: Maybe<Array<LocationStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Location's company ID. */
  company_id?: Maybe<Scalars['Float']>;
};

export enum LocationStatus {
  Draft = 'DRAFT',
  Placeholder = 'PLACEHOLDER',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export enum LocationType {
  DefaultLodaing = 'DEFAULT_LODAING',
  DefaultUnlodaing = 'DEFAULT_UNLODAING',
  CompanyBillingAddress = 'COMPANY_BILLING_ADDRESS',
  Other = 'OTHER'
}

export type LocationUpdate = {
  /** Location's type */
  type?: Maybe<LocationType>;
  /** Location's title */
  title?: Maybe<Scalars['String']>;
  /** Location's description */
  description?: Maybe<Scalars['String']>;
  /** Location's address */
  address?: Maybe<Scalars['String']>;
  /** Location's postCode */
  postCode?: Maybe<Scalars['String']>;
  /** Location's postName */
  postName?: Maybe<Scalars['String']>;
  /** Location's country_id */
  country_id?: Maybe<Scalars['Float']>;
  /** Location's contactName */
  contactName?: Maybe<Scalars['String']>;
  /** Location's phone */
  phone?: Maybe<Scalars['String']>;
  /** Location's email */
  email?: Maybe<Scalars['String']>;
  /** Location's geoLocation */
  geoLocation?: Maybe<Scalars['String']>;
  /** Location's notes */
  notes?: Maybe<Scalars['String']>;
  /** Location's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Location's ID */
  id: Scalars['Float'];
};

export type Message = {
  __typename?: 'Message';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  /** Message's user ID */
  user_id: Scalars['Float'];
  /** Message's company ID */
  company_id: Scalars['Float'];
  /** Message's order ID */
  order_id: Scalars['Float'];
  /** Message's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Message's type */
  type?: Maybe<Scalars['Float']>;
  /** Message's text */
  text?: Maybe<Scalars['String']>;
  /** Message's send date */
  sendDate?: Maybe<Scalars['DateTime']>;
};

export type MessageCreate = {
  /** Message's order ID */
  order_id: Scalars['Float'];
  /** Message's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Message's text */
  text: Scalars['String'];
};

export type MessageListing = {
  __typename?: 'MessageListing';
  items?: Maybe<Array<MessageListingItem>>;
  /** Message's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type MessageListingItem = {
  __typename?: 'MessageListingItem';
  /** Message's id. */
  id: Scalars['Float'];
  /** Message's status. */
  status: SqlModelStatus;
  /** Message's order_id. */
  order_id?: Maybe<Scalars['Float']>;
  /** Message's transporter ID. */
  transporter_id?: Maybe<Scalars['Float']>;
  /** Message's user ID. */
  user_id?: Maybe<Scalars['Float']>;
  /** Message's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** Message's type. */
  type?: Maybe<Scalars['Float']>;
  /** Message's text. */
  text?: Maybe<Scalars['String']>;
  /** Message's send date. */
  sendDate?: Maybe<Scalars['DateTime']>;
  /** Message's user's first name. */
  user_firstName?: Maybe<Scalars['String']>;
  /** Message's user's last name. */
  user_lastName?: Maybe<Scalars['String']>;
  /** Message's user's profile image url. */
  user_profileImageUrl?: Maybe<Scalars['String']>;
};

export type MessageQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Message's user ID */
  user_id?: Maybe<Scalars['Float']>;
  /** Message's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Message's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Message's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
};

export type MessageUpdate = {
  /** Message's order ID */
  order_id?: Maybe<Scalars['Float']>;
  /** Message's transport ID */
  transport_id?: Maybe<Scalars['Float']>;
  /** Message's text */
  text?: Maybe<Scalars['String']>;
  /** Message's ID */
  id: Scalars['Float'];
};

export type Module = {
  __typename?: 'Module';
  /** id */
  id: Scalars['Float'];
  /** name */
  name: Scalars['String'];
  /** createTime */
  createTime: Scalars['DateTime'];
  /** updateTime */
  updateTime: Scalars['DateTime'];
};

export type ModuleListing = {
  __typename?: 'ModuleListing';
  items?: Maybe<Array<Module>>;
  /** Module listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type ModulePermission = {
  __typename?: 'ModulePermission';
  module_name?: Maybe<Scalars['String']>;
  companyInfo?: Maybe<Array<Company>>;
  /** Module ID */
  module_id: Scalars['Float'];
  /** Company ID */
  company_id: Scalars['Float'];
  /** Has access */
  hasAccess: Scalars['Boolean'];
  /** Create time */
  createTime: Scalars['DateTime'];
  /** Update time */
  updateTime: Scalars['DateTime'];
};

export type ModulePermissionListing = {
  __typename?: 'ModulePermissionListing';
  items?: Maybe<Array<ModulePermission>>;
  /** ModulePermission's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type ModulePermissionQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** ModulePermission's module_id */
  module_id?: Maybe<Scalars['Float']>;
  /** ModulePermission's company_id */
  company_id?: Maybe<Scalars['Float']>;
  /** ModulePermission's hasAccess */
  hasAccess?: Maybe<Scalars['Boolean']>;
};

export type ModuleQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: User;
  deactivateUser: User;
  registerUserAndCompany: User;
  registerUser: User;
  requestActivateUser: Scalars['String'];
  activateUser: User;
  requestPasswordReset: Scalars['String'];
  resetPassword: User;
  requestEmailChange: Scalars['String'];
  changeEmail: User;
  login: User;
  changeRole: User;
  deleteUser: User;
  createCompany: Company;
  createPlaceholder: Company;
  updateCompany: Company;
  deactivateCompany: Company;
  activateCompany: Company;
  activateCompanyToken: Company;
  inviteUser: Scalars['String'];
  inviteCompany: Scalars['String'];
  importContacts: Array<Company>;
  deleteAccount: Company;
  createVehicle: Vehicle;
  updateVehicle: Vehicle;
  deleteVehicle: Vehicle;
  createOrder: Order;
  updateInternal: Order;
  updateOrder: Order;
  updateTag: Order;
  deleteOrder: Order;
  flagOrder: Order;
  updateOrderStatus: Order;
  assignUser: AssignedUser;
  deleteAssignedUser: Order;
  importOrders: Array<Order>;
  createSubOrder: Order;
  getExtraCosts: Scalars['Float'];
  createShareLink: OrderLink;
  updateShareLink: OrderLink;
  createTransport: Transport;
  updateTransport: Transport;
  updateTransportStatus: Transport;
  deleteTransport: Transport;
  changeVehicle: Transport;
  assignDriver: Transport;
  importTransports: Array<Transport>;
  createTransportChangeRequest: Transport;
  respondToChangeRequest: Transport;
  createNotificationMessage: NotificationMessageListingItem;
  updateNotificationMessage: NotificationMessageListingItem;
  deleteNotificationMessage: NotificationMessageListingItem;
  markReadNotification: Notification;
  markUnreadNotification: Notification;
  readAllNotifications?: Maybe<Scalars['Int']>;
  setNotificationChannel: Array<NotificationSettings>;
  setAllNotificationChannel: Array<NotificationSettings>;
  createDocument: Document;
  deleteDocument: Document;
  updateDocumentExpiryDate: Document;
  replaceInsuranceDocument: Document;
  confirmDocument: Document;
  addLocationPoints: Array<LocationPoint>;
  fillLocationPoints: Scalars['Boolean'];
  updateLocationPointCompletedState: LocationPoint;
  updateLocationPointLoadingDate: LocationPoint;
  createBid: Bid;
  updateBid: Bid;
  acceptBid: Bid;
  declineBid: Bid;
  createTransportState: TransportState;
  createTransportStateLocation: TransportState;
  updateTransportState: TransportState;
  deleteTransportState: TransportState;
  createPricing: Pricing;
  updatePricing: Pricing;
  deletePricing: Pricing;
  createLocation: Location;
  createPlaceholderLocation: Location;
  updateLocation: Location;
  activateLocation: Location;
  deactivateLocation: Location;
  createService: Service;
  updateService: Service;
  deleteService: Service;
  createSubscription: ServiceSubscription;
  updateSubscription: ServiceSubscription;
  deleteSubscription: ServiceSubscription;
  subscriptionPaid: ServiceSubscription;
  changeSubscription: ServiceSubscription;
  createInvoice: Invoice;
  updateInvoice: Invoice;
  deactivateInvoice: Invoice;
  createInvoiceItem: InvoiceItem;
  updateInvoiceItem: InvoiceItem;
  createMessage: Message;
  updateMessage: Message;
  deleteMessage: Message;
  createFeedback: Feedback;
  createModulePermission: ModulePermission;
  updateModulePermission: ModulePermission;
  createOrderTag: OrderTag;
  updateOrderTag: OrderTag;
  addOrderTag: OrderTag;
  removeOrderTag: OrderTag;
  deleteOrderTag: OrderTag;
  updateInvoiceSettings: InvoiceSettings;
  updateTaxationClause: TaxationClause;
  addTaxationClause: TaxationClause;
  removeTaxationClause: TaxationClause;
  updateInvoiceDataStatus: InvoiceData;
};


export type MutationCreateUserArgs = {
  user: UserCreate;
};


export type MutationUpdateUserArgs = {
  user: UserUpdate;
};


export type MutationDeactivateUserArgs = {
  id: Scalars['Int'];
};


export type MutationRegisterUserAndCompanyArgs = {
  insuranceCertificate?: Maybe<DocumentCreate>;
  company: CompanyCreate;
  user: UserCreate;
};


export type MutationRegisterUserArgs = {
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  inviteToken: Scalars['String'];
  user: UserCreate;
};


export type MutationRequestActivateUserArgs = {
  email: Scalars['String'];
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRequestEmailChangeArgs = {
  newEmail: Scalars['String'];
  email: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  login: UserLogin;
};


export type MutationChangeRoleArgs = {
  role_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationCreateCompanyArgs = {
  company: CompanyCreate;
};


export type MutationCreatePlaceholderArgs = {
  company: CompanyPlaceholder;
};


export type MutationUpdateCompanyArgs = {
  company: CompanyUpdate;
};


export type MutationDeactivateCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationActivateCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationActivateCompanyTokenArgs = {
  token: Scalars['String'];
};


export type MutationInviteUserArgs = {
  invite: CompanyInvite;
};


export type MutationInviteCompanyArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationImportContactsArgs = {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export type MutationDeleteAccountArgs = {
  id: Scalars['Int'];
};


export type MutationCreateVehicleArgs = {
  vehicle: VehicleCreate;
};


export type MutationUpdateVehicleArgs = {
  vehicle: VehicleUpdate;
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['Int'];
};


export type MutationCreateOrderArgs = {
  locationPoints?: Maybe<Array<LocationPointItem>>;
  transport?: Maybe<TransportCreateWo>;
  order: OrderCreate;
};


export type MutationUpdateInternalArgs = {
  code: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationUpdateOrderArgs = {
  order: OrderUpdate;
};


export type MutationUpdateTagArgs = {
  order: OrderUpdateTag;
};


export type MutationDeleteOrderArgs = {
  id: Scalars['Int'];
};


export type MutationFlagOrderArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateOrderStatusArgs = {
  status: OrderStatus;
  id: Scalars['Int'];
};


export type MutationAssignUserArgs = {
  type: AssignedUserType;
  user_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteAssignedUserArgs = {
  type?: Maybe<AssignedUserType>;
  user_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationImportOrdersArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateSubOrderArgs = {
  order?: Maybe<OrderCreate>;
  transports?: Maybe<Array<Scalars['Int']>>;
  parent_id: Scalars['Int'];
};


export type MutationGetExtraCostsArgs = {
  transport_id?: Maybe<Scalars['Int']>;
  order_id: Scalars['Int'];
};


export type MutationCreateShareLinkArgs = {
  orderId: Scalars['Int'];
};


export type MutationUpdateShareLinkArgs = {
  isActive: Scalars['Boolean'];
  orderId: Scalars['Int'];
};


export type MutationCreateTransportArgs = {
  transport: TransportCreate;
};


export type MutationUpdateTransportArgs = {
  transport: TransportUpdate;
};


export type MutationUpdateTransportStatusArgs = {
  status: TransportStatus;
  order_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationDeleteTransportArgs = {
  order_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationChangeVehicleArgs = {
  vehicle_id?: Maybe<Scalars['Int']>;
  driver_contact?: Maybe<Scalars['String']>;
  order_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationAssignDriverArgs = {
  vehicle_id?: Maybe<Scalars['Int']>;
  driver_contact?: Maybe<Scalars['String']>;
  driver_id?: Maybe<Scalars['Int']>;
  order_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationImportTransportsArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateTransportChangeRequestArgs = {
  transportChangeRequest: TransportChangeRequestCreate;
};


export type MutationRespondToChangeRequestArgs = {
  response: RespondToChangeRequest;
};


export type MutationCreateNotificationMessageArgs = {
  data?: Maybe<CreateNotificationMessage>;
};


export type MutationUpdateNotificationMessageArgs = {
  data?: Maybe<UpdateNotificationMessage>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationDeleteNotificationMessageArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationMarkReadNotificationArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationMarkUnreadNotificationArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationReadAllNotificationsArgs = {
  company_id?: Maybe<Scalars['Int']>;
};


export type MutationSetNotificationChannelArgs = {
  user_id?: Maybe<Scalars['Int']>;
  channel: NotificationChannel;
  type: NotificationType;
};


export type MutationSetAllNotificationChannelArgs = {
  user_id?: Maybe<Scalars['Int']>;
  channel: NotificationChannel;
};


export type MutationCreateDocumentArgs = {
  document: DocumentCreate;
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateDocumentExpiryDateArgs = {
  expiryDate: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationReplaceInsuranceDocumentArgs = {
  document: DocumentCreate;
  id: Scalars['Int'];
};


export type MutationConfirmDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationAddLocationPointsArgs = {
  locationPoints: AddLocationPoints;
};


export type MutationUpdateLocationPointCompletedStateArgs = {
  completedValue: Scalars['Boolean'];
  id: Scalars['Float'];
};


export type MutationUpdateLocationPointLoadingDateArgs = {
  loadingDate: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationCreateBidArgs = {
  bid: BidCreate;
};


export type MutationUpdateBidArgs = {
  bid: BidUpdate;
};


export type MutationAcceptBidArgs = {
  id: Scalars['Int'];
};


export type MutationDeclineBidArgs = {
  id: Scalars['Int'];
};


export type MutationCreateTransportStateArgs = {
  transportState: TransportStateCreate;
};


export type MutationCreateTransportStateLocationArgs = {
  transportLocation: TransportStateLocation;
};


export type MutationUpdateTransportStateArgs = {
  transportState: TransportStateUpdate;
};


export type MutationDeleteTransportStateArgs = {
  order_id: Scalars['Int'];
  transport_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationCreatePricingArgs = {
  pricing: PricingCreate;
};


export type MutationUpdatePricingArgs = {
  pricing: PricingUpdate;
};


export type MutationDeletePricingArgs = {
  id: Scalars['Int'];
};


export type MutationCreateLocationArgs = {
  location: LocationCreate;
};


export type MutationCreatePlaceholderLocationArgs = {
  location: LocationCreate;
};


export type MutationUpdateLocationArgs = {
  location: LocationUpdate;
};


export type MutationActivateLocationArgs = {
  id: Scalars['Int'];
};


export type MutationDeactivateLocationArgs = {
  id: Scalars['Int'];
};


export type MutationCreateServiceArgs = {
  service: ServiceCreate;
};


export type MutationUpdateServiceArgs = {
  service: ServiceUpdate;
};


export type MutationDeleteServiceArgs = {
  id: Scalars['Int'];
};


export type MutationCreateSubscriptionArgs = {
  subscription: SubscriptionCreate;
};


export type MutationUpdateSubscriptionArgs = {
  subscription: SubscriptionUpdate;
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationSubscriptionPaidArgs = {
  paymentDate?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationChangeSubscriptionArgs = {
  subscription_id?: Maybe<Scalars['Int']>;
  service_id: Scalars['Int'];
};


export type MutationCreateInvoiceArgs = {
  invoice: InvoiceCreate;
};


export type MutationUpdateInvoiceArgs = {
  invoice: InvoiceUpdate;
};


export type MutationDeactivateInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationCreateInvoiceItemArgs = {
  invoiceItem: InvoiceItemCreate;
};


export type MutationUpdateInvoiceItemArgs = {
  invoiceItem: InvoiceItemUpdate;
};


export type MutationCreateMessageArgs = {
  message: MessageCreate;
};


export type MutationUpdateMessageArgs = {
  message: MessageUpdate;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['Int'];
};


export type MutationCreateFeedbackArgs = {
  feedback: FeedbackCreate;
};


export type MutationCreateModulePermissionArgs = {
  hasAccess: Scalars['Boolean'];
  companyId: Scalars['Int'];
  moduleId: Scalars['Int'];
};


export type MutationUpdateModulePermissionArgs = {
  hasAccess: Scalars['Boolean'];
  companyId: Scalars['Int'];
  moduleId: Scalars['Int'];
};


export type MutationCreateOrderTagArgs = {
  tag?: Maybe<Scalars['String']>;
};


export type MutationUpdateOrderTagArgs = {
  tag: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationAddOrderTagArgs = {
  tag_id: Scalars['Int'];
  order_id: Scalars['Int'];
};


export type MutationRemoveOrderTagArgs = {
  tag_id: Scalars['Int'];
  order_id: Scalars['Int'];
};


export type MutationDeleteOrderTagArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateInvoiceSettingsArgs = {
  invoiceSettings: UpdateInvoiceSettings;
};


export type MutationUpdateTaxationClauseArgs = {
  taxationClause: UpdateTaxationClause;
};


export type MutationAddTaxationClauseArgs = {
  taxationClause: AddTaxationClause;
};


export type MutationRemoveTaxationClauseArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateInvoiceDataStatusArgs = {
  id: Scalars['Float'];
  status: InvoiceDataStatus;
};

export type Notification = {
  __typename?: 'Notification';
  status: NotificationStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: NotificationType;
  /** user_id */
  user_id?: Maybe<Scalars['Float']>;
  /** company_id */
  company_id?: Maybe<Scalars['Float']>;
  /** order_id */
  order_id?: Maybe<Scalars['Float']>;
  /** date */
  date?: Maybe<Scalars['DateTime']>;
  /** readUser_id */
  readUser_id?: Maybe<Scalars['Float']>;
  /** readDate */
  readDate?: Maybe<Scalars['DateTime']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** text */
  text?: Maybe<Scalars['String']>;
  /** data */
  data?: Maybe<Scalars['String']>;
  /** link */
  link?: Maybe<Scalars['String']>;
  /** Whether notification was accepted or not */
  accepted?: Maybe<Scalars['Boolean']>;
};

export enum NotificationChannel {
  None = 'NONE',
  AllChannels = 'ALL_CHANNELS',
  Email = 'EMAIL',
  Notification = 'NOTIFICATION'
}

export type NotificationListing = {
  __typename?: 'NotificationListing';
  items?: Maybe<Array<NotificationListingItem>>;
  /** Notification's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type NotificationListingItem = {
  __typename?: 'NotificationListingItem';
  status?: Maybe<NotificationStatus>;
  type?: Maybe<NotificationType>;
  /** Notification's id */
  id?: Maybe<Scalars['Float']>;
  /** Notification's user ID. */
  user_id?: Maybe<Scalars['Float']>;
  /** Notification's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** Notification's order ID. */
  order_id?: Maybe<Scalars['Float']>;
  /** Notification's date. */
  date?: Maybe<Scalars['DateTime']>;
  /** Notification's read user ID. */
  readUser_id?: Maybe<Scalars['Float']>;
  /** Notification's read date. */
  readDate?: Maybe<Scalars['DateTime']>;
  /** Notification's title. */
  title?: Maybe<Scalars['String']>;
  /** Notification's text. */
  text?: Maybe<Scalars['String']>;
  /** Notification's data. */
  data?: Maybe<Scalars['String']>;
  /** Notifications delivery date */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Notification's link. */
  link?: Maybe<Scalars['String']>;
  /** Notification's action company id. */
  actionCompany_id?: Maybe<Scalars['Float']>;
  /** Notification's action company name. */
  actionCompany_name?: Maybe<Scalars['String']>;
  /** Notification's action company logo. */
  actionCompany_logo?: Maybe<Scalars['String']>;
  /** Notification's data user ID. */
  actionUser_id?: Maybe<Scalars['Float']>;
  /** Notification's data user first name. */
  actionUser_firstName?: Maybe<Scalars['String']>;
  /** Notification's data user last name. */
  actionUser_lastName?: Maybe<Scalars['String']>;
  /** Notification's data user profile image url. */
  actionUser_profileImageUrl?: Maybe<Scalars['String']>;
  /** Notification's data company ID. */
  dataCompany_id?: Maybe<Scalars['Float']>;
  /** Notification's data company name. */
  dataCompany_name?: Maybe<Scalars['String']>;
  /** Notification's data company logo. */
  dataCompany_logo?: Maybe<Scalars['String']>;
  /** Notification's data user ID. */
  dataUser_id?: Maybe<Scalars['Float']>;
  /** Notification's data user first name. */
  dataUser_firstName?: Maybe<Scalars['String']>;
  /** Notification's data user last name. */
  dataUser_lastName?: Maybe<Scalars['String']>;
  /** Notification's data user profile image url. */
  dataUser_profileImageUrl?: Maybe<Scalars['String']>;
  /** Notification's acceptance state. */
  accepted?: Maybe<Scalars['Boolean']>;
};

export type NotificationMessageItemRecipient = {
  __typename?: 'NotificationMessageItemRecipient';
  status: NotificationUserMessageStatus;
  /** Recipient user's id. */
  user_id: Scalars['Float'];
  /** First name of recipient at time of being added to recipients. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of recipient at time of being added to recipients. */
  lastName?: Maybe<Scalars['String']>;
  /** Id of notification after it was sent to recipient. */
  notification_id?: Maybe<Scalars['Float']>;
};

export type NotificationMessageListing = {
  __typename?: 'NotificationMessageListing';
  items?: Maybe<Array<NotificationMessageListingItem>>;
  /** Notification message's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type NotificationMessageListingItem = {
  __typename?: 'NotificationMessageListingItem';
  status?: Maybe<NotificationMessageStatus>;
  recipients?: Maybe<Array<NotificationMessageItemRecipient>>;
  /** Notification message's id */
  id?: Maybe<Scalars['Float']>;
  /** Notification message's user ID. */
  user_id?: Maybe<Scalars['Float']>;
  /** Notification message's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** Notification message's title. */
  title?: Maybe<Scalars['String']>;
  /** Notification message's text. */
  text?: Maybe<Scalars['String']>;
  /** Notification message's label. */
  label?: Maybe<Scalars['String']>;
  /** Notifications delivery date */
  scheduleDate?: Maybe<Scalars['DateTime']>;
};

export type NotificationMessageQuery = {
  status?: Maybe<Array<NotificationMessageStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Notification's company ID. */
  company_id?: Maybe<Scalars['Float']>;
};

export enum NotificationMessageStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Deleted = 'DELETED'
}

export type NotificationQuery = {
  status?: Maybe<Array<NotificationStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Array<NotificationType>>;
  /** Notification's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** Notification's order ID in data. */
  order_id?: Maybe<Scalars['Float']>;
  /** Notification's acceptance state. */
  accepted?: Maybe<Scalars['Boolean']>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  type?: Maybe<NotificationType>;
  channel?: Maybe<NotificationChannel>;
  /** Notification settings's user ID. */
  user_id?: Maybe<Scalars['Float']>;
};

export enum NotificationStatus {
  Unread = 'UNREAD',
  EmailSent = 'EMAIL_SENT',
  Read = 'READ',
  Deactivated = 'DEACTIVATED'
}

export enum NotificationType {
  OrderStatus = 'ORDER_STATUS',
  Message = 'MESSAGE',
  BidReceived = 'BID_RECEIVED',
  BidCancelled = 'BID_CANCELLED',
  BidAccepted = 'BID_ACCEPTED',
  Assigned = 'ASSIGNED',
  Document = 'DOCUMENT',
  BidRejected = 'BID_REJECTED',
  Unassigned = 'UNASSIGNED',
  DeliveryChangeRequest = 'DELIVERY_CHANGE_REQUEST',
  DeliveryChanged = 'DELIVERY_CHANGED',
  Custom = 'CUSTOM',
  OperationalWarning = 'OPERATIONAL_WARNING',
  AccountingWarning = 'ACCOUNTING_WARNING',
  Invoice = 'INVOICE'
}

export enum NotificationUserMessageStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Seen = 'SEEN'
}

export type Order = {
  __typename?: 'Order';
  status: OrderStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  bids?: Maybe<Array<Bid>>;
  tags?: Maybe<Array<OrderTag>>;
  tagOrders?: Maybe<Array<OrderListingItem>>;
  transports?: Maybe<Array<Transport>>;
  assignedUsers?: Maybe<Array<AssignedUser>>;
  ownerCompany?: Maybe<Company>;
  transporterCompany?: Maybe<Company>;
  /** Order's parent order ID */
  parentOrder_id?: Maybe<Scalars['Float']>;
  groupIndex?: Maybe<Scalars['Float']>;
  /** Order's description */
  description?: Maybe<Scalars['String']>;
  /** Order's code */
  code?: Maybe<Scalars['String']>;
  /** Order's internal code */
  internalCode?: Maybe<Scalars['String']>;
  /** Order's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Order's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** Order's transporter company internal code */
  transporterCompany_internalCode?: Maybe<Scalars['String']>;
  /** Order's price */
  price?: Maybe<Scalars['Float']>;
  /** Order's private notes */
  privateNotes?: Maybe<Scalars['String']>;
  /** Order's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Order's market expiration date */
  marketExpirationDate?: Maybe<Scalars['DateTime']>;
};

export type OrderCreate = {
  /** Order's description */
  description?: Maybe<Scalars['String']>;
  /** Order's internal code */
  internalCode?: Maybe<Scalars['String']>;
  /** Order's company ID */
  company_id: Scalars['Float'];
  /** Order's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** Order's price */
  price?: Maybe<Scalars['Float']>;
  /** Order's private notes */
  privateNotes?: Maybe<Scalars['String']>;
  /** Order's currency ID */
  currency_id?: Maybe<Scalars['String']>;
  /** Order's group index */
  groupIndex?: Maybe<Scalars['Float']>;
  /** Order's market expiration date */
  marketExpirationDate?: Maybe<Scalars['DateTime']>;
};

export type OrderLink = {
  __typename?: 'OrderLink';
  id: Scalars['String'];
  order_id: Scalars['Float'];
  creator_id: Scalars['Float'];
  is_active: Scalars['Boolean'];
  createTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
};

export type OrderListing = {
  __typename?: 'OrderListing';
  items?: Maybe<Array<OrderListingItem>>;
  /** Order's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type OrderListingItem = {
  __typename?: 'OrderListingItem';
  status?: Maybe<OrderStatus>;
  cargoType?: Maybe<TransportCargoType>;
  parcels?: Maybe<Array<Parcel>>;
  userRole?: Maybe<Array<AssignedUserType>>;
  tags?: Maybe<Array<OrderTag>>;
  dispatchers?: Maybe<Array<Dispatcher>>;
  transports?: Maybe<Array<Transport>>;
  /** Order's id. */
  id: Scalars['Float'];
  /** Order's code. */
  code?: Maybe<Scalars['String']>;
  /** Order's internalCode. */
  internalCode?: Maybe<Scalars['String']>;
  /** Order's market expiration date. */
  marketExpirationDate?: Maybe<Scalars['DateTime']>;
  /** Order's loading time. */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Order's unloading. */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Order's loading time. */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Order's unloading time. */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Order is dangerous?. */
  dangerous?: Maybe<Scalars['Boolean']>;
  /** Orderis stackable. */
  stackable?: Maybe<Scalars['Boolean']>;
  /** Order's owner company id. */
  company_id?: Maybe<Scalars['Float']>;
  /** Order's shipper company id. */
  shipperCompany_id?: Maybe<Scalars['Float']>;
  /** Order's shipper company name. */
  shipperCompany_name?: Maybe<Scalars['String']>;
  /** Order's shipper company logo. */
  shipperCompany_logo?: Maybe<Scalars['String']>;
  /** Order's shipper company internal code. */
  shipperCompany_internalCode?: Maybe<Scalars['String']>;
  /** Order's receiver company id. */
  receiverCompany_id?: Maybe<Scalars['Float']>;
  /** Order's receiver company name. */
  receiverCompany_name?: Maybe<Scalars['String']>;
  /** Order's receiver company logo. */
  receiverCompany_logo?: Maybe<Scalars['String']>;
  /** Order's receiver company internal code. */
  receiverCompany_internalCode?: Maybe<Scalars['String']>;
  /** Order's transporter company id. */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** Order's transporter company name. */
  transporterCompany_name?: Maybe<Scalars['String']>;
  /** Order's transporter company logo. */
  transporterCompany_logo?: Maybe<Scalars['String']>;
  /** Order's transporter company internal code. */
  transporterCompany_internalCode?: Maybe<Scalars['String']>;
  /** Order's unloading location post name. */
  unloadingLocation_postName?: Maybe<Scalars['String']>;
  /** Order's unloading location post code. */
  unloadingLocation_postCode?: Maybe<Scalars['String']>;
  /** Order's unloading location address. */
  unloadingLocation_address?: Maybe<Scalars['String']>;
  /** Order's unloading location country ID. */
  unloadingLocation_country_id?: Maybe<Scalars['Float']>;
  /** Order's unloading location country name. */
  unloadingLocation_country_name?: Maybe<Scalars['String']>;
  /** Order's loading location post code. */
  loadingLocation_postCode?: Maybe<Scalars['String']>;
  /** Order's loading location post name. */
  loadingLocation_postName?: Maybe<Scalars['String']>;
  /** Order's loading location address. */
  loadingLocation_address?: Maybe<Scalars['String']>;
  /** Order's loading location country ID. */
  loadingLocation_country_id?: Maybe<Scalars['Float']>;
  /** Order's loading location country name. */
  loadingLocation_country_name?: Maybe<Scalars['String']>;
  /** Order transport's od. */
  transport_id?: Maybe<Scalars['Float']>;
  /** Order's driver id. */
  driver_id?: Maybe<Scalars['Float']>;
  /** Order's vehicle id. */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Order's vehicle registartion. */
  vehicleRegistration?: Maybe<Scalars['String']>;
  /** Order's bid count. */
  bidCount?: Maybe<Scalars['Float']>;
  /** Order's owners first name. */
  owner_firstName?: Maybe<Scalars['String']>;
  /** Order's owners last name. */
  owner_lastName?: Maybe<Scalars['String']>;
  /** Order's owners profile image url. */
  owner_profileImageUrl?: Maybe<Scalars['String']>;
  groupIndex?: Maybe<Scalars['Float']>;
  /** Order's price. */
  price?: Maybe<Scalars['Float']>;
  /** Order's document PoD indicator */
  hasPoD?: Maybe<Scalars['Boolean']>;
  /** Order's document Invoice indicator */
  hasInvoice?: Maybe<Scalars['Boolean']>;
};

export type OrderQuery = {
  status?: Maybe<Array<OrderStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Order's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** Order's location ID. */
  location_id?: Maybe<Scalars['Float']>;
  /** Order's country ID. */
  country_id?: Maybe<Scalars['Float']>;
  /** Order's tag ID. */
  tag_id?: Maybe<Scalars['Float']>;
  /** Order's post code. */
  postCode?: Maybe<Scalars['String']>;
  /** Order's post name. */
  postName?: Maybe<Scalars['String']>;
  /** Order's loading country ID. */
  loadingCountry_id?: Maybe<Scalars['Float']>;
  /** Order's loading post code. */
  loadingPostCode?: Maybe<Scalars['String']>;
  /** Order's loadgin post name. */
  loadingPostName?: Maybe<Scalars['String']>;
  /** Order's loading country ID. */
  unloadingCountry_id?: Maybe<Scalars['Float']>;
  /** Order's loading post code. */
  unloadingPostCode?: Maybe<Scalars['String']>;
  /** Order's loadgin post name. */
  unloadingPostName?: Maybe<Scalars['String']>;
  /** Order's driver ID. */
  driver_id?: Maybe<Scalars['Float']>;
  /** Order's user ID. */
  user_id?: Maybe<Scalars['Float']>;
  /** Order's loading time from date. */
  loadingTime_from?: Maybe<Scalars['DateTime']>;
  /** Order's loading time to date. */
  loadingTime_to?: Maybe<Scalars['DateTime']>;
  /** Order's exact loading time. */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Order's exact unloading time. */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Order's unloading time from date. */
  unloadingTime_from?: Maybe<Scalars['DateTime']>;
  /** Order's unloading time to date. */
  unloadingTime_to?: Maybe<Scalars['DateTime']>;
  createTime_from?: Maybe<Scalars['DateTime']>;
  createTime_to?: Maybe<Scalars['DateTime']>;
  /** Order has internal code? */
  hasInternalCode?: Maybe<Scalars['Boolean']>;
  /** Order's vehicle registrations. */
  vehicleRegistrations?: Maybe<Array<Scalars['String']>>;
  /** Multiple Order's driver IDs. */
  driver_ids?: Maybe<Array<Scalars['Float']>>;
  /** Multiple Order's user IDs. */
  user_ids?: Maybe<Array<Scalars['Float']>>;
  /** Multiple Order's company IDs. */
  company_ids?: Maybe<Array<Scalars['Float']>>;
};

export enum OrderStatus {
  Draft = 'DRAFT',
  OnMarket = 'ON_MARKET',
  Pending = 'PENDING',
  Ready = 'READY',
  InTransit = 'IN_TRANSIT',
  Completed = 'COMPLETED',
  Stored = 'STORED',
  OnHold = 'ON_HOLD',
  Flagged = 'FLAGGED',
  Damaged = 'DAMAGED',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED'
}

export type OrderTag = {
  __typename?: 'OrderTag';
  id: Scalars['Float'];
  company_id: Scalars['Float'];
  tag: Scalars['String'];
  createTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
};

export type OrderTagListing = {
  __typename?: 'OrderTagListing';
  items?: Maybe<Array<OrderTag>>;
  /** Order tag listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type OrderTagQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Float']>;
};

export type OrderUpdate = {
  /** Order's description */
  description?: Maybe<Scalars['String']>;
  /** Order's internal code */
  internalCode?: Maybe<Scalars['String']>;
  /** Order's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** Order's price */
  price?: Maybe<Scalars['Float']>;
  /** Order's private notes */
  privateNotes?: Maybe<Scalars['String']>;
  /** Order's currency ID */
  currency_id?: Maybe<Scalars['String']>;
  /** Order's group index */
  groupIndex?: Maybe<Scalars['Float']>;
  /** Order's market expiration date */
  marketExpirationDate?: Maybe<Scalars['DateTime']>;
  /** Order's ID */
  id: Scalars['Float'];
};

export type OrderUpdateTag = {
  id: Scalars['Float'];
  tag_id?: Maybe<Scalars['Float']>;
  groupIndex?: Maybe<Scalars['Float']>;
};

export type Parcel = {
  __typename?: 'Parcel';
  /** Parcel's status */
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  /** Parcel's order ID */
  order_id: Scalars['Float'];
  /** Parcel's transport ID */
  transport_id: Scalars['Float'];
  /** Parcel's weight */
  weight?: Maybe<Scalars['Float']>;
  /** Parcel's volume */
  volume?: Maybe<Scalars['Float']>;
  /** Parcel's quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** Parcel's length */
  length?: Maybe<Scalars['Float']>;
  /** Parcel's width */
  width?: Maybe<Scalars['Float']>;
  /** Parcel's height */
  height?: Maybe<Scalars['Float']>;
};

export type ParcelCreate = {
  /** Parcel's ID */
  id?: Maybe<Scalars['Float']>;
  /** Parcel's weight */
  weight?: Maybe<Scalars['Float']>;
  /** Parcel's volume */
  volume?: Maybe<Scalars['Float']>;
  /** Parcel's quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** Parcel's length */
  length?: Maybe<Scalars['Float']>;
  /** Parcel's width */
  width?: Maybe<Scalars['Float']>;
  /** Parcel's height */
  height?: Maybe<Scalars['Float']>;
};

export type ParcelUpdate = {
  /** Parcel's ID */
  id?: Maybe<Scalars['Float']>;
  /** Parcel's weight */
  weight?: Maybe<Scalars['Float']>;
  /** Parcel's volume */
  volume?: Maybe<Scalars['Float']>;
  /** Parcel's quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** Parcel's length */
  length?: Maybe<Scalars['Float']>;
  /** Parcel's width */
  width?: Maybe<Scalars['Float']>;
  /** Parcel's height */
  height?: Maybe<Scalars['Float']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  /** Pricing's company ID */
  company_id: Scalars['Float'];
  /** Pricing's transport company ID */
  transportCompany_id?: Maybe<Scalars['Float']>;
  /** Pricing's title */
  title?: Maybe<Scalars['String']>;
  /** Pricing's post code A */
  postCode_A?: Maybe<Scalars['String']>;
  /** Pricing's post code B */
  postCode_B?: Maybe<Scalars['String']>;
  /** Pricing's country ID A */
  country_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's country ID B */
  country_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID A */
  location_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID B */
  location_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's price */
  price?: Maybe<Scalars['Float']>;
  /** Pricing's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Pricing's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
};

export type PricingCreate = {
  /** Pricing's company ID */
  company_id: Scalars['Float'];
  /** Pricing's transport company ID */
  transportCompany_id?: Maybe<Scalars['Float']>;
  /** Pricing's title */
  title: Scalars['String'];
  /** Pricing's post code A */
  postCode_A: Scalars['String'];
  /** Pricing's post code B */
  postCode_B: Scalars['String'];
  /** Pricing's country ID A */
  country_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's country ID B */
  country_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID A */
  location_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID B */
  location_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's price */
  price: Scalars['Float'];
  /** Pricing's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Pricing's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
};

export type PricingListing = {
  __typename?: 'PricingListing';
  items?: Maybe<Array<Pricing>>;
  /** Pricing's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type PricingQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
};

export type PricingUpdate = {
  /** Pricing's transport company ID */
  transportCompany_id?: Maybe<Scalars['Float']>;
  /** Pricing's title */
  title?: Maybe<Scalars['String']>;
  /** Pricing's post code A */
  postCode_A?: Maybe<Scalars['String']>;
  /** Pricing's post code B */
  postCode_B?: Maybe<Scalars['String']>;
  /** Pricing's country ID A */
  country_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's country ID B */
  country_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID A */
  location_A_id?: Maybe<Scalars['Float']>;
  /** Pricing's location ID B */
  location_B_id?: Maybe<Scalars['Float']>;
  /** Pricing's price */
  price?: Maybe<Scalars['Float']>;
  /** Pricing's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Pricing's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
  /** Pricing's ID */
  id: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getUser: User;
  getUsers: UserListing;
  getUserNotificationSettings?: Maybe<Array<NotificationSettings>>;
  getCompany: Company;
  getCompanies: CompanyListing;
  getTeam: UserListing;
  getDrivers: DriverListing;
  getPartners: CompanyPartnerListing;
  getVehicle: Vehicle;
  getVehicles: VehicleListing;
  getOrder: Order;
  getOrders: OrderListing;
  getMarketOrders: OrderListing;
  getOverlappedOrders: OrderListing;
  getNotificationCount: Scalars['Int'];
  getMessageCount: Scalars['Int'];
  getOrderDetailsByShareLink: SharedOrder;
  downloadOrderDocuments: DownloadOrderDocumentResponse;
  getTransport: Transport;
  getTransports: TransportListing;
  getNotification: Notification;
  getNotifications: NotificationListing;
  getNotificationMessages: NotificationMessageListing;
  getDocument: Document;
  getDocuments: DocumentListing;
  getLocationPoint: LocationPoint;
  getLocationPoints: LocationPointListingItem;
  getBid: Bid;
  getReceivedBids: BidListing;
  getPlacedBids: BidListing;
  getTransportState: TransportState;
  getTransportStates: TransportStateListing;
  getActivityLogs: ActivityLogListing;
  getPricing: Pricing;
  getPricings: PricingListing;
  getLocation: Location;
  getLocations: LocationListing;
  getCountry: Country;
  getCountries: CountryListing;
  globalSearch: Search;
  getService: Service;
  getServices: ServiceListing;
  getSubscription: ServiceSubscription;
  getSubscriptions: SubscriptionListing;
  getInvoice: Invoice;
  getInvoices: InvoiceListing;
  getInvoiceItem: InvoiceItem;
  getMessage: Message;
  getMessages: MessageListing;
  getFeedback: Feedback;
  getFeedbacks: FeedbackListing;
  getCurrency: Currency;
  getCurrencies: CurrencyListing;
  getModules: ModuleListing;
  getModulePermissions: ModulePermissionListing;
  getOrderTagById: OrderTag;
  getOrderTags: OrderTagListing;
  getInvoiceSettings: InvoiceSettings;
  getAllTaxationClause: TaxationClauseListing;
  getInvoicePrefilledData: InvoicePrefilledData;
  getInvoiceData: InvoiceDataListing;
  export: ExportResponse;
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryGetUsersArgs = {
  query: UserQuery;
};


export type QueryGetUserNotificationSettingsArgs = {
  type?: Maybe<NotificationType>;
};


export type QueryGetCompanyArgs = {
  id: Scalars['Int'];
};


export type QueryGetCompaniesArgs = {
  query: CompanyQuery;
};


export type QueryGetTeamArgs = {
  query: UserQuery;
  id: Scalars['Int'];
};


export type QueryGetDriversArgs = {
  query: UserQuery;
  id: Scalars['Int'];
};


export type QueryGetPartnersArgs = {
  query: CompanyQuery;
  id: Scalars['Int'];
};


export type QueryGetVehicleArgs = {
  id: Scalars['Int'];
};


export type QueryGetVehiclesArgs = {
  query: VehicleQuery;
};


export type QueryGetOrderArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


export type QueryGetOrdersArgs = {
  query: OrderQuery;
};


export type QueryGetMarketOrdersArgs = {
  query: OrderQuery;
};


export type QueryGetOverlappedOrdersArgs = {
  query: OrderQuery;
};


export type QueryGetNotificationCountArgs = {
  order_id?: Maybe<Scalars['Int']>;
};


export type QueryGetMessageCountArgs = {
  order_id?: Maybe<Scalars['Int']>;
};


export type QueryGetOrderDetailsByShareLinkArgs = {
  linkId: Scalars['String'];
};


export type QueryDownloadOrderDocumentsArgs = {
  documentId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
};


export type QueryGetTransportArgs = {
  order_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type QueryGetTransportsArgs = {
  query: TransportQuery;
};


export type QueryGetNotificationArgs = {
  id: Scalars['Int'];
};


export type QueryGetNotificationsArgs = {
  query?: Maybe<NotificationQuery>;
};


export type QueryGetNotificationMessagesArgs = {
  query?: Maybe<NotificationMessageQuery>;
};


export type QueryGetDocumentArgs = {
  id: Scalars['Int'];
};


export type QueryGetDocumentsArgs = {
  query: DocumentQuery;
};


export type QueryGetLocationPointArgs = {
  id: Scalars['Float'];
};


export type QueryGetLocationPointsArgs = {
  query: LocationPointQuery;
};


export type QueryGetBidArgs = {
  id: Scalars['Int'];
};


export type QueryGetReceivedBidsArgs = {
  query: BidQuery;
};


export type QueryGetPlacedBidsArgs = {
  query: BidQuery;
};


export type QueryGetTransportStateArgs = {
  order_id: Scalars['Int'];
  transport_id: Scalars['Int'];
  id: Scalars['Int'];
};


export type QueryGetTransportStatesArgs = {
  query: TransportStateQuery;
};


export type QueryGetActivityLogsArgs = {
  query?: Maybe<ActivityLogQuery>;
};


export type QueryGetPricingArgs = {
  id: Scalars['Int'];
};


export type QueryGetPricingsArgs = {
  query: PricingQuery;
};


export type QueryGetLocationArgs = {
  id: Scalars['Int'];
};


export type QueryGetLocationsArgs = {
  query: LocationQuery;
};


export type QueryGetCountryArgs = {
  id: Scalars['Int'];
};


export type QueryGetCountriesArgs = {
  query: CountryQuery;
};


export type QueryGlobalSearchArgs = {
  query: SearchQuery;
};


export type QueryGetServiceArgs = {
  id: Scalars['Int'];
};


export type QueryGetServicesArgs = {
  query: ServiceQuery;
};


export type QueryGetSubscriptionArgs = {
  id: Scalars['Int'];
};


export type QueryGetSubscriptionsArgs = {
  query: SubscriptionQuery;
};


export type QueryGetInvoiceArgs = {
  id: Scalars['Int'];
};


export type QueryGetInvoicesArgs = {
  query: InvoiceQuery;
};


export type QueryGetInvoiceItemArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessageArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessagesArgs = {
  query: MessageQuery;
};


export type QueryGetFeedbackArgs = {
  id: Scalars['Int'];
};


export type QueryGetFeedbacksArgs = {
  query: FeedbackQuery;
};


export type QueryGetCurrencyArgs = {
  id: Scalars['Int'];
};


export type QueryGetCurrenciesArgs = {
  query: CurrencyQuery;
};


export type QueryGetModulesArgs = {
  query: ModuleQuery;
};


export type QueryGetModulePermissionsArgs = {
  query: ModulePermissionQuery;
};


export type QueryGetOrderTagByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetOrderTagsArgs = {
  query: OrderTagQuery;
};


export type QueryGetAllTaxationClauseArgs = {
  query: TaxationClauseQuery;
};


export type QueryGetInvoicePrefilledDataArgs = {
  payeeId: Scalars['Float'];
  orderCode?: Maybe<Scalars['String']>;
  orderId: Scalars['Float'];
};


export type QueryGetInvoiceDataArgs = {
  orderId: Scalars['Float'];
};


export type QueryExportArgs = {
  options: ExportInput;
};

export type RespondToChangeRequest = {
  /** Transport change request's notification ID */
  notification_id: Scalars['Float'];
  /** Whether change should be accepted or not */
  accept: Scalars['Boolean'];
};

export type Role = {
  __typename?: 'Role';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  rolePermissions: Array<Maybe<RolePermission>>;
  /** name */
  name: Scalars['String'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
  execute: Scalars['Boolean'];
  /** role_id */
  role_id: Scalars['Float'];
  /** permission_id */
  permission_id: Scalars['Float'];
};

export type Search = {
  __typename?: 'Search';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  users?: Maybe<UserListing>;
  companies?: Maybe<CompanyListing>;
  orders?: Maybe<OrderListing>;
  documents?: Maybe<DocumentListing>;
};

export type SearchQuery = {
  /** Lisiting page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  type: ServiceType;
  billingInterval?: Maybe<BillingInterval>;
  /** Service's name */
  name: Scalars['String'];
  /** Service's description */
  description?: Maybe<Scalars['String']>;
  /** Service's team size */
  teamSize?: Maybe<Scalars['Float']>;
  /** Service's price */
  price?: Maybe<Scalars['Float']>;
  /** Service's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
  /** Service's tax amount */
  taxAmount?: Maybe<Scalars['Float']>;
  /** Service's total amount */
  totalAmount?: Maybe<Scalars['Float']>;
};

export type ServiceCreate = {
  type: ServiceType;
  /** Service's name */
  name: Scalars['String'];
  /** Service's description */
  description?: Maybe<Scalars['String']>;
  /** Service's team size */
  teamSize?: Maybe<Scalars['Float']>;
  /** Service's price */
  price?: Maybe<Scalars['Float']>;
  /** Service's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
};

export type ServiceListing = {
  __typename?: 'ServiceListing';
  items?: Maybe<Array<Service>>;
  /** Service's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type ServiceQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Service's type */
  type?: Maybe<Scalars['Float']>;
};

export type ServiceSubscription = {
  __typename?: 'ServiceSubscription';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  service?: Maybe<Service>;
  /** Subscription's service ID */
  service_id: Scalars['Float'];
  /** Subscription's company ID */
  company_id: Scalars['Float'];
  /** Subscription's discount */
  discount?: Maybe<Scalars['Float']>;
  /** Subscription's discount rate */
  discountRate?: Maybe<Scalars['Float']>;
  /** Subscription's payment method */
  paymentMethod?: Maybe<Scalars['Float']>;
  /** Subscription's bill date */
  billDate?: Maybe<Scalars['DateTime']>;
  /** Subscription's payment date */
  paymentDate?: Maybe<Scalars['DateTime']>;
  /** Subscription's expiration date */
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export enum ServiceType {
  Free = 'FREE',
  Billable = 'BILLABLE'
}

export type ServiceUpdate = {
  type?: Maybe<ServiceType>;
  /** Service's name */
  name?: Maybe<Scalars['String']>;
  /** Service's description */
  description?: Maybe<Scalars['String']>;
  /** Service's team size */
  teamSize?: Maybe<Scalars['Float']>;
  /** Service's price */
  price?: Maybe<Scalars['Float']>;
  /** Service's tax rate */
  taxRate?: Maybe<Scalars['Float']>;
  /** Service's ID */
  id: Scalars['Float'];
};

export type SharedOrder = {
  __typename?: 'SharedOrder';
  order: Order;
  documents: DocumentListing;
};

export enum SqlModelStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type Subscription = {
  __typename?: 'Subscription';
  newNotification: NotificationListingItem;
  newMessage: Message;
};


export type SubscriptionNewNotificationArgs = {
  user_id: Scalars['Int'];
};


export type SubscriptionNewMessageArgs = {
  order_id: Scalars['Int'];
};

export type SubscriptionCreate = {
  /** Subscription's service ID */
  service_id: Scalars['Float'];
  /** Subscription's company ID */
  company_id: Scalars['Float'];
  /** Subscription's discount rate */
  discountRate?: Maybe<Scalars['Float']>;
  /** Subscription's payment method */
  paymentMethod?: Maybe<Scalars['Float']>;
  /** Subscription's last billed date */
  lastBilledDate?: Maybe<Scalars['String']>;
  /** Subscription's expiration date */
  expirationDate?: Maybe<Scalars['String']>;
};

export type SubscriptionListing = {
  __typename?: 'SubscriptionListing';
  items?: Maybe<Array<ServiceSubscription>>;
  /** Subscription's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type SubscriptionQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Subscription's company ID */
  company_id?: Maybe<Scalars['Float']>;
};

export type SubscriptionUpdate = {
  /** Subscription's service ID */
  service_id?: Maybe<Scalars['Float']>;
  /** Subscription's discount rate */
  discountRate?: Maybe<Scalars['Float']>;
  /** Subscription's payment method */
  paymentMethod?: Maybe<Scalars['Float']>;
  /** Subscription's last billed date */
  lastBilledDate?: Maybe<Scalars['String']>;
  /** Subscription's expiration date */
  expirationDate?: Maybe<Scalars['String']>;
  /** Subscription's ID */
  id: Scalars['Float'];
};

export type TaxationClause = {
  __typename?: 'TaxationClause';
  id: Scalars['Float'];
  company_id: Scalars['Float'];
  clause: Scalars['String'];
  isDefault: Scalars['Boolean'];
  vat: Scalars['Float'];
  createTime: Scalars['DateTime'];
  updateTime: Scalars['DateTime'];
};

export type TaxationClauseListing = {
  __typename?: 'TaxationClauseListing';
  items?: Maybe<Array<TaxationClause>>;
  total?: Maybe<Scalars['Float']>;
};

export type TaxationClauseQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type Transport = {
  __typename?: 'Transport';
  /** id */
  id: Scalars['Float'];
  status?: Maybe<TransportStatus>;
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  cargoType?: Maybe<TransportCargoType>;
  shipperCompany?: Maybe<Company>;
  receiverCompany?: Maybe<Company>;
  vehicle?: Maybe<Vehicle>;
  parcels?: Maybe<Array<Parcel>>;
  locationPoints?: Maybe<Array<LocationPoint>>;
  /** Transport's order ID */
  order_id: Scalars['Float'];
  /** Transport's parent ID */
  parentOrder_id?: Maybe<Scalars['Float']>;
  /** Transport's type */
  type?: Maybe<Scalars['Float']>;
  /** Transport's description */
  description?: Maybe<Scalars['String']>;
  /** Transport's shipper company ID */
  shipperCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's receiver company ID */
  receiverCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's shipper company internal code */
  shipperCompany_internalCode?: Maybe<Scalars['String']>;
  /** Transport's receiver company internal code */
  receiverCompany_internalCode?: Maybe<Scalars['String']>;
  /** Transport's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Transport's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Transport's driver contact */
  driverContact?: Maybe<Scalars['String']>;
  /** Transport's loading location ID */
  loadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's unloading location ID */
  unloadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's requested loading time */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's requested unloading time */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading time */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's unloading time */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading contact */
  loadingContact?: Maybe<Scalars['String']>;
  /** Transport's unloading contact */
  unloadingContact?: Maybe<Scalars['String']>;
  /** Transport's notes */
  notes?: Maybe<Scalars['String']>;
  /** Transport's cost */
  cost?: Maybe<Scalars['Float']>;
  /** Transport's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Transport is dangerous? */
  dangerous?: Maybe<Scalars['Boolean']>;
  /** Transport is stackable? */
  stackable?: Maybe<Scalars['Boolean']>;
};

export enum TransportCargoType {
  Ftl = 'FTL',
  IndustryPallet = 'INDUSTRY_PALLET',
  EurPallet = 'EUR_PALLET',
  VolumeWeight = 'VOLUME_WEIGHT',
  SizeWeight = 'SIZE_WEIGHT'
}

export type TransportChangeRequestCreate = {
  parcels?: Maybe<Array<ParcelUpdate>>;
  /** Transport change request's transport ID */
  id: Scalars['Float'];
  /** Transport change request's order ID */
  order_id: Scalars['Float'];
  /** Transport change request's loading location ID */
  loadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport change request's unloading location ID */
  unloadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport change request's loading time */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport change request's unloading time */
  unloadingTime?: Maybe<Scalars['DateTime']>;
};

export type TransportCreate = {
  cargoType?: Maybe<TransportCargoType>;
  parcels?: Maybe<Array<ParcelCreate>>;
  /** Transport's order ID */
  order_id: Scalars['Float'];
  /** Transport's type */
  type?: Maybe<Scalars['Float']>;
  /** Transport's description */
  description?: Maybe<Scalars['String']>;
  /** Transport's shipper company ID */
  shipperCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's receiver company ID */
  receiverCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's internal code. */
  internalCode?: Maybe<Scalars['String']>;
  /** Transport's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Transport's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Transport's driver contact */
  driverContact?: Maybe<Scalars['String']>;
  /** Transport's loading location ID */
  loadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's unloading location ID */
  unloadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's requested loading time */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's requested unloading time */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading time */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's unloading time */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading contact */
  loadingContact?: Maybe<Scalars['String']>;
  /** Transport's unloading contact */
  unloadingContact?: Maybe<Scalars['String']>;
  /** Transport's notes */
  notes?: Maybe<Scalars['String']>;
  /** Transport's cost */
  cost?: Maybe<Scalars['Float']>;
  /** Transport's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Transport is dangerous? */
  dangerous?: Maybe<Scalars['Boolean']>;
  /** Transport is stackable? */
  stackable?: Maybe<Scalars['Boolean']>;
};

export type TransportCreateWo = {
  cargoType?: Maybe<TransportCargoType>;
  parcels?: Maybe<Array<ParcelCreate>>;
  /** Transport's type */
  type?: Maybe<Scalars['Float']>;
  /** Transport's description */
  description?: Maybe<Scalars['String']>;
  /** Transport's shipper company ID */
  shipperCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's receiver company ID */
  receiverCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Transport's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Transport's driver contact */
  driverContact?: Maybe<Scalars['String']>;
  /** Transport's loading location ID */
  loadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's unloading location ID */
  unloadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's requested loading time */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's requested unloading time */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading time */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's unloading time */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading contact */
  loadingContact?: Maybe<Scalars['String']>;
  /** Transport's unloading contact */
  unloadingContact?: Maybe<Scalars['String']>;
  /** Transport's notes */
  notes?: Maybe<Scalars['String']>;
  /** Transport's cost */
  cost?: Maybe<Scalars['Float']>;
  /** Transport's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Transport is dangerous? */
  dangerous?: Maybe<Scalars['Boolean']>;
  /** Transport is stackable? */
  stackable?: Maybe<Scalars['Boolean']>;
};

export type TransportListing = {
  __typename?: 'TransportListing';
  items?: Maybe<Array<Transport>>;
  /** Transport's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type TransportQuery = {
  status?: Maybe<Array<TransportStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Transport's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Transport's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
};

export type TransportState = {
  __typename?: 'TransportState';
  /** id */
  id: Scalars['Float'];
  status?: Maybe<TransportStateStatus>;
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  /** TransportState's transport ID */
  transport_id: Scalars['Float'];
  /** TransportState's order ID */
  order_id: Scalars['Float'];
  /** TransportState's type */
  type?: Maybe<Scalars['Float']>;
  /** TransportState's location ID */
  location_id?: Maybe<Scalars['Float']>;
  /** TransportState's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** TransportState's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** TransportState's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** TransportState's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** TransportState's planned time */
  plannedTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's execute time */
  executeTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's notes */
  notes?: Maybe<Scalars['String']>;
  /** TransportState's extra cost */
  extraCost?: Maybe<Scalars['Float']>;
};

export type TransportStateCreate = {
  status: TransportStateStatus;
  /** TransportState's transport ID */
  transport_id: Scalars['Float'];
  /** TransportState's order ID */
  order_id: Scalars['Float'];
  /** TransportState's type */
  type?: Maybe<Scalars['Float']>;
  /** TransportState's location ID */
  location_id?: Maybe<Scalars['Float']>;
  /** TransportState's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** TransportState's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** TransportState's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** TransportState's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** TransportState's planned time */
  plannedTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's execute time */
  executeTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's notes */
  notes?: Maybe<Scalars['String']>;
  /** TransportState's extra cost */
  extraCost?: Maybe<Scalars['Float']>;
};

export type TransportStateListing = {
  __typename?: 'TransportStateListing';
  items?: Maybe<Array<TransportState>>;
  /** TransportState's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type TransportStateLocation = {
  /** TransportState's transport ID */
  transport_id: Scalars['Float'];
  /** TransportState's order ID */
  order_id: Scalars['Float'];
  /** TransportState's type */
  type?: Maybe<Scalars['Float']>;
  /** TransportState's geo location */
  geoLocation: Scalars['String'];
  /** TransportState's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** TransportState's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** TransportState's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** TransportState's planned time */
  plannedTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's execute time */
  executeTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's notes */
  notes?: Maybe<Scalars['String']>;
};

export type TransportStateQuery = {
  status?: Maybe<Array<TransportStateStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** TransportState's type. */
  type?: Maybe<Array<Scalars['Float']>>;
  /** TransportState's transport id. */
  transport_id?: Maybe<Scalars['Float']>;
  /** TransportState's order id. */
  order_id?: Maybe<Scalars['Float']>;
  /** TransportState's vehicle id. */
  vehicle_id?: Maybe<Scalars['Float']>;
};

export enum TransportStateStatus {
  InitialLoading = 'INITIAL_LOADING',
  InTransit = 'IN_TRANSIT',
  Location = 'LOCATION',
  LastLocation = 'LAST_LOCATION',
  Unload = 'UNLOAD',
  Load = 'LOAD',
  TransportChange = 'TRANSPORT_CHANGE',
  DriverChange = 'DRIVER_CHANGE',
  Waiting = 'WAITING',
  Processing = 'PROCESSING',
  ExtraCost = 'EXTRA_COST',
  Delivered = 'DELIVERED'
}

export type TransportStateUpdate = {
  status?: Maybe<TransportStateStatus>;
  /** TransportState's transport ID */
  transport_id: Scalars['Float'];
  /** TransportState's order ID */
  order_id: Scalars['Float'];
  /** TransportState's type */
  type?: Maybe<Scalars['Float']>;
  /** TransportState's location ID */
  location_id?: Maybe<Scalars['Float']>;
  /** TransportState's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** TransportState's transporter company ID */
  transporterCompany_id?: Maybe<Scalars['Float']>;
  /** TransportState's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** TransportState's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** TransportState's planned time */
  plannedTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's execute time */
  executeTime?: Maybe<Scalars['DateTime']>;
  /** TransportState's notes */
  notes?: Maybe<Scalars['String']>;
  /** TransportState's extra cost */
  extraCost?: Maybe<Scalars['Float']>;
  /** TransportState's ID */
  id: Scalars['Float'];
};

export enum TransportStatus {
  Draft = 'DRAFT',
  WaitingLoading = 'WAITING_LOADING',
  InTransit = 'IN_TRANSIT',
  Unloaded = 'UNLOADED',
  Completed = 'COMPLETED',
  Flagged = 'FLAGGED',
  Damaged = 'DAMAGED',
  Deleted = 'DELETED'
}

export type TransportUpdate = {
  cargoType?: Maybe<TransportCargoType>;
  parcels?: Maybe<Array<ParcelUpdate>>;
  /** Transport's order ID */
  order_id: Scalars['Float'];
  /** Transport's type */
  type?: Maybe<Scalars['Float']>;
  /** Transport's description */
  description?: Maybe<Scalars['String']>;
  /** Transport's shipper company ID */
  shipperCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's receiver company ID */
  receiverCompany_id?: Maybe<Scalars['Float']>;
  /** Transport's internal code. */
  internalCode?: Maybe<Scalars['String']>;
  /** Transport's vehicle ID */
  vehicle_id?: Maybe<Scalars['Float']>;
  /** Transport's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Transport's driver contact */
  driverContact?: Maybe<Scalars['String']>;
  /** Transport's loading location ID */
  loadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's unloading location ID */
  unloadingLocation_id?: Maybe<Scalars['Float']>;
  /** Transport's requested loading time */
  requestedLoadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's requested unloading time */
  requestedUnloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading time */
  loadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's unloading time */
  unloadingTime?: Maybe<Scalars['DateTime']>;
  /** Transport's loading contact */
  loadingContact?: Maybe<Scalars['String']>;
  /** Transport's unloading contact */
  unloadingContact?: Maybe<Scalars['String']>;
  /** Transport's notes */
  notes?: Maybe<Scalars['String']>;
  /** Transport's cost */
  cost?: Maybe<Scalars['Float']>;
  /** Transport's currency ID */
  currency_id?: Maybe<Scalars['Float']>;
  /** Transport is dangerous? */
  dangerous?: Maybe<Scalars['Boolean']>;
  /** Transport is stackable? */
  stackable?: Maybe<Scalars['Boolean']>;
  /** Transport's ID */
  id: Scalars['Float'];
};

export type UpdateInvoiceSettings = {
  customPrefix?: Maybe<Scalars['String']>;
  addPayeePrefix?: Maybe<Scalars['Boolean']>;
  addChronologicalSequence?: Maybe<Scalars['Boolean']>;
  defaultDueDate?: Maybe<Scalars['Float']>;
};

export type UpdateNotificationMessage = {
  status?: Maybe<Array<NotificationMessageStatus>>;
  /** Notification's id. */
  id?: Maybe<Scalars['Float']>;
  /** Notification's title. */
  title?: Maybe<Scalars['String']>;
  /** Notification's message body. */
  text?: Maybe<Scalars['String']>;
  /** Notification's label. */
  label?: Maybe<Scalars['String']>;
  /** A date when message should be delivered. */
  scheduleDate?: Maybe<Scalars['DateTime']>;
  /** Notification's recipients (ids). Users should be from company with company_id. */
  recipients?: Maybe<Array<Scalars['Float']>>;
};

export type UpdateTaxationClause = {
  id: Scalars['Float'];
  isDefault: Scalars['Boolean'];
};


export type User = {
  __typename?: 'User';
  status: UserStatus;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Role>>;
  modules?: Maybe<Array<Module>>;
  company?: Maybe<Company>;
  isExternalDriver?: Maybe<Scalars['Boolean']>;
  /** User's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** User's first name property definition. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name property definition. */
  lastName?: Maybe<Scalars['String']>;
  /** User's email property definition. */
  email?: Maybe<Scalars['String']>;
  /** phone */
  phone?: Maybe<Scalars['String']>;
  /** User's profile image url property definition. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** User's password hash property definition. */
  password?: Maybe<Scalars['String']>;
  /** Vehicle registration */
  vehicleRegistration?: Maybe<Array<VehicleRegistration>>;
  /** user token */
  token?: Maybe<Scalars['String']>;
};

export type UserCreate = {
  /** User's first name property definition. */
  firstName: Scalars['String'];
  /** User's last name property definition. */
  lastName: Scalars['String'];
  /** User's email property definition. */
  email: Scalars['String'];
  /** User's phone property definition. */
  phone?: Maybe<Scalars['String']>;
  /** User's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** User's profile image url property definition. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** User's password hash property definition. */
  password?: Maybe<Scalars['String']>;
  /** Is user an external driver. */
  isExternalDriver?: Maybe<Scalars['Boolean']>;
};

export type UserListing = {
  __typename?: 'UserListing';
  items?: Maybe<Array<User>>;
  /** User's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type UserLogin = {
  /** User's email property definition. */
  email: Scalars['String'];
  /** User's password hash property definition. */
  password?: Maybe<Scalars['String']>;
};

export type UserQuery = {
  status?: Maybe<Array<UserStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** User's ID. */
  id?: Maybe<Scalars['Float']>;
  /** Multiple user's IDs. */
  ids?: Maybe<Array<Scalars['Float']>>;
  /** User's company ID. */
  company_id?: Maybe<Scalars['Float']>;
  /** User's role ID. */
  role?: Maybe<Scalars['Float']>;
  /** Should include external drivers flag. */
  externalDriver?: Maybe<Array<Scalars['Float']>>;
};

export enum UserStatus {
  Incomplete = 'INCOMPLETE',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type UserUpdate = {
  /** User's first name property definition. */
  firstName?: Maybe<Scalars['String']>;
  /** User's last name property definition. */
  lastName?: Maybe<Scalars['String']>;
  /** User's phone property definition. */
  phone?: Maybe<Scalars['String']>;
  /** User's profile image url property definition. */
  profileImageUrl?: Maybe<Scalars['String']>;
  /** Is user an external driver. */
  isExternalDriver?: Maybe<Scalars['Boolean']>;
  /** User's ID. */
  id: Scalars['Float'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  status?: Maybe<SqlModelStatus>;
  /** id */
  id: Scalars['Float'];
  /** Created at property definition. */
  createTime?: Maybe<Scalars['DateTime']>;
  /** Updated at property definition. */
  updateTime?: Maybe<Scalars['DateTime']>;
  /** User who created the object */
  createUser?: Maybe<Scalars['Float']>;
  /** User who created the object */
  updateUser?: Maybe<Scalars['Float']>;
  driver?: Maybe<User>;
  /** Vehicle's company ID */
  company_id: Scalars['Float'];
  /** Vehicle's type */
  type?: Maybe<Scalars['Float']>;
  /** Vehicle's registration */
  registration?: Maybe<Scalars['String']>;
  /** Vehicle's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Vehicle's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** Vehicle's description */
  description?: Maybe<Scalars['String']>;
  /** Vehicle's maximum cargo volume */
  maxCargoVolume?: Maybe<Scalars['Float']>;
  /** Vehicle's maximum cargo weight */
  maxCargoWeight?: Maybe<Scalars['Float']>;
};

export type VehicleCreate = {
  /** Vehicle's company ID */
  company_id: Scalars['Float'];
  /** Vehicle's type */
  type?: Maybe<Scalars['Float']>;
  /** Vehicle's registration */
  registration: Scalars['String'];
  /** Vehicle's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Vehicle's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** Vehicle's description */
  description?: Maybe<Scalars['String']>;
  /** Vehicle's description */
  maxCargoVolume?: Maybe<Scalars['Float']>;
  /** Vehicle's description */
  maxCargoWeight?: Maybe<Scalars['Float']>;
};

export type VehicleListing = {
  __typename?: 'VehicleListing';
  items?: Maybe<Array<Vehicle>>;
  /** Vehicle's listing count. */
  total?: Maybe<Scalars['Float']>;
};

export type VehicleQuery = {
  status?: Maybe<Array<SqlModelStatus>>;
  /** Listing page limit. */
  limit?: Maybe<Scalars['Float']>;
  /** Listing order. */
  orderBy?: Maybe<Scalars['String']>;
  /** Is descending order. */
  desc?: Maybe<Scalars['Boolean']>;
  /** Listing page number. */
  page?: Maybe<Scalars['Float']>;
  /** Listing search. */
  search?: Maybe<Scalars['String']>;
  /** Vehicle's company ID */
  company_id?: Maybe<Scalars['Float']>;
  /** Vehicle's ID */
  id?: Maybe<Scalars['Float']>;
  /** Multiple vehicle's IDs */
  ids?: Maybe<Array<Scalars['Float']>>;
};

export type VehicleRegistration = {
  __typename?: 'VehicleRegistration';
  reg: Scalars['String'];
};

export type VehicleUpdate = {
  /** Vehicle's type */
  type?: Maybe<Scalars['Float']>;
  /** Vehicle's registration */
  registration?: Maybe<Scalars['String']>;
  /** Vehicle's driver ID */
  driverUser_id?: Maybe<Scalars['Float']>;
  /** Vehicle's geo location */
  geoLocation?: Maybe<Scalars['String']>;
  /** Vehicle's description */
  description?: Maybe<Scalars['String']>;
  /** Vehicle's description */
  maxCargoVolume?: Maybe<Scalars['Float']>;
  /** Vehicle's description */
  maxCargoWeight?: Maybe<Scalars['Float']>;
  /** Vehicle's ID */
  id: Scalars['Float'];
};

export type AcceptBidMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AcceptBidMutation = (
  { __typename?: 'Mutation' }
  & { acceptBid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
  ) }
);

export type ActivateCompanyMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { activateCompanyToken: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type ActivateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ActivateLocationMutation = (
  { __typename?: 'Mutation' }
  & { activateLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & { activateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type AddLocationPointsMutationVariables = Exact<{
  locationPoints: AddLocationPoints;
}>;


export type AddLocationPointsMutation = (
  { __typename?: 'Mutation' }
  & { addLocationPoints: Array<(
    { __typename?: 'LocationPoint' }
    & Pick<LocationPoint, 'location_id' | 'order' | 'note'>
  )> }
);

export type AddOrderTagMutationVariables = Exact<{
  tag_id: Scalars['Int'];
  order_id: Scalars['Int'];
}>;


export type AddOrderTagMutation = (
  { __typename?: 'Mutation' }
  & { addOrderTag: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'tag'>
  ) }
);

export type AddTaxationClauseMutationVariables = Exact<{
  taxationClause: AddTaxationClause;
}>;


export type AddTaxationClauseMutation = (
  { __typename?: 'Mutation' }
  & { addTaxationClause: (
    { __typename?: 'TaxationClause' }
    & Pick<TaxationClause, 'clause' | 'vat'>
  ) }
);

export type AssignDriverMutationVariables = Exact<{
  driver_id: Scalars['Int'];
  driver_contact: Scalars['String'];
  order_id: Scalars['Int'];
  id: Scalars['Int'];
  vehicle_id?: Maybe<Scalars['Int']>;
}>;


export type AssignDriverMutation = (
  { __typename?: 'Mutation' }
  & { assignDriver: (
    { __typename?: 'Transport' }
    & Pick<Transport, 'id'>
  ) }
);

export type AssignUserMutationVariables = Exact<{
  type: AssignedUserType;
  user_id: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type AssignUserMutation = (
  { __typename?: 'Mutation' }
  & { assignUser: (
    { __typename?: 'AssignedUser' }
    & Pick<AssignedUser, 'status' | 'id'>
  ) }
);

export type ChangeEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { changeEmail: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ChangeRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  role_id: Scalars['Int'];
}>;


export type ChangeRoleMutation = (
  { __typename?: 'Mutation' }
  & { changeRole: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ChangeVehicleMutationVariables = Exact<{
  vehicle_id?: Maybe<Scalars['Int']>;
  order_id: Scalars['Int'];
  id: Scalars['Int'];
  driver_contact?: Maybe<Scalars['String']>;
}>;


export type ChangeVehicleMutation = (
  { __typename?: 'Mutation' }
  & { changeVehicle: (
    { __typename?: 'Transport' }
    & Pick<Transport, 'vehicle_id'>
  ) }
);

export type ConfirmDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConfirmDocumentMutation = (
  { __typename?: 'Mutation' }
  & { confirmDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  ) }
);

export type CreateBidMutationVariables = Exact<{
  bid: BidCreate;
}>;


export type CreateBidMutation = (
  { __typename?: 'Mutation' }
  & { createBid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
  ) }
);

export type CreateDocumentMutationVariables = Exact<{
  document: DocumentCreate;
}>;


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path'>
  ) }
);

export type CreateFeedbackMutationVariables = Exact<{
  feedback: FeedbackCreate;
}>;


export type CreateFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { createFeedback: (
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id'>
  ) }
);

export type CreateLocationMutationVariables = Exact<{
  location: LocationCreate;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'timezone'>
  ) }
);

export type CreateMessageMutationVariables = Exact<{
  message: MessageCreate;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'company_id' | 'order_id' | 'sendDate' | 'status' | 'text' | 'type' | 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'profileImageUrl'>
    )> }
  ) }
);

export type CreateModulePermissionMutationVariables = Exact<{
  hasAccess: Scalars['Boolean'];
  companyId: Scalars['Int'];
  moduleId: Scalars['Int'];
}>;


export type CreateModulePermissionMutation = (
  { __typename?: 'Mutation' }
  & { createModulePermission: (
    { __typename?: 'ModulePermission' }
    & Pick<ModulePermission, 'module_id'>
  ) }
);

export type CreateNotificationMessageMutationVariables = Exact<{
  data?: Maybe<CreateNotificationMessage>;
}>;


export type CreateNotificationMessageMutation = (
  { __typename?: 'Mutation' }
  & { createNotificationMessage: (
    { __typename?: 'NotificationMessageListingItem' }
    & Pick<NotificationMessageListingItem, 'status' | 'id' | 'user_id' | 'company_id' | 'title' | 'text' | 'label' | 'scheduleDate'>
    & { recipients?: Maybe<Array<(
      { __typename?: 'NotificationMessageItemRecipient' }
      & Pick<NotificationMessageItemRecipient, 'status' | 'user_id' | 'firstName' | 'lastName' | 'notification_id'>
    )>> }
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  transport?: Maybe<TransportCreateWo>;
  order: OrderCreate;
  locationPoints?: Maybe<Array<LocationPointItem> | LocationPointItem>;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'status' | 'company_id' | 'code' | 'description' | 'internalCode' | 'price' | 'privateNotes' | 'marketExpirationDate'>
    & { transports?: Maybe<Array<(
      { __typename?: 'Transport' }
      & Pick<Transport, 'order_id' | 'id' | 'status' | 'type' | 'shipperCompany_id' | 'receiverCompany_id' | 'loadingContact' | 'unloadingContact' | 'cargoType' | 'dangerous' | 'stackable' | 'loadingLocation_id' | 'unloadingLocation_id' | 'requestedUnloadingTime' | 'requestedLoadingTime'>
      & { parcels?: Maybe<Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'weight' | 'volume' | 'quantity' | 'length' | 'width' | 'height'>
      )>> }
    )>> }
  ) }
);

export type CreateOrderTagMutationVariables = Exact<{
  tag: Scalars['String'];
}>;


export type CreateOrderTagMutation = (
  { __typename?: 'Mutation' }
  & { createOrderTag: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'company_id' | 'tag'>
  ) }
);

export type CreatePlaceholderMutationVariables = Exact<{
  company: CompanyPlaceholder;
}>;


export type CreatePlaceholderMutation = (
  { __typename?: 'Mutation' }
  & { createPlaceholder: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'status' | 'type' | 'name' | 'logoPath' | 'taxNumber' | 'country_id'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'address' | 'postCode' | 'postName' | 'type' | 'status' | 'title' | 'geoLocation' | 'timezone'>
    )> }
  ) }
);

export type CreatePlaceholderLocationMutationVariables = Exact<{
  location: LocationCreate;
}>;


export type CreatePlaceholderLocationMutation = (
  { __typename?: 'Mutation' }
  & { createPlaceholderLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'type' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone' | 'status'>
  ) }
);

export type CreateTransportChangeRequestMutationVariables = Exact<{
  transportChangeRequest: TransportChangeRequestCreate;
}>;


export type CreateTransportChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { createTransportChangeRequest: (
    { __typename?: 'Transport' }
    & Pick<Transport, 'id' | 'requestedLoadingTime' | 'requestedUnloadingTime'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  user: UserCreate;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CreateVehicleMutationVariables = Exact<{
  vehicle: VehicleCreate;
}>;


export type CreateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { createVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
  ) }
);

export type DectivateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DectivateLocationMutation = (
  { __typename?: 'Mutation' }
  & { deactivateLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type DeactivateUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeactivateUserMutation = (
  { __typename?: 'Mutation' }
  & { deactivateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status'>
  ) }
);

export type DeclineBidMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeclineBidMutation = (
  { __typename?: 'Mutation' }
  & { declineBid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
  ) }
);

export type DeleteAssignedUserMutationVariables = Exact<{
  user_id: Scalars['Int'];
  id: Scalars['Int'];
  type?: Maybe<AssignedUserType>;
}>;


export type DeleteAssignedUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteAssignedUser: (
    { __typename?: 'Order' }
    & Pick<Order, 'status' | 'id'>
  ) }
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  ) }
);

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  ) }
);

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'status'>
  ) }
);

export type DeleteOrderTagMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteOrderTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrderTag: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'company_id' | 'tag'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status'>
  ) }
);

export type DeleteVehicleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteVehicleMutation = (
  { __typename?: 'Mutation' }
  & { deleteVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
  ) }
);

export type ExportQueryVariables = Exact<{
  options: ExportInput;
}>;


export type ExportQuery = (
  { __typename?: 'Query' }
  & { export: (
    { __typename?: 'ExportResponse' }
    & { orders?: Maybe<(
      { __typename?: 'ExportOrdersResponse' }
      & Pick<ExportOrdersResponse, 'csvPath'>
    )>, documents?: Maybe<(
      { __typename?: 'ExportDocumentsResponse' }
      & Pick<ExportDocumentsResponse, 'csvPath' | 'zipPath'>
    )> }
  ) }
);

export type FlagOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FlagOrderMutation = (
  { __typename?: 'Mutation' }
  & { flagOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'status'>
  ) }
);

export type GetActivityLogsQueryVariables = Exact<{
  query: ActivityLogQuery;
}>;


export type GetActivityLogsQuery = (
  { __typename?: 'Query' }
  & { getActivityLogs: (
    { __typename?: 'ActivityLogListing' }
    & Pick<ActivityLogListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'id' | 'status' | 'ts' | 'data' | 'text' | 'order_id' | 'transport_id' | 'transportState_id' | 'type'>
    )>> }
  ) }
);

export type GetAllTaxationClauseQueryVariables = Exact<{
  query: TaxationClauseQuery;
}>;


export type GetAllTaxationClauseQuery = (
  { __typename?: 'Query' }
  & { getAllTaxationClause: (
    { __typename?: 'TaxationClauseListing' }
    & Pick<TaxationClauseListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'TaxationClause' }
      & Pick<TaxationClause, 'id' | 'company_id' | 'clause' | 'vat' | 'isDefault'>
    )>> }
  ) }
);

export type GetCompaniesQueryVariables = Exact<{
  query: CompanyQuery;
}>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { getCompanies: (
    { __typename?: 'CompanyListing' }
    & Pick<CompanyListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoPath' | 'type' | 'contactEmail' | 'status'>
      & { insuranceCertificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path' | 'expiryDate'>
      )> }
    )>> }
  ) }
);

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'status' | 'type' | 'name' | 'taxNumber' | 'logoPath'>
    & { insuranceCertificate?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path' | 'expiryDate'>
    )> }
  ) }
);

export type GetCountriesQueryVariables = Exact<{
  query: CountryQuery;
}>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { getCountries: (
    { __typename?: 'CountryListing' }
    & Pick<CountryListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'phoneCode' | 'code'>
    )>> }
  ) }
);

export type GetCountryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCountryQuery = (
  { __typename?: 'Query' }
  & { getCountry: (
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'code' | 'phoneCode'>
  ) }
);

export type GetCurrenciesQueryVariables = Exact<{
  query: CurrencyQuery;
}>;


export type GetCurrenciesQuery = (
  { __typename?: 'Query' }
  & { getCurrencies: (
    { __typename?: 'CurrencyListing' }
    & Pick<CurrencyListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'name' | 'code' | 'symbol' | 'exchangeRate' | 'status'>
    )>> }
  ) }
);

export type GetCurrencyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCurrencyQuery = (
  { __typename?: 'Query' }
  & { getCurrency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'code' | 'symbol' | 'exchangeRate' | 'status'>
  ) }
);

export type GetDocumentsQueryVariables = Exact<{
  query: DocumentQuery;
}>;


export type GetDocumentsQuery = (
  { __typename?: 'Query' }
  & { getDocuments: (
    { __typename?: 'DocumentListing' }
    & Pick<DocumentListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'code' | 'status' | 'type' | 'company_id' | 'order_id' | 'order_code' | 'invoice_id' | 'date' | 'description' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'logoPath'>
      )> }
    )>> }
  ) }
);

export type GetDriversQueryVariables = Exact<{
  query: UserQuery;
  id: Scalars['Int'];
}>;


export type GetDriversQuery = (
  { __typename?: 'Query' }
  & { getDrivers: (
    { __typename?: 'DriverListing' }
    & Pick<DriverListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'status' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profileImageUrl' | 'token' | 'isExternalDriver' | 'createTime'>
      & { vehicleRegistration?: Maybe<Array<(
        { __typename?: 'VehicleRegistration' }
        & Pick<VehicleRegistration, 'reg'>
      )>>, roles?: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
      )>> }
    )>> }
  ) }
);

export type GetInvoiceDataQueryVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type GetInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { getInvoiceData: (
    { __typename?: 'InvoiceDataListing' }
    & { items?: Maybe<Array<(
      { __typename?: 'InvoiceData' }
      & Pick<InvoiceData, 'id' | 'status' | 'amount' | 'paymentDueDate' | 'payeeCompany_id' | 'document_id' | 'issuingCompany_id' | 'creator_id' | 'order_id' | 'currency_id'>
    )>> }
  ) }
);

export type GetInvoicePrefilledDataQueryVariables = Exact<{
  payeeId: Scalars['Float'];
  orderId: Scalars['Float'];
  orderCode?: Maybe<Scalars['String']>;
}>;


export type GetInvoicePrefilledDataQuery = (
  { __typename?: 'Query' }
  & { getInvoicePrefilledData: (
    { __typename?: 'InvoicePrefilledData' }
    & Pick<InvoicePrefilledData, 'amount' | 'invoiceNumber' | 'paymentDueDate'>
  ) }
);

export type GetInvoiceSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceSettingsQuery = (
  { __typename?: 'Query' }
  & { getInvoiceSettings: (
    { __typename?: 'InvoiceSettings' }
    & Pick<InvoiceSettings, 'company_id' | 'customPrefix' | 'addPayeePrefix' | 'addChronologicalSequence' | 'defaultDueDate'>
  ) }
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { getLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'status' | 'id' | 'type' | 'company_id' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name' | 'phoneCode' | 'code'>
    )> }
  ) }
);

export type GetLocationPointQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type GetLocationPointQuery = (
  { __typename?: 'Query' }
  & { getLocationPoint: (
    { __typename?: 'LocationPoint' }
    & Pick<LocationPoint, 'id' | 'transport_id' | 'location_id' | 'note' | 'completed' | 'order'>
  ) }
);

export type GetLocationsQueryVariables = Exact<{
  query: LocationQuery;
}>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { getLocations: (
    { __typename?: 'LocationListing' }
    & Pick<LocationListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'status' | 'id' | 'type' | 'company_id' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name' | 'phoneCode' | 'code'>
      )> }
    )>> }
  ) }
);

export type GetMarketOrdersQueryVariables = Exact<{
  query: OrderQuery;
}>;


export type GetMarketOrdersQuery = (
  { __typename?: 'Query' }
  & { getMarketOrders: (
    { __typename?: 'OrderListing' }
    & Pick<OrderListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'OrderListingItem' }
      & Pick<OrderListingItem, 'id' | 'code' | 'internalCode' | 'status' | 'marketExpirationDate' | 'loadingTime' | 'unloadingTime' | 'cargoType' | 'company_id' | 'dangerous' | 'stackable' | 'shipperCompany_id' | 'shipperCompany_name' | 'shipperCompany_logo' | 'receiverCompany_id' | 'receiverCompany_name' | 'receiverCompany_logo' | 'unloadingLocation_postName' | 'loadingLocation_postName' | 'vehicleRegistration' | 'bidCount' | 'requestedLoadingTime' | 'requestedUnloadingTime'>
      & { parcels?: Maybe<Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'quantity' | 'volume' | 'weight' | 'width' | 'length' | 'height'>
      )>> }
    )>> }
  ) }
);

export type GetMeQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'company_id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profileImageUrl' | 'password'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'status' | 'type' | 'location_id' | 'contactUser_id' | 'accountOwnerUser_id' | 'name' | 'taxNumber' | 'contactEmail' | 'IBAN' | 'BIC' | 'logoPath' | 'contactPhone'>
      & { insuranceCertificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path' | 'expiryDate'>
      )>, defaultContact?: Maybe<(
        { __typename?: 'CompanyContact' }
        & Pick<CompanyContact, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'type' | 'address' | 'postCode' | 'postName' | 'country_id' | 'geoLocation' | 'timezone'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name' | 'phoneCode' | 'code'>
        )> }
      )> }
    )>, roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'status' | 'id' | 'name'>
    )>>, modules?: Maybe<Array<(
      { __typename?: 'Module' }
      & Pick<Module, 'id' | 'name'>
    )>> }
  ) }
);

export type GetMessageCountQueryVariables = Exact<{
  order_id?: Maybe<Scalars['Int']>;
}>;


export type GetMessageCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMessageCount'>
);

export type GetMessagesQueryVariables = Exact<{
  query: MessageQuery;
}>;


export type GetMessagesQuery = (
  { __typename?: 'Query' }
  & { getMessages: (
    { __typename?: 'MessageListing' }
    & Pick<MessageListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'MessageListingItem' }
      & Pick<MessageListingItem, 'id' | 'status' | 'type' | 'text' | 'sendDate' | 'user_firstName' | 'user_lastName' | 'user_profileImageUrl'>
    )>> }
  ) }
);

export type GetModulePermissionsQueryVariables = Exact<{
  query: ModulePermissionQuery;
}>;


export type GetModulePermissionsQuery = (
  { __typename?: 'Query' }
  & { getModulePermissions: (
    { __typename?: 'ModulePermissionListing' }
    & Pick<ModulePermissionListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'ModulePermission' }
      & Pick<ModulePermission, 'module_id' | 'module_name' | 'company_id' | 'hasAccess'>
      & { companyInfo?: Maybe<Array<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'createTime' | 'contactEmail'>
      )>> }
    )>> }
  ) }
);

export type GetNotificationCountQueryVariables = Exact<{
  order_id?: Maybe<Scalars['Int']>;
}>;


export type GetNotificationCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationCount'>
);

export type GetNotificationMessagesQueryVariables = Exact<{
  query?: Maybe<NotificationMessageQuery>;
}>;


export type GetNotificationMessagesQuery = (
  { __typename?: 'Query' }
  & { getNotificationMessages: (
    { __typename?: 'NotificationMessageListing' }
    & Pick<NotificationMessageListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'NotificationMessageListingItem' }
      & Pick<NotificationMessageListingItem, 'status' | 'id' | 'user_id' | 'company_id' | 'title' | 'text' | 'label' | 'scheduleDate'>
      & { recipients?: Maybe<Array<(
        { __typename?: 'NotificationMessageItemRecipient' }
        & Pick<NotificationMessageItemRecipient, 'status' | 'user_id' | 'firstName' | 'lastName' | 'notification_id'>
      )>> }
    )>> }
  ) }
);

export type GetNotificationsQueryVariables = Exact<{
  query: NotificationQuery;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications: (
    { __typename?: 'NotificationListing' }
    & Pick<NotificationListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'NotificationListingItem' }
      & Pick<NotificationListingItem, 'status' | 'type' | 'id' | 'user_id' | 'company_id' | 'order_id' | 'date' | 'readDate' | 'title' | 'text' | 'data' | 'link' | 'actionCompany_id' | 'actionCompany_name' | 'actionCompany_logo' | 'actionUser_id' | 'actionUser_firstName' | 'actionUser_lastName' | 'actionUser_profileImageUrl' | 'dataCompany_id' | 'dataCompany_name' | 'dataCompany_logo' | 'dataUser_id' | 'dataUser_firstName' | 'dataUser_lastName' | 'dataUser_profileImageUrl' | 'accepted'>
    )>> }
  ) }
);

export type GetOrderQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'status' | 'company_id' | 'transporterCompany_internalCode' | 'code' | 'description' | 'transporterCompany_id' | 'internalCode' | 'price' | 'privateNotes' | 'marketExpirationDate' | 'groupIndex'>
    & { transports?: Maybe<Array<(
      { __typename?: 'Transport' }
      & Pick<Transport, 'id' | 'status' | 'type' | 'description' | 'shipperCompany_id' | 'receiverCompany_id' | 'loadingLocation_id' | 'unloadingLocation_id' | 'vehicle_id' | 'driverUser_id' | 'driverContact' | 'requestedLoadingTime' | 'requestedUnloadingTime' | 'loadingTime' | 'unloadingTime' | 'loadingContact' | 'unloadingContact' | 'notes' | 'cost' | 'cargoType' | 'dangerous' | 'stackable' | 'shipperCompany_internalCode' | 'receiverCompany_internalCode'>
      & { locationPoints?: Maybe<Array<(
        { __typename?: 'LocationPoint' }
        & Pick<LocationPoint, 'id' | 'transport_id' | 'loadingDate' | 'note' | 'completed' | 'order'>
        & { locationInfo?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'id' | 'address' | 'postCode' | 'postName' | 'timezone'>
          & { country?: Maybe<(
            { __typename?: 'Country' }
            & Pick<Country, 'name'>
          )> }
        )> }
      )>>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'registration' | 'id' | 'driverUser_id'>
      )>, parcels?: Maybe<Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'id' | 'weight' | 'volume' | 'quantity' | 'length' | 'width' | 'height'>
      )>>, shipperCompany?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'status' | 'type' | 'name' | 'taxNumber' | 'contactEmail' | 'logoPath'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'status' | 'id' | 'type' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'timezone'>
        )>, defaultContact?: Maybe<(
          { __typename?: 'CompanyContact' }
          & Pick<CompanyContact, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone'>
        )> }
      )>, receiverCompany?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'status' | 'type' | 'name' | 'contactEmail' | 'logoPath'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'status' | 'id' | 'type' | 'company_id' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone'>
        )>, defaultContact?: Maybe<(
          { __typename?: 'CompanyContact' }
          & Pick<CompanyContact, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone'>
        )> }
      )> }
    )>>, assignedUsers?: Maybe<Array<(
      { __typename?: 'AssignedUser' }
      & Pick<AssignedUser, 'id' | 'status' | 'company_id' | 'type' | 'user_id'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'email' | 'phone' | 'profileImageUrl'>
      )> }
    )>>, ownerCompany?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'status' | 'type' | 'country_id' | 'name' | 'contactEmail' | 'logoPath'>
      & { defaultContact?: Maybe<(
        { __typename?: 'CompanyContact' }
        & Pick<CompanyContact, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'status' | 'id' | 'type' | 'company_id' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone'>
      )> }
    )>, transporterCompany?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'status' | 'type' | 'country_id' | 'name' | 'taxNumber' | 'contactEmail' | 'IBAN' | 'BIC' | 'logoPath'>
      & { defaultContact?: Maybe<(
        { __typename?: 'CompanyContact' }
        & Pick<CompanyContact, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'status' | 'id' | 'type' | 'company_id' | 'title' | 'description' | 'address' | 'postCode' | 'postName' | 'country_id' | 'contactName' | 'phone' | 'email' | 'geoLocation' | 'notes' | 'timezone'>
      )> }
    )>, tags?: Maybe<Array<(
      { __typename?: 'OrderTag' }
      & Pick<OrderTag, 'id' | 'tag'>
    )>>, tagOrders?: Maybe<Array<(
      { __typename?: 'OrderListingItem' }
      & Pick<OrderListingItem, 'userRole' | 'status' | 'id' | 'code' | 'internalCode' | 'marketExpirationDate' | 'requestedLoadingTime' | 'requestedUnloadingTime' | 'driver_id' | 'shipperCompany_internalCode' | 'transporterCompany_internalCode' | 'receiverCompany_internalCode' | 'loadingTime' | 'unloadingTime' | 'transport_id' | 'vehicle_id' | 'dangerous' | 'stackable' | 'shipperCompany_id' | 'shipperCompany_name' | 'shipperCompany_logo' | 'receiverCompany_id' | 'receiverCompany_name' | 'receiverCompany_logo' | 'transporterCompany_id' | 'transporterCompany_name' | 'transporterCompany_logo' | 'unloadingLocation_postName' | 'unloadingLocation_postCode' | 'unloadingLocation_address' | 'unloadingLocation_country_id' | 'loadingLocation_postCode' | 'loadingLocation_postName' | 'loadingLocation_address' | 'loadingLocation_country_id' | 'vehicleRegistration' | 'bidCount' | 'owner_firstName' | 'owner_lastName' | 'owner_profileImageUrl' | 'loadingLocation_country_name' | 'unloadingLocation_country_name' | 'price' | 'hasPoD' | 'hasInvoice' | 'groupIndex'>
      & { dispatchers?: Maybe<Array<(
        { __typename?: 'Dispatcher' }
        & Pick<Dispatcher, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone' | 'type' | 'user_id'>
      )>>, parcels?: Maybe<Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'status' | 'id' | 'createTime' | 'updateTime' | 'createUser' | 'updateUser' | 'order_id' | 'transport_id' | 'weight' | 'volume' | 'quantity' | 'length' | 'width' | 'height'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'OrderTag' }
        & Pick<OrderTag, 'id' | 'tag'>
      )>>, transports?: Maybe<Array<(
        { __typename?: 'Transport' }
        & Pick<Transport, 'id' | 'status' | 'type'>
        & { locationPoints?: Maybe<Array<(
          { __typename?: 'LocationPoint' }
          & Pick<LocationPoint, 'id' | 'transport_id' | 'loadingDate' | 'note' | 'completed' | 'order'>
          & { locationInfo?: Maybe<(
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'address' | 'postCode' | 'postName' | 'timezone'>
            & { country?: Maybe<(
              { __typename?: 'Country' }
              & Pick<Country, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetOrderTagByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type GetOrderTagByIdQuery = (
  { __typename?: 'Query' }
  & { getOrderTagById: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'company_id' | 'tag'>
  ) }
);

export type GetOrderTagsQueryVariables = Exact<{
  query: OrderTagQuery;
}>;


export type GetOrderTagsQuery = (
  { __typename?: 'Query' }
  & { getOrderTags: (
    { __typename?: 'OrderTagListing' }
    & Pick<OrderTagListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'OrderTag' }
      & Pick<OrderTag, 'id' | 'company_id' | 'tag'>
    )>> }
  ) }
);

export type GetOrdersQueryVariables = Exact<{
  query: OrderQuery;
}>;


export type GetOrdersQuery = (
  { __typename?: 'Query' }
  & { getOrders: (
    { __typename?: 'OrderListing' }
    & Pick<OrderListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'OrderListingItem' }
      & Pick<OrderListingItem, 'userRole' | 'status' | 'id' | 'code' | 'internalCode' | 'marketExpirationDate' | 'requestedLoadingTime' | 'requestedUnloadingTime' | 'driver_id' | 'shipperCompany_internalCode' | 'transporterCompany_internalCode' | 'receiverCompany_internalCode' | 'loadingTime' | 'unloadingTime' | 'transport_id' | 'vehicle_id' | 'dangerous' | 'stackable' | 'shipperCompany_id' | 'shipperCompany_name' | 'shipperCompany_logo' | 'receiverCompany_id' | 'receiverCompany_name' | 'receiverCompany_logo' | 'transporterCompany_id' | 'transporterCompany_name' | 'transporterCompany_logo' | 'unloadingLocation_postName' | 'unloadingLocation_postCode' | 'unloadingLocation_address' | 'unloadingLocation_country_id' | 'loadingLocation_postCode' | 'loadingLocation_postName' | 'loadingLocation_address' | 'loadingLocation_country_id' | 'vehicleRegistration' | 'bidCount' | 'owner_firstName' | 'owner_lastName' | 'owner_profileImageUrl' | 'loadingLocation_country_name' | 'unloadingLocation_country_name' | 'price' | 'hasPoD' | 'hasInvoice' | 'groupIndex'>
      & { dispatchers?: Maybe<Array<(
        { __typename?: 'Dispatcher' }
        & Pick<Dispatcher, 'id' | 'firstName' | 'lastName' | 'profileImageUrl' | 'email' | 'phone' | 'type' | 'user_id'>
      )>>, parcels?: Maybe<Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'status' | 'id' | 'createTime' | 'updateTime' | 'createUser' | 'updateUser' | 'order_id' | 'transport_id' | 'weight' | 'volume' | 'quantity' | 'length' | 'width' | 'height'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'OrderTag' }
        & Pick<OrderTag, 'id' | 'tag'>
      )>> }
    )>> }
  ) }
);

export type GetOverlappedOrdersQueryVariables = Exact<{
  query: OrderQuery;
}>;


export type GetOverlappedOrdersQuery = (
  { __typename?: 'Query' }
  & { getOverlappedOrders: (
    { __typename?: 'OrderListing' }
    & Pick<OrderListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'OrderListingItem' }
      & Pick<OrderListingItem, 'id' | 'code' | 'internalCode' | 'status' | 'price' | 'marketExpirationDate' | 'requestedLoadingTime' | 'requestedUnloadingTime' | 'loadingTime' | 'unloadingTime' | 'transport_id'>
    )>> }
  ) }
);

export type GetPartnersQueryVariables = Exact<{
  query: CompanyQuery;
  id: Scalars['Int'];
}>;


export type GetPartnersQuery = (
  { __typename?: 'Query' }
  & { getPartners: (
    { __typename?: 'CompanyPartnerListing' }
    & Pick<CompanyPartnerListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'CompanyPartner' }
      & Pick<CompanyPartner, 'status' | 'id' | 'type' | 'name' | 'logoPath'>
    )>> }
  ) }
);

export type GetReceivedBidsQueryVariables = Exact<{
  query: BidQuery;
}>;


export type GetReceivedBidsQuery = (
  { __typename?: 'Query' }
  & { getReceivedBids: (
    { __typename?: 'BidListing' }
    & Pick<BidListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'BidListingItem' }
      & Pick<BidListingItem, 'id' | 'status' | 'order_id' | 'company_id' | 'company_name' | 'company_logo' | 'price' | 'expirationDate' | 'comment' | 'unloadingLocation_postName' | 'loadingLocation_postName' | 'requestedUnloadingTime' | 'requestedLoadingTime' | 'order_code'>
    )>> }
  ) }
);

export type GetTransportStatesQueryVariables = Exact<{
  query: TransportStateQuery;
}>;


export type GetTransportStatesQuery = (
  { __typename?: 'Query' }
  & { getTransportStates: (
    { __typename?: 'TransportStateListing' }
    & Pick<TransportStateListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'TransportState' }
      & Pick<TransportState, 'id' | 'status' | 'createTime' | 'updateTime' | 'createUser' | 'updateUser' | 'transport_id' | 'order_id' | 'type' | 'location_id' | 'geoLocation' | 'transporterCompany_id' | 'vehicle_id' | 'driverUser_id' | 'plannedTime' | 'executeTime' | 'notes' | 'extraCost'>
    )>> }
  ) }
);

export type GetTransportsQueryVariables = Exact<{
  query: TransportQuery;
}>;


export type GetTransportsQuery = (
  { __typename?: 'Query' }
  & { getTransports: (
    { __typename?: 'TransportListing' }
    & Pick<TransportListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Transport' }
      & Pick<Transport, 'order_id' | 'vehicle_id' | 'driverUser_id'>
    )>> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'status' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profileImageUrl'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'taxNumber' | 'contactEmail' | 'contactUser_id' | 'IBAN' | 'BIC' | 'type' | 'logoPath' | 'contactPhone'>
      & { insuranceCertificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path' | 'expiryDate'>
      )> }
    )> }
  ) }
);

export type GetUserNotificationSettingsQueryVariables = Exact<{
  type?: Maybe<NotificationType>;
}>;


export type GetUserNotificationSettingsQuery = (
  { __typename?: 'Query' }
  & { getUserNotificationSettings?: Maybe<Array<(
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'type' | 'channel' | 'user_id'>
  )>> }
);

export type GetUsersQueryVariables = Exact<{
  query: UserQuery;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: (
    { __typename?: 'UserListing' }
    & Pick<UserListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'status' | 'firstName' | 'lastName' | 'email' | 'phone' | 'profileImageUrl' | 'token'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
      )>> }
    )>> }
  ) }
);

export type GetVehicleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetVehicleQuery = (
  { __typename?: 'Query' }
  & { getVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'status' | 'type' | 'registration' | 'geoLocation' | 'description' | 'maxCargoVolume' | 'maxCargoWeight'>
  ) }
);

export type GetVehiclesQueryVariables = Exact<{
  query: VehicleQuery;
}>;


export type GetVehiclesQuery = (
  { __typename?: 'Query' }
  & { getVehicles: (
    { __typename?: 'VehicleListing' }
    & Pick<VehicleListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'status' | 'id' | 'type' | 'registration' | 'maxCargoVolume' | 'maxCargoWeight' | 'geoLocation' | 'createTime' | 'description' | 'driverUser_id'>
      & { driver?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    )>> }
  ) }
);

export type InviteCompanyMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
}>;


export type InviteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteCompany'>
);

export type LoginMutationVariables = Exact<{
  userLogin: UserLogin;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'token'>
  ) }
);

export type NewMessageSubscriptionVariables = Exact<{
  order_id: Scalars['Int'];
}>;


export type NewMessageSubscription = (
  { __typename?: 'Subscription' }
  & { newMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'status' | 'id' | 'user_id' | 'type' | 'text' | 'sendDate' | 'company_id' | 'order_id'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'profileImageUrl'>
    )> }
  ) }
);

export type NewNotificationSubscriptionVariables = Exact<{
  user_id: Scalars['Int'];
}>;


export type NewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newNotification: (
    { __typename?: 'NotificationListingItem' }
    & Pick<NotificationListingItem, 'status' | 'type' | 'id' | 'user_id' | 'company_id' | 'order_id' | 'date' | 'readDate' | 'title' | 'text' | 'data' | 'link' | 'actionCompany_id' | 'actionCompany_name' | 'actionCompany_logo' | 'actionUser_id' | 'actionUser_firstName' | 'actionUser_lastName' | 'actionUser_profileImageUrl' | 'dataCompany_id' | 'dataCompany_name' | 'dataCompany_logo' | 'dataUser_id' | 'dataUser_firstName' | 'dataUser_lastName' | 'dataUser_profileImageUrl'>
  ) }
);

export type GetPlacedBidsQueryVariables = Exact<{
  query: BidQuery;
}>;


export type GetPlacedBidsQuery = (
  { __typename?: 'Query' }
  & { getPlacedBids: (
    { __typename?: 'BidListing' }
    & Pick<BidListing, 'total'>
    & { items?: Maybe<Array<(
      { __typename?: 'BidListingItem' }
      & Pick<BidListingItem, 'id' | 'status' | 'order_id' | 'company_id' | 'company_name' | 'company_logo' | 'price' | 'expirationDate' | 'comment' | 'unloadingLocation_postName' | 'loadingLocation_postName' | 'requestedUnloadingTime' | 'requestedLoadingTime' | 'order_code'>
    )>> }
  ) }
);

export type ReadAllNotificationsMutationVariables = Exact<{
  company_id?: Maybe<Scalars['Int']>;
}>;


export type ReadAllNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readAllNotifications'>
);

export type MarkReadNotificationMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type MarkReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { markReadNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, 'type' | 'id' | 'status'>
  ) }
);

export type RegisterUserMutationVariables = Exact<{
  inviteToken: Scalars['String'];
  user: UserCreate;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type RegisterUserAndCompanyMutationVariables = Exact<{
  company: CompanyCreate;
  user: UserCreate;
  insuranceCertificate?: Maybe<DocumentCreate>;
}>;


export type RegisterUserAndCompanyMutation = (
  { __typename?: 'Mutation' }
  & { registerUserAndCompany: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
      & { insuranceCertificate?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path'>
      )> }
    )> }
  ) }
);

export type RemoveOrderTagMutationVariables = Exact<{
  tag_id: Scalars['Int'];
  order_id: Scalars['Int'];
}>;


export type RemoveOrderTagMutation = (
  { __typename?: 'Mutation' }
  & { removeOrderTag: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'tag'>
  ) }
);

export type RemoveTaxationClauseMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveTaxationClauseMutation = (
  { __typename?: 'Mutation' }
  & { removeTaxationClause: (
    { __typename?: 'TaxationClause' }
    & Pick<TaxationClause, 'id'>
  ) }
);

export type ReplaceInsuranceDocumentMutationVariables = Exact<{
  document: DocumentCreate;
  id: Scalars['Int'];
}>;


export type ReplaceInsuranceDocumentMutation = (
  { __typename?: 'Mutation' }
  & { replaceInsuranceDocument: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'fileName' | 'fileSize' | 'fileType' | 'fileUrl' | 'path'>
  ) }
);

export type RequestEmailChangeMutationVariables = Exact<{
  newEmail: Scalars['String'];
  email: Scalars['String'];
}>;


export type RequestEmailChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestEmailChange'>
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordReset'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type RespondToChangeRequestMutationVariables = Exact<{
  response: RespondToChangeRequest;
}>;


export type RespondToChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & { respondToChangeRequest: (
    { __typename?: 'Transport' }
    & Pick<Transport, 'id'>
  ) }
);

export type SetAllNotificationChannelMutationVariables = Exact<{
  user_id?: Maybe<Scalars['Int']>;
  channel: NotificationChannel;
}>;


export type SetAllNotificationChannelMutation = (
  { __typename?: 'Mutation' }
  & { setAllNotificationChannel: Array<(
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'user_id'>
  )> }
);

export type SetNotificationChannelMutationVariables = Exact<{
  user_id?: Maybe<Scalars['Int']>;
  channel: NotificationChannel;
  type: NotificationType;
}>;


export type SetNotificationChannelMutation = (
  { __typename?: 'Mutation' }
  & { setNotificationChannel: Array<(
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'user_id'>
  )> }
);

export type UpdateBidMutationVariables = Exact<{
  bid: BidUpdate;
}>;


export type UpdateBidMutation = (
  { __typename?: 'Mutation' }
  & { updateBid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  CompanyUpdate: CompanyUpdate;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type UpdateDocumentExpiryDateMutationVariables = Exact<{
  expiryDate: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateDocumentExpiryDateMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentExpiryDate: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'expiryDate'>
  ) }
);

export type UpdateInternalMutationVariables = Exact<{
  code: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateInternalMutation = (
  { __typename?: 'Mutation' }
  & { updateInternal: (
    { __typename?: 'Order' }
    & Pick<Order, 'internalCode'>
  ) }
);

export type UpdateInvoiceDataStatusMutationVariables = Exact<{
  status: InvoiceDataStatus;
  id: Scalars['Float'];
}>;


export type UpdateInvoiceDataStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceDataStatus: (
    { __typename?: 'InvoiceData' }
    & Pick<InvoiceData, 'status'>
  ) }
);

export type UpdateInvoiceSettingsMutationVariables = Exact<{
  invoiceSettings: UpdateInvoiceSettings;
}>;


export type UpdateInvoiceSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceSettings: (
    { __typename?: 'InvoiceSettings' }
    & Pick<InvoiceSettings, 'customPrefix' | 'addPayeePrefix' | 'addChronologicalSequence' | 'defaultDueDate'>
  ) }
);

export type UpdateLocationMutationVariables = Exact<{
  location: LocationUpdate;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type UpdateLocationPointCompletedStateMutationVariables = Exact<{
  completedValue: Scalars['Boolean'];
  id: Scalars['Float'];
}>;


export type UpdateLocationPointCompletedStateMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationPointCompletedState: (
    { __typename?: 'LocationPoint' }
    & Pick<LocationPoint, 'id' | 'completed'>
  ) }
);

export type UpdateLocationPointLoadingDateMutationVariables = Exact<{
  loadingDate: Scalars['String'];
  id: Scalars['Float'];
}>;


export type UpdateLocationPointLoadingDateMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationPointLoadingDate: (
    { __typename?: 'LocationPoint' }
    & Pick<LocationPoint, 'id' | 'loadingDate'>
  ) }
);

export type UpdateMessageMutationVariables = Exact<{
  message: MessageUpdate;
}>;


export type UpdateMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  ) }
);

export type UpdateModulePermissionMutationVariables = Exact<{
  hasAccess: Scalars['Boolean'];
  companyId: Scalars['Int'];
  moduleId: Scalars['Int'];
}>;


export type UpdateModulePermissionMutation = (
  { __typename?: 'Mutation' }
  & { updateModulePermission: (
    { __typename?: 'ModulePermission' }
    & Pick<ModulePermission, 'module_id'>
  ) }
);

export type UpdateNotificationMessageMutationVariables = Exact<{
  data?: Maybe<UpdateNotificationMessage>;
  id?: Maybe<Scalars['Int']>;
}>;


export type UpdateNotificationMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationMessage: (
    { __typename?: 'NotificationMessageListingItem' }
    & Pick<NotificationMessageListingItem, 'status' | 'id' | 'user_id' | 'company_id' | 'title' | 'text' | 'label' | 'scheduleDate'>
    & { recipients?: Maybe<Array<(
      { __typename?: 'NotificationMessageItemRecipient' }
      & Pick<NotificationMessageItemRecipient, 'status' | 'user_id' | 'firstName' | 'lastName' | 'notification_id'>
    )>> }
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  order: OrderUpdate;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type UpdateOrderStatusMutationVariables = Exact<{
  status: OrderStatus;
  id: Scalars['Int'];
}>;


export type UpdateOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderStatus: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'code'>
  ) }
);

export type UpdateOrderTagMutationVariables = Exact<{
  tag: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateOrderTagMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderTag: (
    { __typename?: 'OrderTag' }
    & Pick<OrderTag, 'id' | 'company_id' | 'tag'>
  ) }
);

export type UpdateTagMutationVariables = Exact<{
  order: OrderUpdateTag;
}>;


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type UpdateTaxationClauseMutationVariables = Exact<{
  taxationClause: UpdateTaxationClause;
}>;


export type UpdateTaxationClauseMutation = (
  { __typename?: 'Mutation' }
  & { updateTaxationClause: (
    { __typename?: 'TaxationClause' }
    & Pick<TaxationClause, 'clause' | 'vat' | 'isDefault'>
  ) }
);

export type UpdateTransportMutationVariables = Exact<{
  transport: TransportUpdate;
}>;


export type UpdateTransportMutation = (
  { __typename?: 'Mutation' }
  & { updateTransport: (
    { __typename?: 'Transport' }
    & Pick<Transport, 'id'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  user: UserUpdate;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateVehicleMutationVariables = Exact<{
  vehicle: VehicleUpdate;
}>;


export type UpdateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
  ) }
);

export type InviteUserMutationVariables = Exact<{
  invite: CompanyInvite;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUser'>
);


export const AcceptBidDocument = gql`
    mutation acceptBid($id: Int!) {
  acceptBid(id: $id) {
    id
  }
}
    `;
export type AcceptBidMutationFn = Apollo.MutationFunction<AcceptBidMutation, AcceptBidMutationVariables>;

/**
 * __useAcceptBidMutation__
 *
 * To run a mutation, you first call `useAcceptBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBidMutation, { data, loading, error }] = useAcceptBidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptBidMutation(baseOptions?: Apollo.MutationHookOptions<AcceptBidMutation, AcceptBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptBidMutation, AcceptBidMutationVariables>(AcceptBidDocument, options);
      }
export type AcceptBidMutationHookResult = ReturnType<typeof useAcceptBidMutation>;
export type AcceptBidMutationResult = Apollo.MutationResult<AcceptBidMutation>;
export type AcceptBidMutationOptions = Apollo.BaseMutationOptions<AcceptBidMutation, AcceptBidMutationVariables>;
export const ActivateCompanyDocument = gql`
    mutation activateCompany($token: String!) {
  activateCompanyToken(token: $token) {
    id
  }
}
    `;
export type ActivateCompanyMutationFn = Apollo.MutationFunction<ActivateCompanyMutation, ActivateCompanyMutationVariables>;

/**
 * __useActivateCompanyMutation__
 *
 * To run a mutation, you first call `useActivateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanyMutation, { data, loading, error }] = useActivateCompanyMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCompanyMutation, ActivateCompanyMutationVariables>(ActivateCompanyDocument, options);
      }
export type ActivateCompanyMutationHookResult = ReturnType<typeof useActivateCompanyMutation>;
export type ActivateCompanyMutationResult = Apollo.MutationResult<ActivateCompanyMutation>;
export type ActivateCompanyMutationOptions = Apollo.BaseMutationOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>;
export const ActivateLocationDocument = gql`
    mutation activateLocation($id: Int!) {
  activateLocation(id: $id) {
    id
  }
}
    `;
export type ActivateLocationMutationFn = Apollo.MutationFunction<ActivateLocationMutation, ActivateLocationMutationVariables>;

/**
 * __useActivateLocationMutation__
 *
 * To run a mutation, you first call `useActivateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLocationMutation, { data, loading, error }] = useActivateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateLocationMutation(baseOptions?: Apollo.MutationHookOptions<ActivateLocationMutation, ActivateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateLocationMutation, ActivateLocationMutationVariables>(ActivateLocationDocument, options);
      }
export type ActivateLocationMutationHookResult = ReturnType<typeof useActivateLocationMutation>;
export type ActivateLocationMutationResult = Apollo.MutationResult<ActivateLocationMutation>;
export type ActivateLocationMutationOptions = Apollo.BaseMutationOptions<ActivateLocationMutation, ActivateLocationMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token) {
    id
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const AddLocationPointsDocument = gql`
    mutation addLocationPoints($locationPoints: AddLocationPoints!) {
  addLocationPoints(locationPoints: $locationPoints) {
    location_id
    order
    note
  }
}
    `;
export type AddLocationPointsMutationFn = Apollo.MutationFunction<AddLocationPointsMutation, AddLocationPointsMutationVariables>;

/**
 * __useAddLocationPointsMutation__
 *
 * To run a mutation, you first call `useAddLocationPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationPointsMutation, { data, loading, error }] = useAddLocationPointsMutation({
 *   variables: {
 *      locationPoints: // value for 'locationPoints'
 *   },
 * });
 */
export function useAddLocationPointsMutation(baseOptions?: Apollo.MutationHookOptions<AddLocationPointsMutation, AddLocationPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLocationPointsMutation, AddLocationPointsMutationVariables>(AddLocationPointsDocument, options);
      }
export type AddLocationPointsMutationHookResult = ReturnType<typeof useAddLocationPointsMutation>;
export type AddLocationPointsMutationResult = Apollo.MutationResult<AddLocationPointsMutation>;
export type AddLocationPointsMutationOptions = Apollo.BaseMutationOptions<AddLocationPointsMutation, AddLocationPointsMutationVariables>;
export const AddOrderTagDocument = gql`
    mutation addOrderTag($tag_id: Int!, $order_id: Int!) {
  addOrderTag(tag_id: $tag_id, order_id: $order_id) {
    id
    tag
  }
}
    `;
export type AddOrderTagMutationFn = Apollo.MutationFunction<AddOrderTagMutation, AddOrderTagMutationVariables>;

/**
 * __useAddOrderTagMutation__
 *
 * To run a mutation, you first call `useAddOrderTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderTagMutation, { data, loading, error }] = useAddOrderTagMutation({
 *   variables: {
 *      tag_id: // value for 'tag_id'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useAddOrderTagMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderTagMutation, AddOrderTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderTagMutation, AddOrderTagMutationVariables>(AddOrderTagDocument, options);
      }
export type AddOrderTagMutationHookResult = ReturnType<typeof useAddOrderTagMutation>;
export type AddOrderTagMutationResult = Apollo.MutationResult<AddOrderTagMutation>;
export type AddOrderTagMutationOptions = Apollo.BaseMutationOptions<AddOrderTagMutation, AddOrderTagMutationVariables>;
export const AddTaxationClauseDocument = gql`
    mutation addTaxationClause($taxationClause: AddTaxationClause!) {
  addTaxationClause(taxationClause: $taxationClause) {
    clause
    vat
  }
}
    `;
export type AddTaxationClauseMutationFn = Apollo.MutationFunction<AddTaxationClauseMutation, AddTaxationClauseMutationVariables>;

/**
 * __useAddTaxationClauseMutation__
 *
 * To run a mutation, you first call `useAddTaxationClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaxationClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaxationClauseMutation, { data, loading, error }] = useAddTaxationClauseMutation({
 *   variables: {
 *      taxationClause: // value for 'taxationClause'
 *   },
 * });
 */
export function useAddTaxationClauseMutation(baseOptions?: Apollo.MutationHookOptions<AddTaxationClauseMutation, AddTaxationClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTaxationClauseMutation, AddTaxationClauseMutationVariables>(AddTaxationClauseDocument, options);
      }
export type AddTaxationClauseMutationHookResult = ReturnType<typeof useAddTaxationClauseMutation>;
export type AddTaxationClauseMutationResult = Apollo.MutationResult<AddTaxationClauseMutation>;
export type AddTaxationClauseMutationOptions = Apollo.BaseMutationOptions<AddTaxationClauseMutation, AddTaxationClauseMutationVariables>;
export const AssignDriverDocument = gql`
    mutation assignDriver($driver_id: Int!, $driver_contact: String!, $order_id: Int!, $id: Int!, $vehicle_id: Int) {
  assignDriver(
    driver_id: $driver_id
    driver_contact: $driver_contact
    order_id: $order_id
    id: $id
    vehicle_id: $vehicle_id
  ) {
    id
  }
}
    `;
export type AssignDriverMutationFn = Apollo.MutationFunction<AssignDriverMutation, AssignDriverMutationVariables>;

/**
 * __useAssignDriverMutation__
 *
 * To run a mutation, you first call `useAssignDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDriverMutation, { data, loading, error }] = useAssignDriverMutation({
 *   variables: {
 *      driver_id: // value for 'driver_id'
 *      driver_contact: // value for 'driver_contact'
 *      order_id: // value for 'order_id'
 *      id: // value for 'id'
 *      vehicle_id: // value for 'vehicle_id'
 *   },
 * });
 */
export function useAssignDriverMutation(baseOptions?: Apollo.MutationHookOptions<AssignDriverMutation, AssignDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignDriverMutation, AssignDriverMutationVariables>(AssignDriverDocument, options);
      }
export type AssignDriverMutationHookResult = ReturnType<typeof useAssignDriverMutation>;
export type AssignDriverMutationResult = Apollo.MutationResult<AssignDriverMutation>;
export type AssignDriverMutationOptions = Apollo.BaseMutationOptions<AssignDriverMutation, AssignDriverMutationVariables>;
export const AssignUserDocument = gql`
    mutation assignUser($type: AssignedUserType!, $user_id: Int!, $id: Int!) {
  assignUser(type: $type, user_id: $user_id, id: $id) {
    status
    id
  }
}
    `;
export type AssignUserMutationFn = Apollo.MutationFunction<AssignUserMutation, AssignUserMutationVariables>;

/**
 * __useAssignUserMutation__
 *
 * To run a mutation, you first call `useAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserMutation, { data, loading, error }] = useAssignUserMutation({
 *   variables: {
 *      type: // value for 'type'
 *      user_id: // value for 'user_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignUserMutation(baseOptions?: Apollo.MutationHookOptions<AssignUserMutation, AssignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUserMutation, AssignUserMutationVariables>(AssignUserDocument, options);
      }
export type AssignUserMutationHookResult = ReturnType<typeof useAssignUserMutation>;
export type AssignUserMutationResult = Apollo.MutationResult<AssignUserMutation>;
export type AssignUserMutationOptions = Apollo.BaseMutationOptions<AssignUserMutation, AssignUserMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($token: String!) {
  changeEmail(token: $token) {
    id
  }
}
    `;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, options);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ChangeRoleDocument = gql`
    mutation changeRole($id: Int!, $role_id: Int!) {
  changeRole(id: $id, role_id: $role_id) {
    id
  }
}
    `;
export type ChangeRoleMutationFn = Apollo.MutationFunction<ChangeRoleMutation, ChangeRoleMutationVariables>;

/**
 * __useChangeRoleMutation__
 *
 * To run a mutation, you first call `useChangeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoleMutation, { data, loading, error }] = useChangeRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role_id: // value for 'role_id'
 *   },
 * });
 */
export function useChangeRoleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRoleMutation, ChangeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeRoleMutation, ChangeRoleMutationVariables>(ChangeRoleDocument, options);
      }
export type ChangeRoleMutationHookResult = ReturnType<typeof useChangeRoleMutation>;
export type ChangeRoleMutationResult = Apollo.MutationResult<ChangeRoleMutation>;
export type ChangeRoleMutationOptions = Apollo.BaseMutationOptions<ChangeRoleMutation, ChangeRoleMutationVariables>;
export const ChangeVehicleDocument = gql`
    mutation changeVehicle($vehicle_id: Int, $order_id: Int!, $id: Int!, $driver_contact: String) {
  changeVehicle(
    vehicle_id: $vehicle_id
    order_id: $order_id
    id: $id
    driver_contact: $driver_contact
  ) {
    vehicle_id
  }
}
    `;
export type ChangeVehicleMutationFn = Apollo.MutationFunction<ChangeVehicleMutation, ChangeVehicleMutationVariables>;

/**
 * __useChangeVehicleMutation__
 *
 * To run a mutation, you first call `useChangeVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVehicleMutation, { data, loading, error }] = useChangeVehicleMutation({
 *   variables: {
 *      vehicle_id: // value for 'vehicle_id'
 *      order_id: // value for 'order_id'
 *      id: // value for 'id'
 *      driver_contact: // value for 'driver_contact'
 *   },
 * });
 */
export function useChangeVehicleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVehicleMutation, ChangeVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVehicleMutation, ChangeVehicleMutationVariables>(ChangeVehicleDocument, options);
      }
export type ChangeVehicleMutationHookResult = ReturnType<typeof useChangeVehicleMutation>;
export type ChangeVehicleMutationResult = Apollo.MutationResult<ChangeVehicleMutation>;
export type ChangeVehicleMutationOptions = Apollo.BaseMutationOptions<ChangeVehicleMutation, ChangeVehicleMutationVariables>;
export const ConfirmDocumentDocument = gql`
    mutation confirmDocument($id: Int!) {
  confirmDocument(id: $id) {
    id
  }
}
    `;
export type ConfirmDocumentMutationFn = Apollo.MutationFunction<ConfirmDocumentMutation, ConfirmDocumentMutationVariables>;

/**
 * __useConfirmDocumentMutation__
 *
 * To run a mutation, you first call `useConfirmDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDocumentMutation, { data, loading, error }] = useConfirmDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmDocumentMutation, ConfirmDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmDocumentMutation, ConfirmDocumentMutationVariables>(ConfirmDocumentDocument, options);
      }
export type ConfirmDocumentMutationHookResult = ReturnType<typeof useConfirmDocumentMutation>;
export type ConfirmDocumentMutationResult = Apollo.MutationResult<ConfirmDocumentMutation>;
export type ConfirmDocumentMutationOptions = Apollo.BaseMutationOptions<ConfirmDocumentMutation, ConfirmDocumentMutationVariables>;
export const CreateBidDocument = gql`
    mutation createBid($bid: BidCreate!) {
  createBid(bid: $bid) {
    id
  }
}
    `;
export type CreateBidMutationFn = Apollo.MutationFunction<CreateBidMutation, CreateBidMutationVariables>;

/**
 * __useCreateBidMutation__
 *
 * To run a mutation, you first call `useCreateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBidMutation, { data, loading, error }] = useCreateBidMutation({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useCreateBidMutation(baseOptions?: Apollo.MutationHookOptions<CreateBidMutation, CreateBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBidMutation, CreateBidMutationVariables>(CreateBidDocument, options);
      }
export type CreateBidMutationHookResult = ReturnType<typeof useCreateBidMutation>;
export type CreateBidMutationResult = Apollo.MutationResult<CreateBidMutation>;
export type CreateBidMutationOptions = Apollo.BaseMutationOptions<CreateBidMutation, CreateBidMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation createDocument($document: DocumentCreate!) {
  createDocument(document: $document) {
    id
    fileName
    fileSize
    fileType
    fileUrl
    path
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation createFeedback($feedback: FeedbackCreate!) {
  createFeedback(feedback: $feedback) {
    id
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($location: LocationCreate!) {
  createLocation(location: $location) {
    id
    timezone
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateMessageDocument = gql`
    mutation createMessage($message: MessageCreate!) {
  createMessage(message: $message) {
    id
    company_id
    order_id
    sendDate
    status
    text
    type
    user_id
    user {
      firstName
      lastName
      profileImageUrl
    }
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const CreateModulePermissionDocument = gql`
    mutation createModulePermission($hasAccess: Boolean!, $companyId: Int!, $moduleId: Int!) {
  createModulePermission(
    hasAccess: $hasAccess
    companyId: $companyId
    moduleId: $moduleId
  ) {
    module_id
  }
}
    `;
export type CreateModulePermissionMutationFn = Apollo.MutationFunction<CreateModulePermissionMutation, CreateModulePermissionMutationVariables>;

/**
 * __useCreateModulePermissionMutation__
 *
 * To run a mutation, you first call `useCreateModulePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModulePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModulePermissionMutation, { data, loading, error }] = useCreateModulePermissionMutation({
 *   variables: {
 *      hasAccess: // value for 'hasAccess'
 *      companyId: // value for 'companyId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useCreateModulePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateModulePermissionMutation, CreateModulePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModulePermissionMutation, CreateModulePermissionMutationVariables>(CreateModulePermissionDocument, options);
      }
export type CreateModulePermissionMutationHookResult = ReturnType<typeof useCreateModulePermissionMutation>;
export type CreateModulePermissionMutationResult = Apollo.MutationResult<CreateModulePermissionMutation>;
export type CreateModulePermissionMutationOptions = Apollo.BaseMutationOptions<CreateModulePermissionMutation, CreateModulePermissionMutationVariables>;
export const CreateNotificationMessageDocument = gql`
    mutation createNotificationMessage($data: CreateNotificationMessage) {
  createNotificationMessage(data: $data) {
    status
    recipients {
      status
      user_id
      firstName
      lastName
      notification_id
    }
    id
    user_id
    company_id
    title
    text
    label
    scheduleDate
  }
}
    `;
export type CreateNotificationMessageMutationFn = Apollo.MutationFunction<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>;

/**
 * __useCreateNotificationMessageMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMessageMutation, { data, loading, error }] = useCreateNotificationMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>(CreateNotificationMessageDocument, options);
      }
export type CreateNotificationMessageMutationHookResult = ReturnType<typeof useCreateNotificationMessageMutation>;
export type CreateNotificationMessageMutationResult = Apollo.MutationResult<CreateNotificationMessageMutation>;
export type CreateNotificationMessageMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($transport: TransportCreateWO, $order: OrderCreate!, $locationPoints: [LocationPointItem!]) {
  createOrder(
    transport: $transport
    order: $order
    locationPoints: $locationPoints
  ) {
    id
    status
    company_id
    code
    description
    internalCode
    price
    privateNotes
    marketExpirationDate
    transports {
      order_id
      id
      status
      type
      shipperCompany_id
      receiverCompany_id
      loadingContact
      unloadingContact
      loadingContact
      cargoType
      parcels {
        weight
        volume
        quantity
        length
        width
        height
      }
      dangerous
      stackable
      loadingLocation_id
      unloadingLocation_id
      requestedUnloadingTime
      requestedLoadingTime
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      transport: // value for 'transport'
 *      order: // value for 'order'
 *      locationPoints: // value for 'locationPoints'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateOrderTagDocument = gql`
    mutation createOrderTag($tag: String!) {
  createOrderTag(tag: $tag) {
    id
    company_id
    tag
  }
}
    `;
export type CreateOrderTagMutationFn = Apollo.MutationFunction<CreateOrderTagMutation, CreateOrderTagMutationVariables>;

/**
 * __useCreateOrderTagMutation__
 *
 * To run a mutation, you first call `useCreateOrderTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderTagMutation, { data, loading, error }] = useCreateOrderTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateOrderTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderTagMutation, CreateOrderTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderTagMutation, CreateOrderTagMutationVariables>(CreateOrderTagDocument, options);
      }
export type CreateOrderTagMutationHookResult = ReturnType<typeof useCreateOrderTagMutation>;
export type CreateOrderTagMutationResult = Apollo.MutationResult<CreateOrderTagMutation>;
export type CreateOrderTagMutationOptions = Apollo.BaseMutationOptions<CreateOrderTagMutation, CreateOrderTagMutationVariables>;
export const CreatePlaceholderDocument = gql`
    mutation createPlaceholder($company: CompanyPlaceholder!) {
  createPlaceholder(company: $company) {
    id
    status
    type
    location {
      id
      address
      postCode
      postName
      type
      status
      title
      geoLocation
      timezone
    }
    name
    logoPath
    taxNumber
    country_id
  }
}
    `;
export type CreatePlaceholderMutationFn = Apollo.MutationFunction<CreatePlaceholderMutation, CreatePlaceholderMutationVariables>;

/**
 * __useCreatePlaceholderMutation__
 *
 * To run a mutation, you first call `useCreatePlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceholderMutation, { data, loading, error }] = useCreatePlaceholderMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreatePlaceholderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceholderMutation, CreatePlaceholderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceholderMutation, CreatePlaceholderMutationVariables>(CreatePlaceholderDocument, options);
      }
export type CreatePlaceholderMutationHookResult = ReturnType<typeof useCreatePlaceholderMutation>;
export type CreatePlaceholderMutationResult = Apollo.MutationResult<CreatePlaceholderMutation>;
export type CreatePlaceholderMutationOptions = Apollo.BaseMutationOptions<CreatePlaceholderMutation, CreatePlaceholderMutationVariables>;
export const CreatePlaceholderLocationDocument = gql`
    mutation createPlaceholderLocation($location: LocationCreate!) {
  createPlaceholderLocation(location: $location) {
    id
    type
    title
    description
    address
    postCode
    postName
    country_id
    contactName
    phone
    email
    geoLocation
    notes
    timezone
    status
  }
}
    `;
export type CreatePlaceholderLocationMutationFn = Apollo.MutationFunction<CreatePlaceholderLocationMutation, CreatePlaceholderLocationMutationVariables>;

/**
 * __useCreatePlaceholderLocationMutation__
 *
 * To run a mutation, you first call `useCreatePlaceholderLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceholderLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceholderLocationMutation, { data, loading, error }] = useCreatePlaceholderLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCreatePlaceholderLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceholderLocationMutation, CreatePlaceholderLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceholderLocationMutation, CreatePlaceholderLocationMutationVariables>(CreatePlaceholderLocationDocument, options);
      }
export type CreatePlaceholderLocationMutationHookResult = ReturnType<typeof useCreatePlaceholderLocationMutation>;
export type CreatePlaceholderLocationMutationResult = Apollo.MutationResult<CreatePlaceholderLocationMutation>;
export type CreatePlaceholderLocationMutationOptions = Apollo.BaseMutationOptions<CreatePlaceholderLocationMutation, CreatePlaceholderLocationMutationVariables>;
export const CreateTransportChangeRequestDocument = gql`
    mutation createTransportChangeRequest($transportChangeRequest: TransportChangeRequestCreate!) {
  createTransportChangeRequest(transportChangeRequest: $transportChangeRequest) {
    id
    requestedLoadingTime
    requestedUnloadingTime
  }
}
    `;
export type CreateTransportChangeRequestMutationFn = Apollo.MutationFunction<CreateTransportChangeRequestMutation, CreateTransportChangeRequestMutationVariables>;

/**
 * __useCreateTransportChangeRequestMutation__
 *
 * To run a mutation, you first call `useCreateTransportChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransportChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransportChangeRequestMutation, { data, loading, error }] = useCreateTransportChangeRequestMutation({
 *   variables: {
 *      transportChangeRequest: // value for 'transportChangeRequest'
 *   },
 * });
 */
export function useCreateTransportChangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransportChangeRequestMutation, CreateTransportChangeRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransportChangeRequestMutation, CreateTransportChangeRequestMutationVariables>(CreateTransportChangeRequestDocument, options);
      }
export type CreateTransportChangeRequestMutationHookResult = ReturnType<typeof useCreateTransportChangeRequestMutation>;
export type CreateTransportChangeRequestMutationResult = Apollo.MutationResult<CreateTransportChangeRequestMutation>;
export type CreateTransportChangeRequestMutationOptions = Apollo.BaseMutationOptions<CreateTransportChangeRequestMutation, CreateTransportChangeRequestMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($user: UserCreate!) {
  createUser(user: $user) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateVehicleDocument = gql`
    mutation createVehicle($vehicle: VehicleCreate!) {
  createVehicle(vehicle: $vehicle) {
    id
  }
}
    `;
export type CreateVehicleMutationFn = Apollo.MutationFunction<CreateVehicleMutation, CreateVehicleMutationVariables>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleMutation, CreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleMutation, CreateVehicleMutationVariables>(CreateVehicleDocument, options);
      }
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const DectivateLocationDocument = gql`
    mutation dectivateLocation($id: Int!) {
  deactivateLocation(id: $id) {
    id
  }
}
    `;
export type DectivateLocationMutationFn = Apollo.MutationFunction<DectivateLocationMutation, DectivateLocationMutationVariables>;

/**
 * __useDectivateLocationMutation__
 *
 * To run a mutation, you first call `useDectivateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDectivateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dectivateLocationMutation, { data, loading, error }] = useDectivateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDectivateLocationMutation(baseOptions?: Apollo.MutationHookOptions<DectivateLocationMutation, DectivateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DectivateLocationMutation, DectivateLocationMutationVariables>(DectivateLocationDocument, options);
      }
export type DectivateLocationMutationHookResult = ReturnType<typeof useDectivateLocationMutation>;
export type DectivateLocationMutationResult = Apollo.MutationResult<DectivateLocationMutation>;
export type DectivateLocationMutationOptions = Apollo.BaseMutationOptions<DectivateLocationMutation, DectivateLocationMutationVariables>;
export const DeactivateUserDocument = gql`
    mutation deactivateUser($id: Int!) {
  deactivateUser(id: $id) {
    id
    status
  }
}
    `;
export type DeactivateUserMutationFn = Apollo.MutationFunction<DeactivateUserMutation, DeactivateUserMutationVariables>;

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateUserMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserMutation, DeactivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument, options);
      }
export type DeactivateUserMutationHookResult = ReturnType<typeof useDeactivateUserMutation>;
export type DeactivateUserMutationResult = Apollo.MutationResult<DeactivateUserMutation>;
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<DeactivateUserMutation, DeactivateUserMutationVariables>;
export const DeclineBidDocument = gql`
    mutation declineBid($id: Int!) {
  declineBid(id: $id) {
    id
  }
}
    `;
export type DeclineBidMutationFn = Apollo.MutationFunction<DeclineBidMutation, DeclineBidMutationVariables>;

/**
 * __useDeclineBidMutation__
 *
 * To run a mutation, you first call `useDeclineBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBidMutation, { data, loading, error }] = useDeclineBidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineBidMutation(baseOptions?: Apollo.MutationHookOptions<DeclineBidMutation, DeclineBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineBidMutation, DeclineBidMutationVariables>(DeclineBidDocument, options);
      }
export type DeclineBidMutationHookResult = ReturnType<typeof useDeclineBidMutation>;
export type DeclineBidMutationResult = Apollo.MutationResult<DeclineBidMutation>;
export type DeclineBidMutationOptions = Apollo.BaseMutationOptions<DeclineBidMutation, DeclineBidMutationVariables>;
export const DeleteAssignedUserDocument = gql`
    mutation deleteAssignedUser($user_id: Int!, $id: Int!, $type: AssignedUserType) {
  deleteAssignedUser(user_id: $user_id, id: $id, type: $type) {
    status
    id
  }
}
    `;
export type DeleteAssignedUserMutationFn = Apollo.MutationFunction<DeleteAssignedUserMutation, DeleteAssignedUserMutationVariables>;

/**
 * __useDeleteAssignedUserMutation__
 *
 * To run a mutation, you first call `useDeleteAssignedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignedUserMutation, { data, loading, error }] = useDeleteAssignedUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteAssignedUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssignedUserMutation, DeleteAssignedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssignedUserMutation, DeleteAssignedUserMutationVariables>(DeleteAssignedUserDocument, options);
      }
export type DeleteAssignedUserMutationHookResult = ReturnType<typeof useDeleteAssignedUserMutation>;
export type DeleteAssignedUserMutationResult = Apollo.MutationResult<DeleteAssignedUserMutation>;
export type DeleteAssignedUserMutationOptions = Apollo.BaseMutationOptions<DeleteAssignedUserMutation, DeleteAssignedUserMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: Int!) {
  deleteDocument(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation deleteMessage($id: Int!) {
  deleteMessage(id: $id) {
    id
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: Int!) {
  deleteOrder(id: $id) {
    status
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const DeleteOrderTagDocument = gql`
    mutation deleteOrderTag($id: Float!) {
  deleteOrderTag(id: $id) {
    id
    company_id
    tag
  }
}
    `;
export type DeleteOrderTagMutationFn = Apollo.MutationFunction<DeleteOrderTagMutation, DeleteOrderTagMutationVariables>;

/**
 * __useDeleteOrderTagMutation__
 *
 * To run a mutation, you first call `useDeleteOrderTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderTagMutation, { data, loading, error }] = useDeleteOrderTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderTagMutation, DeleteOrderTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderTagMutation, DeleteOrderTagMutationVariables>(DeleteOrderTagDocument, options);
      }
export type DeleteOrderTagMutationHookResult = ReturnType<typeof useDeleteOrderTagMutation>;
export type DeleteOrderTagMutationResult = Apollo.MutationResult<DeleteOrderTagMutation>;
export type DeleteOrderTagMutationOptions = Apollo.BaseMutationOptions<DeleteOrderTagMutation, DeleteOrderTagMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Int!) {
  deleteUser(id: $id) {
    id
    status
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteVehicleDocument = gql`
    mutation deleteVehicle($id: Int!) {
  deleteVehicle(id: $id) {
    id
  }
}
    `;
export type DeleteVehicleMutationFn = Apollo.MutationFunction<DeleteVehicleMutation, DeleteVehicleMutationVariables>;

/**
 * __useDeleteVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleMutation, { data, loading, error }] = useDeleteVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleMutation, DeleteVehicleMutationVariables>(DeleteVehicleDocument, options);
      }
export type DeleteVehicleMutationHookResult = ReturnType<typeof useDeleteVehicleMutation>;
export type DeleteVehicleMutationResult = Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleMutation, DeleteVehicleMutationVariables>;
export const ExportDocument = gql`
    query export($options: ExportInput!) {
  export(options: $options) {
    orders {
      csvPath
    }
    documents {
      csvPath
      zipPath
    }
  }
}
    `;

/**
 * __useExportQuery__
 *
 * To run a query within a React component, call `useExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useExportQuery(baseOptions: Apollo.QueryHookOptions<ExportQuery, ExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportQuery, ExportQueryVariables>(ExportDocument, options);
      }
export function useExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportQuery, ExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportQuery, ExportQueryVariables>(ExportDocument, options);
        }
export type ExportQueryHookResult = ReturnType<typeof useExportQuery>;
export type ExportLazyQueryHookResult = ReturnType<typeof useExportLazyQuery>;
export type ExportQueryResult = Apollo.QueryResult<ExportQuery, ExportQueryVariables>;
export const FlagOrderDocument = gql`
    mutation flagOrder($id: Int!) {
  flagOrder(id: $id) {
    status
  }
}
    `;
export type FlagOrderMutationFn = Apollo.MutationFunction<FlagOrderMutation, FlagOrderMutationVariables>;

/**
 * __useFlagOrderMutation__
 *
 * To run a mutation, you first call `useFlagOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagOrderMutation, { data, loading, error }] = useFlagOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFlagOrderMutation(baseOptions?: Apollo.MutationHookOptions<FlagOrderMutation, FlagOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FlagOrderMutation, FlagOrderMutationVariables>(FlagOrderDocument, options);
      }
export type FlagOrderMutationHookResult = ReturnType<typeof useFlagOrderMutation>;
export type FlagOrderMutationResult = Apollo.MutationResult<FlagOrderMutation>;
export type FlagOrderMutationOptions = Apollo.BaseMutationOptions<FlagOrderMutation, FlagOrderMutationVariables>;
export const GetActivityLogsDocument = gql`
    query getActivityLogs($query: ActivityLogQuery!) {
  getActivityLogs(query: $query) {
    items {
      id
      status
      ts
      data
      text
      order_id
      transport_id
      transportState_id
      type
    }
    total
  }
}
    `;

/**
 * __useGetActivityLogsQuery__
 *
 * To run a query within a React component, call `useGetActivityLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetActivityLogsQuery(baseOptions: Apollo.QueryHookOptions<GetActivityLogsQuery, GetActivityLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogsQuery, GetActivityLogsQueryVariables>(GetActivityLogsDocument, options);
      }
export function useGetActivityLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogsQuery, GetActivityLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogsQuery, GetActivityLogsQueryVariables>(GetActivityLogsDocument, options);
        }
export type GetActivityLogsQueryHookResult = ReturnType<typeof useGetActivityLogsQuery>;
export type GetActivityLogsLazyQueryHookResult = ReturnType<typeof useGetActivityLogsLazyQuery>;
export type GetActivityLogsQueryResult = Apollo.QueryResult<GetActivityLogsQuery, GetActivityLogsQueryVariables>;
export const GetAllTaxationClauseDocument = gql`
    query getAllTaxationClause($query: TaxationClauseQuery!) {
  getAllTaxationClause(query: $query) {
    items {
      id
      company_id
      clause
      vat
      isDefault
    }
    total
  }
}
    `;

/**
 * __useGetAllTaxationClauseQuery__
 *
 * To run a query within a React component, call `useGetAllTaxationClauseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTaxationClauseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTaxationClauseQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetAllTaxationClauseQuery(baseOptions: Apollo.QueryHookOptions<GetAllTaxationClauseQuery, GetAllTaxationClauseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTaxationClauseQuery, GetAllTaxationClauseQueryVariables>(GetAllTaxationClauseDocument, options);
      }
export function useGetAllTaxationClauseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTaxationClauseQuery, GetAllTaxationClauseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTaxationClauseQuery, GetAllTaxationClauseQueryVariables>(GetAllTaxationClauseDocument, options);
        }
export type GetAllTaxationClauseQueryHookResult = ReturnType<typeof useGetAllTaxationClauseQuery>;
export type GetAllTaxationClauseLazyQueryHookResult = ReturnType<typeof useGetAllTaxationClauseLazyQuery>;
export type GetAllTaxationClauseQueryResult = Apollo.QueryResult<GetAllTaxationClauseQuery, GetAllTaxationClauseQueryVariables>;
export const GetCompaniesDocument = gql`
    query getCompanies($query: CompanyQuery!) {
  getCompanies(query: $query) {
    items {
      id
      name
      logoPath
      type
      contactEmail
      status
      insuranceCertificate {
        id
        fileName
        fileSize
        fileType
        fileUrl
        path
        expiryDate
      }
    }
    total
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($id: Int!) {
  getCompany(id: $id) {
    id
    status
    type
    name
    taxNumber
    logoPath
    insuranceCertificate {
      id
      fileName
      fileSize
      fileType
      fileUrl
      path
      expiryDate
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCountriesDocument = gql`
    query getCountries($query: CountryQuery!) {
  getCountries(query: $query) {
    items {
      id
      name
      phoneCode
      code
    }
    total
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCountryDocument = gql`
    query getCountry($id: Int!) {
  getCountry(id: $id) {
    id
    name
    code
    phoneCode
  }
}
    `;

/**
 * __useGetCountryQuery__
 *
 * To run a query within a React component, call `useGetCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountryQuery(baseOptions: Apollo.QueryHookOptions<GetCountryQuery, GetCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryQuery, GetCountryQueryVariables>(GetCountryDocument, options);
      }
export function useGetCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryQuery, GetCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryQuery, GetCountryQueryVariables>(GetCountryDocument, options);
        }
export type GetCountryQueryHookResult = ReturnType<typeof useGetCountryQuery>;
export type GetCountryLazyQueryHookResult = ReturnType<typeof useGetCountryLazyQuery>;
export type GetCountryQueryResult = Apollo.QueryResult<GetCountryQuery, GetCountryQueryVariables>;
export const GetCurrenciesDocument = gql`
    query getCurrencies($query: CurrencyQuery!) {
  getCurrencies(query: $query) {
    items {
      id
      name
      code
      symbol
      exchangeRate
      status
    }
    total
  }
}
    `;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCurrenciesQuery(baseOptions: Apollo.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesQueryResult = Apollo.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const GetCurrencyDocument = gql`
    query getCurrency($id: Int!) {
  getCurrency(id: $id) {
    id
    name
    code
    symbol
    exchangeRate
    status
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
      }
export function useGetCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencyQueryResult = Apollo.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const GetDocumentsDocument = gql`
    query getDocuments($query: DocumentQuery!) {
  getDocuments(query: $query) {
    items {
      id
      code
      status
      type
      company_id
      order_id
      order_code
      invoice_id
      date
      description
      fileName
      fileSize
      fileType
      fileUrl
      path
      company {
        id
        name
        logoPath
      }
    }
    total
  }
}
    `;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const GetDriversDocument = gql`
    query getDrivers($query: UserQuery!, $id: Int!) {
  getDrivers(query: $query, id: $id) {
    items {
      id
      status
      firstName
      lastName
      email
      phone
      profileImageUrl
      token
      isExternalDriver
      vehicleRegistration {
        reg
      }
      roles {
        id
      }
      createTime
    }
    total
  }
}
    `;

/**
 * __useGetDriversQuery__
 *
 * To run a query within a React component, call `useGetDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDriversQuery(baseOptions: Apollo.QueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
      }
export function useGetDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
        }
export type GetDriversQueryHookResult = ReturnType<typeof useGetDriversQuery>;
export type GetDriversLazyQueryHookResult = ReturnType<typeof useGetDriversLazyQuery>;
export type GetDriversQueryResult = Apollo.QueryResult<GetDriversQuery, GetDriversQueryVariables>;
export const GetInvoiceDataDocument = gql`
    query getInvoiceData($orderId: Float!) {
  getInvoiceData(orderId: $orderId) {
    items {
      id
      status
      amount
      paymentDueDate
      payeeCompany_id
      document_id
      issuingCompany_id
      creator_id
      order_id
      currency_id
    }
  }
}
    `;

/**
 * __useGetInvoiceDataQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDataQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetInvoiceDataQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceDataQuery, GetInvoiceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceDataQuery, GetInvoiceDataQueryVariables>(GetInvoiceDataDocument, options);
      }
export function useGetInvoiceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDataQuery, GetInvoiceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceDataQuery, GetInvoiceDataQueryVariables>(GetInvoiceDataDocument, options);
        }
export type GetInvoiceDataQueryHookResult = ReturnType<typeof useGetInvoiceDataQuery>;
export type GetInvoiceDataLazyQueryHookResult = ReturnType<typeof useGetInvoiceDataLazyQuery>;
export type GetInvoiceDataQueryResult = Apollo.QueryResult<GetInvoiceDataQuery, GetInvoiceDataQueryVariables>;
export const GetInvoicePrefilledDataDocument = gql`
    query getInvoicePrefilledData($payeeId: Float!, $orderId: Float!, $orderCode: String) {
  getInvoicePrefilledData(
    payeeId: $payeeId
    orderId: $orderId
    orderCode: $orderCode
  ) {
    amount
    invoiceNumber
    paymentDueDate
  }
}
    `;

/**
 * __useGetInvoicePrefilledDataQuery__
 *
 * To run a query within a React component, call `useGetInvoicePrefilledDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePrefilledDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePrefilledDataQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *      orderId: // value for 'orderId'
 *      orderCode: // value for 'orderCode'
 *   },
 * });
 */
export function useGetInvoicePrefilledDataQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicePrefilledDataQuery, GetInvoicePrefilledDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicePrefilledDataQuery, GetInvoicePrefilledDataQueryVariables>(GetInvoicePrefilledDataDocument, options);
      }
export function useGetInvoicePrefilledDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicePrefilledDataQuery, GetInvoicePrefilledDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicePrefilledDataQuery, GetInvoicePrefilledDataQueryVariables>(GetInvoicePrefilledDataDocument, options);
        }
export type GetInvoicePrefilledDataQueryHookResult = ReturnType<typeof useGetInvoicePrefilledDataQuery>;
export type GetInvoicePrefilledDataLazyQueryHookResult = ReturnType<typeof useGetInvoicePrefilledDataLazyQuery>;
export type GetInvoicePrefilledDataQueryResult = Apollo.QueryResult<GetInvoicePrefilledDataQuery, GetInvoicePrefilledDataQueryVariables>;
export const GetInvoiceSettingsDocument = gql`
    query getInvoiceSettings {
  getInvoiceSettings {
    company_id
    customPrefix
    addPayeePrefix
    addChronologicalSequence
    defaultDueDate
  }
}
    `;

/**
 * __useGetInvoiceSettingsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceSettingsQuery, GetInvoiceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceSettingsQuery, GetInvoiceSettingsQueryVariables>(GetInvoiceSettingsDocument, options);
      }
export function useGetInvoiceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceSettingsQuery, GetInvoiceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceSettingsQuery, GetInvoiceSettingsQueryVariables>(GetInvoiceSettingsDocument, options);
        }
export type GetInvoiceSettingsQueryHookResult = ReturnType<typeof useGetInvoiceSettingsQuery>;
export type GetInvoiceSettingsLazyQueryHookResult = ReturnType<typeof useGetInvoiceSettingsLazyQuery>;
export type GetInvoiceSettingsQueryResult = Apollo.QueryResult<GetInvoiceSettingsQuery, GetInvoiceSettingsQueryVariables>;
export const GetLocationDocument = gql`
    query getLocation($id: Int!) {
  getLocation(id: $id) {
    status
    id
    type
    company_id
    title
    description
    address
    postCode
    postName
    country_id
    contactName
    phone
    email
    geoLocation
    notes
    timezone
    country {
      id
      name
      phoneCode
      code
    }
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetLocationPointDocument = gql`
    query getLocationPoint($id: Float!) {
  getLocationPoint(id: $id) {
    id
    transport_id
    location_id
    note
    completed
    order
  }
}
    `;

/**
 * __useGetLocationPointQuery__
 *
 * To run a query within a React component, call `useGetLocationPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationPointQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationPointQuery(baseOptions: Apollo.QueryHookOptions<GetLocationPointQuery, GetLocationPointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationPointQuery, GetLocationPointQueryVariables>(GetLocationPointDocument, options);
      }
export function useGetLocationPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationPointQuery, GetLocationPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationPointQuery, GetLocationPointQueryVariables>(GetLocationPointDocument, options);
        }
export type GetLocationPointQueryHookResult = ReturnType<typeof useGetLocationPointQuery>;
export type GetLocationPointLazyQueryHookResult = ReturnType<typeof useGetLocationPointLazyQuery>;
export type GetLocationPointQueryResult = Apollo.QueryResult<GetLocationPointQuery, GetLocationPointQueryVariables>;
export const GetLocationsDocument = gql`
    query getLocations($query: LocationQuery!) {
  getLocations(query: $query) {
    items {
      status
      id
      type
      company_id
      title
      description
      address
      postCode
      postName
      country_id
      contactName
      phone
      email
      geoLocation
      notes
      timezone
      country {
        id
        name
        phoneCode
        code
      }
    }
    total
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetMarketOrdersDocument = gql`
    query getMarketOrders($query: OrderQuery!) {
  getMarketOrders(query: $query) {
    items {
      id
      code
      internalCode
      status
      marketExpirationDate
      loadingTime
      unloadingTime
      cargoType
      company_id
      parcels {
        quantity
        volume
        weight
        width
        length
        height
      }
      dangerous
      stackable
      shipperCompany_id
      shipperCompany_name
      shipperCompany_logo
      receiverCompany_id
      receiverCompany_name
      receiverCompany_logo
      unloadingLocation_postName
      loadingLocation_postName
      vehicleRegistration
      bidCount
      requestedLoadingTime
      requestedUnloadingTime
    }
    total
  }
}
    `;

/**
 * __useGetMarketOrdersQuery__
 *
 * To run a query within a React component, call `useGetMarketOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetMarketOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMarketOrdersQuery, GetMarketOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketOrdersQuery, GetMarketOrdersQueryVariables>(GetMarketOrdersDocument, options);
      }
export function useGetMarketOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketOrdersQuery, GetMarketOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketOrdersQuery, GetMarketOrdersQueryVariables>(GetMarketOrdersDocument, options);
        }
export type GetMarketOrdersQueryHookResult = ReturnType<typeof useGetMarketOrdersQuery>;
export type GetMarketOrdersLazyQueryHookResult = ReturnType<typeof useGetMarketOrdersLazyQuery>;
export type GetMarketOrdersQueryResult = Apollo.QueryResult<GetMarketOrdersQuery, GetMarketOrdersQueryVariables>;
export const GetMeDocument = gql`
    query GetMe($id: Int) {
  getUser(id: $id) {
    id
    status
    company_id
    firstName
    lastName
    company {
      id
      status
      insuranceCertificate {
        id
        fileName
        fileSize
        fileType
        fileUrl
        path
        expiryDate
      }
      defaultContact {
        id
        firstName
        lastName
        profileImageUrl
        email
        phone
      }
      location {
        id
        type
        address
        postCode
        postName
        country_id
        geoLocation
        timezone
        country {
          id
          name
          phoneCode
          code
        }
      }
      type
      location_id
      contactUser_id
      accountOwnerUser_id
      name
      taxNumber
      contactEmail
      IBAN
      BIC
      logoPath
      contactPhone
    }
    email
    phone
    profileImageUrl
    password
    roles {
      status
      id
      name
    }
    modules {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMessageCountDocument = gql`
    query getMessageCount($order_id: Int) {
  getMessageCount(order_id: $order_id)
}
    `;

/**
 * __useGetMessageCountQuery__
 *
 * To run a query within a React component, call `useGetMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageCountQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useGetMessageCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMessageCountQuery, GetMessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageCountQuery, GetMessageCountQueryVariables>(GetMessageCountDocument, options);
      }
export function useGetMessageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageCountQuery, GetMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageCountQuery, GetMessageCountQueryVariables>(GetMessageCountDocument, options);
        }
export type GetMessageCountQueryHookResult = ReturnType<typeof useGetMessageCountQuery>;
export type GetMessageCountLazyQueryHookResult = ReturnType<typeof useGetMessageCountLazyQuery>;
export type GetMessageCountQueryResult = Apollo.QueryResult<GetMessageCountQuery, GetMessageCountQueryVariables>;
export const GetMessagesDocument = gql`
    query GetMessages($query: MessageQuery!) {
  getMessages(query: $query) {
    items {
      id
      status
      type
      text
      sendDate
      user_firstName
      user_lastName
      user_profileImageUrl
    }
    total
  }
}
    `;

/**
 * __useGetMessagesQuery__
 *
 * To run a query within a React component, call `useGetMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
      }
export function useGetMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesQuery, GetMessagesQueryVariables>(GetMessagesDocument, options);
        }
export type GetMessagesQueryHookResult = ReturnType<typeof useGetMessagesQuery>;
export type GetMessagesLazyQueryHookResult = ReturnType<typeof useGetMessagesLazyQuery>;
export type GetMessagesQueryResult = Apollo.QueryResult<GetMessagesQuery, GetMessagesQueryVariables>;
export const GetModulePermissionsDocument = gql`
    query getModulePermissions($query: ModulePermissionQuery!) {
  getModulePermissions(query: $query) {
    items {
      module_id
      module_name
      company_id
      hasAccess
      companyInfo {
        id
        name
        createTime
        contactEmail
      }
    }
    total
  }
}
    `;

/**
 * __useGetModulePermissionsQuery__
 *
 * To run a query within a React component, call `useGetModulePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulePermissionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetModulePermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetModulePermissionsQuery, GetModulePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulePermissionsQuery, GetModulePermissionsQueryVariables>(GetModulePermissionsDocument, options);
      }
export function useGetModulePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulePermissionsQuery, GetModulePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulePermissionsQuery, GetModulePermissionsQueryVariables>(GetModulePermissionsDocument, options);
        }
export type GetModulePermissionsQueryHookResult = ReturnType<typeof useGetModulePermissionsQuery>;
export type GetModulePermissionsLazyQueryHookResult = ReturnType<typeof useGetModulePermissionsLazyQuery>;
export type GetModulePermissionsQueryResult = Apollo.QueryResult<GetModulePermissionsQuery, GetModulePermissionsQueryVariables>;
export const GetNotificationCountDocument = gql`
    query getNotificationCount($order_id: Int) {
  getNotificationCount(order_id: $order_id)
}
    `;

/**
 * __useGetNotificationCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCountQuery({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useGetNotificationCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationCountQuery, GetNotificationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationCountQuery, GetNotificationCountQueryVariables>(GetNotificationCountDocument, options);
      }
export function useGetNotificationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationCountQuery, GetNotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationCountQuery, GetNotificationCountQueryVariables>(GetNotificationCountDocument, options);
        }
export type GetNotificationCountQueryHookResult = ReturnType<typeof useGetNotificationCountQuery>;
export type GetNotificationCountLazyQueryHookResult = ReturnType<typeof useGetNotificationCountLazyQuery>;
export type GetNotificationCountQueryResult = Apollo.QueryResult<GetNotificationCountQuery, GetNotificationCountQueryVariables>;
export const GetNotificationMessagesDocument = gql`
    query getNotificationMessages($query: NotificationMessageQuery) {
  getNotificationMessages(query: $query) {
    items {
      status
      recipients {
        status
        user_id
        firstName
        lastName
        notification_id
      }
      id
      user_id
      company_id
      title
      text
      label
      scheduleDate
    }
    total
  }
}
    `;

/**
 * __useGetNotificationMessagesQuery__
 *
 * To run a query within a React component, call `useGetNotificationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationMessagesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetNotificationMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationMessagesQuery, GetNotificationMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationMessagesQuery, GetNotificationMessagesQueryVariables>(GetNotificationMessagesDocument, options);
      }
export function useGetNotificationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationMessagesQuery, GetNotificationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationMessagesQuery, GetNotificationMessagesQueryVariables>(GetNotificationMessagesDocument, options);
        }
export type GetNotificationMessagesQueryHookResult = ReturnType<typeof useGetNotificationMessagesQuery>;
export type GetNotificationMessagesLazyQueryHookResult = ReturnType<typeof useGetNotificationMessagesLazyQuery>;
export type GetNotificationMessagesQueryResult = Apollo.QueryResult<GetNotificationMessagesQuery, GetNotificationMessagesQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($query: NotificationQuery!) {
  getNotifications(query: $query) {
    items {
      status
      type
      id
      user_id
      company_id
      order_id
      date
      readDate
      title
      text
      data
      link
      actionCompany_id
      actionCompany_name
      actionCompany_logo
      actionUser_id
      actionUser_firstName
      actionUser_lastName
      actionUser_profileImageUrl
      dataCompany_id
      dataCompany_name
      dataCompany_logo
      dataUser_id
      dataUser_firstName
      dataUser_lastName
      dataUser_profileImageUrl
      accepted
    }
    total
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($code: String, $id: Int) {
  getOrder(code: $code, id: $id) {
    id
    status
    company_id
    transporterCompany_internalCode
    transports {
      id
      status
      type
      locationPoints {
        id
        transport_id
        loadingDate
        locationInfo {
          id
          address
          postCode
          postName
          timezone
          country {
            name
          }
        }
        note
        completed
        order
      }
      description
      shipperCompany_id
      receiverCompany_id
      loadingLocation_id
      unloadingLocation_id
      vehicle_id
      vehicle {
        registration
        id
        driverUser_id
      }
      driverUser_id
      driverContact
      requestedLoadingTime
      requestedUnloadingTime
      loadingTime
      unloadingTime
      loadingContact
      unloadingContact
      notes
      cost
      cargoType
      parcels {
        id
        weight
        volume
        quantity
        length
        width
        height
      }
      dangerous
      stackable
      shipperCompany_internalCode
      receiverCompany_internalCode
      shipperCompany {
        id
        status
        type
        location {
          status
          id
          type
          title
          description
          address
          postCode
          postName
          country_id
          contactName
          phone
          email
          geoLocation
          timezone
        }
        name
        taxNumber
        contactEmail
        logoPath
        defaultContact {
          id
          firstName
          lastName
          profileImageUrl
          email
          phone
        }
      }
      receiverCompany {
        id
        status
        type
        location {
          status
          id
          type
          company_id
          title
          description
          address
          postCode
          postName
          country_id
          contactName
          phone
          email
          geoLocation
          notes
          timezone
        }
        name
        contactEmail
        logoPath
        defaultContact {
          id
          firstName
          lastName
          profileImageUrl
          email
          phone
        }
      }
    }
    code
    description
    assignedUsers {
      id
      status
      company_id
      type
      user_id
      user {
        firstName
        lastName
        email
        phone
        profileImageUrl
      }
    }
    transporterCompany_id
    ownerCompany {
      id
      status
      type
      defaultContact {
        id
        firstName
        lastName
        profileImageUrl
        email
        phone
      }
      location {
        status
        id
        type
        company_id
        title
        description
        address
        postCode
        postName
        country_id
        contactName
        phone
        email
        geoLocation
        notes
        timezone
      }
      country_id
      name
      contactEmail
      logoPath
    }
    transporterCompany {
      id
      status
      type
      defaultContact {
        id
        firstName
        lastName
        profileImageUrl
        email
        phone
      }
      location {
        status
        id
        type
        company_id
        title
        description
        address
        postCode
        postName
        country_id
        contactName
        phone
        email
        geoLocation
        notes
        timezone
      }
      country_id
      name
      taxNumber
      contactEmail
      IBAN
      BIC
      logoPath
    }
    internalCode
    price
    privateNotes
    marketExpirationDate
    groupIndex
    tags {
      id
      tag
    }
    tagOrders {
      userRole
      status
      id
      code
      internalCode
      marketExpirationDate
      requestedLoadingTime
      requestedUnloadingTime
      driver_id
      dispatchers {
        id
        firstName
        lastName
        profileImageUrl
        email
        phone
        type
        user_id
      }
      parcels {
        status
        id
        createTime
        updateTime
        createUser
        updateUser
        order_id
        transport_id
        weight
        volume
        quantity
        length
        width
        height
      }
      tags {
        id
        tag
      }
      shipperCompany_internalCode
      transporterCompany_internalCode
      receiverCompany_internalCode
      loadingTime
      unloadingTime
      transport_id
      vehicle_id
      dangerous
      stackable
      shipperCompany_id
      shipperCompany_name
      shipperCompany_logo
      receiverCompany_id
      receiverCompany_name
      receiverCompany_logo
      transporterCompany_id
      transporterCompany_name
      transporterCompany_logo
      unloadingLocation_postName
      unloadingLocation_postCode
      unloadingLocation_address
      unloadingLocation_country_id
      loadingLocation_postCode
      loadingLocation_postName
      loadingLocation_address
      loadingLocation_country_id
      vehicleRegistration
      bidCount
      owner_firstName
      owner_lastName
      owner_profileImageUrl
      loadingLocation_country_name
      unloadingLocation_country_name
      price
      hasPoD
      hasInvoice
      groupIndex
      transports {
        id
        status
        type
        locationPoints {
          id
          transport_id
          loadingDate
          locationInfo {
            id
            address
            postCode
            postName
            timezone
            country {
              name
            }
          }
          note
          completed
          order
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrderTagByIdDocument = gql`
    query getOrderTagById($id: Float!) {
  getOrderTagById(id: $id) {
    id
    company_id
    tag
  }
}
    `;

/**
 * __useGetOrderTagByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderTagByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTagByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTagByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderTagByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderTagByIdQuery, GetOrderTagByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderTagByIdQuery, GetOrderTagByIdQueryVariables>(GetOrderTagByIdDocument, options);
      }
export function useGetOrderTagByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTagByIdQuery, GetOrderTagByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderTagByIdQuery, GetOrderTagByIdQueryVariables>(GetOrderTagByIdDocument, options);
        }
export type GetOrderTagByIdQueryHookResult = ReturnType<typeof useGetOrderTagByIdQuery>;
export type GetOrderTagByIdLazyQueryHookResult = ReturnType<typeof useGetOrderTagByIdLazyQuery>;
export type GetOrderTagByIdQueryResult = Apollo.QueryResult<GetOrderTagByIdQuery, GetOrderTagByIdQueryVariables>;
export const GetOrderTagsDocument = gql`
    query getOrderTags($query: OrderTagQuery!) {
  getOrderTags(query: $query) {
    items {
      id
      company_id
      tag
    }
    total
  }
}
    `;

/**
 * __useGetOrderTagsQuery__
 *
 * To run a query within a React component, call `useGetOrderTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTagsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetOrderTagsQuery(baseOptions: Apollo.QueryHookOptions<GetOrderTagsQuery, GetOrderTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderTagsQuery, GetOrderTagsQueryVariables>(GetOrderTagsDocument, options);
      }
export function useGetOrderTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTagsQuery, GetOrderTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderTagsQuery, GetOrderTagsQueryVariables>(GetOrderTagsDocument, options);
        }
export type GetOrderTagsQueryHookResult = ReturnType<typeof useGetOrderTagsQuery>;
export type GetOrderTagsLazyQueryHookResult = ReturnType<typeof useGetOrderTagsLazyQuery>;
export type GetOrderTagsQueryResult = Apollo.QueryResult<GetOrderTagsQuery, GetOrderTagsQueryVariables>;
export const GetOrdersDocument = gql`
    query getOrders($query: OrderQuery!) {
  getOrders(query: $query) {
    items {
      userRole
      status
      id
      code
      internalCode
      marketExpirationDate
      requestedLoadingTime
      requestedUnloadingTime
      driver_id
      dispatchers {
        id
        firstName
        lastName
        profileImageUrl
        email
        phone
        type
        user_id
      }
      parcels {
        status
        id
        createTime
        updateTime
        createUser
        updateUser
        order_id
        transport_id
        weight
        volume
        quantity
        length
        width
        height
      }
      shipperCompany_internalCode
      transporterCompany_internalCode
      receiverCompany_internalCode
      loadingTime
      unloadingTime
      transport_id
      vehicle_id
      dangerous
      stackable
      shipperCompany_id
      shipperCompany_name
      shipperCompany_logo
      receiverCompany_id
      receiverCompany_name
      receiverCompany_logo
      transporterCompany_id
      transporterCompany_name
      transporterCompany_logo
      unloadingLocation_postName
      unloadingLocation_postCode
      unloadingLocation_address
      unloadingLocation_country_id
      loadingLocation_postCode
      loadingLocation_postName
      loadingLocation_address
      loadingLocation_country_id
      vehicleRegistration
      bidCount
      owner_firstName
      owner_lastName
      owner_profileImageUrl
      loadingLocation_country_name
      unloadingLocation_country_name
      price
      hasPoD
      hasInvoice
      groupIndex
      tags {
        id
        tag
      }
    }
    total
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetOverlappedOrdersDocument = gql`
    query getOverlappedOrders($query: OrderQuery!) {
  getOverlappedOrders(query: $query) {
    items {
      id
      code
      internalCode
      status
      price
      marketExpirationDate
      requestedLoadingTime
      requestedUnloadingTime
      loadingTime
      unloadingTime
      transport_id
    }
    total
  }
}
    `;

/**
 * __useGetOverlappedOrdersQuery__
 *
 * To run a query within a React component, call `useGetOverlappedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverlappedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverlappedOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetOverlappedOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOverlappedOrdersQuery, GetOverlappedOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverlappedOrdersQuery, GetOverlappedOrdersQueryVariables>(GetOverlappedOrdersDocument, options);
      }
export function useGetOverlappedOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverlappedOrdersQuery, GetOverlappedOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverlappedOrdersQuery, GetOverlappedOrdersQueryVariables>(GetOverlappedOrdersDocument, options);
        }
export type GetOverlappedOrdersQueryHookResult = ReturnType<typeof useGetOverlappedOrdersQuery>;
export type GetOverlappedOrdersLazyQueryHookResult = ReturnType<typeof useGetOverlappedOrdersLazyQuery>;
export type GetOverlappedOrdersQueryResult = Apollo.QueryResult<GetOverlappedOrdersQuery, GetOverlappedOrdersQueryVariables>;
export const GetPartnersDocument = gql`
    query getPartners($query: CompanyQuery!, $id: Int!) {
  getPartners(query: $query, id: $id) {
    items {
      status
      id
      type
      name
      logoPath
    }
    total
  }
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions: Apollo.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
      }
export function useGetPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;
export const GetReceivedBidsDocument = gql`
    query getReceivedBids($query: BidQuery!) {
  getReceivedBids(query: $query) {
    items {
      id
      status
      order_id
      company_id
      company_name
      company_logo
      price
      expirationDate
      comment
      unloadingLocation_postName
      loadingLocation_postName
      requestedUnloadingTime
      requestedLoadingTime
      order_code
    }
    total
  }
}
    `;

/**
 * __useGetReceivedBidsQuery__
 *
 * To run a query within a React component, call `useGetReceivedBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivedBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivedBidsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetReceivedBidsQuery(baseOptions: Apollo.QueryHookOptions<GetReceivedBidsQuery, GetReceivedBidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivedBidsQuery, GetReceivedBidsQueryVariables>(GetReceivedBidsDocument, options);
      }
export function useGetReceivedBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivedBidsQuery, GetReceivedBidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivedBidsQuery, GetReceivedBidsQueryVariables>(GetReceivedBidsDocument, options);
        }
export type GetReceivedBidsQueryHookResult = ReturnType<typeof useGetReceivedBidsQuery>;
export type GetReceivedBidsLazyQueryHookResult = ReturnType<typeof useGetReceivedBidsLazyQuery>;
export type GetReceivedBidsQueryResult = Apollo.QueryResult<GetReceivedBidsQuery, GetReceivedBidsQueryVariables>;
export const GetTransportStatesDocument = gql`
    query getTransportStates($query: TransportStateQuery!) {
  getTransportStates(query: $query) {
    items {
      id
      status
      createTime
      updateTime
      createUser
      updateUser
      transport_id
      order_id
      type
      location_id
      geoLocation
      transporterCompany_id
      vehicle_id
      driverUser_id
      plannedTime
      executeTime
      notes
      extraCost
    }
    total
  }
}
    `;

/**
 * __useGetTransportStatesQuery__
 *
 * To run a query within a React component, call `useGetTransportStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportStatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetTransportStatesQuery(baseOptions: Apollo.QueryHookOptions<GetTransportStatesQuery, GetTransportStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransportStatesQuery, GetTransportStatesQueryVariables>(GetTransportStatesDocument, options);
      }
export function useGetTransportStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransportStatesQuery, GetTransportStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransportStatesQuery, GetTransportStatesQueryVariables>(GetTransportStatesDocument, options);
        }
export type GetTransportStatesQueryHookResult = ReturnType<typeof useGetTransportStatesQuery>;
export type GetTransportStatesLazyQueryHookResult = ReturnType<typeof useGetTransportStatesLazyQuery>;
export type GetTransportStatesQueryResult = Apollo.QueryResult<GetTransportStatesQuery, GetTransportStatesQueryVariables>;
export const GetTransportsDocument = gql`
    query getTransports($query: TransportQuery!) {
  getTransports(query: $query) {
    items {
      order_id
      vehicle_id
      driverUser_id
    }
    total
  }
}
    `;

/**
 * __useGetTransportsQuery__
 *
 * To run a query within a React component, call `useGetTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetTransportsQuery(baseOptions: Apollo.QueryHookOptions<GetTransportsQuery, GetTransportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransportsQuery, GetTransportsQueryVariables>(GetTransportsDocument, options);
      }
export function useGetTransportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransportsQuery, GetTransportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransportsQuery, GetTransportsQueryVariables>(GetTransportsDocument, options);
        }
export type GetTransportsQueryHookResult = ReturnType<typeof useGetTransportsQuery>;
export type GetTransportsLazyQueryHookResult = ReturnType<typeof useGetTransportsLazyQuery>;
export type GetTransportsQueryResult = Apollo.QueryResult<GetTransportsQuery, GetTransportsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: Int) {
  getUser(id: $id) {
    id
    status
    firstName
    lastName
    company {
      id
      name
      taxNumber
      contactEmail
      contactUser_id
      IBAN
      BIC
      type
      insuranceCertificate {
        id
        fileName
        fileSize
        fileType
        fileUrl
        path
        expiryDate
      }
      logoPath
      contactPhone
    }
    email
    phone
    profileImageUrl
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserNotificationSettingsDocument = gql`
    query getUserNotificationSettings($type: NotificationType) {
  getUserNotificationSettings(type: $type) {
    type
    channel
    user_id
  }
}
    `;

/**
 * __useGetUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationSettingsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
      }
export function useGetUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export type GetUserNotificationSettingsQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsQuery>;
export type GetUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsLazyQuery>;
export type GetUserNotificationSettingsQueryResult = Apollo.QueryResult<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($query: UserQuery!) {
  getUsers(query: $query) {
    items {
      id
      status
      firstName
      lastName
      email
      phone
      profileImageUrl
      token
      roles {
        id
      }
    }
    total
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetVehicleDocument = gql`
    query getVehicle($id: Int!) {
  getVehicle(id: $id) {
    id
    status
    type
    registration
    geoLocation
    description
    maxCargoVolume
    maxCargoWeight
  }
}
    `;

/**
 * __useGetVehicleQuery__
 *
 * To run a query within a React component, call `useGetVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
      }
export function useGetVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
        }
export type GetVehicleQueryHookResult = ReturnType<typeof useGetVehicleQuery>;
export type GetVehicleLazyQueryHookResult = ReturnType<typeof useGetVehicleLazyQuery>;
export type GetVehicleQueryResult = Apollo.QueryResult<GetVehicleQuery, GetVehicleQueryVariables>;
export const GetVehiclesDocument = gql`
    query getVehicles($query: VehicleQuery!) {
  getVehicles(query: $query) {
    items {
      status
      id
      type
      registration
      maxCargoVolume
      maxCargoWeight
      geoLocation
      createTime
      description
      driverUser_id
      driver {
        firstName
        lastName
      }
    }
    total
  }
}
    `;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
      }
export function useGetVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
        }
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<typeof useGetVehiclesLazyQuery>;
export type GetVehiclesQueryResult = Apollo.QueryResult<GetVehiclesQuery, GetVehiclesQueryVariables>;
export const InviteCompanyDocument = gql`
    mutation inviteCompany($email: String!, $name: String!) {
  inviteCompany(email: $email, name: $name)
}
    `;
export type InviteCompanyMutationFn = Apollo.MutationFunction<InviteCompanyMutation, InviteCompanyMutationVariables>;

/**
 * __useInviteCompanyMutation__
 *
 * To run a mutation, you first call `useInviteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCompanyMutation, { data, loading, error }] = useInviteCompanyMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInviteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<InviteCompanyMutation, InviteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteCompanyMutation, InviteCompanyMutationVariables>(InviteCompanyDocument, options);
      }
export type InviteCompanyMutationHookResult = ReturnType<typeof useInviteCompanyMutation>;
export type InviteCompanyMutationResult = Apollo.MutationResult<InviteCompanyMutation>;
export type InviteCompanyMutationOptions = Apollo.BaseMutationOptions<InviteCompanyMutation, InviteCompanyMutationVariables>;
export const LoginDocument = gql`
    mutation login($userLogin: UserLogin!) {
  login(login: $userLogin) {
    id
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      userLogin: // value for 'userLogin'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const NewMessageDocument = gql`
    subscription NewMessage($order_id: Int!) {
  newMessage(order_id: $order_id) {
    status
    id
    user_id
    type
    text
    sendDate
    company_id
    order_id
    user {
      firstName
      lastName
      profileImageUrl
    }
  }
}
    `;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useNewMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewMessageSubscription, NewMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewMessageSubscription, NewMessageSubscriptionVariables>(NewMessageDocument, options);
      }
export type NewMessageSubscriptionHookResult = ReturnType<typeof useNewMessageSubscription>;
export type NewMessageSubscriptionResult = Apollo.SubscriptionResult<NewMessageSubscription>;
export const NewNotificationDocument = gql`
    subscription newNotification($user_id: Int!) {
  newNotification(user_id: $user_id) {
    status
    type
    id
    user_id
    company_id
    order_id
    date
    readDate
    title
    text
    data
    link
    actionCompany_id
    actionCompany_name
    actionCompany_logo
    actionUser_id
    actionUser_firstName
    actionUser_lastName
    actionUser_profileImageUrl
    dataCompany_id
    dataCompany_name
    dataCompany_logo
    dataUser_id
    dataUser_firstName
    dataUser_lastName
    dataUser_profileImageUrl
  }
}
    `;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, options);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = Apollo.SubscriptionResult<NewNotificationSubscription>;
export const GetPlacedBidsDocument = gql`
    query getPlacedBids($query: BidQuery!) {
  getPlacedBids(query: $query) {
    items {
      id
      status
      order_id
      company_id
      company_name
      company_logo
      price
      expirationDate
      comment
      unloadingLocation_postName
      loadingLocation_postName
      requestedUnloadingTime
      requestedLoadingTime
      order_code
    }
    total
  }
}
    `;

/**
 * __useGetPlacedBidsQuery__
 *
 * To run a query within a React component, call `useGetPlacedBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacedBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacedBidsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetPlacedBidsQuery(baseOptions: Apollo.QueryHookOptions<GetPlacedBidsQuery, GetPlacedBidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacedBidsQuery, GetPlacedBidsQueryVariables>(GetPlacedBidsDocument, options);
      }
export function useGetPlacedBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacedBidsQuery, GetPlacedBidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacedBidsQuery, GetPlacedBidsQueryVariables>(GetPlacedBidsDocument, options);
        }
export type GetPlacedBidsQueryHookResult = ReturnType<typeof useGetPlacedBidsQuery>;
export type GetPlacedBidsLazyQueryHookResult = ReturnType<typeof useGetPlacedBidsLazyQuery>;
export type GetPlacedBidsQueryResult = Apollo.QueryResult<GetPlacedBidsQuery, GetPlacedBidsQueryVariables>;
export const ReadAllNotificationsDocument = gql`
    mutation readAllNotifications($company_id: Int) {
  readAllNotifications(company_id: $company_id)
}
    `;
export type ReadAllNotificationsMutationFn = Apollo.MutationFunction<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;

/**
 * __useReadAllNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationsMutation, { data, loading, error }] = useReadAllNotificationsMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useReadAllNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>(ReadAllNotificationsDocument, options);
      }
export type ReadAllNotificationsMutationHookResult = ReturnType<typeof useReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationResult = Apollo.MutationResult<ReadAllNotificationsMutation>;
export type ReadAllNotificationsMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export const MarkReadNotificationDocument = gql`
    mutation markReadNotification($id: Int) {
  markReadNotification(id: $id) {
    type
    id
    status
  }
}
    `;
export type MarkReadNotificationMutationFn = Apollo.MutationFunction<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;

/**
 * __useMarkReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadNotificationMutation, { data, loading, error }] = useMarkReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>(MarkReadNotificationDocument, options);
      }
export type MarkReadNotificationMutationHookResult = ReturnType<typeof useMarkReadNotificationMutation>;
export type MarkReadNotificationMutationResult = Apollo.MutationResult<MarkReadNotificationMutation>;
export type MarkReadNotificationMutationOptions = Apollo.BaseMutationOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($inviteToken: String!, $user: UserCreate!, $bic: String, $iban: String) {
  registerUser(inviteToken: $inviteToken, user: $user, bic: $bic, iban: $iban) {
    id
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *      user: // value for 'user'
 *      bic: // value for 'bic'
 *      iban: // value for 'iban'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const RegisterUserAndCompanyDocument = gql`
    mutation registerUserAndCompany($company: CompanyCreate!, $user: UserCreate!, $insuranceCertificate: DocumentCreate) {
  registerUserAndCompany(
    company: $company
    user: $user
    insuranceCertificate: $insuranceCertificate
  ) {
    id
    company {
      name
      insuranceCertificate {
        id
        fileName
        fileSize
        fileType
        fileUrl
        path
      }
    }
  }
}
    `;
export type RegisterUserAndCompanyMutationFn = Apollo.MutationFunction<RegisterUserAndCompanyMutation, RegisterUserAndCompanyMutationVariables>;

/**
 * __useRegisterUserAndCompanyMutation__
 *
 * To run a mutation, you first call `useRegisterUserAndCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserAndCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserAndCompanyMutation, { data, loading, error }] = useRegisterUserAndCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *      user: // value for 'user'
 *      insuranceCertificate: // value for 'insuranceCertificate'
 *   },
 * });
 */
export function useRegisterUserAndCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserAndCompanyMutation, RegisterUserAndCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserAndCompanyMutation, RegisterUserAndCompanyMutationVariables>(RegisterUserAndCompanyDocument, options);
      }
export type RegisterUserAndCompanyMutationHookResult = ReturnType<typeof useRegisterUserAndCompanyMutation>;
export type RegisterUserAndCompanyMutationResult = Apollo.MutationResult<RegisterUserAndCompanyMutation>;
export type RegisterUserAndCompanyMutationOptions = Apollo.BaseMutationOptions<RegisterUserAndCompanyMutation, RegisterUserAndCompanyMutationVariables>;
export const RemoveOrderTagDocument = gql`
    mutation removeOrderTag($tag_id: Int!, $order_id: Int!) {
  removeOrderTag(tag_id: $tag_id, order_id: $order_id) {
    id
    tag
  }
}
    `;
export type RemoveOrderTagMutationFn = Apollo.MutationFunction<RemoveOrderTagMutation, RemoveOrderTagMutationVariables>;

/**
 * __useRemoveOrderTagMutation__
 *
 * To run a mutation, you first call `useRemoveOrderTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderTagMutation, { data, loading, error }] = useRemoveOrderTagMutation({
 *   variables: {
 *      tag_id: // value for 'tag_id'
 *      order_id: // value for 'order_id'
 *   },
 * });
 */
export function useRemoveOrderTagMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderTagMutation, RemoveOrderTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrderTagMutation, RemoveOrderTagMutationVariables>(RemoveOrderTagDocument, options);
      }
export type RemoveOrderTagMutationHookResult = ReturnType<typeof useRemoveOrderTagMutation>;
export type RemoveOrderTagMutationResult = Apollo.MutationResult<RemoveOrderTagMutation>;
export type RemoveOrderTagMutationOptions = Apollo.BaseMutationOptions<RemoveOrderTagMutation, RemoveOrderTagMutationVariables>;
export const RemoveTaxationClauseDocument = gql`
    mutation removeTaxationClause($id: Float!) {
  removeTaxationClause(id: $id) {
    id
  }
}
    `;
export type RemoveTaxationClauseMutationFn = Apollo.MutationFunction<RemoveTaxationClauseMutation, RemoveTaxationClauseMutationVariables>;

/**
 * __useRemoveTaxationClauseMutation__
 *
 * To run a mutation, you first call `useRemoveTaxationClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaxationClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaxationClauseMutation, { data, loading, error }] = useRemoveTaxationClauseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTaxationClauseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTaxationClauseMutation, RemoveTaxationClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTaxationClauseMutation, RemoveTaxationClauseMutationVariables>(RemoveTaxationClauseDocument, options);
      }
export type RemoveTaxationClauseMutationHookResult = ReturnType<typeof useRemoveTaxationClauseMutation>;
export type RemoveTaxationClauseMutationResult = Apollo.MutationResult<RemoveTaxationClauseMutation>;
export type RemoveTaxationClauseMutationOptions = Apollo.BaseMutationOptions<RemoveTaxationClauseMutation, RemoveTaxationClauseMutationVariables>;
export const ReplaceInsuranceDocumentDocument = gql`
    mutation replaceInsuranceDocument($document: DocumentCreate!, $id: Int!) {
  replaceInsuranceDocument(document: $document, id: $id) {
    id
    fileName
    fileSize
    fileType
    fileUrl
    path
  }
}
    `;
export type ReplaceInsuranceDocumentMutationFn = Apollo.MutationFunction<ReplaceInsuranceDocumentMutation, ReplaceInsuranceDocumentMutationVariables>;

/**
 * __useReplaceInsuranceDocumentMutation__
 *
 * To run a mutation, you first call `useReplaceInsuranceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceInsuranceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceInsuranceDocumentMutation, { data, loading, error }] = useReplaceInsuranceDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplaceInsuranceDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceInsuranceDocumentMutation, ReplaceInsuranceDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceInsuranceDocumentMutation, ReplaceInsuranceDocumentMutationVariables>(ReplaceInsuranceDocumentDocument, options);
      }
export type ReplaceInsuranceDocumentMutationHookResult = ReturnType<typeof useReplaceInsuranceDocumentMutation>;
export type ReplaceInsuranceDocumentMutationResult = Apollo.MutationResult<ReplaceInsuranceDocumentMutation>;
export type ReplaceInsuranceDocumentMutationOptions = Apollo.BaseMutationOptions<ReplaceInsuranceDocumentMutation, ReplaceInsuranceDocumentMutationVariables>;
export const RequestEmailChangeDocument = gql`
    mutation requestEmailChange($newEmail: String!, $email: String!) {
  requestEmailChange(newEmail: $newEmail, email: $email)
}
    `;
export type RequestEmailChangeMutationFn = Apollo.MutationFunction<RequestEmailChangeMutation, RequestEmailChangeMutationVariables>;

/**
 * __useRequestEmailChangeMutation__
 *
 * To run a mutation, you first call `useRequestEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailChangeMutation, { data, loading, error }] = useRequestEmailChangeMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailChangeMutation(baseOptions?: Apollo.MutationHookOptions<RequestEmailChangeMutation, RequestEmailChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestEmailChangeMutation, RequestEmailChangeMutationVariables>(RequestEmailChangeDocument, options);
      }
export type RequestEmailChangeMutationHookResult = ReturnType<typeof useRequestEmailChangeMutation>;
export type RequestEmailChangeMutationResult = Apollo.MutationResult<RequestEmailChangeMutation>;
export type RequestEmailChangeMutationOptions = Apollo.BaseMutationOptions<RequestEmailChangeMutation, RequestEmailChangeMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token) {
    id
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RespondToChangeRequestDocument = gql`
    mutation respondToChangeRequest($response: RespondToChangeRequest!) {
  respondToChangeRequest(response: $response) {
    id
  }
}
    `;
export type RespondToChangeRequestMutationFn = Apollo.MutationFunction<RespondToChangeRequestMutation, RespondToChangeRequestMutationVariables>;

/**
 * __useRespondToChangeRequestMutation__
 *
 * To run a mutation, you first call `useRespondToChangeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToChangeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToChangeRequestMutation, { data, loading, error }] = useRespondToChangeRequestMutation({
 *   variables: {
 *      response: // value for 'response'
 *   },
 * });
 */
export function useRespondToChangeRequestMutation(baseOptions?: Apollo.MutationHookOptions<RespondToChangeRequestMutation, RespondToChangeRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RespondToChangeRequestMutation, RespondToChangeRequestMutationVariables>(RespondToChangeRequestDocument, options);
      }
export type RespondToChangeRequestMutationHookResult = ReturnType<typeof useRespondToChangeRequestMutation>;
export type RespondToChangeRequestMutationResult = Apollo.MutationResult<RespondToChangeRequestMutation>;
export type RespondToChangeRequestMutationOptions = Apollo.BaseMutationOptions<RespondToChangeRequestMutation, RespondToChangeRequestMutationVariables>;
export const SetAllNotificationChannelDocument = gql`
    mutation setAllNotificationChannel($user_id: Int, $channel: NotificationChannel!) {
  setAllNotificationChannel(user_id: $user_id, channel: $channel) {
    user_id
  }
}
    `;
export type SetAllNotificationChannelMutationFn = Apollo.MutationFunction<SetAllNotificationChannelMutation, SetAllNotificationChannelMutationVariables>;

/**
 * __useSetAllNotificationChannelMutation__
 *
 * To run a mutation, you first call `useSetAllNotificationChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAllNotificationChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAllNotificationChannelMutation, { data, loading, error }] = useSetAllNotificationChannelMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useSetAllNotificationChannelMutation(baseOptions?: Apollo.MutationHookOptions<SetAllNotificationChannelMutation, SetAllNotificationChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAllNotificationChannelMutation, SetAllNotificationChannelMutationVariables>(SetAllNotificationChannelDocument, options);
      }
export type SetAllNotificationChannelMutationHookResult = ReturnType<typeof useSetAllNotificationChannelMutation>;
export type SetAllNotificationChannelMutationResult = Apollo.MutationResult<SetAllNotificationChannelMutation>;
export type SetAllNotificationChannelMutationOptions = Apollo.BaseMutationOptions<SetAllNotificationChannelMutation, SetAllNotificationChannelMutationVariables>;
export const SetNotificationChannelDocument = gql`
    mutation setNotificationChannel($user_id: Int, $channel: NotificationChannel!, $type: NotificationType!) {
  setNotificationChannel(user_id: $user_id, channel: $channel, type: $type) {
    user_id
  }
}
    `;
export type SetNotificationChannelMutationFn = Apollo.MutationFunction<SetNotificationChannelMutation, SetNotificationChannelMutationVariables>;

/**
 * __useSetNotificationChannelMutation__
 *
 * To run a mutation, you first call `useSetNotificationChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationChannelMutation, { data, loading, error }] = useSetNotificationChannelMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      channel: // value for 'channel'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetNotificationChannelMutation(baseOptions?: Apollo.MutationHookOptions<SetNotificationChannelMutation, SetNotificationChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNotificationChannelMutation, SetNotificationChannelMutationVariables>(SetNotificationChannelDocument, options);
      }
export type SetNotificationChannelMutationHookResult = ReturnType<typeof useSetNotificationChannelMutation>;
export type SetNotificationChannelMutationResult = Apollo.MutationResult<SetNotificationChannelMutation>;
export type SetNotificationChannelMutationOptions = Apollo.BaseMutationOptions<SetNotificationChannelMutation, SetNotificationChannelMutationVariables>;
export const UpdateBidDocument = gql`
    mutation updateBid($bid: BidUpdate!) {
  updateBid(bid: $bid) {
    id
  }
}
    `;
export type UpdateBidMutationFn = Apollo.MutationFunction<UpdateBidMutation, UpdateBidMutationVariables>;

/**
 * __useUpdateBidMutation__
 *
 * To run a mutation, you first call `useUpdateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBidMutation, { data, loading, error }] = useUpdateBidMutation({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useUpdateBidMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBidMutation, UpdateBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBidMutation, UpdateBidMutationVariables>(UpdateBidDocument, options);
      }
export type UpdateBidMutationHookResult = ReturnType<typeof useUpdateBidMutation>;
export type UpdateBidMutationResult = Apollo.MutationResult<UpdateBidMutation>;
export type UpdateBidMutationOptions = Apollo.BaseMutationOptions<UpdateBidMutation, UpdateBidMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($CompanyUpdate: CompanyUpdate!) {
  updateCompany(company: $CompanyUpdate) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      CompanyUpdate: // value for 'CompanyUpdate'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateDocumentExpiryDateDocument = gql`
    mutation updateDocumentExpiryDate($expiryDate: String!, $id: Int!) {
  updateDocumentExpiryDate(expiryDate: $expiryDate, id: $id) {
    id
    expiryDate
  }
}
    `;
export type UpdateDocumentExpiryDateMutationFn = Apollo.MutationFunction<UpdateDocumentExpiryDateMutation, UpdateDocumentExpiryDateMutationVariables>;

/**
 * __useUpdateDocumentExpiryDateMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentExpiryDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentExpiryDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentExpiryDateMutation, { data, loading, error }] = useUpdateDocumentExpiryDateMutation({
 *   variables: {
 *      expiryDate: // value for 'expiryDate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentExpiryDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentExpiryDateMutation, UpdateDocumentExpiryDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentExpiryDateMutation, UpdateDocumentExpiryDateMutationVariables>(UpdateDocumentExpiryDateDocument, options);
      }
export type UpdateDocumentExpiryDateMutationHookResult = ReturnType<typeof useUpdateDocumentExpiryDateMutation>;
export type UpdateDocumentExpiryDateMutationResult = Apollo.MutationResult<UpdateDocumentExpiryDateMutation>;
export type UpdateDocumentExpiryDateMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentExpiryDateMutation, UpdateDocumentExpiryDateMutationVariables>;
export const UpdateInternalDocument = gql`
    mutation updateInternal($code: String!, $id: Int!) {
  updateInternal(code: $code, id: $id) {
    internalCode
  }
}
    `;
export type UpdateInternalMutationFn = Apollo.MutationFunction<UpdateInternalMutation, UpdateInternalMutationVariables>;

/**
 * __useUpdateInternalMutation__
 *
 * To run a mutation, you first call `useUpdateInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalMutation, { data, loading, error }] = useUpdateInternalMutation({
 *   variables: {
 *      code: // value for 'code'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInternalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInternalMutation, UpdateInternalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInternalMutation, UpdateInternalMutationVariables>(UpdateInternalDocument, options);
      }
export type UpdateInternalMutationHookResult = ReturnType<typeof useUpdateInternalMutation>;
export type UpdateInternalMutationResult = Apollo.MutationResult<UpdateInternalMutation>;
export type UpdateInternalMutationOptions = Apollo.BaseMutationOptions<UpdateInternalMutation, UpdateInternalMutationVariables>;
export const UpdateInvoiceDataStatusDocument = gql`
    mutation updateInvoiceDataStatus($status: InvoiceDataStatus!, $id: Float!) {
  updateInvoiceDataStatus(status: $status, id: $id) {
    status
  }
}
    `;
export type UpdateInvoiceDataStatusMutationFn = Apollo.MutationFunction<UpdateInvoiceDataStatusMutation, UpdateInvoiceDataStatusMutationVariables>;

/**
 * __useUpdateInvoiceDataStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceDataStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceDataStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceDataStatusMutation, { data, loading, error }] = useUpdateInvoiceDataStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInvoiceDataStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceDataStatusMutation, UpdateInvoiceDataStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceDataStatusMutation, UpdateInvoiceDataStatusMutationVariables>(UpdateInvoiceDataStatusDocument, options);
      }
export type UpdateInvoiceDataStatusMutationHookResult = ReturnType<typeof useUpdateInvoiceDataStatusMutation>;
export type UpdateInvoiceDataStatusMutationResult = Apollo.MutationResult<UpdateInvoiceDataStatusMutation>;
export type UpdateInvoiceDataStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceDataStatusMutation, UpdateInvoiceDataStatusMutationVariables>;
export const UpdateInvoiceSettingsDocument = gql`
    mutation updateInvoiceSettings($invoiceSettings: UpdateInvoiceSettings!) {
  updateInvoiceSettings(invoiceSettings: $invoiceSettings) {
    customPrefix
    addPayeePrefix
    addChronologicalSequence
    defaultDueDate
  }
}
    `;
export type UpdateInvoiceSettingsMutationFn = Apollo.MutationFunction<UpdateInvoiceSettingsMutation, UpdateInvoiceSettingsMutationVariables>;

/**
 * __useUpdateInvoiceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceSettingsMutation, { data, loading, error }] = useUpdateInvoiceSettingsMutation({
 *   variables: {
 *      invoiceSettings: // value for 'invoiceSettings'
 *   },
 * });
 */
export function useUpdateInvoiceSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceSettingsMutation, UpdateInvoiceSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceSettingsMutation, UpdateInvoiceSettingsMutationVariables>(UpdateInvoiceSettingsDocument, options);
      }
export type UpdateInvoiceSettingsMutationHookResult = ReturnType<typeof useUpdateInvoiceSettingsMutation>;
export type UpdateInvoiceSettingsMutationResult = Apollo.MutationResult<UpdateInvoiceSettingsMutation>;
export type UpdateInvoiceSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceSettingsMutation, UpdateInvoiceSettingsMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($location: LocationUpdate!) {
  updateLocation(location: $location) {
    id
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateLocationPointCompletedStateDocument = gql`
    mutation updateLocationPointCompletedState($completedValue: Boolean!, $id: Float!) {
  updateLocationPointCompletedState(completedValue: $completedValue, id: $id) {
    id
    completed
  }
}
    `;
export type UpdateLocationPointCompletedStateMutationFn = Apollo.MutationFunction<UpdateLocationPointCompletedStateMutation, UpdateLocationPointCompletedStateMutationVariables>;

/**
 * __useUpdateLocationPointCompletedStateMutation__
 *
 * To run a mutation, you first call `useUpdateLocationPointCompletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationPointCompletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationPointCompletedStateMutation, { data, loading, error }] = useUpdateLocationPointCompletedStateMutation({
 *   variables: {
 *      completedValue: // value for 'completedValue'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLocationPointCompletedStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationPointCompletedStateMutation, UpdateLocationPointCompletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationPointCompletedStateMutation, UpdateLocationPointCompletedStateMutationVariables>(UpdateLocationPointCompletedStateDocument, options);
      }
export type UpdateLocationPointCompletedStateMutationHookResult = ReturnType<typeof useUpdateLocationPointCompletedStateMutation>;
export type UpdateLocationPointCompletedStateMutationResult = Apollo.MutationResult<UpdateLocationPointCompletedStateMutation>;
export type UpdateLocationPointCompletedStateMutationOptions = Apollo.BaseMutationOptions<UpdateLocationPointCompletedStateMutation, UpdateLocationPointCompletedStateMutationVariables>;
export const UpdateLocationPointLoadingDateDocument = gql`
    mutation updateLocationPointLoadingDate($loadingDate: String!, $id: Float!) {
  updateLocationPointLoadingDate(loadingDate: $loadingDate, id: $id) {
    id
    loadingDate
  }
}
    `;
export type UpdateLocationPointLoadingDateMutationFn = Apollo.MutationFunction<UpdateLocationPointLoadingDateMutation, UpdateLocationPointLoadingDateMutationVariables>;

/**
 * __useUpdateLocationPointLoadingDateMutation__
 *
 * To run a mutation, you first call `useUpdateLocationPointLoadingDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationPointLoadingDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationPointLoadingDateMutation, { data, loading, error }] = useUpdateLocationPointLoadingDateMutation({
 *   variables: {
 *      loadingDate: // value for 'loadingDate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateLocationPointLoadingDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationPointLoadingDateMutation, UpdateLocationPointLoadingDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationPointLoadingDateMutation, UpdateLocationPointLoadingDateMutationVariables>(UpdateLocationPointLoadingDateDocument, options);
      }
export type UpdateLocationPointLoadingDateMutationHookResult = ReturnType<typeof useUpdateLocationPointLoadingDateMutation>;
export type UpdateLocationPointLoadingDateMutationResult = Apollo.MutationResult<UpdateLocationPointLoadingDateMutation>;
export type UpdateLocationPointLoadingDateMutationOptions = Apollo.BaseMutationOptions<UpdateLocationPointLoadingDateMutation, UpdateLocationPointLoadingDateMutationVariables>;
export const UpdateMessageDocument = gql`
    mutation updateMessage($message: MessageUpdate!) {
  updateMessage(message: $message) {
    id
  }
}
    `;
export type UpdateMessageMutationFn = Apollo.MutationFunction<UpdateMessageMutation, UpdateMessageMutationVariables>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageMutation, UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageMutation, UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<UpdateMessageMutation, UpdateMessageMutationVariables>;
export const UpdateModulePermissionDocument = gql`
    mutation updateModulePermission($hasAccess: Boolean!, $companyId: Int!, $moduleId: Int!) {
  updateModulePermission(
    hasAccess: $hasAccess
    companyId: $companyId
    moduleId: $moduleId
  ) {
    module_id
  }
}
    `;
export type UpdateModulePermissionMutationFn = Apollo.MutationFunction<UpdateModulePermissionMutation, UpdateModulePermissionMutationVariables>;

/**
 * __useUpdateModulePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateModulePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModulePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModulePermissionMutation, { data, loading, error }] = useUpdateModulePermissionMutation({
 *   variables: {
 *      hasAccess: // value for 'hasAccess'
 *      companyId: // value for 'companyId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useUpdateModulePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModulePermissionMutation, UpdateModulePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModulePermissionMutation, UpdateModulePermissionMutationVariables>(UpdateModulePermissionDocument, options);
      }
export type UpdateModulePermissionMutationHookResult = ReturnType<typeof useUpdateModulePermissionMutation>;
export type UpdateModulePermissionMutationResult = Apollo.MutationResult<UpdateModulePermissionMutation>;
export type UpdateModulePermissionMutationOptions = Apollo.BaseMutationOptions<UpdateModulePermissionMutation, UpdateModulePermissionMutationVariables>;
export const UpdateNotificationMessageDocument = gql`
    mutation updateNotificationMessage($data: UpdateNotificationMessage, $id: Int) {
  updateNotificationMessage(data: $data, id: $id) {
    status
    recipients {
      status
      user_id
      firstName
      lastName
      notification_id
    }
    id
    user_id
    company_id
    title
    text
    label
    scheduleDate
  }
}
    `;
export type UpdateNotificationMessageMutationFn = Apollo.MutationFunction<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>;

/**
 * __useUpdateNotificationMessageMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMessageMutation, { data, loading, error }] = useUpdateNotificationMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>(UpdateNotificationMessageDocument, options);
      }
export type UpdateNotificationMessageMutationHookResult = ReturnType<typeof useUpdateNotificationMessageMutation>;
export type UpdateNotificationMessageMutationResult = Apollo.MutationResult<UpdateNotificationMessageMutation>;
export type UpdateNotificationMessageMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($order: OrderUpdate!) {
  updateOrder(order: $order) {
    id
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($status: OrderStatus!, $id: Int!) {
  updateOrderStatus(status: $status, id: $id) {
    id
    code
  }
}
    `;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, options);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export const UpdateOrderTagDocument = gql`
    mutation updateOrderTag($tag: String!, $id: Int!) {
  updateOrderTag(tag: $tag, id: $id) {
    id
    company_id
    tag
  }
}
    `;
export type UpdateOrderTagMutationFn = Apollo.MutationFunction<UpdateOrderTagMutation, UpdateOrderTagMutationVariables>;

/**
 * __useUpdateOrderTagMutation__
 *
 * To run a mutation, you first call `useUpdateOrderTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderTagMutation, { data, loading, error }] = useUpdateOrderTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderTagMutation, UpdateOrderTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderTagMutation, UpdateOrderTagMutationVariables>(UpdateOrderTagDocument, options);
      }
export type UpdateOrderTagMutationHookResult = ReturnType<typeof useUpdateOrderTagMutation>;
export type UpdateOrderTagMutationResult = Apollo.MutationResult<UpdateOrderTagMutation>;
export type UpdateOrderTagMutationOptions = Apollo.BaseMutationOptions<UpdateOrderTagMutation, UpdateOrderTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($order: OrderUpdateTag!) {
  updateTag(order: $order) {
    id
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateTaxationClauseDocument = gql`
    mutation updateTaxationClause($taxationClause: UpdateTaxationClause!) {
  updateTaxationClause(taxationClause: $taxationClause) {
    clause
    vat
    isDefault
  }
}
    `;
export type UpdateTaxationClauseMutationFn = Apollo.MutationFunction<UpdateTaxationClauseMutation, UpdateTaxationClauseMutationVariables>;

/**
 * __useUpdateTaxationClauseMutation__
 *
 * To run a mutation, you first call `useUpdateTaxationClauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxationClauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxationClauseMutation, { data, loading, error }] = useUpdateTaxationClauseMutation({
 *   variables: {
 *      taxationClause: // value for 'taxationClause'
 *   },
 * });
 */
export function useUpdateTaxationClauseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxationClauseMutation, UpdateTaxationClauseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxationClauseMutation, UpdateTaxationClauseMutationVariables>(UpdateTaxationClauseDocument, options);
      }
export type UpdateTaxationClauseMutationHookResult = ReturnType<typeof useUpdateTaxationClauseMutation>;
export type UpdateTaxationClauseMutationResult = Apollo.MutationResult<UpdateTaxationClauseMutation>;
export type UpdateTaxationClauseMutationOptions = Apollo.BaseMutationOptions<UpdateTaxationClauseMutation, UpdateTaxationClauseMutationVariables>;
export const UpdateTransportDocument = gql`
    mutation updateTransport($transport: TransportUpdate!) {
  updateTransport(transport: $transport) {
    id
  }
}
    `;
export type UpdateTransportMutationFn = Apollo.MutationFunction<UpdateTransportMutation, UpdateTransportMutationVariables>;

/**
 * __useUpdateTransportMutation__
 *
 * To run a mutation, you first call `useUpdateTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransportMutation, { data, loading, error }] = useUpdateTransportMutation({
 *   variables: {
 *      transport: // value for 'transport'
 *   },
 * });
 */
export function useUpdateTransportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransportMutation, UpdateTransportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransportMutation, UpdateTransportMutationVariables>(UpdateTransportDocument, options);
      }
export type UpdateTransportMutationHookResult = ReturnType<typeof useUpdateTransportMutation>;
export type UpdateTransportMutationResult = Apollo.MutationResult<UpdateTransportMutation>;
export type UpdateTransportMutationOptions = Apollo.BaseMutationOptions<UpdateTransportMutation, UpdateTransportMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user: UserUpdate!) {
  updateUser(user: $user) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation updateVehicle($vehicle: VehicleUpdate!) {
  updateVehicle(vehicle: $vehicle) {
    id
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const InviteUserDocument = gql`
    mutation inviteUser($invite: CompanyInvite!) {
  inviteUser(invite: $invite)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      invite: // value for 'invite'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;