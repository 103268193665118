import React from "react";
import { CSSProperties } from "styled-components";
import * as Styled from "./Skeleton.styles";
interface SkeletonProps {
  height?: string;
  borderRadius?: string;
  margin?: string;
  width?: string;
  minHeight?: string;
  flex?: string;
  classN?: string;
  color?: string[];
  minWidth?: string;
  style?: CSSProperties;
}
const Skeleton: React.FC<SkeletonProps> = ({
  height,
  borderRadius,
  margin,
  width,
  minHeight,
  flex,
  classN,
  color = ["#F0F0F0", "#F8F8F8"],
  minWidth,
  style,
}) => {
  return (
    <Styled.Skeleton
      height={height}
      borderRadius={borderRadius}
      margin={margin}
      minWidth={minWidth}
      width={width}
      minHeight={minHeight}
      flex={flex}
      className={classN}
      color={color}
      style={{ ...style }}
    ></Styled.Skeleton>
  );
};

export default Skeleton;
