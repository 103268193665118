import { useEffect } from "react";
import { responseMsgs } from "../../App";
import { InvoiceDataStatus } from "../../generated/graphql";

export const success = [
  { code: "SIGN_IN", msg: "Successfully signed in." },
  { code: "RESET_PASSWORD", msg: "Password has been changed." },
  { code: "RESET_EMAIL", msg: "Email has been changed." },
  {
    code: "FORGOT_PASSWORD",
    msg: "Reset link was sent to your email. Give it few minutes before trying again.",
  },
  {
    code: "SIGN_UP",
    msg: "Account was successfully created. Check your email for a verification link.",
  },
  {
    code: "REQUEST_PASSWORD_RESET",
    msg: "Password reset link was successfully sent to your email.",
  },
  {
    code: "REQUEST_EMAIL_RESET",
    msg: "Email reset link was successfully sent to your old email address.",
  },
  { code: "COMPANY_SETTINGS_UPDATE", msg: "Settings saved." },
  {
    code: "REGISTER_USER",
    msg: "Account was successfully created. Check your email for a verification link.",
  },
  { code: "ORDER_UPDATED", msg: "Order updated." },
  { code: "ORDER_CREATED", msg: "Order created." },
  { code: "ORDER_SENT_ON_MARKET", msg: "Order added to the market." },
  { code: "ORDER_SENT_DRAFT", msg: "Order saved as draft." },
  { code: "DELETE_VEHICLE", msg: "Vehicle deleted." },
  { code: "DELETE_ASSIGNED_USER", msg: "User removed from order." },
  { code: "ASSIGN_USER", msg: "User assigned." },
  { code: "ACTIVATED_USER", msg: "Your account is now activate." },
  {
    code: "LOCATION_ACTIVATE",
    msg: "Your company location has been activated.",
  },
  {
    code: "LOCATION_DEACTIVATE",
    msg: "Your company location has been deactivated.",
  },
  { code: "ACCEPTED_BID", msg: "Bid accepted." },
  { code: "CHANGE_VEHICLE_DRIVER", msg: "Driver added." },
  { code: "PLACEHOLDER_COMPANY", msg: "Partner company created." },
  { code: "CREATE_VEHICLE", msg: "Vehicle created." },
  { code: "UPDATE_VEHICLE", msg: "Vehicle updated." },
  { code: "DECLINE_BID", msg: "Bid rejected." },
  { code: "CANCEL_BID", msg: "Bid canceled." },
  { code: "DELETE_ORDER", msg: "Order deleted." },
  { code: "UPDATE_INTERNAL_NUMBER", msg: "Saved." },
  { code: "INVITE_USER", msg: "Invitation sent." },
  { code: "LOCATION_CREATED", msg: "Location created." },
  { code: "LOCATION_UPDATED", msg: "Location saved." },
  { code: "MAKE_BID", msg: "Bid sent." },
  { code: "REPORT_CREATED", msg: "Report sent." },
  { code: "UPDATED_USER", msg: "Saved." },
  { code: "CREATED_USER", msg: "User created." },
  { code: "USER_VERIFIED", msg: "Verification completed." },
  { code: "ROLE_CHANGED", msg: "Role updated." },
  { code: "ACTIVATED_COMPANY", msg: "Company activated." },
  { code: "READ_ALL_SUCCESS", msg: "Notifications cleared." },
  { code: "NEW_MSG", msg: "" },
  { code: "TRANSPORT_UPDATED", msg: "Transport details updated." },
  { code: "INVOICE_SETTINGS_UPDATED", msg: "Invoice settings updated." },
  { code: "TAXATION_CLAUSE_ADDED", msg: "Taxation clause added." },
  { code: "TAXATION_CLAUSE_REMOVED", msg: "Taxation clause removed." },
  {
    code: "TAXATION_CLAUSE_MARKED_AS_DEFAULT",
    msg: "Taxation clause marked as default.",
  },
  {
    code: `INVOICE_DATA_STATUS_UPDATED_${InvoiceDataStatus.Confirmed}`,
    msg: "Invoice confirmed.",
  },
  {
    code: `INVOICE_DATA_STATUS_UPDATED_${InvoiceDataStatus.Paid}`,
    msg: "Invoice marked as paid.",
  },
  {
    code: `INVOICE_DATA_STATUS_UPDATED_${InvoiceDataStatus.Rejected}`,
    msg: "Invoice rejected.",
  },
  {
    code: `INVOICE_DATA_STATUS_UPDATED_${InvoiceDataStatus.Discarded}`,
    msg: "Invoice discarded.",
  },
  {
    code: "INVOICE_GENERATED",
    msg: "Invoice successfully generated.",
  },
  {
    code: "MODULE_PERMISSION_UPDATED",
    msg: "Module permission updated.",
  },
  {
    code: "MODULE_PERMISSION_CREATED",
    msg: "Module permission created.",
  },
  {
    code: "LOCATION_POINT_COMPLETED_STATE_UPDATED",
    msg: "Location point state updated.",
  },
  {
    code: "LOCATION_POINT_LOADING_DATE_UPDATED",
    msg: "Location point loading date updated.",
  },
  {
    code: "DOCUMENT_EXPIRY_DATE_UPDATE",
    msg: "Document expiry date updated.",
  },
  {
    code: "DOCUMENT_REPLACE",
    msg: "Document replaced.",
  },
  {
    code: "DOCUMENT_CREATED",
    msg: "Document created.",
  },

  //NOV MSG
  { code: "SUBSCRIBE_CHANGE_SUCCESS", msg: "Subscribe plan has been changed." },
  { code: "REMINDER_CREATED", msg: "Reminder created." },
  { code: "REMINDER_UPDATE", msg: "Reminder updated." },
  {
    code: "TRANSPORT_REQUEST",
    msg: "Success",
  },
  {
    code: "REQUEST_CREATED",
    msg: "Transport new date request successfully sent to order owner.",
  },
];

const SuccessBeHandler: ({
  data,
  code,
  fn,
  dep,
}: {
  data: any;
  code: any;
  fn?: any;
  dep?: boolean;
}) => void = ({ data, code, fn, dep = true }) => {
  useEffect(() => {
    if (dep) {
      if (data) {
        const new_suc = success.filter((suc) => suc.code === code);
        if (
          new_suc[0].code !== "USER_VERIFIED" &&
          new_suc[0].code !== "NEW_MSG"
        ) {
          responseMsgs([{ type: "success", string: new_suc[0].msg }]);
        }
        fn !== undefined && fn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

export default SuccessBeHandler;
