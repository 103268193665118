import React, { useState } from "react";
import { Anchor, Icon, Tag, Text } from "atomize";
import Tr from "../../../components/TableComponents/Tr";
import Td from "../../../components/TableComponents/Td";
import Moment from "react-moment";
import moment from "moment";
import { DocumentType } from "../../../generated/graphql";
import renderIcon from "../../../assets/Icons";
import { useHistory } from "react-router";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import Skeleton from "../../../components/Skeleton/Skeleton";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
Moment.globalLocal = true;

const DocumentRow: React.FC<any> = ({ row, view }) => {
  const history = useHistory();
  const [isShown, setIsShown] = useState(false);

  return (
    <Tr>
      <Td width={`${view[0].colW}px`}>
        {row.type === null ? "None" : getTypeTag(row.type)}
      </Td>
      <Td width={`${view[1].colW}px`}>
        <Text textColor="light">{row.code === null ? "None" : row.code}</Text>
      </Td>
      <Td width={`${view[2].colW}px`} className="documents">
        <Anchor
          textSize={14}
          transition
          textColor="light"
          d="block"
          textWeight="400"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          onClick={() => {
            history.push(`/myorder/${row.order_code}`);
          }}
        >
          {!isShown ? (
            <>{row.order_code === null ? "None" : row.order_code}</>
          ) : (
            <Tag
              m={{ l: "-0.5rem" }}
              p={{ r: "5px", l: "0.5rem", y: "0.25rem" }}
              textColor="dark"
              textWeight="600"
              textSize={14}
            >
              {row.order_code === null ? "None" : row.order_code}
              <Icon name="RightUp" color="primary" size="20px" />
            </Tag>
          )}
        </Anchor>
      </Td>
      <Td width={`${view[3].colW}px`}>
        {row.company === null ? (
          "None"
        ) : (
          <>
            <PreloadingImage
              imageSrc={row.company.logoPath}
              skeletonComponent={
                <Skeleton height="32px" width="32px" borderRadius="32px" />
              }
              imgComponent={<ShortNameLogo name={row.company.name} />}
              imageClassNames="i_b f_l"
              imageStyle={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                verticalAlign: "middle",
              }}
            />
            <Text
              textColor={"semiDark"}
              m={{ l: "0.75rem" }}
              className="i_b"
              style={{ verticalAlign: "middle" }}
              textSize={142}
            >
              {row.company.name}
            </Text>
          </>
        )}
      </Td>
      <Td width={`${view[4].colW}px`}>
        {row.date === null ? "None" : moment(row.date).format("D MMM YYYY")}
      </Td>
    </Tr>
  );
};

export default DocumentRow;

function getTypeTag(type: DocumentType) {
  switch (type) {
    case DocumentType.Invoice:
      return (
        <Tag bg="#CFF8EB" style={{ lineHeight: "24px" }}>
          {renderIcon("InvoiceSmall")}
          <Text
            m={{ l: "0.25rem" }}
            textColor="#006242"
            textSize={12}
            cursor="default"
          >
            Invoice
          </Text>
        </Tag>
      );

    case DocumentType.Pod:
      return (
        <Tag bg="#FFDC99">
          <Icon name="EyeSolid" size="16px" color="#F07300" />
          <Text
            m={{ l: "0.25rem" }}
            textColor="#F07300"
            textSize={12}
            cursor="default"
          >
            PoD
          </Text>
        </Tag>
      );

    default:
      return (
        <Tag>
          <Text textSize={12} cursor="default">
            {type}
          </Text>
        </Tag>
      );
  }
}
