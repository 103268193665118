import React, { useEffect, useRef } from "react";
import { Dropdown as DropDown, Div, Input, Icon } from "atomize";
import NumberFormat from "react-number-format";

interface DropdownProps {
  menuList?: JSX.Element[] | undefined;
  handleChange: (value: string) => void;
  isLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setCountryInput: (countryInput: string) => void;
  isOpen: boolean;
  displayResult: JSX.Element;
  isLocked?: boolean;
  addNew?: boolean;
  showSearchField?: boolean;
  border?: boolean;
  h?: string;
  maxH?: string;
  inputValue: string;
  countryInputValue: string;
  placeholder?: string;
  format: string;
  dropWidth?: string;
}

const PrefixDropdown: React.FC<DropdownProps> = ({
  h = "48px",
  maxH,
  showSearchField,
  displayResult,
  menuList,
  handleChange,
  setCountryInput,
  isLoading,
  setIsOpen,
  isOpen,
  inputValue,
  countryInputValue,
  placeholder,
  format,
}) => {
  const input = useRef<HTMLInputElement>(null);
  const div = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen && menuList) {
      input.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Div rounded="6" border="none" h={h} d="flex" justify="flex" ref={div}>
      <DropDown
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        rounded={"6"}
        m={{ r: "8px" }}
        h={h}
        w="fit-content"
        bg={"transparent"}
        closeSuffix={
          <Icon
            name="DownArrow"
            size="18px"
            m={{ l: "1rem" }}
            color="dark"
            hovercolor="semiDark"
          />
        }
        openSuffix={
          <Icon
            name="UpArrow"
            size="18px"
            m={{ l: "1rem" }}
            color="dark"
            hovercolor="semiDark"
          />
        }
        menu={
          <Div top="102%" w={`${div.current?.clientWidth}px`}>
            {showSearchField !== false && (
              <Input
                m={{ t: "1rem", b: "0.5rem", x: "0.75rem" }}
                placeholder="Search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCountryInput(e.target.value);
                }}
                value={countryInputValue}
                autoFocus
                textSize={16}
                textWeight="500"
                suffix={
                  isLoading ? (
                    <Icon
                      name="Loading"
                      size="20px"
                      pos="absolute"
                      top="50%"
                      right="1rem"
                      transform="translateY(-50%)"
                    />
                  ) : (
                    <Icon
                      name="Search"
                      size="20px"
                      cursor="pointer"
                      pos="absolute"
                      top="50%"
                      right="1rem"
                      transform="translateY(-50%)"
                    />
                  )
                }
              />
            )}
            <Div overflow="auto" {...(maxH ? { maxH } : {})}>
              {menuList}
            </Div>
          </Div>
        }
      >
        {displayResult}
      </DropDown>
      <Div w="100%">
        <NumberFormat
          getInputRef={input}
          format={format}
          h={h}
          w="100%"
          textSize={16}
          value={inputValue}
          placeholder={placeholder}
          customInput={Input}
          onValueChange={(values) => {
            handleChange(values.value);
          }}
        />
      </Div>
    </Div>
  );
};

export default PrefixDropdown;
