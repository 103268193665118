import { useReactiveVar } from "@apollo/client";
import { Div, Text } from "atomize";
import React, { useLayoutEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { myOrderView, myOrdersPag } from "../../../App";
import SortIcon from "../../../components/SortIcon/SortIcon";
import Th from "../../../components/TableComponents/Th";
import Thead from "../../../components/TableComponents/Thead";
import Tr from "../../../components/TableComponents/Tr";
import { LocalStorageKeys } from "../../../helpers/constants";

const MyOrdersTableHead: React.FC = () => {
  const myOrderViewVar = useReactiveVar(myOrderView);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(myOrderViewVar?.filter((col) => col.checked));
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    myOrderView(items);
    localStorage.setItem(
      LocalStorageKeys.MY_ORDERS_VIEW,
      JSON.stringify(items)
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Thead>
        <Droppable
          droppableId="columns"
          type="COLUMN"
          direction="horizontal"
          isCombineEnabled={false}
        >
          {(provided) => (
            <Tr
              className="head"
              hidBorderLast={true}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {myOrderViewVar
                ?.filter((col) => col.checked)
                ?.map(
                  (
                    col: {
                      name: string;
                      field: string;
                      checked: boolean;
                      colW: number;
                    },
                    index: number
                  ) => (
                    <Draggable
                      key={col.field}
                      draggableId={col.field}
                      index={index}
                    >
                      {(provided) => (
                        <Th
                          width={`${col.colW}px`}
                          key={col.field}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MyOrdersTableHeadCol col={col} />
                        </Th>
                      )}
                    </Draggable>
                  )
                )}
              {provided.placeholder}
            </Tr>
          )}
        </Droppable>
      </Thead>
    </DragDropContext>
  );
};

const MyOrdersTableHeadCol: React.FC<{ col: { name: string; field: string } }> =
  React.memo(({ col }) => {
    const myOrdersPagVar = useReactiveVar(myOrdersPag);
    const [isLoading, setIsLoading] = useState(true);

    useLayoutEffect(() => {
      setIsLoading(false);
    }, []);

    return (
      <Div p="0">
        <Div
          style={{
            opacity: !isLoading ? 1 : 0,
            transition: "opacity 0.3s ",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text>{col.name}</Text>
          <SortIcon
            sortBy={myOrdersPagVar}
            name={col.field}
            setSortBy={myOrdersPag}
            shouldUpdateLocalStorage
          />
        </Div>
      </Div>
    );
  });

export default React.memo(MyOrdersTableHead);
