import React from "react";
import styled from "styled-components";
import { GlobalTheme } from "../GlobalTheme";

interface ChipProps {
  label: string;
  bgColor?: string;
  textColor?: string;
  size?: "medium" | "small";
}

const ChipContainer = styled.div<{
  bgColor?: string;
  textColor?: string;
  size?: string;
}>`
  display: inline-flex;
  align-items: center;
  padding: ${(props) => (props.size === "small" ? "2px 8px" : "4px 12px")};
  border-radius: 16px;
  background-color: ${(props) => props.bgColor || GlobalTheme.colors.grey};
  color: ${(props) => props.textColor || GlobalTheme.colors.dark};
  font-size: 14px;
  margin-right: 8px;
`;

const Chip: React.FC<ChipProps> = ({
  label,
  bgColor,
  textColor,
  size = "medium",
}) => {
  return (
    <ChipContainer bgColor={bgColor} textColor={textColor} size={size}>
      {label}
    </ChipContainer>
  );
};

export default Chip;
