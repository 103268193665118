import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Row, Col, Text, Div, Tag } from "atomize";
import Table from "../../../components/TableComponents/Table";
import Section from "../../../components/Section/Section";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  useGetOrdersQuery,
  CompanyStatus,
  useGetMeQuery,
  OrderListingItem,
  useAddOrderTagMutation,
  useRemoveOrderTagMutation,
  useUpdateTagMutation,
  useCreateOrderTagMutation,
  useGetOrderTagsQuery,
  OrderTag
} from "../../../generated/graphql";
import Tbody from "../../../components/TableComponents/Tbody";
import Tr from "../../../components/TableComponents/Tr";
import { useHistory } from "react-router-dom";
import renderIcon from "../../../assets/Icons";
import MyOrderRow from "../rows/MyOrderRow";
import Pagination from "../../../components/Pagination/Pagination";
import { initialGetOrders, initialView } from "../../../helpers/initialData";
import PrimaryBtn from "../../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../../components/SecondaryBtn/SecondaryBtn";
import MyOrdersTableHead from "../head/MyOrdersTableHead";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import { useReactiveVar } from "@apollo/client";
import { modalData, myOrdersPag, myOrderView } from "../../../App";
import useAvailableElHeight from "../../../helpers/CustomHooks/useAvailableElHeight";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import { LocalStorageKeys } from "../../../helpers/constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { userHasModule } from "../../../helpers/functions";
import { ModuleName } from "../../../enums";

interface MyOrdersTableProps {
  k: boolean;
  setK: (k: boolean) => void;
  setTotal: (total: number) => void;
  total: number;
}

const MyOrdersTable: React.FC<MyOrdersTableProps> = ({ k, setK, setTotal }) => {
  const myOrdersPagVar = useReactiveVar(myOrdersPag);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [lastTag, setLastTag] = useState<string | undefined>(undefined);
  const [curTag, setCurTag] = useState<any>(undefined);
  const [sortedOrders, setSortedOrders] = useState<any>(undefined);
  const myOrderViewVar = useReactiveVar(myOrderView);
  const { data: me } = useGetMeQuery({ errorPolicy: "all" });

  const userHasOrderManagementModule = userHasModule(
    me,
    ModuleName.ORDER_MANAGEMENT
  )

  const [
    addOrderTag,
    {
      error: addOrderTagError,
    },
  ] = useAddOrderTagMutation({ errorPolicy: "all" });
  const [
    removeOrderTag,
    {
      error: removeOrderTagError,
    },
  ] = useRemoveOrderTagMutation({ errorPolicy: "all" });
  const [
    updateTag,
    {
      error: updateTagError,
    },
  ] = useUpdateTagMutation({ errorPolicy: "all" });

  const [
    createOrderTag,
    {
      error: createOrderTagError,
    },
  ] = useCreateOrderTagMutation({ errorPolicy: "all" });

  useLayoutEffect(() => {
    let storageViewQuery: any = initialView;
    let l = localStorage.getItem(LocalStorageKeys.MY_ORDERS_VIEW);

    if (l) {
      storageViewQuery = JSON.parse(l);
    }
    myOrderView(storageViewQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const getHighestNum = (items: any) => {
    let highestTagNum = 0
    let highestTag
    for (const item of items) {
      const itemTag = typeof item?.tags?.[0]?.tag === 'string' ? item.tags[0].tag : typeof item.tag === 'string' ? item.tag : undefined
      const itemTagSplit = itemTag ? itemTag.split("-") : undefined
      const itemTagNum = itemTagSplit?.[1] ? parseInt(itemTagSplit[1]) : 0
      if (itemTagNum > highestTagNum) {
        highestTagNum = itemTagNum
        highestTag = itemTag
      } 
    }
    return highestTag
  }
  const createTag = (id: number) => {
    const newTag = me?.getUser?.company?.name.substring(0,2) + '-' + id
    createOrderTag({
      variables: {
        tag: String(newTag),
      },
      onCompleted: (data) => {
        if (data?.createOrderTag) {
          setCurTag(data.createOrderTag)
        }
      },
    });
  }

  const {
    data: orders,
    loading: ordersLoading,
    error: ordersErrors,
    refetch,
  } = useGetOrdersQuery({
    variables: { query: { ...myOrdersPagVar } },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data?.getOrders?.total) {
        setTotal(data?.getOrders?.total);
        setTotalPages(Math.ceil(data?.getOrders?.total / myOrdersPagVar.limit));
        if (data?.getOrders?.items) {
          const itemsWithTag = [...data.getOrders.items].filter(item => item.tags && item.tags.length > 0);
          setSortedOrders(sortOrders(data.getOrders.items))
          if (itemsWithTag?.length > 0) {
            setLastTag(getHighestNum(itemsWithTag))
          }
        }
      }
    },
  });

  const sortOrders = (orders: any) => {
    const orderedOrders = []
    let sortedTags: any = []
  
    for (const order of orders) {
      if (order.tags?.length > 0) {
        if (!sortedTags.includes(order.tags[0])) {
          sortedTags.push(order.tags[0])
          const tagGroup: any = orders.filter((row: OrderListingItem) => row.tags?.[0] === order.tags?.[0]).sort((a: any, b: any) => a.groupIndex - b.groupIndex)
          orderedOrders.push(...tagGroup)
        }
      } else {
        orderedOrders.push(order)
      }
    }
  
    return orderedOrders;
  };

  const {
    data: tags,
    error: tagsErrors,
  } = useGetOrderTagsQuery({
    variables: { query: { orderBy: 'id', desc: false } },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (!tags?.getOrderTags?.items) return
    let existingTag: string | undefined
    if (tags.getOrderTags.items.length > 0) {
      existingTag = getHighestNum(tags.getOrderTags.items)
    }
    if (!lastTag) {
      if (existingTag) {
        setCurTag(tags?.getOrderTags?.items.find((item) => item.tag === existingTag))
      } else {
        createTag(1)
      }
    } else {
      if (lastTag.substring(3) < String(existingTag?.substring(3))) {
        setCurTag(tags?.getOrderTags?.items.find((item) => item.tag === existingTag))
      } else {
        createTag(parseInt(lastTag.substring(3)) + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTag, tags])

  useEffect(() => {
    if (myOrdersPagVar.limit === null) {
      let li = Math.floor(maxH / 49 - 1);
      myOrdersPag({ ...myOrdersPagVar, limit: li });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myOrdersPagVar.limit]);

  useEffect(() => {
    if (k) {
      refetch();
      setK(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [k]);

  useEffect(() => {
    if (orders?.getOrders.total === 0) {
      setTotalPages(null);
    }
  }, [orders?.getOrders.total]);

  const [searchInput, setSearchInput] = useState("");

  ErrorsBeHandler({
    error: ordersErrors || addOrderTagError || removeOrderTagError || updateTagError || createOrderTagError || tagsErrors,
  });
  
  const itemRef = useRef<HTMLTableElement | null>(null);
  const maxH = useAvailableElHeight(itemRef);

  const { width } = useWindowDimensions();

  const getLastGroupIndex = (tag: OrderTag) => {
    const tagOrders: any = [...sortedOrders].filter(row => row.tags.includes(tag))
    let lastGroupIndex = 0
    for (const order of tagOrders) {
      if (order.groupIndex && order.groupIndex > lastGroupIndex) {
        lastGroupIndex = order.groupIndex
      } 
    }
    return (lastGroupIndex + 1)
  }

  const changeOrderData = (
    orders: {
      id: number;
      tag?: string | null;
      groupIndex?: number | null
  }[] = []) => {
    const newOrders = sortedOrders.map((order: any) => ({ ...order }))
    orders.forEach(order => {
      const { id, tag, groupIndex } = order
      const modifyedIndex = newOrders.findIndex((newOrder: any) => newOrder.id === id)
      if (modifyedIndex >= 0 && newOrders[modifyedIndex]) {
        if (typeof groupIndex !== 'undefined') {
          newOrders[modifyedIndex].groupIndex = groupIndex
        }
        if (typeof tag !== 'undefined') {
          if (tag === null) {
            newOrders[modifyedIndex].tags = []
          } else {
            newOrders[modifyedIndex].tags = [tag]
          }
        }
      }
    })
    setSortedOrders(sortOrders(newOrders))
  }

  const onDragEnd = (result: any) => {
    if (!result.destination || !result.source) return
    const destOrder = sortedOrders[result.destination.index]
    const sourceOrder = sortedOrders[result.source.index]
    if (sourceOrder === destOrder) return
    if (destOrder?.tags?.length > 0) {
      if (sourceOrder?.tags?.length > 0) {
        if (sourceOrder.tags[0] === destOrder.tags[0]) {
          changeOrderData([
            {
              id: sourceOrder.id,
              groupIndex: destOrder.groupIndex
            },
            {
              id: destOrder.id,
              groupIndex: sourceOrder.groupIndex,
            }
          ])
          modifyOrder({
            id: sourceOrder.id,
            groupIndex: destOrder.groupIndex
          })
          modifyOrder({
            id: destOrder.id,
            groupIndex: sourceOrder.groupIndex
          })
        } else {
          const newGroupIndex = getLastGroupIndex(destOrder.tags[0])
          changeOrderData([
            {
              id: sourceOrder.id,
              groupIndex: newGroupIndex,
              tag: destOrder.tags[0]
            }
          ])
          modifyOrder({
            id: sourceOrder.id,
            groupIndex: newGroupIndex,
            tag: destOrder.tags[0],
            removeTag: sourceOrder.tags[0]
          })
        }
      } else {
        const newGroupIndex = getLastGroupIndex(destOrder.tags[0])
        changeOrderData([{
          id: sourceOrder.id,
          groupIndex: newGroupIndex,
          tag: destOrder.tags[0]
        }])
        modifyOrder({
          id: sourceOrder.id,
          groupIndex: newGroupIndex,
          tag: destOrder.tags[0]
        })
      }
    }
    else if (sourceOrder?.tags?.length > 0) {
      changeOrderData([{
        id: sourceOrder.id,
        groupIndex: null,
        tag: null
      }])
      modifyOrder({
        id: sourceOrder.id,
        groupIndex: null,
        removeTag: sourceOrder.tags[0]
      })
    }
    else {
      if (curTag) {
        changeOrderData([
          {
            id: sourceOrder.id,
            groupIndex: 0,
            tag: curTag
          },
          {
            id: destOrder.id,
            groupIndex: 1,
            tag: curTag,
          }
        ])
        modifyOrder({
          id: sourceOrder.id,
          groupIndex: 0,
          tag: curTag
        })
        modifyOrder({
          id: destOrder.id,
          groupIndex: 1,
          tag: curTag
        })
        setLastTag(curTag.tag)
      }
    }
  };

  const modifyOrder = ({
    id,
    groupIndex,
    tag,
    removeTag
  }: {
    id: number;
    groupIndex?: number | null;
    tag?: OrderTag | null;
    removeTag?: OrderTag | null;
  }) => {
    const order: { id: number; groupIndex?: number | null; tag_id?: number | null } = { id }
    
    if (typeof groupIndex !== 'undefined') {
      order.groupIndex = groupIndex;
    }
    if (tag?.id) {
      addOrderTag({
        variables: {
          order_id: id,
          tag_id: tag.id
        },
      });
    }
    if (removeTag?.id) {
      removeOrderTag({
        variables: {
          order_id: id,
          tag_id: removeTag.id
        },
      });
    }
    updateTag({
      variables: {
        order,
      },
    });
  }

  return (
    <>
      <Row ref={itemRef}>
        <Col>
          {ordersLoading && ordersLoading ? (
            <Section margin="0rem 0rem 0rem 0.5rem">
              <Skeleton
                classN="table_skeleton"
                height={
                  localStorage.getItem(LocalStorageKeys.LIMIT) === "OPD"
                    ? `${myOrdersPagVar.limit * 50 + 50}px`
                    : `${maxH - 20}px`
                }
              />
            </Section>
          ) : (
            <>
              {userHasOrderManagementModule && sortedOrders && sortedOrders.length > 0 && (
                <Section margin="0rem 0rem 0rem 0.5rem" overflowDir={"h"}>
                  <Table>
                    <MyOrdersTableHead />
                    <Tbody
                      maxH={
                        localStorage.getItem(LocalStorageKeys.LIMIT) === 'OPD'
                          ? 'none'
                          : `${maxH - 80}px`
                      }
                      tBodyStyle={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                      <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="orderRows">
                            {(provided) => (
                              <Tr
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {(() => {
                                  let index = 0
                                  let mappedTags: any[] = []
                                  return sortedOrders?.flatMap((row: OrderListingItem) => {
                                    if (row.tags && row.tags.length > 0 && !mappedTags.includes(row.tags[0])) {
                                      const filteredRows = [...sortedOrders]?.filter(item => item.tags?.[0] === row.tags?.[0]).sort((a, b) => a.groupIndex - b.groupIndex) || []
                                      const groupIndex = index
                                      index += filteredRows.length
                                      mappedTags.push(row.tags[0])
                                      return [
                                        <Div key={`group-${groupIndex}`} className="row-group" p="8px" m={{ t: "8px" }} rounded="md" border="2px dashed" borderColor="neutralGrey" textColor="light" hoverBorderColor="brand400" hoverTextColor="dark" transition="all 0.3s ease-in-out">
                                          <Div d="flex" align="center" m={{ b: "2px" }}>
                                            <Text tag="p" textSize="10" m={{ r: "8px" }}>
                                              {row.tags?.[0]?.tag}
                                            </Text>
                                            <Text
                                              className="row-edit"
                                              tag="p"
                                              textSize="10"
                                              textColor="primary"
                                              textWeight="700"
                                              textTransform="uppercase"
                                              onClick={() => {
                                                modalData({
                                                  name: "groupTable",
                                                  returned_value: null,
                                                  props: {
                                                    tag: row.tags?.[0] || undefined,
                                                    changeOrderData
                                                  }
                                                })
                                              }}>
                                              Edit
                                            </Text>
                                          </Div>
                                          {filteredRows.map((filteredRow, filteredIdx) => (
                                            <Tr className="order-outerrow" key={filteredRow.id}>
                                              <Draggable key={filteredRow.id} draggableId={filteredRow.id.toString()} index={groupIndex + filteredIdx}>
                                                {(provided) => (
                                                  <Tr
                                                    className="order-row"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <MyOrderRow
                                                      row={filteredRow}
                                                      view={myOrderViewVar}
                                                      isLoading={ordersLoading}
                                                      key={filteredRow.id}
                                                    />
                                                  </Tr>
                                                )}
                                              </Draggable>
                                            </Tr>
                                          ))}
                                        </Div>
                                      ]
                                    } else if (row.tags && row.tags.length === 0) {
                                      const itemIndex = index++
                                      return (
                                        <Tr className="order-outerrow" key={row.id}>
                                          <Draggable key={row.id} draggableId={row.id.toString()} index={itemIndex}>
                                            {(provided) => (
                                              <Tr
                                                className="order-row"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <MyOrderRow
                                                  row={row}
                                                  view={myOrderViewVar}
                                                  isLoading={ordersLoading}
                                                  key={row.id}
                                                />
                                              </Tr>
                                            )}
                                          </Draggable>
                                        </Tr>
                                      );
                                    }
                                    return null
                                  });
                                })()}
                              </Tr>
                            )}
                          </Droppable>

                      </DragDropContext>
                    </Tbody>
                  </Table>
                </Section>
              )}
              {!userHasOrderManagementModule && orders?.getOrders?.items && orders.getOrders.items.length > 0 && (
                <Section margin="0rem 0rem 0rem 0.5rem" overflowDir={"h"}>
                  <Table>
                    <MyOrdersTableHead />
                    <Tbody
                      maxH={
                        localStorage.getItem(LocalStorageKeys.LIMIT) === 'OPD'
                          ? 'none'
                          : `${maxH - 80}px`
                      }
                      tBodyStyle={{ overflowY: 'auto', overflowX: 'hidden' }}
                    >
                      {orders.getOrders.items.map(
                        (row: any, i) => (
                          <MyOrderRow
                            row={row}
                            view={myOrderViewVar}
                            isLoading={ordersLoading}
                            key={`table-row-${i}`}
                          />
                        )
                      )}
                    </Tbody>
                  </Table>
                </Section>
              )}
            </>
          )}
        </Col>
      </Row>
      {orders?.getOrders.total === 0 &&
      myOrdersPagVar.hasOwnProperty("search") ? (
        <Row m={{ r: width > 700 ? "1.5rem" : "0.5rem", t: "0rem" }}>
          <Col>
            <Tag
              p={{ l: "1rem" }}
              h="48px"
              w="100%"
              d="flex"
              justify="start"
              textColor="light"
            >
              We couldn’t find anything related to {myOrdersPagVar.search}.
            </Tag>
          </Col>
        </Row>
      ) : (
        orders?.getOrders.total === 0 &&
        !ordersLoading && (
          <Row
            maxW="440px"
            h={`${maxH}px`}
            justify="center"
            p="32px 8px 32px 0px"
            d="flex"
            m={{ x: "auto" }}
          >
            <Col d="flex" flexDir="column" justify="center" align="center">
              <Div m={{ x: "auto", t: "0.5rem" }} w="auto" h="auto">
                {renderIcon("AddOrder")}
              </Div>
              {me?.getUser.company?.status === CompanyStatus.Active ? (
                searchInput ||
                localStorage.getItem("myordersfilter") ||
                ordersLoading ? (
                  <>
                    <Text textColor="dark" textWeight="500" textSize={16}>
                      No orders found.
                    </Text>
                    <SecondaryBtn
                      h="48px"
                      w="100%"
                      styleBtn={{ marginTop: "1.5rem" }}
                      handleSubmit={(
                        e: React.ChangeEvent<HTMLButtonElement>
                      ) => {
                        e.preventDefault();

                        if (searchInput) {
                          setSearchInput("");
                          const qq = localStorage.getItem(
                            LocalStorageKeys.MY_ORDERS_FILTER
                          );
                          if (qq) {
                            myOrdersPag({
                              ...JSON.parse(qq),
                            });
                          } else {
                            myOrdersPag({
                              ...initialGetOrders,
                              limit: myOrdersPagVar.limit,
                              page: 1,
                            });
                          }
                        } else {
                          localStorage.removeItem(
                            LocalStorageKeys.MY_ORDERS_FILTER
                          );
                          myOrdersPag({
                            ...initialGetOrders,
                            limit: myOrdersPagVar.limit,
                            page: 1,
                          });
                        }
                      }}
                      isLoading={ordersLoading}
                      text={`Reset filter`}
                    />
                  </>
                ) : (
                  <>
                    <Text
                      textColor="dark"
                      textWeight="500"
                      textAlign="center"
                      textSize={16}
                    >
                      {" "}
                      You have not made any orders on Cargobite.
                    </Text>
                    <PrimaryBtn
                      h="48px"
                      w="100%"
                      styleBtn={{ marginTop: "1.5rem" }}
                      handleSubmit={() => history.push("/createneworder/order")}
                      isLoading={false}
                      text={"Make my first order"}
                    />
                  </>
                )
              ) : (
                <Text
                  textColor="dark"
                  textWeight="500"
                  textAlign="center"
                  textSize={16}
                >
                  Welcome! We will review your account to ensure a seamless
                  experience. Once approved, get ready to unlock all new
                  features and make the most of our platform!
                </Text>
              )}
            </Col>
          </Row>
        )
      )}
      {totalPages && (
        <Row>
          <Col>
            <Pagination
              setQuery={myOrdersPag}
              query={myOrdersPagVar}
              total={totalPages}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(MyOrdersTable);
