import { Anchor, Div, Icon, Input, Text } from "atomize";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { modalData } from "../../../App";
import renderIcon from "../../../assets/Icons";
import DropdownClassic from "../../../components/DropdownClassic/DropdownClassic";
import { GlobalTheme } from "../../../components/GlobalTheme";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
import Skeleton from "../../../components/Skeleton/Skeleton";
import StatusBadge from "../../../components/StatusBadge/StatusBadge";
import Td from "../../../components/TableComponents/Td";
import Tr from "../../../components/TableComponents/Tr";
import TertiaryBtn from "../../../components/TertiaryBtn/TertiaryBtn";
import {
  AssignedUserType,
  CompanyStatus,
  GetOrdersDocument,
  OrderListingItem,
  OrderStatus,
  SqlModelStatus,
  Vehicle,
  GetMeQuery,
  useGetMeQuery,
  useGetVehiclesLazyQuery,
  useUpdateOrderStatusMutation,
} from "../../../generated/graphql";
import useOnClickOutside from "../../../helpers/CustomHooks/useOnClickOutside";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import {
  StatusOption,
  StatusOptions,
  registrationPlateNumberFormat,
  toReadValue,
  toReadValueReturnColor,
  toReadValueReturnBg
} from "../../../helpers/functions";

Moment.globalLocal = true;

const MyOrderRow: React.FC<{
  row: OrderListingItem;
  isLoading: boolean;
  view: Array<{
    field: string;
    name?: string;
    checked: boolean;
    colW: number;
  }>;
}> = ({ row, isLoading, view }) => {
  const { data: me } = useGetMeQuery();
  const [statusDropdown, setstatusDropdown] = useState<boolean>(false);
  const [
    updateOrderStatus,
    {
      data: updateOrderStatusData,
      loading: updateOrderStatusLoading,
      error: updateOrderStatusError,
    },
  ] = useUpdateOrderStatusMutation({ errorPolicy: "all" });
  const history = useHistory();
  const queryVehicle = useGetVehiclesLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [vehicleDropdown, setVehicleDropdown] = useState<boolean>(false);
  const {
    data: vehicles,
    loading: vehiclesLoading,
    search: vehicleInput,
    setSearch: setVehicleInput,
    page: vehicleInputPage,
    setPage: setVehicleInputPage,
  } = useSearch(
    queryVehicle,
    { limit: 3, status: [SqlModelStatus.Active] },
    300,
    me && vehicleDropdown
  );
  const hasAssignedDriver = !isEmpty(row.vehicleRegistration);

  useEffect(() => {
    if (statusDropdown) {
      setVehicleDropdown(false);
    }
  }, [statusDropdown]);

  const [dropdownCordinates, setdropdownCordinates] = useState({
    l: 0,
    t: 0,
    w: 0,
    h: 0,
  });

  const setDivPositionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setdropdownCordinates({
      l: Number(setDivPositionRef.current?.getBoundingClientRect().x),
      t: Number(setDivPositionRef.current?.getBoundingClientRect().top),
      w: Number(setDivPositionRef.current?.getBoundingClientRect().width),
      h: me?.getUser.roles![0].id === 1 ? 390 : 260,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDivPositionRef, statusDropdown]);

  const [dropdownVehicleCordinates, setDropdownVehicleCordinates] = useState({
    l: 0,
    t: 0,
    b: 0,
    w: 0,
    h: 0,
  });

  const setDivVehiclenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDropdownVehicleCordinates({
      l: Number(setDivVehiclenRef.current?.getBoundingClientRect().x) - 1,
      t: Number(setDivVehiclenRef.current?.getBoundingClientRect().top),
      b: Number(setDivVehiclenRef.current?.getBoundingClientRect().bottom),
      w: Number(setDivVehiclenRef.current?.getBoundingClientRect().width) + 2,
      h: me?.getUser.roles![0].id === 1 ? 390 : 260,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDivVehiclenRef, vehicleDropdown]);

  ErrorsBeHandler({
    error: updateOrderStatusError,
  });

  SuccessBeHandler({
    data: updateOrderStatusData,
    code: "ORDER_STATUS_CHANGE",
  });

  const statusOptions = StatusOptions();

  const isLocked = 
    !(
      row.userRole?.includes(AssignedUserType.Transporter) &&
      [1, 2, 3, 6].includes(me?.getUser?.roles?.[0]?.id as any) &&
      [CompanyStatus.Active].includes(me?.getUser?.company?.status as any)
    )

  const renderColumn = (field: string) => {
    const fieldData = view?.find((col: any) => col.field === field);
    const colW = fieldData?.colW;

    if (!fieldData?.checked) return null;

    switch (field) {
      case "code":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor="semiDark" textSize={142}>
              {row.code}
            </Text>
          </Td>
        );
      case "internalCode":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              me?.getUser.company?.status !== CompanyStatus.Active &&
                history.push(`/myorder/${row.code}`);
            }}
          >
            <Anchor
              textSize={142}
              textColor={
                me?.getUser.company?.status !== CompanyStatus.Active
                  ? "semiDark"
                  : row.userRole?.includes(AssignedUserType.Transporter)
                  ? row.transporterCompany_internalCode !== null
                    ? "semiDark"
                    : "semiLight"
                  : row.userRole?.includes(AssignedUserType.Owner)
                  ? row.internalCode !== null
                    ? "semiDark"
                    : "semiLight"
                  : row.userRole?.includes(AssignedUserType.Shipper)
                  ? row.shipperCompany_internalCode !== null
                    ? "semiDark"
                    : "semiLight"
                  : row.userRole?.includes(AssignedUserType.Receiver)
                  ? row.receiverCompany_internalCode !== null
                    ? "semiDark"
                    : "semiLight"
                  : "semiLight"
              }
              hoverTextColor="dark"
              d="block"
              textWeight="400"
              onClick={() => {
                me?.getUser.company?.status === CompanyStatus.Active &&
                  modalData({
                    msg: "Create internal number",
                    name: "internalNumber",
                    returned_value: null,
                    props: {
                      transport_id: 1,
                      order_id: row.id,
                    },
                  });
              }}
            >
              {row.userRole?.includes(AssignedUserType.Transporter)
                ? row.transporterCompany_internalCode !== null
                  ? row.transporterCompany_internalCode
                  : "None"
                : row.userRole?.includes(AssignedUserType.Owner)
                ? row.internalCode !== null
                  ? row.internalCode
                  : "None"
                : row.userRole?.includes(AssignedUserType.Shipper)
                ? row.shipperCompany_internalCode !== null
                  ? row.shipperCompany_internalCode
                  : "None"
                : row.userRole?.includes(AssignedUserType.Receiver)
                ? row.receiverCompany_internalCode !== null
                  ? row.receiverCompany_internalCode
                  : "None"
                : "None"}
            </Anchor>
          </Td>
        );

      case "status":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              (statusOptions.length === 0 ||
                me?.getUser.company?.status !== CompanyStatus.Active) &&
                history.push(`/myorder/${row.code}`);
            }}
          >
            <Div w="100%" ref={setDivPositionRef} d="flex">
              {updateOrderStatusLoading || isLoading ? (
                <Skeleton width="90%" height="30px" />
              ) : (
                <DropdownClassic
                  isInTable={true}
                  border={false}
                  isLocked={
                    statusOptions.length === 0 ||
                    me?.getUser.company?.status !== CompanyStatus.Active
                  }
                  isOpen={statusDropdown}
                  menuList={
                    <DropdownStatus
                      dropdownCordinates={dropdownCordinates}
                      row={row}
                      statusDropdown={statusDropdown}
                      setstatusDropdown={setstatusDropdown}
                      updateOrderStatus={updateOrderStatus}
                      options={statusOptions}
                    />
                  }
                  setIsOpen={setstatusDropdown}
                >
                  <StatusBadge
                    color={toReadValueReturnColor(row.status!)}
                    bgColor={toReadValueReturnBg(row.status!)}
                    minus={row.status! === OrderStatus.Cancelled || row.status! === OrderStatus.Damaged}
                  />
                  <Text
                    textColor={"semiDark"}
                    textSize={142}
                    m={{ r: "auto", l: "0.5rem" }}
                    textTransform="capitalize"
                  >
                    {toReadValue(row.status!)}
                  </Text>
                </DropdownClassic>
              )}
            </Div>
          </Td>
        );

      case "vehicleRegistration":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              isLocked && history.push(`/myorder/${row.code}`);
            }}
          >
            <Div
              w="100%"
              ref={setDivVehiclenRef}
              style={
                !hasAssignedDriver
                  ? {
                      background: "#FFEAEA",
                      borderRadius: 6,
                      padding: "0.25rem 0.65rem",
                    }
                  : {}
              }
            >
              <DropdownClassic
                isInTable={true}
                border={false}
                isLocked={isLocked}
                isOpen={vehicleDropdown}
                h="100%"
                showDropIcon={false}
                menuList={
                  <DropdownInput
                    me={me}
                    dropdownVehicleCordinates={dropdownVehicleCordinates}
                    row={row}
                    dropdown={vehicleDropdown}
                    setVehicleInput={setVehicleInput}
                    setDropdown={setVehicleDropdown}
                    isLoading={vehiclesLoading}
                    vehicleInput={vehicleInput}
                    vehicleInputPage={vehicleInputPage}
                    setVehicleInputPage={setVehicleInputPage}
                    vehicles={vehicles}
                  />
                }
              >
                <Div
                  d="flex"
                  align="center"
                  h="100%"
                  w="100%"
                  m={{ r: "0" }}
                  onClick={() => {
                    setVehicleDropdown(!vehicleDropdown);
                  }}
                >
                  {!hasAssignedDriver && renderIcon("WarningRed")}
                  <Text
                    textColor={hasAssignedDriver ? "dark" : `semiLight`}
                    textWeight="500"
                    textSize={142}
                    m={!hasAssignedDriver ? { l: "0.65rem" } : {}}
                  >
                    {hasAssignedDriver ? row.vehicleRegistration : `None`}
                  </Text>
                  {!isLocked &&
                    (!vehicleDropdown ? (
                      <Icon
                        m={{ l: "auto" }}
                        name="DownArrow"
                        size="18px"
                        color={"semiDark"}
                      />
                    ) : (
                      <Icon
                        m={{ l: "auto" }}
                        name="UpArrow"
                        size="18px"
                        color={"semiDark"}
                      />
                    ))}
                </Div>
              </DropdownClassic>
            </Div>
          </Td>
        );

      case "dispatchers":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              (me?.getUser.roles![0].id === 1 ||
                row.status === OrderStatus.Deleted ||
                row.status === OrderStatus.Stored ||
                me?.getUser.company?.status !== CompanyStatus.Active) &&
                history.push(`/myorder/${row.code}`);
            }}
          >
            {row?.dispatchers && row.dispatchers.filter(
              (user) => user.type !== AssignedUserType.Driver
            )!.length > 0 ? (
              <Text
                textColor={"semiDark"}
                style={{ verticalAlign: "middle" }}
                className="i_b"
                textSize={142}
              >
                {row.dispatchers!.map((user: any, i: number) =>
                  user.type !== AssignedUserType.Driver
                    ? row.dispatchers!.length - 1 === i
                      ? `${user.firstName} ${user.lastName}`
                      : `${user.firstName} ${user.lastName}, `
                    : ""
                )}
              </Text>
            ) : (
              <Anchor
                d="flex"
                textColor="semiDark"
                hoverTextColor="dark"
                textWeight="400"
                onClick={() => {
                  if (
                    !(
                      me?.getUser.roles![0].id === 1 ||
                      row.status === OrderStatus.Deleted ||
                      row.status === OrderStatus.Stored ||
                      me?.getUser.company?.status !== CompanyStatus.Active
                    )
                  ) {
                    modalData({
                      msg: "Assign dispacher",
                      name: "assignUserToOrder",
                      returned_value: null,
                      props: {
                        user_id: null,
                        order_id: row.id,
                        options: row.userRole,
                        code: row.code,
                      },
                    });
                  }
                }}
              >
                None
              </Anchor>
            )}
          </Td>
        );

      case "shipperCompany_name":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <PreloadingImage
              imageSrc={row.shipperCompany_logo!}
              skeletonComponent={
                <Skeleton height="32px" width="32px" borderRadius="32px" />
              }
              imgComponent={<ShortNameLogo name={row.shipperCompany_name || ''} />}
              imageClassNames="i_b f_l"
              imageStyle={{
                width: "32px",
                height: "32px",
                borderRadius: "32px",
                verticalAlign: "middle",
              }}
            />

            <Text
              textColor={"semiDark"}
              m={{ l: "0.75rem" }}
              style={{ verticalAlign: "middle" }}
              className="i_b"
              textSize={142}
            >
              {row.shipperCompany_name}
            </Text>
          </Td>
        );

      case "receiverCompany_name":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <>
              <PreloadingImage
                imageSrc={row.receiverCompany_logo!}
                skeletonComponent={
                  <Skeleton height="32px" width="32px" borderRadius="32px" />
                }
                imgComponent={
                  <ShortNameLogo name={row.receiverCompany_name || ''} />
                }
                imageClassNames="i_b f_l"
                imageStyle={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  verticalAlign: "middle",
                }}
              />
              <Text
                textColor={"semiDark"}
                m={{ l: "0.75rem" }}
                className="i_b"
                style={{ verticalAlign: "middle" }}
                textSize={142}
              >
                {row.receiverCompany_name}
              </Text>
            </>
          </Td>
        );

      case "transporterCompany_name":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            {row.transporterCompany_id ? (
              <>
                <PreloadingImage
                  imageSrc={row.transporterCompany_logo!}
                  skeletonComponent={
                    <Skeleton height="32px" width="32px" borderRadius="32px" />
                  }
                  imgComponent={
                    <ShortNameLogo name={row.transporterCompany_name || ''} />
                  }
                  imageClassNames="i_b f_l"
                  imageStyle={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "32px",
                    verticalAlign: "middle",
                  }}
                />
                <Text
                  textColor={"semiDark"}
                  m={{ l: "0.75rem" }}
                  className="i_b"
                  style={{ verticalAlign: "middle" }}
                  textSize={142}
                >
                  {row.transporterCompany_name}
                </Text>
              </>
            ) : (
              <Text
                textColor={"semiDark"}
                className="i_b"
                style={{ verticalAlign: "middle" }}
                textSize={142}
              >
                None
              </Text>
            )}
          </Td>
        );

      case "loadingLocation_country_name":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text>{row.loadingLocation_country_name}</Text>
          </Td>
        );
      case "loadingLocation_postName":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.loadingLocation_postName}
            </Text>
          </Td>
        );
      case "loadingLocation_postCode":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.loadingLocation_postCode}
            </Text>
          </Td>
        );
      case "loadingLocation_address":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.loadingLocation_address}
            </Text>
          </Td>
        );
      case "unloadingLocation_country_name":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text>{row.unloadingLocation_country_name}</Text>
          </Td>
        );
      case "unloadingLocation_postName":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.unloadingLocation_postName}
            </Text>
          </Td>
        );
      case "unloadingLocation_postCode":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.unloadingLocation_postCode}
            </Text>
          </Td>
        );
      case "unloadingLocation_address":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.unloadingLocation_address}
            </Text>
          </Td>
        );
      case "stackable":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.stackable ? "Yes" : "No"}
            </Text>
          </Td>
        );
      case "dangerous":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row.dangerous ? "Yes" : "No"}
            </Text>
          </Td>
        );
      case "requestedLoadingTime":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {moment(row.requestedLoadingTime).format("DD/MMM/YYYY HH:mm")}
            </Text>
          </Td>
        );
      case "requestedUnloadingTime":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {moment(row.requestedUnloadingTime).format("DD/MMM/YYYY HH:mm")}
            </Text>
          </Td>
        );
      case "marketExpirationDate":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            {row.marketExpirationDate ? (
              <Moment to={row.marketExpirationDate}>{Date.now()}</Moment>
            ) : (
              "None"
            )}
          </Td>
        );
      case "price":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            {row?.price ?? "Not set"}
          </Td>
        );
      case "hasPoD":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row?.hasPoD ? "Yes" : "No"}
            </Text>
          </Td>
        );
      case "hasInvoice":
        return (
          <Td
            key={field}
            width={`${colW}px`}
            handleClick={() => {
              history.push(`/myorder/${row.code}`);
            }}
          >
            <Text textColor={"semiDark"} textSize={142}>
              {row?.hasInvoice ? "Yes" : "No"}
            </Text>
          </Td>
        );

      default:
        return null;
    }
  };

  return (
    <Tr className="link">
      {view?.map((column) => renderColumn(column.field))}
    </Tr>
  );
};

export default MyOrderRow;

const DropdownInput: React.FC<{
  me: GetMeQuery | undefined,
  row: OrderListingItem;
  dropdown: boolean;
  dropdownVehicleCordinates: {
    l: number;
    t: number;
    b: number;
    w: number;
    h: number;
  };
  setDropdown: (dropdown: boolean) => void;
  vehicleInput: string;
  isLoading: boolean;
  vehicles: any;
  setVehicleInput: (vehicleInput: string) => void;
  vehicleInputPage: number;
  setVehicleInputPage: (vehicleInputPage: number) => void;
}> = ({
  me,
  row,
  dropdown,
  dropdownVehicleCordinates,
  setDropdown,
  vehicleInput,
  isLoading,
  vehicles,
  setVehicleInput,
  vehicleInputPage,
  setVehicleInputPage,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const [close, setClose] = useState(false);
  const [total, setTotal] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setScale(0);
    setOpacity(0);
    setTimeout(() => {
      setDropdown(false);
    }, 300);
  });

  const portalContainer = document.getElementById("tbody");

  portalContainer?.addEventListener("wheel", (event: any) => {
    event.preventDefault();
    setClose(true);
    setScale(0);
    setOpacity(0);
    setDropdown(false);
  });

  const [scale, setScale] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    vehicles?.getVehicles &&
      vehicles.getVehicles.total &&
      setTotal(vehicles!.getVehicles.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles?.getVehicles.total]);

  useEffect(() => {
    setTimeout(() => {
      setScale(1);
      setOpacity(1);
    }, 100);
  }, []);

  useEffect(() => {
    setScale(0);
    setOpacity(0);
  }, [dropdown]);

  const handleChangeVehicle = (e: any) => {
    setVehicleInput(registrationPlateNumberFormat(e.toUpperCase()));
  };

  if (close) return null;

  return ReactDOM.createPortal(
    <Div
      ref={ref}
      tabIndex={0}
      shadow={
        GlobalTheme.shadows[
          windowHeight >
          dropdownVehicleCordinates.h + dropdownVehicleCordinates.t
            ? 4
            : 6
        ]
      }
      style={{
        transition: "0.3s transform ease-in-out, 0.3s opacity ease-in-out",
        transform: `scaleY(${scale}`,
        opacity: opacity,
        transformOrigin:
          windowHeight >
          dropdownVehicleCordinates.h + dropdownVehicleCordinates.t
            ? "50% 0%"
            : "0% 100%",
        position: "absolute",
        left: `${dropdownVehicleCordinates.l}px`,
        top: `${
          windowHeight >
          dropdownVehicleCordinates.h + dropdownVehicleCordinates.t
            ? `${dropdownVehicleCordinates.t + 32}px`
            : "auto"
        }`,
        bottom: `${
          windowHeight >
          dropdownVehicleCordinates.h + dropdownVehicleCordinates.t
            ? `auto`
            : `${windowHeight - dropdownVehicleCordinates.t}px`
        }`,
        zIndex: 20000,
        background: GlobalTheme.colors.background,
        padding: "0 0.5rem",
        width: `${dropdownVehicleCordinates.w}px`,
        borderRadius: GlobalTheme.rounded[4],
      }}
    >
      <Input
        m={{ t: "1rem", b: "0.5rem", x: "0.25rem" }}
        placeholder="Search"
        value={vehicleInput}
        autoFocus
        onChange={(e: any) => handleChangeVehicle(e.target.value)}
        suffix={
          isLoading ? (
            <Icon
              name="Loading"
              size="20px"
              pos="absolute"
              top="50%"
              right="1rem"
              transform="translateY(-50%)"
            />
          ) : (
            <Icon
              name="Search"
              size="20px"
              cursor="pointer"
              pos="absolute"
              top="50%"
              right="1rem"
              transform="translateY(-50%)"
            />
          )
        }
      />
      {vehicles?.getVehicles.total && vehicles.getVehicles.total > 3 ? (
        <Div p={{ r: "0.75rem" }} d="flex" justify="flex-end" w="100%">
          <Icon
            name="LongLeft"
            size="20px"
            hovercolor={1 < vehicleInputPage! ? "primary" : "light"}
            color={1 < vehicleInputPage! ? "brand600" : "light"}
            cursor="pointer"
            onClick={() => {
              1 < vehicleInputPage && setVehicleInputPage(vehicleInputPage - 1);
            }}
          />
          <Icon
            name="LongRight"
            size="20px"
            hovercolor={
              Math.ceil(total) - vehicleInputPage * 3 > 0 ? "primary" : "light"
            }
            color={
              Math.ceil(total) - vehicleInputPage * 3 > 0 ? "brand600" : "light"
            }
            cursor="pointer"
            onClick={() => {
              Math.ceil(total) - vehicleInputPage * 3 > 0 &&
                setVehicleInputPage(vehicleInputPage + 1);
            }}
          />
        </Div>
      ) : null}
      <Div
        maxH="20rem"
        overflow="auto"
        onClick={() => {
          setScale(0);
          setOpacity(0);
          setDropdown(false);
        }}
      >
        {vehicles?.getVehicles.items?.map((vehicle: Vehicle, i: number) => (
          <Anchor
            d="flex"
            p={{ y: "0.5rem", x: "0.5rem" }}
            key={i}
            align="center"
            justify="space-between"
          >
            <Text
              textColor="semiDark"
              hoverTextColor="dark"
              onClick={async () => {
                modalData({
                  msg: "Add driver contact",
                  name: "changeVehicleAddDriver",
                  returned_value: null,
                  props: {
                    vehicle_id: vehicle.id,
                    order_id: row?.id,
                    driverUser_id: row?.driver_id,
                    driver_owner: vehicle.driverUser_id,
                    id: row.transport_id,
                    order_code: row.code,
                  },
                });
              }}
            >
              {vehicle.registration!}
            </Text>
          </Anchor>
        ))}
      </Div>
      {[1, 2, 6].includes(me?.getUser?.roles?.[0]?.id as any) && <TertiaryBtn
        text={"Add new"}
        h="40px"
        prefixIcon={
          <Icon name="Add" color="info700" size="18px" m={{ r: "0.5rem" }} />
        }
        w={"calc(100% - 0.5rem)"}
        styleBtn={{ margin: "0rem 0.25rem 0.75rem" }}
        handleSubmit={() => {
          setScale(0);
          setOpacity(0);
          setDropdown(false);
          modalData({
            msg: "Create Vehicle",
            name: "createVehicle",
            returned_value: null,
            props: { company_id: row.transporterCompany_id, order_id: row.id },
          });
        }}
      />}
    </Div>,
    document.body
  );
};

// StatusOptions
const DropdownStatus: React.FC<{
  row: OrderListingItem;
  updateOrderStatus: any;
  statusDropdown: boolean;
  options: StatusOption[];
  dropdownCordinates: { l: number; t: number; w: number; h: number };
  setstatusDropdown: (statusDropdown: boolean) => void;
}> = ({
  row,
  updateOrderStatus,
  statusDropdown,
  options,
  dropdownCordinates,
  setstatusDropdown,
}) => {
  const history = useHistory();
  const { height: windowHeight } = useWindowDimensions();
  const [close, setClose] = useState(false);

  const portalContainer: any = document.getElementById("tbody");

  portalContainer.addEventListener(
    "wheel",
    (event: React.ChangeEvent<Element>) => {
      event.preventDefault();
      setClose(true);
      setScale(0);
      setOpacity(0);
      setstatusDropdown(false);
    }
  );

  const [scale, setScale] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScale(1);
      setOpacity(1);
    }, 100);
  }, []);

  useEffect(() => {
    setScale(0);
    setOpacity(0);
  }, [statusDropdown]);

  if (close) return null;

  return ReactDOM.createPortal(
    <Div
      onClick={() => setstatusDropdown(true)}
      shadow={
        GlobalTheme.shadows[
          windowHeight > dropdownCordinates.h + dropdownCordinates.t ? 4 : 6
        ]
      }
      style={{
        transition: "0.3s transform ease-in-out, 0.3s opacity ease-in-out",
        transform: `scaleY(${scale}`,
        opacity: opacity,
        transformOrigin:
          windowHeight > dropdownCordinates.h + dropdownCordinates.t
            ? "50% 0%"
            : "0% 100%",
        position: "fixed",
        left: `${dropdownCordinates.l}px`,
        top: `${
          windowHeight > dropdownCordinates.h + dropdownCordinates.t
            ? `${dropdownCordinates.t + 40}px`
            : "auto"
        }`,
        bottom: `${
          windowHeight > dropdownCordinates.h + dropdownCordinates.t
            ? `auto`
            : `${windowHeight - dropdownCordinates.t}px`
        }`,
        zIndex: 20000,
        background: GlobalTheme.colors.background,
        padding: "0 0.75rem",
        width: `${dropdownCordinates.w}px`,
        borderRadius: GlobalTheme.rounded[4],
      }}
      tabIndex={0}
    >
      {options?.map((status: StatusOption, index: number) => {
        if (status === undefined || isEmpty(status)) return null;

        return (
          <Div
            key={index}
            d="flex"
            justify="center"
            align="center"
            m={{ l: "2px" }}
          >
            <StatusBadge
              color={status.color}
              bgColor={status.bgColor}
              minus={status.value === OrderStatus.Cancelled || status.value === OrderStatus.Damaged}
            />
            <Anchor
              textSize={142}
              m={{ r: "auto", l: "8px" }}
              textColor="semiDark"
              hoverTextColor="dark"
              textTransform="capitalize"
              key={index}
              onClick={() => {
                if (
                  !(row.marketExpirationDate > moment().format()) &&
                  status.value === OrderStatus.OnMarket
                ) {
                  modalData({
                    msg: "Order expired",
                    name: "confirmModal",
                    returned_value: null,
                    props: {
                      icon: "AlertSolid",
                      iconColor: "warning700",
                      subtext:
                        "Your order expiration date has expired. To receive bids on this order, you need to update the expiration date.",
                      isLoading: false,
                      handleSubmit: () => {
                        history.push(`/createneworder/order/${row.code}`);
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        });
                      },
                      primaryBtn: "Update Order",
                      secondaryBtn: "Cancel",
                    },
                  });
                } else {
                  updateOrderStatus({
                    variables: {
                      status: status.value,
                      id: Number(row.id),
                    },
                    refetchQueries: [GetOrdersDocument],
                    awaitRefetchQueries: true,
                  });
                }
              }}
            >
              {status.name}
            </Anchor>
          </Div>
        );
      })}
    </Div>,
    document.body
  );
};
