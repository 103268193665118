import React from "react";
import { Icon, Div } from "atomize";

interface PaginationProps {
  setQuery: (query: any) => void;
  query: any;
  total?: number;
}

const Pagination: React.FC<PaginationProps> = ({ setQuery, query, total }) => {
  const generateNumbers = () => {
    if (total) {
      let pagesArray = [];
      for (let index = 0; index < total; index++) {
        pagesArray.push(index + 1);
      }
      if (total > 8) {
        let sliceStart = query.page - 3;
        let sliceEnd = query.page + 3;

        if (query.page <= 4) {
          sliceStart = 1;
          sliceEnd = 8;
        }
        if (query.page >= total - 4) {
          sliceStart = total - 8;
          sliceEnd = total;
        }

        let updatePagesArray = pagesArray.slice(sliceStart, sliceEnd);
        let newArr = updatePagesArray.filter((e) => e !== 1);
        newArr = newArr.filter((e) => e !== total);
        newArr.unshift(1);
        newArr.push(total);
        return newArr;
      } else {
        return pagesArray;
      }
    } else {
      return [];
    }
  };

  return (
    <Div
      m={{ t: "0.5rem" }}
      w="auto"
      justify="flex-start"
      d="flex"
      align="center"
    >
      {total && total > 1 && (
        <>
          <Div justify="flex-start" d="flex" align="center">
            {total && (
              <Icon
                name="LeftArrow"
                size="30px"
                hovercolor={query.page !== 1 ? "primary" : "light"}
                color={query.page !== 1 ? "brand600" : "light"}
                cursor="pointer"
                onClick={
                  query.page === 1
                    ? null
                    : () =>
                        setQuery({
                          ...query,
                          page: query.page - 1,
                        })
                }
              />
            )}
          </Div>
          <Div d="flex">
            {total &&
              generateNumbers().map((number, i) => {
                return (
                  <Div d="flex" key={i}>
                    {total > 8 &&
                      query.page < total - 3 &&
                      number === total &&
                      generateNumbers()[generateNumbers().length - 2] !==
                        total - 1 && (
                        <Div
                          textSize={16}
                          textWeight="600"
                          m={{ xs: "0.125rem" }}
                          cursor="pointer"
                          hoverTextColor="semiDark"
                          textColor={
                            number === query.page ? "semiDark" : "light"
                          }
                        >
                          ...
                        </Div>
                      )}

                    <Div
                      textSize={16}
                      textWeight="600"
                      m={{ xs: "0.125rem" }}
                      cursor="pointer"
                      hoverTextColor="semiDark"
                      textColor={number === query.page ? "semiDark" : "light"}
                      onClick={() =>
                        setQuery({
                          ...query,
                          page: number,
                        })
                      }
                    >
                      {number}
                    </Div>
                    {total > 8 &&
                      query.page > 3 &&
                      number === 1 &&
                      generateNumbers()[1] !== 2 && (
                        <Div
                          textSize={16}
                          textWeight="600"
                          m={{ xs: "0.125rem" }}
                          cursor="pointer"
                          hoverTextColor="semiDark"
                          textColor={
                            number === query.page ? "semiDark" : "light"
                          }
                        >
                          ...
                        </Div>
                      )}
                  </Div>
                );
              })}
          </Div>
          <Div justify="flex-start" d="flex" align="center">
            {total && (
              <Icon
                name="RightArrow"
                size="30px"
                hovercolor={query.page !== total ? "primary" : "light"}
                color={query.page !== total ? "brand600" : "light"}
                cursor="pointer"
                onClick={
                  query.page === total
                    ? null
                    : () =>
                        setQuery({
                          ...query,
                          page: query.page + 1,
                        })
                }
              />
            )}
          </Div>
        </>
      )}
    </Div>
  );
};

export default Pagination;
