import React from "react";
import * as Styled from "./ProgressLine.styles";

interface ProgressLineProps {
  allSteps: number;
  step: number;
}

const ProgressLine: React.FC<ProgressLineProps> = ({ allSteps, step }) => {
  const sstep = step > allSteps ? allSteps : step;

  return (
    <Styled.ProgressLine>
      <Styled.Line width={`${(100 * sstep) / allSteps}%`}></Styled.Line>
    </Styled.ProgressLine>
  );
};

export default ProgressLine;
