import React, { useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Text,
  Input,
  Icon,
  Div,
  Anchor,
} from "atomize";
import renderIcon from "../../assets/Icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  Country,
  useGetCountriesLazyQuery,
  useRegisterUserMutation,
} from "../../generated/graphql";
import Border from "../../components/Border/Border";
import { getParams } from "../../helpers/functions";
import PrefixDropdown from "../../components/PrefixDropdown/PrefixDropdown";
import * as yup from "yup";
import { useSearch } from "../../helpers/CustomHooks/useSearch";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import jwt_decode from "jwt-decode";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";

let schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required({ name: "First name", code: "REQUIRED_FIELD" }),
  lastName: yup
    .string()
    .trim()
    .required({ name: "Last name", code: "REQUIRED_FIELD" }),
  email: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email("Email is not a valid."),
  password: yup.string().min(8, "Password should have at least 8 characters."),
  phone: yup
    .string()
    .trim()
    .required({ name: "Email", code: "REQUIRED_FIELD" }),
  phoneCountryNumbers: yup
    .string()
    .trim()
    .required({ name: "Email", code: "REQUIRED_FIELD" }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const RegisterUser: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const search = useLocation().search;
  const token = getParams(search, "token", "?token=");
  const history = useHistory();
  const { validator } = ValidationsFeHandler();

  const decodedToken: any = token ? jwt_decode(token) : "";

  const email = decodedToken?.email;

  const [registerUser, { data, loading, error }] = useRegisterUserMutation({
    errorPolicy: "all",
  });

  const queryCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: countries,
    loading: loadingCountries,
    search: countryInput,
    setSearch: setCountryInput,
  } = useSearch(queryCountries, { limit: 5 }, 300, isCountryDropOpen);

  const queryIbanCountries = useGetCountriesLazyQuery();
  const [isIbanCountryDropOpen, setIsIbanCountryDropOpen] = useState(false);
  const {
    data: dataIbanCountries,
    loading: loadingIbanCountries,
    search: searchIbanCountries,
    setSearch: setSearchIbanCountries,
  } = useSearch(queryIbanCountries, { limit: 5 }, 300, isIbanCountryDropOpen);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    phoneCountryNumbers: "365",
    confirmPassword: "",
  });

  const [placeholder, setPlaceholder] = useState({
    BIC: "",
    IBAN: "",
    IBAN_country: "SI",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    decodedToken.isPlaceholder === true
      ? validator({
          schema: schema,
          data: { ...user, token, email: email },
          iban: `${placeholder.IBAN_country} ${placeholder.IBAN}`,
          bic: placeholder.BIC,
          success: () => {
            registerUser({
              variables: {
                inviteToken: token!,
                bic: `${placeholder.BIC}`,
                iban: `${placeholder.IBAN_country} ${placeholder.IBAN}`,
                user: {
                  email: email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  password: user.password,
                  phone: `(${user.phoneCountryNumbers})${user.phone}`.trim(),
                  profileImageUrl: "",
                },
              },
            });
          },
        })
      : validator({
          schema: schema,
          data: { ...user, token, email: email },
          success: () => {
            registerUser({
              variables: {
                inviteToken: token!,
                user: {
                  email: email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  password: user.password,
                  phone: `(${user.phoneCountryNumbers})${user.phone}`.trim(),
                  profileImageUrl: "",
                },
              },
            });
          },
        });
  };

  ErrorsBeHandler({
    error: error,
  });

  SuccessBeHandler({
    data: data,
    code: "REGISTER_USER",
    fn: () => {
      history.push("/signin");
    },
  });

  return (
    <Container
      maxW="440px"
      p="32px 20px 32px"
      h="calc(100vh - 60px)"
      align="center"
      justify="center"
      d="flex"
    >
      <Row flexDir="row" w="100%" d="flex">
        <Col d="flex" size="12" justify="center">
          {renderIcon("Logo")}
        </Col>
        <Col>
          <Text
            textSize="201"
            textAlign="center"
            textWeight="500"
            textColor="dark"
            m={{ x: { xs: "0rem" }, y: { xs: "2rem" } }}
          >
            Let’s get you set up with Cargobite.
          </Text>
        </Col>

        <Col size="12">
          <Input
            h="48px"
            placeholder="First name"
            name="firstName"
            textSize={"16"}
            value={user.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            m={{ b: "0.5rem" }}
          />
        </Col>

        <Col size="12">
          <Input
            h="48px"
            placeholder="Last name"
            name="lastName"
            textSize={"16"}
            value={user.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            type="text"
            m={{ b: "0.5rem" }}
          />
        </Col>
        <Col size="12 ">
          <PrefixDropdown
            inputValue={user.phone}
            countryInputValue={countryInput}
            placeholder="Phone"
            h="48px"
            addNew={true}
            format="## ### ### ###"
            displayResult={
              <Div
                d="flex"
                h="24px"
                align="center"
                justify="center"
                m={{ l: "auto", r: "-0.75rem" }}
                minW="35px"
              >
                <Text
                  textColor="dark"
                  textWeight="500"
                  textSize={16}
                  m={{ l: "0" }}
                >{`${user.phoneCountryNumbers}`}</Text>
              </Div>
            }
            isLoading={loadingCountries}
            isOpen={isCountryDropOpen}
            setIsOpen={setIsCountryDropOpen}
            setCountryInput={setCountryInput}
            handleChange={(value: string) => {
              setUser({
                ...user,
                phone: value,
              });
            }}
            menuList={countries?.getCountries.items?.map(
              (item: Country, i: number) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={i}
                  align="center"
                  onClick={() => {
                    setUser({
                      ...user,
                      phoneCountryNumbers: item.phoneCode!,
                    });
                    setIsCountryDropOpen(false);
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.phoneCode}`}
                  </Text>
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    p={{ l: "10px" }}
                  >
                    {" "}
                    {`${item.name}`}
                  </Text>
                </Anchor>
              )
            )}
          />
        </Col>
        {decodedToken.isPlaceholder === true && (
          <>
            <Col size="12 ">
              <Div h="8px"></Div>
            </Col>
            <Col size="12 ">
              <PrefixDropdown
                inputValue={placeholder?.IBAN}
                countryInputValue={searchIbanCountries}
                h="48px"
                format="## #### #### #### ###"
                placeholder="IBAN"
                addNew={true}
                displayResult={
                  <Div
                    d="flex"
                    h="24px"
                    align="center"
                    justify="center"
                    w="25px"
                    m={{ l: "0", r: "-0.75rem" }}
                  >
                    <Text
                      textColor="dark"
                      textWeight="500"
                      textSize={16}
                      m={{ l: "0" }}
                    >{`${placeholder?.IBAN_country}`}</Text>
                  </Div>
                }
                isLoading={loadingIbanCountries}
                isOpen={isIbanCountryDropOpen}
                setIsOpen={setIsIbanCountryDropOpen}
                setCountryInput={setSearchIbanCountries}
                handleChange={(value: any) => {
                  setPlaceholder({
                    ...placeholder,
                    IBAN: value,
                  });
                }}
                menuList={dataIbanCountries?.getCountries.items?.map(
                  (item: any, i: number) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={i}
                      align="center"
                      onClick={() => {
                        setPlaceholder({
                          ...placeholder,
                          IBAN_country: item.code,
                        });
                        setIsIbanCountryDropOpen(false);
                      }}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {" "}
                        {`${item.code}`}
                      </Text>
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        p={{ l: "10px" }}
                      >
                        {" "}
                        {`${item.name}`}
                      </Text>
                    </Anchor>
                  )
                )}
              />
            </Col>
            <Col size="12">
              <Input
                placeholder="BIC"
                name="BIC"
                value={placeholder.BIC}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length <= 11) {
                    setPlaceholder({
                      ...placeholder,
                      BIC: e.target.value,
                    });
                  }
                }}
                textSize={"16"}
                h="48px"
                type="text"
                m={{ t: { xs: "0.5rem" } }}
              />
            </Col>
          </>
        )}
        <Col size="12">
          <Input
            h="48px"
            placeholder="Password"
            textSize={"16"}
            type={showPassword ? "text" : "password"}
            m={{ y: "0.5rem" }}
            name="password"
            value={user.password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            suffix={
              <Button
                pos="absolute"
                onClick={() => setShowPassword(!showPassword)}
                bg="transparent"
                right="0.5rem"
                top="50%"
                h="48px"
                transform="translateY(-50%)"
                rounded={{ r: "md" }}
              >
                <Icon
                  name={showPassword ? "EyeSolid" : "Eye"}
                  color={showPassword ? "primary" : "info700"}
                  size="16px"
                  hovercolor="light"
                  transition
                />
              </Button>
            }
          />
        </Col>
        <Col size="12">
          <Input
            h="48px"
            placeholder="Confirm Password"
            textSize={"16"}
            type={showConfirmPassword ? "text" : "password"}
            m={{ b: "0.5rem" }}
            name="confirmPassword"
            value={user.confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            suffix={
              <Button
                pos="absolute"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                bg="transparent"
                right="0.5rem"
                top="50%"
                h="48px"
                transform="translateY(-50%)"
                rounded={{ r: "md" }}
              >
                <Icon
                  name={showConfirmPassword ? "EyeSolid" : "Eye"}
                  color={showConfirmPassword ? "primary" : "info700"}
                  size="16px"
                  hovercolor="light"
                  transition
                />
              </Button>
            }
          />
        </Col>

        <Col size="12">
          <PrimaryBtn
            h="48px"
            w="100%"
            styleBtn={{ marginTop: "1.5rem" }}
            handleSubmit={handleSubmit}
            isLoading={loading}
            text={"Submit"}
          />
        </Col>
        <Col>
          <Border margin={"32px 0 15px"} />
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterUser;
