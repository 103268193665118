import { useReactiveVar } from "@apollo/client";
import { Col, Div, Input, Row, Text } from "atomize";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { modalData, myOrdersPag } from "../../../App";
import renderIcon from "../../../assets/Icons";
import {
  useGetOrderLazyQuery,
  useGetOrdersLazyQuery,
  useUpdateTagMutation,
  useAddOrderTagMutation,
  useRemoveOrderTagMutation
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import DangerBtn from "../../DangerBtn/DangerBtn";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const GroupTable: FC = () => {
  const { name, props } = useReactiveVar(modalData);
  const myOrdersPagVar = useReactiveVar(myOrdersPag);
  const { width, height } = useWindowDimensions();

  const [groupTagId, setGroupTagId] = useState<number | null>(null);
  const [orderCode, setOrderCode] = useState<string>("");
  const [groupOrders, setGroupOrders] = useState<any>(undefined);
  const [addingOrder, setAddingOrder] = useState<boolean>(false);

  const isGroupTable = name === "groupTable";

  const [updateTag, { error: updateTagError }] = useUpdateTagMutation({
    errorPolicy: "all",
  });
  const [addOrderTag, { error: addOrderTagError }] = useAddOrderTagMutation({
    errorPolicy: "all",
  });
  const [removeOrderTag, { error: removeOrderTagError }] = useRemoveOrderTagMutation({
    errorPolicy: "all",
  });

  const [getOrders, { error: ordersErrors }] = useGetOrdersLazyQuery({
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data?.getOrders?.total) {
        if (data?.getOrders?.items) {
          setGroupOrders(
            [...data.getOrders.items].sort(
              (a, b) => (a.groupIndex ?? 0) - (b.groupIndex ?? 0)
            )
          );
        }
      }
    },
  });
  const [getOrder, { error: getOrderError }] = useGetOrderLazyQuery({
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data?.getOrder?.id) {
        const order = {
          id: data.getOrder.id,
          groupIndex: getLastGroupIndex() || null,
          tag_id: groupTagId || null,
        };
        if (groupTagId) {
          addOrderTag({
            variables: {
              order_id: data.getOrder.id,
              tag_id: groupTagId
            }
          })
        }
        updateTag({
          variables: {
            order,
          },
        }).then((data) => {
          setOrderCode("");
          setAddingOrder(false);
          if (typeof data.data?.updateTag?.id !== "undefined") {
            getOrders({
              variables: { query: { ...myOrdersPagVar, tag_id: groupTagId } },
            });
          }
        });
      } else {
        setOrderCode("");
        setAddingOrder(false);
      }
    },
  });

  const handleAdd = (e: any) => {
    e.preventDefault();
    if (addingOrder) return;
    let code = null;
    if (orderCode && /^\d+$/.test(orderCode)) code = orderCode;
    if (code) {
      setAddingOrder(true);
      getOrder({
        variables: { code },
      });
    }
  };
  const handleUngroup = async (e: any) => {
    e.preventDefault();
    await groupOrders.forEach((order: any, index: any) => {
      modifyOrder({
        id: order.id,
        groupIndex: null,
        tag: order?.tags?.[0]?.id || null
      })
    })
    modalData({
      name: "",
      msg: "",
      returned_value: null,
      props: null,
    });
  };

  const handleRemove = (orderId: number, orderTag: number) => {
    if (groupOrders) {
      if (props && props.changeOrderData) {
        props.changeOrderData([
          {
            id: orderId,
            groupIndex: null,
            tag: null,
          },
        ]);
      }
      modifyOrder({
        id: orderId,
        groupIndex: null,
        tag: orderTag
      })
      const newGroupOrders = [...groupOrders].filter((order: any) => order.id !== orderId)
      setGroupOrders(newGroupOrders)
    }
  };

  const getLastGroupIndex = () => {
    let lastGroupIndex = 0;
    for (const order of groupOrders) {
      if (order.groupIndex && order.groupIndex > lastGroupIndex) {
        lastGroupIndex = order.groupIndex;
      }
    }
    return lastGroupIndex + 1;
  };

  const modifyOrder = ({
    id,
    groupIndex,
    tag,
  }: {
    id: number;
    groupIndex?: number | null;
    tag?: number | null;
  }) => {
    const order: {
      id: number;
      groupIndex?: number | null;
      tag_id?: number | null;
    } = { id };

    if (typeof groupIndex !== "undefined") {
      order.groupIndex = groupIndex;
    }
    if (tag) {
      removeOrderTag({
        variables: {
          order_id: id,
          tag_id: tag,
        }
      })
    }
    updateTag({
      variables: {
        order,
      },
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination || !result.source) return;
    const destOrder = groupOrders[result.destination.index];
    const sourceOrder = groupOrders[result.source.index];

    if (!sourceOrder || !destOrder || sourceOrder === destOrder) return;

    // Change groupIndex in Parent Component
    if (props && props.changeOrderData) {
      props.changeOrderData([
        {
          id: sourceOrder.id,
          groupIndex: destOrder.groupIndex,
        },
        {
          id: destOrder.id,
          groupIndex: sourceOrder.groupIndex,
        },
      ]);
    }

    // Change groupIndex in render
    const newOrders = [...groupOrders].map((order: any) => ({ ...order }));
    newOrders[result.destination.index].groupIndex = sourceOrder.groupIndex;
    newOrders[result.source.index].groupIndex = destOrder.groupIndex;
    setGroupOrders(
      newOrders.sort(
        (a: any, b: any) => (a.groupIndex ?? 0) - (b.groupIndex ?? 0)
      )
    );

    // Change groupIndex in BE
    modifyOrder({
      id: sourceOrder.id,
      groupIndex: destOrder.groupIndex,
    });
    modifyOrder({
      id: destOrder.id,
      groupIndex: sourceOrder.groupIndex,
    });
  };

  useEffect(() => {
    if (isGroupTable) {
      if (!props?.tag?.id) return;
      if (props.tag.id !== groupTagId) {
        setGroupTagId(props.tag.id);
      }
      getOrders({
        variables: { query: { ...myOrdersPagVar, tag_id: props.tag.id } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  ErrorsBeHandler({
    error: ordersErrors || updateTagError || addOrderTagError || removeOrderTagError || getOrderError,
  });

  return (
    <StyledModal
      isOpen={isGroupTable}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {isGroupTable && (
        <Div
          maxH={`${height - 100}px`}
          style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
        >
          <Row d="column">
            <Col>
              <Text
                textSize="20"
                textAlign="left"
                textWeight="700"
                textColor="dark"
                m={{ x: "0rem", b: "0.5rem" }}
              >
                Edit group{" "}
                <Text tag="span" textColor="light">
                  {props?.tag?.tag || ""}
                </Text>
              </Text>
            </Col>
          </Row>
          {groupOrders && groupOrders.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="groupOrdersRows">
                {(provided) => (
                  <Row
                    d="column"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {groupOrders.map((order: any, i: number) => (
                      <Draggable key={i} draggableId={i.toString()} index={i}>
                        {(provided) => (
                          <Col
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Div
                              pos="relative"
                              p={{ y: "0.5rem" }}
                              border={{ b: "1px solid" }}
                              borderColor="grey"
                              className="group-order-top"
                            >
                              <Div
                                d="flex"
                                pos="absolute"
                                top="50%"
                                left="-16px"
                                w="12px"
                                h="12px"
                                opacity="0"
                                transform="translate(0%, -50%)"
                                transition="opacity 0.3s ease-in-out"
                                className="group-order-menu"
                              >
                                {renderIcon("SmallMenu")}
                              </Div>
                              <Div
                                d="flex"
                                align="center"
                                justify="space-between"
                                m={{ b: "2px" }}
                              >
                                <Text
                                  textSize="14"
                                  textWeight="500"
                                  textColor="dark"
                                >
                                  {order.loadingLocation_postName || "None"}
                                  {" -> "}
                                  {order.unloadingLocation_postName || "None"}
                                </Text>
                                <Text
                                  textSize="14"
                                  textWeight="500"
                                  textColor="danger"
                                  cursor="pointer"
                                  className="group-order-remove"
                                  onClick={() => handleRemove(order.id, ( order.tags?.[0]?.id || undefined ))}
                                >
                                  Remove
                                </Text>
                              </Div>
                              <Text
                                textSize="14"
                                textWeight="400"
                                textColor="semiDark"
                                m={{ b: "2px" }}
                              >
                                {order.vehicleRegistration || "None"}
                              </Text>
                              <Text
                                textSize="10"
                                textWeight="400"
                                textColor="light"
                              >
                                {order.code || ""}
                              </Text>
                            </Div>
                          </Col>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Row>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {groupOrders && groupOrders.length > 0 && (
            <Row d="column">
              <Col>
                <Div h="2rem" />
                <Div h="1px" w="100%" bg="greyBorder" />
                <Div h="2rem" />
              </Col>
            </Row>
          )}

          <Row d="column">
            <Col>
              <Text
                textSize="12"
                textAlign="left"
                textWeight="500"
                textColor="semiDark"
                m={{ x: "0rem", b: "0.25rem" }}
              >
                Enter order ID to add new order to this group
              </Text>
              <Input
                placeholder="Enter order ID"
                name="orderCode"
                value={orderCode}
                textSize={"16"}
                borderColor="greyBorder"
                type="text"
                h="48px"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOrderCode(e.target.value);
                }}
                suffix={
                  <PrimaryBtn
                    w="52px"
                    handleSubmit={(e: ChangeEvent<HTMLButtonElement>) =>
                      handleAdd(e)
                    }
                    styleBtn={{ marginLeft: "0.5rem", flexShrink: 0 }}
                    isLoading={false}
                    text={"Add"}
                  />
                }
              />
            </Col>

            <Col>
              <Div h="2rem" />
              <Div h="1px" w="100%" bg="greyBorder" />
              <Div h="2rem" />
            </Col>

            <Col>
              <Row>
                <Col size="6">
                  <DangerBtn
                    handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                      handleUngroup(e)
                    }
                    isLoading={false}
                    text={"Ungroup"}
                  />
                </Col>
                <Col size="6">
                  <SecondaryBtn
                    handleSubmit={() => {
                      modalData({
                        name: "",
                        msg: "",
                        returned_value: null,
                        props: null,
                      });
                    }}
                    isLoading={false}
                    text={"Cancel"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default GroupTable;
