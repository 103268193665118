import { omit } from "lodash";
import React, { forwardRef } from "react";
import { CSSProperties } from "styled-components";
import * as Styled from "./TableElements.styles";

interface ThProps {
  width?: string;
  styleTh?: CSSProperties;
  children?: React.ReactNode;
}

const Th = forwardRef<HTMLTableCellElement, ThProps>((props: ThProps, ref) => {
  const { width, styleTh, children } = props;

  const otherProps = omit(props, ["width", "styleTh", "children"]);

  return (
    <Styled.Th
      ref={ref}
      width={width ? width : "10%"}
      style={styleTh}
      {...otherProps}
    >
      {children}
    </Styled.Th>
  );
});

export default Th;
