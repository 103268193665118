import React from "react";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import * as Styled from "./ContainerDashboard.styles";

const ContainerDashboard: React.FC = ({ children }) => {
  const { height } = useWindowDimensions();
  return (
    <Styled.ContainerDashboard height={height}>
      {children}
    </Styled.ContainerDashboard>
  );
};

export default ContainerDashboard;
