import { omit } from "lodash";
import React, { forwardRef } from "react";
import * as Styled from "./TableElements.styles";

interface TrProps {
  className?: string;
  hidBorderLast?: boolean;
  handleClick?: () => void;
  styleTr?: any;
  children?: React.ReactNode;
}

const Tr = forwardRef((props: TrProps, ref) => {
  const { className, hidBorderLast, handleClick, styleTr, children } = props;

  const otherProps = omit(props, [
    "className",
    "hidBorderLast",
    "handleClick",
    "styleTr",
    "children",
  ]);

  return (
    <Styled.Tr
      ref={ref as React.RefObject<HTMLTableRowElement>}
      tabIndex={5}
      className={className}
      hidBorderLast={hidBorderLast}
      onClick={handleClick}
      style={{ ...styleTr }}
      {...otherProps}
    >
      {children}
    </Styled.Tr>
  );
});

export default Tr;
