import { Div, Dropdown as DropDown, Icon, Input } from "atomize";
import React from "react";
import { modalData } from "../../App";
import TertiaryBtn from "../TertiaryBtn/TertiaryBtn";

interface DropdownProps {
  menuList: any;
  value?: string;
  handleChange?: any;
  isLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  displayResult: any;
  isLocked?: boolean;
  addNew?: boolean;
  modal?: { msg: string; name: string; returned_value: any; props: any };
  showSearchField?: boolean;
  border?: boolean;
  h?: string;
  tableDropdown?: boolean;
  page?: number;
  setPage?: (page: number) => void;
  totalPages?: number;
  additionalButtons?: any;
}

const InputDropdown: React.FC<DropdownProps> = ({
  showSearchField,
  isLocked,
  displayResult,
  menuList,
  handleChange,
  isLoading,
  setIsOpen,
  isOpen,
  addNew,
  modal,
  h,
  value,
  page,
  setPage,
  totalPages,
  additionalButtons = null,
}) => {
  return (
    <DropDown
      isOpen={isOpen}
      h={h ? h : "40px"}
      style={{
        pointerEvents: isLocked ? "none" : "unset",
        width: "100%",
      }}
      onClick={() => setIsOpen(!isOpen)}
      tabIndex={0}
      bg={isLocked ? "grey" : "none"}
      openSuffix={
        <Icon
          name="UpArrow"
          size="18px"
          color={isLocked ? "grey" : "semiDark"}
        />
      }
      closeSuffix={
        <Icon
          name="DownArrow"
          size="18px"
          color={isLocked ? "grey" : "semiDark"}
        />
      }
      menu={
        <Div>
          {showSearchField !== false && (
            <Input
              m={{ t: "1rem", b: "0.5rem", x: "0.75rem" }}
              placeholder="Search"
              value={value}
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLButtonElement>) =>
                handleChange(e.target.value)
              }
              suffix={
                isLoading ? (
                  <Icon
                    name="Loading"
                    size="20px"
                    pos="absolute"
                    top="50%"
                    right="1rem"
                    transform="translateY(-50%)"
                  />
                ) : (
                  <Icon
                    name="Search"
                    size="20px"
                    cursor="pointer"
                    pos="absolute"
                    top="50%"
                    right="1rem"
                    transform="translateY(-50%)"
                  />
                )
              }
            />
          )}
          {page && setPage && totalPages && totalPages > 1 ? (
            <Div p={{ r: "0.75rem" }} d="flex" justify="flex-end" w="100%">
              <Icon
                name="LongLeft"
                size="20px"
                hovercolor={1 < page! ? "primary" : "light"}
                color={1 < page! ? "brand600" : "light"}
                cursor="pointer"
                onClick={() => (1 < page ? setPage(page - 1) : "")}
              />
              <Icon
                name="LongRight"
                size="20px"
                hovercolor={page < totalPages! ? "primary" : "light"}
                color={page < totalPages! ? "brand600" : "light"}
                cursor="pointer"
                onClick={() => (page < totalPages! ? setPage(page + 1) : "")}
              />
            </Div>
          ) : (
            ""
          )}

          <Div maxH="20rem" overflow="auto">
            {menuList}
          </Div>
          {addNew !== false && modal && (
            <TertiaryBtn
              text={"Add new"}
              h="40px"
              prefixIcon={
                <Icon
                  name="Add"
                  color="info700"
                  size="18px"
                  m={{ r: "0.5rem" }}
                />
              }
              w={"calc(100% - 1.5rem)"}
              styleBtn={{ margin: "0.5rem 0.75rem" }}
              handleSubmit={() => modalData(modal)}
            />
          )}
          {additionalButtons && additionalButtons}
        </Div>
      }
    >
      {displayResult}
    </DropDown>
  );
};
export default InputDropdown;
