import { Div, Icon, Input, Text, Anchor } from "atomize";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { responseMsgs } from "../../App";
import renderIcon from "../../assets/Icons";
import { isNumber, toNumber } from "lodash";
import LoaderScreen from "../../components/LoaderScreen";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn/SecondaryBtn";
import TertiaryBtn from "../../components/TertiaryBtn/TertiaryBtn";
import { ModuleName } from "../../enums";
import {
  DocumentType,
  GetDocumentsDocument,
  useConfirmDocumentMutation,
  useCreateDocumentMutation,
  useGetMeQuery,
} from "../../generated/graphql";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import {
  DocumentInputAcceptExtensions,
  LocalStorageKeys,
} from "../../helpers/constants";
import {
  downloadInvoicePdf,
  uploadFileToAWS,
  userHasModule,
} from "../../helpers/functions";
import * as Styled from "./CreateDocument.styles";
import InvoiceData from "./components/InvoiceData";
import { InvoiceState } from "./components/helpers/helpers";
import { defaultSchema, getInvoiceSchema } from "./schema/schema.validation";
import DropdownClassic from "../../components/DropdownClassic/DropdownClassic";

const invoiceInitialState = {
  amount: null,
  invoiceNumber: null,
  paymentDueDate: null,
  payingCompany: null,
  note: null,
  taxation: null,
  currency: null,
};

const CreateDocument: React.FC = () => {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [inputKey, setinputKey] = useState(1);
  const [fileLoading, setFileLoading] = useState(false);
  const [documentType, setDocumentType] = useState(DocumentType.Invoice);
  const [documentTypeDropdown, setDocumentTypeDropdown] = useState(false);
  const [orderCode, setOrderCode] = useState(code || ("" as string));
  const documentTypeNames = {
    INVOICE: 'Invoice',
    POD: 'Proof of delivery',
    IMAGE: 'Image',
    INSURANCE: 'Insurance',
    IOC: 'IoC',
    IOD: 'IoD',
    CMR: 'CMR',
    DDT: 'DDT',
    OTHER: 'Other'
  }
  const fileInputRef =
    useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const [
    createDocument,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateDocumentMutation({ errorPolicy: "all" });
  const [confirmDocument, { loading: confirmLoading, error: confirmError }] =
    useConfirmDocumentMutation({});
  const { data: meData, loading: meLoading } = useGetMeQuery({});

  const { validator } = ValidationsFeHandler();

  const userHasDocumentInvoicingModule = userHasModule(
    meData,
    ModuleName.DOCUMENTS_AND_INVOICING
  );
  const isInvoiceTypeSelected = documentType === DocumentType.Invoice;

  const [isGenerateModal, setIsGenerateModal] = useState(false);
  const [invoice, setInvoice] = useState<InvoiceState>(invoiceInitialState);
  const [showLoader, setShowLoader] = useState(false);

  const isValid = useMemo(() => {
    return (
      (isInvoiceTypeSelected &&
        (isGenerateModal
          ? !!invoice?.taxation && !!invoice?.invoiceNumber
          : true) &&
        !!invoice?.amount &&
        !!invoice?.paymentDueDate &&
        !!invoice?.payingCompany &&
        invoice?.currency) ||
      !isInvoiceTypeSelected
    );
  }, [
    invoice?.amount,
    invoice?.currency,
    invoice?.invoiceNumber,
    invoice?.payingCompany,
    invoice?.paymentDueDate,
    invoice?.taxation,
    isGenerateModal,
    isInvoiceTypeSelected,
  ]);

  const handleFieldValueChange = (value: any, field: keyof InvoiceState) => {
    setInvoice((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fileUpload = (e: any) => {
    const file = e.target.files[0];

    if (!DocumentInputAcceptExtensions.includes(file?.type)) {
      return responseMsgs([
        {
          type: "error",
          string: "Please upload a valid file type.",
        },
      ]);
    }

    setSelectedFile(file);
    setinputKey(inputKey + 1);
  };

  useEffect(() => {
    async function uploadFile() {
      try {
        if (createData?.createDocument?.fileUrl) {
          const res = await uploadFileToAWS(
            createData?.createDocument?.fileUrl,
            selectedFile
          );

          if (res?.status === 200) {
            await confirmDocument({
              variables: { id: createData?.createDocument.id },
            });
            history.action !== "POP"
              ? history.goBack()
              : history.push("/documents");
          } else {
            responseMsgs([
              {
                type: "error",
                string: "Something went wrong, please contact our support team",
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (createData) {
      setFileLoading(true);
      uploadFile();
      setFileLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, selectedFile]);

  useEffect(() => {
    setInvoice(invoiceInitialState);
  }, [isGenerateModal]);

  const isLoading = createLoading || fileLoading || meLoading || confirmLoading;

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    setShowLoader(true);
    e.preventDefault();

    const variables = {
      company_id: meData?.getUser.company_id as number,
      order_code: code || (orderCode as string),
      type: documentType,
      fileName: selectedFile?.name,
      fileType: selectedFile?.type,
      fileSize: selectedFile?.size,
      date: moment(),
      amount:
        (isNumber(invoice?.amount)
          ? invoice?.amount
          : toNumber(invoice?.amount)) || 0,
      payeeCompany_id: invoice?.payingCompany?.id,
      paymentDueDate: invoice?.paymentDueDate
        ? moment(invoice?.paymentDueDate).format("YYYY-MM-DD")
        : undefined,
      currency_id: invoice?.currency?.id,
      ...(isGenerateModal
        ? {
            taxationId: invoice?.taxation?.id,
          }
        : {}),
    };

    validator({
      schema: isInvoiceTypeSelected
        ? getInvoiceSchema(isGenerateModal)
        : defaultSchema,
      data: { ...variables },
      success: () => {
        if (isInvoiceTypeSelected && isGenerateModal) {
          const payload = {
            amount: invoice?.amount,
            invoiceNumber: invoice?.invoiceNumber,
            paymentDueDate: invoice?.paymentDueDate,
            payeeCompanyId: invoice?.payingCompany?.id,
            taxationId: invoice?.taxation?.id,
            currencyId: invoice?.currency?.id,
            note: invoice?.note,
            orderCode,
          };
          const token = localStorage.getItem(LocalStorageKeys.TOKEN) ?? "";
          downloadInvoicePdf(token, payload, false, () => {
            setShowLoader(false);
            setTimeout(() => {
              history.push(`/myorder/${orderCode}`);
            }, 1000);
          });
        } else {
          createDocument({
            variables: { document: { ...variables } },
            refetchQueries: [GetDocumentsDocument],
            awaitRefetchQueries: true,
          });
        }
      },
      error: () => {
        setShowLoader(false);
      },
    });
  };

  ErrorsBeHandler({
    error: createError,
  });

  ErrorsBeHandler({
    error: confirmError,
  });

  if (showLoader) {
    return (
      <LoaderScreen
        isOpen
        title="We are generating an invoice for your order. Please wait, you'll be redirected to the order once finished."
      />
    );
  }

  return (
    <Div minH="800px">
      <Div d="flex" align="center" justify="flex-end" w="100%" h="64px">
        <Text textSize="12" textColor="semiDark">
          Add document
        </Text>
        <SecondaryBtn
          w="70px"
          h="2rem"
          handleSubmit={() =>
            history.action !== "POP"
              ? history.goBack()
              : history.push("/documents")
          }
          styleBtn={{ margin: "0 0.5rem" }}
          isLoading={isLoading}
          text={"Cancel"}
        />
      </Div>
      <Styled.CreateDocument>
        <Div w="100%">
          <Div textAlign="center">
            {renderIcon("Documents")}
            <Text
              textColor="dark"
              m={{ t: "1.5rem" }}
              textAlign="center"
              textSize={20}
              textWeight="500"
            >
              Add document to your order
            </Text>
          </Div>
          <Div>
            <Text
              textSize={10}
              m={{ t: "2rem", b: "0.5rem" }}
              textColor="light"
              textWeight="700"
              style={{ letterSpacing: "0.1em" }}
            >
              STEP 1
            </Text>
            <Text textSize={14} textColor="dark" textWeight="400">
              Enter an order ID of the transport you’d like to upload your
              document to.
            </Text>
            <Input
              m={{ t: "2rem" }}
              placeholder="Enter order ID"
              name="orderCode"
              value={orderCode}
              textSize={"16"}
              borderColor="greyBorder"
              type="text"
              h="48px"
              disabled={code}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOrderCode(e.target.value);
              }}
            />
          </Div>
          <Div w="100%">
            <Text
              textSize={10}
              m={{ t: "2rem", b: "0.5rem" }}
              textColor="light"
              textWeight="700"
              style={{ letterSpacing: "0.1em" }}
            >
              STEP 2
            </Text>
            <Text textSize={14} textColor="dark" textWeight="400" m={{ b: "2rem" }}>
              Pick a type of a document and upload a file.
            </Text>
            <DropdownClassic
              padding="0 0.75rem"
              isOpen={documentTypeDropdown}
              menuList={
                <Div tabIndex={0}>
                  {Object.values(DocumentType)
                    .filter(
                      (type) => type !== documentType
                    )
                    .map((type, i: number) => {
                      return (
                        <Div d="flex" align="center" key={i}>
                          <Anchor
                            textSize={142}
                            m={{ r: "auto", l: "0.5rem" }}
                            p="0 0.5rem"
                            textColor="semiDark"
                            hoverTextColor="dark"
                            onClick={() => {
                              setDocumentType(type);
                              setDocumentTypeDropdown(false);
                            }}
                          >
                            {documentTypeNames[type] || ''}
                          </Anchor>
                        </Div>
                      );
                    })}
                </Div>
              }
              setIsOpen={setDocumentTypeDropdown}
            >
              <Text m={{ r: "auto" }} textSize={16}>
                {documentTypeNames[documentType] || ''}
              </Text>
            </DropdownClassic>
            <Div w="100%" h="1rem" />
            <input
              onChange={fileUpload}
              type="file"
              hidden
              ref={fileInputRef}
              key={inputKey}
              accept={DocumentInputAcceptExtensions.join(",")}
            />

            {isInvoiceTypeSelected && userHasDocumentInvoicingModule && (
              <SecondaryBtn
                h="48px"
                text={
                  isGenerateModal
                    ? "Setup invoice manually"
                    : "Generate invoice"
                }
                handleSubmit={() => setIsGenerateModal(!isGenerateModal)}
                styleBtn={{ marginBottom: "1rem" }}
              />
            )}

            {(!isGenerateModal || !isInvoiceTypeSelected) && (
              <TertiaryBtn
                text={"Upload file"}
                h="48px"
                prefixIcon={
                  <Icon
                    name="Upload"
                    color="primary"
                    m={{ r: "0.5rem" }}
                    size="20px"
                  />
                }
                isLoading={isLoading}
                disabled={isLoading}
                styleBtn={{ margin: "0 0 0.5rem 0" }}
                handleSubmit={() => fileInputRef.current.click()}
              />
            )}

            <Div m={{ b: "0.75rem" }}>
              {selectedFile && selectedFile.name && !isGenerateModal && (
                <Div d="flex" justify="space-between">
                  <Div d="flex">
                    <Icon
                      m={{ r: "0.75rem" }}
                      name="Checked"
                      color="success600"
                      size="20px"
                    />
                    <Text textColor="semiDark" textWeight="500">
                      {selectedFile.name}
                    </Text>
                  </Div>
                  <Icon
                    name="Delete"
                    cursor="pointer"
                    color="semiDark"
                    size="20px"
                    right="0"
                    disabled={isLoading}
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  />
                </Div>
              )}
            </Div>

            {isInvoiceTypeSelected && (
              <InvoiceData
                invoice={invoice}
                setInvoice={setInvoice}
                handleFieldValueChange={handleFieldValueChange}
                isGenerateModal={isGenerateModal}
                isOpen={isInvoiceTypeSelected}
                orderCode={orderCode}
              />
            )}

            <PrimaryBtn
              w="100%"
              handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                handleSubmit(e)
              }
              styleBtn={{ marginTop: "2rem", marginBottom: "1rem" }}
              isLoading={isLoading}
              disabled={isLoading || !isValid}
              text={isGenerateModal ? "Generate invoice" : "Submit document"}
            />
          </Div>
        </Div>
      </Styled.CreateDocument>
    </Div>
  );
};

export default CreateDocument;
