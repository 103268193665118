/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { responseMsgs } from "../../App";

const errors = [
  {
    code: "INTERNAL_SERVER_ERROR",
    msg: "Something went wrong. Please try again or contact our team.",
  },
  {
    code: "INVALID_PATH",
    msg: "The page you're trying to reach doesn't exist. Make sure you're entering the right URL path.",
  },
  {
    code: "INVALID_REQUEST_TOKEN",
    msg: "Your authentication failed. Please try logging in again.",
  },
  {
    code: "TEAM_LIMIT_REACHED",
    msg: "You've reached the limit of how many people you can have in your team. Upgrade to increase the limit.",
  },
  {
    code: "NO_FREE_SERVICE",
    msg: "This feature is available only for paid plans.",
  },
  {
    code: "SUBSCRIPTION_NOT_PAID",
    msg: "Your subscriptions has not been paid.",
  },
  {
    code: "INVALID_REQUEST",
    msg: "Invalid request. Please try again or contact our team.",
  },
  {
    code: "MISSING_AUTHENTICATION_TOKEN",
    msg: "Missing authentication token. Please try again or contact our team.",
  },
  {
    code: "INVALID_AUTHENTICATION_TOKEN",
    msg: "Invalid authentication token. Please try again or contact our team.",
  },
  {
    code: "USER_NOT_AUTHENTICATED",
    msg: "You've not been authenticated. Please try logging in again or contact our team.",
  },
  {
    code: "USER_NOT_AUTHORIZED_TO_AUTHENTICATE",
    msg: "You don't have a permission to do that.",
  },
  {
    code: "USER_IS_NOT_COMPANY_MEMBER",
    msg: "Your account is not associated with the company you're trying to access.",
  },
  {
    code: "USER_IS_NOT_ADMIN",
    msg: "You need admin permission to access this.",
  },
  {
    code: "USER_IS_NOT_ON_ORDER",
    msg: "You have not been added to this order..",
  },
  {
    code: "INVALID_PERMISSION_LEVEL",
    msg: "You don't have permission to access this.",
  },
  {
    code: "ORDER_COMPANIES_NOT_ACTIVATED",
    msg: "Company assigned to this order has not been activated yet.",
  },
  {
    code: "DEFAULT_VALIDATION_ERROR",
    msg: "Validation error. Please try again or contact our team.",
  },
  { code: "USER_FIRST_NAME_NOT_PRESENT", msg: "User is missing first name." },
  { code: "USER_LAST_NAME_NOT_PRESENT", msg: "User is missing last name." },
  { code: "USER_EMAIL_NOT_PRESENT", msg: "User is missing email address." },
  { code: "USER_EMAIL_NOT_VALID", msg: "Email address not valid." },
  {
    code: "USER_EMAIL_ALREADY_TAKEN",
    msg: "Email address has already been used.",
  },
  { code: "USER_PASSWORD_NOT_PRESENT", msg: "Password is missing" },
  {
    code: "USER_PASSWORD_NOT_VALID",
    msg: "Password not valid. Use at least 8 characters.",
  },
  {
    code: "USER_ABILITIES_KIND_NOT_PRESENT",
    msg: "User type has not been selected.",
  },
  { code: "USER_ABILITIES_KIND_NOT_VALID", msg: "User type is not valid." },
  { code: "USER_ACCOUNT_NOT_ACTIVE", msg: "User is not active." },
  {
    code: "PROFILE_CREDENTIALS_INVALID",
    msg: "Profile information is invalid.",
  },
  {
    code: "CAN_NOT_DELETE_COMPANY_OWNER",
    msg: "You can't delete company owner.",
  },
  { code: "USER_DOES_NOT_EXIST", msg: "User doesn't exist." },
  { code: "USER_ALREADY_EXISTS", msg: "User already exists." },
  {
    code: "DATA_MODEL_STATUS_MISSING",
    msg: "Data status is missing. Try again or contact our team.",
  },
  {
    code: "DATA_MODEL_INVALID_STATUS",
    msg: "Data has invalid status. Try again or contact our team.",
  },
  {
    code: "DATA_MODEL_ID_MISSING",
    msg: "Data model ID is missing. Try again or contact our team.",
  },
  {
    code: "DATA_MODEL_INVALID_TYPE",
    msg: "Data has invalid type. Try again or contact our team.",
  },
  { code: "ROLE_ID_NOT_PRESENT", msg: "Role is missing." },
  { code: "PERMISSION_ID_NOT_PRESENT", msg: "Permission is missing." },
  {
    code: "READ_PERMISSION_LEVEL_NOT_SET",
    msg: "Read permission has not been set.",
  },
  {
    code: "WRITE_PERMISSION_LEVEL_NOT_SET",
    msg: "Write permission has not been set.",
  },
  {
    code: "EXECUTE_PERMISSION_LEVEL_NOT_SET",
    msg: "Execute permission has not been set.",
  },
  { code: "COMPANY_INVALID_TYPE", msg: "Company has invalid type." },
  { code: "COMPANY_EMAIL_NOT_VALID", msg: "Company email is not valid." },
  { code: "COMPANY_NAME_NOT_PRESENT", msg: "Company name is missing." },
  { code: "COMPANY_COUNTRY_NOT_PRESENT", msg: "Company country is missing." },
  {
    code: "COMPANY_TAX_NUMBER_NOT_PRESENT",
    msg: "Company VAT number is missing.",
  },
  {
    code: "COMPANY_TAX_NUMBER_ALREADY_TAKEN",
    msg: "Company VAT number has already been used.",
  },
  { code: "COMPANY_NOT_ACTIVE", msg: "Company has not been activated yet." },
  { code: "COMPANY_DOES_NOT_EXIST", msg: "Company doesn't exist." },
  { code: "COMPANY_ALREADY_EXISTS", msg: "Company already exists." },
  { code: "BID_ORDER_ID_MISSING", msg: "Bid ID is missing." },
  { code: "BID_COMPANY_ID_MISSING", msg: "Company from the bid is missing." },
  { code: "BID_ORDER_ID_INVALID", msg: "Bid order ID is invalid." },
  { code: "BID_DOES_NOT_EXIST", msg: "Bid doesn't exist." },
  { code: "BID_EXPIRED", msg: "Bid has expired." },
  {
    code: "BID_ORDER_NOT_ON_MARKET",
    msg: "Bid has not been placed to the market.",
  },
  { code: "VEHICLE_COMPANY_ID_MISSING", msg: "Vehicle company ID is missing" },
  { code: "ASSIGNED_USER_INVALID_TYPE", msg: "User assigned has invalid type" },
  { code: "USER_ALREADY_ASSIGNED", msg: "User has already been assigned." },
  {
    code: "TRANSPORT_ORDER_ID_MISSING",
    msg: "Transporter order ID is missing.",
  },
  {
    code: "TRANSPORT_STATE_ORDER_ID_MISSING",
    msg: "Transport order ID is unknown.",
  },
  {
    code: "TRANSPORT_STATE_TRANSPORT_ID_MISSING",
    msg: "Status of a transport is unknown.",
  },
  { code: "TRANSPORT_DOES_NOT_EXIST", msg: "Transport doesn't exist." },
  {
    code: "TRANSPORT_STATE_DOES_NOT_EXIST",
    msg: "Transport status is unknown.",
  },
  { code: "LOCATION_EMAIL_NOT_VALID", msg: "Email is invalid." },
  { code: "CAN_NOT_EDIT_ACTIVE_ORDER", msg: "You can't edit an active order." },
  {
    code: "INVALID_ORDER_STATUS_CHANGE",
    msg: "You can't change the status of this order.",
  },
  { code: "DRIVER_NOT_VALID", msg: "Driver is not valid." },
  { code: "VEHICLE_NOT_VALID", msg: "Vehicle is not valid." },
  {
    code: "INVALID_MARKET_EXPIRATION_DATE",
    msg: "Expiration date is not valid.",
  },
  { code: "MISSING_ORDER_ID", msg: "Missing order ID." },
  { code: "ORDER_DOES_NOT_EXIST", msg: "Order doesn't exist." },
  { code: "PARENT_ORDER_DOES_NOT_EXIST", msg: "Parent order doesn't exist." },
  { code: "NO_SUBORDER_ON_SUBORDER", msg: "There is no sub-order." },
  {
    code: "FEEDBACK_USER_ID_NOT_PRESENT",
    msg: "We couldn't assign user to this feedback.",
  },
  { code: "FEEDBACK_TYPE_NOT_PRESENT", msg: "Feedback type is missing." },
  { code: "FEEDBACK_TYPE_NOT_VALID", msg: "Feedback type not valid." },
  { code: "FEEDBACK_DOES_NOT_EXIST", msg: "Feedback is missing." },
  { code: "COUNTRY_DOES_NOT_EXIST", msg: "Country doesn't exist." },
  { code: "CURRENCY_DOES_NOT_EXIST", msg: "Currency doesn't exist." },
  { code: "DOCUMENT_DOES_NOT_EXIST", msg: "Document doesn't exist." },
  {
    code: "TOKEN_CREATION_ERROR",
    msg: "There was an error with token creation. Please contact our team.",
  },
  { code: "INVOICE_DOES_NOT_EXIST", msg: "Invoice doesn't exist." },
  { code: "INVOICE_ITEM_DOES_NOT_EXIST", msg: "Invoice item is missing." },
  { code: "LOCATION_DOES_NOT_EXIST", msg: "Location is missing." },
  { code: "MESSAGE_DOES_NOT_EXIST", msg: "Message is missing." },
  { code: "NOTIFICATION_DOES_NOT_EXIST", msg: "Notification doesn't exist." },
  { code: "PRICING_DOES_NOT_EXIST", msg: "Pricing doesn't exist." },
  { code: "SERVICE_DOES_NOT_EXIST", msg: "Service doesn't exist." },
  { code: "SUBSCRIPTION_DOES_NOT_EXIST", msg: "Subscription doesn't exist." },
  { code: "VEHICLE_DOES_NOT_EXIST", msg: "Vehicle doesn't exist." },
  {
    code: "UNHANDLED_SYSTEM_ERROR",
    msg: "Unknown error. Please contact our team.",
  },
  { code: "DATABASE_ERROR", msg: "Database error, please contact our team." },
  {
    code: "EMAIL_SENDING_ERROR",
    msg: "Error happened while sending an email. Please contact our team.",
  },
  { code: "UNAUTHENTICATED", msg: "You've logged out from your account." },
  { code: "FORBIDDEN", msg: "You don't have a permission to access this." },
  {
    code: "ERROR_CREATING_INVITE",
    msg: "Error happened while sending an invite. Please contact our team.",
  },
  {
    code: "TRANSPORT_ORDER_IS_COMPLETED",
    msg: "Transport details can't be edited on a completed order.",
  },
  {
    code: "MODULE_PERMISSION_ALREADY_EXISTS",
    msg: "Permission already exists.",
  },
  {
    code: "USER_IS_NOT_MODULE_PERMISSIONS_MANAGER",
    msg: "You don't have permission to manage module permissions.",
  },
  {
    code: "LOCATION_POINT_DOES_NOT_EXIST",
    msg: "Location point doesn't exist.",
  },
];

const ErrorsBeHandler: ({
  error,
  fn,
  dep,
}: {
  error: any;
  fn?: any;
  dep?: boolean;
}) => void = ({ error, fn, dep = true }) => {
  useEffect(() => {
    if (dep) {
      if (error) {
        if (error?.message === "undefined") {
          let msgs: any[] = [];
          error?.graphQLErrors.map((err: any) => {
            err.map((e: any) => {
              const new_er = errors.filter((er) => er.code === e?.message);
              msgs.push({ type: "error", string: new_er?.[0]?.msg });
            });
          });
          responseMsgs([...msgs]);
        } else {
          let errs = error?.graphQLErrors.map((err: any) => {
            const new_er = errors.filter(
              (er) => er.code === err.extensions.code
            );
            return { type: "error", string: new_er?.[0]?.msg };
          });
          responseMsgs([...errs]);
        }
        fn !== undefined && fn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default ErrorsBeHandler;
