import React, { useLayoutEffect } from "react";
import { Icon, Div } from "atomize";
import { useActivateCompanyMutation } from "../../generated/graphql";
import { useHistory, useLocation } from "react-router-dom";
import { getParams } from "../../helpers/functions";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import * as yup from "yup";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";

let schema = yup.object().shape({
  token: yup
    .string()
    .nullable()
    .required({ name: "Token", code: "TOKEN_MISSING" }),
});
const ActivateCompany: React.FC = () => {
  const search = useLocation().search;
  const token = getParams(search, "token", "?token=");
  const [
    activateCompany,
    { data: activateCompanyData, error: activateCompanyError },
  ] = useActivateCompanyMutation({ errorPolicy: "all" });
  const history = useHistory();
  const { validator } = ValidationsFeHandler();

  useLayoutEffect(() => {
    validator({
      schema: schema,
      data: { token },
      error: () => {
        history.push("/signin");
      },
      success: () => {
        activateCompany({ variables: { token: token! } });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ErrorsBeHandler({
    error: activateCompanyError,
    fn: () => {
      history.push("/signin");
    },
  });

  SuccessBeHandler({
    data: activateCompanyData,
    code: "ACTIVATED_COMPANY",
    fn: () => {
      history.push("/signin");
    },
  });

  return (
    <Div d="flex" align="center" justify="center" w="100%" h="100%">
      <Icon name="Loading" size="200px" color="grey" />
    </Div>
  );
};

export default ActivateCompany;
