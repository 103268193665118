import styled from "styled-components";
import { Color } from "../GlobalTheme";
interface StatusBadgeProps {
  color: Color;
  bgColor: Color;
  m?: string;
}
export const StatusBadge = styled.div<StatusBadgeProps>`
  position: relative;
  width: 20px;
  height: 20px;
  display: block;
  box-sizing: border-box;
  border: 5px solid ${(props) => props.theme.colors[props.color]};
  border-radius: 7px;
  background: ${(props) => props.theme.colors[props.bgColor]};
  box-shadow: ${(props) => props.theme.shadows[1]};
  margin: ${(props) => (props.m ? props.m : "0")};
`;

export const MinusIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 5px;
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.background};
  translate: -50% -50%;
`;