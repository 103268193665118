import { useReactiveVar } from "@apollo/client";
import { Col, Div, Row, Text } from "atomize";
import React from "react";
import { modalData } from "../../../App";
import renderIcon from "../../../assets/Icons";
import {
  GetActivityLogsDocument,
  GetMarketOrdersDocument,
  GetOrderDocument,
  GetReceivedBidsDocument,
  useDeleteOrderMutation,
} from "../../../generated/graphql";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import DangerBtn from "../../DangerBtn/DangerBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import { StyledModal } from "../utils/helpers";

const DeleteOrder: React.FC = () => {
  const { name, props } = useReactiveVar(modalData);

  const [deleteOrder, { data, loading, error }] = useDeleteOrderMutation({
    errorPolicy: "all",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    deleteOrder({
      variables: { id: props.id },
      refetchQueries: [
        GetReceivedBidsDocument,
        GetMarketOrdersDocument,
        GetOrderDocument,
        GetActivityLogsDocument,
      ],
      awaitRefetchQueries: true,
    });
  };

  ErrorsBeHandler({
    error: error,
    dep: name === "deleteOrder",
  });

  SuccessBeHandler({
    data: data,
    dep: name === "deleteOrder",
    code: "DELETE_ORDER",
    fn: () => {
      modalData({
        msg: null,
        name: null,
        returned_value: null,
        props: null,
      });
    },
  });

  const { width } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={!!(name === "deleteOrder")}
      onClose={() =>
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        })
      }
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      {props && name === "deleteOrder" && (
        <Div style={{ padding: width > 550 ? "2rem" : "1rem" }}>
          <Div d="flex" justify="center">
            {renderIcon("Delete")}
          </Div>
          <Row>
            <Col>
              <Text
                textColor={"dark"}
                textWeight="500"
                m={{ t: "0.5rem", b: "1.75rem" }}
                textAlign="center"
              >
                Are you sure you want to delete this order?
              </Text>
            </Col>
          </Row>
          <Row justify="center">
            <Col size="12" flexDir="row" d="flex" justify="center">
              <DangerBtn
                w="100%"
                styleBtn={{ maxWidth: "144px", marginRight: "1rem" }}
                handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                  handleSubmit(e)
                }
                isLoading={loading}
                text={"Delete"}
              />
              <SecondaryBtn
                w="100%"
                styleBtn={{ maxWidth: "144px" }}
                handleSubmit={() => {
                  modalData({
                    name: "",
                    msg: "",
                    returned_value: null,
                    props: null,
                  });
                }}
                isLoading={loading}
                text={"Cancel"}
              />
            </Col>
          </Row>
        </Div>
      )}
    </StyledModal>
  );
};

export default DeleteOrder;
