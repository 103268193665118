import React, { useState } from "react";
import Td from "../TableComponents/Td";
import Tr from "../TableComponents/Tr";
import { Text, Div, Anchor } from "atomize";
import renderIcon from "../../assets/Icons";
import { GlobalTheme } from "../GlobalTheme";
import DropDown from "../DropdownClassic/DropdownClassic";
import { Vehicle } from "../../generated/graphql";
import { modalData } from "../../App";

const VehicleRowActions: React.FC<{ vehicle?: Vehicle }> = ({ vehicle }) => {
  const [actionDropdown, setActionDropdown] = useState<boolean>(false);

  return (
    <Tr>
      <Td
        className="no_p"
        styleTd={{
          borderLeft: `1px solid ${GlobalTheme.colors.grey}`,
          borderRight: `1px solid ${GlobalTheme.colors.grey}`,
          padding: 0,
        }}
        isActionTd={true}
      >
        <DropDown
          h="auto"
          showDropIcon={false}
          border={false}
          direction="lefttop"
          style={{ width: "auto!important" }}
          isOpen={actionDropdown}
          menuList={
            <Div
              shadow={GlobalTheme.shadows[4]}
              style={{
                background: GlobalTheme.colors.background,
                padding: "0 0.75rem",
                borderRadius: GlobalTheme.rounded[4],
              }}
              tabIndex={0}
            >
              <Div d="flex" align="center">
                <Anchor
                  textSize={142}
                  textColor="semiDark"
                  hoverTextColor="dark"
                  d="block"
                  p={{ y: "0.25rem" }}
                  onClick={() => {
                    modalData({
                      msg: "Update Vehicle",
                      name: "updateVehicle",
                      returned_value: null,
                      props: {
                        id: vehicle?.id,
                        registration: vehicle?.registration,
                        maxCargoVolume: vehicle?.maxCargoVolume,
                        maxCargoWeight: vehicle?.maxCargoWeight,
                        comment: vehicle?.description,
                        driverUser_id: vehicle?.driverUser_id,
                      },
                    });
                    setActionDropdown(false);
                  }}
                >
                  Edit
                </Anchor>
              </Div>
            </Div>
          }
          setIsOpen={setActionDropdown}
        >
          <Text cursor="pointer" hoverTextColor="info700">
            {renderIcon("ActionDots")}
          </Text>
        </DropDown>
      </Td>
    </Tr>
  );
};

export default VehicleRowActions;
