import React from "react";
import { Color } from "../GlobalTheme";
import * as Styled from "./StatusBadge.styles";

interface StatusBadgeProps {
  color: Color;
  bgColor: Color;
  m?: string;
  minus?: boolean | null;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ color, bgColor, m, minus }) => {
  return <Styled.StatusBadge color={color} bgColor={bgColor} m={m}>
    { minus && <Styled.MinusIcon /> }
  </Styled.StatusBadge>;
};

export default StatusBadge;
