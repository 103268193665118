import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Input, Row, Text } from "atomize";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { modalData } from "../../../App";
import {
  CompanyStatus,
  Driver,
  GetDriversDocument,
  GetUsersDocument,
  SqlModelStatus,
  useCreateUserMutation,
  useGetCountriesLazyQuery,
  useGetMeQuery,
  useGetVehiclesLazyQuery,
} from "../../../generated/graphql";
import { useDebouncedEffect } from "../../../helpers/CustomHooks/useDebounceEffect";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import useWindowDimensions from "../../../helpers/CustomHooks/useWindowDimensions";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../../helpers/Text/ValidationsFeHandler";
import { registrationPlateNumberFormat } from "../../../helpers/functions";
import InputDropdown from "../../InputDropdown/InputDropdown";
import PrefixDropdown from "../../PrefixDropdown/PrefixDropdown";
import PrimaryBtn from "../../PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "../../SecondaryBtn/SecondaryBtn";
import Skeleton from "../../Skeleton/Skeleton";
import { StyledModal } from "../utils/helpers";

// Initial state
const externalDriverInitials = {
  firstName: "",
  lastName: "",
  email: "",
  phoneCountryNumbers: "386",
  phone: "",
  vehicle_id: null,
};

type DriverState = Partial<Driver> & {
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryNumbers: string;
  phone: string;
  vehicle_id?: number | null;
  registration?: string;
};

// Validation schema
const addExternalDriverSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required({ name: "First name", code: "REQUIRED_FIELD" }),
  lastName: yup
    .string()
    .trim()
    .required({ name: "Last name", code: "REQUIRED_FIELD" }),
  phone: yup.string(),
  email: yup.string().email(),
});

const AddExternalDriver: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const { name, props, msg } = useReactiveVar(modalData);

  // Check if modal name is matching
  const isAddExternalDriver = name === "addExternalDriver";

  // Main state
  const [driver, setDriver] = useState<DriverState>({
    ...externalDriverInitials,
    vehicle_id: props?.vehicle_id,
  });

  // Local state variables
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState<number | null>(null);

  // Get me query
  const { data: me, loading: meLoading } = useGetMeQuery();

  // Countries queries
  const queryPhoneCountries = useGetCountriesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isCountryDropOpen, setIsCountryDropOpen] = useState(false);
  const {
    data: phoneCountries,
    loading: loadingPhoneCountries,
    search: phoneCountryInput,
    setSearch: setPhoneCountryInput,
  } = useSearch(queryPhoneCountries, { limit: 3 }, 300, isCountryDropOpen);

  const vehicleId = props?.vehicle_id || null;

  // Vehicles queries
  const queryVehicles = useGetVehiclesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [isVehiclesDropdownOpen, setIsVehiclesDropdownOpen] =
    useState<boolean>(false);
  const {
    data: vehicles,
    loading: loadingVehicles,
    search: searchVehicles,
    setSearch: setSearchVehicles,
    page: searchVehiclesPage,
    setPage: setSearchVehiclesPage,
  } = useSearch(
    queryVehicles,
    {
      limit: 5,
      company_id: me?.getUser.company?.id,
      status: [SqlModelStatus.Active],
      ...(!!vehicleId
        ? {
            id: vehicleId,
          }
        : {}),
    },
    300,
    isVehiclesDropdownOpen || !!vehicleId
  );

  const [
    createUser,
    {
      data: dataCreateUser,
      loading: loadingCreateUser,
      error: errorCreateUser,
    },
  ] = useCreateUserMutation();

  // Event handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setDriver((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const variables = {
      firstName: driver.firstName,
      lastName: driver.lastName,
      phone: driver.phone
        ? `(${driver.phoneCountryNumbers})${driver.phone}`
        : ``,
      email: driver.email,
      isExternalDriver: true,
    };

    validator({
      schema: addExternalDriverSchema,
      data: variables,
      success: () => {
        createUser({
          variables: {
            user: { ...variables, company_id: me?.getUser?.company_id },
          },
          refetchQueries: [GetUsersDocument, GetDriversDocument],
          awaitRefetchQueries: true,
        });
      },
    });
  };

  // Effects
  useEffect(() => {
    setUserRole(Number(me?.getUser.roles![0].id));
  }, [me?.getUser.roles]);

  useDebouncedEffect(
    () => {
      setIsLoading(meLoading || loadingVehicles || loadingPhoneCountries);
    },
    [meLoading, loadingVehicles, loadingPhoneCountries],
    300
  );

  // BE handlers
  ErrorsBeHandler({
    error: errorCreateUser,
  });

  SuccessBeHandler({
    data: dataCreateUser,
    code: "UPDATED_USER",
    fn: () => {
      setDriver(externalDriverInitials);
      modalData({
        msg: "",
        name: "",
        returned_value: null,
        props: null,
      });
    },
  });

  const { width, height } = useWindowDimensions();

  return (
    <StyledModal
      isOpen={isAddExternalDriver}
      onClose={() => {
        setDriver(externalDriverInitials);
        modalData({
          name: "",
          msg: "",
          returned_value: null,
          props: null,
        });
      }}
      m={{ y: "auto", x: { xs: "1rem", lg: "auto" } }}
      rounded="md"
      p="0"
    >
      <Div
        maxH={`${height * 0.7}px`}
        style={{ overflowY: "auto", padding: width > 550 ? "2rem" : "1rem" }}
      >
        <Text textSize="201" textAlign="left" textWeight="700" textColor="dark">
          {msg}
        </Text>

        {isAddExternalDriver && (
          <Row d={"flex"} flexDir={"row"}>
            <>
              <Col size="6">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  First name
                </Text>
                <Input
                  placeholder="First name"
                  name="firstName"
                  value={driver.firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                  textSize={"16"}
                  h="48px"
                  type="text"
                  m={{ y: "0.5rem" }}
                />
              </Col>
              <Col size="6">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Last name
                </Text>
                <Input
                  placeholder="Last name"
                  name="lastName"
                  value={driver.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                  textSize={"16"}
                  h="48px"
                  type="text"
                  m={{ y: "0.5rem" }}
                />
              </Col>
              <Col>
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "0.5rem" }}
                >
                  Email
                </Text>
                <Input
                  placeholder="example@mail.com"
                  name="email"
                  value={driver.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDriver((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  textSize={"16"}
                  type="text"
                  h="48px"
                />
              </Col>
              <Col size="12">
                <Text
                  textColor="semiDark"
                  textSize={12}
                  textWeight={500}
                  m={{ b: "0.25rem", t: "1rem" }}
                >
                  Phone
                </Text>
                <PrefixDropdown
                  inputValue={driver.phone}
                  countryInputValue={phoneCountryInput}
                  h="48px"
                  format="## ### ### ###"
                  placeholder="Phone"
                  addNew={true}
                  displayResult={
                    <Div
                      d="flex"
                      h="24px"
                      align="center"
                      m={{ l: "auto", r: "-0.75rem" }}
                    >
                      <Text
                        textColor="dark"
                        textWeight="500"
                        m={{ l: "auto" }}
                        textSize={16}
                      >{`${driver.phoneCountryNumbers}`}</Text>
                    </Div>
                  }
                  isLoading={loadingPhoneCountries}
                  isOpen={isCountryDropOpen}
                  setIsOpen={setIsCountryDropOpen}
                  setCountryInput={setPhoneCountryInput}
                  handleChange={(value: any) =>
                    setDriver((prevState) => ({
                      ...prevState,
                      phone: value,
                    }))
                  }
                  menuList={phoneCountries?.getCountries.items?.map(
                    (item: any, i: number) => (
                      <Anchor
                        d="flex"
                        p={{ y: "0.5rem", x: "0.75rem" }}
                        key={i}
                        align="center"
                        onClick={() => {
                          setDriver((prevState) => ({
                            ...prevState,
                            phoneCountryNumbers: item.phoneCode,
                          }));
                          setIsCountryDropOpen(false);
                        }}
                      >
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                        >
                          {" "}
                          {`${item.phoneCode}`}
                        </Text>
                        <Text
                          textColor="semiDark"
                          hoverTextColor="dark"
                          p={{ l: "10px" }}
                        >
                          {" "}
                          {`${item.name}`}
                        </Text>
                      </Anchor>
                    )
                  )}
                />
              </Col>

              {!!vehicleId && (
                <Col size="12">
                  <Text
                    textSize={12}
                    textColor="semiDark"
                    textWeight="500"
                    m={{ t: "1rem", b: "4px" }}
                  >
                    Vehicle
                  </Text>
                  {isLoading ? (
                    <Skeleton
                      width="100%"
                      height="40px"
                      borderRadius="0.5rem"
                    />
                  ) : (
                    <InputDropdown
                      h="48px"
                      addNew={
                        userRole === 2 || userRole === 1 || userRole === 6
                      }
                      page={searchVehiclesPage}
                      setPage={setSearchVehiclesPage}
                      totalPages={Math.ceil(vehicles?.getVehicles.total / 5)}
                      isLocked={
                        me?.getUser.company?.status !== CompanyStatus.Active ||
                        !!vehicleId
                      }
                      isLoading={loadingVehicles}
                      displayResult={
                        <Div d="flex" h="24px" align="center">
                          <Text
                            textColor="semiDark"
                            hoverTextColor="dark"
                            textWeight="500"
                            textSize={16}
                          >
                            {
                              (
                                vehicleId &&
                                vehicles?.getVehicles.items?.find(
                                  (item: any) => item?.id === vehicleId
                                )
                              )?.registration
                            }
                            {!vehicleId && driver?.registration}
                          </Text>
                        </Div>
                      }
                      isOpen={isVehiclesDropdownOpen}
                      setIsOpen={setIsVehiclesDropdownOpen}
                      value={searchVehicles}
                      handleChange={(v: string) =>
                        setSearchVehicles(
                          registrationPlateNumberFormat(v.toUpperCase())
                        )
                      }
                      menuList={vehicles?.getVehicles.items?.map(
                        (item: any, i: number) => (
                          <Anchor
                            d="flex"
                            p={{ y: "0.5rem", x: "0.75rem" }}
                            key={i}
                            align="center"
                            justify="space-between"
                            onClick={() => {
                              setDriver((prevState) => ({
                                ...prevState,
                                ...item,
                                vehicle_id: item?.id,
                              }));
                              setIsVehiclesDropdownOpen(false);
                            }}
                          >
                            <Text
                              textColor="semiDark"
                              hoverTextColor="dark"
                              m={{ l: "10px" }}
                            >
                              {item.registration}
                            </Text>
                          </Anchor>
                        )
                      )}
                    />
                  )}
                </Col>
              )}
              <Div h="2rem" w="1px" />
              <Col size="12">
                <Row>
                  <Col size="auto">
                    <PrimaryBtn
                      w="120px"
                      handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
                        handleSubmit(e)
                      }
                      isLoading={false}
                      text="Add driver"
                    />
                  </Col>
                  <Col size="auto">
                    <SecondaryBtn
                      w="120px"
                      handleSubmit={() => {
                        setDriver(externalDriverInitials);
                        modalData({
                          name: "",
                          msg: "",
                          returned_value: null,
                          props: null,
                        });
                      }}
                      isLoading={loadingCreateUser}
                      text={"Cancel"}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          </Row>
        )}
      </Div>
    </StyledModal>
  );
};

export default AddExternalDriver;
