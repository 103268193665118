import moment from "moment";
import { Reducer } from "react";
import { CompanyStatus, TransportCargoType } from "../../generated/graphql";

export interface loadingInitialStateProps {
  id: null | number;
  code: null | string;
  transport_id: null | number;
  shipper: {
    name: string;
    logoPath: string;
    id: null | number;
    type: number;
    taxNumber: string;
    status: CompanyStatus | "";
  };
  loading_date: any;
  loading_time: string;
  loading_timezone: string;
  receiver: {
    name: string;
    logoPath: string;
    id: null | number;
    type: number;
    taxNumber: string;
    status: CompanyStatus | "";
  };
  loadingLocation: {
    id: null;
    status: number | null;
    postName: string;
    postCode: string;
    address: string;
    title: string;
    country_id: null | number;
    country_shortName: string;
    timezone: string;
  };
  unloadingLocation: {
    id: null;
    status: number | null;
    postName: string;
    postCode: string;
    address: string;
    title: string;
    country_id: null | number;
    country_shortName: string;
    timezone: string;
  };
  unloading_date: string;
  unloading_time: string;
  unloading_timezone: string;
  cargoType: TransportCargoType;
  parcels: [
    {
      id: number | null;
      weight: number | null;
      volume: number | null;
      quantity: number | null;
      length: number | null;
      width: number | null;
      height: number | null;
    }
  ];
  dangerous_goods: boolean;
  stackable_goods: boolean;
  description: string;
  onMarket: boolean;
  transporter: {
    name: string;
    logoPath: string;
    id: null | number;
    type: number;
    taxNumber: string;
    status: CompanyStatus | "";
  };
  price: null | number;
  volume: string;
  weight: string;
  expiry_date: string;
  expiry_time: string;
  expiry_timezone: string;
}

export const loadingInitialState: loadingInitialStateProps = {
  id: null,
  code: null,
  transport_id: null,
  shipper: {
    name: "",
    logoPath: "",
    id: null,
    type: 1,
    taxNumber: "",
    status: "",
  },
  loadingLocation: {
    id: null,
    status: null,
    postName: "",
    postCode: "",
    address: "",
    title: "",
    country_id: null,
    country_shortName: "",
    timezone: "",
  },
  unloadingLocation: {
    id: null,
    status: null,
    postName: "",
    postCode: "",
    address: "",
    title: "",
    country_id: null,
    country_shortName: "",
    timezone: "",
  },
  loading_date: moment().add(2, "hours").format("YYYY-MM-DD"),
  loading_time: moment().add(2, "hours").format("HH:mm"),
  loading_timezone: "",
  receiver: {
    name: "",
    logoPath: "",
    id: null,
    type: 1,
    taxNumber: "",
    status: "",
  },
  unloading_date: moment().add(3, "hours").format("YYYY-MM-DD"),
  unloading_time: moment().add(3, "hours").format("HH:mm"),
  unloading_timezone: "",
  onMarket: false,
  transporter: {
    name: "",
    logoPath: "",
    id: null,
    type: 1,
    taxNumber: "",
    status: "",
  },
  price: null,
  cargoType: TransportCargoType.Ftl,
  parcels: [
    {
      id: null,
      weight: null,
      volume: null,
      quantity: 1,
      length: null,
      width: null,
      height: null,
    },
  ],
  dangerous_goods: false,
  stackable_goods: false,
  volume: "",
  weight: "",
  description: "",
  expiry_date: moment().add(1, "hours").format("YYYY-MM-DD"),
  expiry_time: moment().add(1, "hours").format("HH:mm"),
  expiry_timezone: "",
};

type LoadingAction = {
  type: string;
  field: string;
  payload: any;
};

export const LoadingReducer: Reducer<
  loadingInitialStateProps | any,
  LoadingAction
> = (loadingInitialState, action) => {
  switch (action.type) {
    case "HANDLE_CHANGE":
      return {
        ...loadingInitialState,
        [action.field]: action.payload,
      };
    case "USER_TIMEZONE":
      return {
        ...loadingInitialState,
        loading_timezone: action.payload,
        unloading_timezone: action.payload,
        expiry_timezone: action.payload,
      };
    case "HANDLE_RESET":
      return {
        ...action.payload,
      };
    case "HANDLE_CHANGE_SHIPPER":
      return {
        ...loadingInitialState,
        loadingLocation: {
          id: null,
          status: null,
          postName: "",
          postCode: "",
          address: "",
          title: "",
          country_id: null,
          country_shortName: "",
          timezone: "",
        },
        loading_timezone: action.payload.timezone,
        [action.field]: {
          name: action.payload.name,
          logoPath: action.payload.logoPath,
          id: action.payload.id,
          type: action.payload.type,
          taxNumber: action.payload.taxNumber,
          status: action.payload.status,
        },
      };
    case "HANDLE_CHANGE_LOCATION":
      return {
        ...loadingInitialState,
        [action.field]: {
          id: action.payload.id,
          status: action.payload.status,
          postName: action.payload.postName,
          postCode: action.payload.postCode,
          address: action.payload.address,
          title: action.payload.title,
          country_shortName: action.payload.country_id,
          timezone: action.payload.timezone,
        },
      };
    case "HANDLE_CHANGE_RECEIVER":
      return {
        ...loadingInitialState,
        unloadingLocation: {
          id: null,
          status: null,
          postName: "",
          postCode: "",
          address: "",
          title: "",
          country_id: null,
          country_shortName: "",
          timezone: "",
        },
        unloading_timezone: action.payload.timezone,
        [action.field]: {
          name: action.payload.name,
          logoPath: action.payload.logoPath,
          id: action.payload.id,
          type: action.payload.type,
          taxNumber: action.payload.taxNumber,
          status: action.payload.status,
        },
      };
    case "HANDLE_CHANGE_TRANSPORTER":
      return {
        ...loadingInitialState,
        [action.field]: {
          name: action.payload.name,
          logoPath: action.payload.logoPath,
          id: action.payload.id,
          type: action.payload.type,
          taxNumber: action.payload.taxNumber,
          status: action.payload.status,
        },
      };
    case "HANDLE_CHECK_ORDER":
      return {
        ...loadingInitialState,
        id: action.payload.id,
        code: action.payload.code,
        transport_id: action.payload.transport_id,
      };
    case "HANDLE_CHANGE_PARCEL":
      const parcels = loadingInitialState.parcels;

      if (Array.isArray(action.field)) {
        parcels[action.field[0]][action.field[1]] = Number(action.payload);
      } else {
        parcels[0][action.field] = Number(action.payload);
      }

      return {
        ...loadingInitialState,
        parcels,
      };
    case "HANDLE_CHANGE_CARGO":
      return {
        ...loadingInitialState,
        cargoType: action.payload,
        parcels: [
          {
            id: null,
            weight: null,
            volume: null,
            quantity: 1,
            length: null,
            width: null,
            height: null,
          },
        ],
      };
    case "HANDLE_SHIPPER_PLACEHOLDER":
      return {
        ...loadingInitialState,
        loadingLocation: { ...action.payload.location },
        loading_timezone: action.payload.location.timezone,
        shipper: { ...action.payload.company },
      };
    case "HANDLE_SHIPPER_PLACEHOLDER_LOCATION":
      return {
        ...loadingInitialState,
        loadingLocation: { ...action.payload.location },
        loading_timezone: action.payload.location.timezone,
      };
    case "HANDLE_RECEIVER_PLACEHOLDER_LOCATION":
      return {
        ...loadingInitialState,
        unloadingLocation: { ...action.payload.location },
        unloading_timezone: action.payload.location.timezone,
      };
    case "HANDLE_RECEIVER_PLACEHOLDER":
      return {
        ...loadingInitialState,
        unloadingLocation: { ...action.payload.location },
        unloading_timezone: action.payload.location.timezone,
        receiver: { ...action.payload.company },
      };
    case "HANDLE_TRANSPORTER_PLACEHOLDER":
      return {
        ...loadingInitialState,
        transporter: { ...action.payload.company },
      };
    case "HANDLE_INITIAL":
      return {
        id: action.payload.update ? action.payload.order.getOrder.id : null,
        code: action.payload.update ? action.payload.order.getOrder.code : null,
        transport_id: action.payload.order.getOrder.transports[0].id,
        shipper: {
          name: action.payload.companyShipper.getCompany.name,
          logoPath: action.payload.companyShipper.getCompany.logoPath,
          id: action.payload.companyShipper.getCompany.id,
          type: action.payload.companyShipper.getCompany.type,
          users: [],
          taxNumber: action.payload.companyShipper.getCompany.taxNumber,
          status: action.payload.companyShipper.getCompany.status,
        },
        loadingLocation: {
          id: action.payload.locationLoading.getLocation.id,
          status: action.payload.locationLoading.getLocation.status,
          postName: action.payload.locationLoading.getLocation.postName,
          postCode: action.payload.locationLoading.getLocation.postCode,
          address: action.payload.locationLoading.getLocation.address,
          title: action.payload.locationLoading.getLocation.title,
          country_id: action.payload.locationLoading.getLocation.country_id,
          timezone: action.payload.locationLoading.getLocation.timezone,
        },
        unloadingLocation: {
          id: action.payload.locationUnloading.getLocation.id,
          status: action.payload.locationUnloading.getLocation.status,
          postName: action.payload.locationUnloading.getLocation.postName,
          postCode: action.payload.locationUnloading.getLocation.postCode,
          address: action.payload.locationUnloading.getLocation.address,
          title: action.payload.locationUnloading.getLocation.title,
          country_id: action.payload.locationUnloading.getLocation.country_id,
          timezone: action.payload.locationUnloading.getLocation.timezone,
        },
        loading_date: moment(
          action.payload.order.getOrder.transports[0].requestedLoadingTime
        ).format("YYYY-MM-DD"),
        loading_time: moment(
          action.payload.order.getOrder.transports[0].requestedLoadingTime
        ).format("HH:mm"),
        loading_timezone: action.payload.user_timezone,
        receiver: {
          name: action.payload.companyReceiver.getCompany.name,
          logoPath: action.payload.companyReceiver.getCompany.logoPath,
          id: action.payload.companyReceiver.getCompany.id,
          type: action.payload.companyReceiver.getCompany.type,
          users: [],
          taxNumber: action.payload.companyReceiver.getCompany.taxNumber,
          status: action.payload.companyReceiver.getCompany.status,
        },
        unloading_date: moment(
          action.payload.order.getOrder.transports[0].requestedUnloadingTime
        ).format("YYYY-MM-DD"),
        unloading_time: moment(
          action.payload.order.getOrder.transports[0].requestedUnloadingTime
        ).format("HH:mm"),
        unloading_timezone: action.payload.user_timezone,
        transporter: {
          name: action.payload.companyTransporter?.name,
          logoPath: action.payload.companyTransporter?.logoPath,
          id: action.payload.companyTransporter?.id || null,
          type: action.payload.companyTransporter?.type,
          users: [],
          taxNumber: action.payload.companyTransporter?.taxNumber,
          status: action.payload.companyTransporter?.status,
        },
        price: action.payload.companyTransporter
          ? action.payload.order.getOrder.price
          : null,
        // set 2nd condition to true for Receive pricing bids
        onMarket: action.payload.companyTransporter ? false : false,
        cargoType: action.payload.order.getOrder.transports[0].cargoType,
        parcels: action.payload.order.getOrder.transports[0].parcels?.map(
          (x: any) => {
            const y = Object.assign({}, x);
            if (y.__typename) delete y.__typename;
            return y;
          }
        ),
        dangerous_goods: action.payload.order.getOrder.transports[0].dangerous,
        stackable_goods: action.payload.order.getOrder.transports[0].stackable,
        description: action.payload.order.getOrder.description,
        expiry_date: action.payload.order.getOrder.marketExpirationDate
          ? moment(action.payload.order.getOrder.marketExpirationDate).format(
              "YYYY-MM-DD"
            )
          : moment().add(1, "hours").format("YYYY-MM-DD"),
        expiry_time: action.payload.order.getOrder.marketExpirationDate
          ? moment(action.payload.order.getOrder.marketExpirationDate).format(
              "HH:mm"
            )
          : moment().add(1, "hours").format("HH:mm"),
        expiry_timezone: action.payload.user_timezone,
      };

    default:
      break;
  }
};
