import { Anchor, Col, Div, Icon, Input, Row, Text } from "atomize";
import { isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import InputDropdown from "../../../components/InputDropdown/InputDropdown";

import { matchPath, useLocation } from "react-router-dom";
import PreloadingImage from "../../../components/PreloadingImage/PreloadingImage";
import ShortNameLogo from "../../../components/ShortNameLogo/ShortNameLogo";
import Skeleton from "../../../components/Skeleton/Skeleton";
import {
  Company,
  CompanyStatus,
  Currency,
  TaxationClause,
  useGetAllTaxationClauseLazyQuery,
  useGetCompaniesLazyQuery,
  useGetCurrenciesLazyQuery,
  useGetInvoicePrefilledDataLazyQuery,
} from "../../../generated/graphql";
import { useSearch } from "../../../helpers/CustomHooks/useSearch";
import ErrorsBeHandler from "../../../helpers/Text/ErrorsBeHandler";
import { InvoiceState } from "./helpers/helpers";

interface Props {
  invoice: InvoiceState;
  setInvoice: Dispatch<SetStateAction<InvoiceState>>;
  handleFieldValueChange: (value: any, field: keyof InvoiceState) => void;
  isGenerateModal?: boolean;
  subtext?: string;
  shipperCompanyId?: number | null;
  isOpen: boolean;
  orderId?: number;
  orderCode?: string;
  currencyId?: number;
}

const InvoiceData: FC<Props> = ({
  invoice,
  setInvoice,
  handleFieldValueChange,
  isGenerateModal = false,
  subtext = "",
  shipperCompanyId = null,
  isOpen,
  orderId,
  orderCode,
  currencyId = 9,
}) => {
  const [queryShipperStatus] = useState([
    CompanyStatus.Placeholder,
    CompanyStatus.Active,
    CompanyStatus.PlaceholderWaitingConfirm,
  ]);

  const location = useLocation();
  const isDocumentCreatingPage = matchPath(location?.pathname, {
    path: "/document/create",
  });

  const isCompanyNotSelectedInGenerateMode =
    isGenerateModal && !invoice?.payingCompany?.id;

  const [isShipperDropdownOpen, setIsShipperDropdownOpen] =
    useState<boolean>(false);
  const [isTaxationDropdownOpen, setIsTaxationDropdownOpen] =
    useState<boolean>(false);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] =
    useState<boolean>(false);

  const queryShipper = useGetCompaniesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getCompanies] = queryShipper;
  const {
    data: companiesShipper,
    loading: loadingShipperCompanies,
    search: searchShipper,
    setSearch: setSearchShipper,
    page: searchShipperPage,
    setPage: setSearchShipperPage,
  } = useSearch(
    queryShipper,
    { limit: 5, status: queryShipperStatus },
    300,
    queryShipperStatus
  );

  const queryInvoicePrefilledData = useGetInvoicePrefilledDataLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getInvoicePrefilledData, { error: errorInvoicePrefilledData }] =
    queryInvoicePrefilledData;

  ErrorsBeHandler({
    error: errorInvoicePrefilledData,
  });

  const queryTaxationClause = useGetAllTaxationClauseLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getAllTaxationClause] = queryTaxationClause;
  const {
    data: taxationClauses,
    loading: loadingTaxationClauses,
    search: searchTaxation,
    setSearch: setSearchTaxation,
    page: searchTaxationPage,
    setPage: setSearchTaxationPage,
  } = useSearch(
    queryTaxationClause,
    { limit: 5 },
    300,
    isTaxationDropdownOpen && isGenerateModal
  );

  const queryCurrencies = useGetCurrenciesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getCurrencies] = queryCurrencies;
  const {
    data: currencies,
    loading: loadingCurrencies,
    search: searchCurrencies,
    setSearch: setSearchCurrencies,
    page: searchCurrenciesPage,
    setPage: setSearchCurrenciesPage,
  } = useSearch(queryCurrencies, { limit: 5 }, 300, isCurrencyDropdownOpen);

  useEffect(() => {
    if (isOpen && currencyId) {
      getCurrencies({
        variables: {
          query: {
            id: currencyId,
          },
        },
      })?.then((res) => {
        setInvoice((prevState) => ({
          ...prevState,
          currency: res?.data?.getCurrencies?.items?.[0],
        }));
      });
    }
  }, [currencyId, getCurrencies, isOpen, setInvoice, isGenerateModal]);

  useEffect(() => {
    if (
      isOpen &&
      (orderId || orderCode) &&
      invoice?.payingCompany?.id &&
      isGenerateModal
    ) {
      getInvoicePrefilledData({
        variables: {
          orderId: orderId ?? 0,
          orderCode: orderCode ?? "",
          payeeId: invoice?.payingCompany?.id as number,
        },
      })?.then((res) => {
        if (res?.data?.getInvoicePrefilledData) {
          setInvoice((prevState) => ({
            ...prevState,
            amount: res?.data?.getInvoicePrefilledData?.amount,
            invoiceNumber: res?.data?.getInvoicePrefilledData?.invoiceNumber,
            paymentDueDate: moment(
              res?.data?.getInvoicePrefilledData?.paymentDueDate
            ).format("YYYY-MM-DD"),
          }));
        }
      });
    }
  }, [
    getInvoicePrefilledData,
    invoice?.payingCompany?.id,
    isGenerateModal,
    isOpen,
    orderCode,
    orderId,
    setInvoice,
  ]);

  useEffect(() => {
    if (isOpen && isGenerateModal) {
      getAllTaxationClause({
        variables: {
          query: {
            isDefault: true,
          },
        },
      })?.then((res) => {
        if (res.data?.getAllTaxationClause?.items?.length) {
          setInvoice((prevState) => ({
            ...prevState,
            taxation: res?.data?.getAllTaxationClause?.items?.[0],
          }));
        }
      });
    }
  }, [getAllTaxationClause, isGenerateModal, isOpen, setInvoice]);

  useEffect(() => {
    if (shipperCompanyId && isOpen) {
      getCompanies({
        variables: {
          query: {
            ids: [shipperCompanyId],
            status: queryShipperStatus,
          },
        },
      })?.then((res) => {
        if (res.data?.getCompanies?.items?.length) {
          setInvoice((prevState: any) => ({
            ...prevState,
            payingCompany: res?.data?.getCompanies?.items?.[0],
          }));
        }
      });
    }
  }, [getCompanies, isOpen, queryShipperStatus, setInvoice, shipperCompanyId]);

  return (
    <>
      {subtext && (
        <Row>
          <Col d="flex">
            <Text textColor="semiDark" m={{ t: "0.5rem", b: "2rem" }}>
              {subtext}
            </Text>
          </Col>
        </Row>
      )}

      <Row>
        {/* Amount field */}
        <Col>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Amount
          </Text>
          <NumberFormat
            m={{ b: "1rem" }}
            prefix={"€ "}
            style={{ height: "48px" }}
            placeholder="€250"
            value={invoice?.amount || ""}
            onValueChange={(values) =>
              handleFieldValueChange(values.value, "amount")
            }
            disabled={isCompanyNotSelectedInGenerateMode}
            customInput={Input}
          />
        </Col>
        {/* Invoice number field */}
        {isGenerateModal && (
          <Col>
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem" }}
            >
              Invoice number
            </Text>
            <Input
              disabled={isCompanyNotSelectedInGenerateMode}
              placeholder="BA-2024-894"
              name={"invoiceNumber"}
              value={invoice?.invoiceNumber || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldValueChange(
                  e?.target?.value?.toUpperCase(),
                  "invoiceNumber"
                )
              }
              textSize={"16"}
              type="text"
              h="48px"
            />
          </Col>
        )}
      </Row>

      {/* Payment due date field */}
      <Row d="vertical">
        <Col>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Payment due date
          </Text>
        </Col>
        <Col>
          <Row>
            <Col>
              <Input
                h="48px"
                name="paymentDueDate"
                placeholder="Payment due date"
                onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (isEmpty(invoice?.paymentDueDate)) {
                    e.target.type = "date";
                    handleFieldValueChange(
                      moment(new Date()).format("YYYY-MM-DD"),
                      "paymentDueDate"
                    );
                  }
                }}
                value={invoice?.paymentDueDate ? invoice?.paymentDueDate : ""}
                type={invoice?.paymentDueDate ? "date" : "text"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value !== "") {
                    handleFieldValueChange(e.target.value, "paymentDueDate");
                  }
                }}
                disabled={isCompanyNotSelectedInGenerateMode}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Div h="1rem" />
      {/* Company selection field */}
      <Row>
        <Col>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Payee
          </Text>
          {(isCompanyNotSelectedInGenerateMode ||
            (!orderCode && isDocumentCreatingPage)) && (
            <Text textSize={12} textColor="danger">
              To get prefilled data for the invoice generation, please enter
              order id and select a payee company first
            </Text>
          )}
          <InputDropdown
            h="48px"
            page={searchShipperPage}
            setPage={setSearchShipperPage}
            totalPages={Math.ceil(companiesShipper?.getCompanies?.total / 5)}
            addNew={false}
            isLoading={loadingShipperCompanies}
            displayResult={
              <Div
                d={!isEmpty(invoice?.payingCompany) ? "flex" : "none"}
                h="24px"
                align="center"
                style={{
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  WebkitLineClamp: "1",
                  width: "100%",
                }}
              >
                <PreloadingImage
                  imageSrc={invoice?.payingCompany?.logoPath ?? ""}
                  skeletonComponent={
                    <Skeleton
                      height={"24px"}
                      width={"24px"}
                      borderRadius={"24px"}
                    />
                  }
                  imgComponent={
                    <ShortNameLogo
                      size="24px"
                      name={invoice?.payingCompany?.name ?? ""}
                      textSize={12}
                    />
                  }
                  imageStyle={{
                    minWidth: "24px",
                    minHeight: "24px",
                    width: "24px",
                    height: "24px",
                    borderRadius: "24px",
                    verticalAlign: "middle",
                  }}
                />
                <Div
                  style={{
                    width: "calc(100% - 70px)",
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    textWeight="500"
                    m={{ l: "0.5rem" }}
                    style={{
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                    }}
                  >
                    {invoice?.payingCompany?.name}
                  </Text>
                </Div>
                {invoice?.payingCompany?.status === CompanyStatus.Active && (
                  <Icon
                    m={{ l: "1rem" }}
                    name="Checked"
                    size="20px"
                    color="success"
                  />
                )}
              </Div>
            }
            isOpen={isShipperDropdownOpen}
            setIsOpen={setIsShipperDropdownOpen}
            value={searchShipper}
            handleChange={setSearchShipper}
            menuList={companiesShipper?.getCompanies.items?.map(
              (item: Company) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={item?.id}
                  align="center"
                  onClick={() => {
                    handleFieldValueChange(item, "payingCompany");
                    setSearchShipper("");
                    setIsShipperDropdownOpen(false);
                  }}
                >
                  <PreloadingImage
                    imageSrc={item.logoPath ?? ""}
                    skeletonComponent={
                      <Skeleton
                        height={"24px"}
                        width={"24px"}
                        borderRadius={"24px"}
                      />
                    }
                    imgComponent={
                      <ShortNameLogo
                        size="24px"
                        name={item.name}
                        textSize={12}
                      />
                    }
                    imageStyle={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "24px",
                      verticalAlign: "middle",
                    }}
                  />
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    m={{ l: "10px" }}
                  >
                    {item.name}
                  </Text>
                  {item.status === CompanyStatus.Active && (
                    <Icon
                      m={{ l: "auto" }}
                      name="Checked"
                      size="20px"
                      color="success"
                    />
                  )}
                </Anchor>
              )
            )}
          />
        </Col>
      </Row>
      <Div h={isGenerateModal ? "1rem" : "2rem"} />
      {/* Taxation field */}
      {isGenerateModal && (
        <>
          <Row>
            <Col>
              <Text
                textColor="semiDark"
                textSize={12}
                textWeight={500}
                m={{ b: "0.25rem" }}
              >
                Taxation
              </Text>
              <InputDropdown
                h="48px"
                page={searchTaxationPage}
                setPage={setSearchTaxationPage}
                totalPages={Math.ceil(
                  (taxationClauses?.getAllTaxationClause?.total ?? 0) / 5
                )}
                addNew={false}
                isLoading={loadingTaxationClauses}
                displayResult={
                  <Div
                    d="flex"
                    h="24px"
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflowX: "hidden",
                      WebkitLineClamp: "1",
                      width: "100%",
                    }}
                  >
                    <Div
                      style={{
                        width: "calc(100% - 70px)",
                      }}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        textWeight="500"
                        m={{ l: "0.5rem" }}
                        style={{
                          textOverflow: "ellipsis",
                          overflowX: "hidden",
                        }}
                      >
                        {invoice?.taxation?.clause || ""} - VAT{" "}
                        {invoice?.taxation?.vat}%
                      </Text>
                    </Div>
                  </Div>
                }
                isOpen={isTaxationDropdownOpen}
                setIsOpen={setIsTaxationDropdownOpen}
                value={searchTaxation}
                handleChange={setSearchTaxation}
                menuList={taxationClauses?.getAllTaxationClause?.items?.map(
                  (item: TaxationClause) => (
                    <Anchor
                      d="flex"
                      p={{ y: "0.5rem", x: "0.75rem" }}
                      key={item?.id}
                      align="center"
                      onClick={() => {
                        handleFieldValueChange(item, "taxation");
                        setSearchTaxation("");
                        setIsTaxationDropdownOpen(false);
                      }}
                    >
                      <Text
                        textColor="semiDark"
                        hoverTextColor="dark"
                        m={{ l: "10px" }}
                      >
                        {item?.clause || ""} - VAT {item?.vat}%
                      </Text>
                    </Anchor>
                  )
                )}
              />
            </Col>
          </Row>
          <Div h="1rem" />
        </>
      )}
      <Row>
        <Col>
          <Text
            textColor="semiDark"
            textSize={12}
            textWeight={500}
            m={{ b: "0.25rem" }}
          >
            Currency
          </Text>
          <InputDropdown
            h="48px"
            page={searchCurrenciesPage}
            setPage={setSearchCurrenciesPage}
            totalPages={Math.ceil((currencies?.getCurrencies?.total ?? 0) / 5)}
            addNew={false}
            isLoading={loadingCurrencies}
            displayResult={
              <Div
                d="flex"
                h="24px"
                align="center"
                style={{
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  WebkitLineClamp: "1",
                  width: "100%",
                }}
              >
                <Div
                  style={{
                    width: "calc(100% - 70px)",
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    textWeight="500"
                    m={{ l: "0.5rem" }}
                    style={{
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                    }}
                  >
                    {invoice?.currency?.code && (
                      <>
                        {invoice?.currency?.code || ""}(
                        {invoice?.currency?.symbol}) - {invoice?.currency?.name}
                      </>
                    )}
                  </Text>
                </Div>
              </Div>
            }
            isOpen={isCurrencyDropdownOpen}
            setIsOpen={setIsCurrencyDropdownOpen}
            value={searchCurrencies}
            handleChange={setSearchCurrencies}
            menuList={currencies?.getCurrencies?.items?.map(
              (item: Currency) => (
                <Anchor
                  d="flex"
                  p={{ y: "0.5rem", x: "0.75rem" }}
                  key={item?.id}
                  align="center"
                  onClick={() => {
                    handleFieldValueChange(item, "currency");
                    setSearchCurrencies("");
                    setIsCurrencyDropdownOpen(false);
                  }}
                >
                  <Text
                    textColor="semiDark"
                    hoverTextColor="dark"
                    m={{ l: "10px" }}
                  >
                    {item?.code}({item?.symbol}) - {item?.name}
                  </Text>
                </Anchor>
              )
            )}
          />
        </Col>
      </Row>
      <Div h="1rem" />
      {/* Note field */}
      {isGenerateModal && (
        <Row>
          <Col>
            <Text
              textColor="semiDark"
              textSize={12}
              textWeight={500}
              m={{ b: "0.25rem" }}
            >
              Note
            </Text>
            <Input
              name="note"
              value={invoice?.note || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFieldValueChange(e?.target?.value, "note")
              }
              textSize={"16"}
              type="text"
              h="48px"
              m={{ b: "2rem" }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default InvoiceData;
