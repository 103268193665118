import { useEffect } from "react";
import { LocalStorageKeys } from "../constants";

// Update this version with each deployment that changes local storage structure
const CURRENT_LOCAL_STORAGE_VERSION = "1.0.2";
const LOCAL_STORAGE_VERSION_KEY = LocalStorageKeys.LOCAL_STORAGE_VERSION_KEY;

const useLocalStorageVersioning = () => {
  useEffect(() => {
    const checkAndUpdateLocalStorageVersion = () => {
      const storedVersion = localStorage.getItem(LOCAL_STORAGE_VERSION_KEY);
      // Store the token before clearing local storage
      const token = localStorage.getItem("token");

      if (storedVersion !== CURRENT_LOCAL_STORAGE_VERSION) {
        console.log(
          "Local storage version mismatch. Clearing local storage..."
        );
        localStorage.clear();
        if (token) {
          // Restore the token after clearing local storage
          localStorage.setItem("token", token);
        }
        localStorage.setItem(
          LOCAL_STORAGE_VERSION_KEY,
          CURRENT_LOCAL_STORAGE_VERSION
        );
      }
    };

    checkAndUpdateLocalStorageVersion();
  }, []);
};

export default useLocalStorageVersioning;
