import { useReactiveVar } from "@apollo/client";
import { Anchor, Col, Div, Icon, Input, Row, Text } from "atomize";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { path } from "../../App";
import renderIcon from "../../assets/Icons";
import Border from "../../components/Border/Border";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";
import { UserLogin, useLoginMutation } from "../../generated/graphql";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import { LocalStorageKeys } from "../../helpers/constants";

let schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .email({ name: "Email", code: "INVALID_EMAIL" }),
  password: yup
    .string()
    .min(8, { name: "Password", code: "MIN_CHARACTERS", var: "8" }),
});

const SignIn: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { validator } = ValidationsFeHandler();
  const [login, { data, loading, error }] = useLoginMutation({
    errorPolicy: "all",
  });

  const history = useHistory();

  const [input, setInput] = useState<UserLogin>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setInput({
      ...input,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validator({
      schema: schema,
      data: { email: input.email, password: input.password },
      success: () => {
        login({
          variables: {
            userLogin: { email: input.email, password: input.password },
          },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: error,
  });

  const pathVar = useReactiveVar(path);

  SuccessBeHandler({
    data: data,
    code: "SIGN_IN",
    fn: async () => {
      localStorage.setItem(LocalStorageKeys.TOKEN, data?.login.token!);
      history.push(pathVar.from ? pathVar.from : "/myorders");
    },
  });

  return (
    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
      <Row flexDir="column">
        <Col size="12" style={{ display: "flex" }} justify="center">
          {renderIcon("Logo")}
        </Col>
        <Col>
          <Text
            textSize="201"
            textAlign="center"
            textWeight="500"
            textColor="dark"
            m={{ y: { xs: "2rem" } }}
          >
            Improve efficiency and reduce cost of your logistic operations.
          </Text>
        </Col>
        <Col size="12">
          <Input
            placeholder="Email"
            textSize={"16"}
            type="text"
            h="48px"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            name="email"
            value={input.email}
          />
        </Col>
        <Col size="12">
          <Input
            h="48px"
            placeholder="Password"
            textSize={"16"}
            type={showPassword ? "text" : "password"}
            name="password"
            m={{ y: { xs: "0.5rem" } }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            value={input.password}
            suffix={
              <Div
                pos="absolute"
                onClick={() => setShowPassword(!showPassword)}
                bg="transparent"
                w="3rem"
                top="50%"
                transform="translate(0%, -50%)"
                right="0"
                rounded={{ r: "md" }}
              >
                <Icon
                  name={showPassword ? "EyeSolid" : "Eye"}
                  color={showPassword ? "primary" : "info700"}
                  size="16px"
                  hovercolor="light"
                  transition
                />
              </Div>
            }
          />
        </Col>
        <Col size="12">
          <PrimaryBtn
            h="48px"
            w="100%"
            styleBtn={{ marginTop: "1.5rem" }}
            isLoading={loading}
            text={"Sign in"}
          />
        </Col>
        <Col>
          <Border margin={"32px 0 15px"} />
        </Col>
        <Col>
          <Row justify="space-between">
            <Col d="flex" size={{ xs: "12", sm: "4" }} justify={{}}>
              <Anchor
                hoverTextColor="info700"
                transition
                textColor="primary"
                onClick={() => {
                  history.push("/forgotpassword");
                }}
                textSize={12}
                textWeight="600"
              >
                Forgot password?
              </Anchor>
            </Col>
            <Col
              d="flex"
              size={{ xs: "12", sm: "8" }}
              justify={{ xs: "flex-start", sm: "flex-end" }}
            >
              <Text textSize={12} style={{ margin: "0 4px 0 0" }}>
                New to Cargobite?
              </Text>
              <Anchor
                hoverTextColor="info700"
                transition
                textColor="primary"
                onClick={() => {
                  history.push("/signup");
                }}
                textSize={12}
                textWeight="600"
              >
                {"Create an account ->"}
              </Anchor>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default SignIn;
