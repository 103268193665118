/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Col, Div, Icon, Row, Tag, Text } from "atomize";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  currentOrder,
  modalData,
  notificationsCount,
  notificationsCountSkip,
  orderMsgCount,
  orderMsgCountSkip,
  path,
} from "../../App";
import renderIcon from "../../assets/Icons";
import { DefaultUserRole, ModuleName } from "../../enums";
import {
  CompanyStatus,
  CompanyType,
  FeedbackType,
  NotificationStatus,
  NotificationType,
  useGetMeQuery,
  useGetMessageCountQuery,
  useGetNotificationCountQuery,
  useMarkReadNotificationMutation,
  useNewNotificationSubscription,
} from "../../generated/graphql";
import useWindowDimensions from "../../helpers/CustomHooks/useWindowDimensions";
import { LocalStorageKeys } from "../../helpers/constants";
import { userHasModule } from "../../helpers/functions";
import * as Styled from "./Sidebar.styles";

const Sidebar: React.FC = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const [markNotification] = useMarkReadNotificationMutation();
  const currentOrderVar = useReactiveVar(currentOrder);
  const orderMsgCountVar = useReactiveVar(orderMsgCount);
  const notificationsCountVar = useReactiveVar(notificationsCount);
  const notificationsCountSkipVar = useReactiveVar(notificationsCountSkip);
  const orderMsgCountSkipVar = useReactiveVar(orderMsgCountSkip);
  const [selectedDropdown, setSelectedDropdown] = useState<string>("");

  const handleLogout = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    notificationsCount(0);
    await client.cache.reset();
    localStorage.removeItem(LocalStorageKeys.TOKEN);
    history.push("/signin");
  };

  const { data: user, loading } = useGetMeQuery({
    onCompleted: () => {
      notificationsCountSkip(false);
      orderMsgCountSkip(false);
    },
  });

  const userHasDocumentInvoicingModule = userHasModule(
    user,
    ModuleName.DOCUMENTS_AND_INVOICING
  )

  useGetNotificationCountQuery({
    variables: { order_id: null },
    fetchPolicy: "network-only",
    onCompleted: (count) => {
      notificationsCount(count.getNotificationCount);
      notificationsCountSkip(true);
    },
    skip: notificationsCountSkipVar,
  });

  useGetMessageCountQuery({
    variables: { order_id: currentOrderVar.id },
    fetchPolicy: "network-only",
    onCompleted: (count) => {
      orderMsgCount(count.getMessageCount);
      orderMsgCountSkip(true);
    },
    skip: orderMsgCountSkipVar,
  });

  useNewNotificationSubscription({
    variables: { user_id: Number(user?.getUser.id) },
    skip: !user,
    onSubscriptionData: async (data) => {
      const noti = data.subscriptionData.data?.newNotification;
      if (noti?.actionUser_id !== user?.getUser.id) {
        if (
          noti?.type === NotificationType.Message &&
          noti?.status === NotificationStatus.Unread &&
          noti?.order_id === currentOrderVar.id &&
          !currentOrderVar.chatIsOpen
        ) {
          orderMsgCount(Number(orderMsgCountVar) + 1);
          notificationsCount(Number(notificationsCountVar) + 1);
        } else if (
          noti?.type === NotificationType.Message &&
          noti?.status === NotificationStatus.Unread &&
          noti?.order_id === currentOrderVar.id &&
          currentOrderVar.chatIsOpen
        ) {
          await markNotification({
            variables: { id: data.subscriptionData.data?.newNotification.id },
          });
        } else {
          notificationsCount(Number(notificationsCountVar) + 1);
        }
      }
    },
  });

  const pathVar = useReactiveVar(path);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [pathVar]);

  return (
    <>
      <Styled.Sidebar style={{ zIndex: 990 }}>
        <Styled.SidebarFixed isOpen={open}>
          <Styled.SidebarContainer
            isOpen={openSettings}
            isOpenSettings={openSettings}
          >
            <Div
              w={`100%`}
              h="3rem"
              d={width > 700 ? "none" : "flex"}
              bg={open ? "background" : "greyBorder"}
              justify={"space-between"}
              align="center"
              pos="absolute"
              right="0"
              left="0rem"
              top="-3rem"
              hovercolor="dark"
              color="semiDark"
              cursor="pointer"
              p={{ x: "1rem" }}
            >
              {!open ? (
                <>
                  <NavLink
                    to={"/myorders"}
                    className={`link_icons`}
                    activeClassName="link_active_icons"
                  >
                    <Icon name="Draft" size="20px" />
                  </NavLink>
                  <NavLink
                    to={"/inbox"}
                    className={`link_icons ${
                      user?.getUser.company?.status !== CompanyStatus.Active ||
                      loading
                        ? "disabled"
                        : ""
                    }`}
                    activeClassName="link_active_icons"
                  >
                    <Icon name="NotificationSolid" size="20px" />
                    {user?.getUser.company?.status === CompanyStatus.Active &&
                    Number(notificationsCountVar) > 0 ? (
                      <Tag
                        m={{ x: { xs: "0.25rem" }, y: { xs: "0rem" } }}
                        bg={`danger`}
                        pos="absolute"
                        textColor={`background`}
                      >
                        {notificationsCountVar}
                      </Tag>
                    ) : null}
                  </NavLink>
                  <NavLink
                    to={"/documents"}
                    className={`link_icons ${
                      user?.getUser.company?.status !== CompanyStatus.Active ||
                      loading
                        ? "disabled"
                        : ""
                    }`}
                    activeClassName="link_active_icons"
                  >
                    {renderIcon("File")}
                  </NavLink>
                </>
              ) : (
                <Styled.SidebarLogo
                  onClick={() => {
                    history.push("/myorders");
                  }}
                >
                  {renderIcon("Logo")}
                </Styled.SidebarLogo>
              )}
              <Icon
                name={!open ? "Menu" : "Cross"}
                size="20px"
                onClick={() => {
                  setOpen(!open);
                }}
              />
            </Div>
            <Div
              style={{ overflowY: "auto", overflowX: "hidden" }}
              h={"100%"}
              w="auto"
            >
              <Row
                minH={"690px"}
                p={width > 700 ? "16px" : "0 16px 16px"}
                w="auto"
                h="100%"
                pos="relative"
              >
                <Div pos="absolute" className="setting_sidebar">
                  <Row flexDir="column" justify="space-between" minH={"100%"}>
                    <Col size="12" d="flex" flexDir="column" justify="center">
                      <Icon
                        cursor="pointer"
                        name="LongLeft"
                        size="20px"
                        m={{ t: "16px" }}
                        color="primary"
                        hovercolor="brand600"
                        onClick={() => {
                          setOpenSettings(false);
                          setOpen(true);
                        }}
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/company"
                        name="Company"
                        isDisabled={
                          user?.getUser.company?.status !==
                            CompanyStatus.Active ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1)
                        }
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/individual"
                        name="Individual"
                        isDisabled={false}
                        parent="settings"
                      />
                      {userHasDocumentInvoicingModule && (
                        <>
                          <SidebarLink
                            count={0}
                            path="/settings/invoicing"
                            name="Invoicing"
                            isDisabled={user?.getUser?.roles![0].id === 4}
                            parent="settings"
                          />
                          <SidebarLink
                            count={0}
                            path="/settings/export"
                            name="Export"
                            isDisabled={false}
                            parent="settings"
                          />
                        </>
                      )}
                      <SidebarLink
                        count={0}
                        path="/settings/locations"
                        name="Locations"
                        isDisabled={
                          user?.getUser.company?.status !==
                            CompanyStatus.Active ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1 &&
                            user?.getUser.roles![0].id !== 6)
                        }
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/drivers"
                        name="Drivers"
                        isDisabled={
                          user?.getUser.company?.status ===
                            CompanyStatus.WaitingConfirm ||
                          user?.getUser.company?.type !==
                            CompanyType.Transporter ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1 &&
                            user?.getUser.roles![0].id !== 6)
                        }
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/vehicles"
                        name="Vehicles"
                        isDisabled={
                          user?.getUser.company?.status ===
                            CompanyStatus.WaitingConfirm ||
                          user?.getUser.company?.type !==
                            CompanyType.Transporter ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1 &&
                            user?.getUser.roles![0].id !== 6)
                        }
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/team"
                        name="Team"
                        isDisabled={
                          user?.getUser.company?.status !==
                            CompanyStatus.Active ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1 &&
                            user?.getUser.roles![0].id !== 6)
                        }
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/billing"
                        tag="Coming soon"
                        name="Billing"
                        isDisabled={true}
                        parent="settings"
                      />
                      <SidebarLink
                        count={0}
                        path="/settings/inbox"
                        tag="Coming soon"
                        name="Inbox"
                        isDisabled={true}
                        parent="settings"
                      />
                    </Col>
                  </Row>
                </Div>
                <Col
                  className="top_side"
                  size="12"
                  d="flex"
                  flexDir="column"
                  justify="flex-start"
                >
                  {width > 700 && (
                    <Styled.SidebarLogo
                      onClick={() => {
                        history.push("/myorders");
                      }}
                    >
                      {renderIcon("Logo")}
                    </Styled.SidebarLogo>
                  )}
                  {/* Orders Nav Dropdown */}
                  <SidebarDropdown
                    name="orders"
                    selectedDropdown={selectedDropdown}
                    onClick={setSelectedDropdown}
                    dropdownItems={(
                      <>
                        <NavLink
                          to="/myorders"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">View All Orders</Text>
                        </NavLink>
                        <NavLink
                          to="/createneworder/order"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">Create New Order</Text>
                        </NavLink>
                        <NavLink
                          to="/createneworder/order"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">Import Orders</Text>
                        </NavLink>
                        {userHasDocumentInvoicingModule && (
                          <NavLink
                            to="/settings/export"
                            activeClassName="link_active"
                          >
                            <Text textSize={16} textWeight="600">Export Settings</Text>
                          </NavLink>
                        )}
                      </>
                    )}
                  >
                    <Text textSize={16} textWeight="600">Orders</Text>
                  </SidebarDropdown>
                  {/* Documents Nav Dropdown */}
                  <SidebarDropdown
                    name="documents"
                    selectedDropdown={selectedDropdown}
                    onClick={setSelectedDropdown}
                    dropdownItems={(
                      <>
                        <NavLink
                          to="/documents"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">View Documents</Text>
                        </NavLink>
                        {/*<NavLink
                          to="/payments"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">Upcoming Payments</Text>
                        </NavLink>*/}
                        {userHasDocumentInvoicingModule && (
                          <>
                            <NavLink
                              to="/settings/invoicing"
                              activeClassName="link_active"
                            >
                              <Text textSize={16} textWeight="600">Invoice Setup</Text>
                            </NavLink>
                            <NavLink
                              to="/settings/export"
                              activeClassName="link_active"
                            >
                              <Text textSize={16} textWeight="600">Export Settings</Text>
                            </NavLink>
                          </>
                        )}
                      </>
                    )}
                  >
                    <Text textSize={16} textWeight="600">Documents</Text>
                  </SidebarDropdown>
                  {/* Fleet Nav Dropdown */}
                  {!(user?.getUser.company?.status ===
                      CompanyStatus.WaitingConfirm ||
                    user?.getUser.company?.type !==
                      CompanyType.Transporter ||
                    loading ||
                    (user?.getUser.roles![0].id !== 2 &&
                      user?.getUser.roles![0].id !== 1 &&
                      user?.getUser.roles![0].id !== 6))
                  && (
                    <SidebarDropdown
                      name="fleet"
                      selectedDropdown={selectedDropdown}
                      onClick={setSelectedDropdown}
                      dropdownItems={(
                        <>
                          <NavLink
                            to="/settings/vehicles"
                            activeClassName="link_active"
                          >
                            <Text textSize={16} textWeight="600">Vehicles</Text>
                          </NavLink>
                          <NavLink
                            to="/settings/drivers"
                            activeClassName="link_active"
                          >
                            <Text textSize={16} textWeight="600">Drivers</Text>
                          </NavLink>
                        </>
                      )}
                    >
                      <Text textSize={16} textWeight="600">Fleet</Text>
                    </SidebarDropdown>
                  )}
                  {/* Marketplace Nav Dropdown */}
                  {/*<SidebarDropdown
                    name="marketplace"
                    selectedDropdown={selectedDropdown}
                    onClick={setSelectedDropdown}
                    dropdownItems={(
                      <>
                        <NavLink
                          to="/bids"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">Marketplace</Text>
                        </NavLink>
                        <NavLink
                          to="/contractors"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">Contractors</Text>
                        </NavLink>
                      </>
                    )}
                  >
                    <Text textSize={16} textWeight="600">Marketplace</Text>
                  </SidebarDropdown>*/}
                  <SidebarLink
                    path="/inbox"
                    name="Inbox"
                    isDisabled={
                      user?.getUser.company?.status !==
                        CompanyStatus.Active || loading
                    }
                    count={
                      user?.getUser.company?.status === CompanyStatus.Active
                        ? notificationsCountVar
                        : 0
                    }
                  />
                  <SidebarLink
                    count={0}
                    path="/reminders"
                    name="Reminders"
                    isDisabled={
                      user?.getUser.company?.status !== CompanyStatus.Active ||
                      loading
                    }
                  />
                </Col>
                <Col
                  className="bottom_side"
                  size="12"
                  d="flex"
                  flexDir="column"
                  justify="flex-end"
                >
                  {/* Settings Nav Dropdown */}
                  <SidebarDropdown
                    name="settings"
                    selectedDropdown={selectedDropdown}
                    onClick={setSelectedDropdown}
                    dropdownItems={(
                      <>
                        {!(user?.getUser.company?.status ===
                            CompanyStatus.WaitingConfirm ||
                          user?.getUser.company?.type !==
                            CompanyType.Transporter ||
                          loading ||
                          (user?.getUser.roles![0].id !== 2 &&
                            user?.getUser.roles![0].id !== 1 &&
                            user?.getUser.roles![0].id !== 6))
                        && (
                          <>
                            <NavLink
                              to="/settings/team"
                              activeClassName="link_active"
                            >
                              <Text textSize={16} textWeight="600">Team</Text>
                            </NavLink>
                            <NavLink
                              to="/settings/company"
                              activeClassName="link_active"
                            >
                              <Text textSize={16} textWeight="600">Company</Text>
                            </NavLink>
                            <NavLink
                              to="/settings/locations"
                              activeClassName="link_active"
                            >
                              <Text textSize={16} textWeight="600">Locations</Text>
                            </NavLink>
                          </>
                        )}
                        <NavLink
                          to="/settings/individual"
                          activeClassName="link_active"
                        >
                          <Text textSize={16} textWeight="600">My Profile</Text>
                        </NavLink>
                        {user?.getUser?.roles![0].id ===
                          DefaultUserRole.MODULE_PERMISSIONS_MANAGER && (
                          <NavLink
                            to="/settings/manage-module-permissions"
                            activeClassName="link_active"
                          >
                            <Text textSize={16} textWeight="600">Module Permissions</Text>
                          </NavLink>
                        )}
                        <Styled.SidebarLink>
                          <Text
                            className="logout"
                            textSize={16}
                            textWeight="600"
                            m={{ t: "-0.5rem" }}
                            onClick={handleLogout}
                          >
                            Log out
                          </Text>
                        </Styled.SidebarLink>
                      </>
                    )}
                  >
                    <Text textSize={16} textWeight="600">Settings</Text>
                  </SidebarDropdown>
                  {/* Resources Nav Dropdown */}
                  <SidebarDropdown
                    name="resources"
                    selectedDropdown={selectedDropdown}
                    onClick={setSelectedDropdown}
                    dropdownItems={(
                      <>
                        <SidebarLink
                          count={0}
                          path={{
                            pathname:
                              "https://cargobite.notion.site/Cargobite-Help-FAQ-5aa06a8044e043df8df22d2392f31b5e",
                          }}
                          name="Help &amp; FAQ"
                          isDisabled={false}
                          isExternalUrl={true}
                        />
                        <SidebarLink
                          count={0}
                          path={{
                            pathname:
                              "https://cargobite.notion.site/Cargobite-Legal-7d4db2ac8eba49c9a88d7f19545cbfb5",
                          }}
                          name="Legal"
                          isExternalUrl={true}
                          isDisabled={false}
                        />
                        <Styled.SidebarLink>
                          <Text
                            className="logout"
                            textSize={16}
                            textWeight="600"
                            onClick={() => {
                              modalData({
                                msg: "Report a bug",
                                name: "reportBug",
                                props: {
                                  type: FeedbackType.ReportError,
                                  subtext:
                                    "Please tell us what’s wrong with the platform.",
                                },
                              });
                            }}
                          >
                            Report a bug
                          </Text>
                        </Styled.SidebarLink>
                      </>
                    )}
                  >
                    <Text textSize={16} textWeight="600">Resources</Text>
                  </SidebarDropdown>
                  <Styled.SidebarLink>
                    <Div d="flex" align="center" p={{ r: "0.5rem"}} m={{ l: "-0.5rem"}}>
                      <Text
                        className="logout"
                        textSize={16}
                        textWeight="600"
                        onClick={() => {
                          user?.getUser.company?.status === CompanyStatus.Active &&
                            modalData({
                              msg: "Invite company",
                              name: "inviteCompany",
                            });
                        }}
                      >
                        Invite users
                      </Text>
                      <Icon
                        m={{ l: "auto" }}
                        name="UserSolid"
                        size="16px"
                        color="semiDark"
                      />
                    </Div>
                  </Styled.SidebarLink>
                  {/*<Styled.ClientBadge
                    onClick={() => {
                      history.push("/settings");
                    }}
                  >
                    <PreloadingImage
                      imageSrc={
                        user?.getUser.company?.logoPath
                          ? user?.getUser.company?.logoPath
                          : ""
                      }
                      isLoading={loading}
                      skeletonComponent={
                        <Skeleton
                          height={"32px"}
                          width={"32px"}
                          borderRadius={"32px"}
                        />
                      }
                      imgComponent={
                        <ShortNameLogo
                          size="32px"
                          name={`${user?.getUser.company?.name}`}
                          textSize={12}
                        />
                      }
                      imageStyle={{
                        minWidth: "24px",
                        minHeight: "24px",
                        width: "24px",
                        height: "24px",
                        verticalAlign: "middle",
                      }}
                    />
                    {loading ? (
                      <Skeleton height={"18px"} width={"100%"} />
                    ) : (
                      <Text
                        textSize="12"
                        textWeight="500"
                        textColor="semiDark"
                        m={{ l: "1rem" }}
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "hidden",
                          WebkitLineClamp: "1",
                          textOverflow: "ellipsis",
                          width: "calc(100% - 50px)",
                        }}
                      >
                        {user?.getUser.company?.name}
                      </Text>
                    )}
                  </Styled.ClientBadge>*/}
                </Col>
              </Row>
            </Div>
          </Styled.SidebarContainer>
        </Styled.SidebarFixed>
      </Styled.Sidebar>
    </>
  );
};

export default Sidebar;

interface SidebarLinkProps {
  path: string | any;
  name: string;
  isDisabled: boolean;
  parent?: string;
  tag?: string;
  count: number | Number;
  isExternalUrl?: boolean;
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({
  path,
  name,
  isDisabled,
  parent,
  tag,
  count,
  isExternalUrl = false,
}) => {
  return (
    <Styled.SidebarLink className={isDisabled ? "disabled" : ""}>
      <NavLink
        to={path}
        target={isExternalUrl ? "_blank" : "_self"}
        activeClassName="link_active"
      >
        {parent !== "settings" && <div className="active_border"></div>}
        <Text textSize={16} textWeight="600">
          {name}
        </Text>
        {isDisabled && tag && (
          <Tag
            m={{ x: { xs: "0.25rem" }, y: { xs: "0rem" } }}
            bg={`grey`}
            border="1px solid"
            borderColor={`greyBorder`}
            textColor={`semiLight`}
          >
            {tag}
          </Tag>
        )}
        {count && Number(count) > 0 ? (
          <Tag
            m={{ x: { xs: "0.25rem" }, y: { xs: "0rem" } }}
            bg={`danger300`}
            textColor={`danger`}
          >
            <Icon
              m={{ r: "0.25rem" }}
              name="NotificationSolid"
              size="16px"
              color="danger"
            />
            {count}
          </Tag>
        ) : null}
      </NavLink>
    </Styled.SidebarLink>
  );
};

export const SidebarDropdown: React.FC<{
  name: string;
  selectedDropdown: string;
  dropdownItems?: React.ReactNode;
  onClick: (name: string) => void;
}> = ({
  name,
  selectedDropdown,
  dropdownItems,
  onClick,
  children
}) => {
  return (
    <Styled.SidebarDropdown className={selectedDropdown === name ? "show" : ""}>
      <Div
        className={selectedDropdown === name ? "main_link link_active" : "main_link"}
        onClick={() => onClick(selectedDropdown === name ? "" : name)}
      >
        {children}
        <Icon
          m={{ l: "auto" }}
          name="DownArrow"
          size="16px"
          color="semiDark"
        />
      </Div>
      <Div d={selectedDropdown === name ? "flex" : "none"} w="100%" flexDir="column" pos="relative" p={{ l: "18px" }} m={{ t: "8px" }}>
        <Div
          pos="absolute"
          top="0"
          left="8px"
          w="2px"
          h="100%"
          bg="greyBorder"
        />
        {dropdownItems}
      </Div>
    </Styled.SidebarDropdown>
  );
};