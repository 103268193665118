import { useState } from "react";
import { responseMsgs } from "../../App";

export const GoogleAPI = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCoordinates = async (
    address: string,
    city: string,
    code: string,
    country: string
  ) => {
    setIsLoading(true);
    try {
      const fullAddress: string = `${address} ${city} ${code} ${country}`;
      const response = await fetch(
        `https://maps.Googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=AIzaSyAdEheswbq-Mp3yrmwWmyxquXsqczrYIL0`
      );
      const data = await response.json();
      if (data.status === "OK") {
        setIsLoading(false);
        return data.results[0].geometry.location;
      } else {
        responseMsgs([
          ...responseMsgs(),
          {
            type: "error",
            string:
              "Unable to create location. Please insert valid address details.",
          },
        ]);
        setIsLoading(false);
        return false;
      }
    } catch (err) {
      setIsLoading(false);
      return false;
    }
  };

  return { getCoordinates, isLoading };
};
