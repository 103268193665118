import React, { useEffect, useState } from "react";
import { Div, Anchor } from "atomize";
import ReactDOM from "react-dom";
import { GlobalTheme } from "../GlobalTheme";
import { capitalize } from "lodash";

const DropdownOptionsTd: React.FC<{
  statusDropdown: boolean;
  options?: any;
  dropdownCordinates: { l: number; w: number; t: number };
  handleSubmitStatus: any;
  padding?: string;
}> = ({
  statusDropdown,
  options,
  dropdownCordinates,
  handleSubmitStatus,
  padding = "0 0.75rem",
}) => {
  const [scale, setScale] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScale(1);
      setOpacity(1);
    }, 100);
  }, []);

  useEffect(() => {
    setScale(0);
    setOpacity(0);
  }, [statusDropdown]);

  return ReactDOM.createPortal(
    <Div
      shadow={GlobalTheme.shadows[4]}
      transition
      style={{
        transition: "0.3s transform ease-in-out, 0.3s opacity ease-in-out",
        transform: `scaleY(${scale}`,
        opacity: opacity,
        transformOrigin: "50% 0px",
        position: "fixed",
        left: `${dropdownCordinates.l}px`,
        top: `${dropdownCordinates.t + 40}px`,
        zIndex: 20000,
        background: GlobalTheme.colors.background,
        padding: padding,
        width: `${dropdownCordinates.w}px`,
        borderRadius: GlobalTheme.rounded[4],
      }}
      tabIndex={0}
    >
      {options?.map((status: any, i: number) => (
        <Div d="flex" justify="center" align="center" m={{ l: "2px" }} key={i}>
          <Anchor
            textSize={142}
            m={{ r: "auto", l: "8px" }}
            textColor="semiDark"
            hoverTextColor="dark"
            textTransform="capitalize"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              handleSubmitStatus(status);
            }}
          >
            {capitalize(status)}
          </Anchor>
        </Div>
      ))}
    </Div>,
    document.body
  );
};

export default DropdownOptionsTd;
