import React, { useState } from "react";
import { Row, Col, Text, Input } from "atomize";
import renderIcon from "../../assets/Icons";
import { useHistory, useLocation } from "react-router-dom";
import { useResetPasswordMutation } from "../../generated/graphql";
import { getParams } from "../../helpers/functions";
import * as yup from "yup";
import ErrorsBeHandler from "../../helpers/Text/ErrorsBeHandler";
import SuccessBeHandler from "../../helpers/Text/SuccessBeHandler";
import ValidationsFeHandler from "../../helpers/Text/ValidationsFeHandler";
import PrimaryBtn from "../../components/PrimaryBtn/PrimaryBtn";

let schema = yup.object().shape({
  password: yup
    .string()
    .required({ name: "Email", code: "REQUIRED_FIELD" })
    .min(8, { name: "Email", code: "MIN_CHARACTERS", var: "8" }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], {
      name: "Passwords",
      code: "PASSWORD_MATCH",
    }),
  token: yup
    .string()
    .trim()
    .nullable()
    .required({ name: "Token", code: "TOKEN_MISSING" }),
});

const ResetPassword: React.FC = () => {
  const { validator } = ValidationsFeHandler();
  const history = useHistory();

  const [
    passwordReset,
    {
      data: passwordResetData,
      loading: passwordResetDataLoading,
      error: passwordResetError,
    },
  ] = useResetPasswordMutation({ errorPolicy: "all" });

  const search = useLocation().search;
  const token = getParams(search, "token", "?token=");

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validator({
      schema: schema,
      data: { ...state, token },
      success: () => {
        passwordReset({
          variables: {
            password: state.password,
            token: token!,
          },
        });
      },
    });
  };

  ErrorsBeHandler({
    error: passwordResetError,
  });

  SuccessBeHandler({
    data: passwordResetData,
    code: "RESET_PASSWORD",
    fn: () => {
      history.push("/signin");
    },
  });

  return (
    <Row flexDir="column" w="100%">
      <Col size="12" style={{ display: "flex" }} justify="center">
        {renderIcon("Logo")}
      </Col>
      <Col>
        <Text
          textSize="201"
          textAlign="center"
          textWeight="500"
          textColor="dark"
          m={{ y: { xs: "2rem" } }}
        >
          Improve efficiency and reduce cost of your logistic operations.
        </Text>
      </Col>
      <Col size="12">
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "0.5rem" }}
        >
          New Password
        </Text>

        <Input
          textSize={"16"}
          type="password"
          h="48px"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name="password"
          value={state.password}
        />
        <Text
          textColor="semiDark"
          textSize={12}
          textWeight={500}
          m={{ b: "0.25rem", t: "1rem" }}
        >
          Confirm New Password
        </Text>
        <Input
          textSize={"16"}
          h="48px"
          m={{ t: "0.5rem" }}
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name="confirmPassword"
          value={state.confirmPassword}
        />
      </Col>

      <Col size="12">
        <PrimaryBtn
          w="100%"
          handleSubmit={(e: React.ChangeEvent<HTMLButtonElement>) =>
            handleSubmit(e)
          }
          styleBtn={{ marginTop: "1.5rem" }}
          isLoading={passwordResetDataLoading}
          disabled={passwordResetDataLoading}
          text={"Update password"}
        />
      </Col>
    </Row>
  );
};

export default ResetPassword;
